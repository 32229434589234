import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import {
  Routine,
  RoutineStatus,
} from '@augusthealth/models/com/august/protos/routine'
import classNames from 'classnames/bind'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import ButtonLink from '@shared/components/ButtonLink'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { schedulePathForPerson } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import {
  isActiveRoutine,
  isDiscardedRoutine,
  isDraftRoutine,
  isMorningOrBedtime,
} from '@shared/utils/routine'
import {
  timingToDays,
  timingToEvery,
  timingToShiftName,
  timingToTimeString,
} from '@shared/utils/timing'
import { updateRoutine } from '@app/api/routines'
import styles from './styles.module.css'
import { getRoutineIcon } from './helpers'
import { RoutineBadge } from './RoutineBadge'
import { AbbreviatedDescription, FullDescription } from './RoutineDescription'

export function RoutineCard({
  routine,
  person,
  onUpdate,
  isHidden,
}: {
  routine: Routine
  person: Person
  onUpdate: () => Promise<void>
  isHidden: boolean
}) {
  const { setError } = useContext(GlobalContext)
  const [isDiscarding, setIsDiscarding] = useState(false)
  const history = useHistory()
  const [showFullInstructions, setShowFullInstructions] = useState(
    !isMorningOrBedtime(routine)
  )

  const routineCardClasses = classNames.call(null, styles.routineRow, {
    [styles.scheduled]: isActiveRoutine(routine),
    'text-gray-08': isDraftRoutine(routine),
    'text-gray-01': isActiveRoutine(routine),
  })

  const scheduleBtnStyles = classNames.call(null, {
    'mr-[16px]': !isDiscardedRoutine(routine),
  })

  if (isHidden) {
    return null
  }

  return (
    <div
      className={routineCardClasses}
      onClick={() => {
        if (isActiveRoutine(routine)) {
          history.push(
            schedulePathForPerson(person as Required<Person>, routine)
          )
        }
      }}
    >
      <div className="flex">
        <i className={getRoutineIcon(routine.name!)} />
        <section className={styles.routineDetails}>
          <div className="mb-[12px] font-semibold">{routine.name}</div>
          {!showFullInstructions && (
            <AbbreviatedDescription routine={routine} />
          )}
          {showFullInstructions && <FullDescription routine={routine} />}
          {!showFullInstructions && (
            <div>
              <ButtonLink
                onClick={(e) => {
                  e.stopPropagation()
                  setShowFullInstructions(true)
                }}
                className="mt-[8px] text-left leading-[20px]"
              >
                Show full instructions...
              </ButtonLink>
            </div>
          )}
          {isActiveRoutine(routine) && routine.timing && (
            <div className="mt-[12px]">
              <RoutineBadge className="mr-[8px]">
                {timingToEvery(routine.timing)}
              </RoutineBadge>
              {timingToDays(routine.timing).map((day, i) => (
                <RoutineBadge className="mr-[8px]" key={`day-${i}`}>
                  {day}
                </RoutineBadge>
              ))}
              {timingToTimeString(routine.timing).map((time, i) => (
                <RoutineBadge className="mr-[8px]" key={`time-${i}`}>
                  {time}
                </RoutineBadge>
              ))}
              {timingToShiftName(routine.timing)?.map((time, i) => (
                <RoutineBadge className="mr-[8px]" key={`shift-${i}`}>
                  <i className={`fa ${time.icon} mr-[8px]`} />
                  {time.name}
                </RoutineBadge>
              ))}
            </div>
          )}
        </section>
      </div>
      <div className="flex shrink-0">
        <PersonPermissionGate
          person={person}
          permissions={[GroupPermission.GROUP_PERMISSION_ROUTINE_UPDATE]}
        >
          {(isDraftRoutine(routine) || isDiscardedRoutine(routine)) && (
            <AsyncIconButton
              className={scheduleBtnStyles}
              initialIcon="fa-calendar-check"
              buttonStyle="primary-outline"
              buttonSize="small"
              onClick={() => {
                history.push(
                  schedulePathForPerson(person as Required<Person>, routine)
                )
              }}
            >
              Schedule
            </AsyncIconButton>
          )}

          {isDraftRoutine(routine) && (
            <AsyncIconButton
              buttonStyle="secondary-outline"
              buttonSize="small"
              disabled={isDiscarding}
              isLoading={isDiscarding}
              onClick={async () => {
                try {
                  setIsDiscarding(true)

                  await updateRoutine({
                    person,
                    routine: {
                      id: routine.id,
                      status: RoutineStatus.ROUTINE_STATUS_DISCARDED,
                    },
                  })

                  await onUpdate()
                } catch (e) {
                  setError(e)
                }
              }}
            >
              Not Now
            </AsyncIconButton>
          )}
        </PersonPermissionGate>
      </div>
    </div>
  )
}
