import {
  Contact,
  Contact_ContactRelationship,
} from '@augusthealth/models/com/august/protos/contact'
import { useEffect, useState } from 'react'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import { Snapshot } from '@shared/types/snapshot'
import { GtkyFields } from '@shared/utils/gtky'
import { getFullName } from '@shared/utils/humanName'
import { getOrElse, Loading } from '@shared/utils/loading'

export const useReligiousAdvisors = ({
  contacts,
  snapshot,
  saveReligiousAdvisors,
}: {
  contacts: Loading<Contact[]>
  snapshot: Snapshot
  saveReligiousAdvisors: (advisors: OptionTypeBase<Contact>[]) => Promise<void>
}) => {
  const [selectedReligiousAdvisors, setSelectedReligiousAdvisors] = useState<
    { label: string; value: Contact }[]
  >(defaultReligiousAdvisorsFromContacts(getOrElse(contacts, []), snapshot))
  const [religiousAdvisorOptions, setReligiousAdvisorOptions] =
    useState<{ label: string; value: Contact }[]>()

  useEffect(() => {
    if (contacts.tag === 'Complete') {
      const advisorsFromContacts = contacts.value.filter((ct) =>
        ct?.relationship?.includes(
          Contact_ContactRelationship.CONTACT_RELATIONSHIP_RELIGIOUS_ADVISOR
        )
      )
      if (advisorsFromContacts.length > 0) {
        if (religiousAdvisorOptions) {
          // set new RA contact as selected in dropdown if they were added via this form
          const contactJustCreated = advisorsFromContacts.filter(
            (contact) =>
              !religiousAdvisorOptions.some((og) => contact.id === og.value!.id)
          )

          if (contactJustCreated.length > 0) {
            const advisorsWithNewlyCreatedContact: OptionTypeBase<Contact>[] = [
              ...selectedReligiousAdvisors,
              {
                label: getFullName(contactJustCreated[0].name!),
                value: contactJustCreated[0],
              },
            ]
            setSelectedReligiousAdvisors(advisorsWithNewlyCreatedContact)
            void saveReligiousAdvisors(advisorsWithNewlyCreatedContact)
          }
        }

        const mappedOptions = advisorsFromContacts.map((ra) => ({
          label: getFullName(ra.name!),
          value: ra,
        }))
        setReligiousAdvisorOptions(mappedOptions)
      } else {
        setReligiousAdvisorOptions([])
      }
    }
  }, [contacts])

  return {
    religiousAdvisorOptions,
    selectedReligiousAdvisors,
    setSelectedReligiousAdvisors,
  }
}

function defaultReligiousAdvisorsFromContacts(
  contacts: Contact[],
  snapshot: Snapshot
) {
  const advisorsFromContacts = contacts.filter((ct) =>
    ct?.relationship?.includes(
      Contact_ContactRelationship.CONTACT_RELATIONSHIP_RELIGIOUS_ADVISOR
    )
  )

  const savedContactIds =
    snapshot.data?.formData?.oneToManyFields?.[GtkyFields.RELIGIOUS_ADVISOR]
      ?.values || []
  // prefill with contacts that were previously saved
  const savedAdvisors = advisorsFromContacts.filter((opt) =>
    savedContactIds.includes(opt.id!)
  )
  const defaultAdvisors =
    savedAdvisors.length > 0
      ? savedAdvisors.map((adv) => ({
          label: getFullName(adv.name!),
          value: adv,
        }))
      : []

  return defaultAdvisors
}
