import {
  ChangeEvent,
  ForwardedRef,
  KeyboardEvent,
  useEffect,
  useState,
} from 'react'
import {
  BasicInput,
  BasicInputProps,
  BasicTextarea,
  BasicTextAreaProps,
} from '@shared/components/BasicInput/BasicInput'
import {
  EmailInput,
  FaxInput,
} from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import PhoneInput from '@app/components/PhoneInputWithType/PhoneInputInGenerator'
import ElementHolder, { Props as ElementHolderProps } from '../ElementHolder'

export enum InputType {
  TEXT_INPUT_TYPE_EMAIL = 'email',
  TEXT_INPUT_TYPE_FAX = 'fax',
  TEXT_INPUT_TYPE_PHONE = 'phone',
  TEXT_INPUT_TYPE_TEXT = 'text',
  TEXT_INPUT_TYPE_TEXTAREA = 'textarea',
}

export type Props = (BasicInputProps | BasicTextAreaProps) &
  Omit<ElementHolderProps, 'children'> & {
    data?: object
    type?: InputType
    onUpdate: (value?: string, name?: string) => void
    isRequired?: boolean
    visibility?: boolean
    onChange?: (value?: string, name?: string) => void
  }

export default function TextInput(props: Props) {
  const {
    data,
    onUpdate,
    isRequired = false,
    visibility = true,
    onChange,
    ...restProps
  } = props
  const {
    name,
    type = InputType.TEXT_INPUT_TYPE_TEXT,
    value,
    readOnly,
    disabled,
    className: extraClassName,
  } = props
  const [inputValue, setInputValue] = useState(value)
  const [newValue, setNewValue] = useState(value)

  useEffect(() => {
    setInputValue(value)
    setNewValue(value)
  }, [value])

  if (visibility === false) {
    return null
  }

  const classNameList: string[] = []
  if (extraClassName) {
    classNameList.push(extraClassName)
  }
  if (disabled) {
    classNameList.push('disabled')
  }
  const className = classNameList.join(' ')

  function update(value?: string) {
    if (value !== newValue) {
      const updatedValue = value ? value : undefined
      if (isRequired && updatedValue === undefined) {
        setNewValue(newValue)
        setInputValue(newValue)
      } else {
        setNewValue(updatedValue)

        if (typeof onUpdate === 'function') {
          onUpdate(updatedValue, name)
        }
      }
    }
  }

  function change(value?: string) {
    setInputValue(value)

    if (typeof onChange === 'function') {
      onChange(value, name)
    }
  }

  const uiProps = {
    ...restProps,
    className,
    value: inputValue,
    onBlur: (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      // Prevent click-in and click-out without any changes triggering onUpdate
      if ((ev.target.value || value) && ev.target.value !== value) {
        update(ev.target.value)
      }
    },
    onKeyUp: (ev: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      ev.key === 'Enter' &&
        !ev.shiftKey &&
        update((ev.target as HTMLInputElement | HTMLTextAreaElement).value)
    },
    onChange: (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      change(ev.target.value)
    },
    autoComplete: 'off',
    autoCorrect: 'off',
  }
  let component
  if (readOnly) {
    component = <div>{value}</div>
  } else if (type === InputType.TEXT_INPUT_TYPE_TEXTAREA) {
    const textareaProps = uiProps as BasicTextAreaProps & {
      ref?: ForwardedRef<HTMLTextAreaElement>
    }
    component = <BasicTextarea {...textareaProps} />
  } else {
    const inputProps = uiProps as BasicInputProps
    if (type === InputType.TEXT_INPUT_TYPE_EMAIL) {
      component = <EmailInput inputProps={inputProps} />
    } else if (type === InputType.TEXT_INPUT_TYPE_FAX) {
      component = <FaxInput inputProps={inputProps} />
    } else if (type === InputType.TEXT_INPUT_TYPE_PHONE) {
      component = (
        <PhoneInput
          {...inputProps}
          data={data}
          onChange={change}
          onUpdate={update}
        />
      )
    } else {
      component = <BasicInput {...inputProps} />
    }
  }

  return (
    <ElementHolder {...props} value={newValue}>
      {component}
    </ElementHolder>
  )
}
