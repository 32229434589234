import React from 'react'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { DetailModalMode } from '@shared/types/assessment_configuration'
import {
  DetailWithDropdown,
  FrontendDetail,
} from '@app/pages/Tasks/ResidentAssessment/AssessmentPage/CheckboxSections/utils'
import styles from '../styles.module.css'
import { EditRemoveButtons } from '../EditRemoveButtons'
import { REMOVED } from '../helpers'

export type Props = {
  question: DetailWithDropdown
  updateDetail: (eventDetails: {
    value: string | number
    index: number
    key: string
    optIndex?: number
    toUpdate: 'description' | 'score' | 'meta-description'
  }) => void
  index: number
  detailGroupKey: string
  setShowAddEditModal: (mode: DetailModalMode | undefined) => void
  editDetailFromModal: ({
    updatedDetail,
    index,
    optIndex,
  }: {
    updatedDetail: Partial<FrontendDetail>
    index: number
    optIndex?: number
  }) => void
  removeDetail: ({
    index,
    optIndex,
    key,
  }: {
    index: number
    optIndex?: number
    key: string
  }) => void
  disabled: boolean
}
export const EditDropdown = ({
  question,
  updateDetail,
  index,
  detailGroupKey,
  setShowAddEditModal,
  editDetailFromModal,
  removeDetail,
  disabled,
}: Props) => {
  const remove = ({
    index,
    optIndex,
  }: {
    index: number
    optIndex?: number
  }) => {
    removeDetail({ index, optIndex, key: detailGroupKey })
  }

  const onParentEdit = () => {
    setShowAddEditModal({
      tag: 'edit',
      currentDetail: question,
      editDetail: editDetailFromModal,
      index,
    })
  }

  const onParentRemove = () => {
    remove({
      index,
    })
  }

  return (
    <>
      <EditRemoveButtons
        onEdit={onParentEdit}
        onRemove={onParentRemove}
        disabled={disabled}
      />
      <BasicInput
        type={'text'}
        value={question.value.detailMetadata.description}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateDetail({
            value: e.target.value,
            index,
            key: detailGroupKey,
            toUpdate: 'meta-description',
          })
        }
        className={styles.fullGrid}
        data-testid={`${question.value.detailMetadata.name}-metadata`}
        disabled={disabled}
      />
      <div>{/* intentionally left blank to accommodate spacing in grid */}</div>
      {question.value.options.map((opt, optIndex) => {
        if (opt.tempId === REMOVED) {
          return null
        }

        return (
          <React.Fragment
            key={`${optIndex}-dropdownOption-${opt.group}-${opt.answerGroup}`}
          >
            <div>
              {/* intentionally left blank to accommodate spacing in grid */}
            </div>
            <BasicInput
              type={'text'}
              value={opt.description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateDetail({
                  value: e.target.value,
                  index,
                  key: detailGroupKey,
                  optIndex,
                  toUpdate: 'description',
                })
              }
              containerClassName={styles.nestedOptions}
              id={`${question.value.detailMetadata.name}-${optIndex}-description`}
              data-testid={`${question.value.detailMetadata.name}-${optIndex}-description`}
              disabled={disabled}
            />
            <BasicInput
              type={'number'}
              value={opt.score}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                updateDetail({
                  value: e.target.value,
                  index,
                  key: detailGroupKey,
                  optIndex,
                  toUpdate: 'score',
                })
              }
              className={styles.scoreInput}
              data-testid={`${question.value.detailMetadata.name}-${optIndex}-score`}
              disabled={disabled}
            />
          </React.Fragment>
        )
      })}
    </>
  )
}
