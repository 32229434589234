import { isEmpty } from '@augusthealth/august-frontend-form-elements'
import { Task, TaskType } from '@augusthealth/models/com/august/protos/task'
import { ReactNode, useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useUserContext } from '@shared/contexts/UserContext'
import {
  documentsPathForPerson,
  getTasksUrl,
  taskPathForPerson,
} from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import { UserAccount } from '@shared/types/user'
import {
  getCanEditAndReview,
  getCanReview,
  getIsComplete,
  shouldRedirectToSignPage,
} from '@shared/utils/task'
import PersonNavbarLayout from '@app/components/Layouts/PersonNavbarLayout'
import { tabFromUrl } from '@app/components/Layouts/PersonNavbarLayout/Tabs'
import PersonContext from '@app/contexts/PersonContext'
import GettingToKnowYouV2 from '@app/pages/Tasks/GettingToKnowYouV2'
import InitialCareAppraisal from '@app/pages/Tasks/ResidentAssessment'
import ServicePlan from '@app/pages/Tasks/ServicePlan'
import Tasks from '../Tasks'
import { Props } from '../Tasks/type'
import CustomForm from './CustomForm'
import './forms.css'
import '@shared/styles/button.css'
import Review from '../Review'
import Sign from '../Sign'
import Form601 from './601'
import Form603 from './603'
import FormAdmissionsAgreement from './AdmissionsAgreement'
import Consent from './Consent'
import GettingToKnowYou from './GettingToKnowYou'
import LevelOfCare from './LevelOfCare'
import OnlySignForm from './OnlySign'
import TaskLoader from './TaskLoader'

const PATH_PREFIX = `${getTasksUrl({
  orgId: ':orgId',
  facilityId: ':facilityId',
  personId: ':id',
})}`
/**
 * Building path for redirection, for example:
 * from /orgs/1/facilities/1/prospects/1002/tasks/ca_601
 * to /orgs/1/facilities/1/prospects/1002/tasks/20
 */
function getPaths(formCode: string) {
  return [`${PATH_PREFIX}/${formCode}/:page`, `${PATH_PREFIX}/${formCode}`]
}
const PROSPECT_FORM_DEFAULT_PATHS = getPaths(':taskId')

export const CONFIG = {
  TASK_TYPE_CA_FORM_601: {
    Ui: Form601,
    paths: getPaths('CA_601'),
    formCode: 'CA_601',
  },
  TASK_TYPE_CA_FORM_603: {
    Ui: Form603,
    paths: getPaths('CA_603'),
    formCode: 'CA_603',
  },
  TASK_TYPE_CA_FORM_603A: {
    Ui: Form603,
    paths: getPaths('CA_603A'),
    formCode: 'CA_603A',
  },
  TASK_TYPE_ADMISSIONS_AGREEMENT: {
    Ui: FormAdmissionsAgreement,
    paths: getPaths('admissions_agreement'),
    formCode: 'admissions_agreement',
  },
  TASK_TYPE_GETTING_TO_KNOW_YOU: {
    Ui: GettingToKnowYou,
    paths: getPaths('getting_to_know_you'),
    formCode: 'getting_to_know_you',
  },
  TASK_TYPE_LEVEL_OF_CARE: {
    Ui: LevelOfCare,
    paths: getPaths('level_of_care'),
    formCode: 'level_of_care',
  },
  TASK_TYPE_AUGUST_INITIAL_APPRAISAL: {
    Ui: InitialCareAppraisal,
    paths: getPaths('august_initial_appraisal'),
    formCode: 'august_initial_appraisal',
  },
  TASK_TYPE_SERVICE_PLAN: {
    Ui: ServicePlan,
    paths: getPaths('service_plan'),
    formCode: 'service_plan',
  },
  TASK_TYPE_CA_CONSENT_FORMS: {
    Ui: Consent,
    paths: getPaths('consent'),
    formCode: 'consent',
  },
  TASK_TYPE_CUSTOM_SIGNABLE_FORM: {
    Ui: CustomForm,
    paths: [],
  },
  TASK_TYPE_GETTING_TO_KNOW_YOU_V2: {
    Ui: GettingToKnowYouV2,
    paths: getPaths('getting_to_know_you_v2'),
    formCode: 'getting_to_know_you_v2',
  },
  TASK_TYPE_VA_UAI: {
    Ui: OnlySignForm,
    paths: [],
  },
}

function getRoutes({
  taskId,
  tasks,
  user,
  pId,
  person,
}: {
  taskId: string
  tasks: Task[]
  user: UserAccount
  pId: string
  person: Person
}) {
  const routes = tasks.reduce((list: ReactNode[], task) => {
    const { id: tId, taskTemplateInfo: template } = task
    const { taskType } = template || {}
    const config = taskType && CONFIG[taskType]

    // Redirect formCode with taskId
    if (config) {
      const { paths } = config
      if (paths.length === 2) {
        const tIdPaths = getPaths(tId || '')
        const key1 = `redirect-${taskType}-with-page-number`
        const key2 = `redirect-${taskType}-without-page-number`
        list.push(
          <Redirect key={key1} exact from={paths[0]} to={tIdPaths[0]} />
        )
        list.push(
          <Redirect key={key2} exact from={paths[1]} to={tIdPaths[1]} />
        )
      }
    }

    // Handle uploadable tasks: Either redirect to the documents page or render the upload page
    if (task.taskTemplateInfo?.isUpload) {
      const isComplete = getIsComplete(task)
      if (
        isComplete &&
        task?.taskTemplateInfo?.taskType !==
          TaskType.TASK_TYPE_IMMUNIZATION_RECORD
      ) {
        // Do not redirect when TASK_TYPE_IMMUNIZATION_RECORD, so user can keep edit after click Add or Trash in VaccineViewer
        list.push(
          <Redirect
            from={taskPathForPerson(person as Required<Person>, task.id!)}
            to={documentsPathForPerson(person as Required<Person>)}
          />
        )
      } else {
        list.push(
          <Route
            key="upload-with-task-id"
            exact
            path={getPaths(tId || '')}
            render={(props: Props) => (
              <div>
                <PersonNavbarLayout activeTab={tabFromUrl(props.match.url)} />
                <Tasks {...props} uploadPopupTask={task} />
              </div>
            )}
          />
        )
      }
    }

    return list
  }, [])

  routes.push(
    <Route
      key="route-form-with-task-id"
      exact
      path={PROSPECT_FORM_DEFAULT_PATHS}
      render={(props: Props) => {
        const { facilityId, orgId, page: pageNumber } = props.match.params
        const currentTask = tasks.find((t: Task) => t.id === taskId)

        if (currentTask) {
          const { taskTemplateInfo: template = {} } = currentTask
          const { displayName, shortName } = template
          const canReview = getCanReview({ task: currentTask, user })
          const canEdit =
            getCanEditAndReview({ task: currentTask, user, person }) &&
            pageNumber !== undefined

          if (canReview && !canEdit) {
            return <Review {...props} />
          } else if (
            shouldRedirectToSignPage({
              task: currentTask,
              user,
              pId,
              orgId: orgId!,
              facilityId: facilityId!,
            })
          ) {
            return (
              <Sign
                {...props}
                title={null}
                match={{
                  ...props.match,
                  params: {
                    ...props.match.params,
                    taskId: props.match.params.taskId!,
                  },
                }}
              />
            )
          }

          const configElement = template.taskType && CONFIG[template.taskType]

          if (configElement) {
            const Ui = configElement.Ui
            let additionalProps = {}
            if (template.taskType === TaskType.TASK_TYPE_CUSTOM_SIGNABLE_FORM) {
              additionalProps = {
                defaultForm: null,
                dataType: DataType.DATA_TYPE_CUSTOM_SIGNABLE_FORM,
                customType: template.customType,
                taskType: template.taskType,
              }
            }

            if (Ui) {
              return (
                <div>
                  <Ui
                    title={displayName}
                    subTitle={shortName}
                    {...props}
                    {...additionalProps}
                  />
                </div>
              )
            }
          } else {
            return <Redirect to="/" />
          }
        }

        // In case the application routes to a Task URL
        // that isn't in our local task cache
        // We should attempt to load it from the server
        return <TaskLoader formProps={props} />
      }}
    />
  )

  return routes
}

function ProspectForms(props: Props) {
  const { person = {} as Person, tasks = [] } = useContext(PersonContext)
  const { user } = useUserContext()
  if (isEmpty(person) || isEmpty(tasks)) {
    return 'Loading...'
  }

  const { match } = props
  const { params } = match
  const { id: pId, taskId } = params

  const task = tasks.find((t) => t.id === taskId)

  // Completed immunization records do not push to documents page so that the user can continue to edit/add using VaccineViewer
  if (
    task &&
    getIsComplete(task) &&
    task.taskTemplateInfo?.taskType !== TaskType.TASK_TYPE_IMMUNIZATION_RECORD
  ) {
    return (
      <Redirect
        from={taskPathForPerson(person as Required<Person>, task.id!)}
        to={documentsPathForPerson(person)}
      />
    )
  }

  return (
    <Switch>{getRoutes({ taskId: taskId!, tasks, user, pId, person })}</Switch>
  )
}

export default ProspectForms
