import { useEffect, useState } from 'react'
import { FeatureFlagNames } from '@shared/constants/feature_flags'
import { useUserContext } from '@shared/contexts/UserContext'
import { RoutineOrder } from '@shared/types/routine_order'
import { AugustError } from '@shared/utils/error'
import { AsyncResult } from '@shared/utils/loading'
import { isFeatureAllowed } from '@shared/utils/user'
import { getRoutineOrder } from '@app/api/routineOrders'

export default function useRoutineOrder({
  orgId,
  facilityId,
  personId,
  routineId,
  setError,
}: {
  orgId: string
  facilityId: string
  personId: string
  routineId: string
  setError: (error: unknown) => void
}) {
  const [routineOrder, setRoutineOrder] = useState<
    AsyncResult<RoutineOrder, AugustError>
  >({
    tag: 'Loading',
  })

  const { user } = useUserContext()

  const includesAssessmentRoutineOrder = isFeatureAllowed(
    user,
    FeatureFlagNames.ASSESSMENT_ROUTINES_DEBUG
  )

  useEffect(() => {
    setRoutineOrder({ tag: 'Loading' })
    void getRoutineOrder({ orgId, facilityId, id: personId }, routineId, {
      includesAssessmentRoutineOrder,
    }).then((response) => {
      if (response.tag === 'Error') {
        setError(response.value)
      }
      setRoutineOrder(response)
    })
  }, [personId, facilityId, orgId, routineId])

  return { routineOrder }
}
