import { AppraisalSettings_AppraisalCategory } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import Badge from '@shared/components/Badge'
import Icon from '@shared/components/Icon'
import { HasCategoryId, RoutineOrder } from '@shared/types/routine_order'
import { ServicePlan_PlanCategoryKey } from '@shared/types/service_plan'
import { ROUTINE_NAME_ICONS } from '../components/helpers'

const DEFAULT_ROUTINE_CATEGORY_ICON = 'list-check'

function getAppraisalCategory({
  categories,
  categoryKeys,
}: {
  categories: AppraisalSettings_AppraisalCategory[]
  categoryKeys: HasCategoryId
}) {
  const { categoryKey, customKey } = categoryKeys
  switch (categoryKey) {
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_CUSTOM:
      return categories.find((c) => {
        return c.categoryKey === categoryKey && c.customKey === customKey
      })
    default:
      return categories.find((c) => {
        return c.categoryKey === categoryKey
      })
  }
}

function AppraisalCategoryIcon({
  appraisalCategory,
}: {
  appraisalCategory?: AppraisalSettings_AppraisalCategory
}) {
  if (!appraisalCategory) return null
  let faIcon = ''
  switch (appraisalCategory.categoryKey) {
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_BATHING:
      faIcon = 'fa-bath'
      break
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_TOILETING:
      faIcon = 'fa-toilet'
      break
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_TRANSFER_AND_MOBILITY:
      faIcon = 'fa-wheelchair'
      break
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_MEALS_AND_NUTRITION:
      faIcon = 'fa-fork-knife'
      break
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_SPECIAL_CARE_NEEDS:
      faIcon = 'fa-hand-heart'
      break
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_SLEEP:
      faIcon = 'fa-snooze'
      break
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_TRANSFER:
      faIcon = 'fa-arrow-up-from-line'
      break
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_MOBILITY:
      faIcon = 'fa-wheelchair'
      break
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_COGNITIVE:
      faIcon = 'fa-head-side-brain'
      break
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_DENTAL:
      faIcon = 'fa-tooth'
      break
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_ADDITIONAL_SERVICES:
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_CARE_COORDINATION:
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_COMMUNICATION:
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_CUSTOM:
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_DISPOSITION_AND_BEHAVIORS:
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_DRESSING:
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_GROOMING:
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_HEARING:
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_MEDICATION_MANAGEMENT:
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_PSYCHO_SOCIAL_ENGAGEMENT:
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_UNSPECIFIED:
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_VISION:
    case ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_WANDERING_AND_ELOPEMENT:
    case ServicePlan_PlanCategoryKey.UNRECOGNIZED:
    default:
      faIcon = DEFAULT_ROUTINE_CATEGORY_ICON
  }

  return <Icon name={faIcon} />
}

function LegacyRoutineCategoryIcon({ routineName }: { routineName: string }) {
  const icon = ROUTINE_NAME_ICONS[routineName] || DEFAULT_ROUTINE_CATEGORY_ICON
  return <Icon name={icon} />
}

export default function AppraisalCategoryBadge({
  categories,
  routineOrder,
}: {
  categories: AppraisalSettings_AppraisalCategory[]
  routineOrder: RoutineOrder
}) {
  if (routineOrder.routineType.custom) {
    const appraisalCategory = getAppraisalCategory({
      categories,
      categoryKeys: routineOrder,
    })
    const title =
      appraisalCategory?.categoryOptions?.title || 'Unknown category'

    return (
      <Badge shape="square" color="gray" className="flex gap-2">
        <AppraisalCategoryIcon appraisalCategory={appraisalCategory} />
        {title}
      </Badge>
    )
  } else if (routineOrder.routineType.assessment) {
    return (
      <Badge shape="square" color="gray" className="flex gap-2">
        <LegacyRoutineCategoryIcon routineName={routineOrder.name} />
      </Badge>
    )
  }

  return undefined
}
