import { useContext, useEffect, useState } from 'react'
import {
  completeResidentBillingEvent,
  fetchResidentBillingEvents,
} from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import { BillingEvent } from '@shared/types/billing'
import { RequiredPersonIds } from '@shared/types/person'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

export default function useResidentBillingEvents(props: RequiredPersonIds) {
  const { orgId, facilityId, id: personId } = props
  const { setError } = useContext(GlobalContext)
  const [billingEvents, setBillingEvents] =
    useState<Loading<BillingEvent[]>>(LOADING)

  async function reloadBillingEvents() {
    setBillingEvents(LOADING)
    try {
      const newBillingEvents = await fetchResidentBillingEvents(props)
      setBillingEvents(loaded(newBillingEvents))
    } catch (e) {
      setError(e)
    }
  }

  async function completeAndReload(eventId: string) {
    try {
      await completeResidentBillingEvent(props, eventId)
      await reloadBillingEvents()
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    void reloadBillingEvents()
  }, [orgId, facilityId, personId])

  return {
    billingEvents,
    completeAndReload,
    reloadBillingEvents,
  }
}
