import { useContext } from 'react'
import ConnectionProblems from '@shared/components/ErrorModal/ConnectionProblems'
import ExpectedErrorModal from '@shared/components/ErrorModal/ExpectedErrorModal'
import MaintenanceScreen from '@shared/components/ErrorModal/MaintenanceScreen'
import useErrorLogging from '@shared/components/ErrorModal/useErrorLogging'
import { UpdateAppPopup } from '@shared/components/UpdateAppPopup'
import GlobalContext from '@shared/contexts/GlobalContext'
import { ErrorCode } from '@shared/types/api/error_response'
import { UserAccount } from '@shared/types/user'
import {
  AugustError,
  isClientVersionUnsupported,
  isConnectivityError,
  isEtagMismatch,
  isInMaintenanceMode,
} from '@shared/utils/error'
import { twx } from '@shared/utils/tailwind'

export default function ErrorModal({ user }: { user?: UserAccount }) {
  const { error } = useContext(GlobalContext)
  useErrorLogging({ user, error })

  return <ErrorModalBeforeLogin />
}

const ErrorContent = ({ error }: { error: AugustError }) => {
  const { json, status } = error
  const errs = json && json.errors ? json.errors : []
  const errsFiltered = errs.filter(
    (e) => e.code === ErrorCode.ERROR_CODE_DISPLAY_TO_CLIENT
  )

  if (status === 401 || status === 403) {
    return (
      <div>
        You do not have access to this information.
        <br />
        Please try again, or contact &nbsp;
        <a
          className={twx('text-august-primary')}
          href="mailto:support@augusthealth.com"
        >
          support@augusthealth.com{' '}
        </a>
        <br />
        if the problem keeps happening.
      </div>
    )
  } else if (isEtagMismatch(error)) {
    return (
      <div>
        Someone else is editing this form right now. <br />
        Please reload the page and try again.
      </div>
    )
  } else if (isFileTooLargeError(error)) {
    return (
      <div>
        Please reduce the file size and try again, or
        <br />
        contact{' '}
        <a className={twx('email-link')} href="mailto:support@augusthealth.com">
          support@augusthealth.com
        </a>{' '}
        for help.
      </div>
    )
  } else if (errsFiltered.length) {
    // User facing errors
    return (
      <div>
        {errsFiltered.map((e, i) => (
          <div key={`error-row-${i}`}>{e.message}</div>
        ))}
      </div>
    )
  }

  return (
    <div>
      Please try again, or contact{' '}
      <a
        className={twx('text-august-primary')}
        href="mailto:support@augusthealth.com"
      >
        support@augusthealth.com{' '}
      </a>
      if the problem keeps happening.
    </div>
  )
}

function imageForError(e: AugustError) {
  if (isEtagMismatch(e)) {
    return '/images/edit-conflict.png'
  }

  return '/images/sad-people.svg'
}

function messageForError(e: AugustError) {
  if (isEtagMismatch(e)) {
    return 'Somebody else is editing!'
  } else if (isFileTooLargeError(e)) {
    return 'Your file exceeds our 30 MB upload limit'
  } else if (isTaperValidationError(e)) {
    return 'Scheduling Error'
  }

  return 'Something went wrong!'
}

function actionForError(e: AugustError) {
  if (isEtagMismatch(e)) {
    return () => window.location.reload()
  } else if (e.status && [401, 403].includes(e.status)) {
    return () => (window.location.href = '/')
  }

  return undefined
}

function isFileTooLargeError(e: AugustError) {
  return e.status === 413 || e.internalCode === 'FILE_TOO_LARGE'
}

function isTaperValidationError(e: AugustError) {
  return e.name === 'Taper Validation'
}

/**
 * We need to render the ErrorModal before login, so it can't depend on the UserContext.
 */
export function ErrorModalBeforeLogin() {
  const { error, setError } = useContext(GlobalContext)

  if (!error) {
    return null
  }

  if (isConnectivityError(error)) {
    return <ConnectionProblems />
  }

  // TypeError handled above but typescript doesn't know that - casting to AugustError
  const augustError = error as AugustError
  const imgSrc = imageForError(augustError)
  const message = messageForError(augustError)
  const action = actionForError(augustError) ?? (() => setError(undefined))
  const buttonText = isEtagMismatch(augustError) ? 'Reload' : 'Okay'

  if (isClientVersionUnsupported(augustError)) {
    return <UpdateAppPopup />
  }

  if (isInMaintenanceMode(augustError)) {
    return <MaintenanceScreen />
  }

  const imgClasses = twx({
    ['w-[376px]']: !isEtagMismatch(augustError),
    ['w-[104px]']: isEtagMismatch(augustError),
  })

  return (
    <ExpectedErrorModal
      id={'general-error'}
      buttonProps={{
        children: buttonText,
        onClick: action,
      }}
      imgProps={{
        className: imgClasses,
        src: imgSrc,
      }}
      title={message}
    >
      <ErrorContent error={augustError} />
    </ExpectedErrorModal>
  )
}
