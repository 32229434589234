import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { EmptyCard } from '@shared/components/EmptyCard'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { useUserContext } from '@shared/contexts/UserContext'
import PersonPageTitle from '@app/components/PersonPageTitle'
import styles from '@app/components/Prospects/styles.module.css'
import PersonContext from '@app/contexts/PersonContext'
import { useContacts } from '@app/hooks/useContacts'
import { ContactCardSkeleton } from './ContactCard/ContactCardSkeleton'
import { AddContactModal } from './ContactModal/ContactModal'
import ContactsGrid from './Grid'

export default function Contacts() {
  const { user } = useUserContext()
  const { person } = useContext(PersonContext)
  const [openAddContactPopup, setOpenAddContactPopup] = useState(false)

  const { contacts, refreshContacts } = useContacts()

  if (!person) {
    return null
  }

  if (contacts.tag === 'Loading') {
    return (
      <>
        <PersonPageTitle title={'Contacts'} />
        <ContactCardSkeleton />
      </>
    )
  }

  const showContactsGrid = contacts.value.length > 0

  return (
    <>
      <PersonPageTitle title={'Contacts'}>
        <PersonPermissionGate
          person={person}
          permissions={[GroupPermission.GROUP_PERMISSION_PERSON_CONTACT_CREATE]}
        >
          <AsyncIconButton
            buttonStyle={'secondary-fill'}
            className={styles.addContactButton}
            id={'add-contact-button'}
            onClick={() => setOpenAddContactPopup(true)}
            initialIcon={'user-plus'}
          >
            Add Contact
          </AsyncIconButton>
        </PersonPermissionGate>
      </PersonPageTitle>
      {!showContactsGrid && (
        <div>
          <EmptyCard
            title="No contacts yet."
            version={{
              tag: 'GetStarted',
              cta: 'add a new contact',
              ctaFn: () => setOpenAddContactPopup(true),
            }}
          />
        </div>
      )}
      {showContactsGrid && (
        <section className={styles.cardGrid}>
          <h2 className="visually-hidden">Contacts List</h2>
          <ContactsGrid
            contacts={contacts.value}
            user={user}
            person={person}
            refreshContacts={refreshContacts}
          />
        </section>
      )}
      {openAddContactPopup && (
        <AddContactModal
          person={person}
          onClose={async (updated) => {
            if (updated) {
              await refreshContacts()
            }
            setOpenAddContactPopup(false)
          }}
        />
      )}
    </>
  )
}
