import classNames from 'classnames/bind'
import styles from './styles.module.css'
import { BarChartData } from '../helpers'
import { STROKE_COLORS } from '../theme'
import { showPositiveStyle } from './utils'

const cx = classNames.bind(styles)

export default function Label({
  data,
  title = 'Current',
  barColor = STROKE_COLORS[0],
  counterIsPercentage = false,
  hideCounter = false,
  reversePercentageStyle = false,
}: {
  data: BarChartData[]
  title?: string
  barColor?: string
  index: number
  counterIsPercentage?: boolean // add 2 digit after decimal + % sign
  hideCounter?: boolean
  reversePercentageStyle?: boolean
}) {
  if (data.length === 0) {
    return null
  }

  let currentCount: string | number = data[data.length - 1].y
  let percentage
  let percentageClasses
  if (data.length > 1) {
    // TODO - This is a stop gap to prevent hard crash
    const previousCount = data[data.length - 2]?.y ?? 0
    if (data[data.length - 2] === undefined) {
      console.log('Undefined found in data array', {
        data,
        title,
      })
    }
    if (previousCount !== 0) {
      percentage = ((currentCount / previousCount) * 100 - 100).toFixed(2)
      if (percentage > 0) {
        percentage = `+${percentage}`
      }

      const usePositiveStyle = showPositiveStyle(
        percentage,
        reversePercentageStyle
      )
      percentageClasses = cx({
        [styles.overviewPercentage]: true,
        [styles.overviewPercentagePositive]: usePositiveStyle,
        [styles.overviewPercentageNegative]: !usePositiveStyle,
      })
    }
  }

  if (counterIsPercentage) {
    currentCount = `${currentCount.toFixed(2)}%`
  } else {
    currentCount = Math.round(currentCount)
  }

  return (
    <div className={styles.overviewHolder}>
      <div
        className={styles.overviewLine}
        style={{
          borderLeftColor: barColor,
          borderRightColor: barColor,
        }}
      >
        &nbsp;
      </div>
      <div className={styles.overviewContent}>
        <div className={styles.overviewTitle}>{title}</div>
        {!hideCounter && (
          <div className={styles.overviewInfoHolder}>
            <div className={styles.overviewValue}>{currentCount}</div>
            {!hideCounter && percentage && (
              <div className={percentageClasses}>{percentage}%</div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
