import { Popup } from '@augusthealth/august-frontend-form-elements'
import { InvoiceParameters } from '@augusthealth/models/com/august/protos/jobs'
import { useContext, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import Card from '@shared/components/Card'
import GlobalContext from '@shared/contexts/GlobalContext'
import { fromDateToDateMessage, toDatePeriod } from '@shared/utils/date'
import { Loading } from '@shared/utils/loading'
import {
  disconnectFromQuickBooks,
  finishQuickBooksConnection,
  generateInvoices,
  getQuickBooksOAuthUrl,
  isConnectedToQuickBooks,
} from '@app/api/quick_books'
import styles from './styles.module.css'

interface ConnectionProps {
  location: {
    search: string
  }
}

interface QuickBooksProps {
  orgId: string
}

export default function QuickBooks({ orgId }: QuickBooksProps) {
  const [connectedToQuickbooks, setConnectedToQuickbooks] = useState<
    Loading<boolean>
  >({ tag: 'Loading' })

  const { setError } = useContext(GlobalContext)

  useEffect(() => {
    if (orgId) {
      isConnectedToQuickBooks({ orgId })
        .then((value) => setConnectedToQuickbooks({ tag: 'Complete', value }))
        .catch(setError)
    }
  }, [orgId])

  function connect() {
    return getQuickBooksOAuthUrl({ orgId })
      .then((oauthUrl) => (window.location.href = oauthUrl))
      .catch((e) => {
        setError(e)
      })
  }

  function disconnect() {
    setConnectedToQuickbooks({ tag: 'Loading' })
    return disconnectFromQuickBooks({ orgId })
      .then(() => setConnectedToQuickbooks({ tag: 'Complete', value: false }))
      .catch(setError)
  }
  if (connectedToQuickbooks.tag === 'Loading') {
    return <div className="ml-[16px]">Loading...</div>
  } else if (!connectedToQuickbooks.value) {
    return (
      <div className={styles.connect}>
        <ConnectButton connect={connect} />
      </div>
    )
  } else {
    return <QuickBooksConnected orgId={orgId} disconnect={disconnect} />
  }
}

function ConnectButton({ connect }: { connect: () => Promise<any> }) {
  const [disabled, setDisabled] = useState<boolean>(false)

  function onClick() {
    setDisabled(true)
    void connect().then(() => setDisabled(false))
  }

  return (
    <button className={styles.connect} disabled={disabled} onClick={onClick}>
      <img
        src="/svg/quick_books_connect.svg"
        alt="Button that says Connect to QuickBooks"
      />
    </button>
  )
}

interface InvoiceFormData {
  startDate: Date
  endDate: Date
  recurringChargeMonth: Date
}

function QuickBooksConnected({
  orgId,
  disconnect,
}: {
  orgId: string
  disconnect: () => Promise<any>
}) {
  const { setError } = useContext(GlobalContext)
  const [disabled, setDisabled] = useState<boolean>(false)
  const { handleSubmit, control } = useForm<InvoiceFormData>({
    defaultValues: {},
  })

  const [showPopup, setShowPopup] = useState<boolean>(false)

  const onSubmit = async (formData: InvoiceFormData) => {
    const parameters: InvoiceParameters = {
      oneTimeChargePeriod: toDatePeriod(formData.startDate, formData.endDate),
      recurringChargeMonth: fromDateToDateMessage(
        formData.recurringChargeMonth
      ),
    }
    try {
      void generateInvoices({ orgId, parameters }).then(() =>
        setDisabled(false)
      )
      setShowPopup(true)
    } catch (e) {
      setError(e)
    }
  }

  function onClickDisconnect() {
    setDisabled(true)
    void disconnect().then(() => setDisabled(false))
  }

  return (
    <div className="ml-[12px] mr-[12px]">
      <Card className={'flex-col'}>
        {showPopup && (
          <div className="error-popup">
            <Popup
              holderClassName="top-modal"
              contentClassName="no-separator"
              title="Syncing with QuickBooks..."
              defaultOpen={true}
              onClose={() => setShowPopup(false)}
            >
              <div>
                Refresh your QuickBooks page in a few minutes to see your new
                invoices.
              </div>

              <button
                className={`rounded-[6px] bg-button-primary-color px-[12px] py-[11px] text-[11px] font-bold leading-[14px] text-button-fill-font-color hover:brightness-125  ${styles.shareButton}`}
                onClick={() => {
                  setShowPopup(false)
                }}
              >
                CLOSE
              </button>
            </Popup>
          </div>
        )}
        <div className="mb-[16px]">
          Send recurring and one-time charges directly to QuickBooks to generate
          invoices from August Health billing data.
          <br />
          We will create an invoice for each Payer listed as shown on each
          resident's Billing tab.
        </div>
        <div className={'mr-[16px] mt-[8px] flex items-center'}>
          <h3 className={styles.header}>Include one-time charges from</h3>
          <div className="mr-[16px]">
            <Controller
              control={control}
              name="startDate"
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  selected={value}
                  onChange={(v: Date | null) => {
                    onChange(v)
                  }}
                  selectsStart
                  customInput={<CalendarInput />}
                />
              )}
            />
          </div>
          <div className="mr-[16px] font-semibold"> to </div>
          <div className="mr-[16px]">
            <Controller
              control={control}
              name="endDate"
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  className={styles.customInput}
                  selected={value}
                  onChange={(v: Date | null) => {
                    onChange(v)
                  }}
                  selectsStart
                  customInput={<CalendarInput />}
                />
              )}
            />
          </div>
        </div>
        <div className={'mb-[8px] mr-[16px] mt-[8px] flex items-center'}>
          <h3 className={styles.header}>Include recurring charges from</h3>
          <div className={styles.month}>
            <Controller
              control={control}
              name="recurringChargeMonth"
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  className={styles.input}
                  selected={value}
                  onChange={(date) => onChange(date)}
                  placeholderText="Select Month"
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  showFullMonthYearPicker
                />
              )}
            />
          </div>
        </div>
        <div className={`flex items-center ${styles.buttonContainer}`}>
          <hr className="my-[16px]" />
          <div className={styles.header}>
            <button
              className={`rounded-[6px] bg-button-primary-color px-[12px] py-[11px] text-[11px] font-bold leading-[14px] text-button-fill-font-color hover:brightness-125  ${styles.button}`}
              id="generateInvoice"
              onClick={handleSubmit(onSubmit)}
            >
              Generate Invoices
            </button>
          </div>
          <div className={styles.header}>
            <button
              className={`rounded-[6px] bg-button-tertiary-color px-[12px] py-[11px] text-[11px] font-bold leading-[14px] text-button-fill-font-color hover:brightness-125 ${styles.button}`}
              disabled={disabled}
              onClick={onClickDisconnect}
            >
              Disconnect From QuickBooks
            </button>
          </div>
        </div>
      </Card>
    </div>
  )
}

export function ConnectToQuickBooks({ location }: ConnectionProps) {
  const params = new URLSearchParams(location.search)
  const { setError } = useContext(GlobalContext)
  const history = useHistory()
  useEffect(() => {
    const state = params.get('state')
    const jsonState: { csrf: string; orgId: string } = JSON.parse(atob(state!))
    finishQuickBooksConnection({
      orgId: jsonState.orgId,
      accessCode: params.get('code'),
      realmId: params.get('realmId'),
    })
      .then(() => history.push(`/orgs/${jsonState.orgId}/settings`))
      .catch(setError)
  }, [])
  return <div className="ml-[16px]">Connecting to QuickBooks</div>
}
