import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { Redirect, Route, Switch } from 'react-router-dom'
import GatedRoute from '@shared/components/Auth/GatedRoute'
import { useUserContext } from '@shared/contexts/UserContext'
import { isDirector as getIsDirector } from '@shared/utils/user'
import CommunitiesPage from './CommunitiesPage'
import GeneralPage from './GeneralPage'
import {
  ORGANIZATIONS_SETTINGS_COMMUNITIES_PATH,
  ORGANIZATIONS_SETTINGS_GENERAL_PATH,
  ORGANIZATIONS_SETTINGS_PATH,
  ORGANIZATIONS_SETTINGS_USERS_PATH,
  Props,
} from './type'
import UsersPage from './UsersPage'

/**
 * Director has access to Communities and Users tab, default to Communities Tab
 * Org Admin and Super User has access to General, Communities and Users tab, default to General Tab
 */
export default function Settings({
  match: {
    params: { orgId },
  },
}: Props) {
  const communitiesPath = ORGANIZATIONS_SETTINGS_COMMUNITIES_PATH.replace(
    ':orgId',
    orgId
  )
  const { user } = useUserContext()
  const isDirector = getIsDirector({ user, orgId })
  return (
    <Switch key="org-settings-switch">
      {isDirector && (
        <>
          <Route
            exact
            path={ORGANIZATIONS_SETTINGS_PATH}
            component={CommunitiesPage}
          />
          <Redirect
            exact
            from={ORGANIZATIONS_SETTINGS_GENERAL_PATH}
            to={communitiesPath}
          />
          <Route
            exact
            path={ORGANIZATIONS_SETTINGS_COMMUNITIES_PATH}
            component={CommunitiesPage}
          />
          <Route
            exact
            path={ORGANIZATIONS_SETTINGS_USERS_PATH}
            component={UsersPage}
          />
        </>
      )}
      {!isDirector && (
        <>
          <GatedRoute
            exact
            minimalPermissions={[
              GroupPermission.GROUP_PERMISSION_ORGANIZATION_READ,
            ]}
            path={ORGANIZATIONS_SETTINGS_GENERAL_PATH}
            component={GeneralPage}
          />
          <GatedRoute
            exact
            minimalPermissions={[
              GroupPermission.GROUP_PERMISSION_ORGANIZATION_READ,
            ]}
            path={ORGANIZATIONS_SETTINGS_PATH}
            component={GeneralPage}
          />
          <GatedRoute
            exact
            minimalPermissions={[
              GroupPermission.GROUP_PERMISSION_ORGANIZATION_READ,
            ]}
            path={ORGANIZATIONS_SETTINGS_COMMUNITIES_PATH}
            component={CommunitiesPage}
          />
          <GatedRoute
            exact
            minimalPermissions={[
              GroupPermission.GROUP_PERMISSION_ORGANIZATION_USER_READ,
            ]}
            path={ORGANIZATIONS_SETTINGS_USERS_PATH}
            component={UsersPage}
          />
        </>
      )}
    </Switch>
  )
}
