import { Contact } from '@augusthealth/models/com/august/protos/contact'
import React, { useEffect } from 'react'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { updateContact } from '@app/api/contacts'
import useRainforestPaymentMethodConfig from '@app/hooks/useRainforestPaymentMethodConfig'

/**
    TODO:
    1. Can we pass Person & Contact instead of IDs?
*/
export type Params = {
  orgId: string
  facilityId: string
  personId: string
  contact: Contact
  closeFn: () => void
  reloadBillingSummary: () => void
}

type SavedPaymentMethodEvent = {
  data: SavedPaymentMethod
}

type SavedPaymentMethod = {
  payment_method_id: string
  method_type: string
  ach: ACH
}

type ACH = {
  account_number_last_4?: string
  routing_number?: string
  bank_name?: string
  account_type?: string
  account_holder_type?: string
}

export default function PaymentMethod({
  orgId,
  facilityId,
  personId,
  contact,
  closeFn,
  reloadBillingSummary,
}: Params) {
  return (
    <Modal id="add-charge-modal" onClose={closeFn} open>
      <AddPaymentMethod
        orgId={orgId}
        facilityId={facilityId}
        personId={personId}
        contact={contact}
        closeFn={closeFn}
        reloadBillingSummary={reloadBillingSummary}
      />
    </Modal>
  )
}

function AddPaymentMethod({
  orgId,
  facilityId,
  personId,
  contact,
  closeFn,
  reloadBillingSummary,
}: Params) {
  const { paymentMethodConfig } = useRainforestPaymentMethodConfig({
    orgId,
    facilityId,
    personId,
    contactId: contact.id!,
  })

  if (paymentMethodConfig.tag === 'Loading') {
    return 'Loading...'
  }

  function savePaymentMethod(
    data: CustomEvent<Array<SavedPaymentMethodEvent>>
  ) {
    const paymentData = data.detail[0].data
    const updatedContact: Contact = {
      id: contact.id,
      externalFields: {
        rainforestPaymentMethod: {
          paymentMethodId: paymentData.payment_method_id,
          methodType: paymentData.method_type,
          ach: {
            accountNumberLast4: paymentData.ach.account_number_last_4,
            routingNumber: paymentData.ach.routing_number,
            bankName: paymentData.ach.bank_name,
            accountType: paymentData.ach.account_type,
            accountHolderType: paymentData.ach.account_holder_type,
          },
        },
      },
    }

    // TODO: Error handling
    void updateContact({
      contact: updatedContact,
      orgId,
      facilityId,
      personId,
    }).then(() => {
      reloadBillingSummary()
      closeFn()
    })
  }

  return (
    <RainforestPayment
      sessionKey={paymentMethodConfig.value.sessionKey}
      paymentMethodConfigId={paymentMethodConfig.value.paymentMethodConfigId}
      savePaymentMethod={savePaymentMethod}
    />
  )
}

function RainforestPayment({
  sessionKey,
  paymentMethodConfigId,
  savePaymentMethod,
}: {
  sessionKey: string
  paymentMethodConfigId: string
  savePaymentMethod: (data: CustomEvent<Array<SavedPaymentMethodEvent>>) => void
}) {
  useEffect(() => {
    const embeddedComponent = document.querySelector('rainforest-payment')
    if (embeddedComponent) {
      embeddedComponent.addEventListener('approved', savePaymentMethod)
    }

    return () => {
      if (embeddedComponent) {
        embeddedComponent.removeEventListener('approved', savePaymentMethod)
      }
    }
  }, [paymentMethodConfigId])

  return React.createElement('rainforest-payment', {
    'session-key': sessionKey,
    'payment-method-config-id': paymentMethodConfigId,
    'allowed-methods': 'ACH',
    'style-button-color': '#007580',
    'style-font-family': 'Inter',
  })
}
