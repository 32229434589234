import {
  GroupPermission,
  GroupType,
} from '@augusthealth/models/com/august/protos/permission'
import { useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import { DotDivider } from '@shared/components/DotDivider'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { Person } from '@shared/types/person'
import { formatDate } from '@shared/utils/date'
import { getFirstAndLastName } from '@shared/utils/humanName'
import Banner from '@app/components/Banner'
import MarkAsAwayPopup from '@app/components/Residents/MoveOutDetails/MarkAsAwayPopup'

export default function OutOfCommunityBanner({ person }: { person: Person }) {
  const [showPopup, setShowPopup] = useState(false)
  const [includeEndDate, setIncludeEndDate] = useState(false)

  return (
    <>
      <Banner
        description={{
          icon: 'fa-person-walking-luggage',
          copy: <BannerCopy person={person} />,
        }}
      >
        <PersonPermissionGate
          person={person}
          permissions={[GroupPermission.GROUP_PERMISSION_PERSON_UPDATE]}
          excludedRoles={[GroupType.GROUP_TYPE_RESPONSIBLE_PARTY]}
        >
          <div data-testid="cta-block">
            <ButtonLink
              className={'uppercase'}
              linkStyle={'secondary'}
              onClick={() => {
                setShowPopup(true)
                setIncludeEndDate(false)
              }}
            >
              Edit
            </ButtonLink>
            <DotDivider />
            <ButtonLink
              className={'uppercase'}
              linkStyle={'secondary'}
              onClick={() => {
                setShowPopup(true)
                setIncludeEndDate(true)
              }}
            >
              End Now
            </ButtonLink>
          </div>
        </PersonPermissionGate>
      </Banner>
      {showPopup && (
        <MarkAsAwayPopup
          setShowPopup={setShowPopup}
          includeEndDate={includeEndDate}
          doneFn={() => setShowPopup(false)}
          person={person}
        />
      )}
    </>
  )
}

function BannerCopy({ person }: { person: Person }) {
  return (
    <>
      {getFirstAndLastName(person.name)} has been&nbsp;
      <span className={'text-white'}>out of the community</span>&nbsp;
      {person.outOfFacilityDetail?.start?.date && (
        <>
          since&nbsp;
          <span className={'text-white'}>
            {formatDate(person.outOfFacilityDetail.start.date)}
          </span>
        </>
      )}
    </>
  )
}
