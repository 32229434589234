import { Task } from '@augusthealth/models/com/august/protos/task'
import { some } from 'lodash'
import { ReactNode } from 'react'
import Card from '@shared/components/Card'
import Icon from '@shared/components/Icon'
import pluralize from '@shared/utils/pluralize'
import { tw } from '@shared/utils/tailwind'
import { taskTitle } from '@shared/utils/task'
import ConfirmModal from '@app/components/ConfirmModal'

type NothingToSign = 'Nothing to sign'
type SuccessfulSign = 'Successful sign'
type CanceledSign = 'Canceled sign'
export type SigningTask =
  | { tag: 'First time' }
  | {
      tag: NothingToSign
    }
  | {
      tag: SuccessfulSign
    }
  | { tag: CanceledSign }
  | { tag: 'Task to sign'; task: Task }

type ExternalSignerModalProps = {
  signableTasks: Task[]
  taskCardOnClick: (t: Task) => void
  lastSigningState: NothingToSign | SuccessfulSign | CanceledSign
  hideModal: () => void
}

const getModalConfiguration = ({
  signableTasks,
  taskCardOnClick,
  lastSigningState,
  hideModal,
}: ExternalSignerModalProps) => {
  if (lastSigningState === 'Nothing to sign') {
    return {
      titleText: `You have no documents to sign.`,
      subtitleText: '',
      onDeny: hideModal,
      denyText: 'Close',
    }
  } else if (
    signableTasks.length > 0 &&
    lastSigningState === 'Successful sign'
  ) {
    return {
      titleText: 'Nice Work!',
      buttonText: 'Sign next document',
      subtitleText: (
        <>
          <p className={tw`mb-[12px]`}>You are done with this document.</p>
          <p>
            We will email you the fully executed version once everyone has
            signed.
          </p>
        </>
      ),
      onConfirm: () => taskCardOnClick(signableTasks[0]),
    }
  } else if (
    lastSigningState === 'Successful sign' &&
    signableTasks.length === 0
  ) {
    return {
      titleText: 'Nice Work!',
      onDeny: hideModal,
      denyText: 'Close',
      subtitleText: (
        <>
          <p className={tw`mb-[12px]`}>You are done with this document.</p>
          <p>
            We will email you the fully executed version once everyone has
            signed.
          </p>
        </>
      ),
    }
  } else {
    const numTasks = signableTasks.length
    return {
      titleText: `You have ${numTasks} ${pluralize('document', numTasks)} to sign.`,
      buttonText: 'Sign next document',
      onConfirm: () => taskCardOnClick(signableTasks[0]),
    }
  }
}

export default function ExternalSignerModal({
  signableTasks,
  taskCardOnClick,
  lastSigningState,
  hideModal,
}: ExternalSignerModalProps) {
  const confirmation = getModalConfiguration({
    signableTasks,
    taskCardOnClick,
    lastSigningState,
    hideModal,
  })

  return (
    <ConfirmModal
      title={confirmation.titleText}
      confirmButtonConfig={{
        buttonStyle: 'primary-fill',
        children: confirmation.buttonText,
        onClick: confirmation.onConfirm ? confirmation.onConfirm : () => {},
        hidden: !confirmation.onConfirm,
      }}
      denyButtonConfig={{
        onClick: confirmation.onDeny ? confirmation.onDeny : hideModal,
        children: confirmation.denyText,
        hidden: !confirmation.onDeny,
      }}
      content={
        <ExternalSignerModalContentWrapper text={confirmation.subtitleText}>
          {some(signableTasks) && (
            <>
              <div
                className={
                  'mb-[12px] mt-[32px] font-semibold text-secondary-04'
                }
              >
                You have {signableTasks.length} more{' '}
                {pluralize('document', signableTasks.length)} to sign.
              </div>
              {signableTasks.map((t) => (
                <ExternalSignerModalTaskCard
                  key={t.id}
                  cardTitle={taskTitle(t)}
                  onClick={() => taskCardOnClick(t)}
                />
              ))}
            </>
          )}
        </ExternalSignerModalContentWrapper>
      }
    />
  )
}

function ExternalSignerModalContentWrapper({
  children,
  text,
}: {
  children?: ReactNode
  text: ReactNode
}) {
  return (
    <>
      <div className={tw`mb-[12px] font-medium`}>{text}</div>
      {children && <div className={'mb-[32px]'}>{children}</div>}
    </>
  )
}

function ExternalSignerModalTaskCard({
  cardTitle,
  onClick,
}: {
  cardTitle: string
  onClick: () => void
}) {
  return (
    <Card onClick={onClick} className={tw`p-[16px]`}>
      <div
        className={tw`flex flex-grow items-center justify-between text-[14px] font-medium text-primary`}
      >
        <div>
          <Icon name={'file'} className={'mr-1'} />
          {cardTitle}
        </div>
        <Icon name={'chevron-right'} />
      </div>
    </Card>
  )
}
