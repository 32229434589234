import { DashboardRecord } from '@app/api/organizations'
import RemoteDashboard from '@app/pages/Facilities/Insights/RemoteDashboard'

type Props = {
  orgId: string
  dashboard: DashboardRecord
}

export default function Dashboard(props: Props) {
  const { orgId, dashboard } = props

  return <RemoteDashboard orgId={orgId} dashboardRecord={dashboard} />
}
