import { useEffect, useState } from 'react'
import StyledSelect from '@shared/components/StyledSelect'
import {
  isSpecificTime,
  isTimeGroup,
  ScheduleValue,
} from '@shared/utils/scheduleValue'
import { Switch } from '@app/components/Switch'
import styles from './styles.module.css'

type ShiftName = 'Morning' | 'Afternoon' | 'Night'
interface Props {
  name: ShiftName
  onChange: (value: ScheduleValue) => void
  initialValue?: ScheduleValue
  disabled?: boolean
}

function toInitialTime(medSched: ScheduleValue | undefined, name: ShiftName) {
  if (!medSched || medSched === 'NoValue') {
    return defaultTime[name]
  }

  if (isSpecificTime(medSched)) {
    return medSched.time
  } else {
    return medSched.timeGroup
  }
}

const defaultTime: Record<ShiftName, string> = {
  Morning: 'AM',
  Afternoon: 'PM',
  Night: 'NOC',
}

export default function TimeOfDayRow({
  name,
  label,
  onChange,
  initialValue,
  disabled = false,
}: Props & { label: string }) {
  const [enabled, setEnabled] = useState(
    (!!initialValue && initialValue !== 'NoValue') || false
  )

  const [time, setTime] = useState<string>(toInitialTime(initialValue, name))

  const buildResult = (): ScheduleValue => {
    if (enabled && time) {
      if (isTimeGroup(time)) {
        return {
          timeGroup: time,
          quantity: '',
        }
      } else {
        return {
          time,
          name,
          quantity: '',
        }
      }
    }

    return 'NoValue'
  }

  useEffect(() => {
    onChange(buildResult())
  }, [enabled, time])

  return (
    <div className={styles.timeOfDayRow}>
      <label className="mb-0 mr-[8px] flex items-center p-0" htmlFor={name}>
        <i
          className={`fa fa-fw ${iconForTime[name]} mr-[12px] ${styles.frequencyRowIcon}`}
        />
        <span className="m-0 inline-block w-[124px] text-[14px] font-semibold leading-[18px]">
          {label}
        </span>
      </label>
      <div className={'mr-[16px]'}>
        <Switch
          disabled={disabled}
          checked={enabled}
          name={name}
          onChange={(e) => {
            setEnabled(e.target.checked)
          }}
        />
      </div>
      {enabled && (
        <div className={styles.time}>
          <label htmlFor={`select-${name}`} className={'visually-hidden'}>
            {name} Hours
          </label>
          <StyledSelect
            name={`select-${name}`}
            inputId={`select-${name}`}
            isDisabled={disabled}
            defaultValue={optionsForTime[name].find((o) => o.value === time)}
            options={optionsForTime[name]}
            onChange={(option: { value: string }) => {
              setTime(option.value)
            }}
            menuPlacement="auto"
          />
        </div>
      )}
    </div>
  )
}

export const optionsForTime: Record<
  ShiftName,
  { value: string; label: string }[]
> = {
  Morning: [
    { value: 'AM', label: 'AM' },
    { value: '4AM', label: '4 AM' },
    { value: '4:30AM', label: '4:30 AM' },
    { value: '5AM', label: '5 AM' },
    { value: '5:30AM', label: '5:30 AM' },
    { value: '6AM', label: '6 AM' },
    { value: '6:30AM', label: '6:30 AM' },
    { value: '7AM', label: '7 AM' },
    { value: '7:30AM', label: '7:30 AM' },
    { value: '8AM', label: '8 AM' },
    { value: '8:30AM', label: '8:30 AM' },
    { value: '9AM', label: '9 AM' },
    { value: '9:30AM', label: '9:30 AM' },
    { value: '10AM', label: '10 AM' },
    { value: '10:30AM', label: '10:30 AM' },
    { value: '11AM', label: '11 AM' },
    { value: '11:30AM', label: '11:30 AM' },
    { value: '12PM', label: '12 PM' },
    { value: '12:30PM', label: '12:30 PM' },
    { value: '1PM', label: '1 PM' },
    { value: '1:30PM', label: '1:30 PM' },
    { value: '2PM', label: '2 PM' },
    { value: '2:30PM', label: '2:30 PM' },
    { value: '3PM', label: '3 PM' },
    { value: '3:30PM', label: '3:30 PM' },
    { value: '4PM', label: '4 PM' },
  ],
  Afternoon: [
    { value: 'PM', label: 'PM' },
    { value: '12PM', label: '12 PM' },
    { value: '12:30PM', label: '12:30 PM' },
    { value: '1PM', label: '1 PM' },
    { value: '1:30PM', label: '1:30 PM' },
    { value: '2PM', label: '2 PM' },
    { value: '2:30PM', label: '2:30 PM' },
    { value: '3PM', label: '3 PM' },
    { value: '3:30PM', label: '3:30 PM' },
    { value: '4PM', label: '4 PM' },
    { value: '4:30PM', label: '4:30 PM' },
    { value: '5PM', label: '5 PM' },
    { value: '5:30PM', label: '5:30 PM' },
    { value: '6PM', label: '6 PM' },
    { value: '6:30PM', label: '6:30 PM' },
    { value: '7PM', label: '7 PM' },
    { value: '7:30PM', label: '7:30 PM' },
    { value: '8PM', label: '8 PM' },
    { value: '8:30PM', label: '8:30 PM' },
    { value: '9PM', label: '9 PM' },
    { value: '9:30PM', label: '9:30 PM' },
    { value: '10PM', label: '10 PM' },
    { value: '10:30PM', label: '10:30 PM' },
    { value: '11PM', label: '11 PM' },
    { value: '11:30PM', label: '11:30 PM' },
    { value: '12AM', label: '12 AM' },
  ],
  Night: [
    { value: 'NOC', label: 'NOC' },
    { value: '8PM', label: '8 PM' },
    { value: '8:30PM', label: '8:30 PM' },
    { value: '9PM', label: '9 PM' },
    { value: '9:30PM', label: '9:30 PM' },
    { value: '10PM', label: '10 PM' },
    { value: '10:30PM', label: '10:30 PM' },
    { value: '11PM', label: '11 PM' },
    { value: '11:30PM', label: '11:30 PM' },
    { value: '12AM', label: '12 AM' },
    { value: '12:30AM', label: '12:30 AM' },
    { value: '1AM', label: '1 AM' },
    { value: '1:30AM', label: '1:30 AM' },
    { value: '2AM', label: '2 AM' },
    { value: '2:30AM', label: '2:30 AM' },
    { value: '3AM', label: '3 AM' },
    { value: '3:30AM', label: '3:30 AM' },
    { value: '4AM', label: '4 AM' },
    { value: '4:30AM', label: '4:30 AM' },
    { value: '5AM', label: '5 AM' },
    { value: '5:30AM', label: '5:30 AM' },
    { value: '6AM', label: '6 AM' },
    { value: '6:30AM', label: '6:30 AM' },
    { value: '7AM', label: '7 AM' },
    { value: '7:30AM', label: '7:30 AM' },
    { value: '8AM', label: '8 AM' },
  ],
}

const iconForTime: Record<ShiftName, string> = {
  Morning: 'fas fa-sunrise fa-fw',
  Afternoon: 'fas fa-sun fa-fw',
  Night: 'fas fa-moon-stars fa-fw',
}
