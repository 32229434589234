// eslint-disable-next-line no-restricted-imports
import { Clock } from '@augusthealth/models/com/august/protos/date'
// eslint-disable-next-line no-restricted-imports
import { OrgFacilitySettings } from '@augusthealth/models/com/august/protos/org_facility_settings'
import { get, set } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { getFacilityMarSettings } from '@shared/api/facility_settings'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Loading } from '@shared/utils/loading'

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
window.orgFacilitySettingsCache = window.orgFacilitySettingsCache || {}

export interface MarClockSettings {
  refreshMarSettings: (orgId?: string, facilityId?: string) => Promise<void>
  orgFacilitySettings: Loading<OrgFacilitySettings>
  use24HourClock: boolean
}

export const useMARClockSettings = ({
  orgId,
  facilityId,
  dependencies = [],
}: {
  orgId?: string
  facilityId?: string
  dependencies?: React.DependencyList
}): MarClockSettings => {
  const { setError } = useContext(GlobalContext)
  const [orgFacilitySettings, setOrgFacilitySettings] = useState<
    Loading<OrgFacilitySettings>
  >({ tag: 'Loading' })
  const [use24HourClock, setUse24HourClock] = useState<boolean>(false)

  const refreshMarSettings = async (orgId?: string, facilityId?: string) => {
    if (orgId && facilityId) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const cachedSettings = get(window.orgFacilitySettingsCache, [
        orgId,
        facilityId,
      ])
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (cachedSettings !== undefined) {
        setOrgFacilitySettings({
          tag: 'Complete',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          value: cachedSettings,
        })
        setUse24HourClock(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          cachedSettings.settings?.marSettings?.timeFormat?.clock ===
            Clock.CLOCK_24_HOUR
        )
      } else {
        await getFacilityMarSettings(orgId, facilityId)
          .then((res) => {
            set(window.orgFacilitySettingsCache, [orgId, facilityId], res)
            setOrgFacilitySettings({ tag: 'Complete', value: res })
            setUse24HourClock(
              res.settings?.marSettings?.timeFormat?.clock ===
                Clock.CLOCK_24_HOUR
            )
          })
          .catch(setError)
      }
    }
  }

  useEffect(() => {
    void refreshMarSettings(orgId, facilityId).catch(setError)
  }, [orgId, facilityId, ...dependencies])

  if (orgFacilitySettings.tag === 'Loading') {
    return {
      orgFacilitySettings,
      use24HourClock,
      refreshMarSettings,
    }
  }

  return {
    orgFacilitySettings,
    use24HourClock,
    refreshMarSettings,
  }
}
