import { DoseAndRate } from '@augusthealth/models/com/august/protos/dosage'
import { isAfter } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { DosageV2, SlidingScaleEntry } from '@shared/types/dosage'
import { MedicationOrder } from '@shared/types/medication_order'
import { AdditionalInstructions } from '@shared/types/medication_statement'
import { fromDateTimeToDate, isWithinInterval } from '@shared/utils/date'
import {
  getMedicationName,
  statementHasPrn,
} from '@shared/utils/medicationStatement'
import notEmpty from '@shared/utils/notEmpty'
import pluralize from '@shared/utils/pluralize'
import { escapeStringRegexp } from '@shared/utils/regex'
import { MedicationOrderRow } from '@emar/db/emar'

export const medicationOrderDescriptor = (
  order: Pick<MedicationOrder, 'medicationStatement'>
): string => {
  return `${order.medicationStatement.medication?.drugName} ${
    order.medicationStatement.medication?.strengthAndForm || ''
  }`.trim()
}

export const orderHasPrn = (order: MedicationOrder): boolean =>
  statementHasPrn(order.medicationStatement)

export const orderIsCurrentlyOnHoldAtFacility = (
  order: MedicationOrder | MedicationOrderRow,
  facilityTimeZone: string
): boolean => {
  const currentHoldDetail = order.medicationStatement.onHold?.onHoldDetail

  if (currentHoldDetail) {
    const nowAtFacility = utcToZonedTime(new Date(), facilityTimeZone)

    const holdStart = currentHoldDetail.start
      ? fromDateTimeToDate(currentHoldDetail.start)
      : undefined
    const holdEnd = currentHoldDetail.end
      ? fromDateTimeToDate(currentHoldDetail.end)
      : undefined

    if (holdStart && holdEnd) {
      return isWithinInterval(nowAtFacility, {
        start: holdStart,
        end: holdEnd,
      })
    } else if (holdStart) {
      return isAfter(nowAtFacility, holdStart)
    }

    return false
  }

  return false
}

export const orderIsMealDependent = (order: MedicationOrder): boolean => {
  return orderSpecifiesEmptyStomach(order) || orderSpecifiesTakeWithFood(order)
}

export const orderSpecifiesEmptyStomach = (order: MedicationOrder): boolean => {
  return Boolean(
    order.medicationStatement.additionalInstructions?.includes(
      AdditionalInstructions.ADDITIONAL_INSTRUCTIONS_BEFORE_MEALS
    )
  )
}

export const orderSpecifiesTakeWithFood = (order: MedicationOrder): boolean => {
  return Boolean(
    order.medicationStatement.additionalInstructions?.includes(
      AdditionalInstructions.ADDITIONAL_INSTRUCTIONS_AFTER_MEALS
    )
  )
}

export const nameOrInstructionMatchesString = (medFilter: string) => {
  return (order: MedicationOrder): boolean => {
    const name = getMedicationName(order)
    const instructions = getMedicationOrderInstructions(order).some((i) =>
      i.match(new RegExp(escapeStringRegexp(medFilter), 'i'))
    )

    return !!(
      instructions || name.match(new RegExp(escapeStringRegexp(medFilter), 'i'))
    )
  }
}

export const getMedicationOrderInstructions = (
  order: MedicationOrder
): string[] => {
  if (order.medicationStatement.providerDosageInstructions) {
    return [order.medicationStatement.providerDosageInstructions]
  }

  return (order.medicationStatement.dosageInstruction ?? [])
    .map((dose) => dose.text)
    .filter(notEmpty)
}

export const getAllAdditionalInstructions = (
  order: MedicationOrder
): string[] => {
  return [
    order.medicationStatement.note,
    ...(order.medicationStatement.dosageInstruction ?? []).map((i) => i.note),
  ].filter(notEmpty)
}

export const getDosageInstructions = (order: MedicationOrder): DosageV2[] => {
  return order.medicationStatement.dosageInstruction ?? []
}

export const getSlidingScaleEntriesFromDose = (dose?: DosageV2) => {
  return dose?.doseAndRate?.slidingScale?.entries ?? []
}

export const getDoseAndRate = (
  order: MedicationOrder,
  index: number = 0
): DoseAndRate | undefined => {
  return order.medicationStatement.dosageInstruction?.[index]?.doseAndRate
}

export const getSlidingScaleUnit = (entries: SlidingScaleEntry[]): string => {
  return (
    entries.find((entry) => entry.doseQuantity?.unit)?.doseQuantity?.unit ??
    'unit'
  )
}

export const getScaleBoundsValue = ({
  entry,
  boundsType,
}: {
  entry?: SlidingScaleEntry
  boundsType: 'high' | 'low'
}): number | null => {
  return entry?.bounds?.[boundsType]?.value ?? null
}

export const getScaleBoundsUnit = ({
  entry,
  boundsType,
}: {
  entry?: SlidingScaleEntry
  boundsType: 'high' | 'low'
}): string | null => {
  return entry?.bounds?.[boundsType]?.unit ?? null
}

export const getStructuredSlidingScaleDisplayData = (
  scaleEntries?: SlidingScaleEntry[]
): {
  rangeText: string
  administerDose: string
  isValidRange: boolean
  note: string | undefined
}[] => {
  if (!scaleEntries || scaleEntries.length === 0) {
    return []
  }

  const finalEntryIndex = scaleEntries.length - 1
  const unitLabel = getSlidingScaleUnit(scaleEntries)

  return scaleEntries.map((en, index) => {
    const isFinalEntry = index === finalEntryIndex
    let lowRange: string | number | null = getScaleBoundsValue({
      entry: en,
      boundsType: 'low',
    })
    let highRange: string | number | null = getScaleBoundsValue({
      entry: en,
      boundsType: 'high',
    })

    let isValidRange = true

    if (lowRange === null) {
      isValidRange = false
      lowRange = 'N/A'
    }

    if (!isFinalEntry && highRange === null) {
      isValidRange = false
      highRange = 'N/A'
    }

    if (isValidRange && highRange && lowRange >= highRange) {
      isValidRange = false
    }

    const rangeUnit = getScaleBoundsUnit({ entry: en, boundsType: 'low' })

    const rangeText = isFinalEntry
      ? `> ${lowRange} ${rangeUnit}`
      : `${lowRange} - ${highRange} ${rangeUnit}`

    const administerDose = pluralize(
      unitLabel,
      en.doseQuantity?.value ?? 0,
      true
    )

    return {
      rangeText,
      administerDose,
      isValidRange,
      note: en.note,
    }
  })
}

export const getDoseQuantityValue = (dose: DosageV2): number => {
  return dose.doseAndRate?.doseQuantity?.value ?? 0
}

export const getDoseQuantityUnit = (dose: DosageV2): string => {
  return dose.doseAndRate?.doseQuantity?.unit ?? 'unit'
}
