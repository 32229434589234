import { Facility } from '@shared/types/facility'
import {
  formatStreetAddress,
  getCityStatePostalCode,
} from '@shared/utils/address'
import {
  getEmailValue,
  getFaxValue,
  getPhoneValue,
} from '@shared/utils/contactPoint'
import {
  NameTd,
  Table,
  TableContainer,
  TdWithExtraPadding,
  TrWithHoverEffect,
} from '@app/components/Table'
import TableHeader from '@app/components/Table/TableHeader'

const TABLE_HEADERS = ['Name', 'Address', 'Email', 'Phone/Fax']

type Props = {
  facilities: Facility[]
  showFacilityForm: (facility?: Facility) => void
}

export default function FacilityTable(props: Props) {
  const { facilities, showFacilityForm } = props

  let trs
  if (facilities.length) {
    trs = facilities.map((f) => {
      const { id, orgId, name, address, stateLicenseNumber, telecom } = f
      const email = getEmailValue(telecom)
      const fax = getFaxValue(telecom)
      const phone = getPhoneValue(telecom)
      const phoneDiv = phone && (
        <div>
          <i className="fas fa-phone mr-[8px]" />
          {phone}
        </div>
      )
      const faxDiv = fax && (
        <div>
          <i className="fas fa-fax mr-[8px]" />
          {fax}
        </div>
      )
      const licDiv = stateLicenseNumber && (
        <div className="font-normal text-gray-07">
          LIC #{stateLicenseNumber}
        </div>
      )

      return (
        <TrWithHoverEffect
          key={`facility-row-${orgId}-${id}`}
          onClick={() => showFacilityForm(f)}
        >
          <NameTd>
            {name}
            {licDiv}
          </NameTd>
          <TdWithExtraPadding>
            <div>{address && formatStreetAddress(address)}</div>
            <div>{address && getCityStatePostalCode(address)}</div>
          </TdWithExtraPadding>
          <TdWithExtraPadding title={email}>{email}</TdWithExtraPadding>
          <TdWithExtraPadding>
            {phoneDiv}
            {faxDiv}
          </TdWithExtraPadding>
        </TrWithHoverEffect>
      )
    })
  } else {
    trs = (
      <tr>
        <TdWithExtraPadding colSpan={TABLE_HEADERS.length}>
          Record not found
        </TdWithExtraPadding>
      </tr>
    )
  }

  return (
    <div className="ml-[12px] mr-[12px]">
      <TableContainer>
        <Table>
          <TableHeader headers={TABLE_HEADERS} />
          <tbody>{trs}</tbody>
        </Table>
      </TableContainer>
    </div>
  )
}
