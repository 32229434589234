import { AllergyIntolerance_AllergyIntoleranceCategory } from '@augusthealth/models/com/august/protos/allergy_intolerance'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { fetchPerson } from '@shared/api/person'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { LabelAboveInput } from '@shared/components/Labels'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { updateDiagnosesAndAllergies } from '@shared/utils/diagnosis'
import { allergyList, diagnosesList } from '@shared/utils/person'
import DeletableInput from '@app/components/formElements/DeletableInput'
import { SwitchSize } from '@app/components/Switch'
import Allergies from './Allergies'

type Props = { person: Person; onClose: (updated: boolean) => Promise<void> }

export default function EditDiagnoses({ person, onClose }: Props) {
  const { setError } = useContext(GlobalContext)
  const { handleSubmit, formState } = useForm()

  const [updatedPerson, setUpdatedPerson] = useState(person)
  const medCategory =
    AllergyIntolerance_AllergyIntoleranceCategory.ALLERGY_INTOLERANCE_CATEGORY_MEDICATION
  const [diagnoses, setDiagnoses] = useState([
    ...diagnosesList(updatedPerson),
    { code: { text: '' }, isPrimary: false },
  ])
  const [allergies, setAllergies] = useState([
    ...allergyList(updatedPerson).filter(
      (allergy) => allergy.category === medCategory
    ),
    { summary: '', category: medCategory },
  ])
  useEffect(() => {
    fetchPerson({
      facilityId: person.facilityId!,
      personId: person.id!,
      orgId: person.orgId!,
      fields: ['conditions', 'allergiesAndIntolerances/allergies'],
    })
      .then(setUpdatedPerson)
      .catch(setError)
  }, [])

  const onSubmit = async () => {
    const fullAllergyList = [
      ...allergies.filter((a) => a.summary?.trim()),
      ...allergyList(updatedPerson).filter(
        (allergy) => allergy.category !== medCategory
      ),
    ]

    try {
      await updateDiagnosesAndAllergies(
        person,
        diagnoses.filter((d) => d.code?.text?.trim()),
        fullAllergyList
      )
      await onClose(true)
    } catch (e) {
      setError(e)
    }
  }

  const onDiagnosisChange = (newValue: string, index: number) =>
    setDiagnoses([
      ...diagnoses.slice(0, index),
      {
        ...diagnoses[index],
        code: { text: newValue },
      },
      ...diagnoses.slice(index + 1),
      // once the last item is filled out, add new blank item at the end
      ...(index === diagnoses.length - 1 && newValue.trim()
        ? [{ code: { text: '' }, isPrimary: false }]
        : []),
    ])
  const onPrimaryDiagnosis = (isPrimary: boolean, index: number) =>
    setDiagnoses([
      ...diagnoses.slice(0, index),
      { ...diagnoses[index], isPrimary },
      ...diagnoses.slice(index + 1),
    ])
  const onTrashDiagnosis = (index: number) =>
    setDiagnoses(diagnoses.filter((_d, i) => i !== index))
  const diagnoseInputs = diagnoses.map((diagnosis, index) => {
    const hasCoding = !!diagnosis.code!.coding
    const text = diagnosis.code!.text
    const inputProps = {
      disabled: hasCoding,
      name: `diagnoses[${index}]`,
      value: text,
      onKeyDown: (ev) => {
        if (ev.key === 'Enter') {
          ev.preventDefault()
        }
      },
      onChange: (ev) => onDiagnosisChange(ev.target.value, index),
      placeholder: 'Enter one per line...',
    }
    const switchProps = text
      ? {
          label: 'PRIMARY',
          checked: diagnosis.isPrimary || false,
          name: `isPrimary[${index}]`,
          size: SwitchSize.small,
          onChange: (ev) => onPrimaryDiagnosis(ev.target.checked, index),
        }
      : undefined
    const trashIconProps =
      text && !hasCoding
        ? {
            name: `delete-diagnosis[${index}]`,
            onClick: () => onTrashDiagnosis(index),
            type: 'button',
          }
        : undefined

    return (
      <DeletableInput
        key={`diagnosis-${index}`}
        inputProps={inputProps}
        switchProps={switchProps}
        trashIconProps={trashIconProps}
      />
    )
  })

  const formContent = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LabelAboveInput htmlFor="diagnoses">Diagnoses</LabelAboveInput>
      {diagnoseInputs}

      <LabelAboveInput className="mt-[32px]" htmlFor="allergies">
        Medical Allergies
      </LabelAboveInput>
      <Allergies
        allergies={allergies}
        setAllergies={setAllergies}
        category={medCategory}
      />
      <div className="mt-[32px]">
        <AnimatedPopupFormFooter
          noBtn={{ action: () => void onClose(false) }}
          yesBtn={{ props: { id: 'saveDiagnosesAllergies' } }}
          formState={formState}
        />
      </div>
    </form>
  )

  return (
    <AnimatedPopup title="Diagnoses & Medical Allergies">
      {formContent}
    </AnimatedPopup>
  )
}
