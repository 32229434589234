import { OptionTypeBase } from '@shared/components/StyledSelect'
import {
  CustomDetailEnum,
  DetailModalMode,
} from '@shared/types/assessment_configuration'
import { AugustFieldType } from '@shared/types/custom_field'
import { isCustomTextDetail } from '@shared/utils/assessmentConfiguration'
import {
  DetailWithDropdownOption,
  FrontendDetail,
} from '@app/pages/Tasks/ResidentAssessment/AssessmentPage/CheckboxSections/utils'
import { isSupportedAugustField } from '@app/pages/Tools/AssessmentConfiguration/DetailGroups/augustFieldHelpers'
import {
  CUSTOM_DETAIL,
  CUSTOM_DROPDOWN_DETAIL,
  CUSTOM_TEXTAREA_DETAIL,
  CUSTOM_TEXTBOX_DETAIL,
} from '@app/pages/Tools/AssessmentConfiguration/helpers'

export const categoryContainsDetailType = (
  currentDetails: Map<string, FrontendDetail[]>,
  detailType: string,
  detailGroup: string
) => {
  return Array.from(currentDetails.values()).some((cat) => {
    return cat.some((catDetail) => {
      if (catDetail.tag === 'DetailWithCheckbox') {
        return catDetail.value[detailGroup] === detailType
      } else if (
        catDetail.tag === 'DetailWithTextbox' ||
        catDetail.tag === 'DetailWithTextarea'
      ) {
        // Textbox details are always custom, so this branch is here
        // to type-refine only
        return false
      } else if (catDetail.tag === 'DetailWithAugustField') {
        // August Fields are configured separately
        return false
      } else {
        return (catDetail.value as any).options.some(
          (option: any) => option[detailGroup] === detailType
        )
      }
    })
  })
}

export const getValidDropdownEnumOptions = (
  currentDetails: Map<string, FrontendDetail[]>,
  detailType: string,
  detailGroup: string,
  enumsToDisable: string[],
  currentEnum?: string
): OptionTypeBase[] => {
  const shouldDisableOption = (type) => {
    if (
      categoryContainsDetailType(currentDetails, type, detailGroup) &&
      enumsToDisable.includes(type)
    ) {
      return true
    }

    return categoryContainsDetailType(currentDetails, type, detailGroup)
  }
  const categoryEnums = [
    ...Object.values(detailType)
      .filter(
        (type: string) =>
          type &&
          !excludedTypeNamePatterns.some((excludedType) =>
            type.includes(excludedType)
          )
      )
      .map((type: string) => {
        return {
          label: type,
          value: type,
          isDisabled: type === currentEnum ? false : shouldDisableOption(type),
        }
      }),
  ]

  return [
    {
      label: 'Custom Value',
      value: CUSTOM_DETAIL,
      isDisabled: false,
    },
    ...categoryEnums,
  ]
}

export const excludedTypeNamePatterns = [
  'UNRECOGNIZED',
  'UNSPECIFIED',
  'CUSTOM_DETAILS_DEFAULT',
  'CUSTOM_DETAILS_TEXTBOX',
  'CUSTOM_DETAILS_TEXTAREA',
  'CUSTOM_DETAILS_DROPDOWN',
]

export const getRelevantCategoryOptions = (
  detailType,
  currentDetails: Map<string, FrontendDetail[]>,
  detailGroupProperty
): OptionTypeBase[] => [
  ...Object.values(detailType)
    .filter(
      (type: string) =>
        type &&
        !excludedTypeNamePatterns.some((excludedType) =>
          type.includes(excludedType)
        )
    )
    .map((type: string) => {
      return {
        label: type,
        value: type,
        isDisabled: categoryContainsDetailType(
          currentDetails,
          type,
          detailGroupProperty
        ),
      }
    }),
]

const SUPPORTED_AUGUST_FIELDS = Object.values(AugustFieldType).filter(
  isSupportedAugustField
)

export type DetailModalForm = {
  description: string
  score?: number
  enum?: CustomDetailEnum
  helpText?: string
  isRequired?: boolean
  answerGroup?: string
  multiSelect?: boolean
  options?: DetailWithDropdownOption[]
}

export const allRelevantOptions = (
  defaultVals: DetailModalForm,
  mode: DetailModalMode
) => [
  {
    label: 'Custom Checkbox',
    value: CUSTOM_DETAIL,
    isDisabled: isCustomTextDetail(defaultVals.enum) || mode.tag === 'edit',
  },
  {
    label: 'Custom Text Input',
    value: CUSTOM_TEXTBOX_DETAIL,
    isDisabled: mode.tag === 'edit',
  },
  {
    label: 'Custom Text Area',
    value: CUSTOM_TEXTAREA_DETAIL,
    isDisabled: mode.tag === 'edit',
  },
  {
    label: 'Custom Dropdown',
    value: CUSTOM_DROPDOWN_DETAIL,
    isDisabled: mode.tag === 'edit',
  },
  ...SUPPORTED_AUGUST_FIELDS.map((aft) => ({
    label: aft,
    value: aft,
  })),
]
