import { Clock } from '@augusthealth/models/com/august/protos/date'
import {
  OrgFacilitySettings,
  SettingsType,
} from '@augusthealth/models/com/august/protos/org_facility_settings'
import { useState } from 'react'
import { mergePatchFacility } from '@shared/api/facility'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { Facility } from '@shared/types/facility'
import { updateOrgFacilitySettings } from '@app/api/organizations'
import { Switch } from '@app/components/Switch'

export default function FacilitySettings({
  facility,
  marClockSettings,
  onClose,
  setFacility,
  setShowHUD,
}: {
  facility: Facility
  marClockSettings?: OrgFacilitySettings
  onClose: () => void
  setFacility: React.Dispatch<React.SetStateAction<Facility>>
  setShowHUD: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const [isActive, setIsActive] = useState(facility.isActive)
  const [isMar24HrFormat, setIsMar24HrFormat] = useState(
    marClockSettings?.settings?.marSettings?.timeFormat?.clock ===
      Clock.CLOCK_24_HOUR
  )

  const updateFacilitySettings = async () => {
    const clockFormat = isMar24HrFormat
      ? Clock.CLOCK_24_HOUR
      : Clock.CLOCK_12_HOUR
    if (
      clockFormat !== marClockSettings?.settings?.marSettings?.timeFormat?.clock
    ) {
      const newSettings: OrgFacilitySettings = {
        facilityId: facility.id,
        orgId: facility.orgId,
        settingsType: SettingsType.SETTINGS_TYPE_MAR_SETTINGS,
        settings: {
          marSettings: {
            timeFormat: {
              clock: clockFormat,
            },
          },
        },
      }
      await updateOrgFacilitySettings(newSettings)
    }

    if (isActive !== facility.isActive) {
      const { data: updated } = await mergePatchFacility({
        facility,
        patch: { isActive },
      })
      setFacility(updated)
    }

    setShowHUD(true)
    onClose()
  }

  return (
    <AnimatedPopup
      title={
        <div className="mb-[32px] text-[20px] font-semibold leading-[20px]">
          Facility Settings
        </div>
      }
      containerClassName="w-[320px]"
      onClickBackground={onClose}
    >
      <div className="mb-[16px] flex items-center justify-between">
        <label className="mb-0" htmlFor="MAR-clock">
          MAR 24-hr Clock
        </label>
        <Switch
          name="MAR-clock"
          onChange={() => setIsMar24HrFormat(!isMar24HrFormat)}
          checked={isMar24HrFormat}
        />
      </div>
      <div className="flex items-center justify-between">
        <label className="mb-0" htmlFor="inactive">
          Mark as inactive
        </label>
        <Switch
          name="inactive"
          onChange={() => setIsActive(!isActive)}
          checked={!isActive}
        />
      </div>
      <div className="mt-[24px] flex justify-between">
        <AsyncIconButton
          buttonStyle="secondary-outline"
          buttonSize="small"
          onClick={onClose}
          width="120px"
        >
          Cancel
        </AsyncIconButton>
        <AsyncIconButton
          buttonStyle="primary-fill"
          buttonSize="small"
          onClick={async () => {
            await updateFacilitySettings()
          }}
          width="120px"
        >
          Save
        </AsyncIconButton>
      </div>
    </AnimatedPopup>
  )
}
