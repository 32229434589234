import { Task } from '@shared/types/task'
import { Order } from '@shared/utils/common'
import { alphaNumericSort } from '@shared/utils/personStats'
import { getIsCompleteOrProcessing } from '@shared/utils/signature'
import {
  getTaskLastModification,
  isClosed,
  rpCanAct,
  taskOrder,
  taskTitle,
  visibleToRP,
} from '@shared/utils/task'
import { TaskSortOrder } from '@app/components/SortByDropdown/helpers'

export function getAdmissionTasks(tasks: Task[], isRP: boolean): Task[] {
  return tasks
    .reduce((list: Task[], t: Task) => {
      if (getIsCompleteOrProcessing(t) || isClosed(t)) {
        return list
      }

      if (isRP) {
        if (visibleToRP(t) || rpCanAct(t)) {
          return [...list, t]
        }

        return list
      }

      return [...list, t]
    }, [])
    .sort((a, b) => taskOrder(a) - taskOrder(b))
}

export function getHasCompletedTasks(tasks: Task[]) {
  return tasks.some(getIsCompleteOrProcessing)
}

function getSortByTaskNameFunc(sortOrder: Order) {
  return (taskA: Task, taskB: Task) => {
    const taskTitleA = taskTitle(taskA)
    const taskTitleB = taskTitle(taskB)
    return alphaNumericSort(taskTitleA, taskTitleB, sortOrder)
  }
}

function getSortByTaskLastModificationFunc(sortOrder: Order) {
  return (taskA: Task, taskB: Task) => {
    const lastModificationA = getTaskLastModification(taskA) || undefined
    const lastModificationB = getTaskLastModification(taskB) || undefined
    return alphaNumericSort(
      lastModificationA?.modificationTime,
      lastModificationB?.modificationTime,
      sortOrder
    )
  }
}

export function getSortedTasks(tasks: Task[], sortOrder: TaskSortOrder) {
  const sortedTasks = [...tasks]
  if (sortOrder === TaskSortOrder.TASK_SORT_BY_NAME_ASC) {
    return sortedTasks.sort(getSortByTaskNameFunc(Order.ASC))
  } else if (sortOrder === TaskSortOrder.TASK_SORT_BY_NAME_DESC) {
    return sortedTasks.sort(getSortByTaskNameFunc(Order.DESC))
  } else if (sortOrder === TaskSortOrder.TASK_SORT_BY_LAST_UPDATE_ASC) {
    return sortedTasks.sort(getSortByTaskLastModificationFunc(Order.ASC))
  } else if (sortOrder === TaskSortOrder.TASK_SORT_BY_LAST_UPDATE_DESC) {
    return sortedTasks.sort(getSortByTaskLastModificationFunc(Order.DESC))
  }

  return sortedTasks
}
