import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import ButtonLink from '@shared/components/ButtonLink'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import StyledSelect from '@shared/components/StyledSelect'
import {
  FaxInput,
  isValidEmail,
  LockedEmailInput,
  PhoneInput,
} from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import { useUserContext } from '@shared/contexts/UserContext'
import { Person } from '@shared/types/person'
import { isResponsiblePerson as contactIsResponsiblePerson } from '@shared/utils/contact'
import { getFullName } from '@shared/utils/humanName'
import { isResponsiblePerson as loginAsResponsiblePerson } from '@shared/utils/user'
import { RpEmailWarning } from '@app/components/Warning'
import styles from './styles.module.css'
import { phoneTypeOptions } from './helpers'
import { ContactFormData } from './types'

type Props = {
  contact?: Contact
  methods: UseFormReturn<ContactFormData>
  person: Person
}
export default function TelecomSection({ contact, methods, person }: Props) {
  const { user } = useUserContext()
  const {
    control,
    formState: { errors },
    register,
    resetField,
  } = methods
  const isContactRP = contact ? contactIsResponsiblePerson(contact) : false
  const { orgId, facilityId, id: personId } = person
  const canChangeRpEmail =
    !isContactRP ||
    !loginAsResponsiblePerson({ user, orgId, facilityId, personId })
  const [isEmailLocked, setIsEmailLocked] = useState(isContactRP)
  const showRPEmailWarning = isContactRP && !isEmailLocked

  return (
    <div>
      <div className="mt-[24px]">
        <LabelAboveInput htmlFor="primaryPhoneNumber" uppercase={false}>
          Phone
          <span className={styles.sublabel}>Primary</span>
        </LabelAboveInput>
        <div className="flex">
          <Controller
            control={control}
            name="primaryPhoneNumber"
            render={({ field: { onChange, value } }) => (
              <div className="mr-[8px] grow">
                <PhoneInput
                  inputProps={{ name: 'primaryPhoneNumber', onChange, value }}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="primaryPhoneType"
            render={({ field: { onChange, value } }) => (
              <div style={{ width: '136px' }}>
                <StyledSelect
                  name="primaryPhoneType"
                  options={phoneTypeOptions()}
                  value={value}
                  onChange={onChange}
                />
              </div>
            )}
          />
        </div>
      </div>
      <div className="mt-[24px]">
        <LabelAboveInput htmlFor="secondaryPhoneNumber" uppercase={false}>
          Phone <span className={styles.sublabel}>Secondary</span>
        </LabelAboveInput>
        <div className="flex">
          <Controller
            control={control}
            name="secondaryPhoneNumber"
            render={({ field: { onChange, value } }) => (
              <div className="mr-[8px] grow">
                <PhoneInput
                  inputProps={{ name: 'secondaryPhoneNumber', onChange, value }}
                />
              </div>
            )}
          />
          <Controller
            control={control}
            name="secondaryPhoneType"
            render={({ field: { onChange, value } }) => (
              <div style={{ width: '136px' }}>
                <StyledSelect
                  name="secondaryPhoneType"
                  options={phoneTypeOptions()}
                  value={value}
                  onChange={onChange}
                />
              </div>
            )}
          />
        </div>
      </div>
      <div className="mt-[24px]">
        <div className="flex items-baseline justify-between">
          <LabelAboveInput
            errorMessage="Invalid Format"
            subLabel={requiredWhenError(!!errors.emailAddress)}
            htmlFor="emailAddress"
            uppercase={false}
          >
            Email
          </LabelAboveInput>
          {canChangeRpEmail && (
            <ButtonLink
              onClick={() => {
                // if cancelling, clear the changes
                if (!isEmailLocked) {
                  resetField('emailAddress')
                }
                setIsEmailLocked(!isEmailLocked)
              }}
            >
              {isEmailLocked ? 'Unlock to Edit' : 'Cancel Edit'}
            </ButtonLink>
          )}
        </div>
        <LockedEmailInput
          inputProps={{
            disabled: isEmailLocked,
            ...register('emailAddress', {
              validate: (email) => {
                if (!email) {
                  // email is not required so this is fine
                  return true
                }
                return isValidEmail(email)
              },
            }),
          }}
        />
        {showRPEmailWarning && (
          <RpEmailWarning
            className="mt-[12px]"
            name={contact?.name && getFullName(contact.name)}
          />
        )}
      </div>
      <Controller
        control={control}
        name="faxNumber"
        render={({ field: { onChange, value } }) => (
          <div className="mt-[24px]">
            <LabelAboveInput htmlFor="faxNumber" uppercase={false}>
              Fax
            </LabelAboveInput>
            <FaxInput inputProps={{ name: 'faxNumber', onChange, value }} />
          </div>
        )}
      />
    </div>
  )
}
