import { groupBy } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  savePharmacyIntegration,
  updatePharmacyIntegration,
} from '@shared/api/pharmacy_integration'
import Card from '@shared/components/Card'
import ExpectedErrorModal from '@shared/components/ErrorModal/ExpectedErrorModal'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, { OptionTypeBase } from '@shared/components/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { PharmacyFacility } from '@shared/types/pharmacy_facility'
import { PharmacyPartner } from '@shared/types/pharmacy_partner'
import { AugustError } from '@shared/utils/error'
import { getOrElse } from '@shared/utils/loading'
import { getReadablePharmacyPartnerName } from '@shared/utils/pharmacyPartner'
import { tw } from '@shared/utils/tailwind'
import HUD from '@app/components/HUD'
import { CurrentIntegrationsTable } from '@app/pages/Tools/PharmacyTools/PharmacyIntegration/CurrentIntegrationsTable/CurrentIntegrationsTable'
import {
  BLANK_INTEGRATIONS_FORM,
  getCreatePharmacyIntegrationPayload,
  getExistingPharmacyPartnerOptions,
  getPharmacyPartnerOptions,
  getUpdatePharmacyIntegrationPayload,
  IntegrationsForm,
  mapPharmacyFacilityToIntegrationsForm,
} from '@app/pages/Tools/PharmacyTools/PharmacyIntegration/helpers'
import { LabelWithInput } from '@app/pages/Tools/PharmacyTools/PharmacyIntegration/Layout'
import { PartnerConfigurationForm } from '@app/pages/Tools/PharmacyTools/PharmacyIntegration/PartnerConfigurationForm/PartnerConfigurationForm'
import { usePharmacyIntegrations } from '@app/pages/Tools/PharmacyTools/PharmacyIntegration/usePharmacyIntegrations'
import { OrgFacilitySelects } from '@app/pages/Tools/SharedSelects/OrgFacilitySelects'
import useOrgFacilityPersonSelects from '@app/pages/Tools/SharedSelects/useOrgFacilityPersonSelects'

export const PharmacyIntegrations = () => {
  const { setError } = useContext(GlobalContext)
  const [showHud, setShowHud] = useState<string | null>(null)
  const [integrationsForFacility, setIntegrationsForFacility] =
    useState<PharmacyFacility[]>()
  const [expectedErrorData, setExpectedErrorData] = useState<{
    children: string
    title: string
  }>()
  const methods = useForm<IntegrationsForm>({
    defaultValues: BLANK_INTEGRATIONS_FORM,
    mode: 'onChange',
  })
  const selectedPharmacyPartner = methods.watch('partner')
  const {
    selectedOrganization,
    selectedFacility,
    onOrgSelect,
    onFacilitySelect,
  } = useOrgFacilityPersonSelects()

  const {
    allIntegrations,
    editingIntegration,
    setEditingIntegration,
    listAllPharmacyIntegrations,
  } = usePharmacyIntegrations()

  useEffect(() => {
    methods.reset({
      ...BLANK_INTEGRATIONS_FORM,
      partner: selectedPharmacyPartner,
      augustFacilityId: selectedFacility?.value?.id,
      externalIdentifier: undefined,
    })
  }, [selectedPharmacyPartner, selectedFacility])

  useEffect(() => {
    if (selectedFacility?.value?.id) {
      const byFacility = groupBy(getOrElse(allIntegrations, []), 'facilityId')
      const matches = byFacility[selectedFacility.value.id]
      setIntegrationsForFacility(matches)
    } else {
      setIntegrationsForFacility(undefined)
      setEditingIntegration(undefined)
      methods.reset(BLANK_INTEGRATIONS_FORM)
    }
  }, [selectedFacility, allIntegrations])

  const handleErrors = (error: any) => {
    const { status, json } = error as AugustError
    if (status === 400 && json?.errors && json.errors.length) {
      const { message } = json.errors[0]
      if (message) {
        setExpectedErrorData({
          children: message,
          title: 'Unable to save pharmacy integration',
        })
        return
      }
    }

    setError(error)
  }

  const saveIntegration = async (formData: IntegrationsForm) => {
    if (!selectedOrganization?.value || !selectedFacility?.value) {
      setExpectedErrorData({
        children: 'Must select and org and facility before saving integration',
        title: 'Unable to save pharmacy integration',
      })
      return
    }

    if (editingIntegration) {
      const editPayload = getUpdatePharmacyIntegrationPayload({
        formData,
        currentIntegration: editingIntegration,
      })

      try {
        const updated = await updatePharmacyIntegration(editPayload)
        setShowHud('Integration updated!')
        setEditingIntegration(updated)
        void listAllPharmacyIntegrations()
      } catch (error: any) {
        handleErrors(error)
      }
    } else {
      const payload = getCreatePharmacyIntegrationPayload(formData)

      try {
        await savePharmacyIntegration({
          orgId: selectedOrganization.value.id,
          facilityId: selectedFacility.value.id,
          payload,
        })
        void listAllPharmacyIntegrations(payload.externalIdentifier)
        setShowHud('Integration saved!')
      } catch (error: any) {
        handleErrors(error)
      }
    }
  }

  const partnerOptions = getPharmacyPartnerOptions()
  const existingConfigurationPartnerOptions = getExistingPharmacyPartnerOptions(
    integrationsForFacility
  )

  return (
    <div className="content-holder">
      {!!showHud && <HUD>{showHud}</HUD>}
      <h4 className="page-title mt-[24px]">Pharmacy Integrations</h4>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(saveIntegration)}
          className={tw`flex flex-row justify-between gap-4`}
        >
          <div
            className={tw`flex flex-1 flex-col justify-start gap-4 overflow-hidden`}
          >
            <Card className="flex h-fit flex-col gap-4">
              <OrgFacilitySelects
                onOrgSelect={onOrgSelect}
                onFacilitySelect={(
                  facility: OptionTypeBase<Facility> | null
                ) => {
                  setEditingIntegration(undefined)
                  methods.reset({
                    ...BLANK_INTEGRATIONS_FORM,
                    augustFacilityId: facility?.value?.id ?? '',
                  })
                  onFacilitySelect(facility ?? null)
                }}
                selectedOrganization={selectedOrganization}
                selectedFacility={selectedFacility}
                orgLabel={'Create or edit integrations for...'}
              />
            </Card>
            <Card className={tw`flex h-fit flex-col gap-4`}>
              {integrationsForFacility && (
                <>
                  <div>
                    <LabelAboveInput htmlFor={'existingPharmacyPartner'}>
                      Edit Existing Integration
                    </LabelAboveInput>
                    <StyledSelect
                      placeholder={'Select a pharmacy partner'}
                      value={
                        existingConfigurationPartnerOptions.find(
                          (opt) =>
                            opt.value.externalIdentifier ===
                            editingIntegration?.externalIdentifier
                        ) ?? null
                      }
                      options={existingConfigurationPartnerOptions}
                      name="existingPharmacyPartner"
                      id="existingPharmacyPartner"
                      instanceId="existingPharmacyPartner"
                      isClearable
                      isDisabled={
                        !editingIntegration && !!methods.watch('partner')
                      }
                      onChange={(o: OptionTypeBase<PharmacyFacility>) => {
                        if (o) {
                          setEditingIntegration(o.value)
                          methods.reset(
                            mapPharmacyFacilityToIntegrationsForm(o.value)
                          )
                        } else {
                          setEditingIntegration(undefined)
                          methods.reset(BLANK_INTEGRATIONS_FORM)
                        }
                      }}
                      formatOptionLabel={(
                        data: OptionTypeBase<PharmacyFacility>
                      ) => {
                        return (
                          <span
                            className={tw`flex flex-row items-center gap-3`}
                          >
                            {getReadablePharmacyPartnerName(
                              data.value.partner!
                            )}{' '}
                            <span className={tw`font-medium text-gray-07`}>
                              {data.value.externalIdentifier}
                            </span>
                          </span>
                        )
                      }}
                    />
                  </div>
                  <div
                    className={tw`w-full text-center text-[14px] font-semibold`}
                  >
                    OR
                  </div>
                </>
              )}
              <div>
                <LabelAboveInput htmlFor={'pharmacyPartner'}>
                  Connect to {integrationsForFacility ? 'New ' : ''}Pharmacy
                  Partner
                </LabelAboveInput>
                <StyledSelect
                  placeholder={'Select a pharmacy partner'}
                  value={
                    editingIntegration
                      ? null
                      : (partnerOptions.find(
                          (opt) => opt.value === selectedPharmacyPartner
                        ) ?? null)
                  }
                  options={partnerOptions}
                  name="pharmacyPartner"
                  id="pharmacyPartner"
                  instanceId="pharmacyPartner"
                  onChange={(o: OptionTypeBase<PharmacyPartner>) => {
                    methods.setValue('partner', o?.value ?? null)
                    setEditingIntegration(undefined)
                  }}
                  isDisabled={!!editingIntegration}
                  isClearable
                />
              </div>
              <hr />
              <LabelWithInput
                field={{
                  name: 'augustFacilityId',
                  label: 'August Facility ID',
                  required: true,
                  disabled: true,
                  value: methods.watch('augustFacilityId'),
                }}
              />
            </Card>
          </div>
          <div className={tw`flex-1 shrink-0`}>
            <Card className={tw`flex h-fit flex-1 flex-col gap-4`}>
              {selectedPharmacyPartner && selectedFacility && (
                <div className={tw`text-center`}>
                  <div
                    className={tw`tracking-wide text-gray-07`}
                  >{`${editingIntegration ? 'Edit' : 'New'} Integration`}</div>
                  <div
                    className={tw`font-semibold`}
                  >{`${getReadablePharmacyPartnerName(selectedPharmacyPartner)} & ${selectedFacility.label}`}</div>
                </div>
              )}
              <PartnerConfigurationForm
                editingIntegration={editingIntegration}
              />
            </Card>
          </div>
        </form>
      </FormProvider>
      {expectedErrorData && (
        <ExpectedErrorModal
          id={'pharmacy-integration-error'}
          buttonProps={{
            children: 'Close',
            onClick: () => setExpectedErrorData(undefined),
          }}
          {...expectedErrorData}
        />
      )}
      <CurrentIntegrationsTable integrations={allIntegrations} />
    </div>
  )
}
