import { CodeableConcept } from '@augusthealth/models/com/august/protos/codeable_concept'
import { Immunization } from '@augusthealth/models/com/august/protos/immunization'
import { ChangeEvent, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import ButtonLink from '@shared/components/ButtonLink'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { EmbossedCard } from '@shared/components/EmbossedCard'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import StyledSelect from '@shared/components/StyledSelect'
import {
  fromDateMessageToDate,
  fromDateToDateMessage,
} from '@shared/utils/date'
import {
  getDefaultImmunization,
  ROUTE_OPTIONS,
} from '@shared/utils/immunizations'
import { tw, twx } from '@shared/utils/tailwind'
import ManufacturerSelect, { ManufacturerOption } from './ManufacturerSelect'

export type ErrorType = 'dateNotAdd' | 'needAtLeastOneImmunization'

/**
 * Add Immunization form
 * DO NO USE <form> or react-hook-form to avoid form inside form error when using inside following files:
 * - src/app/pages/Tools/ResidentDocumentUploader/ResidentDocumentUploader.tsx
 * - src/app/pages/Tools/ResidentDocumentUploader/FormProducer.tsx
 */

export default function AddImmunization({
  diseaseName,
  errorType,
  onAdd,
  onDateChange,
  onValueChange,
  hideAddButton = false,
}: {
  diseaseName?: string
  errorType?: ErrorType
  onAdd: (immu: Immunization) => void
  onDateChange?: (d: Date | null) => void
  onValueChange?: (immunization: Immunization) => void
  hideAddButton?: boolean
}) {
  const [immunization, setImmunization] = useState<Immunization>(
    getDefaultImmunization(diseaseName)
  )
  const { route, performer, site, manufacturer, lotNumber, occurrenceDate } =
    immunization
  const [showAllFields, setShowAllFields] = useState<boolean>(false)
  const [showDateError, setShowDateError] = useState<boolean>(false)
  const [showAddBtnError, setShowAddBtnError] = useState<boolean>(false)
  const onAddClick = () => {
    // occurrenceDate is missing and other fields exist
    if (!occurrenceDate && Object.keys(immunization).length >= 2) {
      setShowDateError(true)
    } else {
      setShowDateError(false)
      onAdd(immunization)
      setImmunization(getDefaultImmunization(diseaseName))
      if (onDateChange) {
        onDateChange(null)
      }
    }
  }

  useEffect(() => {
    if (errorType === 'needAtLeastOneImmunization') {
      setShowDateError(true)
    } else {
      setShowDateError(false)
    }
    if (errorType === 'dateNotAdd') {
      setShowAddBtnError(true)
    } else {
      setShowAddBtnError(false)
    }
  }, [errorType])

  useEffect(() => {
    onValueChange?.(immunization)
  }, [immunization])

  return (
    <EmbossedCard className={tw`mb-[16px]`}>
      {showAllFields && (
        <>
          <div className={tw`mb-[12px]`}>
            <StyledSelect
              options={ROUTE_OPTIONS}
              value={
                ROUTE_OPTIONS.find((opt) => opt.value.text === route?.text) ||
                null
              }
              onChange={(opt: { value: CodeableConcept } | null) => {
                setImmunization((prev) => ({
                  ...prev,
                  route: opt?.value ? opt.value : undefined,
                }))
              }}
              placeholder="Select route..."
              isClearable
            />
          </div>
          <div className={tw`mb-[12px]`}>
            <BasicInput
              onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                const inputValue = ev.target.value
                setImmunization((prev) => ({
                  ...prev,
                  performer: inputValue.trim() ? inputValue : undefined,
                }))
              }}
              value={performer || ''}
              placeholder="Administered by"
            />
          </div>
          <div className={tw`mb-[12px]`}>
            <BasicInput
              onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                const inputValue = ev.target.value
                setImmunization((prev) => ({
                  ...prev,
                  site: inputValue.trim() ? { text: inputValue } : undefined,
                }))
              }}
              value={site?.text || ''}
              placeholder="Site"
            />
          </div>
          <div className={tw`mb-[12px]`}>
            <ManufacturerSelect
              diseaseName={diseaseName}
              manufacturer={manufacturer}
              onChange={(opt: ManufacturerOption | null) => {
                setImmunization((prev) => ({
                  ...prev,
                  manufacturer: opt?.value ? opt.value : undefined,
                }))
              }}
              placeholder="Vaccine manufacturer"
              isClearable
            />
          </div>
          <div className={tw`mb-[12px]`}>
            <BasicInput
              onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                const inputValue = ev.target.value
                setImmunization((prev) => ({
                  ...prev,
                  lotNumber: inputValue.trim() ? inputValue : undefined,
                }))
              }}
              value={lotNumber || ''}
              placeholder="LOT #"
            />
          </div>
        </>
      )}
      <div className={tw`flex`}>
        <div className={tw`flex-1`}>
          <DatePicker
            customInput={
              <CalendarInput
                width="100%"
                data-testid="occurrence-date"
                showErrorBorder={showDateError}
              />
            }
            onChange={(d: Date | null) => {
              setImmunization((prev) => ({
                ...prev,
                occurrenceDate: d ? fromDateToDateMessage(d) : undefined,
              }))
              if (onDateChange) {
                onDateChange(d)
              }
            }}
            selected={
              occurrenceDate ? fromDateMessageToDate(occurrenceDate) : null
            }
            maxDate={new Date()}
            isClearable
            title={showDateError ? 'Date is required' : ''}
          />
          <LabelAboveInput
            className={tw`mt-[8px]`}
            subLabel={requiredWhenError(showDateError)}
            uppercase={false}
          />
        </div>
        <div
          className={twx({
            hidden: hideAddButton,
            'flex-0': !hideAddButton,
          })}
        >
          <Button
            data-testid="add-immunization-btn"
            buttonStyle={showAddBtnError ? 'danger-fill' : 'secondary-fill'}
            className={tw`ml-[12px]`}
            title="Add a new vaccination"
            disabled={!occurrenceDate}
            onClick={onAddClick}
          >
            Add
          </Button>
        </div>
      </div>
      <ButtonLink
        data-testid="show-all-fields-btn"
        className={tw`text-gray-06`}
        onClick={() => setShowAllFields((prev) => !prev)}
      >
        {showAllFields ? 'Hide optional fields...' : 'Show all fields...'}
      </ButtonLink>
    </EmbossedCard>
  )
}
