import {
  StyledOption,
  StyledOptionProps,
} from '@shared/components/StyledSelect'
import styles from './styles.module.css'
import { OptionProps } from './types'

export default function DropdownOption(props: StyledOptionProps) {
  const { label, facility } = props.data as OptionProps

  return (
    <StyledOption {...props}>
      <div className="flex items-baseline">
        <div className={styles.personName}>{label}</div>
        <div className={`uppercase text-gray-07 ${styles.facilityName}`}>
          {facility?.name}
        </div>
      </div>
    </StyledOption>
  )
}
