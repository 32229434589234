import { capitalize, isEmpty } from 'lodash'
import React from 'react'
import streetTypes from '@shared/constants/streetTypes'
import { Address } from '@shared/types/address'
import { nonEmptyJoin } from '@shared/utils/array'
import { abbreviateState } from '@shared/utils/state'

export function formatStreetAddress(address: Address): string {
  if (address.line) {
    return nonEmptyJoin(address.line, ' ')
  } else if (address.text) {
    return address.text
  }

  return ''
}

export function getCityStatePostalCode({ city, state, postalCode }: Address) {
  const abbrState = abbreviateState(state)
  const cityState = nonEmptyJoin([city, abbrState], ', ')
  return nonEmptyJoin([cityState, postalCode], ' ')
}

export function formatSingleLineAddress(
  address: Address | undefined
): string | undefined {
  if (!address) return undefined
  if (address.text) return address.text

  return nonEmptyJoin(
    [formatStreetAddress(address), getCityStatePostalCode(address)],
    ' '
  )
}

export function getAddressPartFromGeocoder(
  addressComponents: Array<google.maps.GeocoderAddressComponent>,
  type: string
): string {
  const a = addressComponents.find((ac) => ac.types.includes(type))
  return (a && a.long_name) || ''
}

export function getAddressFromGeocoder(
  addressComponents: Array<google.maps.GeocoderAddressComponent>
): Address {
  const streetNumber = getAddressPartFromGeocoder(
    addressComponents,
    'street_number'
  )
  const route = getAddressPartFromGeocoder(addressComponents, 'route')
  const city = getAddressPartFromGeocoder(addressComponents, 'locality')
  const sublocality = getAddressPartFromGeocoder(
    addressComponents,
    'sublocality'
  )
  const subpremise = getAddressPartFromGeocoder(addressComponents, 'subpremise')

  return {
    line: [`${streetNumber} ${route}`.trim(), subpremise.trim()],
    city: city || sublocality,
    state: getAddressPartFromGeocoder(
      addressComponents,
      'administrative_area_level_1'
    ),
    postalCode: getAddressPartFromGeocoder(addressComponents, 'postal_code'),
    text: null as unknown as undefined,
  }
}

export function formatInlineAddressWithoutUnit({
  line,
  city,
  state,
  postalCode,
}: Address) {
  return nonEmptyJoin(
    [line?.[0], city, nonEmptyJoin([abbreviateState(state), postalCode], ' ')],
    ', '
  )
}

export function getAddressErrors(address?: Address): Array<string> {
  const errorList: Array<string> = []

  if (address) {
    const { line = [], city, state, postalCode, text } = address

    if (text) {
      return errorList
    }

    if (!line[0]) {
      errorList.push('Street not found.')
    }
    if (!city) {
      errorList.push('City not found.')
    }
    if (!state) {
      errorList.push('State not found.')
    }
    if (!postalCode) {
      errorList.push('Postal Code not found.')
    }
  }

  return errorList
}

type GetAddressLabelOptions = {
  jsx?: boolean
  ignoreAddressLine2?: boolean
  abbreviated?: boolean
}

export function buildAddressLabel(
  { line = [], city, state, postalCode, text }: Address = {},
  { jsx, ignoreAddressLine2, abbreviated }: GetAddressLabelOptions = {}
): string | React.JSX.Element {
  if ([line, city, state, postalCode, text].every(isEmpty)) return ''
  if (text) return text

  if (abbreviated) {
    if (line[0]) {
      // the last word of the first line text will be the street type
      const match = line[0].match(/\w+$/)
      if (match) {
        line[0] = line[0].replace(
          /\w+$/,
          capitalize(abbreviateStreetType(match[0]))
        )
      }
    }

    if (state) {
      state = abbreviateState(state)
    }
  }

  const street = ignoreAddressLine2 ? line[0] : line.join(' ')
  const cityStateZip = nonEmptyJoin([city, state, postalCode], ', ')

  if (jsx) {
    return (
      <div>
        <div>{street}</div>
        <div>{cityStateZip}</div>
      </div>
    )
  }

  return nonEmptyJoin([street, cityStateZip], ', ')
}

export const abbreviateStreetType = (streetType: string): string => {
  const abbreviation = streetTypes[streetType.toUpperCase()] as
    | string
    | undefined

  if (abbreviation) {
    return capitalize(abbreviation)
  }

  return streetType
}
