import { RoutineOptions } from '@augusthealth/models/com/august/protos/api/routine_settings'
import { useCallback, useEffect, useState } from 'react'
import { FeatureFlagNames } from '@shared/constants/feature_flags'
import { useUserContext } from '@shared/contexts/UserContext'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import { Person } from '@shared/types/person'
import { Routine } from '@shared/types/routine'
import { RoutineOrder } from '@shared/types/routine_order'
import { AsyncResult, Loading } from '@shared/utils/loading'
import { isFeatureAllowed } from '@shared/utils/user'
import { getRoutineOrders } from '@app/api/routineOrders'
import {
  fetchRoutine,
  fetchRoutineOptions,
  fetchRoutines,
} from '../api/routines'

export default function useRoutines(
  { person }: { person: Person },
  deps: React.DependencyList = []
) {
  const [routines, setRoutines] = useState<Loading<Routine[]>>({
    tag: 'Loading',
  })
  const [routineOrders, setRoutineOrders] = useState<
    AsyncResult<RoutineOrder[], unknown>
  >({ tag: 'Loading' })

  const { user } = useUserContext()

  const includesAssessmentRoutineOrder = isFeatureAllowed(
    user,
    FeatureFlagNames.ASSESSMENT_ROUTINES_DEBUG
  )

  const refreshRoutines = useCallback(async () => {
    const newRoutines = await fetchRoutines(person)
    setRoutines({ tag: 'Complete', value: newRoutines })
  }, [person.id])

  const refreshRoutineOrders = useCallback(async () => {
    const response = await getRoutineOrders(person, {
      includesAssessmentRoutineOrder,
    })
    if (response.tag === 'Error') {
      ErrorMonitoring.capture({ error: response })
    }
    setRoutineOrders(response)
  }, [person.id, includesAssessmentRoutineOrder])

  useEffect(() => {
    void refreshRoutines()
  }, [person.id, ...deps])

  useEffect(() => {
    void getRoutineOrders(person, { includesAssessmentRoutineOrder }).then(
      (response) => {
        if (response.tag === 'Error') {
          ErrorMonitoring.capture({ error: response })
        }
        setRoutineOrders(response)
      }
    )
  }, [person.id, includesAssessmentRoutineOrder])

  return {
    routines,
    refreshRoutines,
    routineOrders,
    refreshRoutineOrders,
  }
}

export function useRoutine(
  { person, routineId }: { person: Person; routineId: string },
  deps: React.DependencyList = []
) {
  const [routine, setRoutine] = useState<Loading<Routine>>({
    tag: 'Loading',
  })

  const refreshRoutine = useCallback(async () => {
    const newRoutine = await fetchRoutine(person, routineId)
    setRoutine({ tag: 'Complete', value: newRoutine })
  }, [person.id, routineId])

  useEffect(() => {
    void refreshRoutine()
  }, [person.id, routineId, ...deps])

  return { routine, refreshRoutine }
}

export function useRoutineOptions(
  { person }: { person: Person },
  deps: React.DependencyList = []
) {
  const [routineOptions, setRoutineOptions] = useState<
    Loading<RoutineOptions[]>
  >({
    tag: 'Loading',
  })

  const refreshRoutineOptions = useCallback(async () => {
    const newRoutines = await fetchRoutineOptions({
      orgId: person.orgId!,
      id: person.facilityId!,
    })

    setRoutineOptions({ tag: 'Complete', value: newRoutines })
  }, [person.id])

  useEffect(() => {
    void refreshRoutineOptions()
  }, [person.id, ...deps])

  return { routineOptions, refreshRoutineOptions }
}
