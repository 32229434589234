import {
  OrgFacilitySettings,
  SettingsType,
} from '@augusthealth/models/com/august/protos/org_facility_settings'
import { SignableFormAssignment } from '@augusthealth/models/com/august/protos/signable_form'
import { useContext, useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { EmptyCard } from '@shared/components/EmptyCard'
import { SuperUserOnlyGate } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Loading } from '@shared/utils/loading'
import {
  findScheduledTaskForTaskDefinition,
  SettingsLevel,
  sortTaskDefinitions,
} from '@shared/utils/orgFacilitySettings'
import { expandStateAbbreviation, StateAbbreviation } from '@shared/utils/state'
import { getAssignmentsForState } from '@app/api/form'
import { fetchStateSettings } from '@app/api/orgFacilitySettings'
import PersonPageTitle from '@app/components/PersonPageTitle'
import useOrgFacilitySettings from '@app/hooks/useOrgFacilitySettings'
import { useSignableFormContext } from '@app/pages/Tools/PdfTemplate/SignableFormContext'
import TaskDefinitionModal from '@app/pages/Tools/TaskDefinitions/TaskDefinitionModal'
import TaskCard from '../TaskCard'
import { SuppressedTaskCard } from '../TaskCard/SuppressedTaskCard'

export default function StateTasks({
  match,
}: RouteComponentProps<{ state: string }>) {
  const { setError } = useContext(GlobalContext)
  const { state } = match.params
  const {
    settings: scheduledTaskSettings,
    reloadSettings: reloadScheduledTaskSettings,
  } = useOrgFacilitySettings({
    state,
    settingsType: SettingsType.SETTINGS_TYPE_SCHEDULED_TASK,
  })
  const { signableForms } = useSignableFormContext('All Templates')

  const [showGlobalTaskPopup, setShowGlobalTaskPopup] = useState(false)
  const [taskSettings, setTaskSettings] = useState<
    Loading<OrgFacilitySettings[]>
  >({ tag: 'Loading' })
  const [stateAssignments, setStateAssignments] = useState<
    Loading<SignableFormAssignment[]>
  >({ tag: 'Loading' })

  const getStateTaskSettings = () => {
    return fetchStateSettings({
      settingsType: SettingsType.SETTINGS_TYPE_TASK_DEFINITION,
      state,
    }).then((value) => setTaskSettings({ tag: 'Complete', value }))
  }
  const history = useHistory()

  const loadAssignments = async () => {
    if (state) {
      setStateAssignments({ tag: 'Loading' })
      void getAssignmentsForState(state as StateAbbreviation).then(
        (assignments) => {
          setStateAssignments({ tag: 'Complete', value: assignments })
        }
      )
    }
  }

  useEffect(() => {
    try {
      void loadAssignments()
      void getStateTaskSettings()
    } catch (e) {
      setError(e)
    }
  }, [])

  if (
    signableForms.tag === 'Loading' ||
    taskSettings.tag === 'Loading' ||
    stateAssignments.tag === 'Loading' ||
    scheduledTaskSettings.tag === 'Loading'
  ) {
    return <>Loading...</>
  }

  const stateName = expandStateAbbreviation(state)

  return (
    <div className="mt-[24px]">
      <PersonPageTitle subtitle title={`${stateName} Tasks`}>
        <AsyncIconButton
          buttonStyle="primary-outline"
          initialIcon="fa-arrow-left"
          buttonSize="small"
          onClick={() => history.goBack()}
        >
          Back
        </AsyncIconButton>
        <SuperUserOnlyGate>
          <AsyncIconButton
            buttonStyle="primary-fill"
            initialIcon="fa-plus"
            buttonSize="small"
            className="ml-[8px]"
            data-testid={`add-${state}-task`}
            onClick={() => setShowGlobalTaskPopup(true)}
          >
            Add {state} Task
          </AsyncIconButton>
        </SuperUserOnlyGate>
      </PersonPageTitle>
      {taskSettings.value.length === 0 && (
        <EmptyCard
          title={`Could not find any tasks for ${stateName}.`}
          version={{
            tag: 'GetStarted',
            cta: 'add one now',
            ctaFn: () => setShowGlobalTaskPopup(true),
          }}
        />
      )}

      {sortTaskDefinitions(taskSettings.value).map((t) => {
        if (!t.settings!.taskDefinition!.template) {
          return (
            <SuppressedTaskCard
              key={`state-suppressed-${state}-task-${t.key}`}
              taskSetting={t}
              reloadTaskSettings={getStateTaskSettings}
            />
          )
        }

        return (
          <TaskCard
            cardLevel={{ tag: 'State', state }}
            key={`state-${state}-task-card-${t.key}`}
            allAssignments={stateAssignments.value}
            formList={signableForms.value}
            loadAssignments={loadAssignments}
            name={state}
            onTaskDefinitionChange={() => getStateTaskSettings()}
            onScheduledTaskChange={async () => {
              await reloadScheduledTaskSettings()
            }}
            taskSettings={t}
            scheduledTask={findScheduledTaskForTaskDefinition({
              scheduledTasks: scheduledTaskSettings.value,
              taskDefinition: t,
              cardLevel: { tag: 'State', state },
            })}
          />
        )
      })}
      {showGlobalTaskPopup && (
        <TaskDefinitionModal
          name={stateName}
          defaultSettingsLevel={SettingsLevel.STATE_LEVEL}
          onClose={(updated: boolean) => {
            setShowGlobalTaskPopup(false)
            if (updated) {
              return getStateTaskSettings()
            }
            return Promise.resolve()
          }}
          cardLevel={{ tag: 'State', state }}
        />
      )}
    </div>
  )
}
