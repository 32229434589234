import { Routine } from '@augusthealth/models/com/august/protos/routine'
import { UnitOfTime } from '@augusthealth/models/com/august/protos/timing'
import { isNumber } from 'lodash'
import { useEffect, useState } from 'react'
import { CSSObjectWithLabel } from 'react-select'
import { EmbossedCard } from '@shared/components/EmbossedCard'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import StyledSelect, {
  modifiedStyles,
  OptionTypeBase,
} from '@shared/components/StyledSelect'
import { toRoutineSchedule } from '@shared/utils/timing'
import { ButtonGroup } from '@app/components/ButtonGroup'
import styles from '../styles.module.css'
import {
  dayOfWeekOptions,
  everydayOption,
  repetitionOptions,
  specificDaysOption,
} from '../constants'
import { getDefaultTiming, isEveryDayTiming, RoutineSchedule } from './helpers'
import TimeOfDayRow from './TimeOfDayRow'

interface ScheduleProps {
  onChange: (newSchedule: RoutineSchedule) => void
  routine: Routine
}

export default function Schedule({ onChange, routine }: ScheduleProps) {
  const { timing } = routine
  const defaultTiming = getDefaultTiming(routine)
  const defaultScheduleValue: RoutineSchedule = defaultTiming
    ? toRoutineSchedule(defaultTiming)
    : {
        morning: 'NoValue',
        afternoon: 'NoValue',
        night: 'NoValue',
      }

  const [scheduleValue, setScheduleValue] =
    useState<RoutineSchedule>(defaultScheduleValue)

  useEffect(() => onChange(scheduleValue), [scheduleValue])

  let defaultEveryOption: OptionTypeBase<number | string> | undefined
  if (timing) {
    if (timing.dayOfWeek) {
      defaultEveryOption = specificDaysOption
    } else if (isEveryDayTiming(timing)) {
      defaultEveryOption = everydayOption
    }
  }

  return (
    <>
      <LabelAboveInput
        className="horizontal baseline"
        htmlFor="frequency"
        subLabel={requiredLabel(false)}
      >
        Schedule
      </LabelAboveInput>
      <EmbossedCard>
        <div className="flex h-[48px] items-center">
          <label
            className="mb-0 mr-[8px] flex items-center p-0"
            htmlFor="schedule-every"
          >
            <i
              className={`fa fa-fw fa-calendar-alt mr-[12px] ${styles.frequencyRowIcon}`}
            />
            <span className="m-0 inline-block w-[124px] text-[14px] font-semibold leading-[18px]">
              Repeat
            </span>
          </label>
          <StyledSelect
            defaultValue={defaultEveryOption}
            className={`ml-0 mr-[12px]`}
            options={repetitionOptions}
            placeholder="Every"
            styles={customStyles}
            inputId={'schedule-every'}
            isClearable
            name={'schedule-every'}
            onChange={(newValue?: OptionTypeBase<string | number>) => {
              if (!newValue) {
                setScheduleValue((prev) => {
                  const clone = { ...prev }
                  delete clone.repeat
                  return clone
                })
              } else if (isNumber(newValue.value)) {
                const count = newValue.value

                setScheduleValue((prev) => ({
                  ...prev,
                  repeat: {
                    tag: 'Every',
                    count,
                    period: UnitOfTime.UNIT_OF_TIME_DAY,
                  },
                }))
              } else if (newValue.value === 'specificDays') {
                setScheduleValue((prev) => ({
                  ...prev,
                  repeat: { tag: 'Specific', days: [] },
                }))
              }
            }}
          />
          {scheduleValue.repeat?.tag === 'Specific' && (
            <ButtonGroup
              onChange={(selected) => {
                setScheduleValue((prev) => ({
                  ...prev,
                  repeat: {
                    tag: 'Specific',
                    days: selected,
                  },
                }))
              }}
              options={dayOfWeekOptions}
              chosen={
                timing?.dayOfWeek
                  ? (timing.dayOfWeek.map((d) => {
                      return dayOfWeekOptions.find(({ value }) => value === d)
                    }) as OptionTypeBase[])
                  : []
              }
            />
          )}
        </div>
        <TimeOfDayRow
          name={'Morning'}
          label={'Morning Shift'}
          initialValue={scheduleValue.morning}
          onChange={(value) =>
            setScheduleValue((previous) => ({ ...previous, morning: value }))
          }
        />
        <TimeOfDayRow
          name={'Afternoon'}
          label={'Afternoon Shift'}
          initialValue={scheduleValue.afternoon}
          onChange={(value) =>
            setScheduleValue((previous) => ({ ...previous, afternoon: value }))
          }
        />
        <TimeOfDayRow
          name={'Night'}
          label={'Night Shift'}
          initialValue={scheduleValue.night}
          onChange={(value) =>
            setScheduleValue((previous) => ({ ...previous, night: value }))
          }
        />
      </EmbossedCard>
    </>
  )
}

const customStyles = {
  ...modifiedStyles(),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    width: '200px',
  }),
}
