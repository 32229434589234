import { AugustInitialAppraisal } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import React from 'react'
import PersonPills from '@shared/components/PersonPills'
import { Person } from '@shared/types/person'
import { formatDateMessage } from '@shared/utils/date'
import { getFirstAndLastName } from '@shared/utils/humanName'
import {
  displayGender,
  getLevelOfCare,
  getRoomNumber,
} from '@shared/utils/person'
import { AvatarImage } from '@app/components/Layouts/PersonNavbarLayout/PersonHeader/Avatar'
import { admissionType } from '@app/components/Prospects/Details/AdmissionDetails/admissionDetailsHelpers'
import useAmbulatoryStatus from '@app/hooks/useAmbulatoryStatus'
import styles from './styles.module.css'
import {
  getDenturesPrintDisplay,
  getElopementRiskPrintDisplay,
  getFallRiskPrintDisplay,
  getGlassesPrintDisplay,
  getHearingAidPrintDisplay,
} from '../ResidentAssessment/helpers'

export default function ResidentTaskHeader({
  person,
  assessment,
}: {
  person: Person
  assessment?: AugustInitialAppraisal
}) {
  const { ambulatoryBadgeColor, ambulatoryStatus } = useAmbulatoryStatus(person)

  return (
    <div className={styles.personHeader}>
      <AvatarImage person={person} />
      <div className={styles.textContent}>
        <h1 className={styles.name}>
          {getFirstAndLastName(person.name)}
          <i className="fas fa-bed ml-[16px] mr-[4px]" />
          {getRoomNumber(person)}
        </h1>
        <div className={styles.dataGrid}>
          <InfoColumn>
            <InfoRow label={'Gender'} value={displayGender(person)} />
            <InfoRow
              label={'Birthday'}
              value={
                person.birthDate
                  ? formatDateMessage(person.birthDate)
                  : 'Not Specified'
              }
            />
            <InfoRow
              label={'Care Level'}
              value={`Level ${getLevelOfCare(person) || 'Unknown'}`}
            />
          </InfoColumn>
          <InfoColumn>
            <InfoRow
              label={'Fall Risk'}
              value={getFallRiskPrintDisplay(assessment)}
            />
            <InfoRow
              label={'Elopement Risk'}
              value={getElopementRiskPrintDisplay(assessment)}
            />
            <InfoRow
              label={'Admission Type'}
              value={admissionType(person) || 'Unknown'}
            />
          </InfoColumn>
          <InfoColumn>
            <InfoRow
              label={'Glasses'}
              value={getGlassesPrintDisplay(assessment)}
            />
            <InfoRow
              label={'Hearing Aid'}
              value={getHearingAidPrintDisplay(assessment)}
            />
            <InfoRow
              label={'Dentures'}
              value={getDenturesPrintDisplay(assessment)}
            />
          </InfoColumn>
          <PersonPills
            person={person}
            containerClass={styles.printBadges}
            ambulatoryBadgeColor={ambulatoryBadgeColor}
            ambulatoryStatus={ambulatoryStatus}
          />
        </div>
      </div>
    </div>
  )
}

function InfoColumn({ children }: { children: React.ReactNode }) {
  return <div className={styles.infoColumn}>{children}</div>
}

function InfoRow({ label, value }: { label: string; value: string }) {
  return (
    <>
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>{value}</span>
    </>
  )
}
