import { AugustFieldOption } from '@augusthealth/models/com/august/protos/api/json_report'
import { AugustFieldType } from '@augusthealth/models/com/august/protos/custom_field'
import {
  CustomDisplayField,
  DisplayField,
  DisplayPage,
} from '@augusthealth/models/com/august/protos/signable_form'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import { AUGUST_FIELD_WHITE_LIST } from '@shared/constants/custom_field/custom_field'

type DisplayPageKey = keyof DisplayPage
type DisplayFieldKey = keyof DisplayField
export type CustomDisplayFieldKey = keyof CustomDisplayField
type PageNamePath = `pages.${number}.${DisplayPageKey}`
type FieldNamePath = `pages.${number}.fields.${number}.${DisplayFieldKey}`
type CustomFieldNamePath =
  `pages.${number}.customFields.${number}.${CustomDisplayFieldKey}`
type FormConfigurationFieldNamePath =
  `pages.${number}.customFields.${number}.formFieldConfiguration.${string}`
type FormConfigurationOptionsPath =
  `pages.${number}.customFields.${number}.formFieldConfiguration.options.${string}`
export type Option = OptionTypeBase<AugustFieldType> & {
  category?: string
  description?: string
}
export type AugustValueGroupHash = Record<
  string,
  { label: string; options: Option[] }
>

export function getPageName(
  pageIndex: number,
  pageAttrName: DisplayPageKey
): PageNamePath {
  return `pages.${pageIndex}.${pageAttrName}`
}

export function getFieldName(
  pageIndex: number,
  fieldIndex: number,
  fieldName: DisplayFieldKey
): FieldNamePath {
  return `pages.${pageIndex}.fields.${fieldIndex}.${fieldName}`
}

export function getCustomFieldName(
  pageIndex: number,
  fieldIndex: number,
  customFieldName: CustomDisplayFieldKey
): CustomFieldNamePath {
  return `pages.${pageIndex}.customFields.${fieldIndex}.${customFieldName}`
}

export function getFormConfigurationFieldName(
  pageIndex: number,
  fieldIndex: number,
  subFieldName: string
): FormConfigurationFieldNamePath {
  return `pages.${pageIndex}.customFields.${fieldIndex}.formFieldConfiguration.${subFieldName}`
}

export function getFormConfigurationOptionsName(
  pageIndex: number,
  fieldIndex: number,
  optionIndex: number,
  subFieldName: string
): FormConfigurationOptionsPath {
  return `pages.${pageIndex}.customFields.${fieldIndex}.formFieldConfiguration.options.${optionIndex}.${subFieldName}`
}

export function getOptionFromField(augustValue: AugustFieldOption): Option {
  const { displayInfo, field: value } = augustValue
  const {
    displayName: label = 'Unknown',
    category = 'Other',
    description = '',
  } = displayInfo || {}

  return {
    category,
    isDisabled: !AUGUST_FIELD_WHITE_LIST.includes(value!),
    label,
    subLabel: description,
    value: value!,
  }
}
