import { PharmacyResidentIdentifier } from '@augusthealth/models/com/august/protos/pharmacy_message'
import { useContext, useEffect, useState } from 'react'
import {
  deletePharmacyAssignment,
  getPharmacyAssignments,
  GetPharmacyParameters,
} from '@shared/api/pharmacy_message'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Card from '@shared/components/Card'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, { OptionTypeBase } from '@shared/components/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { getOrElse, loaded, Loading } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import HUD from '@app/components/HUD'
import styles from '@app/pages/Tools/ResidentDocumentUploader/styles.module.css'
import { OrgFacilityPersonSelects } from '@app/pages/Tools/SharedSelects/OrgFacilityPersonSelects'
import useOrgFacilityPersonSelects from '@app/pages/Tools/SharedSelects/useOrgFacilityPersonSelects'

const UnassignResident = () => {
  const { setError } = useContext(GlobalContext)

  const {
    selectedOrganization,
    selectedFacility,
    selectedPerson,
    onOrgSelect,
    onFacilitySelect,
    onPersonSelect,
  } = useOrgFacilityPersonSelects()

  const orgId = selectedOrganization?.value.id
  const facilityId = selectedFacility?.value.id
  const personId = selectedPerson?.value.id

  const [pharmacyAssignments, setPharmacyAssignments] = useState<
    Loading<PharmacyResidentIdentifier[]>
  >({ tag: 'Loading' })

  const [selectedPharmacyAssignment, setSelectedPharmacyAssignment] =
    useState<OptionTypeBase<PharmacyResidentIdentifier> | null>(null)

  const [assignmentProcessed, setAssignmentProcessed] = useState<boolean>(false)

  const fetchPharmacyAssignmentsAndAddToState = async (
    props: GetPharmacyParameters
  ) => {
    try {
      const assignments = await getPharmacyAssignments(props)
      setPharmacyAssignments(loaded(assignments))
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    if (orgId && facilityId && personId) {
      void fetchPharmacyAssignmentsAndAddToState({
        orgId,
        facilityId,
        personId,
      })
    }
  }, [orgId, facilityId, personId])

  const deleteAssignment = async () => {
    if (selectedPharmacyAssignment && orgId && facilityId && personId) {
      try {
        await deletePharmacyAssignment({
          augustIdentifier: { orgId, facilityId, personId },
          pharmacyIdentifier: selectedPharmacyAssignment.value,
        })
        setAssignmentProcessed(true)
        setSelectedPharmacyAssignment(null)
      } catch (error) {
        setError(error)
      }
    }
  }

  const pharmacyResidentOptions = getOrElse(pharmacyAssignments, []).map(
    (f) => ({
      label: `${f.partner} | ${f.externalResidentIdentifier}`,
      value: f,
    })
  )

  return (
    <div className="content-holder">
      <h4 className="page-title mt-[24px]">Unassign Pharmacy Resident</h4>
      <div className={tw`flex flex-row gap-4`}>
        <Card className="mb-[16px] flex flex-1 flex-col gap-4">
          <OrgFacilityPersonSelects
            onOrgSelect={onOrgSelect}
            onFacilitySelect={onFacilitySelect}
            onPersonSelect={onPersonSelect}
            selectedOrganization={selectedOrganization}
            selectedFacility={selectedFacility}
            selectedPerson={selectedPerson}
            orgLabel={'I want to remove an assignment for...'}
          />
          <LabelAboveInput
            className={tw`pt-input`}
            htmlFor={'assignmentToDelete'}
          >
            Pharmacy assignment to remove
          </LabelAboveInput>
          <StyledSelect
            placeholder={'Select an assignment to delete'}
            value={selectedPharmacyAssignment}
            options={pharmacyResidentOptions}
            name="assignmentToDelete"
            id="assignmentToDelete"
            instanceId="assignmentToDelete"
            onChange={(o: OptionTypeBase<PharmacyResidentIdentifier>) =>
              setSelectedPharmacyAssignment(o)
            }
            isDisabled={pharmacyAssignments.tag === 'Loading'}
          />
          <AsyncIconButton
            buttonStyle={'danger-fill'}
            onClick={deleteAssignment}
            type={'button'}
            isLoading={false}
            className={styles.button}
            disabled={!selectedPharmacyAssignment}
          >
            Remove Assignment
          </AsyncIconButton>
          {assignmentProcessed && (
            <HUD onExpire={() => setAssignmentProcessed(false)}>
              Pharmacy assignment deleted
            </HUD>
          )}
        </Card>
        <div className={tw`flex-1`} />
      </div>
    </div>
  )
}

export default UnassignResident
