import { embedDashboard } from '@superset-ui/embedded-sdk'
import { useEffect, useRef } from 'react'
import environment from '@shared/environment'
import { tw } from '@shared/utils/tailwind'
import { DashboardRecord, fetchDashboardToken } from '@app/api/organizations'

export default function RemoteDashboard({
  dashboardRecord,
  orgId,
}: {
  dashboardRecord: DashboardRecord
  orgId: string
}) {
  const divRef = useRef<HTMLDivElement>(null)
  const { id: dashboardId } = dashboardRecord

  useEffect(() => {
    if (divRef.current) {
      void embedDashboard({
        id: dashboardId,
        supersetDomain: environment.superset.url,
        mountPoint: divRef.current,
        fetchGuestToken: () =>
          fetchDashboardToken(orgId, dashboardId).then((t) => t.accessToken!),
        debug: false,
        dashboardUiConfig: {
          hideTitle: true,
          hideChartControls: false,
          hideTab: true,
          filters: {
            expanded: false,
          },
        },
      })
    }
  }, [divRef?.current, orgId, dashboardRecord.id])

  const css = `
    iframe {
        height: calc(100vh - 92px);
        border: none;
        width: 100%;
        display: block;
    }
  `
  return (
    <div>
      <style>{css}</style>
      <div className={tw`w-full`} id="remote-dashboard-container" ref={divRef}>
        Loading...
      </div>
    </div>
  )
}
