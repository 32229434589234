import {
  OrgFacilitySettings,
  SettingsType,
} from '@augusthealth/models/com/august/protos/org_facility_settings'
import { DietSettings } from '@augusthealth/models/com/august/protos/settings/diet_settings'
import { useForm } from 'react-hook-form'
import { getFacilityDietSettings } from '@shared/api/facility_settings'
import { upsertSettings } from '@app/api/orgFacilitySettings'
import DraggableList from '@app/libraries/DragAndDrop/DraggableList'
import OrgFacilityLoaderSaver, {
  OnSubmit,
} from '../subComponents/OrgFacilityLoaderSaver'

export default function Diets() {
  const useFormReturn = useForm<DietSettings>()

  return (
    <OrgFacilityLoaderSaver
      pageTitle="Diet Settings"
      loaderTitle="I want to view Diet settings for..."
      saverTitle="Save Diet Settings at..."
      childrenTitle="Diet Type"
      saveBtnLabel="Save Diet Settings"
      hudText="Diet Settings Updated"
      useFormReturn={useFormReturn}
      path="dietType"
      loaderFunc={getFacilityDietSettings}
      loaderFuncMap={(res: OrgFacilitySettings) =>
        res.settings?.dietSettings?.dietType || []
      }
      onSubmit={({ ids, data }: OnSubmit) => {
        return upsertSettings({
          ...ids,
          settings: { dietSettings: data },
          settingsType: SettingsType.SETTINGS_TYPE_DIET_SETTINGS,
        })
      }}
    >
      <DraggableList
        path="dietType"
        useFormReturn={useFormReturn}
        addBtn="Add new diet"
      />
    </OrgFacilityLoaderSaver>
  )
}
