import classNames from 'classnames'
import { History } from 'history'
import { useContext, useState } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import ButtonLink from '@shared/components/ButtonLink'
import { EmbossedCard } from '@shared/components/EmbossedCard'
import { LabelAboveInput, RequiredLabel } from '@shared/components/Labels'
import { FeatureFlagNames } from '@shared/constants/feature_flags'
import { useUserContext } from '@shared/contexts/UserContext'
import { carePathForPerson } from '@shared/legacy_routes'
import { PersonIds } from '@shared/types/person'
import { CategoryTasks } from '@shared/types/routine'
import { ServicePlan_PlanCategoryKey } from '@shared/types/service_plan'
import { DataType } from '@shared/types/snapshot'
import { tw } from '@shared/utils/tailwind'
import { isFeatureAllowed } from '@shared/utils/user'
import { getLatestRoutineOrder } from '@app/api/routineOrders'
import ConfirmModal, {
  Props as ConfirmModalProps,
} from '@app/components/ConfirmModal'
import PersonPageTitle from '@app/components/PersonPageTitle'
import { residentEditRoutinePath } from '@app/components/Residents'
import footerStyles from '@app/components/Residents/Medications/Orders/styles.module.css'
import PersonContext from '@app/contexts/PersonContext'
import useAssessment from '@app/hooks/useAssessment'
import { recreateTaskAndReturnUrl } from '@app/pages/Documents/helpers'
import { toFullDescription } from '../components/RoutineDescription'
import { EditAssessmentCategory } from '../EditAssessmentCategory'
import { Description } from '../ScheduleRoutine'
import { DynamicFrequencyRoutines } from './DynamicFrequencyRoutines'
import { RoutineOrderFormData } from './RoutineOrderForm'

export type RoutineOrderFormAssessmentData = {
  categoryTasks: CategoryTasks[]
}

export type Props = {
  title: string
  history: History<unknown>
  methods: UseFormReturn<RoutineOrderFormData, unknown, undefined>
  onSubmit: (onValid: RoutineOrderFormData) => Promise<void>
  personIds: Required<PersonIds>
  assessmentData: RoutineOrderFormAssessmentData
  routineOrderGroupId: string
  disabled?: boolean
}

export default function AssessmentRoutineOrderForm({
  title,
  history,
  methods,
  onSubmit,
  personIds,
  assessmentData,
  routineOrderGroupId,
  disabled = false,
}: Props) {
  const fieldHasError = (field: keyof RoutineOrderFormData) => {
    return methods.formState.errors[field] !== undefined
  }

  const { person } = useContext(PersonContext)
  const { assessment, refreshAssessment } = useAssessment({
    person: person!,
    withOriginalSettings: true,
  })
  const allowInlineEdits =
    assessment.tag === 'Complete' &&
    assessment.value.newerSettingsAvailable === false

  const [showEditAssessmentCategory, setShowEditAssessmentCategory] =
    useState<ServicePlan_PlanCategoryKey>()

  const [confirmationModalProps, setConfirmationModalProps] =
    useState<ConfirmModalProps>()

  const { user } = useUserContext()

  const includesAssessmentRoutineOrder = isFeatureAllowed(
    user,
    FeatureFlagNames.ASSESSMENT_ROUTINES_DEBUG
  )

  async function handleEdit(d: Description) {
    if (allowInlineEdits) {
      setShowEditAssessmentCategory(d.categoryKey)
    } else {
      setConfirmationModalProps((existing) => ({
        ...existing,
        title: 'Editing not available',
        content:
          'The assessment questions changed since your last edits. To make edits, start a new assessment.',
        confirmButtonConfig: {
          children: 'Start new assessment',
          buttonStyle: 'danger-fill',
          onClick: async () => {
            const url = await recreateTaskAndReturnUrl(
              person!,
              undefined,
              DataType.DATA_TYPE_AUGUST_INITIAL_APPRAISAL
            )
            history.push(url)
          },
        },
        denyButtonConfig: {
          onClick: () => setConfirmationModalProps(undefined),
        },
      }))
    }
  }

  function SaveButton() {
    return (
      <AsyncIconButton
        id="save-routine-order"
        type="submit"
        buttonStyle="primary-fill"
        width="152px"
        disabled={methods.formState.isSubmitting}
      >
        Save
      </AsyncIconButton>
    )
  }

  function CancelButton() {
    return (
      <AsyncIconButton
        id="cancel-routine-order-changes"
        buttonStyle="secondary-outline"
        type="button"
        width="152px"
        disabled={methods.formState.isSubmitting}
        onClick={async () => history.push(carePathForPerson(personIds))}
      >
        Cancel
      </AsyncIconButton>
    )
  }

  function FormActions() {
    return (
      <footer className={classNames(footerStyles.footer)}>
        <div className="mx-auto flex w-[720px] max-w-[720px] justify-between">
          <>
            <CancelButton />
            <SaveButton />
          </>
        </div>
      </footer>
    )
  }

  const categoryTasks = assessmentData.categoryTasks
  const fullDescription: Description[] =
    categoryTasks && toFullDescription(categoryTasks)

  async function onComplete(updated: boolean) {
    if (updated) {
      await refreshAssessment()
      const updatedOrder = await getLatestRoutineOrder(
        personIds,
        routineOrderGroupId,
        {
          includesAssessmentRoutineOrder,
        }
      )
      if (updatedOrder.tag === 'Complete') {
        history.push(residentEditRoutinePath(personIds, updatedOrder.value.id))
      }
    }

    setShowEditAssessmentCategory(undefined)
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className={tw`mb-28 ml-auto mr-auto mt-8 h-fit w-[720px]`}>
            <PersonPageTitle title={title} />
            <LabelAboveInput className={tw`mt-input`} htmlFor="name">
              Routine Name <RequiredLabel showError={fieldHasError('name')} />
            </LabelAboveInput>
            <BasicInput
              {...methods.register('name', {
                required: true,
                disabled: disabled,
              })}
              readOnly={true}
            />
            <LabelAboveInput className={tw`mt-input`} htmlFor="instructions">
              Instructions
            </LabelAboveInput>
            <EmbossedCard>
              {fullDescription.map((d, i) => {
                const lastOne = fullDescription.length - 1 === i
                const sectionClasses = classNames(
                  'text-[14px] leading-[20px] font-bold',
                  {
                    'mb-[24px]': !lastOne,
                  }
                )

                return (
                  <section
                    className={sectionClasses}
                    key={`${d.categoryTitle}-${i}`}
                  >
                    <div className={'uppercase'}>
                      {d.categoryTitle}
                      <ButtonLink
                        className={'ml-[8px]'}
                        onClick={async () => handleEdit(d)}
                      >
                        edit
                      </ButtonLink>
                    </div>
                    <div className={'mt-[8px] font-medium'}>
                      <span className={'font-semibold'}>{d.categoryLevel}</span>
                      {d.notes && <span> &ndash; {d.notes}</span>}
                      <ul>
                        {d.details.map((d, j) => (
                          <li key={`${j}`}>
                            <span>{d}</span>
                          </li>
                        ))}
                        {d.options.map((o, j) => (
                          <li key={`${j}`}>
                            <span>{o}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </section>
                )
              })}
            </EmbossedCard>
            <LabelAboveInput className={tw`mt-input`}>
              Schedule <RequiredLabel showError={fieldHasError('schedules')} />
            </LabelAboveInput>
            <DynamicFrequencyRoutines index={0} readOnly={disabled} />
          </div>

          <FormActions />
        </form>
      </FormProvider>
      {showEditAssessmentCategory && assessment.tag === 'Complete' && (
        <EditAssessmentCategory
          person={person!}
          categoryKey={showEditAssessmentCategory}
          assessment={assessment.value}
          onComplete={onComplete}
        />
      )}
      {confirmationModalProps && <ConfirmModal {...confirmationModalProps} />}
    </>
  )
}
