import StyledSelect, {
  OptionTypeBase,
  SingleValue,
  StyledOption,
  StyledOptionProps,
  StyledSelectProps,
} from '@shared/components/StyledSelect'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { twx } from '@shared/utils/tailwind'
import {
  getFacilityOrgOptions,
  getSelectedCommunityOption,
  OrgOrFacility,
} from './helpers'

type Props = Omit<StyledSelectProps, 'onChange'> & {
  includeOrgOption: boolean
  onChange: (fId: string | null) => void
  facility: Facility
  selectedFacilityId?: string
  organization: Organization
}

function OrgFacilityOption(props: StyledOptionProps) {
  const { data } = props
  const orgOrFacility = (data as OptionTypeBase<OrgOrFacility>).value

  return (
    <StyledOption
      {...props}
      labelClassName={twx({ 'ml-[24px]': 'orgId' in orgOrFacility })}
    />
  )
}

export default function OrgFacilitySelect(props: Props) {
  const {
    onChange,
    includeOrgOption,
    facility,
    selectedFacilityId,
    organization,
    ...restProps
  } = props
  const { facilityOptions, orgFacilityOptions } = getFacilityOrgOptions({
    facility,
    organization,
  })

  return (
    <StyledSelect
      onChange={(opt: OptionTypeBase<OrgOrFacility>) => {
        const orgOrFacility = opt.value
        const isFacility = 'orgId' in orgOrFacility
        if (isFacility) {
          onChange(orgOrFacility.id)
        } else {
          onChange(null) // reset facilityId
        }
      }}
      options={includeOrgOption ? orgFacilityOptions : facilityOptions}
      value={getSelectedCommunityOption({
        fId: selectedFacilityId || '',
        options: orgFacilityOptions,
      })}
      inputId="facilityId"
      components={{
        Option: includeOrgOption ? OrgFacilityOption : StyledOption,
        SingleValue,
      }}
      {...restProps}
    />
  )
}
