import { List } from '@augusthealth/august-frontend-form-elements'
import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { RELATIONSHIPS_BY_CATEGORY_MAP } from '@shared/constants/contactRelationship'
import './style.css'
import ContactDropdown, { ContactDropdownProps } from '../ContactDropdown'
import { getRelationshipTypeFromName } from '../ContactDropdown/helpers'

export type ContactDropdownListProps = Omit<ContactDropdownProps, 'value'> & {
  value?: Contact[]
}

export default function ContactDropdownList(props: ContactDropdownListProps) {
  const {
    name,
    value = [],
    person,
    relationshipsToExclude,
    relationshipsToInclude,
    onUpdate,
    onlyAllowToAdd,
    placeholder,
    hideRemoveBtn,
    reloadData,
    onRemove,
    useStandardUpdate,
    onAddContact,
  } = props

  const defaultRemove = (currentContact) => {
    const relationshipType = getRelationshipTypeFromName(name)
    if (relationshipType && currentContact && typeof onUpdate === 'function') {
      const { contact = [] } = person
      const contactFiltered = contact.filter((c) =>
        c.relationship?.includes(relationshipType)
      )
      const contactIndex = contactFiltered.findIndex(
        (c) => c.id === currentContact.id
      )
      if (contactIndex > -1) {
        contactFiltered[contactIndex].relationship = contactFiltered[
          contactIndex
        ].relationship?.filter((r) => r !== relationshipType)
        onUpdate(
          contactFiltered[contactIndex],
          name.replace('[*]', `[${contactIndex}]`)
        )
      }
    }
  }

  const remove = onRemove || defaultRemove

  const includes =
    typeof relationshipsToInclude === 'string'
      ? RELATIONSHIPS_BY_CATEGORY_MAP[relationshipsToInclude]
      : relationshipsToInclude

  const excludes =
    typeof relationshipsToExclude === 'string'
      ? RELATIONSHIPS_BY_CATEGORY_MAP[relationshipsToExclude]
      : relationshipsToExclude

  let valueFiltered = [...value]

  if (Array.isArray(includes)) {
    valueFiltered = valueFiltered.filter(
      (c) =>
        c && c.relationship && c.relationship.some((r) => includes.includes(r))
    )
  }

  if (Array.isArray(excludes)) {
    valueFiltered = valueFiltered.filter(
      (c) =>
        c && c.relationship && !c.relationship.some((r) => excludes.includes(r))
    )
  }

  return (
    <List
      {...props}
      className="no-style"
      item={ContactDropdown}
      sharedProps={{
        inList: true,
        person: person,
        onRemove: hideRemoveBtn ? undefined : remove,
        contactsToExclude: valueFiltered,
        relationshipsToExclude,
        relationshipsToInclude,
        placeholder,
        hideRemoveBtn,
        onlyAllowToAdd,
        reloadData,
        useStandardUpdate,
        onAddContact,
      }}
      defaultItem={{}}
      onRemove={remove}
      value={valueFiltered}
      hideRemoveBtn
      useEmptyFieldToAddNewOption
    />
  )
}
