import { MedicationOrder } from '@shared/types/medication_order'
import { getMedicationTitleAndSubTitle } from '@shared/utils/medicationStatement'
import MedMetadata from '@app/components/Residents/Medications/Orders/MedicationOrderDetails/MedMetadata'
import styles from './review_modal.module.css'

type Props = {
  order: MedicationOrder
}

const UnassignedOrder = ({ order }: Props) => {
  const { title, subTitle } = getMedicationTitleAndSubTitle(order)

  return (
    <div className={styles.orderContainer}>
      <div className={styles.medName}>
        {title}
        {subTitle && <span className={styles.medDosage}>{subTitle}</span>}
      </div>

      <div className={styles.medInstructions}>
        {order.medicationStatement.dosageInstruction!.map((dose, index) => (
          <span
            key={`${
              order.medicationStatement.medication!.drugName
            }-${index}-dosage-instructions`}
            className={'block'}
          >
            {dose.text}
          </span>
        ))}
      </div>
      <div className={styles.orderFooter}>
        <MedMetadata
          value={order.medicationStatement.rxNumber}
          iconClasses={'fa-prescription'}
          testId={'rx-number'}
        />
        <MedMetadata
          value={order.medicationStatement.pharmacy}
          iconClasses={'fa-house-medical'}
          testId={'pharmacy'}
        />

        <MedMetadata
          value={order.medicationStatement.prescriber?.toLowerCase()}
          iconClasses={'fa-user-doctor-hair-long'}
        />
      </div>
    </div>
  )
}

export default UnassignedOrder
