import { useContext, useEffect, useState } from 'react'
import Badge from '@shared/components/Badge'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { Organization } from '@shared/types/organization'
import { Loading } from '@shared/utils/loading'
import { getOrganization } from '@app/api/organizations'
import Content from '@app/components/generic/Content'
import HUD from '@app/components/HUD'
import OrgTitleTabs from '../OrgTitleTabs'
import { Props } from '../type'
import VersionInfo from '../VersionInfo'
import OrgDetails from './OrgDetails'
import QuickBooks from './QuickBooks/quick_books'

export default function GeneralPage(props: Props) {
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const { match } = props
  const { orgId } = match.params
  const [notification, setNotification] = useState<string>()
  const [organization, setOrganization] = useState<Loading<Organization>>({
    tag: 'Loading',
  })
  const reloadOrganization = () => {
    return getOrganization(orgId)
      .then((value) => setOrganization({ tag: 'Complete', value }))
      .catch(setError)
  }

  useEffect(() => {
    document.title = 'General - Org Settings'
  }, [])

  useEffect(() => {
    if (orgId) {
      void reloadOrganization()
    }
  }, [orgId])

  return (
    <Content>
      <OrgTitleTabs activeTab="General" orgId={orgId} />
      {organization.tag === 'Complete' && (
        <div className="mb-[32px]">
          <OrgDetails
            organization={organization.value}
            reload={reloadOrganization}
          />
        </div>
      )}
      {(user.featureFlags?.includes('quickbooks') || false) && (
        <>
          <div className="flex-holder evenly mb-[8px] mt-[32px]">
            <label className="form-control-label ml-[16px]">
              <div className="form-element-title uppercase">
                Quickbooks Integration
                <Badge className="ml-[8px]" shape={'square'} color={'green'}>
                  Beta
                </Badge>
              </div>
            </label>
          </div>
          <div>
            <QuickBooks orgId={orgId} />
          </div>
          <div className="flex-holder evenly mb-[8px] mt-[32px]">
            <label className="form-control-label ml-[16px]">
              <div className="form-element-title uppercase">Software</div>
            </label>
          </div>
        </>
      )}
      <VersionInfo />
      {notification && (
        <HUD
          onExpire={() => setNotification(undefined)}
          className="animate-fade-in"
        >
          {notification}
        </HUD>
      )}
    </Content>
  )
}
