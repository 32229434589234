import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { UnauthedUserSessionSynchronizer } from '@shared/components/Auth/UnauthedUserSessionSynchronizer'
import { ErrorModalBeforeLogin } from '@shared/components/ErrorModal/ErrorModal'
import {
  forgotPasswordPath,
  loginPath,
  logoutPath,
  magicEmailPath,
  MAIN_APP_BASE_PATH,
} from '@shared/routes'
import { twx } from '@shared/utils/tailwind'
import Login from './Login'
import ForgotPassword from './LoginWithUsernameOrEmail/ForgotPassword/ForgotPassword'
import LoginWithUsernameOrEmail, {
  DEFAULT_LOGIN_TITLE,
} from './LoginWithUsernameOrEmail/LoginWithUsernameOrEmail'
import Logout from './Logout'

/**
 * @param basePath - Application base path (must end with trailing '/')
 */
export default function LoginRouter({
  title = DEFAULT_LOGIN_TITLE,
  applicationBasePath = MAIN_APP_BASE_PATH,
}: {
  title?: string
  applicationBasePath?: string
}) {
  return (
    <div className={twx(`flex h-screen items-center justify-center`)}>
      <UnauthedUserSessionSynchronizer>
        <BrowserRouter key="login-router">
          <Switch key="error-popup">
            <Route path="*">
              <ErrorModalBeforeLogin />
            </Route>
          </Switch>
          <Switch key="magic-link">
            <Route
              path={magicEmailPath(applicationBasePath)}
              component={() => (
                <Login applicationBasePath={applicationBasePath} />
              )}
            />
            <Route
              exact
              path={loginPath(applicationBasePath)}
              component={() =>
                LoginWithUsernameOrEmail({
                  title,
                  applicationBasePath,
                })
              }
            />
            <Route
              path={forgotPasswordPath(applicationBasePath)}
              component={() => (
                <ForgotPassword applicationBasePath={applicationBasePath} />
              )}
            />
            <Route
              path={logoutPath(applicationBasePath)}
              exact
              component={() => <Logout />}
            />
            <Route
              path={'*'}
              component={() =>
                LoginWithUsernameOrEmail({
                  title,
                  applicationBasePath,
                })
              }
            />
          </Switch>
        </BrowserRouter>
      </UnauthedUserSessionSynchronizer>
    </div>
  )
}
