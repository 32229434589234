import { UnitOfTime } from '@augusthealth/models/com/august/protos/timing'
import { useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, useFormContext } from 'react-hook-form'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { Label } from '@shared/components/Labels'
import {
  fromDateMessageToDate,
  fromDateToDateMessage,
} from '@shared/utils/date'
import { CustomPeriodUnits } from '@shared/utils/medicationStatement'
import { MedOrderFormData } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'

type Props = {
  index: number
  readOnly: boolean
}
export const OnThisDay = ({ index, readOnly }: Props) => {
  const { register, unregister, control, setValue, getValues } =
    useFormContext<MedOrderFormData>()

  useEffect(() => {
    register(`doses.${index}.period`)
    setValue(`doses.${index}.period`, 1)
    return () => {
      if (
        getValues(`doses.${index}.periodUnit`) !==
        CustomPeriodUnits.SPECIFIC_DAYS
      ) {
        unregister(`doses.${index}.onThisDate`)
      }
    }
  }, [])

  return (
    <Controller
      control={control}
      name={`doses.${index}.onThisDate`}
      rules={{ required: true }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <Label visuallyHidden htmlFor={`doses.${index}.onThisDate`}>
            {'On this day'}
          </Label>
          <DatePicker
            name={`doses.${index}.onThisDate`}
            customInput={
              <CalendarInput
                showErrorBorder={!!error}
                data-testid={`doses.${index}.onThisDate`}
              />
            }
            onChange={(e: Date) => {
              if (e) {
                const date = fromDateToDateMessage(e)
                onChange(date)
                setValue('effectivePeriod.startDate', date)
                setValue('effectivePeriod.endDate', date)
                setValue(`doses.${index}.duration`, 1)
                setValue(
                  `doses.${index}.durationUnit`,
                  UnitOfTime.UNIT_OF_TIME_DAY
                )
              }
            }}
            selected={fromDateMessageToDate(
              value ?? getValues('effectivePeriod.startDate')
            )}
            disabled={readOnly}
          />
        </>
      )}
    />
  )
}
