import { ValueOf } from 'type-fest'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import states from '@shared/constants/states'

export type StateName = keyof typeof states
export type StateAbbreviation = ValueOf<typeof states>

export const stateOptions: Array<OptionTypeBase<StateAbbreviation>> =
  Object.entries(states).map(([name, abbreviation]) => {
    return {
      label: name,
      value: abbreviation,
    }
  })

export const stateOptionForAbbreviation = (
  abbreviation: string | null | undefined
): OptionTypeBase<StateAbbreviation> | undefined => {
  return stateOptions.find((option) => option.value === abbreviation)
}

export const expandStateAbbreviation = (
  abbreviation: string
): string | undefined =>
  Object.keys(states).find((key) => states[key] === abbreviation) || undefined

export const abbreviateState = (state: string | undefined): string =>
  (states[state ?? ''] as string | undefined) ?? state ?? ''
