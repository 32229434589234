import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import localForage from 'localforage'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { download } from '@shared/api/legacy'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { hasPermissionForFacility } from '@shared/components/PermissionGates/PermissionGates'
import SearchBox from '@shared/components/SearchBox'
import { FeatureFlagNames } from '@shared/constants/feature_flags'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import {
  detailsPathForPerson,
  getMovedOutResidentsUrl,
} from '@shared/legacy_routes'
import { Incident } from '@shared/types/incidents'
import { ResidentStatus } from '@shared/types/person'
import { Order } from '@shared/utils/common'
import { isDateWithinRange } from '@shared/utils/date'
import { hasActiveAlert } from '@shared/utils/incident'
import {
  personNameMatchesSearch,
  personRoomMatchesSearch,
} from '@shared/utils/person'
import { canReceiveEmail, isFeatureAllowed } from '@shared/utils/user'
import { requestBulkFaceSheet } from '@app/api/facesheet'
import { careSummaryBulkUrl } from '@app/api/routines'
import { requestBulkServicePlans } from '@app/api/servicePlan'
import AddPersonPopup from '@app/components/AddPersonPopup/AddPersonModal'
import { DownloadMenu } from '@app/components/DownloadMenu'
import HUD from '@app/components/HUD'
import LowPriorityLink from '@app/components/LowPriorityLink'
import { DownloadBulkMarModal } from '@app/components/Residents/Medications/PaperMar/DownloadMarModal'
import { Table, TableContainer } from '@app/components/Table'
import TableHeader, {
  getTableHeaderOnToggle,
} from '@app/components/Table/TableHeader'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import useReportMenuAndPreview from '@app/hooks/useReportMenuAndPreview'
import styles from '../list.module.css'
import {
  ColumnName,
  getResidentSortFunc,
  NameToSort,
  onNameSortingToggle,
  residentHeaders,
  ResidentTableColumn,
  routineMenuItems,
  setCareSummaryReportFileName,
  sortPeopleByName,
  STORAGE_NAME_TO_SORT,
  STORAGE_PREVIOUS_COLUMN,
} from './helpers'
import OnAlertFilter from './OnAlertFilter'
import { Placeholder } from './Placeholder'
import ResidentTableBody from './ResidentTableBody'

interface Props {
  match: { params: { facilityId: string; orgId: string } }
}

const STORAGE_RESIDENT_COLUMN = 'residentListSelectedColumn'
const STORAGE_RESIDENT_ORDER = 'residentListColumnOrder'
const SEARCH_PLACEHOLDER = 'Name or room number...'

export default function ResidentList({ match }: Props) {
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const history = useHistory()
  const { facilityId, orgId } = match.params
  const { currentFacility, residents, movedOutResidents, refreshResidents } =
    useCurrentFacility()
  const [showDownloadBulkMar, setShowDownloadBulkMar] = useState(false)
  const [openAddResidentPopup, setOpenAddResidentPopup] = useState(false)
  const [nameFilter, setNameFilter] = useState<string>('')
  const [filterOnAlert, setFilterOnAlert] = useState(false)
  const [showHUD, setShowHUD] = useState<'BulkFacesheet' | 'BulkServicePlan'>()
  const [selectedColumn, setSelectedColumn] = useState<ResidentTableColumn>(
    ResidentTableColumn.NAME
  )
  const [sortingOrder, setSortingOrder] = useState<Order>(Order.ASC)
  const [nameToSort, setNameToSort] = useState<NameToSort>('LastName')
  const [previousColumn, setPreviousColumn] = useState<ColumnName | undefined>(
    undefined
  )
  const includesAssessmentRoutineOrder = isFeatureAllowed(
    user,
    FeatureFlagNames.ASSESSMENT_ROUTINES_DEBUG
  )

  const { reportMenuItems, previewLightbox } = useReportMenuAndPreview({
    facility: { id: facilityId, orgId },
  })

  useEffect(() => {
    void refreshResidents(orgId, facilityId)
  }, [facilityId, orgId])

  useEffect(() => {
    void localForage.getItem(STORAGE_RESIDENT_COLUMN).then((column) => {
      setSelectedColumn(column as ResidentTableColumn)
    })
    void localForage.getItem(STORAGE_RESIDENT_ORDER).then((order) => {
      setSortingOrder(Number(order))
    })
    void localForage.getItem(STORAGE_NAME_TO_SORT).then((name) => {
      setNameToSort(name as NameToSort)
    })
    void localForage.getItem(STORAGE_PREVIOUS_COLUMN).then((previousCol) => {
      setPreviousColumn(previousCol as ColumnName)
    })
  }, [])

  const redirectToNewResident = (
    prospectFacilityId?: string | undefined,
    prospectId?: number | undefined
  ): void => {
    setOpenAddResidentPopup(false)

    if (prospectFacilityId && prospectId) {
      history.push(
        detailsPathForPerson({
          orgId,
          facilityId: prospectFacilityId,
          id: `${prospectId}`,
          residentStatus: ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT,
        })
      )
    }
  }

  if (
    residents.tag === 'Loading' ||
    currentFacility?.tag === 'Loading' ||
    currentFacility === undefined
  ) {
    return <LoadingTable />
  }

  const filteredResidents = residents.value.filter(
    (resident) =>
      personRoomMatchesSearch(resident.person!, nameFilter) ||
      personNameMatchesSearch(resident.person!, nameFilter)
  )

  const sortedResidents =
    selectedColumn === 'Name'
      ? sortPeopleByName(filteredResidents, sortingOrder, nameToSort)
      : getResidentSortFunc(selectedColumn)(filteredResidents, sortingOrder)
  const downloadMenuItems = [
    {
      isDisabled: false,
      isVisible: canReceiveEmail({
        checkPermissionFunc: () =>
          hasPermissionForFacility({
            user,
            facility: { orgId, id: facilityId },
            permissions: [GroupPermission.GROUP_PERMISSION_SNAPSHOT_READ],
          }),
        user,
      }),
      title: 'Service Plans',
      id: 'downloadServicePlans',
      dataTestId: 'download-service-plan-btn',
      onClick: async () => {
        try {
          await requestBulkServicePlans({
            orgId,
            id: facilityId,
          })
          setShowHUD('BulkServicePlan')
        } catch (e) {
          setError(e)
        }
      },
    },
    {
      isDisabled: false,
      isVisible: canReceiveEmail({
        checkPermissionFunc: () =>
          hasPermissionForFacility({
            user,
            facility: { orgId, id: facilityId },
            permissions: [GroupPermission.GROUP_PERMISSION_FACESHEET_READ],
          }),
        user,
      }),
      title: 'Facesheets',
      id: 'downloadFacesheet',
      dataTestId: 'download-facesheet-btn',
      onClick: async () => {
        try {
          await requestBulkFaceSheet({
            orgId,
            id: facilityId,
          })
          setShowHUD('BulkFacesheet')
        } catch (e) {
          setError(e)
        }
      },
    },
    {
      isDisabled: false,
      isVisible: hasPermissionForFacility({
        user,
        facility: { orgId, id: facilityId },
        permissions: [GroupPermission.GROUP_PERMISSION_ROUTINE_READ],
      }),
      id: 'downloadAllCareSummary',
      onClick: async () => {
        try {
          const fileUrl = careSummaryBulkUrl({
            orgId,
            facilityId,
            options: { includesAssessmentRoutineOrder },
          })

          const { value: { name } = {} } = currentFacility!

          const fileName = setCareSummaryReportFileName({
            facilityName: name,
          })

          await download({
            fileUrl,
            fileName,
          })
        } catch (e) {
          setError(e)
        }
      },
      title: 'Care Summary',
      dataTestId: 'download-care-summary-btn',
    },
    {
      isDisabled: false,
      isVisible: canReceiveEmail({
        checkPermissionFunc: () =>
          hasPermissionForFacility({
            user,
            facility: { orgId, id: facilityId },
            permissions: [GroupPermission.GROUP_PERMISSION_MAR_READ],
          }),
        user,
      }),
      id: 'downloadAllMar',
      onClick: async () => setShowDownloadBulkMar(true),
      title: 'MAR',
      dataTestId: 'download-mar-btn',
    },
    ...routineMenuItems({
      facility: { orgId, id: facilityId },
      user,
      setError,
    }),
  ]

  const residentsWithAlerts = sortedResidents.filter((r) =>
    (r.alerts ?? []).some(hasActiveAlert)
  )
  const alertCount = residentsWithAlerts.length
  const newAlerts = residentsWithAlerts.reduce(
    (alerts: Incident[], resident) => {
      const residentAlerts = (resident.alerts ?? [])
        .filter(hasActiveAlert)
        .filter((i) =>
          isDateWithinRange(
            new Date(i.updatedBy?.modificationTime as string),
            -1
          )
        )
      return alerts.concat(residentAlerts)
    },
    []
  )
  const clearFilterAndSearch = (value: string) => {
    setFilterOnAlert(false)
    setNameFilter(value)
  }

  const hasResidents = residents.value.length > 0
  const hasNoVisibleResidents = filteredResidents.length === 0

  const menuItems = [...downloadMenuItems, ...reportMenuItems].sort((a, b) =>
    a.title.localeCompare(b.title, 'en', { sensitivity: 'base' })
  )

  return (
    <>
      <div className={`${styles.residentTitle} horizontal`}>
        <div>Residents</div>
        <div className={styles.tableButtons}>
          {hasResidents && (
            <SearchBox
              onChange={
                hasNoVisibleResidents ? setNameFilter : clearFilterAndSearch
              }
              placeholder={SEARCH_PLACEHOLDER}
              labelText="Search residents"
              value={nameFilter}
            />
          )}
          {hasResidents && (
            <DownloadMenu
              menuItems={menuItems}
              menuProps={{
                buttonProps: {
                  buttonStyle: 'secondary-fill',
                  dataTestId: 'download-residents-data',
                  dataCy: 'download-residents-data',
                  classNames: 'ml-[12px]',
                },
                defaultIconName: 'file-lines',
              }}
              includeSearchBar
            />
          )}
        </div>
      </div>
      {alertCount > 0 && nameFilter.length === 0 && (
        <OnAlertFilter
          alertCount={alertCount}
          newAlertCount={newAlerts.length}
          filterOnAlert={filterOnAlert}
          setFilterOnAlert={setFilterOnAlert}
        />
      )}
      {showDownloadBulkMar && (
        <DownloadBulkMarModal
          facility={currentFacility.value}
          onClose={() => setShowDownloadBulkMar(false)}
        />
      )}
      {hasNoVisibleResidents && (
        <TableContainer isEmpty>
          <Table>
            <TableHeader headers={residentHeaders} />
          </Table>
          <Placeholder
            text={
              hasNoVisibleResidents && nameFilter === ''
                ? 'No residents yet.'
                : `No residents found matching "${nameFilter}"`
            }
            action={hasNoVisibleResidents ? setOpenAddResidentPopup : undefined}
            showImage={hasNoVisibleResidents}
          />
        </TableContainer>
      )}
      {!hasNoVisibleResidents && (
        <TableContainer withScrollBar>
          <Table>
            <TableHeader
              headers={residentHeaders}
              sortable={{
                onToggle: (colName: string) => {
                  if (colName === 'Name') {
                    onNameSortingToggle({
                      nameToSort,
                      previousColumn,
                      sortingOrder,
                      setNameToSort,
                      setPreviousColumn,
                    })
                  }
                  getTableHeaderOnToggle({
                    selectedColumn,
                    selectedOrder: sortingOrder,
                    setColumn: setSelectedColumn as (c: string) => void,
                    setOrder: setSortingOrder,
                    storageColumnName: STORAGE_RESIDENT_COLUMN,
                    storageOrderName: STORAGE_RESIDENT_ORDER,
                  })(colName)
                },
                selectedColumn,
                sortingOrder,
              }}
            />
            {sortedResidents.length > 0 && (
              <ResidentTableBody
                residents={
                  filterOnAlert ? residentsWithAlerts : sortedResidents
                }
              />
            )}
          </Table>
        </TableContainer>
      )}
      {movedOutResidents.length > 0 && (
        <LowPriorityLink to={getMovedOutResidentsUrl(orgId, facilityId)}>
          View moved-out residents
        </LowPriorityLink>
      )}
      {openAddResidentPopup && (
        <AddPersonPopup
          facilityId={facilityId}
          orgId={orgId}
          redirectOnClose={redirectToNewResident}
        />
      )}
      {previewLightbox}
      {showHUD && (
        <HUD onExpire={() => setShowHUD(undefined)}>
          {showHUD === 'BulkFacesheet' &&
            "You'll receive an email when the face sheets are ready to download."}
          {showHUD === 'BulkServicePlan' &&
            "You'll receive an email when the service plans are ready to download."}
        </HUD>
      )}
    </>
  )
}

export const LoadingTable = SimpleSpinner
