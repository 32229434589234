import { DropZone } from '@augusthealth/august-frontend-form-elements'
import { useState } from 'react'
import './style.css'

// https://www.geeksforgeeks.org/http-headers-content-type/
export const CONTENT_TYPE_TO_EXTENSION_MAP = {
  'application/pdf': 'pdf',
  'image/gif': 'gif',
  'image/jpeg': 'jpg',
  'image/png': 'png',
}
const SUPPORT_CONTENT_TYPES = Object.keys(CONTENT_TYPE_TO_EXTENSION_MAP)

function getUploadMessage(label) {
  return (
    <div key={`upload-${label}`} className="vertical">
      {label}
    </div>
  )
}

const ERROR_CODE_MAP = {
  'too-many-files': 'Please upload one file at time',
  'file-invalid-type': 'We only support images and pdf files',
}

function getRejectionErrorMessage(rejections) {
  const errors = rejections.reduce((list, rej) => {
    const { errors } = rej
    if (Array.isArray(errors)) {
      errors.forEach((err) => {
        const { code = '' } = err || {}
        const message = ERROR_CODE_MAP[code]
        if (message && !list.includes(message)) {
          list.push(message)
        }
      })
    }

    return list
  }, [])

  if (Array.isArray(errors)) {
    return errors[0]
  }
}

function FileDropZone(props) {
  const [warningMessage, setWarningMessage] = useState()
  const dafaultMessage = getUploadMessage(
    <div>
      Drag a file here or{' '}
      <span className="em-label">select a file from your computer</span>
    </div>
  )
  const droppingMessage = getUploadMessage('Drop the file here ...')
  const onError = (rejections) => {
    if (Array.isArray(rejections)) {
      const errorMessage = getRejectionErrorMessage(rejections)
      setWarningMessage(errorMessage)
    }
  }

  // We only support images and pdf files
  const warning = warningMessage && (
    <div className="warning-holder">
      <i className="fas fa-exclamation-circle" />
      &nbsp;
      {warningMessage}
    </div>
  )

  return (
    <DropZone
      {...props}
      maxFiles={1}
      acceptFileTypes={SUPPORT_CONTENT_TYPES}
      warning={warning}
      onError={onError}
    >
      {[dafaultMessage, droppingMessage]}
    </DropZone>
  )
}

export default FileDropZone
