import { ErrorCode } from '@augusthealth/models/com/august/protos/api/error_response'
import { DateMessage } from '@augusthealth/models/com/august/protos/date'
import { UnitOfTime } from '@augusthealth/models/com/august/protos/timing'
import { add, isAfter } from 'date-fns'
import { isNaN } from 'lodash'
import { UseFormGetValues, UseFormReturn } from 'react-hook-form'
import {
  fromDateMessageToDate,
  fromDateToDateMessage,
} from '@shared/utils/date'
import { AugustError } from '@shared/utils/error'
import { MedOrderFormData } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'

type DurationBounds = {
  updatedStartDate: DateMessage
  updatedEndDate: DateMessage | undefined
}
export const updateTaperDoseDurations = (
  methods: UseFormReturn<MedOrderFormData>,
  {
    differenceInDays,
    effectivePeriodStart,
  }: { differenceInDays?: number; effectivePeriodStart?: DateMessage } = {}
) => {
  const { getValues, setValue } = methods

  const updatedDoses = getValues('doses')
  const lastIndex = updatedDoses.length ? updatedDoses.length - 1 : 0

  updatedDoses.forEach((_dose, doseI) => {
    const currentDuration = getValues(`doses.${doseI}.duration`)
    const { updatedStartDate, updatedEndDate } = differenceInDays
      ? getUpdatedDoseStartAndEndDateByDifference({
          getValues,
          index: doseI,
          difference: differenceInDays,
        })
      : getUpdatedDoseStartAndEndDateFromPreviousDates({
          getValues,
          index: doseI,
        })

    const relevantStartDate =
      doseI === 0 ? effectivePeriodStart || updatedStartDate : updatedStartDate
    setValue(`doses.${doseI}.boundsPeriod.startDate`, relevantStartDate)
    setValue(`doses.${doseI}.boundsPeriod.endDate`, updatedEndDate)

    if (doseI === 0) {
      setValue(`effectivePeriod.startDate`, relevantStartDate)
    }

    if (doseI === lastIndex) {
      setValue(`effectivePeriod.endDate`, updatedEndDate)
    }

    if (
      lastIndex &&
      (!currentDuration || currentDuration === 0 || isNaN(currentDuration))
    ) {
      // @ts-ignore - need to set to null in order to clear the dropdown
      setValue(`doses.${doseI}.durationUnit`, null)
    }
  })
}

export const getUpdatedDoseStartAndEndDateByDifference = ({
  getValues,
  index,
  difference,
}: {
  getValues: UseFormGetValues<MedOrderFormData>
  index: number
  difference: number
}): DurationBounds => {
  const dose = getValues(`doses.${index}`)

  const updatedStartDate = addTimeToDateMessage({
    date: dose!.boundsPeriod!.startDate,
    unitToAdd: 'days',
    amountToAdd: difference,
  })

  const updatedEndDate = addTimeToDateMessage({
    date: dose!.boundsPeriod!.endDate,
    unitToAdd: 'days',
    amountToAdd: difference,
  })

  return {
    updatedStartDate,
    updatedEndDate,
  }
}

export const isIndefiniteEndTaperDuration = (
  value: number | undefined | null
): boolean => {
  return value === undefined || isNaN(value) || value === null
}

export const getUpdatedDoseStartAndEndDateFromPreviousDates = ({
  getValues,
  index,
}: {
  getValues: UseFormGetValues<MedOrderFormData>
  index: number
}): DurationBounds => {
  const dose = getValues(`doses.${index}`)

  const duration = dose!.duration as number
  const durationUnit = dose!.durationUnit

  let unitToAdd: 'days' | 'weeks' | 'months' | 'years' = 'days'
  let amountToAdd = duration
  switch (durationUnit) {
    case UnitOfTime.UNIT_OF_TIME_DAY:
      unitToAdd = 'days'
      amountToAdd = duration - 1
      break
    case UnitOfTime.UNIT_OF_TIME_WEEK:
      unitToAdd = 'weeks'
      break
    case UnitOfTime.UNIT_OF_TIME_MONTH:
      unitToAdd = 'months'
      break
    case UnitOfTime.UNIT_OF_TIME_YEAR:
      unitToAdd = 'years'
      break
  }

  let updatedStartDate = dose!.boundsPeriod?.startDate as DateMessage
  if (index > 0) {
    const previousEndDate = getValues(`doses.${index - 1}.boundsPeriod.endDate`)
    updatedStartDate = addTimeToDateMessage({
      date: previousEndDate,
      unitToAdd: 'days',
      amountToAdd: 1,
    })
  }

  const updatedDoses = getValues('doses')
  const lastIndex = updatedDoses.length ? updatedDoses.length - 1 : 0

  let updatedEndDate: DateMessage | undefined

  if (durationUnit && !isIndefiniteEndTaperDuration(duration)) {
    updatedEndDate = addTimeToDateMessage({
      date: updatedStartDate,
      unitToAdd,
      amountToAdd,
    })
  } else if (index === lastIndex && isIndefiniteEndTaperDuration(duration)) {
    updatedEndDate = getValues('effectivePeriod.endDate')
  }

  if (
    isDateMessageAfter({ date: updatedStartDate, isAfterDate: updatedEndDate })
  ) {
    updatedEndDate = undefined
  }

  return {
    updatedStartDate,
    updatedEndDate,
  }
}

const isDateMessageAfter = ({
  date,
  isAfterDate,
}: {
  date: DateMessage | undefined
  isAfterDate: DateMessage | undefined
}) => {
  if (date && isAfterDate) {
    return isAfter(
      fromDateMessageToDate(date) as Date,
      fromDateMessageToDate(isAfterDate) as Date
    )
  }

  return false
}

export const addTimeToDateMessage = ({
  date,
  unitToAdd,
  amountToAdd,
}: {
  date: DateMessage | undefined
  unitToAdd: 'days' | 'weeks' | 'months' | 'years'
  amountToAdd: number
}) => {
  return fromDateToDateMessage(
    add(fromDateMessageToDate(date as DateMessage) as Date, {
      [unitToAdd]: amountToAdd,
    })
  )
}

export const TaperValidationError = {
  name: 'Taper Validation',
  status: 400,
  message: 'Tapers must have multiple parts',
  json: {
    errors: [
      {
        code: ErrorCode.ERROR_CODE_DISPLAY_TO_CLIENT,
        message:
          'Tapers must have multiple parts. Please use Routine Schedule for medications with only one part.',
      },
    ],
  },
}

export const errorIsTaperMultipartValidation = (e: unknown): boolean => {
  return (
    (e as unknown as AugustError)?.json?.errors?.some((err) =>
      err?.message?.includes('tapers have multiple parts.')
    ) ?? false
  )
}
