import {
  AggregateFunction,
  AggregateInterval,
  Metric,
} from '@augusthealth/models/com/august/protos/api/facility_metrics'
import { ApplicationEvent } from '@augusthealth/models/com/august/protos/application_event'
import { useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  AnalyticsAPI,
  ApplicationInterval,
  getFacility,
} from '@shared/api/facility'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useMARClockSettings } from '@shared/hooks/useMARClockSettings'
import { Facility } from '@shared/types/facility'
import HUD from '@app/components/HUD'
import LowPriorityLink from '@app/components/LowPriorityLink'
import styles from '@app/components/Residents/list.module.css'
import { Table, TableContainer } from '@app/components/Table'
import useFacilityAnalytics from '@app/hooks/useFacilityAnalytics'
import useFacilityMetrics from '@app/hooks/useFacilityMetrics'
import FacilitySettings from '../FacilitySettings'
import EventCountsTr from './EventCountsTr'
import MetricsTr from './MetricsTr'

const EXCLUDED_APPLICATION_EVENTS = [
  ApplicationEvent.APPLICATION_EVENT_HELLO_WORLD,
  ApplicationEvent.APPLICATION_EVENT_UNSPECIFIED,
  ApplicationEvent.UNRECOGNIZED,
]

export default function FacilityMetricsPage({
  match,
}: RouteComponentProps<{ orgId: string; facilityId: string }>) {
  const { setError } = useContext(GlobalContext)
  const { orgId, facilityId } = match.params

  const [facility, setFacility] = useState<Facility>()
  const [openFacilitySettingsPopup, setOpenFacilitySettingsPopup] =
    useState(false)
  const [showHUD, setShowHUD] = useState(false)

  const { orgFacilitySettings } = useMARClockSettings({
    orgId,
    facilityId,
    dependencies: [openFacilitySettingsPopup],
  })

  useEffect(() => {
    getFacility({ facilityId, orgId }).then(setFacility).catch(setError)
  }, [orgId, facilityId])

  const today = new Date()
  const todayYear = today.getFullYear()
  const todayMonth = today.getMonth()
  const todayDate = today.getDate()
  const oneMonthAgo = new Date(todayYear, todayMonth - 1, todayDate)
  const metricListData = useFacilityMetrics({
    facility: { id: facilityId, orgId },
    startDate: oneMonthAgo,
    endDate: today,
    interval: AggregateInterval.AGGREGATE_INTERVAL_DAY,
    metrics: [
      {
        metric: Metric.METRIC_PROSPECTS,
        dataAggregate: AggregateFunction.AGGREGATE_FUNCTION_COUNT,
        timeAggregate: AggregateFunction.AGGREGATE_FUNCTION_AVG,
      },
      {
        metric: Metric.METRIC_CURRENT_RESIDENTS,
        dataAggregate: AggregateFunction.AGGREGATE_FUNCTION_COUNT,
        timeAggregate: AggregateFunction.AGGREGATE_FUNCTION_AVG,
      },
      {
        metric: Metric.METRIC_DISCHARGED_RESIDENTS,
        dataAggregate: AggregateFunction.AGGREGATE_FUNCTION_COUNT,
        timeAggregate: AggregateFunction.AGGREGATE_FUNCTION_AVG,
      },
    ],
  })
  const metricList =
    (metricListData.tag === 'Complete' && metricListData.value) || []
  const eventCountKeys = Object.keys(ApplicationEvent)
    .filter((k) => !EXCLUDED_APPLICATION_EVENTS.includes(k as ApplicationEvent))
    .sort()
  const analyticsHash = useFacilityAnalytics({
    facility: { id: facilityId, orgId },
    startDate: oneMonthAgo,
    endDate: today,
    interval: ApplicationInterval.DAY,
    events: eventCountKeys as ApplicationEvent[],
  })
  const analyticsHashValue =
    (analyticsHash.tag === 'Complete' && analyticsHash.value) ||
    ({} as AnalyticsAPI)

  if (!facility) {
    return null
  }

  return (
    <div className={styles.content}>
      <div className="page-title flex items-center justify-between">
        {facility?.name} {facility?.isActive ? undefined : '(Inactive)'}
        <AsyncIconButton
          initialIcon="fa-gear"
          buttonStyle="secondary-fill"
          buttonSize="small"
          onClick={() => setOpenFacilitySettingsPopup(true)}
        >
          Settings
        </AsyncIconButton>
      </div>
      <div className={styles.container}>
        <TableContainer>
          <Table>
            <thead className={styles.tableHeader}>
              <th className="pl-[8px]">COUNT / INTERVAL</th>
              <th>Today</th>
              <th>Yesterday</th>
              <th>Last Week</th>
              <th>Last Month</th>
              <th>Lowest</th>
              <th>Highest</th>
            </thead>
            <tbody>
              <MetricsTr
                title="Prospect"
                metricList={metricList}
                metric={Metric.METRIC_PROSPECTS}
              />
              <MetricsTr
                title="Current Resident"
                metricList={metricList}
                metric={Metric.METRIC_CURRENT_RESIDENTS}
              />
              <MetricsTr
                title="Moved-out Resident"
                metricList={metricList}
                metric={Metric.METRIC_DISCHARGED_RESIDENTS}
              />
              <tr className={styles.tableHeader}>
                <th className="pl-[8px]">COUNT / INTERVAL</th>
                <th>Today</th>
                <th>Yesterday</th>
                <th>Week total</th>
                <th>Month total</th>
                <th>Min by day</th>
                <th>Max by day</th>
              </tr>
              {eventCountKeys.map((key) => {
                return (
                  <EventCountsTr
                    key={`event-count-tr-${key}`}
                    title={key.replace(/_/g, ' ').toLowerCase()}
                    analyticsHash={analyticsHashValue}
                    event={key as ApplicationEvent}
                  />
                )
              })}
            </tbody>
          </Table>
        </TableContainer>
      </div>
      <LowPriorityLink to="/tools/facilities">
        View all facilities
      </LowPriorityLink>
      {openFacilitySettingsPopup && orgFacilitySettings.tag === 'Complete' && (
        <FacilitySettings
          facility={facility}
          onClose={() => {
            setOpenFacilitySettingsPopup(false)
          }}
          marClockSettings={orgFacilitySettings.value}
          setFacility={setFacility}
          setShowHUD={setShowHUD}
        />
      )}
      {showHUD && (
        <HUD
          onExpire={() => {
            setShowHUD(false)
          }}
        >
          <div className="text-[16px] font-semibold leading-[24px]">
            Facility Settings Updated
          </div>
        </HUD>
      )}
    </div>
  )
}
