import { Routine } from '@augusthealth/models/com/august/protos/routine'
import {
  DayOfWeek,
  EventTiming,
  Timing,
  UnitOfTime,
} from '@augusthealth/models/com/august/protos/timing'
import { DeepNull } from '@shared/types/utilities'
import { isEmptyObject } from '@shared/utils/common'
import notEmpty from '@shared/utils/notEmpty'
import {
  ScheduleValue,
  scheduleValueToTiming,
  TimeGroup,
} from '@shared/utils/scheduleValue'

export type RepeatedOrSpecificDays =
  | { tag: 'Every'; count: number; period?: UnitOfTime }
  | { tag: 'Specific'; days: { label: string; value: string }[] }

export interface RoutineSchedule {
  repeat?: RepeatedOrSpecificDays
  morning: ScheduleValue
  afternoon: ScheduleValue
  night: ScheduleValue
}

export function isEmptySchedule(schedule: RoutineSchedule) {
  return (
    (schedule.repeat === undefined ||
      (schedule.repeat.tag === 'Every' &&
        schedule.repeat.period === undefined) ||
      (schedule.repeat.tag === 'Specific' &&
        schedule.repeat.days.length === 0)) &&
    [schedule.morning, schedule.afternoon, schedule.night].every(
      (sv) => sv === undefined || sv === 'NoValue'
    )
  )
}

export function isEmptyTiming(timing: Timing) {
  return (
    isEmptyObject(timing) ||
    Object.values(timing).every((v) => v === undefined || v === null)
  )
}

/**
 * Turns the frontend representation of a schedule into the backend
 * representation of a schedule.
 *
 * @param schedule
 */
export function scheduleToTiming(schedule: RoutineSchedule): Timing {
  const dailyTimings = [schedule.morning, schedule.afternoon, schedule.night]
    .filter(notEmpty)
    .map(scheduleValueToTiming)
    .reduce(
      (accum, el) => {
        return {
          timeOfDay: [...el.timeOfDay, ...accum.timeOfDay],
        }
      },
      { timeOfDay: [] }
    )
  let dayOfWeek: DayOfWeek[] | null = null
  let period: number | null = null
  let periodUnit: UnitOfTime | null = null
  let frequency: number | null = null
  if (schedule.repeat?.tag === 'Specific') {
    dayOfWeek = schedule.repeat.days.map(({ value }) => {
      return value as DayOfWeek
    })
  } else if (schedule.repeat?.tag === 'Every') {
    const { count: c, period: p } = schedule.repeat
    period = c
    if (p) {
      periodUnit = p
    }
    frequency = 1
  }

  const timing: DeepNull<Timing> = {
    dayOfWeek,
    frequency,
    period,
    periodUnit,
    ...dailyTimings,
  }

  return timing as Timing
}

export function isInAM(value: ScheduleValue) {
  return isAt(value, 'Morning') || isAt(value, 'AM')
}

export function isInPM(value: ScheduleValue) {
  return isAt(value, 'Afternoon') || isAt(value, 'PM')
}

export function isInNS(value: ScheduleValue) {
  return isAt(value, 'Night') || isAt(value, 'HS') || isAt(value, 'NOC')
}

/**
 * Checks if value is not a 'NoValue' and if it matches any of the passed
 * routine time args
 *
 * @param value
 * @param routineTime
 */
export function isAt(
  value: ScheduleValue,
  routineTime: TimeGroup | 'Morning' | 'Afternoon' | 'Night' | 'NoValue'
) {
  return (
    value !== 'NoValue' &&
    (('name' in value && value.name === routineTime) ||
      ('timeGroup' in value && value.timeGroup === routineTime))
  )
}

export function getDefaultTiming(routine: Routine) {
  const { name, timing } = routine
  let defaultTiming = timing
  if (!timing) {
    if (name === 'Morning') {
      defaultTiming = { timeOfDay: [{ when: EventTiming.EVENT_TIMING_MORN }] }
    } else if (name === 'Bedtime') {
      defaultTiming = { timeOfDay: [{ when: EventTiming.EVENT_TIMING_EVE }] }
    }
  }

  return defaultTiming
}

export function isEveryDayTiming(timing: Timing) {
  const { frequency, period, periodUnit } = timing
  return (
    frequency === 1 &&
    period === 1 &&
    periodUnit === UnitOfTime.UNIT_OF_TIME_DAY
  )
}
