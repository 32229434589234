import { KeyboardEvent } from 'react'
import { Control, Controller } from 'react-hook-form'
import { LabelAboveInput, SubLabel } from '@shared/components/Labels'
import { Props as TextInputProps } from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import MoneyInput from './index'
import { convertCentsToDollars, convertDollarsToCents } from './utils'

export type Props = TextInputProps & {
  control: Control<any>
  holderClassName?: string
  id: string
  name: string
  required?: boolean
  subLabel?: SubLabel
  title: string
  uppercaseTitle?: boolean
  saveAsNegative?: boolean
}

export default function MoneyInReactHookForm({
  control,
  holderClassName,
  id,
  inputProps,
  name,
  required = false,
  subLabel,
  title,
  uppercaseTitle = false,
  saveAsNegative = false,
}: Props) {
  return (
    <div className={holderClassName}>
      <LabelAboveInput
        uppercase={uppercaseTitle}
        htmlFor={id}
        subLabel={subLabel}
      >
        {title}
      </LabelAboveInput>
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { onChange, value } }) => (
          <MoneyInput
            inputProps={{
              name,
              defaultValue: convertCentsToDollars({ saveAsNegative, value }),
              onChange: (ev: KeyboardEvent<HTMLInputElement>) => {
                const moneyValue = ev.currentTarget.value
                onChange(
                  moneyValue
                    ? convertDollarsToCents({
                        saveAsNegative,
                        value: moneyValue,
                      })
                    : null
                )
              },
              ...inputProps,
            }}
          />
        )}
      />
    </div>
  )
}
