import { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { forgotPasswordPath } from '@shared/routes'
import { tw, twx } from '@shared/utils/tailwind'
import Button from './Button'
import { BtnState } from './LoginWithUsernameOrEmail'

function getCtaText(state: BtnState) {
  switch (state) {
    case 'disengaged':
      return 'log in with password'
    case 'engaged':
      return 'log in'
    default:
      return 'login in with password'
  }
}

export default function UsernameLoginForm({
  state,
  handleLogin,
  applicationBasePath,
}: {
  state: BtnState
  handleLogin: (values: unknown) => Promise<void>
  applicationBasePath: string
}) {
  const { reset, register, handleSubmit } = useForm({
    defaultValues: { preferredUsername: '', password: '' },
  })

  useEffect(() => {
    if (state === 'disengaged') {
      reset()
    }
  }, [state, reset])

  const icon = state === 'engaged' ? undefined : 'key'
  const usernameRef: React.MutableRefObject<HTMLInputElement | null> =
    useRef<HTMLInputElement>(null)
  const { ref, ...rest } = register('preferredUsername', {
    required: state === 'engaged',
  })

  useEffect(() => {
    if (state === 'engaged') {
      usernameRef.current?.focus()
    }
  })

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <div
        className={twx(
          'relative text-[14px] transition-[height] duration-300 ease-out',
          {
            'h-0 w-0 overflow-hidden opacity-0':
              state === 'default' || state === 'disengaged',
            'mb-[16px] h-[48px] w-full opacity-100': state === 'engaged',
          }
        )}
      >
        <input
          type="text"
          className={tw`h-[48px] w-[360px] rounded-[6px] border border-gray-10 pl-[40px] text-[14px] font-medium hover:outline hover:outline-1 hover:outline-form-input-border-hover focus:outline focus:outline-1 focus:outline-form-input-border-hover`}
          placeholder="Username (or email)"
          autoComplete={'username'}
          {...rest}
          ref={(e) => {
            ref(e)
            usernameRef.current = e
          }}
        />
        <i
          className={tw`fa fa-fw fa-id-card-clip absolute left-[16px] top-[17px] text-primary-light`}
        />
      </div>

      <div
        className={twx(
          'relative text-[14px] transition-[height] duration-300 ease-out',
          {
            'h-0 w-0 overflow-hidden opacity-0':
              state === 'default' || state === 'disengaged',
            'mb-[16px] h-[48px] w-full opacity-100': state === 'engaged',
          }
        )}
      >
        <input
          type="password"
          autoComplete={'current-password'}
          className={tw`h-[48px] w-[360px] rounded-[6px] border border-gray-10 pl-[40px] font-medium hover:outline hover:outline-1 hover:outline-form-input-border-hover focus:outline focus:outline-1 focus:outline-form-input-border-hover`}
          placeholder="Password"
          {...register('password', { required: state === 'engaged' })}
        />
        <i
          className={tw`fa fa-fw fa-key absolute left-[16px] top-[17px] text-primary-light`}
        />
      </div>

      <Button
        disabled={false}
        text={getCtaText(state)}
        icon={icon}
        state={state}
      />
      <ForgotPassword state={state} applicationBasePath={applicationBasePath} />
    </form>
  )
}

function ForgotPassword({
  state,
  applicationBasePath,
}: {
  state: 'engaged' | 'disengaged' | 'default'
  applicationBasePath: string
}) {
  return (
    <div
      className={twx(
        'relative text-center transition-[height] duration-[150ms] ease-out',
        {
          'h-0': state === 'default' || state === 'disengaged',
          'mt-[16px] h-[16px]': state === 'engaged',
        }
      )}
    >
      <Link
        className={twx(
          'absolute -left-1/2 -right-1/2 bottom-0 top-0 text-[14px] font-medium leading-[16px] text-secondary-07 underline opacity-0 transition-opacity transition-transform duration-300',
          {
            'translate-y-[+100px] opacity-0':
              state === 'default' || state === 'disengaged',
            'opacity-1 translate-y-0': state === 'engaged',
          }
        )}
        to={forgotPasswordPath(applicationBasePath)}
      >
        Forgot password
      </Link>
    </div>
  )
}
