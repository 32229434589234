import { Task } from '@augusthealth/models/com/august/protos/task'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import GlobalContext from '@shared/contexts/GlobalContext'
import { getTaskUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import styles from './styles.module.css'
import TaskNavigationStep from './TaskNavigationStep'

type Props = {
  stepLinks: string[]
  currentStepNumber: number
  onStepClick?: () => void
  promiseQueue: Promise<void>
  person: Person
  task: Task
  promiseRef?: React.MutableRefObject<Promise<void>>
}
export default function TaskPageNavigation({
  stepLinks,
  currentStepNumber,
  onStepClick,
  person,
  task,
  promiseQueue,
  promiseRef,
}: Props) {
  const history = useHistory()
  const { setError } = useContext(GlobalContext)
  const { orgId, facilityId, id: personId } = person

  const url = getTaskUrl({
    orgId: orgId || '',
    facilityId: facilityId || '',
    personId: personId || '',
    id: task.id,
  })
  const handleStepClick = async (index: number) => {
    onStepClick?.()

    try {
      if (promiseRef) {
        promiseRef.current = promiseRef.current.then(() =>
          history.push(`${url}/${index === 0 ? '' : index + 1}`)
        )

        await promiseRef.current
      } else {
        await promiseQueue
        history.push(`${url}/${index === 0 ? '' : index + 1}`)
      }
    } catch (e) {
      setError(e)
    }
  }

  return (
    <nav className={styles.stepHeader}>
      {stepLinks.map((stepLink, index) => {
        return (
          <TaskNavigationStep
            isCurrentStep={index + 1 === currentStepNumber}
            onStepClick={() => handleStepClick(index)}
            taskStepUrl={`${url}/${index + 1}`}
            stepLabel={`${index + 1}. ${stepLink}`}
            key={`stepHeader-${index}`}
          />
        )
      })}
    </nav>
  )
}
