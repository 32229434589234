import {
  Charge,
  ChargeType,
  Recurring_Interval,
  Recurring_Rate,
  Recurring_Rate_RateChangeReason,
} from '@augusthealth/models/com/august/protos/charge'
import {
  centsToMoney,
  nameForChargeType,
  nameForRateChange,
} from '@shared/utils/charge'
import { firstLastName } from '@shared/utils/contact'
import { fromDateMessageToDate, monthDayYear } from '@shared/utils/date'

export interface RecurringCharge {
  id: string
  title: string
  subtitle?: string
  payer: string
  recurrence: 'Monthly' | 'Daily' | 'Weekly'
  amount: string
  type: ChargeType
  rates: Rate[]
}

interface Rate {
  amount: string
  date: string
  inPast: boolean
  description: string
  isInitialRate: boolean
}

export function toRecurringCharge(charge: Charge): RecurringCharge {
  const rates: Rate[] = (charge.recurring!.rates || []).map((rate) => {
    const inPast = fromDateMessageToDate(rate.start!)! < new Date()

    return {
      date: monthDayYear(rate.start!),
      inPast,
      amount: centsToMoney(rate.amount!),
      description: nameForReason(
        rate,
        intervalName(charge.recurring!.interval!),
        inPast
      ),
      isInitialRate: rate.reason === 'RATE_CHANGE_REASON_INITIAL_RATE',
    }
  })

  // Find the first rate that occurs in the future
  // The rate before that is our current rate
  const firstFutureRateIx = rates.findIndex((rate) => !rate.inPast)
  let currentRate: Rate | undefined

  if (firstFutureRateIx === -1 || firstFutureRateIx === 0) {
    currentRate = rates[rates.length - 1]
  } else {
    currentRate = rates[firstFutureRateIx - 1]
  }

  return {
    id: charge.id!,
    title: nameForChargeType(charge.type!),
    subtitle: charge.notes,
    payer: charge.payer ? firstLastName(charge.payer) : 'Unknown',
    recurrence: intervalName(charge.recurring!.interval!),
    amount: currentRate.amount,
    rates,
    type: charge.type!,
  }
}

function intervalName(
  interval: Recurring_Interval
): 'Monthly' | 'Daily' | 'Weekly' {
  switch (interval) {
    case Recurring_Interval.INTERVAL_DAY:
      return 'Daily'
    case Recurring_Interval.INTERVAL_WEEK:
      return 'Weekly'
    case Recurring_Interval.INTERVAL_MONTH:
      return 'Monthly'
    default:
      throw new Error('Unrecognized interval')
  }
}

function nameForReason(
  rate: Recurring_Rate,
  recurrence: string,
  inPast: boolean
) {
  const dollars = centsToMoney(rate.amount!)
  const reason = rate.reason!
  const reasonDescription = `(${nameForRateChange(reason)})`

  const text = inPast
    ? `Rate changed to ${dollars} ${reasonDescription}`
    : `Scheduled rate change to ${dollars} ${reasonDescription}`

  switch (reason) {
    case Recurring_Rate_RateChangeReason.RATE_CHANGE_REASON_INITIAL_RATE:
      return `Start at ${recurrence.toLowerCase()} rate of ${dollars}`
    default:
      return text
  }
}
