import { ReactNode, useContext } from 'react'
import Card from '@shared/components/Card'
import { DotDivider } from '@shared/components/DotDivider'
import { SquareBadge } from '@shared/components/SquareBadge'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import { getFirstAndLastName, getFullName } from '@shared/utils/humanName'
import { getFormattedCompletionDate } from '@shared/utils/snapshot'
import { tw } from '@shared/utils/tailwind'

export default function DocumentPageHolder({
  title,
  score,
  level,
  children,
  person,
  snapshot,
}: {
  title: string
  score?: number
  level?: number
  children: ReactNode
  person: Person
  snapshot: Snapshot
}) {
  const { convertToFacilityTimezone } = useContext(CurrentFacilityContext)
  const { lastModification } = snapshot
  const completionDate = getFormattedCompletionDate(
    snapshot,
    convertToFacilityTimezone
  )

  return (
    <main className={tw`content-holder vertical mt-[48px] font-inter`}>
      <div>
        <img alt="" src="/svg/logo--masthead.svg" />
      </div>
      <h2 className={tw`mb-[8px] mt-[32px] text-[16px] leading-[24px]`}>
        {getFirstAndLastName(person.name)}
      </h2>
      <h1 className={tw`flex items-center text-[24px] font-medium`}>
        {title}
        {score !== undefined && (
          <SquareBadge className={tw`ml-[16px]`}>Score {score}</SquareBadge>
        )}
        {level !== undefined && (
          <SquareBadge className={tw`ml-[16px]`}>Level {level}</SquareBadge>
        )}
      </h1>
      {lastModification && (
        <div className={tw`text-[16px] leading-[24px] text-gray-07`}>
          {lastModification.modifiedByUserName &&
            `Completed by ${getFullName(lastModification.modifiedByUserName)}`}
          <DotDivider />
          {completionDate}
        </div>
      )}
      <Card className={tw`mt-[16px] flex flex-col items-center`}>
        <div className={tw`mt-[16px] max-w-[600px]`}>{children}</div>
      </Card>
    </main>
  )
}
