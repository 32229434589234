import { Task } from '@augusthealth/models/com/august/protos/task'
import React from 'react'
import { Person } from '@shared/types/person'
import { SignInPersonData } from '@app/components/Prospects/Tasks/popups/SignInPersonPopup'

export type PersonContextProps = {
  person?: Person
  setPerson: (person: Person) => void
  tasks?: Task[]
  setTasks: (tasks?: Task[]) => void
  profileImageURL: string
  setProfileImageURL: (url: string) => void
  isPersonLoading: boolean
  setIsPersonLoading: (isLoading: boolean) => void
  signInPersonPopupTask?: SignInPersonData
  setSignInPersonPopupTask: (task: SignInPersonData) => void
}

export const defaultProps: PersonContextProps = {
  person: undefined,
  setPerson: () => {},
  tasks: undefined,
  setTasks: () => {},
  profileImageURL: '',
  setProfileImageURL: () => {},
  isPersonLoading: false,
  setIsPersonLoading: () => {},
  signInPersonPopupTask: undefined,
  setSignInPersonPopupTask: () => {},
}

export default React.createContext(defaultProps)
