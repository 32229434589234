import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { TaskTemplateInfo } from '@augusthealth/models/com/august/protos/task'
import { useContext, useEffect, useState } from 'react'
import {
  fetchFacilityTaskTemplates,
  fetchFacilityTaskTemplatesForToolUsers,
} from '@shared/api/facility'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { PersonIds } from '@shared/types/person'
import { Loading } from '@shared/utils/loading'

export default function useConfiguredTasks(
  person: PersonIds,
  deps: React.DependencyList = []
) {
  const { user } = useUserContext()
  const { setError } = useContext(GlobalContext)

  const [possibleTasks, setPossibleTasks] = useState<
    Loading<TaskTemplateInfo[]>
  >({ tag: 'Loading' })

  useEffect(() => {
    const canReadTemplates = hasPermissionForPerson({
      user,
      person,
      permissions: [GroupPermission.GROUP_PERMISSION_SNAPSHOT_READ],
    })

    if (canReadTemplates && person.orgId && person.facilityId) {
      fetchFacilityTaskTemplates(person.orgId!, person.facilityId!)
        .then((response) =>
          setPossibleTasks({
            tag: 'Complete',
            value: response.data,
          })
        )
        .catch((e) => {
          setError(e)
          setPossibleTasks({ tag: 'Complete', value: [] })
        })
    } else {
      setPossibleTasks({
        tag: 'Complete',
        value: [],
      })
    }
  }, deps)

  return possibleTasks
}

export function useToolsLoadingConfiguredTasks(
  person: PersonIds,
  deps: React.DependencyList = []
) {
  const { user } = useUserContext()
  const { setError } = useContext(GlobalContext)

  const [possibleTasks, setPossibleTasks] = useState<
    Loading<TaskTemplateInfo[]>
  >({ tag: 'Loading' })

  useEffect(() => {
    const canReadTemplates = hasPermissionForPerson({
      user,
      person,
      permissions: [GroupPermission.GROUP_PERMISSION_TOOL_GENERAL],
    })

    if (canReadTemplates && person.orgId && person.facilityId) {
      setPossibleTasks({ tag: 'Loading' })
      fetchFacilityTaskTemplatesForToolUsers(person.orgId!, person.facilityId!)
        .then((response) =>
          setPossibleTasks({
            tag: 'Complete',
            value: response.data,
          })
        )
        .catch((e) => {
          setError(e)
          setPossibleTasks({ tag: 'Complete', value: [] })
        })
    } else {
      setPossibleTasks({
        tag: 'Complete',
        value: [],
      })
    }
  }, deps)

  return possibleTasks
}
