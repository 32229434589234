import { BasicInputProps } from '@shared/components/BasicInput/BasicInput'
import { PhoneInput } from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import UseDropdown, { Props as DropdownProps } from './ContactPointUseDropdown'

type Props = {
  inputProps: BasicInputProps
  dropdownProps?: DropdownProps
}

export default function PhoneInputWithType(props: Props) {
  const { inputProps, dropdownProps } = props

  if (dropdownProps) {
    return (
      <div className="flex-holder evenly">
        <div className="w-full">
          <PhoneInput {...props} />
        </div>
        <div className="flex-additional-item">
          <UseDropdown {...dropdownProps} holderStyle={{ width: '150px' }} />
        </div>
      </div>
    )
  }

  return <PhoneInput inputProps={inputProps} />
}
