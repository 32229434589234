import {
  SignatureStatus,
  Task,
  TaskStatus,
  TaskType,
} from '@augusthealth/models/com/august/protos/task'
import { Person } from '@shared/types/person'
import { Signer, SignerRole } from '@shared/types/snapshot'
import { UserAccount } from '@shared/types/user'
import { getFullName } from '@shared/utils/humanName'
import { nextSigner } from '@shared/utils/task'
import { isResponsiblePerson, userMatchesSigner } from '@shared/utils/user'

interface TaskStatusDisplay {
  actionTextTemplate: {
    default: '' | 'FINISH' | 'REVIEW' | 'SIGN' | 'GET STARTED'
    uploadable?: 'UPLOAD'
  }
  badgeText?:
    | 'WAITING FOR OTHER SIGNATURES'
    | 'PENDING ADMIN SIGNATURES'
    | 'WAITING FOR YOUR SIGNATURE'
    | 'SHARED'
    | 'READY FOR YOUR REVIEW'
    | 'COMPLETED'
    | 'READY FOR YOUR SIGNATURE'
    | 'NEW'
    | 'PROCESSING'
  statusTextTemplate: {
    default:
      | 'Last updated by $modifiedByUserName'
      | 'Shared with Responsible Person'
      | 'Submitted by Responsible Person'
      | 'Completed by $modifiedByUserName'
      | 'Awaiting signature of $nextSigner'
      | 'Awaiting signature of an external signer'
      | 'Not started yet'
      | string
    uploadable?: 'Uploaded by $modifiedByUserName' | 'Not uploaded yet'
    signable?: 'Signed by $modifiedByUserName'
  }
}

export function displayDataForTask({
  task,
  user,
  person,
}: {
  task: Task
  user: UserAccount
  person: Person
}): TaskStatusDisplay | undefined {
  const { orgId, facilityId, id: personId } = person
  const currentUserIsRP = isResponsiblePerson({
    user,
    orgId,
    facilityId,
    personId,
  })

  if (task.status === TaskStatus.TASK_STATUS_PENDING_SIGNATURES) {
    const signatureData = task.signatures || []
    if (
      signatureData.some(
        (v) => v.status === SignatureStatus.SIGNATURE_STATUS_PROCESSING
      )
    ) {
      return processingState
    }
    const nextTaskSigner = nextSigner(task)
    const nextSignerText = setNextSignerText(nextTaskSigner)
    if (nextTaskSigner && userMatchesSigner(user, nextTaskSigner)) {
      return {
        actionTextTemplate: {
          default: 'SIGN',
        },
        badgeText: 'READY FOR YOUR SIGNATURE',
        statusTextTemplate: {
          default: nextSignerText,
        },
      }
    } else {
      return {
        actionTextTemplate: {
          default: '',
        },
        badgeText: currentUserIsRP
          ? 'PENDING ADMIN SIGNATURES'
          : 'WAITING FOR OTHER SIGNATURES',
        statusTextTemplate: {
          default: nextSignerText,
        },
      }
    }
  }

  if (task.status === TaskStatus.TASK_STATUS_ASSIGNED_TO_ADMIN) {
    return {
      actionTextTemplate: {
        default: 'FINISH',
      },
      statusTextTemplate: {
        default: 'Last updated by $modifiedByUserName',
      },
    }
  }

  if (task.status === TaskStatus.TASK_STATUS_ASSIGNED_TO_RP) {
    const statusText = currentUserIsRP
      ? 'Shared with you'
      : 'Shared with Responsible Person'

    return {
      actionTextTemplate: currentUserIsRP
        ? { default: 'GET STARTED', uploadable: 'UPLOAD' }
        : { default: '' },
      badgeText: 'SHARED',
      statusTextTemplate: {
        default: statusText,
      },
    }
  }

  if (task.status === TaskStatus.TASK_STATUS_AWAITING_REVIEW) {
    return {
      actionTextTemplate: {
        default: 'REVIEW',
      },
      badgeText: 'READY FOR YOUR REVIEW',
      statusTextTemplate: {
        default: 'Submitted by Responsible Person',
      },
    }
  }

  if (task.status === TaskStatus.TASK_STATUS_COMPLETE_BUT_NEW) {
    return {
      actionTextTemplate: {
        default: '',
      },
      badgeText: 'NEW',
      statusTextTemplate: {
        default: 'Completed by $modifiedByUserName',
        uploadable: 'Uploaded by $modifiedByUserName',
        signable: 'Signed by $modifiedByUserName',
      },
    }
  }

  if (task.status === TaskStatus.TASK_STATUS_NOT_STARTED) {
    return {
      actionTextTemplate: {
        default: 'GET STARTED',
        uploadable: 'UPLOAD',
      },
      statusTextTemplate: {
        default: 'Not started yet',
        uploadable: 'Not uploaded yet',
      },
    }
  }

  return undefined
}

const processingState: TaskStatusDisplay = {
  badgeText: 'PROCESSING',
  actionTextTemplate: {
    default: '',
  },
  statusTextTemplate: {
    default: 'Processing signatures',
  },
}

export const TASK_REQUIRED_FIELDS_HASH: Record<string, string[][]> = {
  [TaskType.TASK_TYPE_CA_FORM_601]: [
    ['name[0].given[0]', 'name[0].family'],
    [
      'contact[relationship=CONTACT_RELATIONSHIP_RESPONSIBLE_PERSON]',
      'contact[relationship=CONTACT_RELATIONSHIP_RESPONSIBLE_PERSON].address',
      'contact[relationship=CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER]',
    ],
    [],
    [],
  ],
  [TaskType.TASK_TYPE_ADMISSIONS_AGREEMENT]: [
    [
      'admissionsInformation.startDate',
      'admissionsInformation.bedId',
      'admissionsInformation.assignedRoomType',
      'admissionsInformation.initialIntendedRate',
    ],
    [],
  ],
  [TaskType.TASK_TYPE_LEVEL_OF_CARE]: [
    ['admissionsInformation.admissionType'],
    [],
    [],
    [],
  ],
}

export function getRequiredFieldsByTaskType(taskType: TaskType) {
  const requiredFieldsByPageIndex = TASK_REQUIRED_FIELDS_HASH[taskType] || []
  if (Array.isArray(requiredFieldsByPageIndex)) {
    return requiredFieldsByPageIndex.reduce((list, fields) => {
      if (Array.isArray(fields)) {
        list.push(...fields)
      }
      return list
    }, [])
  }

  return []
}

function setNextSignerText(nextTaskSigner: Signer | undefined) {
  if (!nextTaskSigner) return ''
  if (nextTaskSigner.role === SignerRole.SIGNER_ROLE_EXTERNAL) {
    return 'Awaiting next signature'
  }

  return `Awaiting signature of ${getFullName(nextTaskSigner.name)}`
}
