import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'

export async function handleLogin(
  username: string,
  password: string
): Promise<CognitoUser> {
  Auth.configure({
    authenticationFlowType: 'USER_SRP_AUTH',
  })
  return Auth.signIn({ username, password }) as Promise<CognitoUser>
}
