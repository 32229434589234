import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useHistory } from 'react-router-dom'
import ButtonLink from '@shared/components/ButtonLink'
import { EmptyCard } from '@shared/components/EmptyCard'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import { recreateTaskAndReturnUrl } from '@app/pages/Documents/helpers'
import RoutinePageTitle from './RoutinePageTitle'

export function NoActiveRoutines({
  person,
  handleSchedulePriorRoutine,
}: {
  person: Person
  handleSchedulePriorRoutine: () => void
}) {
  const history = useHistory()

  return (
    <>
      <RoutinePageTitle person={person} />
      <EmptyCard
        version={{
          tag: 'Children',
          children: (
            <PersonPermissionGate
              person={person}
              permissions={[GroupPermission.GROUP_PERMISSION_TASK_CREATE]}
            >
              <div className="mt-[4px]">
                To get started add a routine above,{' '}
                <ButtonLink onClick={handleSchedulePriorRoutine}>
                  add a schedule to an existing routine
                </ButtonLink>
                ,{' '}
                <ButtonLink
                  onClick={async () => {
                    const url = await recreateTaskAndReturnUrl(
                      person,
                      undefined,
                      DataType.DATA_TYPE_AUGUST_INITIAL_APPRAISAL
                    )
                    history.push(url)
                  }}
                >
                  or continue the Resident Assessment
                </ButtonLink>
                .
              </div>
            </PersonPermissionGate>
          ),
        }}
        title="No routines yet."
        className="mb-[32px] grow-0 justify-start"
      />
    </>
  )
}
