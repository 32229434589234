import {
  Routine,
  RoutineStatus,
} from '@augusthealth/models/com/august/protos/routine'
import { ServicePlan_PlanCategoryKey } from '@augusthealth/models/com/august/protos/service_plan'
import classNames from 'classnames'
import { CategoryTasks } from '@shared/types/routine'
import notEmpty from '@shared/utils/notEmpty'
import {
  getCategoryKeyTitle,
  getLevelLabel,
} from '@shared/utils/residentAssessment'
import { getCategoryKey, getCategoryTasks } from '@shared/utils/routine'
import styles from './styles.module.css'
import { Description } from '../ScheduleRoutine'

export function AbbreviatedDescription({ routine }: { routine: Routine }) {
  const levelsTextForRoutine = levelsForRoutine(getCategoryTasks(routine))
  const borderClasses = classNames(styles.detailsBorder, {
    [styles.draft]: routine.status === RoutineStatus.ROUTINE_STATUS_DRAFT,
    [styles.active]: routine.status === RoutineStatus.ROUTINE_STATUS_ACTIVE,
  })

  return (
    <div className={'flex'}>
      <div className={borderClasses} />
      <div className={styles.detailsText}>
        <>
          {levelsTextForRoutine.map((s, i) => (
            <div key={`l-i${i}`}>{s}</div>
          ))}
          {levelsTextForRoutine.length === 0 && (
            <div>No level information available.</div>
          )}
        </>
      </div>
    </div>
  )
}

export function FullDescription({ routine }: { routine: Routine }) {
  const fullDescription = toFullDescription(getCategoryTasks(routine))
  const borderClasses = classNames(styles.detailsBorder, {
    [styles.draft]: routine.status === RoutineStatus.ROUTINE_STATUS_DRAFT,
    [styles.active]: routine.status === RoutineStatus.ROUTINE_STATUS_ACTIVE,
  })

  return (
    <>
      {fullDescription.map((fid, i) => (
        <div className={'mb-[12px] flex'} key={`${routine.id}-${i}`}>
          <div className={borderClasses} />
          <div className={styles.detailsText}>
            <ul className={styles.fullInstructions}>
              {fid.categoryLevel && (
                <span>
                  {fid.categoryLevel} {fid.categoryTitle}{' '}
                  {fid.notes && <>- {fid.notes}</>}
                </span>
              )}

              {fid.details.map((d, j) => (
                <li key={`${routine.id}-${i}-${j}`}>
                  <span>{d}</span>
                </li>
              ))}
              {fid.options.map((o, j) => (
                <li key={`${routine.id}-${i}-${j}`}>
                  <span>{o}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  )
}

export function levelsForRoutine(categoryTasks: CategoryTasks[]) {
  return categoryTasks
    .map((ct) => {
      const appraisalTasks = ct.appraisalTasks
      const categoryKey =
        ct.category ?? ServicePlan_PlanCategoryKey.PLAN_CATEGORY_KEY_UNSPECIFIED

      if (appraisalTasks?.level?.level) {
        const categoryTitle = getCategoryKeyTitle(categoryKey).toLowerCase()
        return `${getLevelLabel(appraisalTasks.level.level)} ${categoryTitle}`
      }

      return undefined
    })
    .filter(notEmpty)
}

export function toFullDescription(
  categoryTasks: CategoryTasks[]
): Description[] {
  return categoryTasks
    .map((ct) => {
      const appraisalTasks = ct.appraisalTasks
      const servicePlanTasks = ct.servicePlanTasks
      const categoryKey = getCategoryKey(ct)
      const categoryTitle = getCategoryKeyTitle(categoryKey).toLowerCase()

      const details = appraisalTasks?.details ?? []
      const detailDescriptions = details.map((d) => d.detail!.description!)

      const servicePlanOptions = servicePlanTasks?.servicePlanOptions ?? []
      const optionDescriptions = servicePlanOptions.map((o) => o.description)

      const level = appraisalTasks?.level?.level
      const categoryLevel = level ? `${getLevelLabel(level)}` : undefined
      const notes = appraisalTasks?.appraisalNotes

      if (categoryLevel === undefined && detailDescriptions.length === 0) {
        return undefined
      }

      return {
        categoryKey,
        categoryTitle,
        categoryLevel,
        details: detailDescriptions,
        options: optionDescriptions,
        notes,
      }
    })
    .filter(notEmpty)
}
