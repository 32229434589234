import { SetRequired } from 'type-fest'
import urlcat, { ParamMap } from 'urlcat'
import { getUrl } from '@shared/api/legacy'
import { SNAPSHOT_FILE_URL } from '@shared/api/person'
import { requestJson } from '@shared/api/request'
import environment from '@shared/environment'
import { PersonTasks } from '@shared/types/api/person_tasks'
import { ImportKind } from '@shared/types/data_import'
import { FacilityIds } from '@shared/types/facility'
import { Immunization } from '@shared/types/immunization'
import { Incident, IncidentAction } from '@shared/types/incidents'
import { EmailNotification } from '@shared/types/notification_subscriptions'
import { Person, PersonIds, ResidentStatus } from '@shared/types/person'
import { Routine } from '@shared/types/routine'
import { DataType, ImageSize, Snapshot } from '@shared/types/snapshot'
import { Task } from '@shared/types/task'
import { PickPartial } from '@shared/types/utilities'
import { isDischargedResident, isResident } from '@shared/utils/person'

const { baseUrl } = environment

export const getFullSizeProfileImageUrl = ({
  person,
  snapshotId,
}: {
  person: Required<PickPartial<Person, 'id' | 'orgId'>>
  snapshotId: string
}) =>
  `${getSnapshotsUrl({
    personId: person.id,
    orgId: person.orgId,
  })}/${DataType.DATA_TYPE_PROFILE_PHOTO}/${snapshotId}/file`

export const getOrgListUrl = () => '/orgs'
export const getOrgUrl = (orgId: string) => `/orgs/${orgId}`
export const getOrgSettingsUrl = (orgId: string) =>
  `${getOrgUrl(orgId)}/settings`
export const orgFacilitySettingsUrl = () =>
  `${environment.baseUrl}/orgFacilitySettings`
export const apiOrganizationsUrl = () => `${environment.baseUrl}/organizations`
export const apiOrganizationUrl = (orgId: string) =>
  `${apiOrganizationsUrl()}/${orgId}`
export const apiOrganizationDashboardsUrl = (orgId: string) =>
  `${apiOrganizationUrl(orgId)}/dashboards`
export const apiOrganizationDashboardTokenUrl = (
  orgId: string,
  dashboardId: string
) => `${apiOrganizationUrl(orgId)}/dashboards/${dashboardId}/token`
export const getProfileImageUrl = ({
  orgId,
  personId,
}: {
  personId: string
  orgId: string
}) =>
  `${apiOrganizationUrl(orgId)}/people/${personId}/image/${
    DataType.DATA_TYPE_RESIZED_PROFILE_PHOTO
  }/size/${ImageSize.IMAGE_SIZE_184X184}`

export const documentUrl = ({
  person,
  documentId,
}: {
  person: SetRequired<
    PickPartial<Person, 'id' | 'orgId' | 'facilityId' | 'residentStatus'>,
    'id' | 'orgId' | 'facilityId'
  >
  documentId: string
}) => {
  const { facilityId, id: personId, orgId } = person
  const personStatus = isResident(person) ? 'residents' : 'prospects'

  return `/orgs/${orgId}/facilities/${facilityId}/${personStatus}/${personId}/documents/${documentId}`
}
export const documentsUrl = ({
  person,
}: {
  person: Required<
    PickPartial<Person, 'id' | 'orgId' | 'facilityId' | 'residentStatus'>
  >
}) => {
  const { facilityId, id: personId, orgId } = person
  const personStatus = isResident(person) ? 'residents' : 'prospects'

  return `/orgs/${orgId}/facilities/${facilityId}/${personStatus}/${personId}/documents`
}

export function notificationSubscriptionUrl(notification: EmailNotification) {
  return `${environment.baseUrl}/notificationSubscriptions/${notification}`
}

export function notificationOptions() {
  return `${environment.baseUrl}/notificationSubscriptions/emailNotificationOptions`
}

export function notificationSubscriptions() {
  return `${environment.baseUrl}/notificationSubscriptions`
}

export const apiVitalsUrl = ({
  orgId,
  facilityId,
  id: personId,
}: Required<PersonIds>) => `${apiPersonUrl(orgId, facilityId, personId)}/vitals`
export const apiVitalsByIdUrl = (
  { orgId, facilityId, id }: Required<PersonIds>,
  vitalsId: string
) => `${apiVitalsUrl({ orgId, facilityId, id })}/${vitalsId}`
export const apiFacilitiesUrl = (orgId: string) =>
  `${apiOrganizationUrl(orgId)}/facilities`
export const apiFacilityUrl = (orgId: string, facilityId: string) =>
  `${apiFacilitiesUrl(orgId)}/${facilityId}`
export const getFacilitiesUrl = (orgId: string) =>
  `${getOrgUrl(orgId)}/facilities`
export const getFacilityUrl = (orgId: string, facilityId: string) =>
  `${getFacilitiesUrl(orgId)}/${facilityId}`
export const getProspectsUrl = (orgId: string, facilityId: string) =>
  `${getFacilityUrl(orgId, facilityId)}/prospects`
export const getComplianceUrl = (orgId: string, facilityId: string) =>
  `${getFacilityUrl(orgId, facilityId)}/compliance`
export const getIncidentsUrl = (orgId: string, facilityId: string) =>
  `${getFacilityUrl(orgId, facilityId)}/incidents`
export const getInsightsUrl = (orgId: string, facilityId: string) =>
  `${getFacilityUrl(orgId, facilityId)}/insights/1`
export const getResidentCompletionUrl = (orgId: string, facilityId: string) =>
  `${getComplianceUrl(orgId, facilityId)}/residentCompletion`
export const getResidentsUrl = (orgId: string, facilityId: string) =>
  `${getFacilityUrl(orgId, facilityId)}/residents`
export const getMovedOutResidentsUrl = (orgId: string, facilityId: string) =>
  `${getFacilityUrl(orgId, facilityId)}/residents/movedOut`
export const getClosedProspectsUrl = (orgId: string, facilityId: string) =>
  `${getFacilityUrl(orgId, facilityId)}/prospects/closed`
export const getReportsUrl = (orgId: string, facilityId: string) =>
  `${getFacilityUrl(orgId, facilityId)}/reports`
export const getOccupancyUrl = (orgId: string, facilityId: string) =>
  `${getFacilityUrl(orgId, facilityId)}/occupancy`
export const getReportUrl = (
  orgId: string,
  facilityId: string,
  reportName: string
) => `${getFacilityUrl(orgId, facilityId)}/reports/${reportName}`
export const getAccountPreferencesUrl = (orgId: string, facilityId: string) =>
  `${getFacilityUrl(orgId, facilityId)}/account_preferences`
export const getFacilityInboxUrl = (orgId: string, facilityId: string) =>
  `${getFacilityUrl(orgId, facilityId)}/inbox`
export const apiResidentCompletionStatsUrl = (
  orgId: string,
  facilityId: string
) => `${apiFacilitiesUrl(orgId)}/${facilityId}/residentCompletionStats`
export const apiFacilityCompletionStatsUrl = (
  orgId: string,
  facilityId: string
) => `${apiFacilitiesUrl(orgId)}/${facilityId}/facilityCompletionStats`
export const apiFacilityMetricsUrl = (orgId: string, facilityId: string) =>
  `${apiFacilitiesUrl(orgId)}/${facilityId}/metrics`
export const apiFacilityAnalytics = (orgId: string, facilityId: string) =>
  `${apiFacilitiesUrl(orgId)}/${facilityId}/analytics`
export const apiFacilityMetricsDataUrl = (orgId: string, facilityId: string) =>
  `${apiFacilityMetricsUrl(orgId, facilityId)}/data`
export const getFacilityUsersUrl = (orgId: string, facilityId: string) => `
${apiFacilityUrl(orgId, facilityId)}/users`
export const apiRoomUrl = (orgId: string, facilityId: string) =>
  `${apiFacilityUrl(orgId, facilityId)}/rooms`

export const apiPersonTasksUrl = (orgId: string, facilityId: string) =>
  `${apiFacilitiesUrl(orgId)}/${facilityId}/tasks`

export async function fetchPersonTasks({
  orgId,
  facilityId,
  residentStatus,
}: {
  orgId: string
  facilityId: string
  residentStatus?: Exclude<
    ResidentStatus,
    ResidentStatus.RESIDENT_STATUS_UNSPECIFIED | ResidentStatus.UNRECOGNIZED
  >
}): Promise<PersonTasks[]> {
  let url = apiPersonTasksUrl(orgId, facilityId)

  if (residentStatus) {
    url += `?residentStatus=${residentStatus}`
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const response = await requestJson({
    url,
  })

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return response.data as Promise<PersonTasks[]>
}

type ListSnapshotsUrlArgs = {
  orgId: string
  personId: string
}

function getSnapshotsUrl({ orgId, personId }: ListSnapshotsUrlArgs) {
  return `${baseUrl}/organizations/${orgId}/people/${personId}/snapshots`
}

export const apiUploadUrl = ({
  documentId,
  orgId,
  personId,
  dataType,
  customType,
}: {
  orgId: string
  personId: string
  dataType: DataType
  documentId?: string
  customType?: string
}) => {
  let coreUrl = `${environment.baseUrl}/organizations/${orgId}/people/${personId}/snapshots/${dataType}`

  if (documentId) {
    coreUrl += `/${documentId}`
  }

  if (customType) {
    coreUrl += `?customType=${encodeURIComponent(customType)}`
  }

  return coreUrl
}
export const apiPartialUploadUrl = ({
  orgId,
  personId,
  dataType,
  customType,
}: {
  orgId: string
  personId: string
  dataType: DataType
  customType?: string
}) => {
  if (customType) {
    return `${apiUploadUrl({
      orgId,
      personId,
      dataType,
    })}/partialFile?customType=${encodeURIComponent(customType)}`
  }
  return `${apiUploadUrl({
    orgId,
    personId,
    dataType,
  })}/partialFile`
}

export const apiUploadPOLSTUrl = ({
  orgId,
  personId,
}: {
  orgId: string
  personId: string
}) =>
  `${environment.baseUrl}/organizations/${orgId}/people/${personId}/snapshots/DATA_TYPE_POLST`

interface SnapshotFileUrl {
  personId: string
  orgId: string
  snapshotId?: string
  dataType: DataType
  params?: {
    customType?: string
  }
}

export function snapshotFileUrl({
  personId,
  orgId,
  dataType,
  snapshotId = 'latest',
  params = {},
}: SnapshotFileUrl): string {
  return getUrl({
    pId: personId,
    orgId,
    snapshotId,
    dataType,
    params,
    baseUrl: SNAPSHOT_FILE_URL,
  })
}

export const apiChargesUrl = ({ orgId, facilityId, id }: PersonIds) =>
  `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/people/${id}/charges`
export const apiRecurringChargesUrl = ({ orgId, facilityId, id }: PersonIds) =>
  `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/people/${id}/recurring_charges`
export const apiRecurringChargeUrl = (chargeId: string, person: PersonIds) =>
  `${apiRecurringChargesUrl(person)}/${chargeId}`
export const apiOneTimeChargeUrl = (chargeId: string, person: PersonIds) =>
  `${apiChargesUrl(person)}/${chargeId}`

export function apiOneTimeChargesCsvReportUrl({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId: string
}) {
  return `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/charges/report.csv`
}

type PersonURLProps = {
  orgId: string
  facilityId: string
  id: string
  residentStatus?: ResidentStatus
}
export const tabUrlForPerson = (
  {
    orgId,
    facilityId,
    id,
    residentStatus,
  }: SetRequired<
    PickPartial<Person, 'id' | 'orgId' | 'facilityId' | 'residentStatus'>,
    'id' | 'orgId' | 'facilityId'
  >,
  tab:
    | 'details'
    | 'contacts'
    | 'admission_tasks'
    | 'medications'
    | 'billing'
    | 'documents'
    | 'care'
    | 'notes'
    | 'incidents'
    | 'routines'
) =>
  `${defaultPathForPerson({
    orgId: orgId,
    facilityId: facilityId,
    id: id,
    residentStatus: residentStatus,
  })}/${tab}`
export const defaultPathForPerson = ({
  orgId,
  facilityId,
  id,
  residentStatus,
}: PersonURLProps) =>
  `/orgs/${orgId}/facilities/${facilityId}/${
    isResident({ residentStatus }) || isDischargedResident({ residentStatus })
      ? 'residents'
      : 'prospects'
  }/${id}`
export const detailsPathForPerson = (
  person: SetRequired<
    PickPartial<Person, 'id' | 'orgId' | 'facilityId' | 'residentStatus'>,
    'id' | 'orgId' | 'facilityId'
  >
) => tabUrlForPerson(person, 'details')
export const contactsPathForPerson = (
  person: SetRequired<
    PickPartial<Person, 'id' | 'orgId' | 'facilityId' | 'residentStatus'>,
    'id' | 'orgId' | 'facilityId'
  >
) => tabUrlForPerson(person, 'contacts')
export const admissionTasksPathForPerson = (
  person: SetRequired<
    PickPartial<Person, 'id' | 'orgId' | 'facilityId' | 'residentStatus'>,
    'id' | 'orgId' | 'facilityId'
  >
) => tabUrlForPerson(person, 'admission_tasks')
export const taskPathForPerson = (
  person: SetRequired<
    PickPartial<Person, 'id' | 'orgId' | 'facilityId' | 'residentStatus'>,
    'id' | 'orgId' | 'facilityId'
  >,
  taskId: string
) =>
  defaultPathForPerson({
    orgId: person.orgId,
    facilityId: person.facilityId,
    id: person.id,
  }) + `/tasks/${taskId}`
export const medicationsPathForPerson = (
  person: SetRequired<
    PickPartial<Person, 'id' | 'orgId' | 'facilityId' | 'residentStatus'>,
    'id' | 'orgId' | 'facilityId'
  >
) => tabUrlForPerson(person, 'medications')
export const carePathForPerson = (
  person: SetRequired<
    PickPartial<Person, 'id' | 'orgId' | 'facilityId' | 'residentStatus'>,
    'id' | 'orgId' | 'facilityId'
  >
) => tabUrlForPerson(person, 'care')
export const documentsPathForPerson = (
  person: SetRequired<
    PickPartial<Person, 'id' | 'orgId' | 'facilityId' | 'residentStatus'>,
    'id' | 'orgId' | 'facilityId'
  >
) => tabUrlForPerson(person, 'documents')
export const documentPathForPerson = (
  person: SetRequired<
    PickPartial<Person, 'id' | 'orgId' | 'facilityId' | 'residentStatus'>,
    'id' | 'orgId' | 'facilityId'
  >,
  document: PickPartial<Snapshot, 'id'>
) => tabUrlForPerson(person, 'documents') + `/${document.id}`
export const billingPathForPerson = (
  person: SetRequired<
    PickPartial<Person, 'id' | 'orgId' | 'facilityId' | 'residentStatus'>,
    'id' | 'orgId' | 'facilityId'
  >
) => tabUrlForPerson(person, 'billing')
export const notesPathForPerson = (
  person: SetRequired<
    PickPartial<Person, 'id' | 'orgId' | 'facilityId' | 'residentStatus'>,
    'id' | 'orgId' | 'facilityId'
  >
) => tabUrlForPerson(person, 'notes')
export const incidentPathForPerson = (
  person: SetRequired<
    PickPartial<Person, 'id' | 'orgId' | 'facilityId' | 'residentStatus'>,
    'id' | 'orgId' | 'facilityId'
  >,
  incident: Required<PickPartial<Incident, 'id'>>
) => tabUrlForPerson(person, 'incidents') + `/${incident.id}/edit`
export const routinePathForPerson = (
  person: SetRequired<
    PickPartial<Person, 'id' | 'orgId' | 'facilityId' | 'residentStatus'>,
    'id' | 'orgId' | 'facilityId'
  >
) => tabUrlForPerson(person, 'routines')
export const schedulePathForPerson = (
  person: SetRequired<
    PickPartial<Person, 'id' | 'orgId' | 'facilityId' | 'residentStatus'>,
    'id' | 'orgId' | 'facilityId'
  >,
  routine: Routine
) => tabUrlForPerson(person, 'routines') + `/${routine.id}/schedule`
export const prospectsList = (
  orgId: string,
  facilityId: string,
  queryParams: Record<string, string>
) =>
  `/orgs/${orgId}/facilities/${facilityId}/prospects?${new URLSearchParams(
    queryParams
  ).toString()}`
export const apiPeopleUrl = (orgId: string, facilityId: string) =>
  `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/people`
export const apiPersonUrl = (
  orgId: string,
  facilityId: string,
  personId: string
) => `${apiPeopleUrl(orgId, facilityId)}/${personId}`
export const apiPeopleStatsUrl = (orgId: string, facilityId: string) =>
  `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/peopleStats`
export const emergencyPacketUrl = ({
  orgId,
  facilityId,
  id,
}: {
  orgId: string
  facilityId: string
  id: string
}) => `${apiPersonUrl(orgId, facilityId, id)}/emergencyPacket.pdf`
export const personCompletionStatsUrl = ({
  orgId,
  facilityId,
  id,
}: {
  orgId: string
  facilityId: string
  id: string
}) => `${apiPersonUrl(orgId, facilityId, id)}/residentCompletionStats`
export const apiTransferPersonUrl = (
  orgId: string,
  facilityId: string,
  id: string
) => `${apiPersonUrl(orgId, facilityId, id)}/transfer`
type TaskURLProps = {
  facilityId?: string
  orgId: string
  personId: string
  id?: string
  page?: 'sign' | 'signInPerson' | 'review' | 'requiredFields' | number
  email?: string
}
export const getTasksUrl = ({
  orgId,
  facilityId,
  personId,
}: SetRequired<TaskURLProps, 'facilityId'>) =>
  `${defaultPathForPerson({
    orgId,
    facilityId: facilityId,
    id: personId,
  })}/tasks`
export const getTaskUrl = ({
  orgId,
  facilityId,
  id,
  page,
  personId,
  email,
}: SetRequired<TaskURLProps, 'facilityId'>) => {
  const url = `${getTasksUrl({ orgId, facilityId, personId })}/${id}${
    page ? `/${page}` : ''
  }`

  if (email) {
    return `${url}?email=${encodeURIComponent(email)}`
  }

  return url
}
const apiTaskUrl = ({ orgId, personId, id }: TaskURLProps) =>
  `${environment.baseUrl}/organizations/${orgId}/people/${personId}/tasks/${id}`
export const completeViaUploadUrl = ({ personId, orgId, id }: TaskURLProps) =>
  `${apiTaskUrl({ orgId, personId, id })}/completeViaUpload`
export const completeViaPartialUploadUrl = ({
  personId,
  orgId,
  id,
}: TaskURLProps) =>
  `${apiTaskUrl({ orgId, personId, id })}/completeViaPartialUpload`
export const closeTaskUrl = ({
  task,
  person,
}: {
  task: Required<PickPartial<Task, 'id'>>
  person: PersonIds
}) =>
  `${environment.baseUrl}/organizations/${person.orgId}/people/${person.id}/tasks/${task.id}/close`
const apiTasksUrl = ({ orgId, personId }: TaskURLProps) =>
  `${environment.baseUrl}/organizations/${orgId}/people/${personId}/tasks`
const apiTasksWithFacilityIdUrl = (person: PersonIds) => {
  const { facilityId, id, orgId } = person
  return `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/people/${id}/tasks`
}
export type PersonIdsWithDataType = {
  dataType: DataType
  person: PersonIds
}
const apiTaskWithDataTypeUrl = ({
  dataType,
  person,
}: PersonIdsWithDataType) => {
  return `${apiTasksWithFacilityIdUrl(person)}/${dataType}`
}
export const apiTaskDueDateUrl = ({
  dataType,
  person,
}: PersonIdsWithDataType) => {
  const baseUrl = apiTaskWithDataTypeUrl({
    person,
    dataType,
  })
  return `${baseUrl}/dueDate`
}
// Share Tasks Url without facilityId is deprecated
export const shareTasksUrl = ({
  orgId,
  personId,
}: {
  orgId: string
  personId: string
}) => `${apiTasksUrl({ orgId, personId })}/share`
export const apiRecreateTaskUrl = ({
  orgId,
  facilityId,
  personId,
  dataType,
  customType,
}: {
  orgId: string
  facilityId: string
  personId: string
  dataType: DataType
  customType?: string
}) => {
  const params = customType
    ? `?customType=${encodeURIComponent(customType)}`
    : ''
  return `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/people/${personId}/tasks/${dataType}${params}`
}
export type ReminderUrlProps = {
  facilityId: string
  orgId: string
  personId: string
}
export const apiTasksReminderUrl = ({
  facilityId,
  orgId,
  personId,
}: ReminderUrlProps) =>
  `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/people/${personId}/tasks/remind`
export type CompleteTaskManuallyUrlProps = {
  task: Required<PickPartial<Task, 'id'>>
  person: PersonIds
}
export const apiCompleteTaskManuallyUrl = ({
  person: { orgId, facilityId, id: personId },
  task: { id },
}: CompleteTaskManuallyUrlProps) =>
  `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/people/${personId}/tasks/${id}/completeManually`
export const pdfUrl = (templateId: string) =>
  `${environment.baseUrl}/forms/${templateId}/template.pdf`
export const dataTypePdfUrl = ({
  person,
  dataType,
  customType,
}: {
  person: Required<Pick<Person, 'orgId' | 'facilityId' | 'id'>>
  dataType: DataType
  customType?: string
}) => {
  const coreUrl = `${apiPersonUrl(
    person.orgId,
    person.facilityId,
    person.id
  )}/forms/${dataType}.pdf`

  if (customType) {
    return `${coreUrl}?customType=${encodeURIComponent(customType)}`
  }

  return coreUrl
}
export const getImmunizationsUrl = (
  person: Required<PickPartial<Person, 'orgId' | 'facilityId' | 'id'>>
) => `${apiPersonUrl(person.orgId, person.facilityId, person.id)}/immunizations`
export const createImmunizationUrl = (person: Required<PersonIds>) =>
  `${apiPersonUrl(person.orgId, person.facilityId, person.id)}/immunizations`
export const attachImmunizationUrl = (
  person: Required<PersonIds>,
  immunizationId: string
) => `${createImmunizationUrl(person)}/${immunizationId}/attachment`
export const attachPartialImmunizationUrl = (
  person: Required<PersonIds>,
  immunizationId: string
) => `${createImmunizationUrl(person)}/${immunizationId}/partialFileAttachment`
export const getSingleImmunizationUrl = (
  person: Required<PersonIds>,
  immunization: Immunization
) => `${getImmunizationsUrl(person)}/${immunization.id}`

export const getAllImportsUrl = ({ kind }: { kind: ImportKind }) =>
  `${environment.baseUrl}/imports?kind=${kind}`

export function incidentsUrl(person: Required<PersonIds>) {
  return `${apiPersonUrl(person.orgId, person.facilityId, person.id)}/incidents`
}

export function startIncidentAlertUrl(
  person: Required<PersonIds>,
  incident: Required<PickPartial<Incident, 'id'>>
) {
  return `${incidentUrl(person, incident)}/startAlert`
}

export function stopIncidentAlertUrl(
  person: Required<PersonIds>,
  incident: Required<PickPartial<Incident, 'id'>>
) {
  return `${incidentUrl(person, incident)}/stopAlert`
}

export function incidentUrl(
  person: Required<PersonIds>,
  incident: Required<PickPartial<Incident, 'id'>>
) {
  return `${apiPersonUrl(
    person.orgId,
    person.facilityId,
    person.id
  )}/incidents/${incident.id}`
}

export function incidentActionUrl(
  person: Required<PersonIds>,
  incident: Required<PickPartial<Incident, 'id'>>,
  incidentAction: Required<PickPartial<IncidentAction, 'id'>>
) {
  return `${apiPersonUrl(
    person.orgId,
    person.facilityId,
    person.id
  )}/incidents/${incident.id}/actions/${incidentAction.id}`
}

export function incidentStatusUrl(
  person: Required<PersonIds>,
  incident: Required<PickPartial<Incident, 'id'>>
) {
  return `${incidentUrl(person, incident)}/status`
}

export function facilityIncidentsUrl(facility: FacilityIds, params: ParamMap) {
  return urlcat(
    apiFacilityUrl(facility.orgId, facility.id),
    'incidents',
    params
  )
}

export const incidentCommentsUrl = (
  person: Required<PersonIds>,
  incident: Required<PickPartial<Incident, 'id'>>
) => `${incidentUrl(person, incident)}/comments`
export const getStateIncidentReportUrl = (
  person: Required<PersonIds>,
  incident: Required<PickPartial<Incident, 'id'>>
) => `${incidentUrl(person, incident)}/stateIncidentReport.pdf`
export const incidentAttachmentsUrl = (
  person: Required<PersonIds>,
  incidentId: string
) => {
  return `${apiPersonUrl(
    person.orgId,
    person.facilityId,
    person.id
  )}/incidents/${incidentId}/attachments`
}
export const deleteIncidentAttachmentUrl = (
  person: Required<PersonIds>,
  incidentId: string,
  snapshotId: string
): string => {
  return `${apiPersonUrl(
    person.orgId,
    person.facilityId,
    person.id
  )}/incidents/${incidentId}/attachments/${snapshotId}`
}

export function followUpActionsUrl({
  person,
  incidentId,
}: {
  person: Required<PersonIds>
  incidentId: string
}): string {
  return `${apiPersonUrl(
    person.orgId,
    person.facilityId,
    person.id
  )}/incidents/${incidentId}/actions`
}

export function followUpActionUrl({
  person,
  incidentId,
  actionId,
}: {
  person: Required<PersonIds>
  incidentId: string
  actionId: string
}) {
  return `${followUpActionsUrl({ person, incidentId })}/${actionId}`
}
