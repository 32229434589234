import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { AppraisalSettings_AppraisalCategory } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { useContext, useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { BasicSpinner } from '@shared/components/BasicSpinner'
import Card from '@shared/components/Card'
import LinkButton from '@shared/components/LinkButton'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import TimingBadges from '@shared/components/TimingBadges'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import GlobalContext from '@shared/contexts/GlobalContext'
import { InclusiveDatePeriod } from '@shared/types/date'
import { Person, PersonIds } from '@shared/types/person'
import { RoutineOrder } from '@shared/types/routine_order'
import { monthDayYear } from '@shared/utils/date'
import { AsyncResult } from '@shared/utils/loading'
import {
  isDraft,
  isDraftOrDiscarded,
  isMorningOrBedtime,
} from '@shared/utils/routineOrder'
import { twx } from '@shared/utils/tailwind'
import { discardRoutineOrder } from '@app/api/routineOrders'
import { residentEditRoutinePath } from '@app/components/Residents'
import AppraisalCategoryBadge from './CustomRoutines/AppraisalCategoryBadge'
import { canEditRoutineOrder } from './CustomRoutines/permissions'
import { Instructions } from './RoutineOrderInstructions'

function IconArrowRight() {
  return <i className={twx`fas fa-fw fa-arrow-right-from-line`}></i>
}

function DatePeriod({ datePeriod }: { datePeriod: InclusiveDatePeriod }) {
  return (
    <div className="flex gap-4 font-medium text-gray-07">
      {datePeriod.startDate && (
        <div title={'Routine start date'} className="flex items-center gap-1">
          <IconArrowRight />
          <span>{monthDayYear(datePeriod.startDate)}</span>
        </div>
      )}
      {datePeriod.endDate && (
        <div title={'Routine end date'} className="flex items-center gap-1">
          <IconArrowRight />
          <span>{monthDayYear(datePeriod.endDate)}</span>
        </div>
      )}
    </div>
  )
}

function routineOrderCardClasses(routineOrder: RoutineOrder): string {
  const textColor = isDraftOrDiscarded(routineOrder)
    ? 'text-gray-08'
    : 'text-gray-01'
  return twx(
    'block',
    'bg-white p-4 shadow-card',
    'text-sm leading-5 tracking-tight',
    textColor
  )
}

function RoutineOrderEditButtons({
  routineOrder,
  person,
  onRoutineOrderUpdate,
}: {
  routineOrder: RoutineOrder
  person: Required<PersonIds>
  onRoutineOrderUpdate: () => Promise<void>
}) {
  const { setError } = useContext(GlobalContext)
  const [isDiscarding, setIsDiscarding] = useState(false)

  async function onDiscard() {
    setIsDiscarding(true)
    const response = await discardRoutineOrder(person, routineOrder.id)
    if (response.tag === 'Complete') {
      await onRoutineOrderUpdate()
    } else {
      setError(response.value)
    }
    setIsDiscarding(false)
  }

  if (isDraftOrDiscarded(routineOrder)) {
    return (
      <div>
        <LinkButton
          className="mr-4"
          buttonSize="small"
          icon="fa-calendar-check"
          buttonStyle="primary-outline"
          href={residentEditRoutinePath(person, routineOrder.id)}
        >
          Schedule
        </LinkButton>
        {isDraft(routineOrder) && (
          <AsyncIconButton
            buttonStyle="secondary-outline"
            buttonSize="small"
            disabled={isDiscarding}
            isLoading={isDiscarding}
            onClick={onDiscard}
          >
            Not now
          </AsyncIconButton>
        )}
      </div>
    )
  } else {
    return (
      <LinkButton
        className="max-w-fit"
        buttonSize="small"
        icon="fa-pen"
        buttonStyle="secondary-outline"
        href={residentEditRoutinePath(person, routineOrder.id)}
      >
        Edit
      </LinkButton>
    )
  }
}

function RoutineCardList({
  routineOrders,
  categories,
  person,
  use24HourClock,
  onRoutineOrderUpdate,
}: {
  routineOrders: RoutineOrder[]
  categories: AppraisalSettings_AppraisalCategory[]
  person: Person
  use24HourClock: boolean
  onRoutineOrderUpdate: () => Promise<void>
}) {
  return (
    !!routineOrders.length && (
      <ul className={twx`flex flex-col gap-4`}>
        {routineOrders.map((routineOrder) => (
          <Card
            key={routineOrder.id}
            className={routineOrderCardClasses(routineOrder)}
          >
            <li className="flex flex-col justify-between gap-2 sm:flex-row">
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-2">
                  <AppraisalCategoryBadge
                    categories={categories}
                    routineOrder={routineOrder}
                  />
                  <p className={twx`font-semibold`}>{routineOrder.name}</p>
                </div>
                <Instructions
                  routineOrder={routineOrder}
                  abbreviated={isMorningOrBedtime(routineOrder)}
                />
                {routineOrder?.schedules?.at(0) && (
                  <div className="flex flex-col items-start gap-6">
                    <div className={twx`flex flex-wrap gap-2`}>
                      <TimingBadges
                        displayPeriodAndFrequency={true}
                        timing={routineOrder.schedules.at(0)?.timing}
                        use24HourTime={use24HourClock}
                        keyPrefix={`routine-order-${routineOrder.id}`}
                        className={twx`bg-gray-07 font-medium text-white`}
                      />
                    </div>
                    {routineOrder.effectivePeriod && (
                      <DatePeriod datePeriod={routineOrder.effectivePeriod} />
                    )}
                  </div>
                )}
              </div>
              <div className="flex flex-col items-end justify-between">
                {canEditRoutineOrder(routineOrder) && (
                  <PersonPermissionGate
                    person={person}
                    permissions={[
                      GroupPermission.GROUP_PERMISSION_ROUTINE_UPDATE,
                    ]}
                  >
                    <RoutineOrderEditButtons
                      routineOrder={routineOrder}
                      person={person}
                      onRoutineOrderUpdate={onRoutineOrderUpdate}
                    />
                  </PersonPermissionGate>
                )}
              </div>
            </li>
          </Card>
        ))}
      </ul>
    )
  )
}

export function RoutineOrderList({
  routineOrders,
  categories,
  person,
  onRoutineOrderChange,
}: {
  routineOrders: AsyncResult<RoutineOrder[], unknown>
  categories: AppraisalSettings_AppraisalCategory[]
  person: Person
  onRoutineOrderChange: () => Promise<void>
}) {
  const {
    settings: { use24HourClock },
  } = useContext(CurrentFacilityContext)

  switch (routineOrders.tag) {
    case 'Complete':
      return (
        <RoutineCardList
          routineOrders={routineOrders.value}
          categories={categories}
          person={person}
          use24HourClock={use24HourClock}
          onRoutineOrderUpdate={onRoutineOrderChange}
        />
      )
    case 'Loading':
      return (
        <div className="flex items-center gap-2">
          <BasicSpinner className="fa-fw text-secondary" />
          <span>Loading custom routines</span>
        </div>
      )
    case 'Error':
      return (
        <p className="text-md text-center font-medium text-gray-07">
          An error occurred loading custom routine information!
        </p>
      )
  }
}
