import { OptionTypeBase } from '@shared/components/StyledSelect'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { BaseFacilitySelect } from './base/BaseFacilitySelect'
import { BaseOrgSelect } from './base/BaseOrgSelect'

type Props = {
  onOrgSelect: (org: OptionTypeBase<Organization> | null) => void
  onFacilitySelect: (facility: OptionTypeBase<Facility> | null) => void
  orgLabel?: string
  facilityLabel?: string
  selectedOrganization: OptionTypeBase<Organization> | null
  selectedFacility: OptionTypeBase<Facility> | null
}
export const OrgFacilitySelects = ({
  onOrgSelect,
  onFacilitySelect,
  orgLabel,
  facilityLabel,
  selectedOrganization,
  selectedFacility,
}: Props) => {
  return (
    <>
      <BaseOrgSelect
        orgLabel={orgLabel}
        onOrgSelect={(o: OptionTypeBase<Organization>) => {
          onFacilitySelect(null)
          onOrgSelect(o)
        }}
        selectClassName={'mb-[16px]'}
        selectedOrganization={selectedOrganization}
      />
      <BaseFacilitySelect
        facilityLabel={facilityLabel}
        onFacilitySelect={(o: OptionTypeBase<Facility>) => {
          onFacilitySelect(o)
        }}
        orgId={selectedOrganization?.value.id}
        selectedFacility={selectedFacility}
      />
    </>
  )
}
