import { useState } from 'react'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import Icon from '@shared/components/Icon'
import StyledSelect, {
  StyledSelectProps,
} from '@shared/components/StyledSelect'
import { tw } from '@shared/utils/tailwind'
import {
  Option,
  SnapshotSortOrder,
  TaskSortOrder,
} from '@app/components/SortByDropdown/helpers'

type Props = Omit<StyledSelectProps, 'value'> & {
  value: Option | null
}

function getSortOrder(opt: Option | null) {
  return opt?.value || 'Sort by...'
}

function getIconName(opt: Option | null) {
  const sortOrder = getSortOrder(opt)
  switch (sortOrder) {
    case SnapshotSortOrder.SNAPSHOT_SORT_BY_NAME_ASC:
    case TaskSortOrder.TASK_SORT_BY_NAME_ASC:
      return 'arrow-up-a-z'
    case SnapshotSortOrder.SNAPSHOT_SORT_BY_NAME_DESC:
    case TaskSortOrder.TASK_SORT_BY_NAME_DESC:
      return 'arrow-up-z-a'
    case SnapshotSortOrder.SNAPSHOT_SORT_BY_LAST_UPDATE_ASC:
    case TaskSortOrder.TASK_SORT_BY_LAST_UPDATE_ASC:
      return 'arrow-up-1-9'
    case SnapshotSortOrder.SNAPSHOT_SORT_BY_LAST_UPDATE_DESC:
    case TaskSortOrder.TASK_SORT_BY_LAST_UPDATE_DESC:
      return 'arrow-up-9-1'
    default:
      return 'sort'
  }
}

export default function SortByDropdown(props: Props) {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const { onChange, value, ...restProps } = props

  return (
    <span>
      <Button
        buttonStyle="secondary-outline"
        onClick={() => setMenuIsOpen((prev) => !prev)}
        onBlur={() => setMenuIsOpen(false)}
        title={getSortOrder(value)}
      >
        <Icon
          name={getIconName(value)}
          variant="solid"
          className={tw`text-[16px]`}
        />
      </Button>
      <StyledSelect
        {...restProps}
        onChange={(opt, meta) => {
          if (onChange) {
            onChange(opt, meta)
          }
          setMenuIsOpen(false)
        }}
        menuIsOpen={menuIsOpen}
        styles={{
          control: () => ({
            display: menuIsOpen ? 'default' : 'none',
            visibility: 'hidden',
            height: 0,
            pointerEvents: 'none',
          }),
          menu: (base) => ({
            ...base,
            width: 'default',
            right: 0,
          }),
          valueContainer: () => ({ display: 'none' }),
        }}
        menuPortalTarget={null}
      />
    </span>
  )
}
