import { isNumber } from 'lodash'
import { match } from 'ts-pattern'
import { AdmissionsInformation_AdmissionType as AdmissionType } from '@shared/types/admissions'
import {
  AdmissionTypeChanged,
  BillingEvent,
  BillingEventStatus,
  BillingEventType as Event,
  LevelOfCareChanged,
  ResidentStatusChanged,
  RoomChanged,
  RoomDescription,
} from '@shared/types/billing'
import { Person } from '@shared/types/person'
import { getAdmissionTypeLabel } from '@shared/utils/admissions'
import { formatDateWithWeekOfDay } from '@shared/utils/date'
import {
  formatRoomNumber,
  getResidentMoveInDate,
  getResidentMoveOutDate,
} from '@shared/utils/person'
import {
  isCurrentResident,
  isDischarged,
  isProspect,
} from '@shared/utils/residentStatus'

function getAdmissionType(admissionType: AdmissionType) {
  return getAdmissionTypeLabel(admissionType).toLowerCase()
}

function getBedRoomLabel(bedAndRoom: RoomDescription) {
  const { bedNumber = '', roomNumber } = bedAndRoom

  return formatRoomNumber(roomNumber, bedNumber)
}

/**
 * Display Pending Billing Events message or null if message is not supported
 */
export function getEventLabel({
  billingEvent,
  person,
}: {
  billingEvent: BillingEvent
  person: Person
}) {
  const {
    meta: { modifiedAt },
  } = billingEvent
  const dateOn = modifiedAt
    ? ` on ${formatDateWithWeekOfDay(new Date(modifiedAt))}`
    : ''

  let message: string | null = null

  match(billingEvent)
    .with(
      { data: { eventType: Event.ROOM_CHANGED } },
      (e) => (message = roomChangeMessage(e.data.data, dateOn))
    )
    .with(
      { data: { eventType: Event.RESIDENT_STATUS_CHANGED } },
      (e) => (message = residentStatusChangeMessage(e.data.data, person))
    )
    .with(
      { data: { eventType: Event.ADMISSION_TYPE_CHANGED } },
      (e) => (message = admissionTypeChanged(e.data.data, dateOn))
    )
    .with({ data: { eventType: Event.LEVEL_OF_CARE_CHANGED } }, (e) => {
      message = careLevelChanged(e.data.data, dateOn)
    })
    .exhaustive()

  return message
}

export type FormattedEvent = {
  id: string
  message: string
}

/**
 * Filter out completed and not supported Billing Events
 * Put in here since currently use EventLabel as function to detect supported Events
 */
export function filterOutInvalidBillingEvents({
  billingEvents,
  person,
}: {
  billingEvents: BillingEvent[]
  person: Person
}) {
  return billingEvents.reduce((filtered: FormattedEvent[], event) => {
    const {
      data: { status },
      meta: { id: eventId },
    } = event
    const msg = getEventLabel({ billingEvent: event, person }) // return null if not supported
    if (status !== BillingEventStatus.COMPLETED && msg !== null) {
      filtered.push({ id: eventId, message: msg })
    }
    return filtered
  }, [])
}

/**
 * Functions to turn billing events into human-readable messages.
 * The handling of spaces before/after phrases is a little janky.
 * We should probably be using an Array and join().
 */

function roomChangeMessage(data: RoomChanged, modificationDate: string) {
  const { newRoom, previousRoom } = data
  if (previousRoom) {
    return `Resident moved from room ${getBedRoomLabel(previousRoom)} to ${getBedRoomLabel(newRoom)}${modificationDate}.`
  }

  return `Resident moved into room ${getBedRoomLabel(newRoom)}${modificationDate}.`
}

function residentStatusChangeMessage(
  data: ResidentStatusChanged,
  person: Person
) {
  const { newStatus, previousStatus } = data
  const moveInDate = getResidentMoveInDate(person)
  const moveOutDate = getResidentMoveOutDate(person)

  if (
    moveOutDate &&
    isCurrentResident(previousStatus) &&
    isDischarged(newStatus)
  ) {
    return `Resident moved out on ${formatDateWithWeekOfDay(moveOutDate)}.`
  }

  if (moveInDate) {
    if (isProspect(previousStatus) && isCurrentResident(newStatus)) {
      return `Resident moved in on ${formatDateWithWeekOfDay(moveInDate)}.`
    } else if (isDischarged(previousStatus) && isCurrentResident(newStatus)) {
      return `Resident was re-admitted on ${formatDateWithWeekOfDay(moveInDate)}.`
    }
  }

  return null
}

function admissionTypeChanged(
  data: AdmissionTypeChanged,
  modificationDate: string
) {
  const { currentAdmissionType, previousAdmissionType } = data
  if (previousAdmissionType) {
    return `Admission type changed from ${getAdmissionType(previousAdmissionType)} to ${getAdmissionType(currentAdmissionType)}${modificationDate}.`
  }

  return `Admission type set to ${getAdmissionType(currentAdmissionType)}${modificationDate}.`
}

function careLevelChanged(data: LevelOfCareChanged, modificationDate: string) {
  const { currentLevelOfCare, previousLevelOfCare } = data

  if (previousLevelOfCare) {
    let fromText: string = ''
    let toText: string = ''

    if (isNumber(currentLevelOfCare.value)) {
      toText += `to ${currentLevelOfCare.value}`
    } else if (isNumber(currentLevelOfCare.score)) {
      toText += `to ${currentLevelOfCare.score}`
    }

    if (toText && isNumber(previousLevelOfCare.value)) {
      fromText += `from ${previousLevelOfCare.value} `
    } else if (toText && isNumber(previousLevelOfCare.score)) {
      fromText += `from ${previousLevelOfCare.score} `
    }

    if (toText && fromText) {
      return `Care level changed ${fromText}${toText}${modificationDate}.`
    } else if (toText) {
      return `Care level set ${toText}${modificationDate}.`
    } else {
      return `Care level changed.`
    }
  }

  let toText: string = ''

  if (isNumber(currentLevelOfCare.value)) {
    toText += `to ${currentLevelOfCare.value}`
  } else if (isNumber(currentLevelOfCare.score)) {
    toText += `to ${currentLevelOfCare.score}`
  }

  return `Care level changed ${toText}${modificationDate}.`
}
