import { useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { fetchPerson } from '@shared/api/person'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import {
  getCategoriesFromSnapshot,
  getLevelAndScoreFromSnapshot,
} from '@shared/utils/residentAssessment'
import AssessmentPage from '../Tasks/ResidentAssessment/AssessmentPage'
import { getOrderedCategories } from '../Tasks/ResidentAssessment/helpers'
import DocumentPageHolder from './DocumentPageHolder'

interface Props
  extends RouteComponentProps<{
    orgId: string
    facilityId: string
    personId: string
    documentId: string
  }> {
  snapshot: Snapshot
}

export default function ArchivedAssessment({ match, snapshot }: Props) {
  const { setError } = useContext(GlobalContext)
  const [person, setPerson] = useState<Person>()
  const { orgId, facilityId, personId } = match.params
  const categories = getCategoriesFromSnapshot(snapshot) ?? []

  useEffect(() => {
    fetchPerson({
      orgId,
      facilityId,
      personId,
    })
      .then(setPerson)
      .catch(setError)
  }, [match])

  if (person === undefined) {
    return null
  }

  const isExternalAppraisal =
    snapshot.data?.augustInitialAppraisal?.isExternalAppraisal ?? false
  const { level, score } = getLevelAndScoreFromSnapshot(snapshot)

  return (
    <DocumentPageHolder
      title="Resident Assessment"
      person={person}
      snapshot={snapshot}
      score={score}
      level={level}
    >
      {isExternalAppraisal && (
        <div className={'mb-[16px]'}>
          This appraisal was conducted outside of August Health.
        </div>
      )}
      {!isExternalAppraisal && (
        <AssessmentPage
          showAdmissionTypeAndAssessmentReason={true}
          mode={{ tag: 'view' }}
          person={person}
          snapshot={snapshot}
          categories={getOrderedCategories({ categories })}
        />
      )}
    </DocumentPageHolder>
  )
}
