import { Task, TaskStatus } from '@augusthealth/models/com/august/protos/task'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { BasicCheckbox } from '@shared/components/BasicInput/BasicInput'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { taskSubtitle, taskTitle } from '@shared/utils/task'
import Warning from '../Warning'
import { closeTasks } from './helpers'

export function RemoveTasksModal({
  incompleteTasks,
  done,
  person,
}: {
  incompleteTasks: Task[]
  done: (didCloseTasks: boolean) => Promise<void>
  person: Person
}) {
  const { setError } = useContext(GlobalContext)
  const [tasksToDelete, setTasksToDelete] = useState<Set<Task>>(new Set())
  const { handleSubmit, formState } = useForm()

  const onCloseTasks = async () => {
    try {
      await closeTasks(tasksToDelete, person)
      await done(true)
    } catch (e) {
      setError(e)
    }
  }

  return (
    <AnimatedPopup
      title={`Remove Tasks for ${getFirstAndLastName(person.name)}`}
      onClickBackground={() => done(false)}
    >
      <form onSubmit={handleSubmit(onCloseTasks)}>
        <Warning className={'mb-[16px] mt-[-12px] text-[12px]'}>
          <p>
            This will <strong>cancel</strong> the selected tasks and{' '}
            <strong>discard all changes</strong>. A new task will need to be
            created in the future.
          </p>
        </Warning>
        {incompleteTasks.map((task, ix) => (
          <CloseTaskCheckbox
            id={`close-task-${ix}`}
            key={`close-task-checkbox-${ix}`}
            setTasksToDelete={setTasksToDelete}
            tasksToDelete={tasksToDelete}
            task={task}
          />
        ))}
        <div className={'mt-[32px]'}>
          <AnimatedPopupFormFooter
            yesBtn={{
              label: 'Remove Tasks',
              props: {
                disabled: tasksToDelete.size === 0,
                id: 'close-tasks-submit',
              },
            }}
            noBtn={{ action: () => void done(false) }}
            formState={formState}
          />
        </div>
      </form>
    </AnimatedPopup>
  )
}

interface CloseTasksCheckboxProps extends React.HTMLProps<HTMLInputElement> {
  task: Task
  setTasksToDelete: (newTasks: Set<Task>) => void
  tasksToDelete: Set<Task>
}

function CloseTaskCheckbox({
  task,
  tasksToDelete,
  setTasksToDelete,
  id,
}: CloseTasksCheckboxProps) {
  const shouldShowWarning =
    tasksToDelete.has(task) &&
    task.status === TaskStatus.TASK_STATUS_PENDING_SIGNATURES

  return (
    <div>
      <BasicCheckbox
        id={id}
        labelClassName={'mb-[12px]'}
        onChange={(e) => {
          if (e.currentTarget.checked) {
            setTasksToDelete(new Set([...tasksToDelete, task]))
          } else {
            setTasksToDelete(
              new Set([...tasksToDelete].filter((t) => t.id !== task.id))
            )
          }
        }}
      >
        <CheckboxText task={task} />
      </BasicCheckbox>
      {shouldShowWarning && (
        <Warning className={'mb-[12px]'}>
          Closing this task will cancel any pending signature requests.{' '}
          <span className={'font-bold'}> Are you sure?</span>
        </Warning>
      )}
    </div>
  )
}

function CheckboxText({ task }: { task: Task }) {
  const taskType = task.taskTemplateInfo?.taskType
  if (taskType === undefined) {
    return 'Unknown'
  }

  const isShared = task.shared || false
  const title = taskTitle(task)
  const subTitle = taskSubtitle(task)

  return (
    <>
      <span>{title}</span>
      {subTitle && (
        <span
          className={'text-[12px] text-secondary'}
          style={{ marginLeft: '4px' }}
        >
          {subTitle}
        </span>
      )}
      {isShared && (
        <span className={'text-[12px] uppercase text-secondary'}>
          {' '}
          · Shared
        </span>
      )}
    </>
  )
}
