import { AmbulatoryStatus_AmbulatoryResult as AmbulatoryStatus } from '@augusthealth/models/com/august/protos/ambulatory_status'
import { AmbulatoryStatusSettings } from '@augusthealth/models/com/august/protos/settings/ambulatory_settings'
import { useContext, useEffect, useState } from 'react'
import {
  getFacilityAmbulatorySettings,
  getFacilityDietSettings,
} from '@shared/api/facility_settings'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import { FacilitySettings } from '@shared/contexts/CurrentFacilityContext'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useMARClockSettings } from '@shared/hooks/useMARClockSettings'
import { Facility } from '@shared/types/facility'
import { getAmbulatoryStatusCssClassOrSvgPath } from '@shared/utils/ambulatoryStatus'
import { Loading } from '@shared/utils/loading'

export default function useFacilitySettings({
  facility,
}: {
  facility: Loading<Facility> | undefined
}): FacilitySettings {
  const { setError } = useContext(GlobalContext)
  const [dietOptions, setDietOptions] = useState<OptionTypeBase[]>([])
  const [ambulatoryOptions, setAmbulatoryOptions] = useState<
    OptionTypeBase<AmbulatoryStatus>[]
  >([])

  const { use24HourClock, refreshMarSettings } = useMARClockSettings({
    orgId: facility?.tag === 'Complete' ? facility.value.orgId : undefined,
    facilityId: facility?.tag === 'Complete' ? facility.value.id : undefined,
  })

  const refreshDietSettings = async (orgId: string, facilityId: string) => {
    try {
      const orgFacilitySettings = await getFacilityDietSettings(
        orgId,
        facilityId
      )
      const dietType =
        orgFacilitySettings.settings?.dietSettings?.dietType || []
      setDietOptions(dietType.map((d) => ({ label: d, value: d })))
    } catch (e) {
      setError(e)
    }
  }

  const refreshAmbulatorySettings = async (
    orgId: string,
    facilityId: string
  ) => {
    try {
      const orgFacilitySettings = await getFacilityAmbulatorySettings(
        orgId,
        facilityId
      )
      const statusSettings =
        orgFacilitySettings.settings?.ambulatorySettings
          ?.ambulatoryStatusSettings || []
      setAmbulatoryOptions(statusSettings.map(ambulatoryStatusToOption))
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    if (facility?.tag === 'Complete') {
      void refreshDietSettings(facility.value.orgId, facility.value.id)
      void refreshAmbulatorySettings(facility.value.orgId, facility.value.id)
      void refreshMarSettings(facility.value.orgId, facility.value.id)
    } else {
      setDietOptions([])
      setAmbulatoryOptions([])
    }
  }, [facility])

  return {
    dietSettingsOptions: dietOptions,
    ambulatorySettingsOptions: ambulatoryOptions,
    use24HourClock,
  }
}

function ambulatoryStatusToOption(s: AmbulatoryStatusSettings) {
  const { ambulatoryStatus, title, description } = s
  const icon = getAmbulatoryStatusCssClassOrSvgPath(ambulatoryStatus)
  const isSvg = icon.endsWith('svg')

  return {
    label: title || 'Untitled',
    subLabel: description,
    value: ambulatoryStatus || AmbulatoryStatus.AMBULATORY_RESULT_UNSPECIFIED,
    iconClass: isSvg ? undefined : icon,
    svgPath: isSvg ? icon : undefined,
  }
}
