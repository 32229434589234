import { Time } from '@augusthealth/models/com/august/protos/date'
import { isEqual } from 'lodash'
import { RoutineSchedule } from '@shared/types/routine'
import { RoutineOrder } from '@shared/types/routine_order'
import { Shift, toShiftOption } from '@shared/types/shift'
import { TimeAndEventTiming, Timing, UnitOfTime } from '@shared/types/timing'
import { DeepNull } from '@shared/types/utilities'
import notEmpty from '@shared/utils/notEmpty'
import { validNumberOrNull } from '@shared/utils/parsing'
import {
  CategoryOption,
  RoutineOrderFormData,
  RoutineOrderFormSchedule,
  RoutineOrderFormTiming,
  RoutineOrderPeriodChoice,
  toTimeOption,
} from './RoutineOrderForm'

function isTime(time: any): time is Time {
  return !!(time && typeof time === 'object' && time.minute !== undefined)
}

function getTimeOfDay(
  schedule: RoutineOrderFormSchedule
): TimeAndEventTiming[] | null {
  return (
    schedule.timeOfDay
      ?.map((timingOption) => {
        if (!timingOption) {
          return null
        } else if (isTime(timingOption.value)) {
          return timingOption.value
            ? {
                time: timingOption.value,
              }
            : null
        } else {
          return timingOption.value
            ? {
                shiftId: timingOption.value,
              }
            : null
        }
      })
      .filter(notEmpty) ?? null
  )
}

function toFormTime(
  time: TimeAndEventTiming,
  {
    shifts,
    use24HourClock,
  }: {
    shifts: Shift[]
    use24HourClock: boolean
  }
): RoutineOrderFormTiming {
  if (time.time) {
    return toTimeOption(time.time, { use24HourClock })
  } else {
    const shift = shifts.find((shift) => shift.id === time.shiftId)
    if (!shift) {
      return {
        label: 'Shift no longer exists, choose another',
        value: time.shiftId || '',
        isDisabled: false,
      }
    } else {
      return toShiftOption(shift, { use24HourClock })
    }
  }
}

function toFormPeriod({
  period,
  periodUnit,
  dayOfWeek,
  frequency,
  timeOfDay,
  effectivePeriod,
}: Pick<
  Timing,
  'period' | 'periodUnit' | 'dayOfWeek' | 'frequency' | 'timeOfDay'
> & {
  effectivePeriod: RoutineOrder['effectivePeriod']
}): Pick<
  RoutineOrderFormSchedule,
  'period' | 'periodUnit' | 'periodChoice' | 'onThisDate' | 'frequency'
> {
  const timeOfDayCount = timeOfDay?.length || 0

  const singleDatePeriod =
    effectivePeriod &&
    effectivePeriod.startDate &&
    effectivePeriod.endDate &&
    isEqual(effectivePeriod.endDate, effectivePeriod.startDate)

  const everyXDaysTiming =
    !!period &&
    period > 1 &&
    periodUnit === UnitOfTime.UNIT_OF_TIME_DAY &&
    !singleDatePeriod

  const everyXHoursTiming =
    !!period && periodUnit === UnitOfTime.UNIT_OF_TIME_HOUR

  const daysOfWeekTiming =
    periodUnit === UnitOfTime.UNIT_OF_TIME_WEEK ||
    (periodUnit === UnitOfTime.UNIT_OF_TIME_DAY &&
      dayOfWeek &&
      dayOfWeek.length > 0)

  const onThisDateTiming =
    period === 1 &&
    periodUnit === UnitOfTime.UNIT_OF_TIME_DAY &&
    singleDatePeriod

  if (everyXDaysTiming) {
    return {
      period,
      periodUnit,
      periodChoice: RoutineOrderPeriodChoice.Every,
      frequency: frequency!,
    }
  } else if (everyXHoursTiming) {
    return {
      period,
      periodChoice: RoutineOrderPeriodChoice.Every,
      periodUnit: UnitOfTime.UNIT_OF_TIME_HOUR,
      frequency: frequency!,
    }
  } else if (onThisDateTiming) {
    return {
      period,
      periodChoice: RoutineOrderPeriodChoice.OnThisDay,
      periodUnit: UnitOfTime.UNIT_OF_TIME_DAY, // TODO: correct?
      onThisDate: effectivePeriod.startDate,
      frequency: frequency!,
    }
  } else if (daysOfWeekTiming) {
    return {
      period,
      periodChoice: RoutineOrderPeriodChoice.DaysOfWeek,
      // Enforce that DaysOfWeek timing is UNIT_OF_TIME_DAY with a frequency equal to the number of times per day.
      periodUnit: UnitOfTime.UNIT_OF_TIME_DAY,
      frequency: timeOfDayCount,
    }
  } else {
    switch (periodUnit) {
      case UnitOfTime.UNIT_OF_TIME_DAY:
        return {
          period,
          periodUnit,
          periodChoice: RoutineOrderPeriodChoice.Daily,
          frequency: frequency!,
        }
      case UnitOfTime.UNIT_OF_TIME_MONTH:
        return {
          period,
          periodUnit,
          periodChoice: RoutineOrderPeriodChoice.DaysOfMonth,
          frequency: frequency!,
        }
    }
    return {
      period,
      periodUnit,
      periodChoice: RoutineOrderPeriodChoice.Daily,
      frequency: frequency!,
    }
  }
}

export function toRoutineOrderFormSchedule(
  schedule: RoutineSchedule,
  {
    effectivePeriod,
    shifts,
    use24HourClock,
  }: {
    effectivePeriod: RoutineOrder['effectivePeriod']
    shifts: Shift[]
    use24HourClock: boolean
  }
): RoutineOrderFormSchedule {
  const timing = schedule.timing!
  const { period, periodUnit, periodChoice, onThisDate, frequency } =
    toFormPeriod({
      ...timing,
      effectivePeriod,
    })

  return {
    frequency,
    period,
    periodUnit,
    periodChoice,
    onThisDate,
    timeOfDay: timing.timeOfDay?.map((time) =>
      toFormTime(time, { shifts, use24HourClock })
    ),
    dayOfWeek: timing.dayOfWeek,
    dayOfMonth: timing.dayOfMonth,
  }
}

export function toRoutineOrderFormData(
  routineOrder: RoutineOrder,
  {
    categoryOptions,
    shifts,
    use24HourClock,
  }: {
    categoryOptions: CategoryOption[]
    shifts: Shift[]
    use24HourClock: boolean
  }
): RoutineOrderFormData {
  return {
    name: routineOrder.name,
    instructions: routineOrder.instructions,
    category: {
      label:
        categoryOptions.find(
          (option) =>
            option.value.categoryKey === routineOrder.categoryKey &&
            option.value.customKey === routineOrder.customKey
        )?.label || 'Unlabelled category',
      value: {
        categoryKey: routineOrder.categoryKey,
        customKey: routineOrder.customKey,
      },
    },
    schedules: routineOrder.schedules?.map((schedule) =>
      toRoutineOrderFormSchedule(schedule, {
        effectivePeriod: routineOrder.effectivePeriod,
        shifts,
        use24HourClock,
      })
    ),
    effectivePeriod: routineOrder.effectivePeriod,
  }
}

export function toRoutineSchedule(
  schedule: RoutineOrderFormSchedule
): DeepNull<RoutineSchedule> {
  const { frequency, dayOfWeek, dayOfMonth, periodUnit } = schedule
  const period = validNumberOrNull(schedule.period)
  const timeOfDay = getTimeOfDay(schedule)

  return {
    instructions: null,
    timing: {
      frequency,
      period,
      periodUnit,
      duration: null,
      durationUnit: null,
      dayOfWeek: dayOfWeek ?? null,
      dayOfMonth: dayOfMonth ?? null,
      boundsPeriod: null,
      timeOfDay,
    },
  }
}

// Adapted from @app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard
// Instead of calculating with CustomPeriodUnits, we use the separate `periodChoice` field.
export const getNumberOfTimeInputsToShow = ({
  frequencyCount,
  periodChoice,
  periodUnit,
  period,
}: {
  frequencyCount: number
  periodChoice?: keyof typeof RoutineOrderPeriodChoice
  periodUnit?: UnitOfTime
  period?: number
}) => {
  if (
    periodUnit === UnitOfTime.UNIT_OF_TIME_HOUR &&
    periodChoice === RoutineOrderPeriodChoice.Every &&
    period &&
    !isNaN(period) &&
    period > 0 // Prevent division by zero
  ) {
    // calculate inputs to show for 24 hour period, rounding up
    return Math.ceil(24 / period)
  }

  // frequencyCount coming from input can be NaN
  if (isNaN(frequencyCount) || frequencyCount < 0) {
    return 0
  }

  if (periodChoice === undefined && periodUnit === undefined) {
    return frequencyCount
  }

  return periodUnit === UnitOfTime.UNIT_OF_TIME_DAY ||
    periodChoice === RoutineOrderPeriodChoice.Every ||
    periodChoice === RoutineOrderPeriodChoice.OnThisDay
    ? frequencyCount
    : 1
}
