import { useContext, useState } from 'react'
import { download } from '@shared/api/legacy'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import Card from '@shared/components/Card'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { createReport, previewReportCsvUrl } from '@app/api/reports'
import PreviewLightbox from '@app/components/Facilities/Reports/PreviewLightbox'
import HUD from '@app/components/HUD'
import { OrgFacilitySelects } from '@app/pages/Tools/SharedSelects/OrgFacilitySelects'
import { convertReport } from './helpers'
import { ReportFields } from './models'

export default function ReportPreviewer({
  report,
  afterCreate,
}: {
  report: ReportFields
  afterCreate: () => void
}) {
  const { setError } = useContext(GlobalContext)

  const [selectedOrganization, setSelectedOrganization] =
    useState<OptionTypeBase<Organization> | null>(null)
  const [selectedFacility, setSelectedFacility] =
    useState<OptionTypeBase<Facility> | null>(null)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [reportDescription, setReportDescription] = useState<string>('')
  const [showSaveReportNotice, setShowSaveReportNotice] =
    useState<boolean>(false)

  function preview() {
    setShowPreview(true)
  }

  function saveReport() {
    const toSave = convertReport(report)
    toSave.description = reportDescription
    void createReport(toSave)
      .then(() => {
        setShowSaveReportNotice(true)
        setReportDescription('')
        afterCreate()
      })
      .catch(setError)
  }
  const disableSave =
    report.fields.length === 0 || !reportDescription || !selectedFacility

  return (
    <div>
      <label className="form-control-label">
        <div className="form-element-title uppercase">Preview Report</div>
      </label>
      <Card className={'flex-col'}>
        <OrgFacilitySelects
          onOrgSelect={(org: OptionTypeBase<Organization>) =>
            setSelectedOrganization(org)
          }
          onFacilitySelect={(facility: OptionTypeBase<Facility>) =>
            setSelectedFacility(facility)
          }
          selectedOrganization={selectedOrganization}
          selectedFacility={selectedFacility}
        />
        {showPreview && selectedFacility && (
          <PreviewLightbox
            orgId={selectedOrganization!.value.id}
            facility={selectedFacility.value}
            facilityId={selectedFacility.value.id}
            name="Draft Report"
            previewReport={convertReport(report)}
            download={() => {
              const fileUrl = previewReportCsvUrl(
                selectedOrganization!.value.id,
                selectedFacility!.value.id
              )
              download({
                fileUrl,
                fileName: 'report.csv',
                method: 'POST',
                body: JSON.stringify(convertReport(report)),
                contentType: 'application/json',
              }).catch(setError)
            }}
            onClose={() => setShowPreview(false)}
          />
        )}
        <div className="mt-[16px]">
          <BasicTextarea
            placeholder="Report Description (required)"
            value={reportDescription}
            onChange={(e) => setReportDescription(e.currentTarget.value)}
          />
          <div className="mt-[24px] flex">
            <AsyncIconButton
              className={'mr-[8px]'}
              buttonStyle={'primary-fill'}
              onClick={saveReport}
              disabled={disableSave}
            >
              Save Report
            </AsyncIconButton>
            <AsyncIconButton
              buttonStyle={'secondary-fill'}
              onClick={preview}
              disabled={disableSave}
            >
              Preview Report
            </AsyncIconButton>
          </div>
        </div>
      </Card>
      {showSaveReportNotice && (
        <HUD onExpire={() => setShowSaveReportNotice(false)}>
          Report created!
        </HUD>
      )}
    </div>
  )
}
