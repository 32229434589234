import { useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { FieldArrayWithId, useFormContext, useFormState } from 'react-hook-form'
import Card from '@shared/components/Card'
import Icon from '@shared/components/Icon'
import { formatCurrencyForBilling } from '@shared/utils/billing'
import { getFullName } from '@shared/utils/humanName'
import { formatRoomNumber, getProfileSvgPath } from '@shared/utils/person'
import { twx } from '@shared/utils/tailwind'
import { BulkPaymentsForm, isValidPaymentRow } from './helpers'
import { PaymentsForm } from './PersonPaymentsForm'

const ANIMATION_DURATION = 250

export function RecordPaymentCard({
  field,
  index,
}: {
  field: FieldArrayWithId<BulkPaymentsForm, 'residents', 'id'>
  index: number
}) {
  const resident = field.resident
  const [formHeight, setFormHeight] = useState<0 | 'auto'>(0)
  const methods = useFormContext<BulkPaymentsForm>()
  const { errors } = useFormState<BulkPaymentsForm>({
    name: `residents.${index}.payments`,
  })
  const errorsForResident = (errors.residents?.[index]?.payments ??
    []) as unknown[]

  const profileUrl = resident.profilePictureUrl
    ? resident.profilePictureUrl
    : getProfileSvgPath(resident)

  const roomString =
    formatRoomNumber(
      resident.roomDetails?.roomNumber,
      resident.roomDetails?.bedNumber
    ) ?? 'No room'

  const currentPaymentAmount = methods
    .watch(`residents.${index}.payments`)
    .filter(isValidPaymentRow)
    .reduce((acc, p) => acc + (p.amountInCents ? p.amountInCents * 100 : 0), 0)

  return (
    <Card
      className={twx(
        'hover:bg-red flex cursor-pointer flex-col [&:not(:first-child)]:mt-[8px]'
      )}
      onClick={() => {
        if (formHeight === 0) {
          setTimeout(() => {
            methods.setFocus(`residents.${index}.payments.0.checkNumber`)
          }, ANIMATION_DURATION)
        }

        setFormHeight((prev) => (prev === 0 ? 'auto' : 0))
      }}
    >
      <section className="flex items-center justify-between">
        <h2 className="m-0 flex">
          <img
            src={profileUrl}
            className="mr-[16px] h-[40px] w-[40px] rounded"
          />
          <div>
            <h3 className="m-0 text-[14px] font-semibold leading-[20px]">
              {getFullName(resident.name)}
            </h3>
            <p className="m-0 text-[14px] font-medium leading-[20px] text-gray-07">
              <Icon name="bed" className="mr-[8px]" />
              {roomString}
            </p>
          </div>
        </h2>
        <div className="flex min-w-[30%] justify-between text-[14px] font-medium text-gray-07">
          <div>
            Balance due:{' '}
            <span className="text-gray-04">
              {formatCurrencyForBilling(resident.statementBalanceCents)}
            </span>
          </div>
          <div className="ml-[16px]">
            Record payment:{' '}
            <span className="text-gray-04">
              {formatCurrencyForBilling(currentPaymentAmount)}
            </span>
          </div>
        </div>
      </section>
      <AnimateHeight
        duration={ANIMATION_DURATION}
        height={errorsForResident.length ? 'auto' : formHeight}
      >
        <PaymentsForm nestIndex={index} />
      </AnimateHeight>
    </Card>
  )
}
