import { AppraisalSettings_AssessmentReason } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import {
  CreatableStyledSelect,
  OptionTypeBase,
} from '@shared/components/StyledSelect'
import { AssessmentReason as AssessmentReasonType } from '@shared/types/assessment_configuration'
import { assessmentReasonToDisplay } from '@shared/utils/assessmentConfiguration'
import notEmpty from '@shared/utils/notEmpty'
import styles from '../styles.module.css'
import { ASSESSMENT_REASON_OPTIONS } from './helpers'

type Props = {
  reason: AssessmentReasonType
  deleteReason: (tempId: string) => void
  saveReason: (reason: AssessmentReasonType) => void
  currentEnums: AppraisalSettings_AssessmentReason[]
}
export const AssessmentReason = ({
  reason,
  deleteReason,
  saveReason,
  currentEnums,
}: Props) => {
  const [selectedOption, setSelectedOption] =
    useState<OptionTypeBase<AppraisalSettings_AssessmentReason> | null>(
      reason
        ? {
            label: assessmentReasonToDisplay(reason!) ?? '',
            value: reason.assessmentReason!,
          }
        : null
    )

  const options = [
    selectedOption?.value ===
    AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_OTHER
      ? selectedOption
      : null,
    ...ASSESSMENT_REASON_OPTIONS,
  ]
    .filter(notEmpty)
    .map((opt: OptionTypeBase<AppraisalSettings_AssessmentReason>) => ({
      ...opt,
      isDisabled: currentEnums.includes(opt.value),
    }))

  const saveAssessmentReason = (
    selectedValue: OptionTypeBase<AppraisalSettings_AssessmentReason>
  ) => {
    saveReason({
      tempId: reason.tempId,
      assessmentReason: selectedValue.value!,
      otherReason:
        selectedValue.value ===
        AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_OTHER
          ? selectedValue.label
          : undefined,
    })
  }

  return (
    <div className={'mb-[8px] flex flex-row items-center justify-between'}>
      <CreatableStyledSelect
        id={'assessment-reason'}
        name={'assessment-reason'}
        instanceId={'assessment-reason'}
        isValidNewOption={() => true}
        createOptionPosition={'last'}
        formatCreateLabel={() => (
          <span className={styles.createReason}>
            <i className={'fa fa-plus-circle mr-[8px]'}></i>Custom Reason
          </span>
        )}
        onCreateOption={(val) => {
          if (val?.trim().length > 0) {
            const opt = {
              label: val,
              value: AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_OTHER,
            }
            setSelectedOption(opt)
            saveAssessmentReason(opt)
          }
        }}
        onChange={(val: OptionTypeBase<AppraisalSettings_AssessmentReason>) => {
          if (val) {
            setSelectedOption(val)
            saveAssessmentReason(val)
          }
        }}
        value={selectedOption?.value ? selectedOption : null}
        options={options}
        placeholder={'Select reason...'}
        className={'w-full'}
      />
      <ButtonLink
        title={'Delete Item'}
        data-testid={`delete-reason-${reason.tempId}`}
        className={'ml-[8px]'}
        onClick={() => {
          deleteReason(reason.tempId)
        }}
      >
        <i className={`fa fa-fw fa-trash`} />
      </ButtonLink>
    </div>
  )
}
