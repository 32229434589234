import { RoutineStatus } from '@augusthealth/models/com/august/protos/routine'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import Badge from '@shared/components/Badge'
import { BasicSpinner } from '@shared/components/BasicSpinner'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import GlobalContext from '@shared/contexts/GlobalContext'
import { carePathForPerson } from '@shared/legacy_routes'
import { getOrElse, mapAsyncResult } from '@shared/utils/loading'
import {
  discontinueRoutineOrder,
  updateRoutineOrder,
} from '@app/api/routineOrders'
import ConfirmModal from '@app/components/ConfirmModal'
import PersonContext from '@app/contexts/PersonContext'
import useAppraisalSettingsCategories from '@app/hooks/useAppraisalSettingsCategories'
import useRoutineOrder from '@app/hooks/useRoutineOrder'
import AssessmentRoutineOrderForm from './AssessmentRoutineOrderForm'
import { toRoutineOrderFormData, toRoutineSchedule } from './helpers'
import RoutineOrderForm, {
  RoutineOrderFormData,
  toCategoryOptions,
} from './RoutineOrderForm'

export default function EditCustomRoutineWrapper() {
  const { setError } = useContext(GlobalContext)
  const { person } = useContext(PersonContext)
  const {
    shifts,
    settings: { use24HourClock },
  } = useContext(CurrentFacilityContext)
  const {
    orgId,
    facilityId,
    id: personId,
    routineId,
  } = useParams<{
    orgId: string
    facilityId: string
    id: string
    routineId: string
  }>()
  const { categories } = useAppraisalSettingsCategories({
    person,
  })
  const categoryOptions = mapAsyncResult(categories, toCategoryOptions)
  const { routineOrder } = useRoutineOrder({
    orgId,
    facilityId,
    personId,
    routineId,
    setError,
  })
  const history = useHistory()
  const formDataLoaded =
    routineOrder.tag === 'Complete' &&
    categoryOptions.tag === 'Complete' &&
    shifts.tag === 'Complete'
  const formData = formDataLoaded
    ? toRoutineOrderFormData(routineOrder.value, {
        categoryOptions: categoryOptions.value,
        shifts: shifts.value || [],
        use24HourClock,
      })
    : undefined
  const methods = useForm<RoutineOrderFormData>({
    mode: 'onSubmit',
    values: formData,
  })
  const [confirmDiscontinue, setConfirmDiscontinue] = useState(false)

  if (person === undefined) {
    return null
  }

  if (categoryOptions.tag === 'Error') {
    setError(categoryOptions.value)

    return (
      <Badge color="darkOrange" className="w-full p-4">
        <i className="fas fa-info-circle mr-2" />
        Error: Could not load assessment categories for routines!
      </Badge>
    )
  }

  const onSubmit = async (formData: RoutineOrderFormData) => {
    if (
      person === undefined ||
      routineOrder.tag === 'Loading' ||
      routineOrder.tag === 'Error'
    ) {
      return
    }

    const { name, instructions, effectivePeriod } = formData
    const response = await updateRoutineOrder(person, {
      ...routineOrder.value,
      name,
      instructions,
      effectivePeriod,
      status: RoutineStatus.ROUTINE_STATUS_ACTIVE,
      categoryKey: formData.category.value.categoryKey,
      customKey: formData.category.value.customKey,
      schedules: formData.schedules.map(toRoutineSchedule),
    })

    if (response.tag === 'Error') {
      setError(response.value)
      methods.reset(formData, { keepDirtyValues: true, keepTouched: true })
    } else {
      history.push(carePathForPerson(person))
    }
  }

  const onConfirmDiscontinue = async () => {
    if (
      person === undefined ||
      routineOrder.tag === 'Loading' ||
      routineOrder.tag === 'Error'
    ) {
      return
    }

    const response = await discontinueRoutineOrder(
      person,
      routineOrder.value.id
    )

    if (response.tag === 'Complete') {
      history.push(carePathForPerson(person))
    } else {
      setError(response.value)
    }
  }

  const onDiscontinue =
    routineOrder.tag === 'Complete' &&
    routineOrder.value.status !== RoutineStatus.ROUTINE_STATUS_DISCONTINUED
      ? () => setConfirmDiscontinue(true)
      : undefined

  const assessmentRoutineData =
    routineOrder.tag === 'Complete' && routineOrder.value.routineType.assessment
      ? { categoryTasks: routineOrder.value.routineType.assessment.tasks || [] }
      : undefined

  return (
    <>
      {confirmDiscontinue && (
        <ConfirmModal
          testId="discontinue-routine-order-modal"
          title="Discontinue this routine?"
          confirmButtonConfig={{
            children: 'Discontinue',
            onClick: async () => {
              await onConfirmDiscontinue()
            },
          }}
          denyButtonConfig={{
            onClick: () => {
              setConfirmDiscontinue(false)
            },
          }}
        />
      )}
      <div className="mb-28 ml-auto mr-auto mt-8 h-fit w-[720px]">
        {!formDataLoaded && (
          <div className="flex items-center gap-2">
            <BasicSpinner className="fa-fw text-secondary" />
            <span>Loading routine data</span>
          </div>
        )}
        {formDataLoaded && assessmentRoutineData ? (
          <AssessmentRoutineOrderForm
            title="Edit Routine"
            disabled={!formDataLoaded}
            history={history}
            methods={methods}
            onSubmit={onSubmit}
            assessmentData={assessmentRoutineData}
            routineOrderGroupId={routineOrder.value.routineOrderGroupId}
            personIds={person}
          />
        ) : (
          <RoutineOrderForm
            title="Edit Routine"
            disabled={!formDataLoaded}
            history={history}
            methods={methods}
            onSubmit={onSubmit}
            onDiscontinue={onDiscontinue}
            categoryOptions={getOrElse(categoryOptions, [])}
            person={person}
          />
        )}
      </div>
    </>
  )
}
