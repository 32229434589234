import { PageGenerator } from '@augusthealth/august-frontend-form-elements'
import {
  DisplayPage,
  FormDisplay,
  SignableForm,
  SignableFormMetaData,
} from '@augusthealth/models/com/august/protos/signable_form'
import { useContext, useEffect, useState } from 'react'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import LoadingPopup from '@shared/components/LoadingPopup'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import { Loading } from '@shared/utils/loading'
import notEmpty from '@shared/utils/notEmpty'
import { twx } from '@shared/utils/tailwind'
import { getForm } from '@app/api/form'
import HUD from '@app/components/HUD'
import { convertToPageGeneratorItem } from '@app/components/Prospects/Forms/CustomForm/helpers'
import { DEFAULT_PAGE_GENERATOR_PROPS } from '@app/components/Prospects/Forms/FormLayout'
import PageForm from '@app/components/Prospects/Forms/FormLayout/PageForm'
import PersonContext from '@app/contexts/PersonContext'
import { OrgFacilityPersonSelects } from '@app/pages/Tools/SharedSelects/OrgFacilityPersonSelects'
import styles from './styles.module.css'

type CustomFormPreviewProps = {
  pdfTemplate: SignableFormMetaData
  title: string
  pages?: DisplayPage[]
  dataType: DataType
  customType?: string
  closePreview: () => void
}

export default function CustomFormPreview(props: CustomFormPreviewProps) {
  const [selectedOrganization, setSelectedOrganization] =
    useState<OptionTypeBase<Organization> | null>(null)
  const [selectedFacility, setSelectedFacility] =
    useState<OptionTypeBase<Facility> | null>(null)
  const [selectedPerson, setSelectedPerson] =
    useState<OptionTypeBase<Person> | null>(null)

  const [fullForm, setFullForm] = useState<Loading<SignableForm>>({
    tag: 'Loading',
  })
  const [person, setPerson] = useState<Person>()

  const [showReview, setShowReview] = useState(false)
  const [formPageIndex, setFormPageIndex] = useState(0)
  const { user } = useUserContext()
  const { setError } = useContext(GlobalContext)
  const { pdfTemplate, title, pages, dataType, customType, closePreview } =
    props

  useEffect(() => {
    setPerson(selectedPerson ? selectedPerson.value : undefined)
  }, [selectedOrganization, selectedFacility, selectedPerson])

  useEffect(() => {
    getForm(pdfTemplate.id!)
      .then((form) => {
        setFullForm({ tag: 'Complete', value: form })
      })
      .catch(setError)
  }, [pdfTemplate.id])

  if (fullForm.tag === 'Loading') {
    return <LoadingPopup loading />
  } else {
    const freeTextFields = (fullForm.value.template?.preFilledFields || [])
      .map((f) => f.customField)
      .filter(notEmpty)
    const configuration = convertToPageGeneratorItem(
      { pages } as FormDisplay,
      freeTextFields,
      dataType,
      customType,
      user
    )

    const onProgressBarClick = (pageIndex: number) => {
      if (pageIndex === configuration.length - 1) {
        setShowReview(true)
      } else {
        setFormPageIndex(pageIndex)
      }
    }

    const convertedConfiguration = configuration.map((c) => {
      const newConf = { ...c }
      const { json } = newConf
      if (newConf.json) {
        newConf.content = (
          <PageForm
            {...newConf}
            configuration={json}
            match={{
              params: {
                id: person?.id ?? '1',
                facilityId: person?.facilityId ?? '2',
                orgId: person?.orgId ?? '3',
                taskId: '4',
              },
              url: '',
            }}
            defaultShowRequiredFieldAlert={false}
            returnUpdatedAttributeOnly={false}
          />
        )
      }

      return newConf
    })
    return (
      <PersonContext.Provider
        value={{
          person: person?.facilityId ? person : undefined,
          setPerson: setPerson as (person: Person) => void,
          tasks: undefined,
          setTasks: () => null,
          profileImageURL: '',
          setProfileImageURL: () => null,
          isPersonLoading: false,
          setIsPersonLoading: () => null,
          signInPersonPopupTask: undefined,
          setSignInPersonPopupTask: () => null,
        }}
      >
        <Modal
          id={'custom-form-preview'}
          open={true}
          contentClassName={twx('w-[80vw] h-[80vh]')}
          onClose={closePreview}
        >
          <div className={styles.previewHeader}>
            <div className={styles.selectsContainer}>
              <OrgFacilityPersonSelects
                onOrgSelect={(e) => {
                  setSelectedOrganization(e)
                  setSelectedFacility(null)
                  setSelectedPerson(null)
                }}
                onFacilitySelect={(e) => {
                  setSelectedFacility(e)
                  setSelectedPerson(null)
                }}
                onPersonSelect={(e) => {
                  setSelectedPerson(e)
                }}
                prepopulateWithDefaults={true}
                selectedOrganization={selectedOrganization}
                selectedFacility={selectedFacility}
                selectedPerson={selectedPerson}
              />
            </div>
          </div>
          {person && person.id && (
            <PageGenerator
              {...DEFAULT_PAGE_GENERATOR_PROPS}
              title={`${title} (Preview)`}
              pageIndex={formPageIndex}
              configuration={convertedConfiguration}
              onProgressBarClick={onProgressBarClick}
              hideButtonFooter
            />
          )}
        </Modal>
        {showReview && (
          <HUD milliseconds={1000} onExpire={() => setShowReview(false)}>
            Review Form Flow will show here
          </HUD>
        )}
      </PersonContext.Provider>
    )
  }
}
