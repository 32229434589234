import styles from './styles.module.css'
import { BarChartData } from '../helpers'
import { STROKE_COLORS } from '../theme'
import Label from './Label'

export default function OverviewLabels({
  data,
  title = 'Current',
  barColor = STROKE_COLORS,
  counterIsPercentage = false,
  hideCounter = false,
  reversePercentageStyle = false,
}: {
  data: BarChartData[][]
  title?: string | string[]
  barColor?: string | string[]
  counterIsPercentage?: boolean // add 2 digit after decimal + % sign
  hideCounter?: boolean
  reversePercentageStyle?: boolean
}) {
  return (
    <div className={styles.outerHolder}>
      {data.map((d, i) => (
        <Label
          key={`overview-label-${i}`}
          data={d}
          title={Array.isArray(title) ? title[i] : title}
          barColor={Array.isArray(barColor) ? barColor[i] : barColor}
          index={i}
          counterIsPercentage={counterIsPercentage}
          hideCounter={hideCounter}
          reversePercentageStyle={reversePercentageStyle}
        />
      ))}
    </div>
  )
}
