import { useFormContext } from 'react-hook-form'
import { formatDateMessage, formatTime } from '@shared/utils/date'
import { twx } from '@shared/utils/tailwind'
import { MedOrderFormData } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'
import { ReviewOrderBadge } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderBadges'

export default function HoldBadges({
  use24HourTime,
}: {
  use24HourTime: boolean
}) {
  const { watch } = useFormContext<MedOrderFormData>()

  const holdStartDate = watch('onHold.start.date')
  const holdEndDate = watch('onHold.end.date')
  const holdStartTime = watch('onHold.start.time')
  const holdEndTime = watch('onHold.end.time')

  return (
    <div className={'block'} data-testid="hold-badges">
      {(holdStartTime || holdStartDate) && (
        <div className={twx('mb-[8px] mt-[16px] flex items-center')}>
          <span
            className={twx(
              'mr-[8px] text-[14px] font-medium leading-[16px] text-secondary-07'
            )}
          >
            Start
          </span>
          {holdStartDate && (
            <ReviewOrderBadge>
              {formatDateMessage(holdStartDate)}
            </ReviewOrderBadge>
          )}
          <ReviewOrderBadge className={twx('ml-[8px]')}>
            {holdStartTime &&
              formatTime(holdStartTime.value, {
                use24HourClock: use24HourTime,
              })}
          </ReviewOrderBadge>
        </div>
      )}
      {(holdEndTime || holdEndDate) && (
        <div className={'flex items-center'}>
          <span
            className={
              'mr-[8px] text-[14px] font-medium leading-[16px] text-secondary-07'
            }
          >
            End
          </span>
          {holdEndDate && (
            <ReviewOrderBadge>
              {formatDateMessage(holdEndDate)}
            </ReviewOrderBadge>
          )}
          <ReviewOrderBadge className={twx('ml-[8px]')}>
            {holdEndTime &&
              formatTime(holdEndTime.value, { use24HourClock: use24HourTime })}
          </ReviewOrderBadge>
        </div>
      )}
    </div>
  )
}
