import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { MedicationOrder } from '@shared/types/medication_order'
import { Loading } from '@shared/utils/loading'
import { isExternal } from '@shared/utils/medicationStatement'
import {
  getMedicationOrders,
  getSingleMedicationOrder,
} from '../api/medicationOrders'
import PersonContext from '../contexts/PersonContext'

export default function useMedications(): {
  medications: Loading<MedicationOrder[]>
  externalMedications: Loading<MedicationOrder[]>
  reloadMedications: () => Promise<void>
} {
  const { setError } = useContext(GlobalContext)
  const { person } = useContext(PersonContext)

  const [medications, setMedications] = useState<Loading<MedicationOrder[]>>({
    tag: 'Loading',
  })
  const [externalMedications, setExternalMedications] = useState<
    Loading<MedicationOrder[]>
  >({
    tag: 'Loading',
  })

  const reloadMedications = async () => {
    if (person) {
      try {
        const medications = await getMedicationOrders(person)
        const internalMedications = medications.filter(
          (medication) => !isExternal(medication)
        )
        const _externalMedications = medications.filter((medication) =>
          isExternal(medication as MedicationOrder)
        )
        setMedications({ tag: 'Complete', value: internalMedications })
        setExternalMedications({ tag: 'Complete', value: _externalMedications })
      } catch (e) {
        setError(e)
      }
    }
  }

  useEffect(() => {
    setMedications({ tag: 'Loading' })
    void reloadMedications()
  }, [person?.id])

  return { medications, externalMedications, reloadMedications }
}

export function useMedicationOrder(medicationId: string): {
  medicationOrder: Loading<MedicationOrder | null>
  reloadMedicationOrder: () => Promise<void>
} {
  const { setError } = useContext(GlobalContext)
  const { person } = useContext(PersonContext)

  const [medicationOrder, setMedicationOrder] = useState<
    Loading<MedicationOrder | null>
  >({
    tag: 'Loading',
  })

  const reloadMedicationOrder = async () => {
    if (person) {
      try {
        const medicationOrder = await getSingleMedicationOrder(
          person,
          medicationId
        )
        setMedicationOrder({
          tag: 'Complete',
          value: medicationOrder as MedicationOrder,
        })
      } catch (e) {
        setError(e)
        setMedicationOrder({
          tag: 'Complete',
          value: null,
        })
      }
    }
  }

  useEffect(() => {
    setMedicationOrder({ tag: 'Loading' })
    void reloadMedicationOrder()
  }, [`${person?.id} ${medicationId}`])

  return { medicationOrder: medicationOrder, reloadMedicationOrder }
}
