import { addDays, parseISO } from 'date-fns'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { endBillingCharge } from '@shared/api/billing'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import GlobalContext from '@shared/contexts/GlobalContext'
import { BillingCharge } from '@shared/types/billing'
import { RequiredPersonIds } from '@shared/types/person'
import { tw } from '@shared/utils/tailwind'
import useBillingItems from '@app/hooks/useBillingItems'
import BillingModal from '@app/pages/Facilities/Billing/Modals/components/BillingModal'
import FeeDropdown from '../components/FeeDropdown'
import StartEndDates from '../components/StartEndDates'
import {
  BillingChargeFormData,
  convertChargeDataToFormData,
  ModalProps,
} from '../helpers'

type Props = Pick<ModalProps, 'doneFn' | 'closeFn'> & {
  personIds: RequiredPersonIds
  charge: BillingCharge
}

export default function EndRecurringChargeModal(props: Props) {
  const { setError } = useContext(GlobalContext)
  const { personIds, charge, doneFn, closeFn } = props
  const { orgId, facilityId, id: personId } = personIds
  const chargeData = charge.data
  const useFormReturn = useForm<BillingChargeFormData>({
    defaultValues: {
      ...convertChargeDataToFormData(chargeData),
      startDate: undefined, // TO DO: Temporary use startDate field as required date to set new end date, to be fixed
    },
  })
  const dayAfterStartDateObj = addDays(parseISO(chargeData.startDate), 1) // New startDate must be after current startDate
  const { formState, register, handleSubmit } = useFormReturn
  const { billingItemsWithParentCategories } = useBillingItems({
    facilityId,
    orgId,
  })
  const disabled = billingItemsWithParentCategories.tag === 'Loading'

  async function onSubmit(formData: BillingChargeFormData) {
    try {
      await endBillingCharge({
        personIds: { orgId, facilityId, id: personId },
        chargeId: charge.meta.id,
        endDate: formData.startDate, // temporary using startDate as a required date to set endDate
      })

      await doneFn()
      closeFn()
    } catch (e) {
      setError(e)
    }
  }

  return (
    <BillingModal
      id="end-recurring-charge-modal"
      title="End Recurring Charge"
      onClose={closeFn}
    >
      <form className={tw`mt-[32px]`} onSubmit={handleSubmit(onSubmit)}>
        <FeeDropdown
          billingItemsWithParentCategories={billingItemsWithParentCategories}
          useFormReturn={useFormReturn}
          disabled
        />
        <div className={tw`mt-[24px]`}>
          <StartEndDates
            startDateLabel="Effective Date"
            startDatePickerProps={{
              minDate: dayAfterStartDateObj,
            }}
            useFormReturn={useFormReturn}
          />
        </div>
        <div className={tw`mt-[24px]`}>
          <LabelAboveInput uppercase={false} htmlFor="note">
            Note
          </LabelAboveInput>
          <BasicInput
            {...register('note')}
            disabled={disabled}
            placeholder="Optional note for internal use only..."
          />
        </div>
        <div className={tw`mt-[32px]`}>
          <AnimatedPopupFormFooter
            yesBtn={{ props: { disabled } }}
            noBtn={{ action: closeFn }}
            formState={formState}
          />
        </div>
      </form>
    </BillingModal>
  )
}
