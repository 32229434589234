import { useState } from 'react'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import StyledSelect from '@shared/components/StyledSelect'
import { CategoryGroup, Field, FieldOption } from './models'
import { FieldOptionComponent, MultiValueLabel } from './SelectComponents'

export default function FieldPicker({
  fieldOptions,
  field,
  onChange,
}: {
  fieldOptions: CategoryGroup[]
  field: Field
  onChange: (GroupOption) => void
}) {
  const [columnName, setColumnName] = useState('Column Name')
  return (
    <div className="mb-[16px] flex">
      <div className="mr-[8px]">
        <BasicInput
          placeholder={columnName}
          value={field.columnTitle}
          onChange={(e) =>
            onChange({ ...field, columnTitle: e.currentTarget.value })
          }
        />
      </div>
      <div style={{ flexGrow: 1 }}>
        <StyledSelect
          isClearable
          isMulti
          placeholder="Select a field..."
          components={{
            Option: FieldOptionComponent,
            MultiValueLabel,
          }}
          options={fieldOptions}
          onChange={(e: FieldOption[]) => {
            let columnName = ''
            e.forEach((fo, index) => {
              if (index === e.length - 1) {
                columnName += ` ${fo.label.displayName}`
              } else {
                columnName += ` ${fo.label.displayName},`
              }
            })
            setColumnName(columnName)
            onChange({ ...field, fieldOption: e })
          }}
        />
      </div>
    </div>
  )
}
