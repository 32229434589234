import { CustomFacilityField } from '@augusthealth/models/com/august/protos/facility'
import { FormField_FacilityCustomField } from '@augusthealth/models/com/august/protos/signable_form'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { getOrElse } from '@shared/utils/loading'
import { getFacilityFieldsFromTemplate } from '@shared/utils/signableForm'
import { useFacilities } from '@app/hooks/useFacilities'
import { useTemplateById } from '@app/hooks/useTemplate'
import { KeyType, makeKey } from './helpers'

export default function useFacilityFieldsData() {
  const { templateId } = useParams<{ templateId: string }>()
  const template = useTemplateById({ templateId })
  const [organization, setOrganization] =
    useState<OptionTypeBase<Organization> | null>(null)
  const [fieldValues, setFieldValues] = useState<
    Map<KeyType, CustomFacilityField>
  >(new Map())
  const { facilities, refreshFacilities } = useFacilities({
    orgId: organization?.value.id,
  })

  const facilityFields = getFacilityFieldsFromTemplate({
    template: getOrElse(template, {}),
  })

  // Set the default values once the template and facilities are loaded
  useEffect(() => {
    if (facilities.tag === 'Complete' && template.tag === 'Complete') {
      setFieldValues(
        buildValuesMap({
          facilities: facilities.value,
          facilityFields,
        })
      )
    }
  }, [template.tag, facilities.tag])

  // Clear the values when the organization is cleared
  useEffect(() => {
    if (organization === null) {
      setFieldValues(new Map())
    }
  }, [organization])

  const backendValues = buildValuesMap({
    facilities: facilities.tag === 'Complete' ? facilities.value : [],
    facilityFields,
  })
  const noChangesDetected = isEqual(fieldValues, backendValues)

  const changedValues: Map<KeyType, CustomFacilityField> = new Map()
  fieldValues.forEach((value, key) => {
    if (!isEqual(backendValues.get(key), value)) {
      changedValues.set(key, value)
    }
  })

  return {
    organization,
    setOrganization,
    template,
    facilities,
    noChangesDetected,
    facilityFields,
    changedValues,
    fieldValues,
    setFieldValues,
    refreshFacilities,
  }
}

function buildValuesMap({
  facilities,
  facilityFields,
}: {
  facilities: Facility[]
  facilityFields: FormField_FacilityCustomField[]
}) {
  const newFieldValues = new Map<KeyType, CustomFacilityField>()

  facilities.forEach((facility) => {
    const customFields = facility.customFields ?? {}

    facilityFields.forEach((field) => {
      const value = customFields[field.key!]

      if (value && value.image) {
        newFieldValues.set(makeKey({ facility, field }), value)
      } else {
        const existingValue = customFields[field.key!]?.text ?? ''
        newFieldValues.set(makeKey({ facility, field }), {
          text: existingValue,
        })
      }
    })
  })

  return newFieldValues
}
