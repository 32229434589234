import { intersection, isMatchWith } from 'lodash'
import { PropsWithChildren } from 'react'
import { useUserContext } from '@shared/contexts/UserContext'
import { FacilityIds } from '@shared/types/facility'
import { Group, GroupPermission, GroupType } from '@shared/types/permission'
import { Person, PersonIds } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { hasPermissions } from '@shared/utils/permisson'
import { isSuperUser } from '@shared/utils/user'

interface WithGateProps {
  children: React.ReactNode
  authorizer: (user: UserAccount) => boolean
}

function WithGate(props: WithGateProps) {
  const { authorizer, children } = props

  const { user } = useUserContext()
  const hasPermission = authorizer(user)

  if (hasPermission) {
    return children
  }

  return null
}

export function PersonPermissionGate({
  person,
  permissions,
  children,
  excludedRoles = [],
}: PropsWithChildren<{
  person: Person
  permissions: GroupPermission[]
  excludedRoles?: GroupType[]
}>) {
  const authorizer = (user: UserAccount) =>
    hasPermissionForPerson({
      user,
      person,
      permissions,
      excludedRoles,
    })

  return WithGate({
    children,
    authorizer,
  })
}

export function FacilityPermissionGate({
  facility,
  permissions,
  children,
}: PropsWithChildren<{
  facility?: FacilityIds
  permissions: GroupPermission[]
}>) {
  const authorizer = (user: UserAccount) =>
    Boolean(
      facility &&
        hasPermissionForFacility({
          user,
          facility,
          permissions,
        })
    )

  return WithGate({
    children,
    authorizer,
  })
}

export function ToolPermissionGate({
  permissions,
  children,
}: PropsWithChildren<{
  permissions: GroupPermission[]
}>) {
  const authorizer = (user: UserAccount) =>
    hasPermissions({
      user,
      permissions,
    })

  return WithGate({
    children,
    authorizer,
  })
}

export function SuperUserOnlyGate({ children }: PropsWithChildren) {
  return WithGate({
    children,
    authorizer: isSuperUser,
  })
}

function hasAccessToPerson({
  group,
  person,
}: {
  group: Group
  person: PersonIds
}) {
  return isMatchWith(
    {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      organizationId: person.orgId!,
      facilityId: person.facilityId!,
      personId: person.id!,
      /* eslint-enable @typescript-eslint/no-non-null-assertion */
    },
    {
      organizationId: undefined,
      facilityId: undefined,
      personId: undefined,
      ...group.personMatcher,
    },
    (personValue, matcherValue) =>
      personValue === matcherValue || matcherValue === undefined
  )
}

function hasAccessToFacility({
  group,
  facility,
}: {
  group: Group
  facility: FacilityIds
}) {
  return isMatchWith(
    {
      organizationId: facility.orgId,
      facilityId: facility.id,
      personId: undefined,
    },
    {
      organizationId: undefined,
      facilityId: undefined,
      personId: undefined,
      ...group.personMatcher,
    },
    (personValue, matcherValue) =>
      personValue === matcherValue || matcherValue === undefined
  )
}

export function useHasPermissionForPerson({
  person,
  permissions,
}: {
  permissions: GroupPermission[]
  person: Person
}) {
  const { user } = useUserContext()
  return hasPermissionForPerson({ user, person: person, permissions })
}

export function hasPermissionForPerson({
  user,
  person,
  permissions,
  excludedRoles = [],
}: {
  permissions: GroupPermission[]
  user: UserAccount
  person: PersonIds
  excludedRoles?: GroupType[]
}): boolean {
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  const relevantGroups = user.groups!.filter(
    (g) =>
      intersection(g.groupPermissions, permissions).length ===
      permissions.length
  )

  return relevantGroups.some(
    (g) =>
      hasAccessToPerson({ group: g, person }) &&
      !excludedRoles.includes(g.groupType!)
  )
  /* eslint-enable @typescript-eslint/no-non-null-assertion */
}

export function hasPermissionForFacility({
  user,
  facility,
  permissions,
}: {
  permissions: GroupPermission[]
  user: UserAccount
  facility: FacilityIds
}): boolean {
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  const relevantGroups = user.groups!.filter(
    (g) =>
      intersection(g.groupPermissions, permissions).length ===
      permissions.length
  )

  return relevantGroups.some((g) => hasAccessToFacility({ group: g, facility }))
  /* eslint-enable @typescript-eslint/no-non-null-assertion */
}

export function hasPermissionForOrg({
  user,
  orgId,
  permissions,
}: {
  permissions: GroupPermission[]
  user: UserAccount
  orgId: string
}): boolean {
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  const relevantGroups = user.groups!.filter(
    (g) =>
      intersection(g.groupPermissions, permissions).length ===
      permissions.length
  )

  return relevantGroups.some(
    (g) =>
      g.personMatcher?.organizationId === undefined ||
      g.personMatcher.organizationId === orgId
  )
  /* eslint-enable @typescript-eslint/no-non-null-assertion */
}

export function useHasAtLeastOnePermission({
  person,
  permissions,
}: {
  person: Person
  permissions: GroupPermission[]
}) {
  const { user } = useUserContext()

  return permissions.some((permission) =>
    hasPermissionForPerson({ user, person, permissions: [permission] })
  )
}
