import { TaskTemplateInfo } from '@augusthealth/models/com/august/protos/task'
import ButtonLink from '@shared/components/ButtonLink'
import {
  Incident,
  IncidentAction,
  IncidentActionType,
} from '@shared/types/incidents'
import { DataType } from '@shared/types/snapshot'
import { firstLastName } from '@shared/utils/contact'
import {
  followUpActions as allActions,
  customIncidentActionIdentifier,
  isInsuranceIncidentReportAction,
  isResidentIncidentReportAction,
  isServicePlanAction,
} from '@shared/utils/incident'
import { getPrimaryPhysician, getResponsiblePerson } from '@shared/utils/person'
import ContactLink from '@app/components/ContactModalLink'
import { canEditIncidentActions } from '@app/pages/Notes/helpers'
import { useIncidentCardContext } from '@app/pages/Notes/IncidentCard/IncidentCardContext'
import styles from '@app/pages/Notes/styles.module.css'
import { ActionButton } from './ActionButton'
import { DownloadCustomActionPDF, ServicePlanLink } from './ActionLinks'
import NotifyStateLink from './NotifyStateLink'

export default function FollowUpActions({
  onUpdate,
  creatableTasks,
  onClickEdit,
}: {
  onUpdate: (newIncident: Incident) => Promise<void>
  creatableTasks: TaskTemplateInfo[]
  onClickEdit: () => void
}) {
  const { user, facility, person, incident } = useIncidentCardContext()
  const followUpActions =
    incident?.detail?.incidentDetail?.incidentActions ?? []

  const facilityHasServicePlan = creatableTasks.some(
    (t) => t.dataType === DataType.DATA_TYPE_SERVICE_PLAN
  )
  const rp = getResponsiblePerson(person)
  const gp = getPrimaryPhysician(person)

  return (
    <>
      <div className="w-[144px] text-[12px] font-semibold uppercase leading-[16px] text-gray-06">
        Actions
        {canEditIncidentActions({ user, person }) && (
          <ButtonLink
            data-testid="edit-follow-up-actions"
            className="ml-[8px] text-[12px] font-medium leading-[16px]"
            onClick={onClickEdit}
          >
            Edit
          </ButtonLink>
        )}
      </div>
      <div className="mt-[12px] flex grow flex-col pr-[16px]">
        {followUpActions.length === 0 && (
          <div className={styles.followUpActionRow}>
            <span className="text-gray-07">None yet</span>
          </div>
        )}
        {followUpActions.map((fua, index) => {
          const { infoUrl, isProjectable } =
            facility.stateIncidentReportInfo || {}
          const showNotifyStateLink =
            fua.type ===
              IncidentActionType.INCIDENT_ACTION_TYPE_NOTIFY_CA_LIC_624 &&
            !!(infoUrl || isProjectable)

          if (
            fua.type ===
              IncidentActionType.INCIDENT_ACTION_TYPE_NOTIFY_CA_LIC_624 &&
            showNotifyStateLink === false
          ) {
            return null
          }

          return (
            <div key={`fua-${index}`} className={styles.followUpActionRow}>
              <div>
                <FollowUpActionLabel fua={fua} />
                {fua.type ===
                  IncidentActionType.INCIDENT_ACTION_TYPE_NOTIFY_RP &&
                  rp && (
                    <ContactLink
                      person={person}
                      contact={rp}
                      linkContent={<>{firstLastName(rp)}</>}
                    />
                  )}
                {fua.type ===
                  IncidentActionType.INCIDENT_ACTION_TYPE_NOTIFY_GP &&
                  gp && (
                    <ContactLink
                      person={person}
                      contact={gp}
                      linkContent={<>{firstLastName(gp)}</>}
                    />
                  )}
                {showNotifyStateLink && (
                  <NotifyStateLink
                    facility={facility}
                    incident={incident}
                    person={person}
                  />
                )}
                {isServicePlanAction(fua) && facilityHasServicePlan && (
                  <ServicePlanLink person={person} />
                )}
                {isResidentIncidentReportAction(fua) && (
                  <DownloadCustomActionPDF
                    incident={incident}
                    person={person}
                    action={fua}
                  />
                )}
                {isInsuranceIncidentReportAction(fua) && (
                  <DownloadCustomActionPDF
                    incident={incident}
                    person={person}
                    action={fua}
                  />
                )}
              </div>
              <ActionButton
                incident={incident}
                incidentAction={fua}
                user={user}
                person={person}
                onUpdate={onUpdate}
              />
            </div>
          )
        })}
      </div>
    </>
  )
}

function FollowUpActionLabel({ fua }: { fua: IncidentAction }) {
  if (
    isResidentIncidentReportAction(fua) ||
    isInsuranceIncidentReportAction(fua)
  ) {
    return <>{customIncidentActionIdentifier(fua)}: </>
  }

  const label = allActions.find((a) => a.value === fua.type)?.name
  const otherText = fua.otherType

  const needsColon =
    fua.type &&
    [
      IncidentActionType.INCIDENT_ACTION_TYPE_UPDATE_SERVICE_PLAN,
      IncidentActionType.INCIDENT_ACTION_TYPE_NOTIFY_CA_LIC_624,
      IncidentActionType.INCIDENT_ACTION_TYPE_NOTIFY_RP,
      IncidentActionType.INCIDENT_ACTION_TYPE_NOTIFY_GP,
    ].includes(fua.type)

  return (
    <>
      {label || otherText}
      {needsColon ? ': ' : ''}
    </>
  )
}
