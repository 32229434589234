import { DosageV2 } from '@augusthealth/models/com/august/protos/dosage'
import { DosageType } from '@augusthealth/models/com/august/protos/medication_statement'
import { useFormContext } from 'react-hook-form'
import TimingBadges from '@shared/components/TimingBadges'
import { MedicationOrder } from '@shared/types/medication_order'
import {
  getDosageInstructions,
  getSlidingScaleEntriesFromDose,
} from '@shared/utils/medicationOrder'
import { tw } from '@shared/utils/tailwind'
import styles from './styles.module.css'
import { mapFormMedToMedOrder, MedOrderFormData } from '../helpers'
import {
  DurationBadge,
  PeriodAndFrequencyBadge,
  PRNBadges,
  QuantityAndDosageBadge,
  SlidingScaleBadges,
  VitalBadge,
} from '../ReviewOrderBadges'

type Props = {
  order: Partial<MedicationOrder>
  doseType: DosageType
  use24HourTime: boolean
}
export const ScheduleBadges = ({ order, doseType, use24HourTime }: Props) => {
  const { watch, formState } = useFormContext<MedOrderFormData>()

  const mergedWithFormData = mapFormMedToMedOrder({
    formData: watch(),
    medOrder: order,
  })

  const dosageInstructions = getDosageInstructions(
    mergedWithFormData as MedicationOrder
  )

  return (
    <div className={styles.scheduleBadges} data-testid={'med-badges'}>
      {dosageInstructions.map((dose: DosageV2, index) => {
        const isPRN = dose.asNeededBoolean ?? false
        const numberOfDoses = dosageInstructions.length

        return (
          <div
            className={tw`mt-2 flex flex-col gap-2`}
            key={`${order.id}-dose-${index}`}
          >
            <div className={tw`flex flex-row flex-wrap items-center gap-2`}>
              <VitalBadge medStatement={order.medicationStatement} />
              {isPRN ? (
                <PRNBadges dose={dose.doseAndRate}></PRNBadges>
              ) : (
                <>
                  <QuantityAndDosageBadge dose={dose} />
                  <PeriodAndFrequencyBadge dose={dose} />
                </>
              )}
              <DurationBadge dose={dose} dosageType={doseType} />
              <TimingBadges
                timing={dose.timing}
                use24HourTime={use24HourTime}
                keyPrefix={`${doseType}-${index}`}
              />
              <SlidingScaleBadges
                scaleEntries={getSlidingScaleEntriesFromDose(dose)}
                formState={formState}
              />
            </div>

            {dose.note && dose.note.trim().length > 0 && (
              <div
                className={tw`mt-2 flex items-center text-[14px] font-medium leading-[20px] text-secondary-04`}
              >
                <div
                  className={tw`min-h-[28px] w-[2px] self-stretch rounded-sm border-l-2 border-l-primary-light`}
                />
                <span className={tw`ml-3`}>{dose.note}</span>
              </div>
            )}
            {index + 1 !== numberOfDoses && (
              <div
                className={tw`mb-2 mt-2 h-[1px] w-full rounded-sm bg-secondary-10`}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}
