import {
  FacilityMetricAggregateHistory,
  Metric,
} from '@augusthealth/models/com/august/protos/api/facility_metrics'
import { NameTd, TdWithExtraPadding } from '@app/components/Table'

function MetricTd({
  currentCount,
  previousCount,
  reverseCssClass,
}: {
  currentCount: number
  previousCount?: number
  reverseCssClass?: boolean
}) {
  const diff = previousCount === undefined ? 0 : previousCount - currentCount
  let className
  if (diff > 0) {
    className = reverseCssClass ? 'text-warning' : 'text-success'
  } else if (diff < 0) {
    className = reverseCssClass ? 'text-success' : 'text-warning'
  }
  const diffLabel = diff ? (
    <span className={className}>
      ({diff > 0 ? '+' : ''}
      {diff})
    </span>
  ) : (
    ''
  )
  return (
    <TdWithExtraPadding>
      {currentCount} {diffLabel}
    </TdWithExtraPadding>
  )
}

export default function MetricsTr({
  title,
  metricList = [],
  metric,
}: {
  title: string
  metricList?: FacilityMetricAggregateHistory[]
  metric: Metric
}) {
  const metricData = metricList.find(
    (m) => m?.metricDetail?.clientId === metric
  )
  const values = metricData?.values || []
  const todayCount = values[values.length - 1]
  const yesterdayCount = values[values.length - 2]
  const weekCount = values[values.length - 8]
  const monthCount = values[0]
  const minCount = Math.min(...values)
  const maxCount = Math.max(...values)
  const reverseCssClass = metric === Metric.METRIC_DISCHARGED_RESIDENTS

  return (
    <tr>
      <NameTd title={title}>{title}</NameTd>
      <MetricTd currentCount={todayCount} />
      <MetricTd
        currentCount={yesterdayCount}
        previousCount={todayCount}
        reverseCssClass={reverseCssClass}
      />
      <MetricTd
        currentCount={weekCount}
        previousCount={todayCount}
        reverseCssClass={reverseCssClass}
      />
      <MetricTd
        currentCount={monthCount}
        previousCount={todayCount}
        reverseCssClass={reverseCssClass}
      />
      <MetricTd currentCount={minCount} />
      <MetricTd currentCount={maxCount} />
    </tr>
  )
}
