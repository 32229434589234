import { useForm, useWatch } from 'react-hook-form'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { LabelAboveInput } from '@shared/components/Labels'
import {
  OptionTypeBase,
  StyledMultiSelect,
} from '@shared/components/StyledSelect'
import { IncidentType, NoteType } from '@shared/types/incidents'
import { tw } from '@shared/utils/tailwind'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import { getInputWithIconGroups } from '../helpers'
import { defaultFilterValues } from '../index'
import { FilterIncidentsFormData, InputWithIcon, TagGroupType } from '../types'
import InputGroup, { InputGroupProps } from './InputGroup'
import ToggleSwitch from './ToggleSwitch'

type UserId = string

export default function FilterIncidentsModal({
  closeModal,
  filters,
  setFilters,
  userOptions,
}: {
  closeModal: () => void
  filters: FilterIncidentsFormData
  setFilters: React.Dispatch<React.SetStateAction<FilterIncidentsFormData>>
  userOptions: OptionTypeBase<UserId>[]
}) {
  const cleanOnAlert = {
    ...filters,
    onAlert: filters.onAlert ?? false,
  }
  const { control, setValue, handleSubmit, reset, register, formState } =
    useForm<FilterIncidentsFormData>({
      defaultValues: cleanOnAlert,
    })

  function onSubmit(formData: FilterIncidentsFormData) {
    const cleanOnAlert = { ...formData, onAlert: formData.onAlert || null }
    setFilters(cleanOnAlert)
    closeModal()
  }

  const { currentFacility } = useCurrentFacility()

  const selectedNoteFilters = useWatch({
    control,
    name: 'noteTypes',
  })

  const selectedIncidentFilters = useWatch({
    control,
    name: 'incidentTypes',
  })

  const selectedUserIds = useWatch({
    control,
    name: 'users',
  })

  const selectedUserOptions = userOptions.filter((user) =>
    selectedUserIds.includes(user.value)
  )

  function Checkboxes(props: Omit<InputGroupProps, 'inputType' | 'register'>) {
    return <InputGroup inputType="checkbox" register={register} {...props} />
  }

  function RadioButtons(
    props: Omit<InputGroupProps, 'inputType' | 'register'>
  ) {
    return <InputGroup inputType="radio" register={register} {...props} />
  }

  function ToggleableCheckboxes({
    name,
    label,
    group,
    selectedFilters,
    availableFilters,
  }: {
    name: 'noteTypes' | 'incidentTypes'
    label: string
    group: TagGroupType[]
    selectedFilters: NoteType[] | IncidentType[]
    availableFilters: TagGroupType[]
  }) {
    return (
      <Checkboxes
        name={name}
        label={label}
        group={group}
        globalToggleProps={{
          allTagsSelected: allTagsSelected(
            selectedFilters?.length,
            availableFilters.length
          ),
          onToggle: () =>
            handleGlobalToggle({
              name,
              selectedFilters,
              availableFilters: availableFilters.map(
                (t: InputWithIcon) => t.value
              ),
              setValue,
            }),
        }}
      />
    )
  }

  if (currentFacility === undefined || currentFacility.tag === 'Loading') {
    return null
  }

  const { noteTypes, incidentTypes, incidentStatus, time } =
    getInputWithIconGroups({ facility: currentFacility.value })

  return (
    <Modal
      open={true}
      onClose={() => closeModal()}
      id={'filter-incidents'}
      contentClassName={tw`min-w-[800px] max-w-[800px]`}
    >
      <ModalTitle>Filter Notes & Incidents</ModalTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ToggleableCheckboxes
          name="noteTypes"
          label="Notes"
          group={noteTypes}
          selectedFilters={selectedNoteFilters}
          availableFilters={noteTypes}
        />

        <ToggleableCheckboxes
          name="incidentTypes"
          label="Incidents"
          group={incidentTypes}
          selectedFilters={selectedIncidentFilters}
          availableFilters={incidentTypes}
        />

        <RadioButtons
          name="incidentStatus"
          label="Incident Status"
          group={incidentStatus}
        />

        <RadioButtons name="time" label="time" group={time} />
        <section className="mt-[32px]">
          <LabelAboveInput
            htmlFor="created-by-user"
            id="label-for-created-by-user"
          >
            Created by
          </LabelAboveInput>
          <StyledMultiSelect
            inputId="created-by-user"
            aria-labelledby="label-for-created-by-user"
            isClearable
            menuPlacement="top"
            onChange={(selectedOptions: OptionTypeBase<UserId>[]) => {
              setValue(
                'users',
                selectedOptions.map((option) => option.value)
              )
            }}
            options={userOptions}
            placeholder="Search by name"
            value={selectedUserOptions}
          />
        </section>
        <ToggleSwitch
          control={control}
          id="onAlert"
          label="Resident Status"
          name="onAlert"
          switchLabel="On Alert"
        />
        <footer className="mt-[32px]">
          <AsyncIconButton
            buttonStyle={'secondary-outline'}
            isLoading={false}
            width={'136px'}
            className="mr-[16px]"
            onClick={() => {
              reset(defaultFilterValues)
            }}
            type={'button'}
          >
            Clear All
          </AsyncIconButton>
          <AsyncIconButton
            data-testid="submit-filters"
            isLoading={formState.isSubmitting}
            disabled={formState.isSubmitting}
            buttonStyle="primary-fill"
            width={'136px'}
            type="submit"
          >
            Filter
          </AsyncIconButton>
        </footer>
      </form>
    </Modal>
  )
}

function allTagsSelected(
  selectedFiltersLength: number | undefined,
  availableFiltersLength: number
) {
  return selectedFiltersLength === availableFiltersLength
}

function handleGlobalToggle({
  selectedFilters,
  availableFilters,
  name,
  setValue,
}: {
  selectedFilters: NoteType[] | IncidentType[]
  availableFilters: string[]
  name: 'noteTypes' | 'incidentTypes'
  setValue: (name: any, value: any) => void
}) {
  if (allTagsSelected(selectedFilters?.length, availableFilters?.length)) {
    setValue(name, [])

    if (name === 'incidentTypes') {
      setValue('incidentStatus', null)
    }
  } else {
    setValue(name, [...availableFilters])
  }
}
