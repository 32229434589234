import { format, startOfToday } from 'date-fns'
import { createBillingCharges } from '@shared/api/billing'
import { BillingChargeData } from '@shared/types/billing'

export type BillingChargeFormData = Omit<BillingChargeData, 'personId'> & {
  personIds: string[]
}

export type ModalProps = {
  residentIds: string[]
  closeFn: () => void
  doneFn: () => Promise<void>
  disabled?: boolean
  title?: string
  defaultValues?: BillingChargeFormData
}

export function getDefaultValues({
  defaultFormData,
  personIds,
}: {
  defaultFormData?: BillingChargeFormData
  personIds: string[]
}) {
  if (defaultFormData) {
    return defaultFormData
  }

  return {
    personIds,
    name: undefined,
    quantity: 1,
    amountCents: undefined,
    note: undefined,
    startDate: format(startOfToday(), 'yyyy-MM-dd'),
    endDate: undefined,
    itemId: undefined,
  }
}

export async function createCharge({
  orgId,
  facilityId,
  formData,
}: {
  orgId: string
  facilityId: string
  formData: BillingChargeFormData
}) {
  const billingChargesDataList = formData.personIds.map((personId) => {
    const { personIds, ...restFormData } = formData
    return { personId, ...restFormData }
  })

  return await createBillingCharges({
    orgId,
    facilityId,
    billingChargesDataList,
  })
}

export function convertChargeDataToFormData(
  chargeData: BillingChargeData
): BillingChargeFormData {
  const { personId, ...restData } = chargeData

  return {
    personIds: [personId],
    ...restData,
  }
}

export function convertFormDataToChargeData(
  formData: BillingChargeFormData
): BillingChargeData {
  const { personIds, ...chargeDataProps } = formData

  return {
    personId: personIds[0],
    ...chargeDataProps,
  }
}
