import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import TextInputWithIcon from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import { PasswordPolicyRequirement } from '@shared/constants/passwordPolicy'
import { tw } from '@shared/utils/tailwind'
import { copyToClipboard, generatePassword, validate } from './helpers'
import Requirements from './Requirements'

export default function PasswordField({
  requirements,
  isEmailAuth,
  isAddUser,
  disabled,
}: {
  requirements: PasswordPolicyRequirement
  isEmailAuth: boolean
  isAddUser: boolean
  disabled: boolean
}) {
  const {
    getValues,
    watch,
    register,
    formState: { errors },
    setValue,
  } = useFormContext()

  const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(true)
  const [isPasswordCopied, setIsPasswordCopied] = useState<boolean>(false)
  const [isManualEntry, setIsManualEntry] = useState<boolean>(false)

  const password = watch('password') as string | undefined

  async function copyPassword() {
    try {
      if (password) {
        await copyToClipboard(password)
        setIsPasswordCopied(true)
      }
    } finally {
      setTimeout(() => {
        setIsPasswordCopied(false)
      }, 3000)
    }
  }

  function handleSubmitGeneratePassword() {
    setIsManualEntry(false)
    const newPassword = generatePassword()
    setValue('password', newPassword, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    })
  }

  function getLabelCtaProps() {
    if (!password?.length || errors.password) {
      return {
        text: 'Generate password',
        onClick: handleSubmitGeneratePassword,
      }
    } else {
      return {
        text: 'Reset password',
        onClick: () => {
          handleSubmitGeneratePassword()
          console.log('NOOP: API endpoint has not been implemented yet')
        },
      }
    }
  }

  function getInputCtaProps() {
    if (password && password.length) {
      if (isPasswordHidden) {
        return {
          text: 'Show password',
          onClick: () => setIsPasswordHidden(false),
        }
      } else {
        return {
          text: isPasswordCopied ? 'Copied!' : 'Copy password',
          onClick: copyPassword,
        }
      }
    }
    return undefined
  }

  return (
    <div className={tw`mb-[32px]`}>
      <LabelAboveInput
        subLabel={
          isEmailAuth
            ? undefined
            : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              requiredLabel(Boolean(!isEmailAuth && errors.password))
        }
        htmlFor="password"
        ctaProps={getLabelCtaProps()}
      >
        Password
      </LabelAboveInput>
      <TextInputWithIcon
        inputProps={{
          disabled,
          id: 'password',
          placeholder: 'generate or enter password',
          type: isPasswordHidden ? 'password' : 'text',
          ...register('password', {
            required: !isEmailAuth && isAddUser,
            onChange: () => setIsManualEntry(true),
            validate: {
              minLength: () =>
                validate({
                  key: 'minLength',
                  requirements,
                  values: getValues(),
                }),
              noKnownStrings: () =>
                validate({
                  key: 'noKnownStrings',
                  requirements,
                  values: getValues(),
                }),
              requiresOneNumber: () =>
                validate({
                  key: 'requiresOneNumber',
                  requirements,
                  values: getValues(),
                }),
              requiresOneUppercase: () =>
                validate({
                  key: 'requiresOneUppercase',
                  requirements,
                  values: getValues(),
                }),
              requiresOneLowercase: () =>
                validate({
                  key: 'requiresOneLowercase',
                  requirements,
                  values: getValues(),
                }),
              requiresOneSpecialChar: () =>
                validate({
                  key: 'requiresOneSpecialChar',
                  requirements,
                  values: getValues(),
                }),
            },
          }),
        }}
        iconClassName="fas fa-shield-halved"
        ctaProps={getInputCtaProps()}
      />
      {isManualEntry && password && password.length > 0 && (
        <Requirements
          requirements={requirements}
          errors={errors.password?.types}
        />
      )}
    </div>
  )
}
