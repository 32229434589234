import { useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Card from '@shared/components/Card'
import { useUserContext } from '@shared/contexts/UserContext'
import { Organization } from '@shared/types/organization'
import { formatSingleLineAddress } from '@shared/utils/address'
import { getEmail, getFax, getPhone } from '@shared/utils/contactPoint'
import { BILLING_STRATEGY_MAP, FREQUENCY_MAP } from '@shared/utils/payment'
import { isSuperUser as getIsSuperUser } from '@shared/utils/user'
import HUD from '@app/components/HUD'
import OrgEditPopup from '@app/pages/Organizations/OrgEditPopup'
import styles from './styles.module.css'

type Props = {
  organization: Organization
  reload: () => Promise<any>
}

export default function OrgDetails(props: Props) {
  const { organization, reload } = props
  const { user } = useUserContext()
  const isSuperUser = getIsSuperUser(user)
  const [openEditOrgPopup, setOpenEditOrgPopup] = useState(false)
  const [showNotice, setShowNotice] = useState(false)
  const { name, telecom, address, paymentCustomer } = organization
  const addressLabel = formatSingleLineAddress(address)
  const phoneLabel = getPhone(telecom)?.value
  const faxLabel = getFax(telecom)?.value
  const emailLabel = getEmail(telecom)?.value
  const editPopup = openEditOrgPopup && (
    <OrgEditPopup
      organization={organization}
      onClose={() => setOpenEditOrgPopup(false)}
      onSave={() => {
        setOpenEditOrgPopup(false)
        void reload().then(() => setShowNotice(true))
      }}
    />
  )
  const onExpire = () => {
    setShowNotice(false)
  }
  const updatingNotice = showNotice ? (
    <HUD onExpire={onExpire}>
      Organization {organization && organization.name} updated!
    </HUD>
  ) : undefined

  return (
    <div className="ml-[12px] mr-[12px]">
      <Card className="flex flex-col p-[24px]">
        <div className="mb-[24px] flex w-full justify-between">
          <label className="form-control-label mb-0">
            <div className="form-element-title">
              <i className="fas fa-home-heart mr-[8px]" />
              ORGANIZATION
            </div>
          </label>
          <div className="flex-additional-item mr-[12px]">
            <AsyncIconButton
              initialIcon={'pen'}
              buttonStyle={'secondary-outline'}
              buttonSize={'xsmall'}
              onClick={() => setOpenEditOrgPopup(true)}
              id={'editOrganizationButton'}
            >
              Edit
            </AsyncIconButton>
          </div>
        </div>
        <hr className="mb-0 mt-0 w-full" />
        <section
          data-id="orgInfoSection"
          className={`${styles.infoSection} mt-[24px] w-full text-left`}
        >
          <div className={styles.infoSectionHeading}>{name}</div>
          {addressLabel && (
            <div className="mb-[4px]">
              <i className="fas fa-map-marker-alt mr-[8px]" />
              {addressLabel}
            </div>
          )}
          {phoneLabel && (
            <div className="mb-[4px]">
              <i className="fas fa-phone mr-[8px]" />
              {phoneLabel}
            </div>
          )}
          {faxLabel && (
            <div className="mb-[4px]">
              <i className="fas fa-fax mr-[8px]" />
              {faxLabel}
            </div>
          )}
          {emailLabel && (
            <div className="mb-[4px]">
              <i className="fas fa-envelope mr-[8px]" />
              {emailLabel}
            </div>
          )}
          {isSuperUser && paymentCustomer?.billingStrategy && (
            <div className="mb-[4px]">
              Billing Strategy:&nbsp;
              {BILLING_STRATEGY_MAP[paymentCustomer.billingStrategy]}
            </div>
          )}
          {isSuperUser && paymentCustomer?.paymentFrequency && (
            <div className="mb-[4px]">
              Payment Frequency:&nbsp;
              {FREQUENCY_MAP[paymentCustomer.paymentFrequency]}
            </div>
          )}
        </section>
      </Card>
      {editPopup}
      {updatingNotice}
    </div>
  )
}
