import { AugustInitialAppraisal } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import { AppraisalSettings_AppraisalCategory as AppraisalCategory } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { Task } from '@augusthealth/models/com/august/protos/task'
import { useHistory } from 'react-router-dom'
import { LabelAboveInput } from '@shared/components/Labels'
import { getTaskUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import {
  getCategoryQuestion,
  getLevelValue,
} from '@shared/utils/residentAssessment'
import styles from '../styles.module.css'
import { getPageByCategory } from '../helpers'
import { AssessmentPageMode } from '../types'

export default function NeedsHeader({
  assessment = {},
  category,
  mode,
  person,
  task,
}: {
  assessment?: AugustInitialAppraisal
  category: AppraisalCategory
  mode: AssessmentPageMode
  person: Person
  task?: Task
}) {
  const history = useHistory()

  const { levels = [] } = category
  if (levels.length === 0) {
    return null
  }

  const question = getCategoryQuestion(category)
  const { orgId, facilityId, id: personId } = person
  const page = getPageByCategory(category)
  const isIncomplete = !getLevelValue({ category, assessment })

  if (
    isIncomplete &&
    mode.tag === 'review' &&
    task?.id &&
    orgId &&
    personId &&
    page
  ) {
    const { id } = task
    const k = category.categoryKey
    const taskUrl = `${getTaskUrl({
      orgId,
      facilityId: facilityId || '',
      personId,
      id,
      page,
    })}#${k}`
    return (
      <>
        <div className={styles.incompleteText}>
          <i className="fas fa-info-circle text-[12px] text-tags-alert" />
          <span className="ml-[8px] mr-[8px] text-[14px] font-bold uppercase leading-[18px]">
            Needs
          </span>
          <a
            className={styles.incompleteLink}
            href={taskUrl}
            onClick={(e) => {
              e.preventDefault()
              history.push(taskUrl)
            }}
          >
            Complete Now
          </a>
        </div>
        <h4 className={styles.h4}>{question}</h4>
      </>
    )
  } else {
    return (
      <>
        <LabelAboveInput className="mb-[16px] mt-[32px]" htmlFor="">
          Needs
        </LabelAboveInput>
        <h4 className={styles.h4}>{question}</h4>
      </>
    )
  }
}
