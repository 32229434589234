import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import {
  AssessmentCategory,
  AssessmentConfig,
} from '@shared/types/assessment_configuration'
import { FrontendDetail } from '@app/pages/Tasks/ResidentAssessment/AssessmentPage/CheckboxSections/utils'
import { addNewDetailGroup } from './helpers'

export type DetailModalForm = {
  groupName: string
}
type Props = {
  updatedDetails: Map<string, FrontendDetail[]>
  selectedCategory: AssessmentCategory
  setUpdatedDetails: (updatedDetails: Map<string, FrontendDetail[]>) => void
  setAssessmentConfig: (assessmentConfig: AssessmentConfig) => void
  currentAssessmentConfig: AssessmentConfig
  categoryIsMarkedForDelete: boolean
}

export const AddDetailGroupModal = ({
  updatedDetails,
  selectedCategory,
  setUpdatedDetails,
  setAssessmentConfig,
  currentAssessmentConfig,
  categoryIsMarkedForDelete,
}: Props) => {
  const [showModal, setShowModal] = useState(false)
  const { reset, handleSubmit, register, formState, getValues } =
    useForm<DetailModalForm>({
      mode: 'onSubmit',
    })

  useEffect(() => {
    reset()
  }, [showModal])

  const currentGroupNames = Array.from(updatedDetails.keys())
  const uniqueNameError = 'Must be unique'
  const nameIsTaken = (proposedName: string): boolean => {
    const currentNames = currentGroupNames.map((v) => v.toLowerCase())
    return currentNames.includes(proposedName.toLowerCase())
  }

  const onSubmit = () => {
    const newDetails = addNewDetailGroup({
      groupName: getValues('groupName'),
      currentDetails: updatedDetails,
    })

    setUpdatedDetails(newDetails)
    setAssessmentConfig({
      ...currentAssessmentConfig,
      [selectedCategory!.categoryKey!]: {
        ...currentAssessmentConfig[selectedCategory!.categoryKey!],
        details: newDetails,
      },
    })

    setShowModal(false)
  }

  return (
    <>
      <AsyncIconButton
        buttonStyle="primary-outline"
        id="addNewDetailGroup"
        initialIcon={'fa-solid fa-plus'}
        onClick={() => setShowModal(true)}
        className={'mb-[16px] ml-auto block w-full'}
        disabled={categoryIsMarkedForDelete}
      >
        Add New Group
      </AsyncIconButton>
      {showModal && (
        <AnimatedPopup title={'Add New Group'}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <LabelAboveInput
              htmlFor={'groupName'}
              subLabel={requiredLabel(!!formState.errors.groupName)}
              errorMessage={formState.errors.groupName?.message}
            >
              Group Name
            </LabelAboveInput>
            <BasicInput
              placeholder={'Unique name for this group'}
              {...register('groupName', {
                required: true,
                validate: (value: string) => {
                  if (nameIsTaken(value)) {
                    return uniqueNameError
                  }

                  return true
                },
              })}
            />
            {formState.errors.groupName?.message === uniqueNameError && (
              <div className={'mt-[8px] text-[12px] text-secondary-font-color'}>
                The following names are taken in this category:
                <code className={'ml-[8px]'}>
                  {currentGroupNames.join(', ')}
                </code>
              </div>
            )}
            <div className={'mb-[16px] mt-[32px]'}>
              <AnimatedPopupFormFooter
                formState={formState}
                noBtn={{ action: () => setShowModal(false) }}
                yesBtn={{
                  props: {
                    id: 'saveDetailGroup',
                    ['data-testid']: 'saveDetailGroup',
                  },
                }}
              />
            </div>
          </form>
        </AnimatedPopup>
      )}
    </>
  )
}
