import { tw } from '@shared/utils/tailwind'

export default function Header({
  title,
  copy,
}: {
  title: string
  copy?: string
}) {
  return (
    <>
      <div className={tw`mb-[40px] flex items-center`}>
        <img
          src="/svg/symbol.svg"
          alt="August Health logo symbol"
          className={tw`mr-[10px] h-[32px] w-[32px]`}
        />
        <img
          src="/svg/wordmark-copy-only.svg"
          alt="August Health logo copy"
          className={tw`h-[38px] w-[228px]`}
        />
      </div>

      <div className={tw`font-inter text-[20px] font-semibold leading-[24px]`}>
        {title}
      </div>
      {copy && (
        <div
          className={tw`mt-[16px] text-center text-[16px] font-medium text-gray-07`}
        >
          {copy}
        </div>
      )}
    </>
  )
}
