import { AugustInitialAppraisal } from '@augusthealth/models/com/august/protos/august_initial_appraisal'
import { AugustFieldType } from '@augusthealth/models/com/august/protos/custom_field'
import Diet from 'app/components/Diet'
import Badge from '@shared/components/Badge'
import { Person } from '@shared/types/person'
import { getDietaryRestrictions } from '@shared/utils/residentAssessment'
import { tw } from '@shared/utils/tailwind'
import { usePersonInContext } from '@app/hooks/usePerson'

export default function AugustFieldDetail({
  initialPerson,
  augustField,
  mode,
  assessment,
}: {
  initialPerson: Person
  augustField: AugustFieldType
  mode: 'edit' | 'edit-sans-august-fields' | 'view'
  assessment?: AugustInitialAppraisal
}) {
  const { refreshPerson } = usePersonInContext({
    initialData: initialPerson,
    skipFirstFetch: true,
  })

  if (mode === 'edit-sans-august-fields') {
    return null
  }

  switch (augustField) {
    case AugustFieldType.AUGUST_FIELD_TYPE_DIETARY_RESTRICTIONS:
      if (mode === 'edit') {
        return <Diet person={initialPerson} updatePerson={refreshPerson} />
      } else {
        const dietData = assessment
          ? getDietaryRestrictions(assessment)
          : undefined

        if (dietData) {
          return (
            <div className={tw`mb-[16px] flex items-center`}>
              <Badge
                badgeSize={'medium'}
                color={'primary'}
                shape={'square'}
                className={tw`mr-[8px] uppercase`}
              >
                Diet
              </Badge>
              <span className={tw`font-medium`}>{dietData}</span>
            </div>
          )
        }

        return null
      }
    default:
      return null
  }
}
