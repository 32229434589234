import { useContext, useEffect, useState } from 'react'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { EmptyCard } from '@shared/components/EmptyCard'
import { Person } from '@shared/types/person'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { tw } from '@shared/utils/tailwind'
import { VITALS_INPUT_PROPS_MAP } from '@shared/utils/vitals'
import Print from '@app/components/Printable'
import VitalsContext from '@app/contexts/VitalsContext'
import LatestVitalsCard from '../Vitals/LatestVitalsCard'
import VitalsContent from './VitalsContent'

export default function HistoricVitalsModal({
  closeModal,
  person,
}: {
  closeModal: () => void
  person: Person
}) {
  const { activeTab, allVitals, refreshAllVitals } = useContext(VitalsContext)
  const [printHistoricVitals, setPrintHistoricVitals] = useState(false)
  const label = VITALS_INPUT_PROPS_MAP[activeTab!].label
  const title = `${label} for ${getFirstAndLastName(person.name)}`

  useEffect(() => {
    void refreshAllVitals()
  }, [])

  return (
    <Modal
      open={true}
      onClose={() => closeModal()}
      id={'historic-vitals'}
      contentClassName={tw`flex h-[85vh] max-h-[85vh] min-h-[85vh] w-[1138px] min-w-[1138px] max-w-[1138px] flex-col`}
    >
      {allVitals.tag === 'Loading' ? (
        <>
          <h4 className="mb-[24px]">{title}</h4>
          <LatestVitalsCard showDateOfLastEdits={false} />
          <EmptyCard
            className={tw`h-full`}
            version={{
              tag: 'Children',
              children: <></>,
            }}
            title="Loading vitals..."
            data-testid="no-vitals-message"
          />
        </>
      ) : (
        <>
          <VitalsContent
            setPrintHistoricVitals={setPrintHistoricVitals}
            person={person}
          />
          {printHistoricVitals && (
            <Print>
              <VitalsContent
                setPrintHistoricVitals={setPrintHistoricVitals}
                person={person}
              />
            </Print>
          )}
        </>
      )}
    </Modal>
  )
}
