import {
  OrgFacilitySettings,
  SettingsType,
} from '@augusthealth/models/com/august/protos/org_facility_settings'
import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { Loading } from '@shared/utils/loading'
import { fetchSettingsByKey } from '../api/orgFacilitySettings'

export default function useSettingsByKey({
  facility,
  settingsType,
}: {
  facility?: Facility
  settingsType: SettingsType
}) {
  const { setError } = useContext(GlobalContext)
  const [settings, setTaskSettings] = useState<Loading<OrgFacilitySettings[]>>({
    tag: 'Loading',
  })

  const reloadSettings = async () => {
    try {
      if (facility) {
        const value = await fetchSettingsByKey(
          facility.orgId,
          facility.id,
          settingsType
        )

        setTaskSettings({ tag: 'Complete', value })
      } else {
        setTaskSettings({ tag: 'Loading' })
      }
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    void reloadSettings()
  }, [facility?.id])

  return { settings, reloadSettings }
}
