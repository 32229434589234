import { ValidDate } from '@augusthealth/models/com/august/protos/date'
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
  endOfWeek,
  format,
  startOfWeek,
  startOfYear,
  sub,
} from 'date-fns'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import {
  CountByTimeUnitStat,
  GroupByTimeUnit,
  IncidentActionType,
  IncidentStatus,
} from '@shared/types/incidents'
import {
  formatIsoDate,
  fromDateMessageToDate,
  fromDateToDateMessage,
  fromIsoDateToDateMessage,
  isWithinInterval,
} from '@shared/utils/date'
import {
  DateRangeParams,
  FacilityIncidentsParams,
  PaginationParams,
} from '@app/api/incidents'
import { FilterIncidentsFormData, FilterTimeSpan } from '@app/pages/Notes/types'

const now = new Date()

function getStartDate(
  timeSpan: Exclude<FilterTimeSpan, null>,
  now: Date
): Date {
  switch (timeSpan) {
    case 'lastTwentyFourHours':
      return sub(now, { days: 1 })
    case 'lastSevenDays':
      return sub(now, { days: 7 })
    case 'lastThirtyDays':
      return sub(now, { days: 30 })
    case 'lastSixtyDays':
      return sub(now, { days: 60 })
    case 'lastHundredAndTwentyDays':
      return sub(now, { days: 120 })
    case 'lastTwelveMonths':
      return sub(now, { months: 12 })
    case 'yearToDate':
      return startOfYear(now)
  }
}

function getGroupByTimeUnit(timeSpanUnit: TimeSpanUnit): GroupByTimeUnit {
  switch (timeSpanUnit) {
    case 'days':
      return GroupByTimeUnit.GROUP_BY_TIME_UNIT_DAY
    case 'weeks':
      return GroupByTimeUnit.GROUP_BY_TIME_UNIT_WEEK
    case 'months':
      return GroupByTimeUnit.GROUP_BY_TIME_UNIT_MONTH
    case 'yearToDate':
      return GroupByTimeUnit.GROUP_BY_TIME_UNIT_MONTH
  }
}

function toDateRangeParams(timeSpan: FilterTimeSpan): DateRangeParams {
  if (timeSpan === null) {
    return { startDate: undefined, endDate: undefined }
  } else {
    const now = new Date()
    return {
      startDate: formatIsoDate(getStartDate(timeSpan, now)),
      endDate: formatIsoDate(now),
    }
  }
}

function isIncidentStatus(
  value: IncidentStatus | IncidentActionType
): value is IncidentStatus {
  return Object.values(IncidentStatus).includes(value as IncidentStatus)
}

function separateTypes(
  value: IncidentStatus | IncidentActionType | null
):
  | { incidentStatus: IncidentStatus; actionType: undefined }
  | { incidentStatus: undefined; actionType: IncidentActionType }
  | { incidentStatus: undefined; actionType: undefined } {
  if (value === null) {
    return {
      incidentStatus: undefined,
      actionType: undefined,
    }
  } else if (isIncidentStatus(value)) {
    return {
      incidentStatus: value,
      actionType: undefined,
    }
  } else {
    return {
      incidentStatus: undefined,
      actionType: value,
    }
  }
}

export function facilityIncidentsFormDataToParams({
  filters,
}: {
  filters: FilterIncidentsFormData & PaginationParams
}): FacilityIncidentsParams {
  const { limit, offset } = filters
  const { startDate, endDate } = toDateRangeParams(filters.time)
  const { incidentStatus, actionType } = separateTypes(filters.incidentStatus)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, timeSpanUnit] = getNumberAndTypeOfUnits(filters.time)

  return {
    startDate,
    endDate,
    noteTypes: filters.noteTypes,
    incidentTypes: filters.incidentTypes,
    incidentStatus,
    actionType,
    onAlert: filters.onAlert ?? undefined,
    createdBy: filters.users,
    groupStatsBy: getGroupByTimeUnit(timeSpanUnit),
    limit,
    offset,
  }
}

export type TimeSpanUnit = 'months' | 'weeks' | 'days' | 'yearToDate'

type IntervalProps = {
  numberOfUnits: number | null
  typeOfUnit: TimeSpanUnit
}

export function getInterval(props: IntervalProps) {
  const { numberOfUnits, typeOfUnit } = props

  if (numberOfUnits) {
    if (typeOfUnit === 'days') {
      return eachDayOfInterval({
        start: sub(now, { days: numberOfUnits }),
        end: now,
      })
    }

    if (typeOfUnit === 'weeks') {
      return eachWeekOfInterval({
        start: sub(now, { weeks: numberOfUnits }),
        end: now,
      })
    }

    if (typeOfUnit === 'months') {
      return eachMonthOfInterval({
        start: sub(now, { months: numberOfUnits }),
        end: now,
      })
    }
  }

  return eachMonthOfInterval({
    // We launched Incidents in 2022, and the chart should display 'all incidents'
    // if no filters are selected
    start: new Date(2022, 0, 1),
    end: now,
  }).slice(-12)
}

export function getLabels({
  dates,
  typeOfUnit,
}: {
  dates: Date[]
  typeOfUnit: TimeSpanUnit
}) {
  const formats = {
    days: 'MMM do',
    weeks: 'MMM do',
    months: 'MMM',
    yearToDate: 'MMM',
  }
  return dates.map((d) => format(d, formats[typeOfUnit]))
}

export function formatDatesAndLabels(props: IntervalProps) {
  const { numberOfUnits, typeOfUnit } = props
  const dates = getInterval({ numberOfUnits, typeOfUnit })

  return {
    dates,
    labels: getLabels({ dates, typeOfUnit }),
  }
}

type IncidentCount = {
  count: number
  date: Date
}

export function calculateNumberOfOccurences({
  dates,
  typeOfUnit,
  incidentCountByDate,
}: {
  dates: Date[]
  typeOfUnit: TimeSpanUnit
  incidentCountByDate: CountByTimeUnitStat[]
}): { incidentCounts: IncidentCount[]; noteCounts: IncidentCount[] } {
  return dates.reduce(
    (
      memo: { incidentCounts: IncidentCount[]; noteCounts: IncidentCount[] },
      date
    ) => {
      const counts = incidentCountByDate.reduce(
        (memo, countByDate) => {
          const incidentDateMessage = fromIsoDateToDateMessage(countByDate.date)
          if (!incidentDateMessage) {
            ErrorMonitoring.capture({
              error: `Server returned non ISO date string ${countByDate.date} for incident filtering chart counts`,
              level: 'error',
            })

            return {
              incidentCount: memo.incidentCount,
              noteCount: memo.noteCount,
            }
          }
          const comparisonDateMessage = fromDateToDateMessage(date)
          if (
            filterByUnitType({
              incidentDateMessage,
              comparisonDateMessage,
              typeOfUnit,
            })
          ) {
            return {
              incidentCount: memo.incidentCount + countByDate.incidentCount,
              noteCount: memo.noteCount + countByDate.noteCount,
            }
          } else {
            return {
              incidentCount: memo.incidentCount,
              noteCount: memo.noteCount,
            }
          }
        },
        {
          incidentCount: 0,
          noteCount: 0,
        }
      )

      return {
        incidentCounts: [
          ...memo.incidentCounts,
          { date, count: counts.incidentCount },
        ],
        noteCounts: [...memo.noteCounts, { date, count: counts.noteCount }],
      }
    },
    { incidentCounts: [], noteCounts: [] }
  )
}

function filterByUnitType({
  incidentDateMessage,
  comparisonDateMessage,
  typeOfUnit,
}: {
  incidentDateMessage: ValidDate
  comparisonDateMessage: ValidDate
  typeOfUnit: TimeSpanUnit
}) {
  const hasOccuredInGivenMonth =
    incidentDateMessage.year === comparisonDateMessage.year &&
    incidentDateMessage.month === comparisonDateMessage.month

  if (typeOfUnit === 'weeks') {
    const comparisonDate = fromDateMessageToDate(comparisonDateMessage)
    const weekStart = startOfWeek(comparisonDate!)
    const weekEnd = endOfWeek(comparisonDate!)
    return isWithinInterval(fromDateMessageToDate(incidentDateMessage)!, {
      start: weekStart,
      end: weekEnd,
    })
  }

  if (typeOfUnit === 'days') {
    return (
      hasOccuredInGivenMonth &&
      incidentDateMessage.day === comparisonDateMessage.day
    )
  }

  if (typeOfUnit === 'months') {
    return hasOccuredInGivenMonth
  }

  return false
}

export function getNumberAndTypeOfUnits(
  time: FilterTimeSpan
): [number | null, TimeSpanUnit] {
  switch (time) {
    case 'lastTwentyFourHours':
      return [1, 'days']
    case 'lastSevenDays':
      return [7, 'days']
    case 'lastThirtyDays':
      return [30, 'days']
    case 'lastSixtyDays':
      return [60, 'days']
    case 'lastHundredAndTwentyDays':
      return [17, 'weeks']
    case 'lastTwelveMonths':
      return [12, 'months']
    default:
      return [null, 'months']
  }
}

interface IncidentsCountChartData {
  labels: string[]
  maxY: number
  incidentCoords: { date: Date; x: number; y: number }[]
  noteCoords: { date: Date; x: number; y: number }[]
}

export function getChartData({
  time,
  incidentCountByDate,
}: {
  time: FilterTimeSpan
  incidentCountByDate: CountByTimeUnitStat[]
}): IncidentsCountChartData {
  const [numberOfUnits, typeOfUnit] = getNumberAndTypeOfUnits(time)
  const { dates, labels } = formatDatesAndLabels({
    numberOfUnits,
    typeOfUnit,
  })
  const { incidentCounts, noteCounts } = calculateNumberOfOccurences({
    dates,
    typeOfUnit,
    incidentCountByDate,
  })
  let maxY = 4
  const getCoordsAndSetMaxY = (list: IncidentCount[]) =>
    list.map((list, index) => {
      const { count: y, date } = list
      if (maxY < y) {
        maxY = y
      }

      return { date, x: index + 1, y }
    })

  return {
    labels,
    incidentCoords: getCoordsAndSetMaxY(incidentCounts),
    noteCoords: getCoordsAndSetMaxY(noteCounts),
    maxY,
  }
}
