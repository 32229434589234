import { useState } from 'react'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { Person } from '@shared/types/person'

const useOrgFacilityPersonSelects = () => {
  const [selectedOrganization, setSelectedOrganization] =
    useState<OptionTypeBase<Organization> | null>(null)
  const [selectedFacility, setSelectedFacility] =
    useState<OptionTypeBase<Facility> | null>(null)
  const [selectedPerson, setSelectedPerson] =
    useState<OptionTypeBase<Person> | null>(null)

  const onOrgSelect = (e: OptionTypeBase<Organization> | null) => {
    setSelectedOrganization(e)
  }

  const onFacilitySelect = (e: OptionTypeBase<Facility> | null) => {
    setSelectedFacility(e)
  }

  const onPersonSelect = (e: OptionTypeBase<Person> | null) => {
    setSelectedPerson(e)
  }

  const resetOrgFacilityPersonSelects = () => {
    setSelectedOrganization(null)
    setSelectedFacility(null)
    setSelectedPerson(null)
  }

  return {
    selectedOrganization,
    selectedFacility,
    selectedPerson,
    onOrgSelect,
    onFacilitySelect,
    onPersonSelect,
    resetOrgFacilityPersonSelects,
  }
}

export default useOrgFacilityPersonSelects
