import { sortBy } from 'lodash'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import { CodeableConcept, SystemUrl } from '@shared/types/codeable_concept'
import { Immunization } from '@shared/types/immunization'
import { fromDateMessageToDate } from '@shared/utils/date'

export const covidDiseaseName = 'COVID-19' as const
// https://phinvads.cdc.gov/vads/ViewCodeSystemConcept.action?oid=2.16.840.1.113883.6.96&code=840539006
export const covidDiseaseCode = '840539006' as const
export const influenzaName = 'Influenza' as const

export const COVID_CODEABLE_CONCEPT: CodeableConcept = {
  coding: [
    {
      code: covidDiseaseCode,
      display: covidDiseaseName,
      systemEnum: SystemUrl.SYSTEM_URL_RXNORM,
    },
  ],
  text: covidDiseaseName,
}

export const INFLUENZA_CODEABLE_CONCEPT: CodeableConcept = {
  text: influenzaName,
}

export type TargetDiseaseOption = OptionTypeBase<CodeableConcept>

export const TARGET_DISEASE_OPTIONS: TargetDiseaseOption[] = [
  {
    label: covidDiseaseName,
    value: COVID_CODEABLE_CONCEPT,
  },
  {
    label: 'Flu (Influenza)',
    value: INFLUENZA_CODEABLE_CONCEPT,
  },
]

export const AVAILABLE_VACCINATIONS = TARGET_DISEASE_OPTIONS.map(
  (opt) => opt.value.text
)

export function getDefaultImmunization(diseaseName?: string): Immunization {
  if (!diseaseName) {
    return {}
  }

  const option = TARGET_DISEASE_OPTIONS.find(
    (opt) => opt.value.text === diseaseName
  )
  return option?.value
    ? {
        protocolApplied: {
          targetDisease: option.value,
        },
      }
    : {}
}

export function getDiseaseLabel(diseaseName: string) {
  const option = TARGET_DISEASE_OPTIONS.find(
    (opt) => opt.value.text === diseaseName
  )
  return option?.label || ''
}

export const ROUTE_OPTIONS: OptionTypeBase<CodeableConcept>[] = [
  {
    label: 'Subcutaneous route',
    value: {
      text: 'subcutaneous route',
    },
  },
  {
    label: 'Intramuscular route',
    value: {
      text: 'intramuscular route',
    },
  },
  {
    label: 'Intranasal route',
    value: {
      text: 'intranasal route',
    },
  },
]

export function sortByDateOfOccurrence(immunizations: Immunization[]) {
  return sortBy(immunizations, [
    (immunization) => fromDateMessageToDate(immunization.occurrenceDate),
  ]).reverse()
}

export function mostRecentAttachmentId(immunizations: Immunization[]) {
  const sortedAttachments = sortByDateOfOccurrence(immunizations)

  return sortedAttachments.find((i) => i.attachmentSnapshotId !== undefined)
    ?.attachmentSnapshotId
}

export function getTargetDiseaseName(immunization: Immunization) {
  return immunization.protocolApplied?.targetDisease?.text
}

export function getImmunizationsByDiseaseName({
  diseaseName,
  immunizations,
}: {
  diseaseName: string
  immunizations: Immunization[]
}) {
  return immunizations.filter(
    (immu) => getTargetDiseaseName(immu) === diseaseName
  )
}
