import { FunctionComponent, useEffect, useState } from 'react'
import ElementHolder, { ValueProps } from '../ElementHolder'
import ButtonOption from './ButtonOption'
import CardOption from './CardOption'
import { Option, OptionComponentProps } from './helpers'
import RadioInputOption from './RadioInputOption'

// TO DO: Need to change onUpdate to onClick so it can be more generic rather Generator specific
type Props = {
  OptionComponent?: FunctionComponent<OptionComponentProps>
  disabled?: boolean
  isOptional?: boolean // Allow to unclick a selected option
  name: string
  onUpdate: (value: ValueProps, name: string) => void
  options: Option[]
  value?: ValueProps
  inGenerator?: boolean
}

export default function RadioGroup(props: Props) {
  const {
    name,
    options,
    value,
    onUpdate,
    disabled: defaultDisabled,
    isOptional = false,
    OptionComponent = RadioInputOption,
    inGenerator = false,
  } = props
  const [data, setData] = useState<ValueProps>(value)

  useEffect(() => {
    setData(value)
  }, [value])

  function update(val: ValueProps) {
    if (val === data && isOptional) {
      setData(undefined)
      onUpdate(null, name) // Use null value to reset in PATCH
    } else {
      setData(val)
      onUpdate(val, name)
    }
  }

  const radioGroup = options.map((opt) => {
    const { disabled = defaultDisabled, value: val } = opt

    return (
      <OptionComponent
        key={`${val}-radio`}
        {...opt}
        name={name}
        onClick={update}
        checked={val === data}
        disabled={disabled}
      />
    )
  })
  const group = <div>{radioGroup}</div>

  if (inGenerator) {
    return <ElementHolder {...props}>{group}</ElementHolder>
  }

  return group
}

export function RadioButtonGroup(props: Omit<Props, 'OptionComponent'>) {
  return <RadioGroup {...props} OptionComponent={ButtonOption} />
}

export function RadioCardGroup(props: Omit<Props, 'OptionComponent'>) {
  return <RadioGroup {...props} OptionComponent={CardOption} />
}

export function RadioInputGroup(props: Omit<Props, 'OptionComponent'>) {
  return <RadioGroup {...props} OptionComponent={RadioInputOption} />
}

export function RadioButtonGroupInGenerator(
  props: Omit<Props, 'OptionComponent'>
) {
  return <RadioGroup {...props} OptionComponent={ButtonOption} inGenerator />
}

export function RadioCardGroupInGenerator(
  props: Omit<Props, 'OptionComponent'>
) {
  return <RadioGroup {...props} OptionComponent={CardOption} inGenerator />
}

export function RadioInputGroupInGenerator(
  props: Omit<Props, 'OptionComponent'>
) {
  return (
    <RadioGroup {...props} OptionComponent={RadioInputOption} inGenerator />
  )
}
