import React, { useState } from 'react'
import StyledSelect, { OptionTypeBase } from '@shared/components/StyledSelect'
import { useUserContext } from '@shared/contexts/UserContext'
import { FacilityPageProps } from '@shared/types/facility'
import { isSuperUser } from '@shared/utils/user'
import { DashboardSession } from '@app/api/payments'
import useRainforestDashboardSession from '@app/hooks/useRainforestDashboardSession'
import GeneralLedger from './GeneralLedger'

export default function Reports({ match }: FacilityPageProps) {
  const { orgId, facilityId } = match.params
  const { dashboardSession } = useRainforestDashboardSession({
    orgId,
    facilityId,
  })

  if (dashboardSession.tag === 'Loading') {
    return <div>Loading...</div>
  } else {
    return (
      <RainforestDashboards
        dashboardSession={dashboardSession.value}
        orgId={orgId}
        facilityId={facilityId}
      />
    )
  }
}

type DashboardType = 'Payments' | 'Deposits' | 'General Ledger'

function RainforestDashboards({
  dashboardSession,
  facilityId,
  orgId,
}: {
  dashboardSession: DashboardSession
  facilityId: string
  orgId: string
}) {
  const [dashboardType, setDashboardType] = useState<DashboardType>('Payments')

  return (
    <section>
      <div className="mx-[12px] mt-[24px] w-[225px]">
        <StyledSelect
          value={{ label: dashboardType, value: dashboardType }}
          onChange={(value: OptionTypeBase<DashboardType>) =>
            setDashboardType(value.value as DashboardType)
          }
          options={[
            { label: 'Payments', value: 'Payments' },
            { label: 'Deposits', value: 'Deposits' },
            { label: 'General Ledger', value: 'General Ledger' },
          ]}
        />
      </div>
      {dashboardType === 'Payments' && (
        <PaymentDashboard
          dashboardSession={dashboardSession}
          facilityId={facilityId}
        />
      )}
      {dashboardType === 'Deposits' && (
        <DepositDashboard
          dashboardSession={dashboardSession}
          facilityId={facilityId}
        />
      )}
      {dashboardType === 'General Ledger' && (
        <GeneralLedger orgId={orgId} facilityId={facilityId} />
      )}
    </section>
  )
}

function PaymentDashboard({
  dashboardSession,
  facilityId,
}: {
  dashboardSession: DashboardSession
  facilityId: string
}) {
  const { user } = useUserContext()

  return React.createElement(
    'rainforest-payment-report',
    dashboardConfiguration({
      dashboardSession,
      facilityId,
      showMetadata: isSuperUser(user),
    })
  )
}

function DepositDashboard({
  dashboardSession,
  facilityId,
}: {
  dashboardSession: DashboardSession
  facilityId: string
}) {
  return React.createElement(
    'rainforest-deposit-report',
    dashboardConfiguration({ dashboardSession, facilityId })
  )
}

function dashboardConfiguration({
  dashboardSession,
  facilityId,
  showMetadata,
}: {
  dashboardSession: DashboardSession
  facilityId: string
  showMetadata?: boolean
}) {
  return {
    'session-key': dashboardSession.sessionKey,
    'data-filters': `{"merchant_id": "${dashboardSession.merchantId}", "metadata[$.resident.facilityId]": "${facilityId}"}`,
    'details-view': 'fullscreen_overlay',
    'show-export-button': true,
    'hide-metadata-raw-json': true,
    'hide-metadata': !showMetadata || undefined,
    'display-header': undefined,
    'style-button-color': '#007580',
    'style-font-family': 'Inter',
    'style-font-size': '14px',
    'style-border-radius': '8px',
  }
}
