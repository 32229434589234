import { OptionTypeBase } from '@shared/components/StyledSelect'
import { DayOfWeek } from '@shared/types/timing'

export const specificDaysOption: OptionTypeBase = {
  label: 'Specific Days',
  value: 'specificDays',
}

export const everydayOption: OptionTypeBase<number> = {
  label: 'Every day',
  value: 1,
}

export const repetitionOptions: OptionTypeBase<number | string>[] = [
  everydayOption,
  specificDaysOption,
]

export const dayOfWeekOptions = [
  { label: 'M', value: DayOfWeek.DAY_OF_WEEK_MONDAY },
  { label: 'T', value: DayOfWeek.DAY_OF_WEEK_TUESDAY },
  { label: 'W', value: DayOfWeek.DAY_OF_WEEK_WEDNESDAY },
  { label: 'T', value: DayOfWeek.DAY_OF_WEEK_THURSDAY },
  { label: 'F', value: DayOfWeek.DAY_OF_WEEK_FRIDAY },
  { label: 'S', value: DayOfWeek.DAY_OF_WEEK_SATURDAY },
  { label: 'S', value: DayOfWeek.DAY_OF_WEEK_SUNDAY },
]

export const longDayOfWeekOptions = [
  { label: 'Monday', value: DayOfWeek.DAY_OF_WEEK_MONDAY },
  { label: 'Tuesday', value: DayOfWeek.DAY_OF_WEEK_TUESDAY },
  { label: 'Wednesday', value: DayOfWeek.DAY_OF_WEEK_WEDNESDAY },
  { label: 'Thursday', value: DayOfWeek.DAY_OF_WEEK_THURSDAY },
  { label: 'Friday', value: DayOfWeek.DAY_OF_WEEK_FRIDAY },
  { label: 'Saturday', value: DayOfWeek.DAY_OF_WEEK_SATURDAY },
  { label: 'Sunday', value: DayOfWeek.DAY_OF_WEEK_SUNDAY },
]

export const abbreviatedDayOfWeekOptions = [
  { label: 'Mon', value: DayOfWeek.DAY_OF_WEEK_MONDAY },
  { label: 'Tues', value: DayOfWeek.DAY_OF_WEEK_TUESDAY },
  { label: 'Wed', value: DayOfWeek.DAY_OF_WEEK_WEDNESDAY },
  { label: 'Thur', value: DayOfWeek.DAY_OF_WEEK_THURSDAY },
  { label: 'Fri', value: DayOfWeek.DAY_OF_WEEK_FRIDAY },
  { label: 'Sat', value: DayOfWeek.DAY_OF_WEEK_SATURDAY },
  { label: 'Sun', value: DayOfWeek.DAY_OF_WEEK_SUNDAY },
]
