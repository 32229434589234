export type ConfigurationType =
  | 'Buttons'
  | 'CustomContactDropdown'
  | 'CustomContactDropdownList'
  | 'HashTextarea'
  | 'HashTextInput'
  | 'StyledSelect'
  | 'YesNoButtons'
  | 'CheckboxGroup'

type ConfigurationTypeMap = Record<ConfigurationType, string>

const CONFIGURATION_TYPE_MAP: ConfigurationTypeMap = {
  Buttons: 'buttons',
  CustomContactDropdown: 'custom_contact_dropdown',
  CustomContactDropdownList: 'custom_contact_dropdown_list',
  HashTextInput: 'hash_text',
  HashTextarea: 'hash_textarea',
  StyledSelect: 'StyledSelect',
  YesNoButtons: 'boolean_buttons',
  CheckboxGroup: 'CheckboxGroup',
}

export const TYPES_WITH_OPTIONS_FIELD: ConfigurationType[] = [
  'Buttons',
  'StyledSelect',
  'CheckboxGroup',
]

export const TYPES_WITHOUT_REQUIRED_FIELD: ConfigurationType[] = [
  'CheckboxGroup',
]

export const TYPES_WITH_VARIABLE_NAME_FIELD: ConfigurationType[] = [
  'CustomContactDropdown',
  'CustomContactDropdownList',
]

const PREFIX_PATTERN = 'contactLabels\\.'
const SUFFIX_PATTERN = '\\.ids((\\[0\\])?)'
const VARIABLE_NAME_PATTERN = '((\\w|\\d|-|_)+)'

const VARIABLE_NAME_REGEXP = new RegExp(`^${VARIABLE_NAME_PATTERN}$`)
const VARIABLE_NAME_FROM_CONTACT_DROPDOWN_REGEXP = new RegExp(
  `^${PREFIX_PATTERN}(.*)${SUFFIX_PATTERN}$`
) // Get any value between PRE and POSTFIX without validation, UI will validate it.
const CONTACT_DROPDOWN_NAME__REGEXP = new RegExp(
  `^${PREFIX_PATTERN}${VARIABLE_NAME_PATTERN}${SUFFIX_PATTERN}$`
)

export function buildContactDropdownName({
  type,
  variableName,
}: {
  type: ConfigurationType
  variableName: string
}) {
  const prefix = PREFIX_PATTERN.replace('\\', '')
  if (type === 'CustomContactDropdown') {
    return `${prefix}${variableName}.ids[0]`
  }

  if (type === 'CustomContactDropdownList') {
    return `${prefix}${variableName}.ids`
  }

  return variableName
}

/** Allow empty variable name */
export function isValidVariableName(variableName: string) {
  return !variableName || VARIABLE_NAME_REGEXP.test(variableName)
}

export function getContactDropdownVariableName({
  name = '',
  type,
}: {
  name: string
  type: ConfigurationType
}) {
  let variableName = name
  if (
    type === 'CustomContactDropdown' ||
    type === 'CustomContactDropdownList'
  ) {
    variableName = name.replace(
      VARIABLE_NAME_FROM_CONTACT_DROPDOWN_REGEXP,
      '$1'
    )
  }

  return name === variableName ? '' : variableName
}

function getPatternOption(regexPattern: RegExp) {
  return {
    pattern: {
      value: regexPattern,
      message: `Invalid format, please use Variable Name to generate Name`,
    },
  }
}

export function getNameRegisterOptions(type: ConfigurationType) {
  if (
    type === 'CustomContactDropdown' ||
    type === 'CustomContactDropdownList'
  ) {
    return getPatternOption(CONTACT_DROPDOWN_NAME__REGEXP)
  }

  return {}
}

export function areSameTypes(
  type1: ConfigurationType | string,
  type2: ConfigurationType | string
) {
  const t1 = CONFIGURATION_TYPE_MAP[type1] || type1
  const t2 = CONFIGURATION_TYPE_MAP[type2] || type2

  return t1 === t2
}

export type ConfigurationOption = {
  label: string
  subLabel?: string
  value: ConfigurationType
  isDisabled?: boolean
}

export const CONFIGURATION_OPTIONS: ConfigurationOption[] = [
  {
    label: 'Text',
    subLabel: 'One line text field',
    value: 'HashTextInput',
  },
  {
    label: 'Textarea',
    subLabel: 'Multi-line text field',
    value: 'HashTextarea',
  },
  {
    label: 'Yes or No Buttons',
    subLabel: 'Buttons support Yes and No options',
    value: 'YesNoButtons',
  },
  {
    label: 'Buttons',
    subLabel: 'Buttons with customized options',
    value: 'Buttons',
  },
  {
    label: 'Select',
    subLabel: 'Single value select dropdown',
    value: 'StyledSelect',
  },
  {
    label: 'Contact Dropdown',
    subLabel: 'Select one contact',
    value: 'CustomContactDropdown',
  },
  {
    label: 'Contact Dropdown List',
    subLabel: 'Select multiple contacts',
    value: 'CustomContactDropdownList',
  },
  {
    label: 'Checkbox Group',
    subLabel: 'A group of related checkboxes, all under one heading',
    value: 'CheckboxGroup',
  },
]
