import { KeyboardEvent } from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { Control, Controller } from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { LabelAboveInput, SubLabel } from '@shared/components/Labels'
import {
  formatTime,
  fromDateMessageToDate,
  fromDateToDateMessage,
  fromTimeStringToTime,
} from '@shared/utils/date'

export default function DateTimePicker({
  control,
  id,
  datePickerProps = {},
  name,
  required = false,
  subLabel,
  title,
}: {
  control: Control
  datePickerProps?: Omit<ReactDatePickerProps, 'onChange' | 'selected'>
  id: string
  name: string
  required?: boolean
  subLabel?: SubLabel
  title: string
}) {
  const dateName = `${name}.date`
  const timeName = `${name}.time`

  return (
    <div className="mb-[32px]">
      <LabelAboveInput htmlFor={id} subLabel={subLabel}>
        {title}
      </LabelAboveInput>
      <div className="flex items-center">
        <Controller
          control={control}
          name={dateName}
          rules={{ required }}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              customInput={<CalendarInput />}
              portalId={'root'}
              id={id}
              name={dateName}
              {...datePickerProps}
              onChange={(d: Date | null) =>
                onChange(d ? fromDateToDateMessage(d) : null)
              }
              selected={fromDateMessageToDate(value)}
            />
          )}
        />
        <Controller
          control={control}
          name={timeName}
          render={({ field: { onChange, value } }) => (
            <div className="ml-[16px] w-[184px]">
              <BasicInput
                name={timeName}
                onChange={(ev: KeyboardEvent<HTMLInputElement>) => {
                  const timeString = ev.currentTarget.value
                  onChange(timeString ? fromTimeStringToTime(timeString) : null)
                }}
                type="time"
                defaultValue={
                  value && formatTime(value, { use24HourClock: true })
                }
              />
            </div>
          )}
        />
      </div>
    </div>
  )
}
