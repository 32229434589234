import { Task, TaskStatus } from '@augusthealth/models/com/august/protos/task'
import { useContext, useState } from 'react'
import * as TimeAgo from 'timeago.js'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import LoadingSpinner from '@shared/components/LoadingPopup'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import pluralize from '@shared/utils/pluralize'
import { rpCanAct } from '@shared/utils/task'
import { isAdmin } from '@shared/utils/user'
import { sendReminder } from '@app/api/tasks'
import ConfirmModal from '@app/components/ConfirmModal'
import HUD from '@app/components/HUD'

// https://github.com/hustcc/timeago.js/blob/master/src/lang/en_US.ts
const EN_US = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year']
function locale(diff: number, idx: number): [string, string] {
  // Second item in array is text for time in future
  // Which should not apply
  if (idx === 0) return ['You just shared a task.', '']
  const unit = EN_US[Math.floor(idx / 2)]
  return [
    `It’s been ${pluralize(unit, diff, true)} since you last shared a task.`,
    '',
  ]
}
TimeAgo.register('TIME_AGO_MESSAGE', locale)

function getReminderedTasks(tasks: Task[]) {
  return tasks.filter(rpCanAct)
}

function getReminderConfirmPopupTitle(count: number) {
  return `The Responsible Person has ${count} open ${pluralize(
    'task',
    count,
    false
  )}`
}

function getReminderConfirmPopupContent(timeAgoMsg: string) {
  return (
    <div className="mb-[12px] mt-[12px] text-center text-[16px] leading-[24px]">
      {timeAgoMsg && <div>{timeAgoMsg}</div>}
      <div>Send them an email reminder?</div>
    </div>
  )
}

function getLatestTimeString(strList: string[]) {
  if (strList.length) {
    const returnedList: string[] = [...strList]
    returnedList.sort()
    return returnedList[returnedList.length - 1]
  }

  return undefined
}

function getLastTimeAgoMsg(tasks: Task[]) {
  const lastSharedTimes = tasks.reduce((tList: string[], t) => {
    const { statusHistory } = t
    if (statusHistory) {
      const sharedTimes = statusHistory.reduce((sList: string[], h) => {
        const { endingStatus, modificationTime } = h
        if (
          endingStatus === TaskStatus.TASK_STATUS_ASSIGNED_TO_RP &&
          modificationTime
        ) {
          sList.push(modificationTime)
        }
        return sList
      }, [])
      const lastSharedTimeByTask = getLatestTimeString(sharedTimes)
      if (lastSharedTimeByTask) {
        tList.push(lastSharedTimeByTask)
      }
    }
    return tList
  }, [])

  const lastSharedTime = getLatestTimeString(lastSharedTimes)
  if (lastSharedTime) {
    return TimeAgo.format(lastSharedTime, 'TIME_AGO_MESSAGE')
  }

  return ''
}

type Props = {
  orgId: string
  facilityId: string
  personId: string
  tasks: Task[]
}

export default function ReminderConfirm(props: Props) {
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const { orgId, facilityId, personId, tasks } = props
  const [loading, setLoading] = useState(false)
  const [showReminderConfirm, setShowReminderConfirm] = useState(false)
  const [showReminderNotice, setShowReminderNotice] = useState(false)
  const sendReminderToRP = async () => {
    if (orgId && facilityId && personId) {
      try {
        setLoading(true)
        await sendReminder({ orgId, facilityId, personId })
        setShowReminderNotice(true)
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    }
  }
  const reminderedTask = getReminderedTasks(tasks)
  const reminderedTaskCount = reminderedTask.length
  // Only Admin can send the reminder to RP
  if (
    !isAdmin(user, { organizationId: orgId, facilityId }) ||
    reminderedTaskCount === 0
  ) {
    return null
  }

  return (
    <>
      <Button
        buttonStyle="secondary-outline"
        className="ml-[8px]"
        id="dend-reminder-button"
        onClick={() => setShowReminderConfirm(true)}
      >
        <span className="icon-before">
          <label className="visually-hidden">Send Reminder</label>
          <i className="fas fa-alarm-exclamation" />
        </span>
        Send Reminder
      </Button>
      {showReminderConfirm && (
        <ConfirmModal
          title={getReminderConfirmPopupTitle(reminderedTaskCount)}
          content={getReminderConfirmPopupContent(
            getLastTimeAgoMsg(reminderedTask)
          )}
          confirmButtonConfig={{
            buttonStyle: 'primary-fill',
            children: 'Send email reminder',
            onClick: async () => {
              await sendReminderToRP()
              setShowReminderConfirm(false)
            },
          }}
          denyButtonConfig={{
            onClick: () => setShowReminderConfirm(false),
          }}
        />
      )}
      {showReminderNotice && (
        <HUD onExpire={() => setShowReminderNotice(false)}>
          Reminder email sent
        </HUD>
      )}
      <LoadingSpinner loading={loading} />
    </>
  )
}
