import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { getOrElse } from '@shared/utils/loading'
import HUD from '@app/components/HUD'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import useOrganizations from '@app/hooks/useOrganizations'
import BillingCategoriesTable from './BillingCategoriesTable'
import FeesTable from './FeesTable'

export default function Settings() {
  const { orgId } = useParams<{
    facilityId: string
    orgId: string
  }>()
  const { organizations } = useOrganizations()
  const organization = getOrElse(organizations, []).find(
    (org) => org.id === orgId
  )
  const { currentFacility } = useCurrentFacility()
  const [hudText, setHudText] = useState('')

  if (!organization || !currentFacility || currentFacility.tag === 'Loading') {
    return <SimpleSpinner />
  }

  return (
    <>
      <FeesTable
        facility={currentFacility.value}
        organization={organization}
        onUpdate={setHudText}
      />
      <BillingCategoriesTable
        facility={currentFacility.value}
        organization={organization}
        onUpdate={setHudText}
      />
      {hudText && <HUD onExpire={() => setHudText('')}>{hudText}</HUD>}
    </>
  )
}
