import { AugustFieldFormConfigurationMap } from '@shared/constants/custom_field/type'
import { Contact_ContactRelationship as Relationship } from '@shared/types/contact'
import { AugustFieldType } from '@shared/types/custom_field'

const responsiblePersonPath = `contact[relationship=${Relationship.CONTACT_RELATIONSHIP_RESPONSIBLE_PERSON}]`
const responsiblePersonAddressPath = `${responsiblePersonPath}.address`
const generalPractitionerPath = `contact[relationship=${Relationship.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER}]`
const dentistListPath = `contact[relationship=${Relationship.CONTACT_RELATIONSHIP_DENTIST}][*]`
const emergencyListPath = `contact[relationship=${Relationship.CONTACT_RELATIONSHIP_C}][*]`
const financialPaymentListPath = `contact[relationship=${Relationship.CONTACT_RELATIONSHIP_FINANCIAL_PAYMENT_OF_CARE}][*]`
const funeralHomeListPath = `contact[relationship=${Relationship.CONTACT_RELATIONSHIP_FUNERAL_HOME}][*]`
const pharmacistListPath = `contact[relationship=${Relationship.CONTACT_RELATIONSHIP_PHARMACIST}][*]`

export const CONTACTS_INFO: AugustFieldFormConfigurationMap = {
  [AugustFieldType.AUGUST_FIELD_TYPE_RP_NAME]: {
    fieldLists: ['contact'],
    requiredFields: [responsiblePersonPath],
    fields: [
      {
        name: responsiblePersonPath,
        type: 'RpContactDropdown',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_RP_ADDRESS]: {
    fieldLists: ['contact'],
    requiredFields: [responsiblePersonAddressPath],
    fields: [
      {
        title: 'RESPONSIBLE PERSON ADDRESS',
        name: responsiblePersonAddressPath,
        type: 'RpAddressAutoComplete',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_GP_NAME]: {
    fieldLists: ['contact'],
    requiredFields: [generalPractitionerPath],
    fields: [
      {
        title: 'PRIMARY PHYSICIAN',
        name: generalPractitionerPath,
        type: 'contact_dropdown',
        placeholder: 'Select primary physician',
        unique: true,
        relationshipsToInclude: [
          Relationship.CONTACT_RELATIONSHIP_PHYSICIAN,
          Relationship.CONTACT_RELATIONSHIP_PHYSICIAN_ASSISTANT,
          Relationship.CONTACT_RELATIONSHIP_NURSE_PRACTITIONER,
        ],
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_PAY_1_NAME]: {
    fieldLists: ['contact'],
    requiredFields: [financialPaymentListPath],
    fields: [
      {
        title: 'PAYMENT OF CARE',
        description:
          'Select the representative who is responsible for payment of care',
        name: financialPaymentListPath,
        type: 'contact_dropdown_list',
        placeholder: 'Select contact for payment of care',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_DENTIST_1_NAME]: {
    fieldLists: ['contact'],
    requiredFields: [dentistListPath],
    fields: [
      {
        title: 'DENTIST',
        name: dentistListPath,
        type: 'contact_dropdown_list',
        relationshipsToInclude: [Relationship.CONTACT_RELATIONSHIP_CLINICIAN],
        placeholder: 'Select dentist',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_EMERGENCY_1_NAME]: {
    fieldLists: ['contact'],
    requiredFields: [emergencyListPath],
    fields: [
      {
        title: 'OTHER EMERGENCY CONTACTS',
        description:
          'Friend, relative or other parties to notify in case of emergency',
        name: emergencyListPath,
        type: 'contact_dropdown_list',
        placeholder: 'Select emergency contacts',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_FUNERAL_HOME_1_NAME]: {
    fieldLists: ['contact'],
    requiredFields: [funeralHomeListPath],
    fields: [
      {
        title: 'FUNERAL HOME',
        name: funeralHomeListPath,
        type: 'contact_dropdown_list',
        relationshipsToInclude: [
          Relationship.CONTACT_RELATIONSHIP_PROFESSIONAL,
        ],
        placeholder: 'Select funeral home',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_PHARMACIST_1_NAME]: {
    fieldLists: ['contact'],
    requiredFields: [pharmacistListPath],
    fields: [
      {
        title: 'PHARMACIST',
        name: pharmacistListPath,
        type: 'contact_dropdown_list',
        relationshipsToInclude: [Relationship.CONTACT_RELATIONSHIP_CLINICIAN],
        placeholder: 'Select pharmacist',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_HOSPITAL_NAME]: {
    fieldLists: ['hospital'],
    requiredFields: ['hospital.name'],
    fields: [
      {
        type: 'label',
        label: 'PREFERRED HOSPITAL FOR EMERGENCIES',
      },
      {
        title: 'HOSPITAL NAME',
        name: 'hospital.name',
        addressName: 'hospital.location[0].address',
        placeholder: 'eg. Mercy General',
        type: 'address_autocomplete',
        searchByName: true,
      },
      {
        title: 'HOSPITAL ADDRESS',
        name: 'hospital.location[0].address',
        type: 'address_autocomplete',
        disabledAutoComplete: true,
      },
    ],
  },
}
