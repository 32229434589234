import { OrgFacilitySettings } from '@augusthealth/models/com/august/protos/org_facility_settings'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Card from '@shared/components/Card'
import { SettingsLevel } from '@shared/utils/orgFacilitySettings'
import { deleteOrgFacilitySetting } from '@app/api/orgFacilitySettings'
import { getSettingsLevel } from '@app/pages/Tools/TaskDefinitions/helpers'
import styles from './styles.module.css'

type Props = {
  taskSetting: OrgFacilitySettings
  reloadTaskSettings: () => Promise<void>
}
export const SuppressedTaskCard = ({
  taskSetting,
  reloadTaskSettings,
}: Props) => {
  /*
    The disabled check will need to change once global tasks can be deleted
    https://linear.app/august-health/issue/AH-3633/allow-deleting-global-task-definitions
   */
  const assignmentLevel = getSettingsLevel(taskSetting)
  return (
    <Card className="mb-[8px] flex items-center justify-between p-[16px]">
      <div className={styles.titleRow}>
        <span className="font-medium">{taskSetting.key}&nbsp;</span>
        Suppressed at the {getSettingsLevel(taskSetting)}
      </div>
      <AsyncIconButton
        buttonStyle="tertiary-fill"
        onClick={async () => {
          await deleteOrgFacilitySetting(taskSetting)
          await reloadTaskSettings()
        }}
        buttonSize="small"
        disabled={assignmentLevel === SettingsLevel.GLOBAL_LEVEL}
      >
        Unsuppress Task
      </AsyncIconButton>
    </Card>
  )
}
