import { useContext, useEffect } from 'react'
import { requestJson } from '@shared/api/request'
import { pingUrl } from '@shared/api/urls'
import ExpectedErrorModal from '@shared/components/ErrorModal/ExpectedErrorModal'
import GlobalContext from '@shared/contexts/GlobalContext'
import { tw } from '@shared/utils/tailwind'

export default function MaintenanceScreen() {
  const { setError } = useContext(GlobalContext)

  useEffect(() => {
    const callPingApiTimeout = () => {
      return setTimeout(() => {
        ;(requestJson({ url: pingUrl }) as Promise<void>)
          .then(() => setError(undefined))
          .catch(() => {
            timer = callPingApiTimeout()
          })
      }, 60000)
    }
    let timer = callPingApiTimeout()

    return () => clearTimeout(timer)
  }, [])

  return (
    <ExpectedErrorModal
      id={`maintenance`}
      contentClassName={tw`flex h-full max-h-full w-full max-w-full items-center justify-center gap-4 rounded-none`}
      buttonProps={{
        className: 'hidden',
      }}
      title={'We are temporarily unavailable for scheduled maintenance'}
    >
      <img className={tw`inline-block`} src="/augusthealth_logo.png" alt="" />
      <div className={tw`mt-[32px] text-[12px] font-medium leading-[16px]`}>
        <a href="mailto:support@augusthealth.com">Contact support</a> if you
        need immediate help.
      </div>
    </ExpectedErrorModal>
  )
}
