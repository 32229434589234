import { LastUpdate } from '@augusthealth/models/com/august/protos/api/last_update'
import { Person } from '@shared/types/person'
import { formatTimeAgo } from '@shared/utils/date'
import { getLevelOfCare } from '@shared/utils/person'

export function formattedLevelOfCare(resident: Person) {
  const lvl = getLevelOfCare(resident)
  return lvl ? `Level ${lvl}` : '-'
}

export function lastUpdateTime(lastUpdate: LastUpdate) {
  return formatTimeAgo(lastUpdate.updatedAt)
}

export const lastUpdatedBy = (lastUpdate: LastUpdate) => {
  return lastUpdate.userName
}
