import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { listFacilities } from '@shared/api/facility'
import ButtonLink from '@shared/components/ButtonLink'
import SearchBox from '@shared/components/SearchBox'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { Loading } from '@shared/utils/loading'
import { twx } from '@shared/utils/tailwind'
import { listOrganizations } from '@app/api/organizations'
import HUD from '@app/components/HUD'
import tableStyles from '@app/components/Residents/list.module.css'
import {
  Table,
  TableContainer,
  TdWithExtraPadding,
  TrWithHoverEffect,
} from '@app/components/Table'
import styles from './facilityManagement.module.css'
import OrgSettings from './OrgSettings'

type Props = {
  history: {
    push: (path: string) => void
  }
}
export default function FacilityManagement({ history }: Props) {
  const [orgs, setOrgs] = useState<Loading<Organization[]>>({ tag: 'Loading' })
  const [allFacilities, setAllFacilities] = useState<Loading<Facility[]>>({
    tag: 'Loading',
  })
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [showOrgSettings, setShowOrgSettings] = useState<Organization>()
  const [showHUD, setShowHUD] = useState(false)

  useEffect(() => {
    void listOrganizations().then((res) => {
      setOrgs({ tag: 'Complete', value: res })
    })
  }, [showOrgSettings])

  useEffect(() => {
    if (orgs.tag === 'Complete') {
      void Promise.all(
        orgs.value.map((org) => listFacilities(org.id, 1000, true))
      ).then((results) => {
        const flattened = Array.prototype.concat.apply([], results)
        setAllFacilities({ tag: 'Complete', value: flattened })
      })
    }
  }, [orgs])

  if (orgs.tag === 'Loading' || allFacilities.tag === 'Loading') {
    return (
      <div className={tableStyles.content}>
        <div className="page-title flex items-center justify-between">
          <div>Facility Management</div>
          <div className="flex h-[40px] items-center">
            <SearchBox
              disabled
              onChange={setSearchFilter}
              placeholder="Org or facility name..."
              value={searchFilter}
            />
          </div>
        </div>
        <div className={tableStyles.container}>
          <TableContainer>
            <Table>
              <th className={`${tableStyles.tableHeader} pl-[8px]`}>
                <TdWithExtraPadding>
                  <Skeleton />
                </TdWithExtraPadding>
              </th>
              <tr>
                <TdWithExtraPadding>
                  <Skeleton />
                </TdWithExtraPadding>
              </tr>
              <tr>
                <TdWithExtraPadding>
                  <Skeleton />
                </TdWithExtraPadding>
              </tr>
              <tr>
                <TdWithExtraPadding>
                  <Skeleton />
                </TdWithExtraPadding>
              </tr>
              <tr>
                <TdWithExtraPadding>
                  <Skeleton />
                </TdWithExtraPadding>
              </tr>
            </Table>
          </TableContainer>
        </div>
      </div>
    )
  }

  const filteredOrgs = searchFilter
    ? orgs.value.filter((o) =>
        matchOrgOrFacilityName(o, allFacilities.value, searchFilter)
      )
    : orgs.value

  return (
    <div className={tableStyles.content}>
      <div className="page-title flex items-center justify-between">
        <div>Facility Management</div>
        <div className="flex h-[40px] items-center">
          <SearchBox
            onChange={setSearchFilter}
            placeholder="Org or facility name..."
            value={searchFilter}
          />
        </div>
      </div>
      <div className={tableStyles.container}>
        <TableContainer>
          <Table>
            {filteredOrgs.map((org) => (
              <>
                {showOrgSettings?.id === org.id && (
                  <OrgSettings
                    org={org}
                    setShowHUD={setShowHUD}
                    onClose={() => {
                      setShowOrgSettings(undefined)
                    }}
                  />
                )}
                <tbody key={`fm-${org.id}`}>
                  <th className={`${tableStyles.tableHeader} pl-[8px]`}>
                    <ButtonLink
                      className={styles.thHover}
                      onClick={() => {
                        setShowOrgSettings(org)
                      }}
                    >
                      <div>
                        {org.name}
                        {!org.isActive && (
                          <span className="ml-[8px] uppercase text-gray-07">
                            (Inactive)
                          </span>
                        )}
                      </div>
                      <i className="fas fa-fw fa-gear mr-[8px]" />
                    </ButtonLink>
                  </th>
                  {allFacilities.value
                    .filter((facility) => facility.orgId === org.id)
                    .map((facility) => {
                      const searchMatch =
                        searchFilter &&
                        facility
                          .name!.toLowerCase()
                          .includes(searchFilter.toLowerCase())
                      const isActive = facility.isActive ? undefined : (
                        <span className={'text-button-quaternary-color'}>
                          {' '}
                          (Inactive)
                        </span>
                      )
                      return (
                        <TrWithHoverEffect
                          className={twx({
                            [tableStyles.activeRow]: searchMatch,
                          })}
                          key={`fm-f-${facility.id}`}
                          onClick={() => {
                            history.push(
                              `/tools/orgs/${org.id}/facilities/${facility.id}/metrics`
                            )
                          }}
                        >
                          <TdWithExtraPadding
                            className={twx({ 'font-semibold': searchMatch })}
                          >
                            {facility.name} {isActive}
                          </TdWithExtraPadding>
                        </TrWithHoverEffect>
                      )
                    })}
                </tbody>
                {showHUD && (
                  <HUD
                    onExpire={() => {
                      setShowHUD(false)
                    }}
                  >
                    <div className="text-[16px] font-semibold leading-[24px]">
                      Org Settings Updated
                    </div>
                  </HUD>
                )}
              </>
            ))}
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

function matchOrgOrFacilityName(
  org: Organization,
  allFacilities: Facility[],
  searchFilter: string
) {
  const orgNameMatch = org
    .name!.toLowerCase()
    .includes(searchFilter.toLowerCase())
  const orgFacilities = allFacilities.filter(
    (facility) => facility.orgId === org.id
  )
  const facilityNameMatch = orgFacilities.some((f) =>
    f.name!.toLowerCase().includes(searchFilter.toLowerCase())
  )

  return orgNameMatch || facilityNameMatch
}
