import { clearCareAppUserAccountCache } from '@careapp/cache'
import { Auth } from 'aws-amplify'
import localForage from 'localforage'
import { useEffect } from 'react'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import { clearEmarRuntimeCache } from '@shared/utils/emar'

export async function logout(redirectTo?: string) {
  return Auth.signOut().finally(() => {
    void Promise.allSettled([
      clearCareAppUserAccountCache(),
      clearEmarRuntimeCache(),
      clearLastUserActivity(),
    ]).then(() => {
      if (redirectTo) {
        window.location.href = redirectTo
      } else {
        window.location.reload()
      }
    })
  })
}

export async function logoutAndRedirect(newHref: string) {
  await logout(newHref)
}

export async function isLoggedIn() {
  try {
    await Auth.currentAuthenticatedUser()
    return true
  } catch {
    return false
  }
}

export async function isLoggedInAs(): Promise<{
  loggedIn: boolean
  currentUserEmail?: string
}> {
  try {
    const currentSession = await Auth.currentSession()
    const decodedIdentityToken = currentSession.getIdToken().decodePayload()
    return {
      loggedIn: true,
      currentUserEmail: decodedIdentityToken['email']
        ? String(decodedIdentityToken['email'])
        : undefined,
    }
  } catch {
    return { loggedIn: false }
  }
}

export async function setLastUserActivity(): Promise<void> {
  const current = Date.now()
  await localForage.setItem('lastUserActivity', `${current}`)
}

async function getLastUserActivity(): Promise<number | null> {
  const raw = await localForage.getItem<string>('lastUserActivity')
  return raw === null ? null : parseInt(raw)
}

export async function clearLastUserActivity(): Promise<void> {
  await localForage.removeItem('lastUserActivity')
}

export function ValidateUserSession({
  interval,
  sessionInactivityExpiration,
  failureRedirectUrl,
}: {
  interval: number
  sessionInactivityExpiration: number
  failureRedirectUrl?: string
}) {
  useEffect(() => {
    const refreshTokenTimer = window.setInterval(() => {
      // if there hasn't been user activity in X hours, invalidate session
      void getLastUserActivity().then((lastActivity) => {
        if (
          lastActivity === null ||
          lastActivity + sessionInactivityExpiration < Date.now()
        ) {
          // then it's an invalid session, logout
          ErrorMonitoring.capture({
            error: 'ValidateUserSession logging user out',
            level: 'log',
          })
          void logout(failureRedirectUrl)
        }
      })
    }, interval)
    return () => window.clearInterval(refreshTokenTimer)
  }, [sessionInactivityExpiration, interval])
  return null
}
