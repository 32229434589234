import { MedPass } from '@augusthealth/models/com/august/protos/api/med_pass'
import { format, getDayOfYear } from 'date-fns'
import { some } from 'lodash'
import { useContext, useState } from 'react'
import { match, P } from 'ts-pattern'
import { listForOrderGroup } from '@shared/api/medication_administration'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import Icon from '@shared/components/Icon'
import { MedOrderPill } from '@shared/components/MedOrderPill'
import Pill from '@shared/components/Pill'
import GlobalContext from '@shared/contexts/GlobalContext'
import {
  emarOrgFacilityMedpassPersonPath,
  medicationEditUrl,
} from '@shared/routes'
import { DateMessage } from '@shared/types/date'
import { EmarMedicationAdministration } from '@shared/types/emar'
import { AdministrationStatus } from '@shared/types/medication_administration'
import { Person } from '@shared/types/person'
import { fromDateMessageToDate } from '@shared/utils/date'
import { LOADING, Loading } from '@shared/utils/loading'
import { administrationDoseDescriptor } from '@shared/utils/medicationAdministration'
import { tw, twx } from '@shared/utils/tailwind'
import Link from '@app/components/Link'
import { NameTd, Table, TrWithHoverEffect } from '@app/components/Table'
import TableHeader from '@app/components/Table/TableHeader'
import useMedPasses from '@app/hooks/useMedPasses'
import DetailsModal from './MedicationAdministrationHistoryDetailsModal'

type Props = {
  person: Person
  orderGroupId: string
  facilityTimeZone: string | undefined
  use24HourClock: boolean
}
export default function MedicationAdministrationHistory({
  person,
  orderGroupId,
  facilityTimeZone,
  use24HourClock,
}: Props) {
  const [includeDeactivated, setIncludeDeactivated] = useState(true)
  const [selectedMedAdministration, setSelectedMedAdministration] =
    useState<EmarMedicationAdministration | null>()
  const [historyHasBeenRequested, setHistoryHasBeenRequested] = useState(false)
  const [medAdministrationHistory, setMedAdministrationHistory] = useState<
    Loading<EmarMedicationAdministration[]>
  >({ tag: 'Loading' })
  const { medPasses } = useMedPasses({
    facility: { orgId: person.orgId, id: person.facilityId },
  })

  const { setError } = useContext(GlobalContext)

  const loadAdministrations = (isExpanded: boolean) => {
    if (isExpanded && !historyHasBeenRequested) {
      setHistoryHasBeenRequested(true)
      listForOrderGroup(person, orderGroupId)
        .then((value) =>
          setMedAdministrationHistory({ tag: 'Complete', value })
        )
        .catch(setError)
    }
  }

  const statusLabel = (status: AdministrationStatus | undefined) => {
    const Status = AdministrationStatus
    return match(status)
      .with(
        P.union(P.nullish, Status.ADMINISTRATION_STATUS_UNSPECIFIED),
        () => null
      )
      .with(Status.ADMINISTRATION_STATUS_ADMINISTERED, () => (
        <StatusBadge title="Administered" iconName="check" color="lime-500" />
      ))
      .with(Status.ADMINISTRATION_STATUS_NOT_ADMINISTERED, () => (
        <StatusBadge
          title="Marked as exception"
          iconName="ban"
          color="pill-warning"
        />
      ))
      .with(Status.ADMINISTRATION_STATUS_ON_HOLD, () => (
        <StatusBadge title="On hold" iconName="pause" />
      ))
      .with(Status.ADMINISTRATION_STATUS_ENTERED_IN_ERROR, () => (
        <StatusBadge title="Entered in error" iconName="exclamation" />
      ))
      .with(Status.UNRECOGNIZED, () => (
        <StatusBadge title="Unrecognized" iconName="question" />
      ))
      .exhaustive()
  }

  const medPassOccurrenceLink = (
    medAdministration: EmarMedicationAdministration,
    medPasses: MedPass[]
  ) => {
    const { medPassId, medPassOccurrenceId } = medAdministration
    const medPass = medPasses.find((medPass) => medPass.id === medPassId)
    const url = emarOrgFacilityMedpassPersonPath(
      person.orgId,
      person.facilityId,
      medPassOccurrenceId,
      person.id
    )
    return (
      <a href={url}>
        {format(
          fromDateMessageToDate(medAdministration.medPassDate)!,
          'EE, LLL dd'
        )}{' '}
        - {medPass?.name ?? medAdministration.id}
      </a>
    )
  }

  const anyLoading = [
    [LOADING, P.any],
    [P.any, LOADING],
  ] as const

  const allComplete = [{ tag: 'Complete' }, { tag: 'Complete' }] as const

  const isEvenDayOfYear = (medPassDate: DateMessage) => {
    const date = fromDateMessageToDate(medPassDate)
    if (date) {
      return getDayOfYear(date) % 2 === 0
    } else {
      return false
    }
  }

  const filterDeactivated = (
    medAdministration: EmarMedicationAdministration
  ): boolean => {
    return includeDeactivated ? true : medAdministration.isActive
  }

  return (
    <div data-testid={'medication-administration-history-panel'}>
      <CollapsibleCard
        cardTitle="Medication Administration History (super user only)"
        defaultExpanded={false}
        onExpandClick={loadAdministrations}
        expandedIcon="fa-minus"
        collapsedIcon="fa-plus"
      >
        <label className="flex gap-2">
          <input
            type="checkbox"
            checked={includeDeactivated}
            onChange={() => setIncludeDeactivated(!includeDeactivated)}
          />
          <span>Include deactivated administrations</span>
        </label>
        {match([medAdministrationHistory, medPasses])
          .with(...anyLoading, () => <p>Loading....</p>)
          .with(allComplete, ([administrations, medPasses]) => (
            <Table>
              <TableHeader
                headers={[
                  { label: 'Status', className: 'w-fit' },
                  { label: 'Dose', className: 'w-fit' },
                  { label: 'PRN', className: 'w-fit' },
                  { label: 'Med Pass', className: 'w-1/3' },
                  { label: 'Med Order', className: 'w-fit' },
                  { label: 'Details', className: 'w-1/5' },
                ]}
              />
              <tbody>
                {administrations.value
                  .filter(filterDeactivated)
                  .map((medAdministration) => (
                    <TrWithHoverEffect
                      disableHoverEffect={true}
                      key={medAdministration.id}
                      className={twx({
                        'line-through opacity-50': !medAdministration.isActive,
                        'bg-gray-11': isEvenDayOfYear(
                          medAdministration.medPassDate
                        ),
                      })}
                    >
                      <NameTd>{statusLabel(medAdministration.status)}</NameTd>
                      <NameTd>
                        <Pill shape="rectangle" className={tw`normal-case`}>
                          {administrationDoseDescriptor(medAdministration)}
                        </Pill>
                      </NameTd>
                      <NameTd>
                        {medAdministration.scheduled ? null : (
                          <MedOrderPill type="prn" />
                        )}
                      </NameTd>
                      <NameTd>
                        {medPassOccurrenceLink(
                          medAdministration,
                          medPasses.value
                        )}
                      </NameTd>
                      <NameTd>
                        <Link
                          to={medicationEditUrl({
                            orgId: person.orgId,
                            facilityId: person.facilityId,
                            residentId: person.id,
                            medicationOrderId:
                              medAdministration.medicationOrderId,
                          })}
                        >
                          {medAdministration.medicationOrderId}
                        </Link>
                      </NameTd>
                      <NameTd>
                        <AsyncIconButton
                          initialIcon={
                            some(medAdministration.conflicts)
                              ? 'diamond-exclamation'
                              : undefined
                          }
                          className="w-full"
                          type="button"
                          buttonStyle="secondary-outline"
                          buttonSize="small"
                          onClick={() =>
                            setSelectedMedAdministration(medAdministration)
                          }
                        >
                          Details
                        </AsyncIconButton>
                      </NameTd>
                    </TrWithHoverEffect>
                  ))}
              </tbody>
            </Table>
          ))
          .exhaustive()}
      </CollapsibleCard>
      {selectedMedAdministration && facilityTimeZone && (
        <DetailsModal
          facilityTimeZone={facilityTimeZone}
          medAdministration={selectedMedAdministration}
          onClose={() => setSelectedMedAdministration(null)}
          use24HourClock={use24HourClock}
        />
      )}
    </div>
  )
}

const StatusBadge = ({
  title,
  iconName,
  color = 'gray-07',
}: {
  title: string
  iconName: string
  color?: string
}) => {
  return (
    <div
      className={twx(
        `inline-flex size-8 flex-col items-center justify-center rounded-full border-[2px] bg-${color} border-white shadow-md`
      )}
    >
      <Icon
        title={title}
        name={iconName}
        className={twx('text-lg text-white')}
      />
    </div>
  )
}
