// https://formidable.com/open-source/victory/guides/themes/
import CSS from 'csstype'
import { PaddingOrCallback } from 'victory-core'

export const BASE_FILL_COLOR = '#82D1D9'
export const BASE_FONT = 'Inter, sans-serif'
export const BASE_TEXT_COLOR = '#808080'
export const TRANSPARENT = 'transparent'

// https://graphmaker.imageonline.co/areachart.php
export const STROKE_COLORS = [
  'rgba(0, 176, 255, 0.5)',
  'rgba(227, 155, 209, 0.5)',
]
export const AREA_COLORS = [
  'rgba(0, 176, 255, 0.1)',
  'rgba(227, 155, 209, 0.1)',
]

export const NO_GRID: CSS.Properties = {
  fill: 'none',
  stroke: 'none',
  pointerEvents: 'painted',
}

// https://github.com/FormidableLabs/victory/blob/main/packages/victory-core/src/victory-theme/material.tsx
export const DEFAULT_GRID: CSS.Properties = {
  fill: 'none',
  stroke: '#ECEFF1',
  strokeDasharray: '10, 5',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  pointerEvents: 'painted',
}

// Color can be a string or a function
type COLOR = (({ datum }: { datum: object }) => string) | string

export function getDefaultTheme({
  axisLabelPadding = 18,
  baseFillColor = BASE_FILL_COLOR,
  baseTextColor = BASE_TEXT_COLOR,
  fontSize = 5,
  showGrid = false,
  tickLabelsFontSize = 4,
  tickLabelsPadding = 5,
}: {
  axisLabelPadding?: PaddingOrCallback
  baseFillColor?: COLOR
  baseTextColor?: COLOR
  fontSize?: number
  showGrid?: boolean
  tickLabelsFontSize?: number
  tickLabelsPadding?: PaddingOrCallback
}) {
  // Labels
  const baseLabelStyles = {
    fontFamily: BASE_FONT,
    fontSize,
    letterSpacing: 'normal',
    fill: baseTextColor,
    stroke: TRANSPARENT,
    fontWeight: 600,
  }

  // Put it all together...
  return {
    axis: {
      style: {
        axisLabel: {
          fontFamily: BASE_FONT,
          fontSize,
          padding: axisLabelPadding,
        },
        axis: {
          strokeWidth: 0,
        },
        grid: showGrid ? DEFAULT_GRID : NO_GRID,
        tickLabels: {
          // Month labels at the bottom
          ...baseLabelStyles,
          padding: tickLabelsPadding,
          textTransform: 'uppercase',
          fontSize: tickLabelsFontSize,
        },
      },
    },
    bar: {
      style: {
        data: {
          fill: baseFillColor,
          strokeWidth: 0,
        },
        labels: {
          // Percentage label at the top of Bar
          ...baseLabelStyles,
          padding: 2,
        },
      },
    },
    legend: {
      colorScale: STROKE_COLORS,
      gutter: 10,
      orientation: 'horizontal',
      titleOrientation: 'top',
      style: {
        data: {
          type: 'minus',
        },
        labels: baseLabelStyles,
        title: {
          ...baseLabelStyles,
          padding: 5,
        },
      },
    },
    tooltip: {
      style: {
        fontFamily: BASE_FONT,
        fontSize,
        padding: 0,
        pointerEvents: 'none',
      },
      flyoutStyle: {
        fontFamily: BASE_FONT,
        strokeWidth: 0,
        pointerEvents: 'none',
      },
    },
  }
}
