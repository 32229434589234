import { AppraisalSettings_AppraisalCategory } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { TaskTemplateInfo } from '@augusthealth/models/com/august/protos/task'
import { useContext } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import Card from '@shared/components/Card'
import { SectionDisclosure } from '@shared/components/Disclosure'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import useLocalStorage from '@shared/hooks/useLocalStorage'
import { Person } from '@shared/types/person'
import { RoutineOrder } from '@shared/types/routine_order'
import { AsyncResult, getOrElse, mapAsyncResult } from '@shared/utils/loading'
import { isDiscardedRoutine, routineHasTasks } from '@shared/utils/routine'
import { effectiveStatus } from '@shared/utils/routineOrder'
import PersonContext from '@app/contexts/PersonContext'
import useAppraisalSettingsCategories from '@app/hooks/useAppraisalSettingsCategories'
import useRoutines from '@app/hooks/useRoutines'
import { NoteCardSkeleton } from '../Notes/NoteCardSkeleton'
import { NoActiveRoutines } from './components/NoActiveRoutines'
import { NoRoutines } from './components/NoRoutines'
import { RoutineCard } from './components/RoutineCard'
import RoutinePageTitle from './components/RoutinePageTitle'
import {
  omitDiscardedDuplicates,
  partitionByVisibility,
  routineIsVisible,
  sortRelevantRoutines,
} from './helpers'
import { RoutineOrderList } from './RoutineOrders'

export default function RoutinesWrapper() {
  const { configuredTasks = { tag: 'Loading' } } = useContext(
    CurrentFacilityContext
  )
  const { person } = useContext(PersonContext)
  const { categories } = useAppraisalSettingsCategories({
    person,
  })

  if (person === undefined) {
    return null
  }

  return (
    <Routines
      person={person}
      configuredTasks={getOrElse(configuredTasks, [])}
      categories={getOrElse(categories, [])}
    />
  )
}

export function Routines({
  person,
  configuredTasks,
  categories,
}: {
  person: Person
  configuredTasks: TaskTemplateInfo[]
  categories: AppraisalSettings_AppraisalCategory[]
}) {
  const { routines, routineOrders, refreshRoutines, refreshRoutineOrders } =
    useRoutines({ person })
  const displayableRoutineOrders = mapAsyncResult(
    routineOrders,
    (routineOrders) =>
      routineOrders.filter((order) => {
        if (order.routineType.assessment) {
          return (
            order.routineType.assessment.tasks &&
            order.routineType.assessment.tasks.length > 0
          )
        }
        return true
      })
  )

  const activeRoutineOrders = mapAsyncResult(
    displayableRoutineOrders,
    (routineOrders) =>
      routineOrders.filter((order) => effectiveStatus(order) === 'active')
  )
  const expiredRoutineOrders = mapAsyncResult(
    displayableRoutineOrders,
    (routineOrders) =>
      routineOrders.filter((order) => effectiveStatus(order) === 'expired')
  )
  const discontinuedRoutineOrders = mapAsyncResult(
    displayableRoutineOrders,
    (routineOrders) =>
      routineOrders.filter((order) => effectiveStatus(order) === 'discontinued')
  )
  const unscheduledRoutineOrders = mapAsyncResult(
    displayableRoutineOrders,
    (routineOrders) =>
      routineOrders.filter((order) => effectiveStatus(order) === 'discarded')
  )
  const routinesToDisplay = (
    routines: AsyncResult<RoutineOrder[], unknown>
  ): boolean => routines.tag === 'Complete' && !!routines.value.length

  const noRoutineOrders = !routinesToDisplay(displayableRoutineOrders)

  // NOTE: 'Discarded' in our code means 'Unscheduled' to the user
  const [storedShowDiscardedRoutines, setShowDiscardedRoutines] =
    useLocalStorage('showDiscardedRoutines', false)
  const showDiscardedRoutines = getOrElse(storedShowDiscardedRoutines, false)

  if (routines.tag === 'Loading' || routineOrders.tag === 'Loading') {
    return (
      <>
        <RoutinePageTitle person={person} />
        <NoteCardSkeleton />
      </>
    )
  }

  const someDiscardedRoutines = routines.value.some((r) =>
    isDiscardedRoutine(r)
  )

  // filter routines by relevant statuses and presence of tasks
  const routinesWithTasks = routines.value
    .filter(routineIsVisible)
    .filter(routineHasTasks)

  // partition result by discarded and active status
  const { visibleRoutines, hiddenRoutines } =
    partitionByVisibility(routinesWithTasks)

  // sort each partition and merge into single array
  const allRoutines = [
    ...sortRelevantRoutines(hiddenRoutines),
    ...sortRelevantRoutines(visibleRoutines),
  ]

  // remove routines that already exist as active
  const displayableRoutines = omitDiscardedDuplicates(allRoutines)

  if (displayableRoutines.length === 0 && noRoutineOrders) {
    return <NoRoutines person={person} configuredTasks={configuredTasks} />
  }

  if (
    displayableRoutines.every((r) => isDiscardedRoutine(r)) &&
    !showDiscardedRoutines &&
    noRoutineOrders
  ) {
    return (
      <NoActiveRoutines
        person={person}
        handleSchedulePriorRoutine={() => setShowDiscardedRoutines(true)}
      />
    )
  }

  return (
    <div className={'mb-[32px] flex flex-col gap-4'}>
      <RoutinePageTitle person={person} />
      <RoutineOrderList
        categories={categories}
        routineOrders={activeRoutineOrders}
        person={person}
        onRoutineOrderChange={refreshRoutineOrders}
      />
      {(displayableRoutines.length || someDiscardedRoutines) && (
        <Card className={'mb-[32px] flex-col p-[8px]'}>
          {displayableRoutines.map((r) => (
            <RoutineCard
              routine={r}
              key={`routine-${r.id}`}
              person={person}
              onUpdate={refreshRoutines}
              isHidden={!showDiscardedRoutines && isDiscardedRoutine(r)}
            />
          ))}
          {someDiscardedRoutines && (
            <div>
              <ButtonLink
                onClick={() => setShowDiscardedRoutines(!showDiscardedRoutines)}
                className="ml-[10px] mt-[8px] p-[12px] text-left leading-[20px]"
              >
                {showDiscardedRoutines ? 'Hide' : 'Show'} unscheduled routines…
              </ButtonLink>
            </div>
          )}
        </Card>
      )}
      {routinesToDisplay(unscheduledRoutineOrders) && (
        <SectionDisclosure summary="Unscheduled Routines">
          <RoutineOrderList
            categories={categories}
            routineOrders={unscheduledRoutineOrders}
            person={person}
            onRoutineOrderChange={refreshRoutineOrders}
          />
        </SectionDisclosure>
      )}
      {routinesToDisplay(expiredRoutineOrders) && (
        <SectionDisclosure summary="Expired Routines">
          <RoutineOrderList
            categories={categories}
            routineOrders={expiredRoutineOrders}
            person={person}
            onRoutineOrderChange={refreshRoutineOrders}
          />
        </SectionDisclosure>
      )}
      {routinesToDisplay(discontinuedRoutineOrders) && (
        <SectionDisclosure summary="Discontinued Routines">
          <RoutineOrderList
            categories={categories}
            routineOrders={discontinuedRoutineOrders}
            person={person}
            onRoutineOrderChange={refreshRoutineOrders}
          />
        </SectionDisclosure>
      )}
    </div>
  )
}
