import { EmbeddedUrlResponse } from '@augusthealth/models/com/august/protos/api/esign'
import { PdfProj } from '@augusthealth/models/com/august/protos/signable_form'
import { useContext, useState } from 'react'
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { Button } from '@shared/components/baseMui/Button'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import LoadingPopup from '@shared/components/LoadingPopup'
import StyledSelect, {
  OptionTypeBase,
  StyledMultiSelect,
} from '@shared/components/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { SignerRole } from '@shared/types/snapshot'
import { createForm, deleteForm } from '@app/api/form'
import HelloSign from '@app/components/generic/HelloSign'
import { DEFAULT_HELLO_SIGN_DATA } from '@app/components/generic/HelloSign/defaults'
import HUD from '@app/components/HUD'
import ImportUploader from '@app/components/ImportUploader'
import PersonPageTitle from '@app/components/PersonPageTitle'
import { setHelloSignFullScreen } from './HelloSignTemplate'
import {
  assignSignerDescriptionsFromHelloSignAndRoles,
  assignSignersFromRoles,
  createFormData,
} from './helpers'
import { useSignableFormContext } from './SignableFormContext'

export interface RoleDescription {
  role: string
  description: string
}

export type CreatePdfTemplateForm = {
  name: string
  description?: string
  file: File | undefined
  template?: OptionTypeBase
  roles: OptionTypeBase[]
  roleDescriptions: RoleDescription[]
  numberOfExternalSigners?: number
  projectionType: OptionTypeBase<PdfProj>
}

const DEFAULT_FORM_DATA: CreatePdfTemplateForm = {
  name: '',
  description: '',
  file: undefined,
  projectionType: {
    label: PdfProj.PDF_PROJ_FROM_TEMPLATE,
    value: PdfProj.PDF_PROJ_FROM_TEMPLATE,
  },
  template: { label: '', value: '' },
  roles: [],
  roleDescriptions: [],
}

export const ADMIN = 'admin'
export const ADMIN_2 = 'admin 2'
export const RP = 'rp'
export const RESIDENT = 'resident'
export const EXTERNAL = 'external'

// cannot map directly to SignerRoles since the admin role is repeated
const SIGNER_OPTIONS = [
  { label: 'Admin', value: ADMIN },
  { label: 'Admin 2', value: ADMIN_2 },
  { label: 'RP', value: RP },
  { label: 'Resident', value: RESIDENT },
  { label: 'External', value: EXTERNAL },
]

// used to convert between SignerRoles and and SIGNER_OPTIONS
export const ROLE_MAP = {
  [ADMIN]: SignerRole.SIGNER_ROLE_ADMIN,
  [ADMIN_2]: SignerRole.SIGNER_ROLE_ADMIN,
  [RESIDENT]: SignerRole.SIGNER_ROLE_RESIDENT,
  [RP]: SignerRole.SIGNER_ROLE_RESPONSIBLE_PARTY,
  [EXTERNAL]: SignerRole.SIGNER_ROLE_EXTERNAL,
}

export default function CreatePdfTemplate() {
  const { reloadSignableForms: reload, signableForms } =
    useSignableFormContext('All Templates')
  const formList =
    (signableForms.tag === 'Complete' && signableForms.value) || []
  const [showNotice, setShowNotice] = useState<boolean>(false)
  const [creating, setCreating] = useState<boolean>(false)
  const [helloSignData, setHelloSignData] = useState<EmbeddedUrlResponse>(
    DEFAULT_HELLO_SIGN_DATA
  )
  const [newFormId, setNewFormId] = useState<number | undefined>()
  const { clientId, url } = helloSignData
  const { setError } = useContext(GlobalContext)

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    control,
    formState: { errors },
    reset,
    resetField,
  } = useForm<CreatePdfTemplateForm>({ defaultValues: DEFAULT_FORM_DATA })
  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'roleDescriptions',
  })

  const file = watch('file')
  const roles = watch('roles')
  const name = watch('name')

  const onSubmit: SubmitHandler<CreatePdfTemplateForm> = (data) => {
    setCreating(true)
    const formData = createFormData(data, ROLE_MAP)
    createForm(formData)
      .then((res) => {
        reset(DEFAULT_FORM_DATA)
        setNewFormId(res.data.id)
        if (res.data.embedded) {
          setHelloSignData(res.data.embedded)
        } else {
          setShowNotice(true)
        }
      })
      .catch(setError)
      .finally(() => setCreating(false))
  }

  const resetStates = () => {
    setNewFormId(undefined)
    setHelloSignData(DEFAULT_HELLO_SIGN_DATA)
  }

  const onCreateTemplate = () => {
    void reload().then(() => {
      resetStates()
      setShowNotice(true)
    })
  }

  const onCancel = () => {
    deleteForm({ id: newFormId })
      .then(() => {
        resetStates()
        void reload()
      })
      .catch(setError)
  }

  if (clientId && url) {
    return (
      <HelloSign
        clientId={clientId}
        url={url}
        onCancel={onCancel}
        onCreateTemplate={onCreateTemplate}
        onErrorClose={resetStates}
        init={setHelloSignFullScreen}
      />
    )
  }

  const onExpire = () => setShowNotice(false)
  const creatingNotice = showNotice ? (
    <HUD onExpire={onExpire}>Template {name} created</HUD>
  ) : undefined

  if (signableForms.tag === 'Loading') {
    return <>Loading...</>
  }

  const templateOptions: OptionTypeBase[] = formList
    .filter((f) => f.hellosign?.subtemplateOf === undefined)
    .map((item) => {
      const { id, name } = item
      return {
        label: `${id}. ${name}`,
        value: id as string,
      }
    })

  const projectionOptions = (Object.keys(PdfProj) as Array<PdfProj>)
    .filter(
      (v) => v !== PdfProj.UNRECOGNIZED && v !== PdfProj.PDF_PROJ_UNSPECIFIED
    )
    .map((v) => {
      return {
        label: v,
        value: v,
      }
    })

  const handleChangeTemplate = (value: OptionTypeBase) => {
    if (value) {
      const templateForm = formList.find((f) => f.id === value.value)
      if (templateForm) {
        const { projectionType } = templateForm
        if (projectionType) {
          const projectionOption = projectionOptions.find(
            (o) => o.value === projectionType
          )
          projectionOption
            ? setValue('projectionType', projectionOption)
            : setValue('projectionType', DEFAULT_FORM_DATA.projectionType)
        }
        const signers = templateForm?.signers
        if (signers) {
          const { numberOfExternalSigners, roles } = assignSignersFromRoles(
            signers,
            ROLE_MAP
          )
          const signerOptions = roles.map((s) => {
            return SIGNER_OPTIONS.find((o) => o.value === s)!
          })
          setValue('roles', signerOptions)
          setValue('numberOfExternalSigners', numberOfExternalSigners)

          const helloSignSigners =
            templateForm?.hellosign?.embeddedTemplateRequest?.signers

          const roleDescriptions =
            assignSignerDescriptionsFromHelloSignAndRoles(
              roles,
              numberOfExternalSigners,
              helloSignSigners,
              ROLE_MAP
            )
          setValue('roleDescriptions', roleDescriptions)
        }
      }
    } else {
      resetField('roles')
      resetField('roleDescriptions')
      resetField('numberOfExternalSigners')
      resetField('projectionType')
    }
  }

  const handleChangeSigners = (values: OptionTypeBase[]) => {
    // all roles have been cleared
    if (values.length === 0) {
      remove()
      setValue('numberOfExternalSigners', undefined)
      // a role has been added
    } else if (values.length > roles.length) {
      // do not append if the role is "external"
      if (values.at(-1)!.value !== EXTERNAL) {
        append({ role: values.at(-1)!.value, description: '' })
      }
      // a role has been removed
    } else {
      const removed = roles.filter(
        (f) => !values.map((v) => v.value).includes(f.value)
      )[0]
      const indexes = fields.reduce<number[]>((r, n, i) => {
        n.role === removed.value && r.push(i)
        return r
      }, [])
      remove(indexes)
      // if the role is external, reset the external signer number
      if (removed.value === EXTERNAL) {
        setValue('numberOfExternalSigners', undefined)
      }
    }
  }

  const handleChangeExternalSignerCount = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const number = e.target.value as unknown as number | undefined
    // get index where role fields begin
    const externalIndex = roles.map((r) => r.value).indexOf(EXTERNAL)
    // get current indexes of external role fields
    const indexes = fields.reduce<number[]>((r, n, i) => {
      n.role === EXTERNAL && r.push(i)
      return r
    }, [])
    if (number === undefined || number === 0) {
      remove(indexes)
    } else if (number > indexes.length) {
      const toAdd = number - indexes.length
      const newFields = Array(toAdd).fill({
        role: EXTERNAL,
        description: '',
      })
      const insertIndex =
        indexes.length > 0 ? (indexes.at(-1) as number) + 1 : externalIndex
      insert(insertIndex, newFields)
    } else {
      const toRemove = indexes.slice(number)
      remove(toRemove)
    }
  }

  return (
    <div className="mt-[24px]">
      <PersonPageTitle subtitle title="Create PDF Template" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-[32px]">
          <LabelAboveInput
            htmlFor="name"
            subLabel={requiredLabel(!!errors.name)}
          >
            Name
          </LabelAboveInput>
          <BasicInput {...register('name', { required: true })} />
        </div>
        <div className="mt-[32px]">
          <LabelAboveInput htmlFor="description">Description</LabelAboveInput>
          <BasicInput {...register('description')} />
        </div>
        <div className="mt-[32px]">
          <LabelAboveInput
            htmlFor="file"
            subLabel={requiredLabel(!!errors.file)}
          >
            Upload Document
          </LabelAboveInput>
          <ImportUploader
            {...register('file', { required: true })}
            importFile={file}
            setImportFile={(value) => setValue('file', value)}
            fileFormat={{
              'application/pdf': ['.pdf'],
            }}
          />
        </div>
        <div className="mt-[32px]">
          <LabelAboveInput htmlFor="template">Template</LabelAboveInput>
          <Controller
            control={control}
            name="template"
            render={({ field: { onChange, value } }) => {
              return (
                <StyledSelect
                  isClearable={true}
                  inputId="template"
                  id="select-template"
                  name="template"
                  instanceId="template-value"
                  value={value}
                  onChange={(value: OptionTypeBase) => {
                    onChange(value)
                    handleChangeTemplate(value)
                  }}
                  options={templateOptions}
                />
              )
            }}
          />
        </div>
        <div className="mt-[32px]">
          <LabelAboveInput
            htmlFor="roles"
            subLabel={requiredLabel(!!errors.roles)}
          >
            Signers
          </LabelAboveInput>
          <span className="mb-4 flex items-center">
            <i className="fa-solid fa-circle-info mr-2 text-gray-07"></i>
            <small className="italic">
              Signing order is determined by selected arrangement below.
            </small>
          </span>
          <Controller
            control={control}
            name="roles"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => {
              return (
                <StyledMultiSelect
                  isClearable={true}
                  inputId="roles"
                  id="select-roles"
                  name="roles"
                  instanceId="roles-value"
                  value={value}
                  onChange={(values: OptionTypeBase[]) => {
                    onChange(values)
                    handleChangeSigners(values)
                  }}
                  options={SIGNER_OPTIONS}
                />
              )
            }}
          />
        </div>
        {roles.map((f) => f.value).includes(EXTERNAL) && (
          <div className="mt-[32px]">
            <LabelAboveInput htmlFor="numberOfExternalSigners">
              Number of External Signers
            </LabelAboveInput>
            <BasicInput
              {...register('numberOfExternalSigners')}
              type="number"
              onBlur={handleChangeExternalSignerCount}
            />
          </div>
        )}

        {fields.length > 0 && (
          <div className="mt-[32px]">
            <LabelAboveInput>Role Descriptions</LabelAboveInput>
            <span className="mb-4 flex items-center">
              <i className="fa-solid fa-circle-info mr-2 text-gray-07"></i>
              <small className="italic">
                These descriptions will show up under every signer row in the
                Signature Flow. They are optional.
              </small>
            </span>
          </div>
        )}
        {fields.map((r, i) => {
          return (
            <div key={r.id} className="mt-[32px]">
              <LabelAboveInput>{r.role} Description</LabelAboveInput>
              <BasicInput {...register(`roleDescriptions.${i}.description`)} />
            </div>
          )
        })}
        <div className="mt-[32px]">
          <LabelAboveInput
            htmlFor="projectionType"
            subLabel={requiredLabel(!!errors.projectionType)}
          >
            Projection Type
          </LabelAboveInput>
          <Controller
            control={control}
            name="projectionType"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => {
              return (
                <StyledSelect
                  isClearable={true}
                  inputId="projectionType"
                  id="select-projectionType"
                  name="projectionType"
                  instanceId="projectionType-value"
                  value={value}
                  onChange={onChange}
                  options={projectionOptions}
                />
              )
            }}
          />
        </div>
        <div className="mt-[32px] flex flex-row items-center justify-center gap-2">
          <Button
            type="reset"
            buttonStyle="secondary-outline"
            className="w-60"
            onClick={() => reset(DEFAULT_FORM_DATA)}
          >
            Reset
          </Button>
          <Button type="submit" buttonStyle="primary-fill" className="w-60">
            Submit
          </Button>
        </div>
      </form>
      <LoadingPopup loading={creating} />
      {creatingNotice}
    </div>
  )
}
