import { Contact_ContactRelationship as Relationship } from '@augusthealth/models/com/august/protos/contact'
import { RELATIONSHIPS_BY_CATEGORY_MAP } from '@shared/constants/contactRelationship'

export function getRelationshipTypeFromName(
  name: string
): Relationship | undefined {
  const match = name.match(/contact\[relationship=(\w*)\]/)
  if (Array.isArray(match) && match.length >= 2) {
    return match[1] as Relationship
  }
  return undefined
}

export function getDefaultRelationships(relationType?: Relationship) {
  const relationships = relationType ? [relationType] : []
  if (relationType) {
    const clinicianRelationships =
      RELATIONSHIPS_BY_CATEGORY_MAP[Relationship.CONTACT_RELATIONSHIP_CLINICIAN]
    const personalRelationships =
      RELATIONSHIPS_BY_CATEGORY_MAP[Relationship.CONTACT_RELATIONSHIP_PERSONAL]
    const professionalRelationships =
      RELATIONSHIPS_BY_CATEGORY_MAP[
        Relationship.CONTACT_RELATIONSHIP_PROFESSIONAL
      ]

    if (
      relationType === Relationship.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER
    ) {
      relationships.push(
        Relationship.CONTACT_RELATIONSHIP_CLINICIAN,
        Relationship.CONTACT_RELATIONSHIP_PHYSICIAN
      )
    } else if (clinicianRelationships?.includes(relationType)) {
      relationships.push(Relationship.CONTACT_RELATIONSHIP_CLINICIAN)
    } else if (
      personalRelationships?.includes(relationType) ||
      relationType ===
        Relationship.CONTACT_RELATIONSHIP_FINANCIAL_PAYMENT_OF_CARE
    ) {
      relationships.push(Relationship.CONTACT_RELATIONSHIP_PERSONAL)
    } else if (professionalRelationships?.includes(relationType)) {
      relationships.push(Relationship.CONTACT_RELATIONSHIP_PROFESSIONAL)
    }
  }

  return relationships
}
