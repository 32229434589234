import Card from '@shared/components/Card'
import { CountByTimeUnitStat } from '@shared/types/incidents'
import AreaChart from '@app/libraries/Victory/AreaChart'
import { getDomainYWithMargin } from '@app/libraries/Victory/helpers'
import OverviewLabels from '@app/libraries/Victory/OverviewLabels'
import { FilterTimeSpan } from '@app/pages/Notes/types'
import { getChartData } from './helpers'

type Props = {
  time: FilterTimeSpan
  incidentCountByDate: CountByTimeUnitStat[]
}

export default function Chart(props: Props) {
  const { labels, incidentCoords, noteCoords, maxY } = getChartData(props)
  const coordsList = [noteCoords, incidentCoords]

  return (
    <Card className="vertical mb-[24px]">
      <AreaChart
        id="incidents-per-facility"
        categories={labels}
        data={coordsList}
        domain={{ y: getDomainYWithMargin([0, maxY]) }}
        chartStyles={{ height: 84 }}
      />
      <OverviewLabels
        title={['Notes', 'Incidents']}
        data={coordsList}
        reversePercentageStyle
      />
    </Card>
  )
}
