import { PageGenerator } from '@augusthealth/august-frontend-form-elements'
import { TaskType } from '@augusthealth/models/com/august/protos/task'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import LoadingPopup from '@shared/components/LoadingPopup'
import GlobalContext from '@shared/contexts/GlobalContext'
import { admissionTasksPathForPerson, getTaskUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { SignableForm } from '@shared/types/signable_form'
import { AugustError } from '@shared/utils/error'
import { AsyncResult, loaded, LOADING } from '@shared/utils/loading'
import { isFillable, isSignable } from '@shared/utils/taskTemplateInfo'
import { getTemplate } from '@app/api/form'
import FillablePdfFlow from '@app/components/FillablePdfFlow'
import {
  FormPageProps,
  PageGeneratorItem,
} from '@app/components/Prospects/Forms/FormLayout/type'
import { fetchPageIndexWithEmptyRequiredField } from '@app/components/Prospects/Forms/FormLayout/utils'
import SignatureFlow from '@app/components/SignatureFlow'
import PersonContext from '@app/contexts/PersonContext'
import TaskTemplateContext from '@app/contexts/TaskTemplateContext'
import PdfPopup from '../sharable/PdfPopup'
import PageForm from './PageForm'

/*
  returnUpdatedAttributeOnly
  true: returns only part of hash, for example: .person.contact
  false: returns whole hash by default, for example .person
*/
interface FormLayoutProps extends FormPageProps {
  configuration: PageGeneratorItem[]
  returnUpdatedAttributeOnly?: boolean
  taskType?: TaskType
}

export const DEFAULT_PAGE_GENERATOR_PROPS = {
  menuStyle: 'progressBar',
  holderClassName: 'horizontal',
  progressBarProps: {
    type: 'multi',
    multiHighlightType: 'tabs',
    className: 'grass vertical',
    labelPosition: 'top',
  },
}

export function getPageIndex(props: FormPageProps) {
  const { match } = props
  const { params } = match
  const { page = 1 } = params
  const pageNumber = Number(page)

  // page can be "requiredFields" instead of number string
  return isNaN(pageNumber) ? 0 : Number(page) - 1
}

export function replaceUrl(url = '', pageIndex: number) {
  const base = url.replace(/tasks\/(\d*)\/((\d*)|requiredFields)$/, 'tasks/$1')
  return `${base}/${pageIndex}`
}

export default function FormLayout(props: FormLayoutProps) {
  const history = useHistory()
  const { match, title, subTitle, configuration, returnUpdatedAttributeOnly } =
    props
  const { params, url } = match
  const { facilityId, id: pId, orgId, taskId, page } = params
  const showRequiredFields = page === 'requiredFields'
  const { setError } = useContext(GlobalContext)
  const { person, tasks = [] } = useContext(PersonContext)
  const task = tasks.find((t) => t.id === taskId)
  const { customType, dataType } = task?.taskTemplateInfo || {}
  const signable = Boolean(
    task?.taskTemplateInfo && isSignable(task.taskTemplateInfo)
  )
  const isFillablePdf = Boolean(
    task?.taskTemplateInfo && isFillable(task.taskTemplateInfo)
  )
  const [isApiLoading, setIsApiLoading] = useState(false)
  // Need to put it in Function[] (vs Function) to avoid function being called right after setter
  const [pageAction, setPageAction] = useState<(() => void)[]>()
  const [pageIndex, setPageIndex] = useState<number | undefined>(
    getPageIndex(props)
  ) // If not specify, pageIndex will default to 1
  useEffect(() => {
    setPageIndex(getPageIndex(props))
  }, [page])

  // Begin -- Code to handle FillRequiredFields mode
  // 1. Find page containing required field which is missing
  useEffect(() => {
    if (showRequiredFields && person && dataType) {
      setPageIndex(undefined)

      fetchPageIndexWithEmptyRequiredField({
        configuration,
        dataType,
        person: person as Person,
      })
        .then(setPageIndex)
        .catch(setError)
    }
  }, [showRequiredFields, person, dataType])

  // 2. If no page found with missing field, launch Review lightbox to allow submit
  const pageIndexNotFound = pageIndex === -1
  const [openReviewPopup, setOpenReviewPopup] = useState(pageIndexNotFound)
  const [signableForm, setSignableForm] =
    useState<AsyncResult<SignableForm, AugustError>>(LOADING)

  useEffect(() => setOpenReviewPopup(pageIndexNotFound), [pageIndexNotFound])
  // End -- Code to handle FillRequiredFields mode

  useEffect(() => {
    if (task && signable) {
      getTemplate({
        orgId,
        facilityId,
        customType,
        dataType: dataType!,
      })
        .then((template) => setSignableForm(loaded(template.data)))
        .catch((err) => {
          setSignableForm({ tag: 'Error', value: err })
        })
    }
  }, [])

  useEffect(() => {
    if (!isApiLoading && pageAction && pageAction.length) {
      pageAction[0]()
      setPageAction(undefined)
    }
  }, [isApiLoading])

  if (
    !task ||
    (showRequiredFields && !person) ||
    (signable && signableForm.tag === 'Loading') ||
    pageIndex === undefined
  ) {
    return <LoadingPopup loading />
  }

  let labelProps: { title?: string; subTitle?: string }
  let titleElement: ReactNode
  // Title can be a string or ReactNode
  if (typeof title === 'string') {
    labelProps = {
      title,
      subTitle,
    }
  } else {
    titleElement = title
    labelProps = {}
  }

  const goToPageByIndex = (pageIndex: number) => {
    const pageNumber = pageIndex + 1
    const path = replaceUrl(url, pageNumber)
    history.push(path)
  }

  const redirectToParentPage = () => {
    if (person) {
      const path = admissionTasksPathForPerson(person as Required<Person>)
      history.push(path)
    }
  }

  const callPageAction = (func: () => void) => {
    if (isApiLoading) {
      setPageAction([func])
    } else {
      func()
    }
  }

  // Build content using <PageForm />
  const convertedConfiguration = configuration.map((c, i) => {
    const newConf = { ...c }
    const { json } = newConf
    if (newConf.json) {
      const isLastForm = i === configuration.length - 2
      const saveButtonClick = () => {
        if (showRequiredFields) {
          history.go(0) // Reload to go to next page with required fields
        } else if (isLastForm) {
          setOpenReviewPopup(true)
        } else {
          goToPageByIndex(i + 1)
        }
      }
      newConf.content = (
        <PageForm
          {...newConf}
          configuration={json}
          match={match}
          cancelButtonClick={() => callPageAction(redirectToParentPage)}
          saveButtonClick={() => callPageAction(saveButtonClick)}
          defaultShowRequiredFieldAlert={showRequiredFields}
          setIsApiLoading={setIsApiLoading}
          returnUpdatedAttributeOnly={returnUpdatedAttributeOnly}
          pageIndex={i}
        />
      )
    }

    return newConf
  })

  const onReviewClose = () => {
    if (showRequiredFields) {
      // Redirect to default Web form url to remove /requiredFields in url
      history.push(getTaskUrl({ orgId, facilityId, personId: pId, id: taskId }))
    }
    setOpenReviewPopup(false)
  }

  const onProgressBarClick = (pageIndex: number) => {
    if (pageIndex === configuration.length - 1) {
      callPageAction(() => setOpenReviewPopup(true))
    } else if (!showRequiredFields) {
      callPageAction(() => goToPageByIndex(pageIndex))
    } else {
      return true
    }

    // Return false to prevent default PageGenerator onProgressBarClick event
    return false
  }

  let reviewPopupContent
  if (openReviewPopup) {
    if (isFillablePdf && dataType) {
      reviewPopupContent = (
        <FillablePdfFlow
          configuration={configuration}
          dataType={dataType}
          person={person!}
          closeModal={onReviewClose}
          task={task}
        />
      )
    } else if (
      signableForm.tag === 'Complete' &&
      signableForm.value.template?.signers?.length
    ) {
      reviewPopupContent = (
        <TaskTemplateContext.Provider value={{ signableForm }}>
          <SignatureFlow
            task={task}
            person={person!}
            onClose={onReviewClose}
            configuration={configuration}
            dataType={dataType}
          />
        </TaskTemplateContext.Provider>
      )
    } else {
      // For GettingToKnowYou and LevelOfCare version 1 only
      reviewPopupContent = <PdfPopup onClose={onReviewClose} params={params} />
    }
  }

  return (
    <>
      {titleElement}
      <PageGenerator
        {...DEFAULT_PAGE_GENERATOR_PROPS}
        {...labelProps}
        pageIndex={pageIndexNotFound ? 0 : pageIndex}
        configuration={convertedConfiguration}
        onProgressBarClick={onProgressBarClick}
        hideButtonFooter
      />
      {reviewPopupContent}
      {pageAction && <LoadingPopup loading />}
    </>
  )
}
