import { TaskTemplateInfo } from '@augusthealth/models/com/august/protos/task'
import { useState } from 'react'
import Card from '@shared/components/Card'
import { Incident } from '@shared/types/incidents'
import { Person } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { hasActiveAlert } from '@shared/utils/incident'
import { deleteIncident } from '@app/api/incidents'
import ConfirmModal from '@app/components/ConfirmModal'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import AddDocumentsModal from '../AddDocumentsModal'
import OnAlertModal from '../Alerts/OnAlertModal'
import StopAlertModal from '../Alerts/StopAlertModal'
import EditFollowUpActionsModal from '../EditFollowUpActionsModal'
import { EditNoteModal } from '../NoteModal'
import AlertBanner from './AlertBanner'
import { toCardIncident } from './helpers'
import IncidentBody from './IncidentBody'
import { IncidentCardContextProvider } from './IncidentCardContext'
import IncidentFooter from './IncidentFooter'
import IncidentHeader from './IncidentHeader'
import NoteBody from './NoteBody'

export default function IncidentCard({
  incident: initialIncident,
  person,
  user,
  onDelete,
  creatableTasks,
  onChangeAlert,
  onLocalStateChange,
  includePersonLink = false,
}: {
  incident: Incident
  person: Person
  user: UserAccount
  onChangeAlert?: (newIncident: Incident) => Promise<void>
  onDelete: () => Promise<void>
  creatableTasks: TaskTemplateInfo[]
  onLocalStateChange?: (newIncident: Incident) => void
  includePersonLink?: boolean
}) {
  const [openModal, setOpenModal] = useState<
    | 'ConfirmDelete'
    | 'AddOrEditAlert'
    | 'StopAlert'
    | 'EditNote'
    | 'AddDocuments'
    | 'EditFollowUpActions'
  >()
  const [incident, setIncident] = useState<Incident>(initialIncident)
  const { currentFacility } = useCurrentFacility()

  const frontendIncident = toCardIncident(incident)
  const { tag, icon } = frontendIncident

  const activeAlert = hasActiveAlert(incident)

  if (!currentFacility || currentFacility.tag === 'Loading') {
    return null
  }

  return (
    <>
      <IncidentCardContextProvider
        value={{ facility: currentFacility.value, incident, person, user }}
      >
        <Card
          data-testid={`incident-card-${incident.id}`}
          className="print-bottom-border mb-[16px] w-full p-[24px]"
          style={activeAlert ? { borderLeft: '4px solid var(--alert)' } : {}}
        >
          <div className="flex w-full items-start">
            <div className="mr-[16px] flex h-[40px] w-[40px] items-center">
              <i className={`fa-solid text-secondary fa-${icon} fa-2x fa-fw`} />
            </div>
            <div className={'grow'}>
              <IncidentHeader
                onToggle={() => {
                  if (activeAlert) {
                    setOpenModal('StopAlert')
                  } else {
                    setOpenModal('AddOrEditAlert')
                  }
                }}
              />
              {tag === 'Incident' && (
                <IncidentBody
                  data={frontendIncident}
                  creatableTasks={creatableTasks}
                  onUpdate={async (newIncident) => {
                    setIncident(newIncident)
                    onLocalStateChange && onLocalStateChange(newIncident)
                  }}
                  onClickEditFollowUpActions={() =>
                    setOpenModal('EditFollowUpActions')
                  }
                  includePersonLink={includePersonLink}
                />
              )}
              {tag === 'Note' && (
                <NoteBody
                  data={frontendIncident}
                  includePersonLink={includePersonLink}
                  incident={incident}
                  person={person}
                />
              )}
              {activeAlert && (
                <AlertBanner
                  onEditAlert={() => setOpenModal('AddOrEditAlert')}
                />
              )}
              <IncidentFooter
                onIncidentChange={(incident) => {
                  setIncident(incident)
                  onLocalStateChange && onLocalStateChange(incident)
                }}
                onClickEditNote={() => setOpenModal('EditNote')}
                onClickDeleteIncident={() => setOpenModal('ConfirmDelete')}
                onClickAddDocuments={() => setOpenModal('AddDocuments')}
              />
            </div>
          </div>
        </Card>
      </IncidentCardContextProvider>
      {openModal === 'EditNote' && (
        <EditNoteModal
          note={incident}
          closeModal={async (newIncident?: Incident) => {
            if (newIncident) {
              setIncident(newIncident)
              onLocalStateChange && onLocalStateChange(newIncident)
            }

            setOpenModal(undefined)
          }}
          person={person}
        />
      )}
      {openModal === 'AddOrEditAlert' && (
        <OnAlertModal
          incident={incident}
          person={person}
          onClose={async (updated) => {
            if (!updated) {
              return setOpenModal(undefined)
            }

            onChangeAlert && (await onChangeAlert(updated))
            setIncident(updated)
            onLocalStateChange && onLocalStateChange(updated)
            return setOpenModal(undefined)
          }}
        />
      )}
      {openModal === 'StopAlert' && (
        <StopAlertModal
          incident={incident}
          person={person}
          onClose={async (updated) => {
            if (!updated) {
              void Promise.resolve(setOpenModal(undefined))
              return
            }

            onChangeAlert && (await onChangeAlert(updated))
            setIncident(updated)
            onLocalStateChange && onLocalStateChange(updated)
            void Promise.resolve(setOpenModal(undefined))
          }}
        />
      )}
      {openModal === 'ConfirmDelete' && (
        <ConfirmModal
          title={`Do you want to delete this ${frontendIncident.tag}?`}
          confirmButtonConfig={{
            children: `Delete ${frontendIncident.tag}`,
            onClick: async () => {
              await deleteIncident({ person, incident })
              await onDelete()

              setOpenModal(undefined)
            },
          }}
          denyButtonConfig={{
            onClick: () => {
              setOpenModal(undefined)
            },
          }}
          content={
            <div className="text-center">
              <div className="font-bold text-gray-06">
                {frontendIncident.title}
              </div>
              <div className="text-gray-06">{frontendIncident.date}</div>
            </div>
          }
        />
      )}
      {openModal === 'AddDocuments' && (
        <AddDocumentsModal
          incident={incident}
          closeModal={async (updatedIncident?: Incident) => {
            if (updatedIncident) {
              setIncident(updatedIncident)
              onLocalStateChange && onLocalStateChange(updatedIncident)
            }

            setOpenModal(undefined)
          }}
          person={person}
        />
      )}

      {openModal === 'EditFollowUpActions' && (
        <EditFollowUpActionsModal
          incident={incident}
          closeModal={async (updatedIncident?: Incident) => {
            if (updatedIncident) {
              setIncident(updatedIncident)
              onLocalStateChange && onLocalStateChange(updatedIncident)
            }

            setOpenModal(undefined)
          }}
          person={person}
        />
      )}
    </>
  )
}
