import { CreateRoomRequest } from '@augusthealth/models/com/august/protos/api/occupancy'
import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { createRoom } from '@app/api/rooms'
import RoomCapacitySelect, { CAPACITY_OPTIONS } from './RoomCapacitySelect'

type Props = {
  facilityId: string
  orgId: string
  onClose: () => void
  onSave: () => Promise<void>
}

const DEFAULT_ROOM: CreateRoomRequest = {
  capacity: 1,
}

export default function AddRoomPopup(props: Props) {
  const { setError } = useContext(GlobalContext)
  const { facilityId, orgId, onClose, onSave } = props
  const {
    control,
    handleSubmit,
    register,
    formState,
    formState: { errors },
  } = useForm<CreateRoomRequest>({
    defaultValues: DEFAULT_ROOM,
  })

  const onSubmit = async (room: CreateRoomRequest) =>
    createRoom({ orgId, id: facilityId }, room).then(onSave).catch(setError)

  return (
    <AnimatedPopup title="Add a Room">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-[32px]">
          <LabelAboveInput
            htmlFor="name"
            subLabel={requiredLabel(Boolean(errors.name))}
          >
            Room Name
          </LabelAboveInput>
          <BasicInput
            {...register('name', { required: true })}
            placeholder="e.g. 1 or Sunny Room"
          />
        </div>
        <Controller
          control={control}
          name="capacity"
          render={({ field: { onChange, value } }) => (
            <div className="mt-[32px]">
              <LabelAboveInput htmlFor="capacity">Room Type</LabelAboveInput>
              <RoomCapacitySelect
                name="capacity"
                id="capacity"
                instanceId="capacity"
                menuPlacement="top"
                onChange={(opt: OptionTypeBase) => onChange(opt.value)}
                value={CAPACITY_OPTIONS.find((opt) => opt.value === value)}
              />
            </div>
          )}
        />
        <div className="mt-[32px] space-x-[12px] whitespace-nowrap">
          <AnimatedPopupFormFooter
            formState={formState}
            noBtn={{
              props: {
                ['data-cy']: 'cancelAddRoomButton',
              },
              label: 'Cancel',
              action: onClose,
            }}
            yesBtn={{
              label: 'Add',
              props: {
                ['data-cy']: 'saveAddRoomButton',
                ['data-testid']: 'saveAddRoomButton',
              },
            }}
          />
        </div>
      </form>
    </AnimatedPopup>
  )
}
