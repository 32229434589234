// eslint-disable-next-line no-restricted-imports
import { loginUrl } from '@shared/api/urls'

export async function login(email: string, path?: string): Promise<void> {
  const response = await fetch(loginUrl(), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, path }),
  })
  await response.json()
}
