import { OptionTypeBase } from '@shared/components/StyledSelect'
import {
  BloodPressureUnit,
  BloodSugarUnit,
  BloodPressurePosition as BPP,
  BloodSugarTiming as BST,
  HeartRateUnit,
  OxygenSaturationUnit,
  RespiratoryRateUnit,
  SupplementalOxygen as SO,
  TemperatureUnit,
  TemperatureType as TT,
  Vital,
  VitalType,
  WeightUnit,
} from '@shared/types/vital'

export const Units = {
  lbs: WeightUnit.WEIGHT_UNIT_POUNDS,
  degF: TemperatureUnit.TEMPERATURE_UNIT_FAHRENHEIT,
  mmHg: BloodPressureUnit.BLOOD_PRESSURE_UNIT_MILLIMETERS_OF_MERCURY,
  bpm: HeartRateUnit.HEART_RATE_UNIT_BEATS_PER_MINUTE,
  brpm: RespiratoryRateUnit.RESPIRATORY_RATE_UNIT_BREATHS_PER_MINUTE,
  o2: OxygenSaturationUnit.OXYGEN_SATURATION_UNIT_PERCENT,
  mgdL: BloodSugarUnit.BLOOD_SUGAR_UNIT_MILLIGRAMS_PER_DECILITER,
}

export enum VitalsType {
  WEIGHT = 'weight',
  TEMPERATURE = 'temperature',
  HEART_RATE = 'heartRate',
  BLOOD_PRESSURE = 'bloodPressure',
  RESPIRATORY_RATE = 'respiratoryRate',
  OXYGEN_SATURATION = 'oxygenSaturation',
  BLOOD_SUGAR = 'bloodSugar',
}

export const VitalTypeEnumToVitalsType = (
  vitalType: VitalType
): VitalsType | null => {
  switch (vitalType) {
    case VitalType.VITAL_TYPE_WEIGHT:
      return VitalsType.WEIGHT
    case VitalType.VITAL_TYPE_TEMPERATURE:
      return VitalsType.TEMPERATURE
    case VitalType.VITAL_TYPE_HEART_RATE:
      return VitalsType.HEART_RATE
    case VitalType.VITAL_TYPE_BLOOD_PRESSURE:
      return VitalsType.BLOOD_PRESSURE
    case VitalType.VITAL_TYPE_RESPIRATORY_RATE:
      return VitalsType.RESPIRATORY_RATE
    case VitalType.VITAL_TYPE_OXYGEN_SATURATION:
      return VitalsType.OXYGEN_SATURATION
    case VitalType.VITAL_TYPE_BLOOD_SUGAR:
      return VitalsType.BLOOD_SUGAR
    default:
      return null
  }
}

export interface VitalsFormData {
  date: Date | null
  time: string | null
  weight: string
  temperature: string
  heartRate: string
  systolic: string
  diastolic: string
  respiratoryRate: string
  oxygenSaturation: string
  bloodSugar: string
  temperatureType?: OptionTypeBase<TT> | null
  bloodPressurePosition?: OptionTypeBase<BPP> | null
  supplementalOxygen?: OptionTypeBase<SO> | null
  bloodSugarTiming?: OptionTypeBase<BST> | null
  note?: string | null
}

type VitalsInputPropsMap = {
  [type in VitalsType]: VitalsInputProps
}

export type VitalsInputProps = {
  name: keyof VitalsFormData
  label: string
  unitsLabel: string
  iconClassName: string
  dropdownName?: keyof VitalsFormData
  options?: OptionTypeBase<BST | SO | BPP | TT>[]
  placeholder?: string
}

export const BloodSugarOptions = [
  { label: 'Before Meal', value: BST.BLOOD_SUGAR_TIMING_BEFORE_MEAL },
  { label: 'After Meal', value: BST.BLOOD_SUGAR_TIMING_AFTER_MEAL },
  { label: 'Fasting', value: BST.BLOOD_SUGAR_TIMING_FASTING },
  { label: 'Bedtime', value: BST.BLOOD_SUGAR_TIMING_BEDTIME },
  { label: 'Random', value: BST.BLOOD_SUGAR_TIMING_RANDOM },
]
export const TemperatureOptions = [
  { label: 'Axillary', value: TT.TEMPERATURE_TYPE_AXILLARY },
  { label: 'Ear', value: TT.TEMPERATURE_TYPE_EAR },
  { label: 'Forehead', value: TT.TEMPERATURE_TYPE_FOREHEAD },
  { label: 'Oral', value: TT.TEMPERATURE_TYPE_ORAL },
  { label: 'Rectal', value: TT.TEMPERATURE_TYPE_RECTAL },
]
export const OxygenSaturationOptions = [
  { label: '0.5 L', value: SO.SUPPLEMENTAL_OXYGEN_0_5_L },
  { label: '1.0 L', value: SO.SUPPLEMENTAL_OXYGEN_1_0_L },
  { label: '1.5 L', value: SO.SUPPLEMENTAL_OXYGEN_1_5_L },
  { label: '2.0 L', value: SO.SUPPLEMENTAL_OXYGEN_2_0_L },
  { label: '2.5 L', value: SO.SUPPLEMENTAL_OXYGEN_2_5_L },
  { label: '3.0 L', value: SO.SUPPLEMENTAL_OXYGEN_3_0_L },
  { label: '3.5 L', value: SO.SUPPLEMENTAL_OXYGEN_3_5_L },
  { label: '4.0 L', value: SO.SUPPLEMENTAL_OXYGEN_4_0_L },
  { label: '4.5 L', value: SO.SUPPLEMENTAL_OXYGEN_4_5_L },
  { label: '5.0 L', value: SO.SUPPLEMENTAL_OXYGEN_5_0_L },
]
export const BloodPressureOptions = [
  { label: 'Lying', value: BPP.BLOOD_PRESSURE_POSITION_LYING },
  { label: 'Sitting', value: BPP.BLOOD_PRESSURE_POSITION_SITTING },
  { label: 'Standing', value: BPP.BLOOD_PRESSURE_POSITION_STANDING },
]

export const VITALS_INPUT_PROPS_MAP: VitalsInputPropsMap = {
  [VitalsType.WEIGHT]: {
    name: 'weight',
    label: 'Weight',
    unitsLabel: 'lbs',
    iconClassName: 'fa-fw fa-solid fa-weight-scale',
  },
  [VitalsType.TEMPERATURE]: {
    name: 'temperature',
    label: 'Temperature',
    unitsLabel: '°F',
    iconClassName: 'fa-fw fa-solid fa-temperature-three-quarters',
    dropdownName: 'temperatureType',
    options: TemperatureOptions,
    placeholder: 'Type...',
  },
  [VitalsType.HEART_RATE]: {
    name: 'heartRate',
    label: 'Pulse',
    unitsLabel: 'bpm',
    iconClassName: 'fa-fw fa-regular fa-wave-pulse',
  },
  [VitalsType.BLOOD_PRESSURE]: {
    name: 'systolic',
    label: 'Blood pressure',
    unitsLabel: 'mmHg',
    iconClassName: 'fa-fw fa-solid fa-heart-circle-bolt',
    dropdownName: 'bloodPressurePosition',
    options: BloodPressureOptions,
    placeholder: 'Position...',
  },
  [VitalsType.RESPIRATORY_RATE]: {
    name: 'respiratoryRate',
    label: 'Respiratory rate',
    unitsLabel: 'bpm',
    iconClassName: 'fa-fw fa-solid fa-monitor-waveform',
  },
  [VitalsType.OXYGEN_SATURATION]: {
    name: 'oxygenSaturation',
    label: 'Oxygen saturation',
    unitsLabel: '%',
    iconClassName: 'fa-fw fa-solid fa-lungs',
    dropdownName: 'supplementalOxygen',
    options: OxygenSaturationOptions,
    placeholder: 'Supplemental O₂ ...',
  },
  [VitalsType.BLOOD_SUGAR]: {
    name: 'bloodSugar',
    label: 'Blood sugar',
    unitsLabel: 'mg/dL',
    iconClassName: 'fa-fw fa-solid fa-droplet',
    dropdownName: 'bloodSugarTiming',
    options: BloodSugarOptions,
    placeholder: 'Timing...',
  },
}

export function getVitalValueForType({
  vital,
  vitalType,
}: {
  vital: Vital
  vitalType: VitalsType
}) {
  if (vital.panel[vitalType]) {
    if (vitalType === VitalsType.BLOOD_PRESSURE) {
      return `${vital.panel[vitalType].systolic?.value}/${vital.panel[vitalType].diastolic?.value}`
    }

    return vital.panel[vitalType].value
  }

  return '--'
}
