import { useState } from 'react'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { Organization } from '@shared/types/organization'
import { updateOrganization } from '@app/api/organizations'
import { Switch } from '@app/components/Switch'

export default function OrgSettings({
  org,
  onClose,
  setShowHUD,
}: {
  org: Organization
  onClose: () => void
  setShowHUD: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const [isActive, setIsActive] = useState(org.isActive)

  const updateOrgSettings = async () => {
    if (isActive !== org.isActive) {
      const updatedOrg = { ...org, isActive }
      await updateOrganization(updatedOrg)
    }

    setShowHUD(true)
    onClose()
  }

  return (
    <AnimatedPopup
      title={
        <div className="mb-[32px] text-[20px] font-semibold leading-[20px]">
          Org Settings
        </div>
      }
      containerClassName="w-[320px]"
      onClickBackground={onClose}
    >
      <div className="flex items-center justify-between">
        <label className="mb-0" htmlFor="inactive">
          Mark as inactive
        </label>
        <Switch
          name="inactive"
          onChange={() => setIsActive(!isActive)}
          checked={!isActive}
        />
      </div>
      <div className="mt-[24px] flex justify-between">
        <AsyncIconButton
          buttonStyle="secondary-outline"
          buttonSize="small"
          onClick={onClose}
          width="120px"
        >
          Cancel
        </AsyncIconButton>
        <AsyncIconButton
          buttonStyle="primary-fill"
          buttonSize="small"
          onClick={async () => {
            await updateOrgSettings()
          }}
          width="120px"
        >
          Save
        </AsyncIconButton>
      </div>
    </AnimatedPopup>
  )
}
