// eslint-disable-next-line no-restricted-imports
import { ErrorCode } from '@augusthealth/models/com/august/protos/api/error_response'
import { add, sub } from 'date-fns'
import environment from '@shared/environment'
import { ErrorResponse } from '@shared/types/api/error_response'

type InternalErrorCode = 'FILE_TOO_LARGE'

export interface AugustError extends Error {
  status?: number
  json?: ErrorResponse
  requestId?: string
  internalCode?: InternalErrorCode
}

export function isClientVersionUnsupported(e: AugustError) {
  return (
    e.json?.errors?.some(
      (e) => e.code === ErrorCode.ERROR_CODE_UNSUPPORTED_VERSION
    ) || false
  )
}

export function isRequiredSignatureError(e: AugustError) {
  return (
    e.json?.errors?.some((e) => e.message?.match(/must have a date signed/i)) ||
    false
  )
}

export function isInMaintenanceMode(e: AugustError) {
  return e.status === 503
}

export function isEtagMismatch(e: AugustError) {
  return e.status === 412
}

export function isConnectivityError(e: AugustError | TypeError) {
  return (
    (e instanceof TypeError && e.message.match(/failed to fetch/i)) ||
    errorMatchesMessage(e, 'failed to fetch') ||
    errorMatchesMessage(e, 'load failed') ||
    errorMatchesMessage(e, 'network error') ||
    errorMatchesMessage(e, 'connection failed') ||
    errorMatchesMessage(e, 'network request failed') ||
    errorMatchesMessage(e, 'networkerror')
  )
}

function errorMatchesMessage(e: AugustError | TypeError, message: string) {
  return (e.message || '').match(new RegExp(message, 'i')) || false
}

export const requiredSignatureError: AugustError = {
  name: 'RequiredSignatureError',
  status: 400,
  message: 'Please select a signature date for this document.',
  json: {
    errors: [
      {
        code: ErrorCode.ERROR_CODE_DISPLAY_TO_CLIENT,
        message: 'Please select a signature date for this document.',
      },
    ],
  },
}

export function buildAugustError({
  name,
  message,
}: {
  name: string
  message: string
}): AugustError {
  return {
    name,
    message,
    json: {
      errors: [
        {
          code: ErrorCode.ERROR_CODE_DISPLAY_TO_CLIENT,
          message,
        },
      ],
    },
  }
}

export function generateCloudwatchUrl(requestId: string): string {
  const logGroup = environment.cloudwatch.logGroup
  const awsRegion = environment.awsRegion

  const encodedFilterPattern = encodeURIComponent(
    `{$.request_id="${requestId}"}`
  )

  const start = sub(new Date(), { minutes: 10 })
  const end = add(new Date(), { minutes: 10 })

  const encodedLogGroup = encodeURIComponent(encodeURIComponent(logGroup))
    .split('%')
    .join('$')
  const encodedFilter = encodeURIComponent(
    `?filterPattern=${encodedFilterPattern}` +
      `&start=${start.getTime()}&end=${end.getTime()}`
  )
    .split('%')
    .join('$')

  return (
    `https://${awsRegion}.console.aws.amazon.com/cloudwatch/home` +
    `?region=${awsRegion}#logsV2:log-groups/log-group/${encodedLogGroup}` +
    `/log-events${encodedFilter}`
  )
}

export const WARNING_LEVEL_INTERNAL_CODES = ["'FILE_TOO_LARGE'"]
