import { get } from '@augusthealth/august-frontend-form-elements'
import {
  ContactPoint,
  ContactPoint_ContactPointSystem,
  ContactPoint_ContactPointUse,
} from '@augusthealth/models/com/august/protos/contact_point'
import { ChangeEvent, KeyboardEvent } from 'react'
import { BasicInputProps } from '@shared/components/BasicInput/BasicInput'
import { CONTACT_POINT_USE_OPTIONS, Option } from './ContactPointUseDropdown'
import PhoneInput from './index'

type Props = Omit<BasicInputProps, 'onChange' | 'value'> & {
  data?: object
  onChange: (value: any) => void
  onUpdate: (value: any) => void
  value?: BasicInputProps['value'] | ContactPoint
}

export default function PhoneInputInGenerator(props: Props) {
  const {
    data = {},
    name = '',
    value,
    disabled = false,
    onChange,
    onUpdate,
  } = props
  let inputName = name
  let inputValue = value
  let dropdownProps
  const isTelecomValue = name.endsWith('.value')
  const getReturnValue = (phone: string): string | ContactPoint | undefined => {
    if (isTelecomValue) {
      return phone
    }

    const telecom = get(data, name)
    if (telecom) {
      return {
        ...telecom,
        value: phone,
      }
    }

    return {
      system: ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_PHONE,
      value: phone,
      use: ContactPoint_ContactPointUse.CONTACT_POINT_USE_UNSPECIFIED,
    }
  }
  const convertUseToTelecom = (
    use: ContactPoint_ContactPointUse
  ): string | ContactPoint | undefined => {
    const telecom = get(data, name)
    if (telecom) {
      return {
        ...telecom,
        use,
      }
    }

    return {
      system: ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_PHONE,
      use,
      value: '',
    }
  }

  if (!isTelecomValue) {
    const contactPointUse = value && (value as ContactPoint).use
    const dropdownName = `${name}.use`
    inputName = `${name}.value`
    inputValue = value && (value as ContactPoint).value
    dropdownProps = {
      name: dropdownName,
      value: CONTACT_POINT_USE_OPTIONS.find(
        (opt) => opt.value === contactPointUse
      ),
      onChange: (opt?: Option) => {
        onUpdate(
          convertUseToTelecom(opt?.value as ContactPoint_ContactPointUse)
        )
      },
      isDisabled: disabled,
    }
  }

  const inputProps = {
    ...props,
    name: inputName,
    value: inputValue,
    onBlur: (ev: ChangeEvent<HTMLInputElement>) => {
      onUpdate(getReturnValue(ev.target.value))
    },
    onKeyUp: (ev: KeyboardEvent<HTMLInputElement>) => {
      ev.key === 'Enter' &&
        onUpdate(getReturnValue((ev.target as HTMLInputElement).value))
    },
    onChange: (ev: ChangeEvent<HTMLInputElement>) => {
      onChange(getReturnValue(ev.target.value))
    },
  }

  return (
    <PhoneInput
      inputProps={inputProps as BasicInputProps}
      dropdownProps={dropdownProps}
    />
  )
}
