import {
  addDays,
  endOfToday,
  format,
  formatISO,
  isValid,
  parseISO,
  startOfToday,
} from 'date-fns'
import {
  formatInTimeZone,
  toDate as toDateTz,
  zonedTimeToUtc,
} from 'date-fns-tz'
import { merge } from 'lodash'
import * as timeago from 'timeago.js'
import {
  DateAndTime,
  DateMessage,
  Time,
  ValidDate,
  ValidDatePeriod,
} from '@shared/types/date'
import { fromDateToTimeString } from '@shared/utils/time'

export const parseIsoToZonedDate = ({
  zoneId,
  isoString,
}: {
  zoneId: string
  isoString: string
}): Date => {
  return toDateTz(isoString, { timeZone: zoneId })
}

export function fromDateMessageToDate(dateMsg?: DateMessage): Date | undefined {
  if (dateMsg && dateMsg.day && dateMsg.month && dateMsg.year) {
    return new Date(dateMsg.year, dateMsg.month - 1, dateMsg.day)
  }

  return undefined
}

export const fromDateTimeToDate = (dateTime: DateAndTime): Date | undefined => {
  if (
    dateTime.date &&
    dateTime.date.day &&
    dateTime.date.month &&
    dateTime.date.year
  ) {
    return new Date(
      dateTime.date.year,
      dateTime.date.month - 1,
      dateTime.date.day,
      dateTime.time?.hour,
      dateTime.time?.minute
    )
  }

  return undefined
}

export const fromDateTimeToDateInTimezone = (
  dateTime: DateAndTime,
  zoneId: string
): Date => {
  const date = fromDateTimeToDate(dateTime) as Date
  return zonedTimeToUtc(date, zoneId)
}

/**
 * value is expected to be of the form 'YYYY-MM-DD'
 */
export function fromIsoDateToDateMessage(
  isoString: string
): ValidDate | undefined {
  const parsed = parseISO(isoString)
  if (isValid(parsed)) {
    return fromDateToDateMessage(parsed)
  } else {
    return undefined
  }
}

export function fromDateToDateMessage(date: Date): ValidDate {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  }
}

export function fromDateToTime(date: Date): Time {
  return {
    hour: date.getHours(),
    minute: date.getMinutes(),
  }
}

export function fromDateToDateAndTime(date: Date): DateAndTime {
  return {
    date: fromDateToDateMessage(date),
    time: fromDateToTime(date),
  }
}

export function monthDayYear(date: DateMessage) {
  return `${String(date.month).padStart(2, '0')}/${String(date.day).padStart(
    2,
    '0'
  )}/${date.year}`
}

// Return MAR date in format YYYY-MM
export function formatMarDate(date: Date) {
  return `${date.toLocaleDateString('en-us', {
    year: 'numeric',
  })}-${date.toLocaleDateString('en-us', { month: '2-digit' })}`
}

/**
 * Convert an HH:mm time string into a Time object.
 * @param timeStr
 */
export function fromTimeStringToTime(timeStr: string): Time | undefined {
  const mHoursMinutes = timeStr.split(':').map((n) => parseInt(n))

  if (mHoursMinutes.every(isFinite) && mHoursMinutes.length === 2) {
    return {
      hour: mHoursMinutes[0],
      minute: mHoursMinutes[1],
    }
  }

  return undefined
}

interface FormatTimeOptions {
  long: boolean
  use24HourClock: boolean
}

/**
 * Convert a Time object into an HH:mm or h:mm a string.
 * @param time
 * @param options
 * @param options.use24HourClock boolean to use 24 hour time (default false)
 * @param options.long          boolean to pad hour with zeros (default false)
 * @example:
 * // returns '13:30'
 * formatTime({hour: 13, minute: 30}, { use24HourClock: true })
 *
 * @example:
 * // returns '01:30 PM'
 * formatTime({hour: 13, minute: 30}, { use24HourClock: false, long: true })
 *
 * @returns string
 */
export function formatTime(
  time: Time | undefined,
  options: Partial<FormatTimeOptions> = {}
): string | undefined {
  const defaults: FormatTimeOptions = {
    long: false,
    use24HourClock: false,
  }
  const { long, use24HourClock } = { ...defaults, ...options }

  if (!time || time.hour === undefined || time.minute === undefined) {
    return undefined
  }

  const date = new Date()
  date.setHours(time.hour)
  date.setMinutes(time.minute)

  return fromDateToTimeString(date, use24HourClock, long)
}

export function dateMessageComparator(a: DateMessage, b: DateMessage): number {
  if (a.year !== b.year) {
    return (a.year || 0) - (b.year || 0)
  }
  if (a.month !== b.month) {
    return (a.month || 0) - (b.month || 0)
  }
  return (a.day || 0) - (b.day || 0)
}

export function sortDates(a: Date, b: Date): number {
  return a.getTime() - b.getTime()
}

export const formatDateMessage = (
  dateMessage: DateMessage | undefined | null
): string => {
  if (!dateMessage) {
    return ''
  }

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return `${months[(dateMessage.month || 0) - 1]} ${dateMessage.day}, ${
    dateMessage.year
  }`
}

interface FormatDateTime {
  includeDate: boolean
  use24HourClock: boolean
  dateFormat: string
  includeTz: boolean
  timeFirst: boolean
}

export const formatDateTimeInZone = (
  dateTime: Date | string,
  timeZone: string,
  options: Partial<FormatDateTime> = {}
) => {
  const timeFormat = options.use24HourClock ? 'HH:mm' : 'hh:mm aa'
  const dateFormat = options.includeDate
    ? options.dateFormat || 'MM/dd/yyyy '
    : ''
  const tzFormat = options.includeTz ? ' z' : ''

  const formatString = options.timeFirst
    ? `${timeFormat}${tzFormat}${dateFormat}`
    : `${dateFormat}${timeFormat}${tzFormat}`

  return formatInTimeZone(dateTime, timeZone, formatString)
}

export const formatDateTime = (
  time?: Date | string,
  options: Partial<FormatDateTime> = {}
) => {
  const defaults = {
    includeDate: false,
    use24HourClock: false,
  }
  const { includeDate, use24HourClock } = { ...defaults, ...options }

  if (time === undefined) {
    return ''
  }

  const dateTime = new Date(time)

  let suffix = ''
  if (!use24HourClock) {
    if (dateTime.getHours() > 12) {
      dateTime.setHours(dateTime.getHours() - 12)
      suffix = ' PM'
    } else if (dateTime.getHours() === 12) {
      suffix = ' PM'
    } else if (dateTime.getHours() === 0) {
      dateTime.setHours(12)
      suffix = ' AM'
    } else {
      suffix = ' AM'
    }
  }

  const readableTime = `${dateTime.getHours()}:${dateTime
    .getMinutes()
    .toLocaleString('en-US', { minimumIntegerDigits: 2 })}${suffix}`

  if (includeDate) {
    const date = fromDateToDateMessage(dateTime)

    return `${formatDateMessage(date)} ${readableTime}`
  }

  return readableTime
}

export const formatIsoTime = (
  isoString?: string,
  formatPattern: string = 'HH:mm:ss'
): string | undefined => {
  if (!isoString) {
    return undefined
  }

  return format(new Date(isoString), formatPattern)
}

export const formatIsoDate = (date: Date) =>
  formatISO(date, { representation: 'date' })

export const safeParseIso = ({
  date,
  fallback = new Date(),
}: {
  date?: string
  fallback?: Date
}): Date => {
  const parsed = date ? parseISO(date) : undefined
  if (parsed && isValid(parsed)) {
    return parsed
  }

  return fallback
}

export const isDateWithinRange = (date: Date, daysInRange: number) => {
  if (daysInRange < 0) {
    return isWithinInterval(date, {
      start: addDays(startOfToday(), daysInRange),
      end: endOfToday(),
    })
  } else {
    return isWithinInterval(date, {
      start: startOfToday(),
      end: addDays(endOfToday(), daysInRange),
    })
  }
}
export const isDatePast = (date: Date) => {
  return date.getTime() < Date.now()
}

export function addLeadingZero(n: number) {
  if (n < 10) {
    return `0${n}`
  }

  return n
}

export function getDateString(date: Date) {
  const y = date.getFullYear()
  const m = date.getMonth() + 1
  const d = date.getDate()
  return `${y}-${addLeadingZero(m)}-${addLeadingZero(d)}`
}

export function toDatePeriod(
  startDate: Date | undefined,
  endDate: Date | undefined
): ValidDatePeriod {
  if (startDate) {
    if (endDate) {
      return {
        startDate: fromDateToDateMessage(startDate),
        endDate: fromDateToDateMessage(endDate),
      }
    }

    return {
      startDate: fromDateToDateMessage(startDate),
    }
  }

  return {}
}

export function formatTimeAgo(
  timestamp: Date | number | string | DateMessage | undefined
): string {
  let date: Date

  if (timestamp instanceof Date) {
    date = timestamp
  } else if (typeof timestamp === 'number' || typeof timestamp === 'string') {
    date = new Date(timestamp)
  } else if (typeof timestamp === 'object') {
    const { year, month, day } = timestamp
    date = new Date(year || 0, (month || 0) - 1, day)
  } else {
    return ''
  }

  return timeago.format(date)
}

export function formatDate(
  date: Date | DateMessage | string | undefined,
  options?: Intl.DateTimeFormatOptions
): string {
  if (date === undefined) {
    return ''
  }

  const parsedDate =
    typeof date === 'string'
      ? new Date(date)
      : date instanceof Date
        ? date
        : new Date(date.year || 0, (date.month || 0) - 1, date.day)
  return parsedDate.toLocaleString(
    'en-US',
    merge(
      {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      options ?? {}
    )
  )
}

export function formatDateWithWeekOfDay(date: Date) {
  return formatDate(date, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    weekday: 'long',
  })
}

export function fromIsoStringToDateAndTime(
  isoString: string | undefined | null
): DateAndTime {
  if (!isoString) {
    return {}
  }

  const dateObj = new Date(isoString)
  const isInvalidDateObj =
    dateObj instanceof Date && isNaN(dateObj as unknown as number)
  if (isInvalidDateObj) {
    return {}
  }

  return {
    date: fromDateToDateMessage(dateObj),
    time: fromDateToTime(dateObj),
  }
}

export function convertTimeToTimeInputValue(time: Time) {
  const { hour, minute } = time
  return `${hour ? addLeadingZero(hour) : '00'}:${
    minute ? addLeadingZero(minute) : '00'
  }`
}

type DateParameter = DateAndTime | Date | string | number

function isDateConvertable(value: DateParameter) {
  return value instanceof Date || ['number', 'string'].includes(typeof value)
}

export function formatDateToDateAtTimeLabel(d: DateParameter) {
  const dateObj = isDateConvertable(d) ? new Date(d as Date) : undefined

  if (dateObj) {
    return dateObj.toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
  } else if ('time' in (d as DateAndTime)) {
    const dateWithTime = fromDateTimeToDate(d as DateAndTime) as Date
    return dateWithTime.toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
  } else if ('date' in (d as DateAndTime)) {
    const dateOnly = fromDateMessageToDate(
      (d as DateAndTime).date as DateMessage
    ) as Date
    return dateOnly.toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }

  return ''
}

/**
 * isWithinInterval is inclusive of start date, exclusive of end date.
 * For context - isWithinInterval from date-fns is inclusive of end date.
 * @param dateToReference
 * @param start
 * @param end
 */
export const isWithinInterval = (
  dateToReference: Date,
  { start, end }: { start: Date; end: Date }
): boolean => dateToReference >= start && dateToReference < end

const DEFAULT_BILLING_DATE_FORMAT = 'M/dd/yyyy'

/**
 * Convert and format isoDateString to giving date format, default to 'MM/dd/yyyy'
 */

export function convertISODateStringToLabel(
  isoDateStr: string,
  strFormat: string = DEFAULT_BILLING_DATE_FORMAT
) {
  return format(parseISO(isoDateStr), strFormat)
}

/**
 * Format list of ISO Date to specified pattern, auto filter-out undefined value
 */

export function convertISODateStringsToLabel(
  isoDateStrList: (string | undefined)[],
  strFormat: string = DEFAULT_BILLING_DATE_FORMAT
) {
  const list = isoDateStrList.filter((dStr) => dStr) as string[]

  return list
    .map((dStr) => convertISODateStringToLabel(dStr, strFormat))
    .join(' - ')
}
