import { Task } from '@augusthealth/models/com/august/protos/task'
import { taskSubtitle, taskTitle } from '@shared/utils/task'
import styles from './styles.module.css'

export default function ServicePlanHeader({ task }: { task: Task }) {
  return (
    <>
      <header
        className={`flex items-baseline pb-[16px] ${styles.headerBorder}`}
      >
        <h1 className="mb-0 text-[24px] font-medium leading-[36px]">
          {taskTitle(task)}
        </h1>
        <h2 className="weigh-500 mb-0 ml-[8px] text-[16px] leading-[30px] text-secondary-07">
          {taskSubtitle(task)}
        </h2>
      </header>
      <aside className="mt-[16px]">
        <h3 className="mb-0 text-[16px] font-medium leading-[24px] text-secondary-06">
          The Service Plan uses the appraisal needs from the Resident
          Assessment.
        </h3>
        <h3 className="mb-0 text-[16px] font-medium leading-[24px] text-secondary-06">
          We recommend reviewing and updating the Resident Assessment before
          completing the Service Plan.
        </h3>
      </aside>
    </>
  )
}
