import { Task } from '@augusthealth/models/com/august/protos/task'
import { useContext, useEffect, useState } from 'react'
import { fetchTask } from '@shared/api/task'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { Loading } from '@shared/utils/loading'

export default function useTask({
  person,
  taskId,
}: {
  person: Person
  taskId: string
}) {
  const { setError } = useContext(GlobalContext)
  const [task, setTask] = useState<Loading<Task>>({ tag: 'Loading' })

  const refreshTask = async () => {
    try {
      const result = await fetchTask({
        pId: person.id!,
        taskId,
        orgId: person.orgId!,
      })

      setTask({ tag: 'Complete', value: result.data })
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    void refreshTask()
  }, [person.id, taskId])

  return { task, refreshTask }
}
