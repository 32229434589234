import { compact } from 'lodash'
import {
  ALL_RELATIONSHIP_MAP,
  NO_ROLE_RELATIONSHIP_MAP,
  PERSONAL_RELATIONSHIP_LIST,
  PRIMARY_CARE_ROLES,
  PROFESSIONAL_RELATIONSHIP_LIST,
  RELATIONSHIPS_HASH_BY_CATEGORY_MAP,
  ROLE_RELATIONSHIP_MAP,
  SUB_RELATIONSHIP_MAP,
} from '@shared/constants/contactRelationship'
import { Contact_ContactRelationship as Relationship } from '@shared/types/contact'

const getOtherLabel = (category: Relationship): string | undefined =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  RELATIONSHIPS_HASH_BY_CATEGORY_MAP[category].CONTACT_RELATIONSHIP_OTHER as
    | string
    | undefined

export const getRelationshipLabel = ({
  relationship,
  list,
  hash = ALL_RELATIONSHIP_MAP,
}: {
  relationship: Relationship
  list: Relationship[]
  hash: Partial<Record<Relationship, string>>
}): string | undefined => {
  const label = hash[relationship]

  if (label && relationship === Relationship.CONTACT_RELATIONSHIP_OTHER) {
    if (!Array.isArray(list)) {
      return 'Other'
    } else if (list.includes(Relationship.CONTACT_RELATIONSHIP_PERSONAL)) {
      return getOtherLabel(Relationship.CONTACT_RELATIONSHIP_PERSONAL)
    } else if (list.includes(Relationship.CONTACT_RELATIONSHIP_CLINICIAN)) {
      return getOtherLabel(Relationship.CONTACT_RELATIONSHIP_CLINICIAN)
    } else if (list.includes(Relationship.CONTACT_RELATIONSHIP_PROFESSIONAL)) {
      return getOtherLabel(Relationship.CONTACT_RELATIONSHIP_PROFESSIONAL)
    } else {
      return 'Other'
    }
  }

  return label
}

export const getRoleRelationshipList = (
  relationships: Relationship[]
): string[] => compact(relationships.map((r) => ROLE_RELATIONSHIP_MAP[r]))

export const getAllRelationshipList = (
  relationships: Relationship[]
): string[] =>
  compact(
    relationships.map((r) =>
      getRelationshipLabel({
        relationship: r,
        list: relationships,
        hash: ALL_RELATIONSHIP_MAP,
      })
    )
  )

export const getNonRoleRelationshipList = (
  relationships: Relationship[]
): string[] =>
  compact(
    relationships.map((r) =>
      getRelationshipLabel({
        relationship: r,
        list: relationships,
        hash: NO_ROLE_RELATIONSHIP_MAP,
      })
    )
  )

// Exclude Role, Category and FamilyMember relationships
export const getSubRelationshipList = (
  relationships: Relationship[]
): string[] =>
  compact(
    relationships.map((r) =>
      getRelationshipLabel({
        relationship: r,
        list: relationships,
        hash: SUB_RELATIONSHIP_MAP,
      })
    )
  )

// type = "role" | "non-role" | "sub" | "all (default)"
export const getRelationships = (
  relationships: Relationship[],
  type: 'role' | 'non-role' | 'sub' | 'all',
  options: {
    excludes?: Relationship[]
    jsx?: boolean
    onlyRelationship?: boolean
  }
) => {
  let func: (relationships: Array<Relationship>) => Array<string | undefined>
  if (type === 'role') {
    func = getRoleRelationshipList
  } else if (type === 'non-role') {
    func = getNonRoleRelationshipList
  } else if (type === 'sub') {
    func = getSubRelationshipList
  } else {
    func = getAllRelationshipList
  }

  const { excludes, jsx, onlyRelationship } = options
  let relations: Array<Relationship>
  if (Array.isArray(excludes)) {
    relations = relationships.filter((r) => !excludes.includes(r))
  } else {
    relations = relationships
  }

  // if we only want the lowest level relationship
  let relationStrings = func(relations)
  if (onlyRelationship) {
    // then only the last entry
    relationStrings = [relationStrings[relationStrings.length - 1]]
  }

  let rows: string | React.ReactNode
  if (jsx) {
    rows = relationStrings.map((r) => <div key={r}>{r}</div>)
  } else {
    rows = relationStrings.join(' / ')
  }

  return <div>{rows}</div>
}

export const getMainRole = (relationships: Relationship[]) => {
  const relations = getRoleRelationshipList(relationships)
  let mainRole = ''
  relations.forEach((relationship) => {
    if (
      relationship ===
        ROLE_RELATIONSHIP_MAP.CONTACT_RELATIONSHIP_RESPONSIBLE_PERSON ||
      relationship ===
        ROLE_RELATIONSHIP_MAP.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER
    ) {
      mainRole = relationship
    }
  })

  return mainRole
}

export const getMainRelationship = (relationships: Relationship[]) => {
  const relations = getSubRelationshipList(relationships)

  return relations[0] ? relations[0] : ''
}

export const isPrimaryCareProviderEligible = (relationships: Relationship[]) =>
  relationships.some((relationship) =>
    PRIMARY_CARE_ROLES.includes(relationship)
  )

// Since Other is shared between Personal and Clinician,
// We need to make sure to be Personal Other or Profesional Other
export const isResponsiblePersonEligible = (relationships: Relationship[]) => {
  const isPersonal = relationships.includes(
    Relationship.CONTACT_RELATIONSHIP_PERSONAL
  )
  const isProfessional = relationships.includes(
    Relationship.CONTACT_RELATIONSHIP_PROFESSIONAL
  )

  if (isPersonal) {
    return relationships.some((r) => PERSONAL_RELATIONSHIP_LIST.includes(r))
  }

  if (isProfessional) {
    return relationships.some((r) => PROFESSIONAL_RELATIONSHIP_LIST.includes(r))
  }

  return false
}
