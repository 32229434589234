import {
  PdfProj,
  SignableFormAssignment,
} from '@augusthealth/models/com/august/protos/signable_form'
import { useContext, useEffect, useState } from 'react'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import StyledSelect, { OptionTypeBase } from '@shared/components/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Loading } from '@shared/utils/loading'
import { StateAbbreviation, stateOptions } from '@shared/utils/state'
import { tw } from '@shared/utils/tailwind'
import { getAssignmentsForState } from '@app/api/form'
import PersonPageTitle from '@app/components/PersonPageTitle'
import { useSignableFormContext } from '../SignableFormContext'
import AssignmentList from './AssignmentList'
import AssignPopup from './AssignPopup'
import { sortAssignmentsByState } from './helpers'

export default function NotifyState() {
  const { setError } = useContext(GlobalContext)
  const { signableForms } = useSignableFormContext('All Templates')
  const [openAssignPopup, setOpenAssignPopup] = useState(false)
  const [usState, setUsState] = useState<OptionTypeBase<StateAbbreviation>>()
  const [stateAssignments, setStateAssignments] = useState<
    Loading<SignableFormAssignment[]>
  >({ tag: 'Loading' })

  const loadAssignments = async () => {
    if (usState) {
      setStateAssignments({ tag: 'Loading' })
      void getAssignmentsForState(usState.value).then((assignments) => {
        setStateAssignments({ tag: 'Complete', value: assignments })
      })
    }
  }

  useEffect(() => {
    try {
      void loadAssignments()
    } catch (e) {
      setError(e)
    }
  }, [usState])

  const allAssignments =
    (stateAssignments.tag === 'Complete' && stateAssignments.value) || []
  const incidentReportForms =
    (signableForms.tag === 'Complete' &&
      signableForms.value.filter(
        (f) => f.projectionType === PdfProj.PDF_PROJ_INCIDENT_REPORT
      )) ||
    []
  const signableFormIds = incidentReportForms.map((f) => f.id)
  const filteredAssignments = allAssignments.filter((a) =>
    signableFormIds.includes(a.signableFormId)
  )

  return (
    <div className="mt-[24px]">
      <PersonPageTitle subtitle title="Notify State">
        <Button
          buttonStyle="primary-fill"
          buttonSize="small"
          onClick={() => {
            setUsState(undefined)
            setOpenAssignPopup(true)
          }}
        >
          Assign Incident State Template
        </Button>
      </PersonPageTitle>
      <StyledSelect
        options={stateOptions}
        value={usState ?? null}
        onChange={(newValue: OptionTypeBase<StateAbbreviation>) => {
          setUsState(newValue)
        }}
      />
      {usState === undefined && (
        <div className={tw`mt-[16px]`}>Select a state</div>
      )}
      {usState && stateAssignments.tag === 'Complete' && (
        <AssignmentList
          assignments={filteredAssignments}
          forms={incidentReportForms}
          sortFunc={sortAssignmentsByState}
          reloadAssignments={loadAssignments}
        />
      )}
      {usState && stateAssignments.tag === 'Loading' && (
        <div className={tw`mt-[16px]`}>Loading</div>
      )}
      {openAssignPopup && (
        <AssignPopup
          signableForms={incidentReportForms}
          onClose={() => setOpenAssignPopup(false)}
        />
      )}
    </div>
  )
}
