import {
  billingPathForPerson,
  contactsPathForPerson,
  medicationsPathForPerson,
} from '@shared/legacy_routes'
import {
  CompletionType,
  ResidentCompletionStats,
} from '@shared/types/api/facility_stats'
import { PersonTasks } from '@shared/types/api/person_tasks'
import { Person } from '@shared/types/person'
import { DataType, SignerRole } from '@shared/types/snapshot'
import {
  CompletionTypeToTemplateHash,
  getLabelForCompletionType,
} from '@shared/utils/facilityStats'
import { isIncomplete, nextSigner } from '@shared/utils/task'
import { recreateTaskAndReturnUrl } from '@app/pages/Documents/helpers'

export type ResidentTask = {
  name: string
  status: 'Complete' | 'Incomplete'
  cta: { text: string; action: () => Promise<void> | void }
  stat: PossibleTasks
}

export type PossibleTasks = Exclude<
  CompletionType,
  'UNRECOGNIZED' | 'COMPLETION_TYPE_UNSPECIFIED'
>

type ResidentTaskProps = {
  stat: PossibleTasks
  completionTypeToTemplateHash: CompletionTypeToTemplateHash
  personStats: ResidentCompletionStats
  personTasks: Partial<PersonTasks>
  history: any
  setShowDietModal: React.Dispatch<React.SetStateAction<boolean>>
  setShowPolstUploader: React.Dispatch<React.SetStateAction<boolean>>
  setShow602Uploader: React.Dispatch<React.SetStateAction<boolean>>
  setShowEditAmbulatory: React.Dispatch<React.SetStateAction<boolean>>
  setShowReminder: React.Dispatch<React.SetStateAction<boolean>>
  setShowProfile?: React.Dispatch<React.SetStateAction<boolean>>
}

export const getTaskForItem = ({
  stat,
  completionTypeToTemplateHash,
  personStats,
  personTasks,
  history,
  setShowDietModal,
  setShowProfile,
  setShowPolstUploader,
  setShow602Uploader,
  setShowEditAmbulatory,
  setShowReminder,
}: ResidentTaskProps): ResidentTask | undefined => {
  const name = getLabelForCompletionType({
    completionType: stat,
    completionTypeToTemplateHash,
    withMoreDetails: true,
  })
  let ctaText: ResidentTask['cta']['text'] | undefined
  let ctaAction: ResidentTask['cta']['action'] | undefined
  if (stat === CompletionType.COMPLETION_TYPE_HAS_PROFILE_PHOTO) {
    // only able to set profile from individual resident page
    if (setShowProfile) {
      ctaText = 'upload'
      ctaAction = () => setShowProfile(true)
    }
  } else if (stat === CompletionType.COMPLETION_TYPE_HAS_MEDICATIONS) {
    ctaText = 'get started'
    ctaAction = () =>
      history.push(
        medicationsPathForPerson(personStats.person as Required<Person>)
      )
  } else if (stat === CompletionType.COMPLETION_TYPE_HAS_DIET) {
    ctaText = 'get started'
    ctaAction = () => setShowDietModal(true)
  } else if (stat === CompletionType.COMPLETION_TYPE_HAS_AMBULATORY_STATUS) {
    ctaText = 'add'
    ctaAction = () => setShowEditAmbulatory(true)
  } else if (stat === CompletionType.COMPLETION_TYPE_HAS_BILLING) {
    ctaText = 'get started'
    ctaAction = () =>
      history.push(billingPathForPerson(personStats.person as Required<Person>))
  } else if (stat === CompletionType.COMPLETION_TYPE_HAS_PRIMARY_DR) {
    ctaText = 'add contact'
    ctaAction = () =>
      history.push(
        contactsPathForPerson(personStats.person as Required<Person>)
      )
  } else if (stat === CompletionType.COMPLETION_TYPE_HAS_RESPONSIBLE_PERSON) {
    ctaText = 'add contact'
    ctaAction = () =>
      history.push(
        contactsPathForPerson(personStats.person as Required<Person>)
      )
  } else if (stat === CompletionType.COMPLETION_TYPE_HAS_POLST) {
    ctaText = 'upload'
    ctaAction = () => setShowPolstUploader(true)
  } else if (stat === CompletionType.COMPLETION_TYPE_HAS_602) {
    ctaText = 'upload'
    ctaAction = () => setShow602Uploader(true)
  } else if (stat === CompletionType.COMPLETION_TYPE_HAS_APPRAISAL) {
    ctaText = 'get started'
    ctaAction = async () => {
      const url = await recreateTaskAndReturnUrl(
        personStats.person!,
        undefined,
        DataType.DATA_TYPE_AUGUST_INITIAL_APPRAISAL
      )
      history.push(url)
    }
  } else if (stat === CompletionType.COMPLETION_TYPE_HAS_SERVICE_PLAN) {
    return getServicePlanCTA(
      name,
      personTasks,
      personStats,
      stat,
      setShowReminder,
      history
    )
  }

  return name && ctaText && ctaAction
    ? {
        name,
        status: personStats.completionStatus![stat] ? 'Complete' : 'Incomplete',
        cta: {
          text: ctaText,
          action: ctaAction,
        },
        stat,
      }
    : undefined
}

function getServicePlanCTA(
  name: string,
  personTasks: Partial<PersonTasks>,
  personStats: ResidentCompletionStats,
  stat: PossibleTasks,
  setShowReminder: React.Dispatch<React.SetStateAction<boolean>>,
  history: any
): ResidentTask {
  const servicePlanTask = (personTasks.tasks ?? [])
    .filter(isIncomplete)
    .find(
      (t) => t.taskTemplateInfo!.dataType === DataType.DATA_TYPE_SERVICE_PLAN
    )

  let ctaText = 'get started'
  let ctaAction = async () => {
    const url = await recreateTaskAndReturnUrl(
      personStats.person!,
      servicePlanTask ? servicePlanTask : undefined,
      DataType.DATA_TYPE_SERVICE_PLAN
    )
    history.push(url)
  }
  if (
    servicePlanTask &&
    nextSigner(servicePlanTask)?.role ===
      SignerRole.SIGNER_ROLE_RESPONSIBLE_PARTY
  ) {
    ctaText = 'send reminder'
    ctaAction = async () => {
      setShowReminder(true)
    }
  } else if (servicePlanTask) {
    ctaText = 'continue'
  }
  return {
    name,
    status: personStats.completionStatus![stat] ? 'Complete' : 'Incomplete',
    cta: {
      text: ctaText,
      action: ctaAction,
    },
    stat,
  }
}
