import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import FinancialMoveOutDateModal from 'app/components/Layouts/PersonNavbarLayout/DischargedResidentBanner/FinancialMoveOutDateModal'
import { useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import { SmallDotDivider } from '@shared/components/DotDivider'
import Icon from '@shared/components/Icon'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { Person } from '@shared/types/person'
import { formatDate } from '@shared/utils/date'
import {
  getResidentFinancialEndDate,
  getResidentMoveOutDate,
} from '@shared/utils/person'
import { tw } from '@shared/utils/tailwind'
import Banner from '@app/components/Banner'
import VerifyAdmissionDetails from '@app/components/Prospects/VerifyAdmissionDetails/VerifyAdmissionDetails'

export default function DischargedResidentBanner({
  person,
}: {
  person: Person
}) {
  const [showAdmissionDetails, setShowAdmissionDetails] = useState(false)
  const [showFinancialDateModal, setShowFinancialDateModal] = useState(false)
  const hasFinancialMoveOutDate = Boolean(
    person.dischargeInformation?.financialMoveOutDate
  )
  const reAdmitLink = (
    <ButtonLink
      className={tw`underline`}
      linkStyle={'secondary'}
      onClick={() => setShowAdmissionDetails(true)}
    >
      Re-admit
    </ButtonLink>
  )
  const financialMoveOutLink = (
    <ButtonLink
      className={tw`underline`}
      linkStyle={'secondary'}
      onClick={() => setShowFinancialDateModal(true)}
    >
      {hasFinancialMoveOutDate ? 'Edit' : 'Add'} financial effective end date
    </ButtonLink>
  )

  return (
    <>
      <Banner
        description={{
          copy: <BannerCopy person={person} />,
        }}
      >
        <PersonPermissionGate
          person={person}
          permissions={[GroupPermission.GROUP_PERMISSION_PERSON_UPDATE]}
        >
          <div>
            {hasFinancialMoveOutDate ? (
              <>
                <div className={tw`text-right`}>{reAdmitLink}</div>
                <div className={tw`mt-[12px] text-right`}>
                  {financialMoveOutLink}
                </div>
              </>
            ) : (
              <>
                {financialMoveOutLink}
                <SmallDotDivider />
                {reAdmitLink}
              </>
            )}
          </div>
        </PersonPermissionGate>
      </Banner>
      {showAdmissionDetails && (
        <VerifyAdmissionDetails
          convertToCurrentResident={true}
          person={person}
          doneFn={setShowAdmissionDetails}
        />
      )}
      {showFinancialDateModal && (
        <FinancialMoveOutDateModal
          person={person}
          onClose={() => setShowFinancialDateModal(false)}
        />
      )}
    </>
  )
}

function BannerCopy({ person }: { person: Person }) {
  return (
    <div>
      <div>
        <Icon
          name="house-person-leave"
          className={tw`mr-[12px] text-[16px] text-white`}
        />
        Resident moved-out on
        {person.dischargeInformation?.moveOutDate && (
          <span className={tw`font-semibold text-white`}>
            {' '}
            {formatDate(getResidentMoveOutDate(person))}
          </span>
        )}
      </div>
      {person.dischargeInformation?.financialMoveOutDate && (
        <div className={tw`mt-[12px]`}>
          <Icon
            name="calendar-day"
            className={tw`mr-[12px] text-[16px] text-white`}
          />
          Financial effective end date is
          <span className={tw`font-semibold text-white`}>
            {' '}
            {formatDate(getResidentFinancialEndDate(person))}
          </span>
        </div>
      )}
    </div>
  )
}
