import classNames from 'classnames'
import Card from '@shared/components/Card'
import { twx } from '@shared/utils/tailwind'
import styles from './styles.module.css'
import { OptionComponentProps } from '../helpers'

type Props = OptionComponentProps & {
  iconClass?: string
  subLabel?: string
  svgPath?: string
}

export default function CardOption({
  value,
  onClick,
  label,
  iconClass,
  subLabel,
  svgPath,
  checked,
}: Props) {
  const classNameTw = twx(
    `text-secondary pl-[16px] pr-[16px] mb-[8px] min-h-[112px]`,
    {
      'cursor-pointer': Boolean(onClick),
      'text-white bg-primary-light': checked,
    }
  )
  const labelHolderClassName = classNames('flex-1', styles.labelHolder)
  const testId = `card-option-${value}`
  // Only show subLabel when checked
  let content =
    subLabel && checked ? (
      <div data-testid={`${testId}-checked`} className={labelHolderClassName}>
        <div>{`${label || value}`}</div>
        <div className={styles.subLabel}>{subLabel}</div>
      </div>
    ) : (
      <div data-testid={testId} className={labelHolderClassName}>
        {`${label || value}`}
      </div>
    )

  if (iconClass || svgPath) {
    let icon
    if (iconClass) {
      icon = <i className={classNames(iconClass, styles.icon)} />
    } else {
      const iconClass = classNames({
        'text-white': checked,
        'text-secondary-color': !checked,
      })
      icon = <img className={iconClass} src={svgPath} alt={label} />
    }

    content = (
      <>
        <div className="flex-0 mr-[16px]">{icon}</div>
        {content}
      </>
    )
  }

  return (
    <Card
      key={`${value}-radio`}
      className={classNameTw}
      onClick={() => onClick(value)}
    >
      {content}
    </Card>
  )
}
