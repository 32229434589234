import { InvoiceData, InvoiceStatus } from '@shared/types/billing'

function isSpecificStatus(
  invoiceData: InvoiceData,
  specificStatus: InvoiceStatus
): boolean {
  return invoiceData.status === specificStatus
}

export function isPending(invoiceData: InvoiceData): boolean {
  return isSpecificStatus(invoiceData, InvoiceStatus.PENDING)
}

export function isPaid(invoiceData: InvoiceData): boolean {
  return isSpecificStatus(invoiceData, InvoiceStatus.PAID)
}

export function isDue(invoiceData: InvoiceData): boolean {
  return isSpecificStatus(invoiceData, InvoiceStatus.DUE)
}

export function isApproved(invoiceData: InvoiceData): boolean {
  return isSpecificStatus(invoiceData, InvoiceStatus.APPROVED)
}

export function isNeedsWork(invoiceData: InvoiceData): boolean {
  return isSpecificStatus(invoiceData, InvoiceStatus.NEEDS_WORK)
}

export function isError(invoiceData: InvoiceData): boolean {
  return isSpecificStatus(invoiceData, InvoiceStatus.ERROR)
}
