import {
  AssessmentReason,
  CustomDetailEnum,
} from '@shared/types/assessment_configuration'
import { ServicePlan_PlanCategoryKey as CatKey } from '@shared/types/service_plan'
import {
  AppraisalSettings_AdditionalServicesDetails,
  AppraisalSettings_AssessmentReason,
  AppraisalSettings_AssessmentReasonWrapper,
  AppraisalSettings_BathingDetails,
  AppraisalSettings_CareCoordinationDetails,
  AppraisalSettings_CognitiveDetails,
  AppraisalSettings_CommunicationDetails,
  AppraisalSettings_CustomDetails,
  AppraisalSettings_DentalDetails,
  AppraisalSettings_DispositionAndBehaviorsDetails,
  AppraisalSettings_DressingDetails,
  AppraisalSettings_GroomingDetails,
  AppraisalSettings_HearingDetails,
  AppraisalSettings_MealsAndNutritionDetails,
  AppraisalSettings_MedicationManagementDetails,
  AppraisalSettings_MobilityDetails,
  AppraisalSettings_PsychoSocialEngagementDetails,
  AppraisalSettings_SleepDetails,
  AppraisalSettings_SpecialCareNeedsDetails,
  AppraisalSettings_ToiletingDetails,
  AppraisalSettings_TransferAndMobilityDetails,
  AppraisalSettings_TransferDetails,
  AppraisalSettings_VisionDetails,
  AppraisalSettings_WanderingAndElopementDetails,
} from '@shared/types/settings/appraisal_settings'

export const categoryKeyToEnumMap = {
  [CatKey.PLAN_CATEGORY_KEY_BATHING]: AppraisalSettings_BathingDetails,
  [CatKey.PLAN_CATEGORY_KEY_CARE_COORDINATION]:
    AppraisalSettings_CareCoordinationDetails,
  [CatKey.PLAN_CATEGORY_KEY_DRESSING]: AppraisalSettings_DressingDetails,
  [CatKey.PLAN_CATEGORY_KEY_GROOMING]: AppraisalSettings_GroomingDetails,
  [CatKey.PLAN_CATEGORY_KEY_DENTAL]: AppraisalSettings_DentalDetails,
  [CatKey.PLAN_CATEGORY_KEY_TOILETING]: AppraisalSettings_ToiletingDetails,
  [CatKey.PLAN_CATEGORY_KEY_TRANSFER_AND_MOBILITY]:
    AppraisalSettings_TransferAndMobilityDetails,
  [CatKey.PLAN_CATEGORY_KEY_HEARING]: AppraisalSettings_HearingDetails,
  [CatKey.PLAN_CATEGORY_KEY_VISION]: AppraisalSettings_VisionDetails,
  [CatKey.PLAN_CATEGORY_KEY_MEALS_AND_NUTRITION]:
    AppraisalSettings_MealsAndNutritionDetails,
  [CatKey.PLAN_CATEGORY_KEY_SPECIAL_CARE_NEEDS]:
    AppraisalSettings_SpecialCareNeedsDetails,
  [CatKey.PLAN_CATEGORY_KEY_COGNITIVE]: AppraisalSettings_CognitiveDetails,
  [CatKey.PLAN_CATEGORY_KEY_WANDERING_AND_ELOPEMENT]:
    AppraisalSettings_WanderingAndElopementDetails,
  [CatKey.PLAN_CATEGORY_KEY_DISPOSITION_AND_BEHAVIORS]:
    AppraisalSettings_DispositionAndBehaviorsDetails,
  [CatKey.PLAN_CATEGORY_KEY_SLEEP]: AppraisalSettings_SleepDetails,
  [CatKey.PLAN_CATEGORY_KEY_COMMUNICATION]:
    AppraisalSettings_CommunicationDetails,
  [CatKey.PLAN_CATEGORY_KEY_PSYCHO_SOCIAL_ENGAGEMENT]:
    AppraisalSettings_PsychoSocialEngagementDetails,
  [CatKey.PLAN_CATEGORY_KEY_MEDICATION_MANAGEMENT]:
    AppraisalSettings_MedicationManagementDetails,
  [CatKey.PLAN_CATEGORY_KEY_TRANSFER]: AppraisalSettings_TransferDetails,
  [CatKey.PLAN_CATEGORY_KEY_MOBILITY]: AppraisalSettings_MobilityDetails,
  [CatKey.PLAN_CATEGORY_KEY_ADDITIONAL_SERVICES]:
    AppraisalSettings_AdditionalServicesDetails,
}

export const categoryKeyToPropertyMap = {
  [CatKey.PLAN_CATEGORY_KEY_BATHING]: 'bathingDetails',
  [CatKey.PLAN_CATEGORY_KEY_CARE_COORDINATION]: 'careCoordinationDetails',
  [CatKey.PLAN_CATEGORY_KEY_DRESSING]: 'dressingDetails',
  [CatKey.PLAN_CATEGORY_KEY_GROOMING]: 'groomingDetails',
  [CatKey.PLAN_CATEGORY_KEY_DENTAL]: 'dentalDetails',
  [CatKey.PLAN_CATEGORY_KEY_TOILETING]: 'toiletingDetails',
  [CatKey.PLAN_CATEGORY_KEY_TRANSFER_AND_MOBILITY]:
    'transferAndMobilityDetails',
  [CatKey.PLAN_CATEGORY_KEY_HEARING]: 'hearingDetails',
  [CatKey.PLAN_CATEGORY_KEY_VISION]: 'visionDetails',
  [CatKey.PLAN_CATEGORY_KEY_MEALS_AND_NUTRITION]: 'mealsAndNutritionDetails',
  [CatKey.PLAN_CATEGORY_KEY_SPECIAL_CARE_NEEDS]: 'specialCareNeedsDetails',
  [CatKey.PLAN_CATEGORY_KEY_COGNITIVE]: 'cognitiveDetails',
  [CatKey.PLAN_CATEGORY_KEY_WANDERING_AND_ELOPEMENT]:
    'wanderingAndElopementDetails',
  [CatKey.PLAN_CATEGORY_KEY_DISPOSITION_AND_BEHAVIORS]:
    'dispositionAndBehaviorsDetails',
  [CatKey.PLAN_CATEGORY_KEY_SLEEP]: 'sleepDetails',
  [CatKey.PLAN_CATEGORY_KEY_COMMUNICATION]: 'communicationDetails',
  [CatKey.PLAN_CATEGORY_KEY_PSYCHO_SOCIAL_ENGAGEMENT]:
    'psychoSocialEngagementDetails',
  [CatKey.PLAN_CATEGORY_KEY_MEDICATION_MANAGEMENT]:
    'medicationManagementDetails',
  [CatKey.PLAN_CATEGORY_KEY_TRANSFER]: 'transferDetails',
  [CatKey.PLAN_CATEGORY_KEY_MOBILITY]: 'mobilityDetails',
  [CatKey.PLAN_CATEGORY_KEY_ADDITIONAL_SERVICES]: 'additionalServicesDetails',
}

export function assessmentReasonToDisplay(
  reason: AssessmentReason | AppraisalSettings_AssessmentReasonWrapper
): string | undefined {
  switch (reason.assessmentReason) {
    case AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_RECURRING:
      return 'Scheduled Reassessment'
    case AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_PRE_ADMISSION:
      return 'Pre-Move In'
    case AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_ON_ADMISSION:
      return 'Move In'
    case AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_7_DAY:
      return '7 Day'
    case AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_14_DAY:
      return '14 Day'
    case AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_30_DAY:
      return '30 Day'
    case AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_90_DAY:
      return '90 Day'
    case AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_CHANGE_OF_CONDITION:
      return 'Change of Condition'
    case AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_ALIGNMENT:
      return 'Points/Level Alignment'
    case AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_OTHER:
      return reason.otherReason
    case AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_UNSPECIFIED:
      return undefined
    case AppraisalSettings_AssessmentReason.UNRECOGNIZED:
      return undefined
    default:
      return undefined
  }
}

export function humanizeReason(
  reason: AssessmentReason | AppraisalSettings_AssessmentReasonWrapper
): string | undefined {
  const term = assessmentReasonToDisplay(reason)

  if (
    reason.assessmentReason &&
    [
      AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_7_DAY,
      AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_14_DAY,
      AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_30_DAY,
      AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_90_DAY,
    ].includes(reason.assessmentReason)
  ) {
    return `Recurring ${term} Schedule`
  }

  return term
}

export const isCustomTextDetail = (
  detail: CustomDetailEnum | undefined
): boolean => {
  if (detail === undefined) {
    return false
  }

  return [
    AppraisalSettings_CustomDetails.CUSTOM_DETAILS_TEXTBOX,
    AppraisalSettings_CustomDetails.CUSTOM_DETAILS_TEXTAREA,
    // @ts-expect-error - detail could be a custom dropdown, which is not included as an enum from backend
  ].includes(detail)
}
