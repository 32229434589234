import { ChangeEvent, useEffect, useState } from 'react'
import { BasicInputProps } from '@shared/components/BasicInput/BasicInput'
import ElementHolder from '../formElements/ElementHolder'
import MoneyInput from './index'
import { convertCentsToDollars, convertDollarsToCents } from './utils'

type Props = Omit<BasicInputProps, 'value'> & {
  value?: string
  onUpdate: (value: string | number | undefined, name?: string) => void
}

export default function Money(props: Props) {
  const { onUpdate, ...restProps } = props
  const { name, value = '', min, max } = restProps
  const [inputValue, setInputValue] = useState<string>(
    convertCentsToDollars({ value })
  )

  useEffect(() => {
    if (inputValue !== convertCentsToDollars({ value })) {
      setInputValue(convertCentsToDollars({ value }))
    }
  }, [value])

  const uiProps: BasicInputProps = { ...restProps }
  const change = (ev: ChangeEvent<HTMLInputElement>) => {
    const val = ev.target.value.trim()
    if ((max !== undefined && val > max) || (min !== undefined && val < min)) {
      setInputValue(inputValue)
    } else {
      setInputValue(val)
    }
  }
  const update = (ev) => {
    const val = ev.target.value.trim()
    onUpdate(convertDollarsToCents({ value: val }), name)
  }
  uiProps.type = 'number' // convert type money to number
  uiProps.value = inputValue
  uiProps.onChange = change
  uiProps.onBlur = update
  uiProps.onKeyUp = (ev) => ev.key === 'Enter' && update(ev)
  return (
    <ElementHolder {...props}>
      <MoneyInput inputProps={{ ...uiProps }} />
    </ElementHolder>
  )
}
