import { first, isEmpty, keys, omit, some } from 'lodash'
import { match, P } from 'ts-pattern'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import { EmarMedicationAdministration } from '@shared/types/emar'
import { MedAdministrationEvent } from '@shared/types/medication_administration'
import { formatDateTimeInZone } from '@shared/utils/date'
import { getFullName } from '@shared/utils/humanName'
import { tw } from '@shared/utils/tailwind'

interface Props {
  facilityTimeZone: string
  medAdministration: EmarMedicationAdministration
  onClose: () => void
  use24HourClock: boolean
}

export default function DetailsModal({
  medAdministration,
  facilityTimeZone,
  use24HourClock,
  onClose,
}: Props) {
  const eventType = (event: MedAdministrationEvent) => {
    const eventName = first(keys(omit(event, ['id', 'parentId', 'occurredAt'])))
    return { label: eventName, payload: event[eventName!] }
  }

  const camelCaseToSentence = (string: string | undefined): string => {
    if (string) {
      const result = string.replace(/([A-Z])/g, ' $1')
      return result.charAt(0).toUpperCase() + result.slice(1)
    } else {
      return ''
    }
  }

  return (
    <Modal
      id="med-administration-details-modal"
      open={true}
      onClose={onClose}
      contentClassName={tw`w-[664px]`}
    >
      <ModalTitle>Med Administration Details</ModalTitle>
      <div className="flex flex-col gap-4">
        <div>
          <div>
            <strong>ID</strong>: <code>{medAdministration.id}</code>
          </div>
          <div>
            <strong>Status</strong>: <code>{medAdministration.status}</code>
          </div>
          <div>
            <strong>Active</strong>:{' '}
            {medAdministration.isActive ? 'true' : 'false'}
          </div>
          {match(medAdministration.performer)
            .with(P.nonNullable, (performer) => (
              <div>
                <strong>Perfomer</strong>: {getFullName(performer.userName)} (
                {performer.userId})
              </div>
            ))
            .otherwise(() => null)}
          {!medAdministration.reasonForException.match(/unspecified/i) && (
            <div>
              <strong>Reason for Exception</strong>:{' '}
              {medAdministration.reasonForException}
            </div>
          )}
          {medAdministration.occurrence && (
            <div>
              <strong>Occurred At</strong>:{' '}
              {formatDateTimeInZone(
                medAdministration.occurrence,
                facilityTimeZone,
                { use24HourClock, includeDate: true }
              )}
            </div>
          )}
          {medAdministration.note && (
            <div>
              <strong>Note</strong>: {medAdministration.note}
            </div>
          )}
          {some(medAdministration.conflicts) && (
            <p>
              <strong>Conflicts</strong>:{' '}
              {medAdministration.conflicts.join(', ')}
            </p>
          )}
        </div>
        <CollapsibleCard
          defaultExpanded={false}
          collapsedIcon=""
          cardTitle={`Events (${medAdministration.events.length})`}
        >
          <div className="space-y-4">
            {medAdministration.events.map((event) => (
              <div key={event.id}>
                <p className="text-lg font-bold">
                  {camelCaseToSentence(eventType(event).label)}
                </p>
                <p>
                  ID: <code>{event.id}</code>
                </p>
                {event.parentId && (
                  <p>
                    Parent ID: <code>{event.parentId}</code>
                  </p>
                )}
                {event.performer && (
                  <div>
                    Perfomer: {getFullName(event.performer.userName)} (
                    {event.performer.userId})
                  </div>
                )}
                <p>
                  Occurred At:{' '}
                  {formatDateTimeInZone(event.occurredAt, facilityTimeZone!, {
                    use24HourClock,
                    includeDate: true,
                  })}
                </p>
                {!isEmpty(eventType(event).payload) && (
                  <pre className="rounded border border-gray-09 bg-gray-11 p-2">
                    {JSON.stringify(eventType(event).payload, null, 2)}
                  </pre>
                )}
              </div>
            ))}
          </div>
        </CollapsibleCard>
      </div>
    </Modal>
  )
}
