import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import React, { useState } from 'react'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import ButtonLink from '@shared/components/ButtonLink'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { Person } from '@shared/types/person'
import { tw, twx } from '@shared/utils/tailwind'
import { usePersonInContext } from '@app/hooks/usePerson'
import ContactCard from '@app/pages/Contacts/ContactCard'
import { EditContactModal } from '@app/pages/Contacts/ContactModal/ContactModal'

export default function ContactLink({
  contact,
  linkContent,
  linkStyles,
  person,
  allowEditingWithPermissions = false,
  children = null,
}: {
  contact: Contact
  linkContent: React.ReactNode
  person: Person
  linkStyles?: string
  allowEditingWithPermissions?: boolean
  children?: React.ReactNode
}) {
  const [showContactCard, setShowContactCard] = useState(false)
  const [showEditContactModal, setShowEditContactModal] = useState(false)
  const { refreshPerson } = usePersonInContext({
    initialData: person,
    skipFirstFetch: true,
  })

  return (
    <>
      <ButtonLink
        className={linkStyles}
        data-testid="contact-link"
        onClick={() => setShowContactCard(true)}
      >
        {linkContent}
      </ButtonLink>
      <Modal
        id={'contact-card'}
        open={showContactCard && !showEditContactModal}
        onClose={() => {
          setShowContactCard(false)
        }}
        contentClassName={tw`w-[336px] min-w-[336px] p-0`}
      >
        <ContactCard contact={contact} gridIndex={0} linkable={false}>
          {allowEditingWithPermissions && (
            <PersonPermissionGate
              person={person}
              permissions={[
                GroupPermission.GROUP_PERMISSION_PERSON_CONTACT_UPDATE,
              ]}
            >
              <div className={twx('mt-[18px] border-t border-t-gray-11')}>
                <ButtonLink
                  data-testid="edit-btn"
                  className="mt-[24px] text-[14px] uppercase"
                  onClick={() => setShowEditContactModal(true)}
                >
                  <i className="fa-solid fa-pencil mr-[6px]" />
                  <span className="font-bold">Edit</span>
                </ButtonLink>
              </div>
            </PersonPermissionGate>
          )}
          {children}
        </ContactCard>
      </Modal>
      {showEditContactModal && (
        <EditContactModal
          contact={contact}
          person={person!}
          onClose={async (updated) => {
            if (updated) {
              await refreshPerson()
            }
            // TODO: should we reload the page so the contacts grid gets updated?

            setShowEditContactModal(false)
          }}
        />
      )}
    </>
  )
}
