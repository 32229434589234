import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { Task, TaskStatus } from '@augusthealth/models/com/august/protos/task'
import { Link } from 'react-router-dom'
import Badge from '@shared/components/Badge'
import Icon from '@shared/components/Icon'
import { useHasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import { getTaskUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { SignerRole } from '@shared/types/snapshot'
import { formatTimeAgo, fromDateMessageToDate } from '@shared/utils/date'
import { tw, twx } from '@shared/utils/tailwind'
import { nextSigner, taskSubtitle, taskTitle } from '@shared/utils/task'
import { dueDateLabelText, isTodoDate } from './helpers'

export function TaskListItem({
  task,
  person,
  onClickUpload,
  id,
}: {
  task: Task
  person: Person
  onClickUpload: (task: Task, person: Person) => void
  id: string
}) {
  const canEditTask = useHasPermissionForPerson({
    permissions: [GroupPermission.GROUP_PERMISSION_TASK_UPDATE],
    person,
  })

  const { dueDate, lastModification } = task
  const lastUpdated = formatTimeAgo(lastModification?.modificationTime)

  const onRowClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (!canEditTask) {
      return
    }

    if (task.taskTemplateInfo?.isUpload) {
      e.preventDefault()
      onClickUpload(task, person)
    }
  }

  let dueDateBadge
  if (dueDate) {
    const isTodo = isTodoDate(fromDateMessageToDate(dueDate)!)
    dueDateBadge = (
      <Badge key={task.id} color={isTodo ? 'orange' : 'gray'}>
        {dueDateLabelText(fromDateMessageToDate(dueDate)!)}
      </Badge>
    )
  }

  const linkTo = !canEditTask
    ? '#'
    : getTaskUrl({
        orgId: person.orgId || '',
        facilityId: person.facilityId || '',
        id: task.id || '',
        personId: person.id || '',
      })

  return (
    <li id={id}>
      <Link
        onClick={onRowClick}
        to={linkTo}
        className={twx(
          'flex items-center justify-between',
          'min-h-[40px] leading-[16px] text-gray-07',
          'border-bottom-separator px-[8px] py-[10px]',
          'hover:no-underline',
          {
            'cursor-default': !canEditTask,
            'cursor-pointer hover:bg-gray-13': canEditTask,
          }
        )}
      >
        <div className={tw`flex w-[50%] justify-between`}>
          <div className={tw`w-[70%] text-[14px] font-medium text-gray-04`}>
            {taskTitle(task)}
            <span className={tw`ml-[8px] text-[11px]`}>
              {taskSubtitle(task)}
            </span>
          </div>
          {lastUpdated && (
            <div className={tw`text-[11px]`}>Last update {lastUpdated}</div>
          )}
        </div>
        <div className={tw`flex w-[34%] items-center justify-between`}>
          <div className={tw`heading-[1em] [&>*]:mr-[4px]`}>
            {dueDateBadge}
            <BadgeForTask task={task} canEditTask={canEditTask} />
          </div>
          <div
            className={tw`whitespace-nowrap text-[13px] uppercase hover:text-gray-06`}
          >
            <CTATextForTask task={task} />
          </div>
        </div>
      </Link>
    </li>
  )
}

function BadgeForTask({
  task,
  canEditTask,
}: {
  task: Task
  canEditTask: boolean
}) {
  const subject = canEditTask ? 'your' : 'admin'

  switch (task.status) {
    case TaskStatus.TASK_STATUS_ASSIGNED_TO_ADMIN:
      return <>&nbsp;</>
    case TaskStatus.TASK_STATUS_ASSIGNED_TO_RP:
      return <Badge color="purple">Shared</Badge>
    case TaskStatus.TASK_STATUS_NOT_STARTED:
      return <>&nbsp;</>
    case TaskStatus.TASK_STATUS_AWAITING_REVIEW:
      if (canEditTask) {
        return <Badge color="green">Ready to Review</Badge>
      } else {
        return <Badge color="green">Ready for {subject} review</Badge>
      }
    case TaskStatus.TASK_STATUS_PENDING_SIGNATURES:
      switch (nextSigner(task)?.role) {
        case SignerRole.SIGNER_ROLE_ADMIN:
          return <Badge color="green">Ready for {subject} Signature</Badge>
        case SignerRole.SIGNER_ROLE_RESPONSIBLE_PARTY:
          return <Badge color="purple">Waiting for Other Signatures</Badge>
        default:
          return <Badge color="green">Processing</Badge>
      }
    default:
      return <Badge color="orange">Unknown</Badge>
  }
}

function CTATextForTask({ task }: { task: Task }) {
  const chevron = <Icon name="angle-right" className={tw`far ml-[4px]`} />

  switch (task.status) {
    case TaskStatus.TASK_STATUS_ASSIGNED_TO_ADMIN:
      return <>Continue {chevron}</>
    case TaskStatus.TASK_STATUS_ASSIGNED_TO_RP:
      return null
    case TaskStatus.TASK_STATUS_NOT_STARTED:
      if (task.taskTemplateInfo!.isUpload) {
        return <>Upload {chevron}</>
      }
      return <>Get Started {chevron}</>
    case TaskStatus.TASK_STATUS_AWAITING_REVIEW:
      return <>Review {chevron}</>
    case TaskStatus.TASK_STATUS_PENDING_SIGNATURES:
      switch (nextSigner(task)?.role) {
        case SignerRole.SIGNER_ROLE_ADMIN:
          return <>Sign {chevron}</>
        case SignerRole.SIGNER_ROLE_RESPONSIBLE_PARTY:
          return null
        default:
          return <>Processing</>
      }
    default:
      return <>Unknown {chevron}</>
  }
}
