import { LoaderSvg } from '@shared/components/LoadingPopup'
import { tw } from '@shared/utils/tailwind'
import Footer from './Footer'
import Header from './Header'

export default function Loader({ title }: { title: string }) {
  return (
    <div className={tw`flex flex-col items-center`}>
      <Header title={title} />

      <div className={tw`mt-[40px]`}>
        <LoaderSvg />
      </div>

      <Footer />
    </div>
  )
}
