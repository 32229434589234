import { Facility } from '@shared/types/facility'

const notifyStates = ['CA', 'California', 'VA', 'Virginia']
const facilityAddressStateMatcher = (facility: Facility, states: string[]) =>
  states.some(
    (s) => s === facility.address?.state || facility.address?.text?.includes(s)
  )

export const isCaliforniaFacility = (facility: Facility | undefined | null) =>
  facility
    ? facilityAddressStateMatcher(facility, notifyStates.slice(0, 2))
    : false

export function isVirginiaFacility(facility: Facility) {
  return facilityAddressStateMatcher(facility, notifyStates.slice(2, 4))
}

interface FacilityHash {
  [key: string]: Facility
}

export function getFacilityHash(facilities: Facility[]): FacilityHash {
  return facilities.reduce<FacilityHash>((hash, f) => {
    const { id } = f
    if (id) {
      hash[id] = f
    }

    return hash
  }, {})
}

export function isEmarActive(facility: Facility) {
  return Boolean(facility.emarSettings?.isActive)
}

export function hasActivePharmacyIntegration(facility: Facility) {
  return Boolean(facility.hasActivePharmacyIntegration)
}

export type BillingFeatureType =
  | 'Community-wide Billing'
  | 'Resident-level Billing'
export function billingFeatureForFacility(
  facility: Facility
): BillingFeatureType {
  return facility.billingSettings?.isActive
    ? 'Community-wide Billing'
    : 'Resident-level Billing'
}
