import { TaskType } from '@augusthealth/models/com/august/protos/task'
import {
  fetchAdmissionsInformation,
  mergePatchAdmissionsInformation,
} from '@shared/api/person'
import { DataType } from '@shared/types/snapshot'
import {
  cloneMoveInDateToFinancialStartDate,
  removeStartRange,
} from '@shared/utils/admissions'
import { getContacts, updateContact } from '@app/api/contacts'
import { TASK_REQUIRED_FIELDS_HASH } from '@app/components/Prospects/tasksConfig'
import CustomForm from '../CustomForm'
import FormLayout from '../FormLayout'
import {
  FormPageProps,
  LoaderProps,
  PageGeneratorItem,
  UpdaterProps,
} from '../FormLayout/type'
import admissionDetailsConf from './json/admissionDetails.json'
import authorizationsConf from './json/authorizations.json'

const requiredFieldsByPageIndex =
  TASK_REQUIRED_FIELDS_HASH[TaskType.TASK_TYPE_ADMISSIONS_AGREEMENT]

export default function AdmissionsAgreement(props: FormPageProps) {
  const formList: PageGeneratorItem[] = [
    {
      menuTitle: '1. ADMISSION DETAILS',
      pageTitle: 'ADMISSION DETAILS',
      json: admissionDetailsConf,
      loader: fetchAdmissionsInformation,
      updater: ({ patch, ...rest }: UpdaterProps) => {
        let updatedPatch = removeStartRange(patch)
        if (rest.name === 'admissionsInformation.startDate') {
          updatedPatch = cloneMoveInDateToFinancialStartDate(updatedPatch)
        }

        return mergePatchAdmissionsInformation({ ...rest, patch: updatedPatch })
      },
      requiredFields: requiredFieldsByPageIndex[0],
    },
    {
      menuTitle: '2. AUTHORIZATIONS',
      pageTitle: 'AUTHORIZATIONS',
      json: authorizationsConf,
      loader: (props: LoaderProps) =>
        getContacts(props).then((contact) => ({ data: { contact } })),
      updater: (props: UpdaterProps) => {
        const { patch, pId: personId, value } = props
        return updateContact({
          ...props,
          personId,
          contact: value,
        }).then(() => ({ data: patch }))
      },
    },
    {
      menuTitle: '3. REVIEW',
      pageTitle: 'REVIEW',
      content: 'Review',
    },
  ]
  const defaultForm = <FormLayout {...props} configuration={formList} />
  return (
    <CustomForm
      defaultForm={defaultForm}
      dataType={DataType.DATA_TYPE_ADMISSIONS_AGREEMENT}
      {...props}
    />
  )
}
