import { Order } from '@shared/utils/common'

export { Order } from '@shared/utils/common'

export function sortStr({
  strA,
  strB,
  sortingOrder,
}: {
  strA?: string
  strB?: string
  sortingOrder: Order
}) {
  if (!strA && !strB) return 0
  if (!strA) return 1
  if (!strB) return -1

  if (sortingOrder === Order.ASC) {
    return strA.localeCompare(strB)
  }

  return strB.localeCompare(strA)
}

export function sortNumber({
  numA,
  numB,
  sortingOrder,
}: {
  numA?: number
  numB?: number
  sortingOrder: Order
}) {
  if (!numA && !numB) return 0
  if (numA === undefined) return -1
  if (numB === undefined) return 1

  if (sortingOrder === Order.ASC) {
    return numA - numB
  }
  return numB - numA
}
