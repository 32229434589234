import { OptionTypeBase } from '@shared/components/StyledSelect'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'

export type OrgOrFacility = Facility | Organization

export function getFacilityOrgOptions({
  organization,
  facility,
}: {
  organization: Organization
  facility: Facility
}) {
  const facilityOptions: OptionTypeBase<OrgOrFacility>[] = [
    {
      label: facility.name,
      value: facility,
    },
  ]

  const orgFacilityOptions = [
    {
      label: organization.name,
      subLabel: 'All Communities',
      value: organization,
    },
    ...facilityOptions,
  ]

  return {
    facilityOptions,
    orgFacilityOptions,
  }
}

export function getSelectedCommunityOption({
  fId,
  options,
}: {
  fId?: string
  options: OptionTypeBase<OrgOrFacility>[]
}) {
  return options.find((o) => {
    const orgOrFacility = o.value
    // Facility => { orgId, id: facilityId }
    // Organization => { id: orgId }
    const isFacility = 'orgId' in orgOrFacility // only Facility has orgId
    if (isFacility) {
      return orgOrFacility.id === fId
    } else {
      // Org. option here
      // Org. option doesn't have facilityId, so should be selected when fId not found
      return !fId
    }
  })
}
