import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { loaded, LOADING, Loading } from '@shared/utils/loading'
import { DashboardRecord, fetchBillingDashboards } from '@app/api/organizations'

export default function useBillingDashboards(orgId: string) {
  const { setError } = useContext(GlobalContext)
  const [billingDashboards, setDashboardRecords] =
    useState<Loading<DashboardRecord[]>>(LOADING)

  useEffect(() => {
    fetchBillingDashboards(orgId)
      .then((v) => setDashboardRecords(loaded(v)))
      .catch(setError)
  }, [orgId])

  return { billingDashboards }
}
