import { Task } from '@augusthealth/models/com/august/protos/task'
import { useContext } from 'react'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import ButtonLink from '@shared/components/ButtonLink'
import GlobalContext from '@shared/contexts/GlobalContext'
import { dataTypePdfUrl } from '@shared/legacy_routes'
import { Facility } from '@shared/types/facility'
import { Person } from '@shared/types/person'
import useTemplate from '@app/hooks/useTemplate'
import './why-popup.css'
import { download } from '@shared/api/legacy'

type Props = {
  onClose: () => void
  onUpload: () => void
  task: Task
  currentFacility: Facility
  person: Person
}

export default function Why({
  onClose,
  onUpload,
  task,
  currentFacility,
  person,
}: Props) {
  const templateResult = useTemplate({
    dataType: task.taskTemplateInfo!.dataType!,
    customType: task.taskTemplateInfo!.customType,
    person,
  })
  const { setError } = useContext(GlobalContext)

  const title = task.taskTemplateInfo?.displayName

  return (
    <AnimatedPopup
      onClickBackground={onClose}
      title={<div className="small-popup-title">Why is a {title} needed?</div>}
      containerClassName="small-popup-content"
    >
      <div>
        <div data-testid="explanation" className={'whitespace-pre-line'}>
          {task.taskTemplateInfo?.descriptionCopy}
        </div>
        {templateResult.tag === 'Complete' && (
          <p className={'mt-[8px]'}>
            You can{' '}
            <ButtonLink
              className={'font-semibold'}
              onClick={async () => {
                try {
                  const fileUrl = dataTypePdfUrl({
                    person: {
                      id: task.personId!,
                      facilityId: currentFacility.id,
                      orgId: currentFacility.orgId,
                    },
                    dataType: task.taskTemplateInfo!.dataType!,
                    customType: task.taskTemplateInfo!.customType,
                  })

                  await download({
                    fileUrl,
                    target: '_blank',
                  })
                } catch (e) {
                  setError(e)
                }
              }}
            >
              download a copy here.
            </ButtonLink>
          </p>
        )}
        <div className="vertical mt-[24px] flex items-center">
          <AsyncIconButton
            buttonStyle={'primary-fill'}
            className={'mb-[12px]'}
            width={'304px'}
            onClick={() => {
              onClose()
              onUpload()
            }}
          >
            get started now
          </AsyncIconButton>
          <AsyncIconButton
            buttonStyle={'secondary-outline'}
            onClick={onClose}
            width={'304px'}
          >
            finish later
          </AsyncIconButton>
        </div>
      </div>
    </AnimatedPopup>
  )
}
