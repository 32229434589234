import { GroupType } from '@augusthealth/models/com/august/protos/permission'
import { some } from 'lodash'
import { Facility } from '@shared/types/facility'
import { UserAccount } from '@shared/types/user'
import { getFullName } from '@shared/utils/humanName'
import { getGroupLabelAndType } from '@shared/utils/permisson'
import {
  getRelevantFacilities,
  hasBillingRole,
  hasDirectorRole,
  hasFacilityAdminRole,
  hasFacilitySalesAndMarketingRole,
  hasFacilityStaffPlusEmarRole,
  hasFacilityStaffRole,
  hasFrontDeskRole,
  hasMedTechRole,
  hasMedTechSupervisorRole,
  hasNurseRole,
  hasOrgAdminRole,
  hasPharmacistRole,
  hasSocialWorkerRole,
  isAnalyst,
  isSuperUser,
  isToolsUser,
} from '@shared/utils/user'

function sortByName(user1: UserAccount, user2: UserAccount) {
  if (user1.name && user2.name) {
    if (getFullName(user1.name) < getFullName(user2.name)) {
      return -1
    }

    return 0
  } else if (user1.name) {
    return -1
  }

  return 1
}

function sortByRole(user1: UserAccount, user2: UserAccount) {
  const isOrgAdmin1 = hasOrgAdminRole(user1)
  const isOrgAdmin2 = hasOrgAdminRole(user2)
  if (isOrgAdmin1 && isOrgAdmin2) {
    return 0
  } else if (isOrgAdmin1) {
    return -1
  }

  return 1
}

export function getUserLabelAndType(user: UserAccount) {
  if (hasOrgAdminRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_ORGANIZATION_ADMIN)
  } else if (hasDirectorRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_FACILITY_DIRECTOR)
  } else if (hasFacilityStaffPlusEmarRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_FACILITY_STAFF_PLUS_EMAR)
  } else if (hasFacilityStaffRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_FACILITY_STAFF)
  } else if (hasFacilitySalesAndMarketingRole(user)) {
    return getGroupLabelAndType(
      GroupType.GROUP_TYPE_FACILITY_SALES_AND_MARKETING
    )
  } else if (hasFacilityAdminRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_FACILITY_ADMIN)
  } else if (hasSocialWorkerRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_SOCIAL_WORKER)
  } else if (hasBillingRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_BILLING)
  } else if (hasNurseRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_NURSE)
  } else if (hasMedTechRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_MED_TECH)
  } else if (hasMedTechSupervisorRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_MED_TECH_SUPERVISOR)
  } else if (hasFrontDeskRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_FRONT_DESK)
  } else if (hasPharmacistRole(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_PHARMACIST)
  } else if (isSuperUser(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_SUPER_USER)
  } else if (isToolsUser(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_TOOL_USER)
  } else if (isAnalyst(user)) {
    return getGroupLabelAndType(GroupType.GROUP_TYPE_ANALYST)
  }

  return getGroupLabelAndType(GroupType.GROUP_TYPE_UNSPECIFIED)
}

export function getFilteredUsers({
  users,
  facilities,
  filterString,
}: {
  users: UserAccount[]
  facilities: Facility[]
  filterString?: string
}) {
  const filteredUsers = [...users]
  if (filterString && filterString.trim()) {
    return filteredUsers.filter((user) => {
      const fullName = getFullName(user.name)?.toLowerCase() || ''
      const groupLabel = getUserLabelAndType(user)?.label?.toLowerCase() || ''
      const email = user.email?.toLowerCase() || ''
      const userName = user.preferredUsername?.toLowerCase() || ''
      const userFacilities = getRelevantFacilities({ facilities, user })
      const searchStr = filterString.trim().toLowerCase()
      return (
        fullName.includes(searchStr) ||
        groupLabel.includes(searchStr) ||
        email.includes(searchStr) ||
        userName.includes(searchStr) ||
        some(userFacilities, (f) => f.name.toLowerCase().includes(searchStr))
      )
    })
  }

  return filteredUsers
}

export function getSortedUsers(users: UserAccount[]) {
  const sortedUsers = [...users]
  sortedUsers.sort(sortByName)
  sortedUsers.sort(sortByRole)

  return sortedUsers
}
