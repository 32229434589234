import { isAfter } from 'date-fns'
import {
  fromDateMessageToDate,
  fromDateTimeToDate,
  isWithinInterval,
} from '@shared/utils/date'
import { MedOrderFormData } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'

export const isCurrentlyOnHold = (formData: MedOrderFormData, today: Date) => {
  const holdStartDate = formData.onHold.start.date ?? undefined
  const holdStartTime = formData.onHold.start.time ?? undefined
  const holdEndDate = formData.onHold.end.date ?? undefined
  const holdEndTime = formData.onHold.end.time ?? undefined

  const startingDateTime =
    holdStartTime && holdStartDate
      ? fromDateTimeToDate({
          date: holdStartDate,
          time: holdStartTime.value,
        })
      : fromDateMessageToDate(holdStartDate)

  const endingDateTime =
    holdEndTime && holdEndDate
      ? fromDateTimeToDate({
          date: holdEndDate,
          time: holdEndTime.value,
        })
      : fromDateMessageToDate(holdEndDate)

  if (holdStartDate && holdEndDate) {
    const interval = {
      start: startingDateTime as Date,
      end: endingDateTime as Date,
    }

    try {
      // this call will fail if the interval is invalid
      // this is guarded via validation in the form but catching just in case
      return isWithinInterval(today, interval)
    } catch (e) {
      return false
    }
  }

  if (holdStartDate) {
    try {
      return isAfter(today, startingDateTime as Date)
    } catch (e) {
      return false
    }
  }

  return false
}
