import { AugustFieldOption } from '@augusthealth/models/com/august/protos/api/json_report'
import { AugustFieldType } from '@augusthealth/models/com/august/protos/custom_field'
import {
  DisplayField,
  FormDisplay,
} from '@augusthealth/models/com/august/protos/signable_form'
import { Controller, UseFormReturn } from 'react-hook-form'
import { BasicCheckbox } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect from '@shared/components/StyledSelect'
import { tw } from '@shared/utils/tailwind'
import {
  AugustValueGroupHash,
  getFieldName,
  getOptionFromField,
  getPageName,
  Option,
} from './helpers'

export default function AugustField({
  augustValues,
  index: fieldIndex,
  pageIndex,
  useFormReturn,
}: {
  augustValues: AugustFieldOption[]
  index: number
  pageIndex: number
  useFormReturn: UseFormReturn<FormDisplay>
}) {
  const { control, register, setValue, watch } = useFormReturn
  const fieldsName = getPageName(pageIndex, 'fields')
  const fields = watch(fieldsName) as DisplayField[]
  const fieldTypeName = getFieldName(pageIndex, fieldIndex, 'fieldType')
  const fieldType = watch(fieldTypeName) as AugustFieldType | undefined
  const hasFieldType = Boolean(fieldType)
  const requiredTypeName = getFieldName(pageIndex, fieldIndex, 'required')
  const augustValueOptions = augustValues
    .filter((v) => v.field)
    .map((v) => getOptionFromField(v))
  const augustValueGroupOptions = Object.values(
    augustValues.reduce((hash: AugustValueGroupHash, v) => {
      const { field: value } = v
      if (value) {
        const option = getOptionFromField(v)
        const { category } = option
        if (category) {
          if (hash[category]) {
            hash[category].options.push(option)
          } else {
            hash[category] = { label: category, options: [option] }
          }
        }
      }

      return hash
    }, {})
  )

  return (
    <div className={tw`mb-[8px] flex`}>
      <div className={tw`mr-[12px] flex flex-1 flex-col`}>
        <Controller
          control={control}
          name={fieldTypeName}
          rules={{ required: hasFieldType }}
          render={({ field: { onChange, value } }) => (
            <StyledSelect
              isClearable
              placeholder="Select a field..."
              options={augustValueGroupOptions}
              onChange={(opt: { value: AugustFieldType }) => {
                if (opt?.value) {
                  onChange(opt.value)
                } else {
                  // Remove whole field from field list
                  setValue(
                    fieldsName,
                    fields.filter((_field, i) => fieldIndex !== i),
                    { shouldDirty: true }
                  )
                }
              }}
              value={augustValueOptions.find((v) => v.value === value)}
              getOptionLabel={(opt: Option) => `${opt.label} (${opt.category})`}
            />
          )}
        />
      </div>
      <div className={tw`fle-col flex-0 ml-[12px] flex items-center`}>
        <LabelAboveInput
          className={tw`mb-0 mr-[8px]`}
          htmlFor={requiredTypeName}
          uppercase={false}
        >
          Required
        </LabelAboveInput>
        <BasicCheckbox
          {...register(requiredTypeName)}
          id={requiredTypeName}
          disabled={!hasFieldType}
        />
      </div>
    </div>
  )
}
