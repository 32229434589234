import { ErrorCode } from '@augusthealth/models/com/august/protos/api/error_response'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { cloneDeep } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { medicationsPathForPerson } from '@shared/legacy_routes'
import {
  DosageDeviation,
  DosageTransitionStrategy,
} from '@shared/types/dosage_deviation'
import {
  MedicationApprovalStatus,
  MedicationOrder,
  RejectionReason,
} from '@shared/types/medication_order'
import { DeepNull } from '@shared/types/utilities'
import { AugustError } from '@shared/utils/error'
import { getOrElse } from '@shared/utils/loading'
import {
  isPendingDiscontinue,
  isPending as isPendingMedOrder,
} from '@shared/utils/medicationStatement'
import { validStringOrUndefined } from '@shared/utils/parsing'
import { tw } from '@shared/utils/tailwind'
import {
  approvePharmacyDiscontinue,
  approvePharmacyMessage,
  rejectPharmacyMessage,
} from '@app/api/medicationOrders'
import ConfirmModal from '@app/components/ConfirmModal'
import { DosageDeviationModal } from '@app/components/Residents/Medications/EditMedication/DosageDeviationModal'
import { getPharmacyMessageTitle } from '@app/components/Residents/Medications/Orders/helpers'
import { MedicationOrderLoading } from '@app/components/Residents/Medications/Orders/MedicationOrderDetails/layout'
import {
  errorIsTaperMultipartValidation,
  TaperValidationError,
} from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/TaperSchedule/helpers'
import PersonContext from '@app/contexts/PersonContext'
import useMedPasses from '@app/hooks/useMedPasses'
import { usePharmacyMessage } from '@app/hooks/usePharmacyMessage'
import styles from '../styles.module.css'
import {
  mapFormMedToMedOrder,
  mapMedOrderToFormData,
  MedOrderFormData,
} from './helpers'
import ReviewMedicationOrderContents from './ReviewMedicationOrderContents'

export default function ReviewMedicationOrder() {
  const { setError } = useContext(GlobalContext)
  const params = useParams<{
    orgId: string
    facilityId: string
    id: string
    orderGroupId: string
    messageId: string
  }>()
  const { orgId, facilityId, id, orderGroupId, messageId } = params
  const { medicationOrder, messageDiffs } = usePharmacyMessage(
    messageId,
    orderGroupId
  )
  const { person } = useContext(PersonContext)
  const { user } = useUserContext()
  const [isSubmitting, setIsSubmitting] = useState<
    'APPROVE' | 'REJECT' | undefined
  >()
  const [originalMedicationOrder, setOriginalMedicationOrder] = useState<
    MedicationOrder | undefined
  >()

  const history = useHistory()
  const methods = useForm<MedOrderFormData>({
    mode: 'onSubmit',
  })

  const { medPasses } = useMedPasses({
    facility: { id: facilityId!, orgId: orgId! },
  })

  useEffect(() => {
    if (medicationOrder.tag === 'Complete') {
      if (medicationOrder.value === null) {
        return void returnToMedicationsList()
      }

      if (medicationOrder.value) {
        const order =
          messageDiffs.tag === 'Complete' &&
          messageDiffs.value?.withDiffs.mergedOrder
            ? (messageDiffs.value.withDiffs!.mergedOrder as MedicationOrder)
            : medicationOrder.value
        methods.reset(mapMedOrderToFormData(order))
      }

      if (
        messageDiffs.tag === 'Complete' &&
        messageDiffs.value?.existingOrder
      ) {
        setOriginalMedicationOrder(messageDiffs.value.existingOrder)
      }
    }
  }, [medicationOrder.tag, messageDiffs.tag])

  const [showConfirmRejectOrderModal, setShowConfirmRejectOrderModal] =
    useState(false)
  const [rejectionNote, setRejectionNote] = useState('')
  const [dosageDeviation, setDosageDeviation] =
    useState<DosageDeviation | null>(null)
  const [dosageTransitionStrategy, setDosageTransitionStrategy] =
    useState<DosageTransitionStrategy | null>(null)

  if (medicationOrder.tag === 'Loading') {
    return <MedicationOrderLoading title={'Loading Order'} person={person} />
  }

  const returnToMedicationsList = () => {
    history.push(
      medicationsPathForPerson({
        orgId: orgId || '',
        facilityId: facilityId || '',
        id: id || '',
      })
    )
  }

  if (!medicationOrder.value || !person) {
    return null
  }

  const allowDosageDeviation = true

  const maybeApplyDosageTransition = (medOrder: DeepNull<MedicationOrder>) => {
    if (
      dosageDeviation &&
      dosageTransitionStrategy !==
        DosageTransitionStrategy.DOSAGE_TRANSITION_STRATEGY_UNSPECIFIED
    ) {
      return {
        ...medOrder,
        dosageTransition: {
          strategy: dosageTransitionStrategy,
          deviation: dosageDeviation,
        },
      }
    } else {
      return medOrder
    }
  }

  const medOrder =
    messageDiffs.tag === 'Complete' && messageDiffs.value?.withDiffs.mergedOrder
      ? (messageDiffs.value.withDiffs!.mergedOrder as MedicationOrder)
      : medicationOrder.value
  const isPending = isPendingMedOrder(medOrder)
  const isPendingDiscontinueMed = isPendingDiscontinue(medOrder)
  const canEdit = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_MEDICATION_UPDATE],
  })

  const updateMedicationOrder = async (
    medicationOrder: DeepNull<MedicationOrder>,
    submitStatus: 'APPROVE' | 'REJECT'
  ) => {
    try {
      setIsSubmitting(submitStatus)
      if (submitStatus === 'APPROVE') {
        if (isPendingDiscontinueMed) {
          await approvePharmacyDiscontinue(person, messageId)
        } else {
          await approvePharmacyMessage(
            person,
            messageId,
            maybeApplyDosageTransition(medicationOrder),
            { allowDosageDeviation }
          )
        }
      } else {
        await rejectPharmacyMessage(
          person,
          messageId,
          medicationOrder.approval || {}
        )
      }

      setIsSubmitting(undefined)

      returnToMedicationsList()
    } catch (e) {
      setIsSubmitting(undefined)

      methods.reset(methods.getValues(), {
        keepDirtyValues: true,
        keepTouched: true,
        keepDirty: true,
      })

      const { code, data } =
        (e as AugustError | undefined)?.json?.errors?.[0] || {}

      if (code === ErrorCode.ERROR_CODE_DOSAGE_TRANSITION_REQUIRED) {
        setDosageDeviation(data.deviation as DosageDeviation)
      } else if (errorIsTaperMultipartValidation(e)) {
        setError(TaperValidationError)
      } else {
        setError(e)
      }
    }
  }

  const rejectMed = async (rejectionNote: string) => {
    const updatedMedOrder = cloneDeep(medOrder)
    updatedMedOrder.approval!.status =
      MedicationApprovalStatus.MEDICATION_APPROVAL_STATUS_REJECTED
    updatedMedOrder.approval!.rejectionReason =
      RejectionReason.REJECTION_REASON_UNSPECIFIED
    updatedMedOrder.approval!.rejectionNote =
      validStringOrUndefined(rejectionNote)

    return updateMedicationOrder(updatedMedOrder, 'REJECT')
  }

  const approveMed = async () => {
    const updatedWithForm = mapFormMedToMedOrder({
      formData: methods.getValues(),
      medOrder,
    })
    updatedWithForm.approval!.status =
      MedicationApprovalStatus.MEDICATION_APPROVAL_STATUS_APPROVED

    return updateMedicationOrder(updatedWithForm, 'APPROVE')
  }

  const allowDiscontinueWhenInvalid = async () => {
    try {
      if (isPendingDiscontinueMed) {
        setIsSubmitting('APPROVE')
        await approvePharmacyDiscontinue(person, messageId)
        setIsSubmitting(undefined)

        returnToMedicationsList()
      }
    } catch (e) {
      setIsSubmitting(undefined)
      setError(e)
    }
  }

  return (
    <>
      {dosageDeviation && originalMedicationOrder && (
        <DosageDeviationModal
          originalMedicationOrder={originalMedicationOrder}
          updatedMedicationOrder={medOrder}
          person={person}
          dosageDeviation={dosageDeviation}
          setDosageTransitionStrategy={setDosageTransitionStrategy}
          dosageTransitionStrategy={dosageTransitionStrategy}
          closeModal={() => {
            setDosageTransitionStrategy(null)
            setDosageDeviation(null)
          }}
          onConfirm={approveMed}
          medPasses={getOrElse(medPasses, [])}
        />
      )}
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(
            approveMed,
            allowDiscontinueWhenInvalid
          )}
        >
          <ReviewMedicationOrderContents
            pageTitle={getPharmacyMessageTitle(medOrder)}
            medicationOrder={medOrder}
            mode={'review'}
            orderDiffs={
              messageDiffs.tag === 'Complete' ? messageDiffs.value : undefined
            }
            canEdit={canEdit}
            user={user}
          />
          <div className={styles.footer}>
            <div className={styles.footerContent}>
              <AsyncIconButton
                buttonStyle={'secondary-outline'}
                className={styles.button}
                onClick={returnToMedicationsList}
              >
                Cancel
              </AsyncIconButton>
              <div>
                <AsyncIconButton
                  buttonStyle={'danger-fill'}
                  className={`${styles.button} ${styles.rejectButton}`}
                  onClick={() => setShowConfirmRejectOrderModal(true)}
                  disabled={!isPending || !!isSubmitting || !canEdit}
                  isLoading={isSubmitting === 'REJECT'}
                >
                  Reject
                </AsyncIconButton>
                <AsyncIconButton
                  buttonStyle={'primary-fill'}
                  className={styles.button}
                  disabled={
                    !canEdit ||
                    !isPending ||
                    !!isSubmitting ||
                    (methods.formState.isSubmitted &&
                      Object.keys(methods.formState.errors).length > 0)
                  }
                  isLoading={isSubmitting === 'APPROVE'}
                  type={'submit'}
                >
                  {isPendingDiscontinueMed ? 'Discontinue' : 'Approve'}
                </AsyncIconButton>
              </div>
            </div>
          </div>
          {showConfirmRejectOrderModal && (
            <ConfirmModal
              testId={'rejectConfirmationModal'}
              title={'Reject this medication order?'}
              content={
                <>
                  <LabelAboveInput
                    htmlFor="rejection-reason"
                    className={tw`mt-4`}
                    a11yOnly={true}
                  >
                    Rejection reason
                  </LabelAboveInput>
                  <BasicTextarea
                    name="rejection-reason"
                    value={rejectionNote}
                    placeholder="Optional rejection reason..."
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setRejectionNote(e.currentTarget.value)
                    }
                  ></BasicTextarea>
                </>
              }
              contentClassNames="mb-0"
              confirmButtonConfig={{
                children: 'Reject',
                onClick: () => rejectMed(rejectionNote),
              }}
              denyButtonConfig={{
                onClick: () => setShowConfirmRejectOrderModal(false),
              }}
            />
          )}
        </form>
      </FormProvider>
    </>
  )
}
