import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { contactsPathForPerson } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { firstLastName } from '@shared/utils/contact'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { EMAIL_REGX } from '@shared/utils/regex'
import styles from './styles.module.css'
import { setRPEmail } from '../SignatureFlow/api'

export function NoRP({ person }: { person: Person }) {
  return (
    <div className={styles.noRp} data-testid={'missingRP'}>
      <h1 className={styles.missingRpTitle}>
        Missing Responsible Person for {getFirstAndLastName(person.name)}
      </h1>
      <p className={'font-medium text-secondary-06'}>
        Please{' '}
        <Link
          className={styles.addRpLink}
          to={contactsPathForPerson(person as Required<Person>)}
        >
          add a responsible person
        </Link>{' '}
        before requesting a signature.
      </p>
    </div>
  )
}

export function ConfirmMakeChanges({
  onConfirm,
  onCancel,
}: {
  onConfirm: () => Promise<void>
  onCancel: () => void
}) {
  const [isConfirming, setIsConfirming] = useState(false)

  return (
    <div className={styles.noRp} data-testid={'confirmMakeChanges'}>
      <h1 className={styles.missingRpTitle}>
        Are you sure you want to make changes?
      </h1>
      <p
        className={
          'mb-0 text-center text-[16px] font-medium leading-[24px] text-secondary-06'
        }
        style={{ width: '500px' }}
      >
        This will cancel existing signatures and re-open the admission task with
        your last edits.
      </p>
      <div className={'mt-[32px]'}>
        <AsyncIconButton
          onClick={onCancel}
          buttonStyle={'secondary-outline'}
          width={'170px'}
          className={'mr-[16px]'}
        >
          Cancel
        </AsyncIconButton>
        <AsyncIconButton
          isLoading={isConfirming}
          disabled={isConfirming}
          buttonStyle={'danger-fill'}
          width={'170px'}
          data-testid={'makeChangesImSure'}
          onClick={async () => {
            setIsConfirming(true)
            await onConfirm()
          }}
        >
          Make Changes
        </AsyncIconButton>
      </div>
    </div>
  )
}

export function RPMissingEmail({
  responsiblePerson,
  person,
  onContinue,
  continueWithEmailButtonText,
}: {
  person: Person
  responsiblePerson: Contact
  continueWithEmailButtonText: string
  onContinue: (
    actionTaken:
      | { tag: 'RPEmailAdded'; value: string }
      | { tag: 'ContinueWithoutRPEmail' }
  ) => Promise<void>
}) {
  const [newRpEmail, setNewRpEmail] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<
    'withEmail' | 'withoutEmail'
  >()

  return (
    <div className={styles.noRp} data-testid={'missingRpEmail'}>
      <h1 className={styles.missingRpTitle}>
        Missing email address for {firstLastName(responsiblePerson)}
      </h1>
      <p
        className={
          'mb-0 text-[16px] font-medium leading-[24px] text-secondary-06'
        }
      >
        If you'd like them to sign online, enter their email.
      </p>
      <p
        className={
          'mb-[32px] text-[16px] font-medium leading-[24px] text-secondary-06'
        }
      >
        Or continue without email, and they can electronically sign in person
        later.
      </p>
      <div className={styles.rpEmailForm}>
        <BasicInput
          autoFocus={true}
          style={{ marginBottom: '32px', width: '360px' }}
          placeholder={`Email address for ${firstLastName(responsiblePerson)}`}
          onChange={(e) => setNewRpEmail(e.currentTarget.value)}
        />
        <div>
          <AsyncIconButton
            buttonStyle={'secondary-fill'}
            className={'mr-[16px]'}
            width={'224px'}
            onClick={async () => {
              setIsSubmitting('withoutEmail')
              await onContinue({ tag: 'ContinueWithoutRPEmail' })
            }}
            disabled={
              EMAIL_REGX.test(newRpEmail) || isSubmitting === 'withoutEmail'
            }
            isLoading={isSubmitting === 'withoutEmail'}
          >
            Continue without Email
          </AsyncIconButton>
          <AsyncIconButton
            disabled={
              !EMAIL_REGX.test(newRpEmail) || isSubmitting === 'withEmail'
            }
            width={'224px'}
            isLoading={isSubmitting === 'withEmail'}
            buttonStyle={'primary-fill'}
            onClick={async () => {
              setIsSubmitting('withEmail')
              await setRPEmail({ responsiblePerson, person, newRpEmail })
              await onContinue({ tag: 'RPEmailAdded', value: newRpEmail })
            }}
          >
            {continueWithEmailButtonText}
          </AsyncIconButton>
        </div>
      </div>
    </div>
  )
}
