/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { getUrl } from '@shared/api/legacy'
import {
  ApiParams,
  fetchBlobUrlAndContentType,
  fetchJson,
  request,
  requestAnything,
  requestJson,
} from '@shared/api/request'
import { apiUrl, facilitiesUrl, facilityUrl } from '@shared/api/urls'
import environment from '@shared/environment'
// eslint-disable-next-line no-restricted-imports
import {
  apiFacilitiesUrl,
  apiFacilityAnalytics,
  apiFacilityCompletionStatsUrl,
  apiFacilityMetricsDataUrl,
  apiFacilityMetricsUrl,
  apiFacilityUrl,
  apiResidentCompletionStatsUrl,
  getFacilityUsersUrl,
} from '@shared/legacy_routes'
import {
  AggregateFunction,
  AggregateInterval,
  FacilityMetricAggregateHistory,
  FacilityMetricDataHistory,
  Metric,
} from '@shared/types/api/facility_metrics'
import {
  FacilityCompletionStats,
  ResidentCompletionStats,
} from '@shared/types/api/facility_stats'
import { ApplicationEvent } from '@shared/types/application_event'
import { Facility, FacilityPatch } from '@shared/types/facility'
import { GenerateBulkMarParameters as GenerateBulkMarJobParameters } from '@shared/types/jobs'
import { TaskTemplateInfo } from '@shared/types/task'
import { UserAccount } from '@shared/types/user'

const BASE_URL = environment.baseUrl
const ORGANIZATIONS_URL = `${BASE_URL}/organizations`
const ORG_URL = `${ORGANIZATIONS_URL}/:orgId`
const FACILITY_URL = `${ORG_URL}/facilities/:facilityId`
const FACILITIES_URL = `${ORG_URL}/facilities`
const FACILITY_SEND_BULK_MAR_V2_URL = `${FACILITY_URL}/sendBulkMarV2`

export function fetchFacility({ orgId, fId }: { orgId: string; fId: string }) {
  const url = getUrl({ baseUrl: FACILITY_URL, orgId, fId })
  return fetchJson(url)
}

export function fetchFacilities({
  orgId,
  includeInactive = false,
}: {
  orgId: string
  includeInactive?: boolean
}) {
  const url = getUrl({
    baseUrl: FACILITIES_URL,
    orgId,
    params: { limit: 1000, includesInactive: includeInactive },
  })
  return fetchJson(url)
}

interface CreateFacilityArgs {
  facility: FacilityPatch
  orgId: number | string
}

export async function createFacility({
  facility,
  orgId,
}: CreateFacilityArgs): Promise<{ id: number }> {
  const responseJson = await requestJson({
    url: apiFacilitiesUrl(`${orgId}`),
    method: 'POST',
    body: JSON.stringify(facility),
  })

  return responseJson.data as Promise<{ id: number }>
}

export async function deleteFacility({
  facilityId,
  orgId,
}: {
  orgId: string
  facilityId: string
}): Promise<{ id: number }> {
  const responseJson = await requestJson({
    url: apiFacilityUrl(orgId, facilityId),
    method: 'DELETE',
  })

  return responseJson.data as Promise<{ id: number }>
}

export async function mergePatchFacility({
  facility,
  patch,
}: {
  facility: Facility
  patch: FacilityPatch
}) {
  const {
    careGroups,
    medGroups,
    shifts,
    taskDefinitions,
    statistics,
    stateIncidentReportInfo,
    hasActivePharmacyIntegration,
    ...facilityWithoutReadonly
  } = patch
  return (await request({
    url: apiFacilityUrl(facility.orgId, facility.id),
    body: JSON.stringify(facilityWithoutReadonly),
    contentType: 'application/merge-patch+json',
    method: 'PATCH',
  })) as Promise<any>
}

export async function getFacility({
  facilityId,
  orgId,
}: {
  orgId: string
  facilityId: string
}): Promise<Facility> {
  const responseJson = await requestJson({
    url: apiFacilityUrl(orgId, facilityId),
  })

  return responseJson.data as Promise<Facility>
}

export async function getFacilityCustomImages({
  facility,
  customFieldKey,
}: {
  facility: Facility
  customFieldKey: string
}): Promise<string> {
  const { orgId, id: facilityId } = facility

  const image = await fetchBlobUrlAndContentType({
    url: `${apiFacilityUrl(
      orgId,
      facilityId
    )}/customFieldImages/${customFieldKey}`,
  })

  return image.url
}

export async function uploadCustomFacilityImage({
  facility,
  customFieldKey,
  file,
}: {
  facility: Facility
  customFieldKey: string
  file: File
}): Promise<Response> {
  const { orgId, id: facilityId } = facility

  const url = `${apiFacilityUrl(
    orgId,
    facilityId
  )}/customFieldImages/${customFieldKey}`
  const formData = new FormData()
  formData.append('file', file)
  // 1. Submit the upload
  return requestAnything({ url, method: 'POST', body: formData })
}

export interface GetFacilitiesParams extends ApiParams {
  limit?: string
  includesInactive?: string
}

export async function getFacilities(
  orgId: string,
  params: GetFacilitiesParams = {}
): Promise<Array<Facility>> {
  return fetchJson<Array<Facility>>(
    facilitiesUrl(orgId, params)
    // Remove casts once fetchJson is fully typed
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  ).then((response) => response.data) as Promise<Array<Facility>>
}

export async function listFacilities(
  orgId: string,
  limit = 1000,
  includeInactive = false
): Promise<Facility[]> {
  const responseJson = await requestJson({
    url: `${apiFacilitiesUrl(
      orgId
    )}?limit=${limit.toString()}&includesInactive=${includeInactive}`,
    method: 'GET',
  })

  return responseJson.data as Promise<Facility[]>
}

export function fetchFacilityTaskTemplates(
  orgId: string,
  facilityId: string
): Promise<{ data: TaskTemplateInfo[] }> {
  const url = `${apiFacilityUrl(orgId, facilityId)}/taskTemplateInfos`
  return requestJson({ url }) as Promise<{ data: TaskTemplateInfo[] }>
}

// This function returns the same list of task templates as fetchFacilityTaskTemplates. The only difference is the required permission that:
//  * fetchFacilityTaskTemplates uses GROUP_PERMISSION_SNAPSHOT_READ
//  * fetchFacilityTaskTemplatesForToolUsers uses GROUP_PERMISSION_TOOL_GENERAL
export function fetchFacilityTaskTemplatesForToolUsers(
  orgId: string,
  facilityId: string
): Promise<{ data: TaskTemplateInfo[] }> {
  const url = `${apiFacilityUrl(orgId, facilityId)}/taskTemplateInfosForToolUsers`
  return requestJson({ url }) as Promise<{ data: TaskTemplateInfo[] }>
}

export interface GenerateBulkMarParameters
  extends Omit<GenerateBulkMarJobParameters, 'date'> {
  date: string
}

export async function sendBulkMar({
  orgId,
  facilityId,
  date,
  blank = false,
  includePrnLog = false,
  sortBy,
}: GenerateBulkMarParameters): Promise<{
  hello: string
}> {
  const url = getUrl({
    baseUrl: FACILITY_SEND_BULK_MAR_V2_URL,
    orgId,
    fId: facilityId,
    params: {
      date: date,
      blank,
      includePrnLog,
      sortBy,
    },
  })

  const responseJson = await requestJson({
    url,
    method: 'POST',
  })

  return responseJson.meta as Promise<{
    hello: string
  }>
}

export async function sendBulkEMar({
  orgId,
  facilityId,
  startMonth,
  endMonth,
  sortBy,
}): Promise<{ hello: string }> {
  const url = apiUrl(facilityUrl(orgId, facilityId), 'sendBulkEmarReport', {
    startMonth,
    endMonth,
    sortBy,
  })

  const responseJson = await requestJson({
    url,
    method: 'POST',
  })

  return responseJson.meta as Promise<{
    hello: string
  }>
}

export async function fetchFacilityCompletionStats({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId: string
}): Promise<FacilityCompletionStats> {
  const url = apiFacilityCompletionStatsUrl(orgId, facilityId)
  const response = await requestJson({
    url,
  })

  return response.data as Promise<FacilityCompletionStats>
}

export async function fetchResidentCompletionStats({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId: string
}): Promise<ResidentCompletionStats[]> {
  const url = apiResidentCompletionStatsUrl(orgId, facilityId)
  const response = await requestJson({
    url,
  })

  return response.data as Promise<ResidentCompletionStats[]>
}

export enum ApplicationInterval {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export type MetricRequest = {
  metric: Metric
  dataAggregate: AggregateFunction
  timeAggregate: AggregateFunction
}

export async function fetchFacilityMetrics({
  orgId,
  facilityId,
  startDate,
  endDate,
  metrics: metricList,
  interval,
}: {
  orgId: string
  facilityId: string
  startDate?: string // ISO String
  endDate?: string // ISO String
  metrics: MetricRequest[]
  interval: AggregateInterval
}): Promise<FacilityMetricAggregateHistory[]> {
  const metrics = metricList.map((m) => {
    return {
      clientId: m.metric,
      startDate,
      endDate,
      timeInterval: interval,
      ...m,
    }
  })

  const response = await requestJson({
    url: apiFacilityMetricsUrl(orgId, facilityId),
    method: 'POST',
    body: JSON.stringify({ metrics }),
  })

  return response.data as Promise<FacilityMetricAggregateHistory[]>
}

export async function fetchFacilityMetricsData({
  orgId,
  facilityId,
  dates,
  metric,
}: {
  orgId: string
  facilityId: string
  dates: string[] // ISO String[]
  metric: Metric
}): Promise<FacilityMetricDataHistory> {
  const response = await requestJson({
    url: apiFacilityMetricsDataUrl(orgId, facilityId),
    method: 'POST',
    body: JSON.stringify({
      metrics: [
        {
          metric,
          dates,
        },
      ],
    }),
  })

  return (response.data?.length &&
    response.data[0]) as Promise<FacilityMetricDataHistory>
}

export type AnalyticsAPI = Record<
  ApplicationEvent,
  {
    periodStartTimes: string[]
    counts: number[]
  }
>

export async function fetchFacilityAnalytics({
  orgId,
  facilityId,
  startDate,
  endDate,
  interval,
  events,
}: {
  orgId: string
  facilityId: string
  startDate: string // inclusive
  endDate: string // exclusive
  interval: ApplicationInterval
  events: ApplicationEvent[]
}): Promise<AnalyticsAPI> {
  let url = apiFacilityAnalytics(orgId, facilityId)
  const eventsStr = `event=${events.join('&event=')}`
  url += `?${eventsStr}&startDate=${startDate}&endDate=${endDate}&interval=${interval}`

  const response = await requestJson({ url })
  return response.data?.eventCounts as Promise<AnalyticsAPI>
}

export const getFacilityUsers = async ({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId: string
}): Promise<UserAccount[]> => {
  const res = await requestJson({
    url: getFacilityUsersUrl(orgId, facilityId),
  })
  return res.data as Promise<UserAccount[]>
}
