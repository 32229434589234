import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { listFacilities } from '@shared/api/facility'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { LabelAboveInput } from '@shared/components/Labels'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import StyledSelect from '@shared/components/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { detailsPathForPerson } from '@shared/legacy_routes'
import { Person, ResidentStatus } from '@shared/types/person'
import { isDischargedResident, isResident } from '@shared/utils/person'
import { fetchOccupancyStats } from '@app/api/rooms'
import CongratsModal from '@app/components/CongratsModal/NewResident'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import usePerson, { usePersonInContext } from '@app/hooks/usePerson'
import AdmissionType from './Components/AdmissionType'
import FinancialStartDate from './Components/FinancialStartDate'
import MoveInDate from './Components/MoveInDate'
import Room from './Components/Room'
import {
  getDefaultValues,
  getFacilityOptions,
  needToReloadOccupancyStats,
  personFieldsForVerifyForm,
  updatePerson,
} from './helpers'
import { VerifyFormData } from './types'

interface Props {
  doneFn: React.Dispatch<React.SetStateAction<boolean>>
  person: Person
  convertToCurrentResident?: boolean
}

export default function VerifyAdmissionDetails({
  doneFn,
  person,
  convertToCurrentResident = false,
}: Props) {
  const history = useHistory()
  const {
    facilities = [],
    setFacilities,
    setOccupancyStats,
  } = useContext(FacilitiesContext)
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const { refreshPerson } = usePersonInContext({
    initialData: person,
    skipFirstFetch: true,
  })
  const { person: eTagPerson } = usePerson({
    initialData: person,
    fields: personFieldsForVerifyForm,
  })

  const facilitySelectOptions = getFacilityOptions(facilities, user)
  const [showCongrats, setShowCongrats] = useState(false)
  const [personState, setPersonState] = useState(person)
  const methods = useForm<VerifyFormData>({
    defaultValues: getDefaultValues({
      person: personState,
      facilitySelectOptions,
      convertToCurrentResident,
    }),
  })
  const { register, handleSubmit, control, formState, reset } = methods
  register('respite')
  register('hospice')

  useEffect(() => {
    if (eTagPerson.tag === 'Complete') {
      setPersonState(eTagPerson.value)
      reset(
        getDefaultValues({
          person: eTagPerson.value,
          facilitySelectOptions,
          convertToCurrentResident,
        })
      )
    }
  }, [eTagPerson])

  const closeAdmissionDetailsForm = async (
    updatedPerson: Person | undefined,
    newResident: boolean | undefined
  ) => {
    if (updatedPerson) {
      await refreshPerson()
      if (newResident) {
        return setShowCongrats(true)
      }
    }

    doneFn(false)
  }

  const closeCongrats = () => {
    setShowCongrats(false)
    const updatedPerson: Person = {
      ...personState,
      residentStatus: convertToCurrentResident
        ? ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT
        : person.residentStatus,
    }
    history.replace(detailsPathForPerson(updatedPerson as Required<Person>))
  }

  const onSubmit = async (data: VerifyFormData) => {
    try {
      const { data: updatedPerson } = await updatePerson({
        person: personState,
        formData: data,
        convertToCurrentResident,
      })
      const { orgId } = updatedPerson
      // Update Prospect / Resident counters in Sidebar
      const canFetchOccupancy = hasPermissionForPerson({
        user,
        person: person!,
        permissions: [GroupPermission.GROUP_PERMISSION_OCCUPANCY_READ],
      })

      if (
        canFetchOccupancy &&
        needToReloadOccupancyStats(personState, updatedPerson)
      ) {
        await fetchOccupancyStats({
          orgId,
          id: updatedPerson.facilityId,
        }).then(setOccupancyStats)
      }

      await Promise.all([
        listFacilities(orgId!).then((res) => setFacilities(res)),
        closeAdmissionDetailsForm(updatedPerson, convertToCurrentResident),
      ])
    } catch (e) {
      setError(e)
    }
  }

  if (showCongrats) {
    return <CongratsModal continueAndClose={closeCongrats} />
  }

  return (
    <AnimatedPopup
      title={
        convertToCurrentResident ? 'Move-In Resident' : 'Admission Details'
      }
      onClickBackground={() => doneFn(false)}
    >
      <FormProvider {...methods}>
        <form
          data-testid="verify-admissions-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            control={control}
            name="facility"
            render={({ field: { value } }) => (
              <div className="mb-[32px]">
                <LabelAboveInput htmlFor="facility">Community</LabelAboveInput>
                <StyledSelect
                  isDisabled={true}
                  name="facility"
                  options={value ? [value] : []}
                  value={value}
                />
              </div>
            )}
          />
          <MoveInDate
            required={
              isResident(person) ||
              isDischargedResident(person) ||
              convertToCurrentResident
            }
          />
          <FinancialStartDate />
          <AdmissionType />
          <Room person={person} />
          <AnimatedPopupFormFooter
            yesBtn={{
              props: {
                id: 'continue-convert-to-resident',
                disabled: eTagPerson.tag !== 'Complete',
              },
            }}
            noBtn={{ action: () => doneFn(false) }}
            formState={formState}
          />
        </form>
      </FormProvider>
    </AnimatedPopup>
  )
}
