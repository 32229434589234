import {
  DayOfWeek,
  UnitOfTime,
} from '@augusthealth/models/com/august/protos/timing'
import classNames from 'classnames'
import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import { ButtonGroup } from '@app/components/ButtonGroup'
import { MedOrderFormData } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'
import { validateRequiredNumberSelection } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/helpers'
import { abbreviatedDayOfWeekOptions } from '@app/pages/Routines/constants'

type Props = {
  index: number
  readOnly: boolean
}
export const DaysOfWeek = ({ index, readOnly }: Props) => {
  const { register, setValue, unregister, control, watch, getValues } =
    useFormContext<MedOrderFormData>()

  const frequencyCount = watch(`doses.${index}.frequency`)
  const numberOfDays = watch(`doses.${index}.dayOfWeek`)?.length || 0

  useEffect(() => {
    register(`doses.${index}.period`)
    setValue(`doses.${index}.period`, 1)
    return () => {
      if (
        getValues(`doses.${index}.periodUnit`) !== UnitOfTime.UNIT_OF_TIME_WEEK
      ) {
        unregister(`doses.${index}.dayOfWeek`)
      }
    }
  }, [])

  useEffect(() => {
    setValue(`doses.${index}.frequency`, numberOfDays)
  }, [numberOfDays])

  return (
    <Controller
      control={control}
      name={`doses.${index}.dayOfWeek`}
      rules={{
        validate: (value: DayOfWeek[]) =>
          validateRequiredNumberSelection(value, frequencyCount),
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const labelClasses = classNames('ml-auto w-fit', {
          'mb-[4px]': error,
          'mb-0': !error,
        })
        return (
          <>
            <LabelAboveInput
              className={labelClasses}
              subLabel={requiredWhenError(!!error)}
              errorMessage={error?.message}
              htmlFor={`doses.${index}.dayOfWeek`}
            />
            <ButtonGroup
              prependTestId={'days-of-week'}
              onChange={(e: OptionTypeBase<DayOfWeek>[]) =>
                onChange(e.map((opt) => opt.value))
              }
              options={abbreviatedDayOfWeekOptions}
              chosen={abbreviatedDayOfWeekOptions.filter((day) =>
                value?.includes(day.value)
              )}
              disabled={readOnly}
            />
          </>
        )
      }}
    />
  )
}
