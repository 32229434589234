import { formDataToVitalsPayload } from '@shared/components/Vitals/helpers'
import { PersonStats } from '@shared/types/person_stats'
import { Vital } from '@shared/types/vital'
import { isEmptyObject } from '@shared/utils/common'
import {
  BloodPressureOptions,
  BloodSugarOptions,
  OxygenSaturationOptions,
  TemperatureOptions,
  VitalsFormData,
} from '@shared/utils/vitals'
import { createVitalNote, updateVitalNote } from '@app/api/incidents'
import { createVitals, mergePatchVitals } from '@app/api/vitals'

export type QueuedVital = Vital & {
  queue: Promise<{ id?: string | number; noteId?: string }>
  status: 'untouched' | 'saving' | 'saved'
  note?: string | null
}
export function getDefaultVitalValues(vitals?: QueuedVital): VitalsFormData {
  const today = new Date()

  return {
    date: vitals?.recordedAt ? new Date(vitals.recordedAt) : today,
    time: today.toLocaleTimeString('en-US', { hour12: false }).slice(0, 5),
    weight: vitals?.panel?.weight?.value?.toString() ?? '',
    temperature: vitals?.panel?.temperature?.value?.toString() ?? '',
    heartRate: vitals?.panel?.heartRate?.value?.toString() ?? '',
    respiratoryRate: vitals?.panel?.respiratoryRate?.value?.toString() ?? '',
    oxygenSaturation: vitals?.panel?.oxygenSaturation?.value?.toString() ?? '',
    bloodSugar: vitals?.panel?.bloodSugar?.value?.toString() ?? '',
    systolic: vitals?.panel?.bloodPressure?.systolic?.value?.toString() ?? '',
    diastolic: vitals?.panel?.bloodPressure?.diastolic?.value?.toString() ?? '',
    temperatureType:
      TemperatureOptions.find(
        (o) => o.value === vitals?.panel?.temperature?.type
      ) ?? null,
    bloodPressurePosition:
      BloodPressureOptions.find(
        (o) => o.value === vitals?.panel?.bloodPressure?.position
      ) ?? null,
    bloodSugarTiming:
      BloodSugarOptions.find(
        (o) => o.value === vitals?.panel?.bloodSugar?.timing
      ) ?? null,
    supplementalOxygen:
      OxygenSaturationOptions.find(
        (o) => o.value === vitals?.panel?.oxygenSaturation?.supplemental
      ) ?? null,
    note: vitals?.note ?? null,
  }
}

export const createOrUpdateVitals = async ({
  data,
  sortedPeopleStats,
  visiblePersonId,
  currentVitals,
  setCurrentVitals,
}: {
  data: VitalsFormData
  sortedPeopleStats: PersonStats[]
  visiblePersonId: string | undefined
  currentVitals: QueuedVital[]
  setCurrentVitals: React.Dispatch<React.SetStateAction<QueuedVital[]>>
}): Promise<{ id?: string | number }> => {
  const vitalsData = { ...data, date: undefined, time: undefined }
  if (isEmptyObject(vitalsData)) {
    return Promise.resolve({})
  }
  const vitals = formDataToVitalsPayload(data)

  if (isEmptyObject(vitals.panel)) {
    return Promise.resolve({})
  }

  const person = sortedPeopleStats.find(
    (ps) => ps.person!.id === visiblePersonId
  )!.person
  const currentVitalQueue =
    currentVitals.find((cv) => cv.personId === visiblePersonId)?.queue ??
    Promise.resolve({ id: undefined, noteId: undefined })

  const { id, noteId } = await currentVitalQueue

  // TODO: Clean this up. I've commented it rather than refactoring for now.
  let newPromise: Promise<{ id?: string | number; noteId?: string }>
  if (id) {
    // Vitals have been saved, note has not been created, but there is note text
    if (noteId === undefined && data.note) {
      const noteText = data.note
      newPromise = mergePatchVitals(person, {
        ...vitals,
        id: id.toString(),
      }).then(async (vital) => {
        const newNote = await createVitalNote({
          noteText,
          person,
          vital,
        })

        return {
          id: vital.id,
          noteId: newNote.id.toString(),
        }
      })
      // Either no note text, or existing note that needs updating
    } else {
      newPromise = mergePatchVitals(person, {
        ...vitals,
        id: id.toString(),
      }).then(async (vital) => {
        if (data.note) {
          await updateVitalNote({
            noteText: data.note!,
            person,
            incidentId: noteId!,
          })
        }

        return {
          id: vital.id,
          noteId,
        }
      })
    }
    // No existing vitals, maybe a note to create.
  } else {
    newPromise = createVitals(person, vitals).then(async ({ id }) => {
      if (data.note) {
        const newNote = await createVitalNote({
          noteText: data.note,
          person,
          vital: { ...vitals, id: id.toString() },
        })
        return {
          id,
          noteId: newNote.id.toString(),
        }
      } else {
        return {
          id,
          noteId: undefined,
        }
      }
    })
  }

  setCurrentVitals((prev) => {
    return prev.map((v) => {
      if (v.personId === visiblePersonId) {
        return {
          ...vitals,
          personId: person.id,
          status: v.status,
          queue: newPromise,
          note: data.note,
        } as QueuedVital
      } else {
        return v
      }
    })
  })

  return newPromise
}

export function modifyVitalFor(
  visiblePersonId: string,
  modifyVital: (current: QueuedVital) => QueuedVital
): (prev: QueuedVital[]) => QueuedVital[] {
  return (prev) =>
    prev.map((v) => {
      if (v.personId === visiblePersonId) {
        return modifyVital(v)
      } else {
        return v
      }
    })
}
