import { useContext } from 'react'
import { useUserContext } from '@shared/contexts/UserContext'
import {
  isDischargedResident,
  isOutOfFacility,
  isProspectClosed,
} from '@shared/utils/person'
import PersonContext from '@app/contexts/PersonContext'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import styles from './PersonHeader/styles.module.css'
import ClosedProspectBanner from './ClosedProspectBanner'
import DischargedResidentBanner from './DischargedResidentBanner'
import OutOfCommunityBanner from './OutOfCommunityBanner'
import PersonHeader from './PersonHeader'
import Tabs, { Tab } from './Tabs'

export default function PersonNavbarLayout(props: {
  activeTab: Tab | undefined
}) {
  const { currentFacility } = useCurrentFacility()
  const { person, setPerson } = useContext(PersonContext)
  const { user } = useUserContext()
  const { activeTab } = props

  if (
    !person ||
    currentFacility?.tag === 'Loading' ||
    currentFacility === undefined
  ) {
    return null
  }

  return (
    <div>
      {isDischargedResident(person) && (
        <DischargedResidentBanner person={person} />
      )}
      {isProspectClosed(person) && <ClosedProspectBanner person={person} />}
      {isOutOfFacility(person) && <OutOfCommunityBanner person={person} />}
      <div className={styles.profileCard}>
        <PersonHeader person={person} setPerson={setPerson} />
        <Tabs
          facility={currentFacility.value}
          user={user}
          person={person}
          activeTab={activeTab!}
        />
      </div>
    </div>
  )
}
