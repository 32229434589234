import { Routine } from '@augusthealth/models/com/august/protos/routine'
import { requestJson } from '@shared/api/request'
import { apiUrl, facilityUrl, personUrl } from '@shared/api/urls'
import { FacilityIds } from '@shared/types/facility'
import { Person } from '@shared/types/person'
import { DeepNull } from '@shared/types/utilities'

type RoutineScheduleUrlOptions = {
  includesAssessmentRoutineOrder: boolean
}

function facilityRoutineOptionsUrl(facility: FacilityIds) {
  const { orgId, id } = facility
  return apiUrl(facilityUrl(orgId, id), '/routineOptions', {})
}

function personRoutinesUrl(person: Person) {
  const { orgId, facilityId, id } = person
  return apiUrl(personUrl(orgId, facilityId, id), '/routines', {})
}

function personRoutineUrl(person: Person, routineId: string) {
  const { orgId, facilityId, id } = person
  return apiUrl(personUrl(orgId, facilityId, id), '/routines/:id', {
    id: routineId,
  })
}

function routineUrl(person: Person, routine: Routine) {
  return personRoutineUrl(person, routine.id!)
}

export function careSummaryPdfUrl(
  person: Person,
  { includesAssessmentRoutineOrder }: RoutineScheduleUrlOptions
) {
  const { orgId, facilityId, id } = person
  return apiUrl(personUrl(orgId, facilityId, id), '/careRoutineSummary.pdf', {
    includesAssessmentRoutineOrder,
  })
}

export function careSummaryBulkUrl({
  orgId,
  facilityId,
  options,
}: {
  orgId: string
  facilityId: string
  options: RoutineScheduleUrlOptions
}) {
  return apiUrl(
    facilityUrl(orgId, facilityId),
    '/routines/careRoutineSummary.zip',
    { includesAssessmentRoutineOrder: options.includesAssessmentRoutineOrder }
  )
}

export async function fetchRoutines(person: Person): Promise<Routine[]> {
  const response = await requestJson({
    url: personRoutinesUrl(person),
  })

  return response.data
}

export async function fetchRoutine(
  person: Person,
  routineId: string
): Promise<Routine> {
  const response = await requestJson({
    url: personRoutineUrl(person, routineId),
  })

  return response.data
}

export async function fetchRoutineOptions(
  facility: FacilityIds
): Promise<Routine[]> {
  const response = await requestJson({
    url: facilityRoutineOptionsUrl(facility),
  })

  return response.data
}

// TODO Fields
export async function updateRoutine({
  person,
  routine,
}: {
  person: Person
  routine: DeepNull<Routine>
}) {
  const response = await requestJson({
    url: routineUrl(person, routine as Routine),
    body: JSON.stringify(routine),
    contentType: 'application/merge-patch+json',
    method: 'PATCH',
  })

  return response.data
}

export function routineScheduleUrl({
  facility,
  type,
  options,
}: {
  facility: FacilityIds
  type: 'laundry' | 'housekeeping' | 'shower'
  options: RoutineScheduleUrlOptions
}) {
  const { orgId, id } = facility
  return apiUrl(facilityUrl(orgId, id), `/routines/${type}Schedule.pdf`, {
    includesAssessmentRoutineOrder: options.includesAssessmentRoutineOrder,
  })
}

export function careNeedsUrl(
  facility: FacilityIds,
  { includesAssessmentRoutineOrder }: RoutineScheduleUrlOptions
) {
  const { orgId, id } = facility
  return apiUrl(facilityUrl(orgId, id), '/routines/careNeeds.pdf', {
    includesAssessmentRoutineOrder,
  })
}
