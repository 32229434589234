/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { requestAnything, requestJson } from '@shared/api/request'
import { apiUrl, facilityUrl, personUrl } from '@shared/api/urls'
import { apiOrganizationUrl } from '@shared/legacy_routes'
import {
  AccountingPeriodClose,
  BillingCategory,
  BillingCategoryData,
  BillingCharge,
  BillingChargeData,
  BillingEvent,
  BillingInvoice,
  BillingItem,
  BillingItemData,
  BillingItemsWithParentCategory,
  BillingTransaction,
  DetailedInvoice,
  DraftStatementsRequest,
  InvoiceItem,
  LedgerExport,
  ResidentBillingSummary,
  ResidentListEntry,
} from '@shared/types/billing'
import { FacilityIds } from '@shared/types/facility'
import { RequiredPersonIds } from '@shared/types/person'

export async function getLedgerExports({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId: string
}) {
  const response = await requestJson({
    url: apiUrl(facilityUrl(orgId, facilityId), '/billing/journalEntries', {}),
  })

  return response.data as LedgerExport[]
}

export async function getOrgBillingCategories({
  orgId,
}: {
  orgId: string
}): Promise<BillingCategory[]> {
  const response = await requestJson({
    url: apiUrl(apiOrganizationUrl(orgId), '/billing/categories', {}),
  })
  return response.data as BillingCategory[]
}

export async function getFacilityBillingCategories({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId: string
}): Promise<BillingCategory[]> {
  const response = await requestJson({
    url: apiUrl(facilityUrl(orgId, facilityId), '/billing/categories', {}),
  })
  return response.data as BillingCategory[]
}

export async function createBillingCategory({
  orgId,
  category,
}: {
  orgId: string
  category: BillingCategoryData
}): Promise<BillingCategory> {
  const response = await requestJson({
    method: 'POST',
    url: apiUrl(apiOrganizationUrl(orgId), '/billing/categories', {}),
    body: JSON.stringify(category),
  })
  return response.data as BillingCategory
}

export async function updateBillingCategory({
  orgId,
  categoryId,
  category,
}: {
  orgId: string
  categoryId: string
  category: BillingCategoryData
}): Promise<BillingCategory> {
  const response = await requestJson({
    method: 'PUT',
    url: apiUrl(
      apiOrganizationUrl(orgId),
      `/billing/categories/${categoryId}`,
      {}
    ),
    body: JSON.stringify(category),
  })
  return response.data as BillingCategory
}

export async function createBillingItem({
  orgId,
  item,
}: {
  orgId: string
  item: BillingItemData
}): Promise<BillingItem> {
  const response = await requestJson({
    method: 'POST',
    url: apiUrl(apiOrganizationUrl(orgId), '/billing/items', {}),
    body: JSON.stringify(item),
  })
  return response.data as BillingItem
}

export async function updateBillingItem({
  orgId,
  itemId,
  item,
}: {
  orgId: string
  itemId: string
  item: Omit<BillingItemData, 'facilityId' | 'orgId'>
}): Promise<BillingItem> {
  const response = await requestJson({
    method: 'PUT',
    url: apiUrl(apiOrganizationUrl(orgId), `/billing/items/${itemId}`, {}),
    body: JSON.stringify(item),
  })
  return response.data as BillingItem
}

export async function upsertBillingItem({
  billingItemData,
  itemId,
  orgId,
}: {
  billingItemData: BillingItemData
  itemId: string
  orgId: string
}) {
  const coreProps = {
    orgId,
    item: billingItemData,
  }

  if (itemId) {
    return await updateBillingItem({
      itemId,
      ...coreProps,
    })
  }

  return await createBillingItem({ ...coreProps })
}

export async function getBillingInvoices({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId: string
}): Promise<BillingInvoice[]> {
  const response = await requestJson({
    url: apiUrl(facilityUrl(orgId, facilityId), '/invoices', {}),
  })
  return response.data as BillingInvoice[]
}

export async function getBillingInvoicePreview({
  orgId,
  facilityId,
  invoiceId,
  personId,
}: {
  orgId: string
  facilityId: string
  invoiceId: string
  personId: string
}): Promise<DetailedInvoice> {
  const response = await requestJson({
    url: apiUrl(
      personUrl(orgId, facilityId, personId),
      `/invoices/${invoiceId}`,
      {}
    ),
  })

  return response.data as DetailedInvoice
}

export async function getFacilityBillingItemsWithParentCategories({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId: string
}) {
  const response = await requestJson({
    url: apiUrl(facilityUrl(orgId, facilityId), '/billing/itemsByCategory', {}),
  })

  return response.data as BillingItemsWithParentCategory[]
}
export async function getOrgBillingItems({
  orgId,
}: {
  orgId: string
}): Promise<BillingItem[]> {
  const response = await requestJson({
    url: apiUrl(apiOrganizationUrl(orgId), '/billing/items', {}),
  })
  return response.data as BillingItem[]
}

export async function createBillingCharges({
  orgId,
  facilityId,
  billingChargesDataList,
}: {
  orgId: string
  facilityId: string
  billingChargesDataList: BillingChargeData[]
}): Promise<void> {
  await requestJson({
    method: 'POST',
    url: apiUrl(facilityUrl(orgId, facilityId), '/billing/charges', {}),
    body: JSON.stringify(billingChargesDataList),
  })

  return undefined
}

export async function createInvoices({
  orgId,
  facilityId,
  data,
}: {
  orgId: string
  facilityId: string
  data: DraftStatementsRequest
}) {
  const response = await requestJson({
    method: 'POST',
    url: apiUrl(facilityUrl(orgId, facilityId), '/invoices', {}),
    body: JSON.stringify(data),
  })

  return response.data as BillingInvoice[]
}

function getBillingChargesUrl(props: RequiredPersonIds) {
  const { orgId, facilityId, id: personId } = props

  return `${personUrl(orgId, facilityId, personId)}/billing/charges`
}

export async function getBillingCharges({
  orgId,
  facilityId,
  personId,
}: {
  orgId: string
  facilityId: string
  personId: string
}): Promise<BillingCharge[]> {
  const response = await requestJson({
    url: getBillingChargesUrl({ orgId, facilityId, id: personId }),
  })
  return response.data as BillingCharge[]
}

export async function deleteBillingCharge({
  personIds,
  chargeId,
}: {
  personIds: RequiredPersonIds
  chargeId: string
}) {
  return (await requestJson({
    url: `${getBillingChargesUrl(personIds)}/${chargeId}`,
    method: 'DELETE',
  })) as Promise<{ meta: { hello: 'Deleted' } }>
}

export async function updateBillingCharge({
  personIds,
  chargeData,
  chargeId,
}: {
  personIds: RequiredPersonIds
  chargeData: BillingChargeData
  chargeId: string
}) {
  return (await requestJson({
    url: `${getBillingChargesUrl(personIds)}/${chargeId}`,
    method: 'POST',
    body: JSON.stringify(chargeData),
  })) as Promise<{ data: BillingCharge }>
}

export async function endBillingCharge({
  personIds,
  chargeId,
  endDate,
}: {
  personIds: RequiredPersonIds
  chargeId: string
  endDate: string
}) {
  return (await requestJson({
    url: `${getBillingChargesUrl(personIds)}/${chargeId}/endDate`,
    method: 'POST',
    body: `"${endDate}"`,
  })) as Promise<{ data: BillingCharge }>
}

export async function getResidentBillingSummary({
  orgId,
  facilityId,
  personId,
}: {
  orgId: string
  facilityId: string
  personId: string
}) {
  const response = await requestJson({
    url: apiUrl(personUrl(orgId, facilityId, personId), '/billingSummary', {}),
  })

  return response.data as ResidentBillingSummary
}

export async function getResidentList({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId: string
}) {
  const response = await requestJson({
    url: apiUrl(facilityUrl(orgId, facilityId), '/billing/people', {}),
  })

  return response.data as ResidentListEntry[]
}

export async function issueStatementsForResidents({
  orgId,
  facilityId,
  statementIds,
}: {
  orgId: string
  facilityId: string
  statementIds: string[]
}) {
  const response = await requestJson({
    method: 'POST',
    url: apiUrl(
      facilityUrl(orgId, facilityId),
      '/billing/statements/issue',
      {}
    ),
    body: JSON.stringify({ invoiceIds: statementIds }),
  })

  return response.data as BillingInvoice[]
}

export async function issueStatementForResident({
  personIds: { orgId, facilityId, id: personId },
  statementId,
}: {
  personIds: RequiredPersonIds
  statementId: string
}) {
  const response = await requestJson({
    method: 'POST',
    url: `${personUrl(orgId, facilityId, personId)}/billing/statements/${statementId}/issue`,
  })

  return response.data as BillingInvoice[]
}

function getBillingEventsUrl(personIds: RequiredPersonIds) {
  const { orgId, facilityId, id: personId } = personIds
  return `${personUrl(orgId, facilityId, personId)}/billing/events`
}

export async function fetchResidentBillingEvents(personIds: RequiredPersonIds) {
  const response = await requestJson({
    url: getBillingEventsUrl(personIds),
  })

  return response.data as BillingEvent[]
}

export async function completeResidentBillingEvent(
  personIds: RequiredPersonIds,
  eventId: string
) {
  const response = await requestJson({
    url: `${getBillingEventsUrl(personIds)}/${eventId}/status/completed`,
    method: 'POST',
  })

  return response.data as { meta: { id: string } }
}

export async function fetchResidentBillingTransactions(
  personIds: RequiredPersonIds
) {
  const { orgId, facilityId, id: personId } = personIds
  const response = await requestJson({
    url: `${personUrl(orgId, facilityId, personId)}/billing/transactions`,
  })

  return response.data as BillingTransaction[]
}

type GetInvoiceItem = {
  personIds: RequiredPersonIds
  invoiceItemId: string
}

function getInvoiceItemUrl({ personIds, invoiceItemId }: GetInvoiceItem) {
  const { orgId, facilityId, id: personId } = personIds
  return `${personUrl(orgId, facilityId, personId)}/billing/invoiceItems/${invoiceItemId}`
}

export async function fetchInvoiceItem(props: GetInvoiceItem) {
  const response = await requestJson({
    url: getInvoiceItemUrl(props),
  })

  return response.data as InvoiceItem
}

export async function deleteInvoiceItem(props: GetInvoiceItem) {
  return (await requestJson({
    url: getInvoiceItemUrl(props),
    method: 'DELETE',
  })) as Promise<void>
}

export async function regenerateStatements(personIds: RequiredPersonIds) {
  const { orgId, facilityId, id: personId } = personIds

  return (await requestJson({
    url: `${personUrl(orgId, facilityId, personId)}/statements/regenerate`,
    method: 'POST',
  })) as Promise<void>
}

function getFacilityBillingUrl({ orgId, id }: FacilityIds) {
  return `${facilityUrl(orgId, id)}/billing`
}

/**
 * To download Facility Billing Statements PDF
 */
export function getFacilityBillingStatementsUrl(facilityIds: FacilityIds) {
  return `${getFacilityBillingUrl(facilityIds)}/statements.pdf`
}

export async function fetchClosedBillingAccounting(facilityIds: FacilityIds) {
  // Use requestAnything since API may return 204 No Content (vs JSON)
  return await requestAnything({
    url: `${getFacilityBillingUrl(facilityIds)}/accountingPeriodClose`,
  })
    .then((res) => {
      if (res.status === 204) {
        return { data: undefined }
      }

      return res.json()
    })
    .then((res) => res.data as AccountingPeriodClose)
}

export async function closeBillingAccountingPeriod({
  facilityIds,
  isoStr,
}: {
  facilityIds: FacilityIds
  isoStr: string
}) {
  const response = await requestJson({
    url: `${getFacilityBillingUrl(facilityIds)}/closeAccountingPeriod`,
    method: 'POST',
    body: `"${isoStr}"`,
  })

  return response.data as AccountingPeriodClose
}

export function getLedgerExportUrl({
  orgId,
  facilityId,
  id,
}: {
  orgId: string
  facilityId: string
  id: string
}) {
  return `${facilityUrl(orgId, facilityId)}/billing/journalEntries/${id}`
}
