import { Redirect, Route, Switch } from 'react-router-dom'
import Ambulatory from './Ambulatory'
import Diets from './Diets'
import EmailInfo from './EmailInfo'
import ResidentImport from './FileImport'
import ResidentTransfer from './ResidentTransfer'
import ScheduledTasks from './ScheduledTasks/ScheduledTasks'
import './style.css'
import { useTitle } from 'react-use'
import { SuperUserOnlyRoute } from '@shared/components/Auth/GatedRoute'
import { tw } from '@shared/utils/tailwind'
import Content from '@app/components/generic/Content'
import FacilityFieldManagement from '@app/pages/Tools/FacilityFieldManagement'
import { PharmacyIntegrations } from '@app/pages/Tools/PharmacyTools/PharmacyIntegration/PharmacyIntegrations'
import ReprocessMessage from '@app/pages/Tools/PharmacyTools/ReprocessMessage/ReprocessMessage'
import UnassignResident from '@app/pages/Tools/PharmacyTools/UnassignResident/UnassignResident'
import Sidebar from '@app/pages/Tools/Sidebar'
import pageStyles from '@app/styles.module.css'
import InternalUsers from './AddUser/InternalUsers'
import MyFeatureFlags from './AddUser/MyFeatureFlags'
import { AssessmentConfiguration } from './AssessmentConfiguration/AssessmentConfiguration'
import FacilityManagement from './FacilityManagement'
import FacilityMetrics from './FacilityManagement/FacilityMetrics'
import LevelOfCareSettings from './LevelOfCareSettings/LevelOfCareSettings'
import { MedpassConfiguration } from './MedpassConfiguration/MedpassConfiguration'
import * as ToolsPaths from './path'
import PdfTemplateTools from './PdfTemplate'
import HelloSignTemplate from './PdfTemplate/HelloSignTemplate'
import { PageTab } from './PdfTemplate/helpers'
import ReportGenerator from './ReportGenerator'
import { ResidentDocumentUploader } from './ResidentDocumentUploader/ResidentDocumentUploader'
import SecuritySettings from './SecuritySettings/SecuritySettings'
import ShiftConfiguration from './ShiftConfiguration'

const DEFAULT_TOOLS_PATH = `${ToolsPaths.TOOLS_PDF_TEMPLATE_PATH}/${PageTab.PDF_TEMPLATE_LIST}`

function PageContent() {
  useTitle('Tools')

  return (
    <>
      <div className={'flex-grow'}>
        <Switch>
          <Route
            exact
            path={ToolsPaths.TOOLS_PATH}
            component={() => (
              <Content>
                <h1 className={tw`page-title mt-[24px]`}>
                  August Health Tools
                </h1>
                Please select a tool from the sidebar.
              </Content>
            )}
          />
          <Redirect
            exact
            from={ToolsPaths.TOOLS_PDF_TEMPLATE_PATH}
            to={DEFAULT_TOOLS_PATH}
          />
          <Redirect
            exact
            from={ToolsPaths.TOOLS_ADD_USER_PATH}
            to={ToolsPaths.TOOLS_INTERNAL_USERS_PATH}
          />
          <SuperUserOnlyRoute
            exact
            path={ToolsPaths.TOOLS_MY_FEATURE_FLAGS_PATH}
            component={MyFeatureFlags}
          />
          <SuperUserOnlyRoute
            exact
            path={ToolsPaths.TOOLS_INTERNAL_USERS_PATH}
            component={InternalUsers}
          />
          <Route
            exact
            path={ToolsPaths.TOOLS_PDF_TEMPLATE_HELLO_SIGN_PATH}
            component={HelloSignTemplate}
          />
          <Route
            exact
            path={ToolsPaths.TOOLS_PDF_TEMPLATE_LIST_PATHS}
            component={PdfTemplateTools}
          />
          <SuperUserOnlyRoute
            exact
            path={ToolsPaths.TOOLS_FACILITY_LIST_PATH}
            component={FacilityManagement}
          />
          <SuperUserOnlyRoute
            exact
            path={ToolsPaths.TOOLS_FACILITY_METRICS_PATH}
            component={FacilityMetrics}
          />
          <SuperUserOnlyRoute
            exact
            path={ToolsPaths.TOOLS_REPORTS_PATHS}
            component={ReportGenerator}
          />
          <Route
            exact
            path={ToolsPaths.TOOLS_RESIDENT_TRANSFER_PATH}
            component={ResidentTransfer}
          />
          <SuperUserOnlyRoute
            exact
            path={ToolsPaths.TOOLS_RESIDENT_IMPORT_PATH}
            component={ResidentImport}
          />
          <SuperUserOnlyRoute
            path={ToolsPaths.TOOLS_EMAIL_INFO_PATH}
            component={EmailInfo}
          />
          <Route
            exact
            path={ToolsPaths.TOOLS_RESIDENT_DOCUMENT_UPLOADER}
            component={ResidentDocumentUploader}
          />
          <SuperUserOnlyRoute
            exact
            path={ToolsPaths.TOOLS_LOC_SETTINGS}
            component={LevelOfCareSettings}
          />
          <SuperUserOnlyRoute
            exact
            path={ToolsPaths.TOOLS_MEDPASS_CONFIG_PATH}
            component={MedpassConfiguration}
          />
          <SuperUserOnlyRoute
            exact
            path={ToolsPaths.TOOLS_ASSESSMENT_CONFIG}
            component={AssessmentConfiguration}
          />
          <SuperUserOnlyRoute
            exact
            path={ToolsPaths.TOOLS_DIETS_PATH}
            component={Diets}
          />
          <SuperUserOnlyRoute
            exact
            path={ToolsPaths.TOOLS_AMBULATORY_PATH}
            component={Ambulatory}
          />
          <SuperUserOnlyRoute
            exact
            path={ToolsPaths.TOOLS_SCHEDULED_TASKS_PATH}
            component={ScheduledTasks}
          />
          <SuperUserOnlyRoute
            exact
            path={ToolsPaths.TOOLS_SECURITY_SETTINGS_PATH}
            component={SecuritySettings}
          />
          <Route
            exact
            path={ToolsPaths.TOOLS_FACILITY_FIELDS_PATH}
            component={FacilityFieldManagement}
          />
          <SuperUserOnlyRoute
            exact
            path={ToolsPaths.TOOLS_REPROCESS_PHARMACY_MESSAGES_PATH}
            component={ReprocessMessage}
          />
          <SuperUserOnlyRoute
            exact
            path={ToolsPaths.TOOLS_UNASSIGN_PHARMACY_RESIDENT}
            component={UnassignResident}
          />
          <SuperUserOnlyRoute
            exact
            path={ToolsPaths.TOOLS_PHARMACY_INTEGRATIONS}
            component={PharmacyIntegrations}
          />
          <SuperUserOnlyRoute
            exact
            path={ToolsPaths.TOOLS_SHIFTS_PATH}
            component={ShiftConfiguration}
          />
        </Switch>
      </div>
    </>
  )
}

export default function Page() {
  return (
    <div className={pageStyles.contentContainer}>
      <Sidebar />
      <PageContent />
    </div>
  )
}
