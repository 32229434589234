import { isUndefined, omitBy } from 'lodash'
import urlcat, { ParamMap } from 'urlcat'
import { ApiParams } from '@shared/api/request'
import environment from '@shared/environment'
import { SettingsType } from '@shared/types/org_facility_settings'

function apiParams(params: ParamMap): ParamMap {
  return omitBy(params, isUndefined)
}

export function apiUrl(
  baseUrl: string,
  pathTemplate: string,
  params: ParamMap
): string {
  return urlcat(baseUrl, pathTemplate, apiParams(params))
}

export const baseUrl: string = environment.baseUrl

export const loginUrl = (params: ParamMap = {}) =>
  apiUrl(baseUrl, '/login', params)

export const facilitiesUrl = (orgId: string, params: ParamMap = {}) =>
  apiUrl(baseUrl, '/organizations/:orgId/facilities', {
    orgId,
    ...params,
  })

export const facilityUrl = (
  orgId: string,
  facilityId: string,
  params: ParamMap = {}
) =>
  apiUrl(baseUrl, '/organizations/:orgId/facilities/:facilityId', {
    orgId,
    facilityId,
    ...params,
  })

export const personUrl = (
  orgId: string,
  facilityId: string,
  personId: string,
  params: ParamMap = {}
) =>
  apiUrl(facilityUrl(orgId, facilityId), '/people/:personId', {
    personId,
    ...params,
  })

export const userUrl = (userId: string, params: ParamMap = {}) =>
  apiUrl(baseUrl, '/users/:userId', {
    userId,
    ...params,
  })

export const queueJobUrl = (params: ParamMap = {}) =>
  apiUrl(baseUrl, '/queueJob', {
    ...params,
  })

export const facilitySettingsByTypeUrl = (
  orgId: string,
  facilityId: string,
  settingsType: SettingsType,
  params: ParamMap = {}
) =>
  apiUrl(
    baseUrl,
    '/organizations/:orgId/facilities/:facilityId/settings/:settingsType',
    {
      orgId,
      facilityId,
      settingsType,
      ...params,
    }
  )

export const medicationAdministrationsByOrderGroupUrl = (
  orgId: string,
  facilityId: string,
  personId: string,
  orderGroupId: string,
  params: ParamMap = {}
) =>
  apiUrl(
    baseUrl,
    '/organizations/:orgId/facilities/:facilityId/people/:personId/medicationAdministrations/orderGroups/:orderGroupId',
    { orgId, facilityId, personId, orderGroupId, ...params }
  )

export const emarBulkUpdateUrl = (
  orgId: string,
  facilityId: string,
  params: ParamMap = {}
) =>
  apiUrl(
    baseUrl,
    '/organizations/:orgId/facilities/:facilityId/emar/bulkUpdate',
    { orgId, facilityId, ...params }
  )

export const emarUrl = (
  orgId: string,
  facilityId: string,
  params: ParamMap = {}
) =>
  apiUrl(baseUrl, '/organizations/:orgId/facilities/:facilityId/emar', {
    orgId,
    facilityId,
    ...params,
  })

interface EmarProgressParams extends ApiParams {
  date?: string
}
export const emarProgressUrl = (
  orgId: string,
  facilityId: string,
  params: EmarProgressParams
) => apiUrl(emarUrl(orgId, facilityId), '/progress', params)

export const medGroupsUrl = (
  orgId: string,
  facilityId: string,
  params: ParamMap = {}
) =>
  apiUrl(baseUrl, '/organizations/:orgId/facilities/:facilityId/medGroups', {
    orgId,
    facilityId,
    ...params,
  })

export const medPassesUrl = (
  orgId: string,
  facilityId: string,
  params: ParamMap = {}
) =>
  apiUrl(baseUrl, '/organizations/:orgId/facilities/:facilityId/medPasses', {
    orgId,
    facilityId,
    ...params,
  })

export const getSnapshotsUrl = (
  orgId: string,
  personId: string,
  dataType: string,
  params: ParamMap = {}
) =>
  apiUrl(
    baseUrl,
    '/organizations/:orgId/people/:personId/snapshots/:dataType',
    {
      orgId,
      personId,
      dataType,
      ...params,
    }
  )

export const confirmForgotPasswordUrl = `${baseUrl}/users/confirmForgotPassword`

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
export const forgotPasswordUrl = `${baseUrl}/users/forgotPassword`

export const pingUrl = `${baseUrl}/ping`

export function possibleSignersUrl({
  orgId,
  facilityId,
  personId,
  taskId,
}: {
  orgId: string
  facilityId: string
  personId: string
  taskId: string
}) {
  const params = {
    includesUserWithOrgAccess: true,
    includesRp: true,
  }
  return apiUrl(
    baseUrl,
    '/organizations/:orgId/facilities/:facilityId/people/:personId/tasks/:taskId/listPossibleSigners',
    {
      orgId,
      facilityId,
      personId,
      taskId,
      ...params,
    }
  )
}

export function signaturePacketUrl({
  orgId,
  personId,
  facilityId,
  taskId,
}: {
  orgId: string
  personId: string
  facilityId: string
  taskId: string
}) {
  return apiUrl(
    baseUrl,
    '/organizations/:orgId/facilities/:facilityId/people/:personId/tasks/:taskId/signaturePacket',
    {
      orgId,
      personId,
      facilityId,
      taskId,
    }
  )
}

export function processSignatureUrl({
  orgId,
  facilityId,
  personId,
  taskId,
  email,
}: {
  orgId: string
  facilityId: string
  personId: string
  taskId: string
  email: string
}) {
  const url =
    signaturePacketUrl({
      orgId,
      facilityId,
      personId,
      taskId,
    }) +
    '/processSignature?email=' +
    encodeURIComponent(email)

  return url
}

export function incidentActionPdfUrl({
  orgId,
  facilityId,
  personId,
  incidentId,
  actionId,
}: {
  orgId: string
  facilityId: string
  personId: string
  incidentId: string
  actionId: string
}) {
  return apiUrl(
    baseUrl,
    '/organizations/:orgId/facilities/:facilityId/people/:personId/incidents/:incidentId/actions/:actionId/report.pdf',
    {
      orgId,
      facilityId,
      personId,
      incidentId,
      actionId,
    }
  )
}

export function billingDashboardsUrl(params: { orgId: string }) {
  return apiUrl(baseUrl, '/organizations/:orgId/dashboards/billing', params)
}
