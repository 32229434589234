import { useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Card from '@shared/components/Card'
import Icon from '@shared/components/Icon'
import { tw } from '@shared/utils/tailwind'
import { FormattedEvent } from './helpers'

type Props = {
  filteredEvents: FormattedEvent[]
  onDismiss: (eventId: string) => Promise<void>
}

export default function BillingEvents({ filteredEvents, onDismiss }: Props) {
  const [isDismissing, setIsDismissing] = useState(false)

  if (filteredEvents.length === 0) {
    return null
  }

  return (
    <Card className={tw`m-0 mt-6 p-4`}>
      <ul className={'flex w-full flex-col'}>
        {filteredEvents.map((event) => {
          const eventId = event.id

          return (
            <li
              key={`pending-event-warning-${eventId}`}
              className={
                'items-center text-xs font-medium leading-5 [&:not(:first-child)]:mt-4'
              }
            >
              <div className={'flex items-center justify-between'}>
                <div className={'inline-flex items-center'}>
                  <Icon name={'bell-on'} variant={'light'} className={'mr-1'} />
                  <div>{event.message}</div>
                </div>
                <AsyncIconButton
                  initialIcon={'do-not-enter'}
                  buttonSize={'xsmall'}
                  buttonStyle={'secondary-outline'}
                  isLoading={isDismissing}
                  disabled={isDismissing}
                  onClick={async () => {
                    setIsDismissing(true)
                    await onDismiss(eventId)
                    setIsDismissing(false)
                  }}
                >
                  Dismiss
                </AsyncIconButton>
              </div>
            </li>
          )
        })}
      </ul>
    </Card>
  )
}
