import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { Vital } from '@augusthealth/models/com/august/protos/vital'
import { useContext, useEffect, useState } from 'react'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { Person } from '@shared/types/person'
import { Loading } from '@shared/utils/loading'
import { getAllVitals } from '../api/vitals'

export function useAllVitals(person: Person | undefined) {
  const { user } = useUserContext()
  const { setError } = useContext(GlobalContext)
  const [allVitals, setAllVitals] = useState<Loading<Vital[]>>({
    tag: 'Loading',
  })

  const { id } = person || {}

  const refreshAllVitals = async (markAsLoadingDuringRefresh = true) => {
    if (person === undefined) {
      return Promise.resolve()
    }

    if (
      !hasPermissionForPerson({
        user,
        person,
        permissions: [GroupPermission.GROUP_PERMISSION_VITAL_READ],
      })
    ) {
      return Promise.resolve()
    }

    if (markAsLoadingDuringRefresh) {
      setAllVitals({ tag: 'Loading' })
    }

    return getAllVitals(person)
      .then((res) => {
        setAllVitals({ tag: 'Complete', value: res })
      })
      .catch(setError)
  }

  useEffect(() => {
    void refreshAllVitals()
  }, [id])

  return { allVitals, refreshAllVitals }
}
