import { OptionTypeBase } from '@shared/components/StyledSelect'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { Person } from '@shared/types/person'
import { BaseFacilitySelect } from './base/BaseFacilitySelect'
import { BaseOrgSelect } from './base/BaseOrgSelect'
import { BasePersonSelect } from './base/BasePersonSelect'

type Props = {
  prepopulateWithDefaults?: boolean
  onOrgSelect: (org: OptionTypeBase<Organization>) => void
  onFacilitySelect: (facility: OptionTypeBase<Facility> | null) => void
  onPersonSelect: (person: OptionTypeBase<Person> | null) => void
  orgLabel?: string
  facilityLabel?: string
  personLabel?: string
  getPersonOptionLabel?: (person: Person) => any
  selectedOrganization: OptionTypeBase<Organization> | null
  selectedFacility: OptionTypeBase<Facility> | null
  selectedPerson: OptionTypeBase<Person> | null
  personSelectProps?: any
}

export const OrgFacilityPersonSelects = ({
  prepopulateWithDefaults = false,
  onOrgSelect,
  onFacilitySelect,
  onPersonSelect,
  orgLabel,
  facilityLabel,
  personLabel,
  selectedOrganization,
  selectedFacility,
  selectedPerson,
  personSelectProps,
}: Props) => {
  return (
    <>
      <BaseOrgSelect
        orgLabel={orgLabel}
        onOrgSelect={(o: OptionTypeBase<Organization>) => {
          onFacilitySelect(null)
          onPersonSelect(null)
          onOrgSelect(o)
        }}
        prepopulateWithDefaults={prepopulateWithDefaults}
        selectedOrganization={selectedOrganization}
      />
      <BaseFacilitySelect
        facilityLabel={facilityLabel}
        orgId={selectedOrganization?.value.id}
        onFacilitySelect={(o: OptionTypeBase<Facility>) => {
          onPersonSelect(null)
          onFacilitySelect(o)
        }}
        prepopulateWithDefaults={prepopulateWithDefaults}
        selectedFacility={selectedFacility}
      />
      <BasePersonSelect
        personLabel={personLabel}
        onPersonSelect={(o: OptionTypeBase<Person>) => {
          onPersonSelect(o)
        }}
        prepopulateWithDefaults={prepopulateWithDefaults}
        facility={selectedFacility?.value}
        selectedPerson={selectedPerson}
        personSelectProps={personSelectProps}
      />
    </>
  )
}
