import { DateMessage } from '@augusthealth/models/com/august/protos/date'
import { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { fetchSnapshotImageUrl, mergePatchPerson } from '@shared/api/person'
import { updateSnapshot } from '@shared/api/request'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import LoadingPopup from '@shared/components/LoadingPopup'
import StyledSelect, { OptionTypeBase } from '@shared/components/StyledSelect'
import {
  EmailInput,
  PhoneInput,
} from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import { Person } from '@shared/types/person'
import { PickPartial } from '@shared/types/utilities'
import { fromDateToDateMessage } from '@shared/utils/date'
import {
  getFirstName,
  getLastName,
  getMiddleName,
  getTitle,
} from '@shared/utils/humanName'
import {
  getBirthDate,
  getEmail,
  getPhone,
  getProfileSvgPath,
  isResident,
  TITLE_OPTIONS,
} from '@shared/utils/person'
import { EMAIL_REGX } from '@shared/utils/regex'
import { tw } from '@shared/utils/tailwind'
import { genderOptions } from '@app/components/AddPersonPopup/helpers'
import DateOfBirth from '@app/components/DateOfBirth'
import { isCompleteAndValidBirthday } from '@app/components/DateOfBirth/utils'
import ContactPointUseDropdown, {
  CONTACT_POINT_USE_OPTIONS,
} from '@app/components/PhoneInputWithType/ContactPointUseDropdown'
import PersonContext from '@app/contexts/PersonContext'
import styles from './styles.module.css'
import { makePatch } from './helpers'
import { FormData } from './types'

function updatePerson(person: Person, formData: FormData) {
  return mergePatchPerson({
    fId: person.facilityId,
    pId: person.id,
    orgId: person.orgId,
    patch: makePatch(formData, person),
    manualETag: '*',
  })
}

interface Props {
  person: Person
  doneFn: (updatedPerson: Person | null) => void
  handleError: (error: unknown) => void
}

function ProfileForm({ person, doneFn, handleError }: Props) {
  const { profileImageURL, setProfileImageURL } = useContext(PersonContext)
  const [profileImage, setProfileImage] = useState<File>()
  const [isPersonUpdating, setIsPersonUpdating] = useState(false)
  const [fileName, setFileName] = useState('')

  const { register, handleSubmit, formState, control, setValue, trigger } =
    useForm<FormData>({
      mode: 'onChange',
      shouldFocusError: true,
      defaultValues: {
        firstName: getFirstName(person.name),
        middleName: getMiddleName(person.name),
        lastName: getLastName(person.name),
        title: getTitle(person.name),
        gender: person.gender,
        email: getEmail(person)?.value || '',
        phone: getPhone(person)?.value || '',
        phoneUse: getPhone(person)?.use || '',
        dob: person.birthDate,
      },
    })

  const onDobUpdate = (d?: DateMessage) => {
    if (d) {
      setValue('dob', d)
      void trigger(['dob'])
    }
  }

  const onSubmit = async (data: FormData) => {
    if (!isCompleteAndValidBirthday(data.dob)) {
      void Promise.reject(new Error('Date of birth is not valid!'))
    }

    try {
      const { data: updatedPerson } = await updatePerson(person, data)
      if (data.gender !== person.gender) {
        // FormData has gender as well, compatible with Person
        setProfileImageURL(
          getProfileSvgPath(data as PickPartial<Person, 'gender'>)
        )
      }
      if (profileImage) {
        setIsPersonUpdating(true)
        const snapshotResponse = await updateSnapshot({
          pId: person.id || '',
          orgId: person.orgId || '',
          dataType: 'DATA_TYPE_PROFILE_PHOTO',
          file: profileImage,
        })
        const url = await fetchSnapshotImageUrl({
          person: person as Required<Person>,
          snapshotId: `${snapshotResponse.data.id}`,
        })
        setProfileImageURL(url)
        setIsPersonUpdating(false)
      }
      doneFn(updatedPerson)
    } catch (error: unknown) {
      handleError(error)
    }
  }

  const onImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.currentTarget.files
    const file = fileList && fileList[0]

    if (file) {
      setFileName(file.name)
      setProfileImage(file)
    }
  }

  const getPreviewProfileImage = () => {
    // prefer to use the newly uploaded profile picture
    if (profileImage) {
      return window.URL.createObjectURL(profileImage)
    }
    // otherwise use the existing profile picture
    if (profileImageURL) {
      return profileImageURL
    }

    return getProfileSvgPath(person)
  }

  const getDefaultDobValue = getBirthDate(person) || undefined

  const getDefaultDobValueDateMessage = getDefaultDobValue
    ? fromDateToDateMessage(getDefaultDobValue)
    : undefined

  const title = isResident(person) ? 'Resident Profile' : 'Move-in Profile'

  return (
    <>
      <Modal
        id="edit-profile"
        open={true}
        onClose={() => doneFn(null)}
        contentClassName={tw`w-[664px]`}
      >
        <ModalTitle>{title}</ModalTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={'mb-[32px] flex'}>
            <div className="mr-[16px]">
              <LabelAboveInput
                htmlFor="firstName"
                subLabel={requiredLabel(!!formState.errors.firstName)}
              >
                First Name
              </LabelAboveInput>
              <BasicInput
                placeholder="First Name"
                {...register('firstName', { required: true })}
              />
            </div>
            <div className="mr-[16px]">
              <LabelAboveInput htmlFor="middleName">
                Middle Name
              </LabelAboveInput>
              <BasicInput
                placeholder="Middle Name"
                {...register('middleName', { required: false })}
              />
            </div>
            <div className={'grow'}>
              <Controller
                control={control}
                name="title"
                render={({ field: { onChange, value } }) => (
                  <div style={{ width: '136px' }}>
                    <LabelAboveInput htmlFor="title">Title</LabelAboveInput>
                    <StyledSelect
                      name="title"
                      options={TITLE_OPTIONS}
                      value={TITLE_OPTIONS.find((opt) => opt.value === value)}
                      onChange={(e: OptionTypeBase) => {
                        onChange(e.value)
                      }}
                      placeholder="Title..."
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <div className="mb-[32px]">
            <LabelAboveInput
              htmlFor="lastName"
              subLabel={requiredLabel(!!formState.errors.lastName)}
            >
              Last Name
            </LabelAboveInput>
            <BasicInput
              placeholder="Last Name"
              {...register('lastName', { required: true })}
            />
          </div>
          <div className="mb-[32px] flex">
            <div className="mr-[16px]">
              <Controller
                control={control}
                defaultValue={getDefaultDobValueDateMessage}
                name="dob"
                rules={{
                  required: true,
                  validate: isCompleteAndValidBirthday,
                }}
                render={({ field: { value }, fieldState: { error } }) => (
                  <>
                    <LabelAboveInput
                      htmlFor="dob"
                      subLabel={requiredLabel(!!error)}
                    >
                      Date of Birth
                    </LabelAboveInput>
                    <DateOfBirth
                      value={value}
                      onUpdate={onDobUpdate}
                      allowEmpty
                      acceptDateObject
                      sendUpdateOnError
                      updateOnEachKeyUp
                    />
                  </>
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="gender"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div>
                      <LabelAboveInput
                        htmlFor="gender"
                        subLabel={requiredLabel(!!formState.errors.gender)}
                      >
                        Gender
                      </LabelAboveInput>
                      <StyledSelect
                        name="gender"
                        inputId={'gender'}
                        value={genderOptions.find((opt) => opt.value === value)}
                        onChange={(e: OptionTypeBase) => onChange(e.value)}
                        options={genderOptions}
                        placeholder="Select..."
                      />
                    </div>
                  )
                }}
              />
            </div>
          </div>
          <div className="mb-[32px] flex">
            <div className="flex grow">
              <Controller
                control={control}
                name="phone"
                render={({ field: { onChange, value } }) => (
                  <div className={'mr-[16px] grow'}>
                    <LabelAboveInput htmlFor="phone">Phone</LabelAboveInput>
                    <PhoneInput
                      inputProps={{ name: 'phone', onChange, value }}
                    />
                  </div>
                )}
              />

              <Controller
                control={control}
                name="phoneUse"
                render={({ field: { value, onChange } }) => {
                  return (
                    <div className={styles.phoneUse}>
                      <LabelAboveInput htmlFor="phoneUse">
                        &nbsp;
                      </LabelAboveInput>
                      <ContactPointUseDropdown
                        name={'phoneUse'}
                        onChange={(e: OptionTypeBase) => {
                          onChange(e.value)
                        }}
                        value={CONTACT_POINT_USE_OPTIONS.find(
                          (opt) => opt.value === value
                        )}
                        placeholder={'Select...'}
                      />
                    </div>
                  )
                }}
              />
            </div>
          </div>
          <div className="mb-[32px]">
            <LabelAboveInput
              htmlFor="email"
              subLabel={{
                tag: 'RequiredWhenError',
                hasError: !!formState.errors['email'],
              }}
              errorMessage={formState.errors['email']?.message}
            >
              Email
            </LabelAboveInput>
            <EmailInput
              inputProps={{
                ...register('email', {
                  required: false,
                  validate: (value: string) => {
                    if (value.length === 0) {
                      return true
                    } else if (value.length > 0 && EMAIL_REGX.test(value)) {
                      return true
                    }

                    return 'Invalid format'
                  },
                }),
              }}
            />
          </div>
          <h4>Picture</h4>
          <div className={styles.picture}>
            <img
              alt={`Picture of ${getFirstName(person.name)}`}
              className={styles.profilePicture}
              src={getPreviewProfileImage()}
            />
            <div className={styles.imageUpload} data-testid="container">
              <div>
                <p className={`${styles.bold} ${styles.uploaderText}`}>
                  {fileName}
                </p>
                <input
                  className="visually-hidden"
                  id="picture"
                  type="file"
                  accept="image/png, image/jpeg"
                  capture="user"
                  data-testid="uploader"
                  onChange={onImageUpload}
                />
                <label
                  className={`${styles.uploadLink} ${styles.bold}`}
                  htmlFor="picture"
                >
                  Add a picture of {getFirstName(person.name)}
                </label>
                <div className={styles.helpText}>
                  We recommend a recent color photo
                </div>
              </div>
            </div>
          </div>
          <div>
            <AnimatedPopupFormFooter
              yesBtn={{
                label: 'Save',
                props: {
                  ['data-cy']: 'save-profile-form',
                  ['data-testid']: 'save-profile-form',
                },
              }}
              noBtn={{ action: () => doneFn(null) }}
              formState={formState}
            />
          </div>
        </form>
      </Modal>
      {isPersonUpdating && <LoadingPopup loading />}
    </>
  )
}

export default ProfileForm
