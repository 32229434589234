import { useEffect, useState } from 'react'
import { isLoggedIn, ValidateUserSession } from '@shared/components/Auth/Auth'
import LoginRouter from '@shared/components/Auth/LoginRouter'
import LoadingPopup from '@shared/components/LoadingPopup'
import GlobalContext from '@shared/contexts/GlobalContext'
import environment from '@shared/environment'
import { AugustError } from '@shared/utils/error'
import { AsyncResult } from '@shared/utils/loading'
import { PageRouter } from '@app/PageRouter'

export default function App() {
  const [error, setError] = useState<AugustError>()
  const [showLogin, setShowLogin] = useState<AsyncResult<boolean, unknown>>({
    tag: 'Loading',
  })
  const [loading, setLoading] = useState(false)

  const search = window.location.search
  const queryString = new URLSearchParams(search)
  const redirectPath = queryString.get('path') || '/'

  useEffect(() => {
    if (environment.authEnabled) {
      void isLoggedIn()
        .then((result) => {
          setShowLogin({ tag: 'Complete', value: !result })
        })
        .catch((e) => setShowLogin({ tag: 'Error', value: e }))
    }
  }, [])

  const loggedInComponent = (
    <GlobalContext.Provider value={{ error, setError, setLoading }}>
      <LoadingPopup loading={loading} />
      <PageRouter defaultRedirectPath={redirectPath} />
    </GlobalContext.Provider>
  )
  const loggedOutComponent = (
    <GlobalContext.Provider value={{ error, setError, setLoading }}>
      <LoginRouter />
    </GlobalContext.Provider>
  )

  if (environment.authEnabled === false) {
    return loggedInComponent
  }

  if (
    showLogin.tag === 'Error' ||
    (showLogin.tag === 'Complete' && showLogin.value)
  ) {
    return loggedOutComponent
  } else if (showLogin.tag === 'Loading') {
    return <LoadingPopup loading={true} />
  } else {
    return (
      <>
        {environment.authEnabled && (
          <ValidateUserSession
            interval={environment.refreshTokenInterval}
            sessionInactivityExpiration={
              environment.sessionInactivityExpiration
            }
          />
        )}
        {loggedInComponent}
      </>
    )
  }
}
