import { AllergyIntolerance_AllergyIntoleranceCategory } from '@augusthealth/models/com/august/protos/allergy_intolerance'
import { useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { allergyList } from '@shared/utils/person'
import { updateDietAndAllergies } from '@app/components/Diet/helpers'
import styles from '@app/components/Diet/styles.module.css'
import DietSelect from '@app/components/formElements/DietSelect'
import Allergies from '@app/components/Prospects/Details/Diagnoses/Allergies'
import usePerson from '@app/hooks/usePerson'

type Props = { person: Person; onClose: (updated: boolean) => Promise<void> }

export default function EditDiet({ person, onClose }: Props) {
  const { setError } = useContext(GlobalContext)
  const { handleSubmit, formState } = useForm()

  const [specialDiets, setSpecialDiets] = useState<string[]>(
    person.socialHistory?.diet?.dietTypeValue || []
  )
  const foodCategory =
    AllergyIntolerance_AllergyIntoleranceCategory.ALLERGY_INTOLERANCE_CATEGORY_FOOD
  const [foodAllergies, setFoodAllergies] = useState([
    ...allergyList(person).filter(
      (allergy) => allergy.category === foodCategory
    ),
    { summary: '', category: foodCategory },
  ])
  const [notes, setNotes] = useState(person.socialHistory?.diet?.summary ?? '')

  const { person: updatedPerson } = usePerson({
    initialData: person,
    fields: ['socialHistory/diet', 'allergiesAndIntolerances/allergies'],
  })

  useEffect(() => {
    if (updatedPerson.tag === 'Complete') {
      setSpecialDiets(
        updatedPerson.value.socialHistory?.diet?.dietTypeValue || []
      )
      setFoodAllergies([
        ...allergyList(updatedPerson.value).filter(
          (allergy) => allergy.category === foodCategory
        ),
        { summary: '', category: foodCategory },
      ])
      setNotes(updatedPerson.value.socialHistory?.diet?.summary ?? '')
    }
  }, [updatedPerson.tag])

  const onSubmit = async () => {
    if (updatedPerson.tag === 'Loading') {
      return
    }

    const allergies = [
      ...foodAllergies.filter((f) => f.summary?.trim()),
      ...allergyList(updatedPerson.value).filter(
        (allergy) => allergy.category !== foodCategory
      ),
    ]

    try {
      await updateDietAndAllergies(person, {
        specialDiets,
        allergies,
        notes,
      })
      await onClose(true)
    } catch (e) {
      setError(e)
    }
  }

  const onTrashDiet = (index: number) =>
    setSpecialDiets(specialDiets.filter((_d, i) => i !== index))

  const formContent = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.content}>
        <section className="mb-[32px]">
          <LabelAboveInput htmlFor="diet-type">Diet</LabelAboveInput>
          {specialDiets.map((diet, index) => (
            <div className={styles.list} key={index}>
              <div className={styles.listItem}>{diet}</div>
              <button
                id={`delete-diet-${index}`}
                className={styles.deleteButton}
                onClick={(e) => {
                  e.preventDefault()
                  onTrashDiet(index)
                }}
                type="button"
              >
                <i className="fas fa-fw fa-trash-can" />
              </button>
            </div>
          ))}
          <DietSelect
            className="mb-[32px]"
            id="diet-type"
            instanceId="diet-type"
            onChange={(newDiet?: OptionTypeBase) => {
              if (newDiet?.value) {
                setSpecialDiets([...specialDiets, newDiet.value])
              }
            }}
            optionsToFilter={specialDiets}
            placeholder="Select a diet..."
            value={null}
          />
        </section>
        <section className="mb-[32px]">
          <LabelAboveInput htmlFor="allergies">Food Allergies</LabelAboveInput>
          <Allergies
            allergies={foodAllergies}
            setAllergies={setFoodAllergies}
            category={foodCategory}
          />
        </section>
        <section className="mb-[32px]">
          <LabelAboveInput htmlFor="additional-notes">
            Additional Notes
          </LabelAboveInput>
          <BasicTextarea
            className="mb-[32px]"
            name="additional-notes"
            value={notes}
            onChange={(e) => setNotes(e.currentTarget.value)}
          />
        </section>
      </div>
      <footer className={`${styles.footer} mt-[32px]`}>
        <AnimatedPopupFormFooter
          noBtn={{ action: () => void onClose(false) }}
          yesBtn={{
            props: {
              type: 'button',
              onClick: handleSubmit(onSubmit),
              id: 'saveDiet',
              'data-testid': 'save-diet',
              disabled: updatedPerson.tag === 'Loading',
            },
          }}
          formState={formState}
        />
      </footer>
    </form>
  )

  return (
    <PortalModal>
      <AnimatedPopup title="Diet">{formContent}</AnimatedPopup>
    </PortalModal>
  )
}

function PortalModal({ children }) {
  const appRoot = document.getElementById('root')

  return createPortal(children, appRoot!)
}
