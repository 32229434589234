import { SlidingScaleEntry } from '@augusthealth/models/com/august/protos/dosage'
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFieldArray,
  useFormContext,
} from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import {
  MedOrderFormData,
  SlidingScaleEntryWithId,
} from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'
import { DynamicFrequencyInputs } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/DynamicFrequencyInputs/DynamicFrequencyInputs'
import { SlidingScaleEntryRow } from './SlidingScaleEntryRow/SlidingScaleEntryRow'
import { VitalCheck } from './VitalCheck'

type Props = {
  readOnly: boolean
  doseIndex: number
}
export const SlidingScaleForm = ({ doseIndex, readOnly }: Props) => {
  const methods = useFormContext<MedOrderFormData>()
  const { formState, getValues, control, watch } = methods
  const { fields, insert, remove } = useFieldArray({
    control,
    name: `doses.${doseIndex}.slidingScale.entries`,
  })

  const firstDose = watch(`doses.${doseIndex}`)
  const scaleEntries = firstDose?.slidingScale?.entries ?? []

  const measuredUnit = 'mg/dL'
  const doseUnit = scaleEntries[0]?.doseQuantity?.unit

  const entryErrors: Merge<
    FieldError,
    FieldErrorsImpl<SlidingScaleEntry[]>
  > = formState.errors?.doses?.[doseIndex]?.slidingScale?.entries ?? []
  const firstRangeErrorIndex =
    // @ts-ignore
    entryErrors?.findIndex(
      (entry: FieldErrorsImpl<SlidingScaleEntry>) =>
        entry?.bounds &&
        // @ts-ignore
        (!!entry.bounds.low?.value || !!entry.bounds.high?.value)
    ) ?? undefined

  const firstUnitErrorIndex =
    // @ts-ignore
    entryErrors?.findIndex((entry) => !!entry?.doseQuantity?.value) ?? undefined

  const addNewScaleEntry = (entryIndex: number) => {
    const currentEntries = getValues(
      `doses.${doseIndex}.slidingScale.entries`
    ) as SlidingScaleEntryWithId[]

    const previousHigh = currentEntries?.[entryIndex]?.bounds?.high?.value
      ? (currentEntries[entryIndex].bounds!.high!.value as number) + 1
      : undefined

    insert(
      entryIndex + 1,
      {
        tempId: uuid(),
        bounds: {
          low: {
            value: previousHigh,
            unit: measuredUnit,
          },
          high: {
            value: undefined,
            unit: measuredUnit,
          },
        },
        doseQuantity: {
          value: undefined,
          unit: doseUnit,
        },
        note: undefined,
      },
      {
        shouldFocus: true,
        focusName: `doses.${doseIndex}.slidingScale.entries.${entryIndex + 1}.bounds.high`,
      }
    )
  }
  const removeScaleEntry = (entryIndex: number) => {
    remove(entryIndex)
  }

  const wasSubmitted = formState.isSubmitted

  return (
    <>
      <VitalCheck dose={firstDose} index={doseIndex} />
      <DynamicFrequencyInputs index={doseIndex} readOnly={readOnly} />
      <div className={'mt-[16px]'}>
        {fields?.map((se, sIndex) => {
          return (
            <SlidingScaleEntryRow
              key={`sliding-scale-entry-${se.tempId}`}
              scaleEntry={se}
              readOnly={readOnly}
              index={sIndex}
              doseIndex={doseIndex}
              addScaleEntry={() => addNewScaleEntry(sIndex)}
              removeScaleEntry={() => removeScaleEntry(sIndex)}
              showRangeError={wasSubmitted && sIndex === firstRangeErrorIndex}
              showUnitError={wasSubmitted && sIndex === firstUnitErrorIndex}
            />
          )
        })}
      </div>
    </>
  )
}
