import { DateMessage } from '@augusthealth/models/com/august/protos/date'
import { UnitOfTime } from '@augusthealth/models/com/august/protos/timing'
import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { match } from 'ts-pattern'
import { BadgeInput } from '@shared/components/BadgeInputs/BadgeInput'
import { Label } from '@shared/components/Labels'
import StyledSelect, { OptionTypeBase } from '@shared/components/StyledSelect'
import { ErrorDropdownStyleConfig } from '@shared/components/StyledSelect/utils'
import pluralize from '@shared/utils/pluralize'
import { validatePositiveNumber } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/helpers'
import styles from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/styles.module.css'
import { RoutineDaysOfMonth } from '@app/pages/Routines/CustomRoutines/RoutineDaysOfMonth'
import { RoutineDaysOfWeek } from '@app/pages/Routines/CustomRoutines/RoutineDaysOfWeek'
import { RoutineEveryBlankPeriod } from '@app/pages/Routines/CustomRoutines/RoutineEveryBlankPeriod'
import { RoutineOnThisDay } from '@app/pages/Routines/CustomRoutines/RoutineOnThisDay'
import { RoutineTimesOfDay } from '@app/pages/Routines/CustomRoutines/RoutineTimesOfDay'
import {
  RoutineOrderFormData,
  RoutineOrderPeriodChoice,
} from './RoutineOrderForm'

export const DynamicFrequencyRoutines = ({
  index,
  readOnly,
}: {
  index: number
  readOnly: boolean
}) => {
  const { register, formState, control, watch, getValues, setValue, trigger } =
    useFormContext<RoutineOrderFormData>()

  const [originalEndDate, setOriginalEndDate] = useState<
    DateMessage | undefined | null
  >()

  const startDate = watch('effectivePeriod.startDate')

  const periodChoice = watch(`schedules.${index}.periodChoice`)
  const periodUnit = watch(`schedules.${index}.periodUnit`)
  const frequency = watch(`schedules.${index}.frequency`)

  useEffect(() => {
    if (periodUnit && originalEndDate === undefined) {
      // saves the original end date of the med or null to enable reverting later
      setOriginalEndDate(getValues('effectivePeriod.endDate') ?? null)
    }
  }, [periodUnit])

  useEffect(() => {
    if (formState.isSubmitted) {
      void trigger([
        `schedules.${index}.dayOfMonth`,
        `schedules.${index}.dayOfWeek`,
      ])
    }
  }, [frequency])

  const isFrequencyDaysOfWeekOrMonth =
    periodUnit === UnitOfTime.UNIT_OF_TIME_WEEK ||
    periodUnit === UnitOfTime.UNIT_OF_TIME_MONTH

  const frequencyInputText = () => {
    if (isFrequencyDaysOfWeekOrMonth) {
      return pluralize('day', frequency)
    } else return pluralize('time', frequency)
  }

  const SCHEDULE_OPTIONS: OptionTypeBase<
    keyof typeof RoutineOrderPeriodChoice
  >[] = [
    {
      label: 'Daily',
      value: RoutineOrderPeriodChoice.Daily,
    },
    {
      label: 'Days of week...',
      value: RoutineOrderPeriodChoice.DaysOfWeek,
    },
    {
      label: 'Days of month...',
      value: RoutineOrderPeriodChoice.DaysOfMonth,
    },
    {
      label: 'Every...',
      value: RoutineOrderPeriodChoice.Every,
    },
    {
      label: 'On this day...',
      value: RoutineOrderPeriodChoice.OnThisDay,
    },
  ]

  return (
    <>
      <div className={styles.scheduleInputRow}>
        <BadgeInput
          badgeLabel={'Frequency'}
          type={'number'}
          staticInputText={frequencyInputText()}
          {...register(`schedules.${index}.frequency`, {
            valueAsNumber: true,
            validate: validatePositiveNumber,
          })}
          placeholder={'#'}
          showErrorBorder={!!formState.errors.schedules?.[index]?.frequency}
          disabled={readOnly || isFrequencyDaysOfWeekOrMonth}
        />
        <Controller
          control={control}
          name={`schedules.${index}.periodChoice`}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <Label
                  visuallyHidden
                  htmlFor={`schedules.${index}.periodUnit`}
                  id={`label-schedules.${index}.periodUnit`}
                >
                  Period Unit
                </Label>
                <StyledSelect
                  aria-labelledby={`label-schedules.${index}.periodUnit`}
                  id={`schedules.${index}.periodUnit`}
                  instanceId={`schedules.${index}.periodUnit`}
                  options={SCHEDULE_OPTIONS}
                  placeholder="Select..."
                  onChange={(e: OptionTypeBase<RoutineOrderPeriodChoice>) => {
                    if (value === 'OnThisDay') {
                      // moving away from specific day, reset the endDate to default value
                      setValue(
                        'effectivePeriod.endDate',
                        originalEndDate ?? undefined
                      )
                    }
                    onChange(e.value)

                    match(e.value)
                      .with(RoutineOrderPeriodChoice.Daily, () => {
                        setValue(
                          `schedules.${index}.periodUnit`,
                          UnitOfTime.UNIT_OF_TIME_DAY
                        )
                      })
                      .with(RoutineOrderPeriodChoice.OnThisDay, () => {
                        setValue(`schedules.${index}.onThisDate`, startDate)
                        setValue('effectivePeriod.startDate', startDate)
                        setValue('effectivePeriod.endDate', startDate)
                      })
                      .with(RoutineOrderPeriodChoice.DaysOfWeek, () => {
                        setValue(
                          `schedules.${index}.periodUnit`,
                          UnitOfTime.UNIT_OF_TIME_DAY
                        )
                      })
                      .with(RoutineOrderPeriodChoice.DaysOfMonth, () => {
                        setValue(
                          `schedules.${index}.periodUnit`,
                          UnitOfTime.UNIT_OF_TIME_MONTH
                        )
                      })
                      .with(RoutineOrderPeriodChoice.Every, () => {
                        // Default 'Every' choice to day
                        setValue(
                          `schedules.${index}.periodUnit`,
                          UnitOfTime.UNIT_OF_TIME_DAY
                        )
                      })
                      .exhaustive()
                  }}
                  value={SCHEDULE_OPTIONS.find((opt) => opt.value === value)}
                  isDisabled={readOnly}
                  styles={ErrorDropdownStyleConfig({ error: !!error })}
                />
              </>
            )
          }}
        />
        {periodChoice === 'Every' && (
          <RoutineEveryBlankPeriod index={index} readOnly={readOnly} />
        )}
        {periodChoice === 'OnThisDay' && (
          <RoutineOnThisDay index={index} readOnly={readOnly} />
        )}
      </div>
      {periodChoice === 'DaysOfWeek' && (
        <RoutineDaysOfWeek index={index} readOnly={readOnly} />
      )}
      {periodChoice === 'DaysOfMonth' && (
        <RoutineDaysOfMonth index={index} readOnly={readOnly} />
      )}
      <RoutineTimesOfDay index={index} readOnly={readOnly} />
    </>
  )
}
