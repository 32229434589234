import { useContext, useEffect, useState } from 'react'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import Icon from '@shared/components/Icon'
import SearchBox from '@shared/components/SearchBox'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { UserAccount } from '@shared/types/user'
import { tw } from '@shared/utils/tailwind'
import { isDirector as getIsDirector } from '@shared/utils/user'
import { getOrganizationUsers } from '@app/api/organizations'
import { fetchAllFacilityUsers } from '@app/api/users'
import Content from '@app/components/generic/Content'
import HUD from '@app/components/HUD'
import OrgTitleTabs from '@app/pages/Organizations/Settings/OrgTitleTabs'
import SectionTitle from '@app/pages/Organizations/Settings/SectionTitle'
import { Props } from '@app/pages/Organizations/Settings/type'
import UserFormPopup, {
  UserPopupData,
} from '@app/pages/Organizations/Settings/UsersPage/UserFormPopup'
import UserTable from '@app/pages/Organizations/Settings/UsersPage/UserTable'
import VersionInfo from '@app/pages/Organizations/Settings/VersionInfo'

export default function UsersPage(props: Props) {
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const { match } = props
  const { orgId } = match.params
  const isDirector = getIsDirector({ user, orgId })
  const [notification, setNotification] = useState<string>()
  const [userPopupData, setUserPopupData] = useState<
    UserPopupData | undefined
  >()
  const [users, setUsers] = useState<UserAccount[] | undefined>()
  const [filterString, setFilterString] = useState('')
  const reloadUsers = () => {
    let func: Promise<UserAccount[]>
    if (isDirector) {
      func = fetchAllFacilityUsers({ loginUser: user, orgId })
    } else {
      func = getOrganizationUsers(orgId)
    }

    return func.then(setUsers).catch(setError)
  }

  useEffect(() => {
    document.title = 'Users - Org Settings'
  }, [])

  useEffect(() => {
    if (orgId) {
      void reloadUsers()
    }
  }, [orgId])

  return (
    <Content>
      <OrgTitleTabs activeTab="Users" orgId={orgId} />
      <SectionTitle title="Users" className={tw`mb-[8px] ml-[16px] mr-[12px]`}>
        <div className={tw`flex`}>
          <div>
            <SearchBox
              onChange={setFilterString}
              value={filterString}
              placeholder="Search..."
            />
          </div>
          <div className={tw`ml-[8px]`}>
            <Button
              id="addUserButton"
              buttonStyle="primary-fill"
              onClick={() =>
                setUserPopupData({
                  user: null,
                  users: users ?? [],
                  disabled: false,
                })
              }
            >
              <Icon name="user-plus" className={tw`mr-[8px]`} />
              Add New
            </Button>
          </div>
        </div>
      </SectionTitle>
      <UserTable
        orgId={orgId}
        reloadUsers={reloadUsers}
        setUserPopupData={setUserPopupData}
        users={users}
        filterString={filterString}
        setFilterString={setFilterString}
      />
      <VersionInfo />
      {userPopupData && (
        <UserFormPopup
          users={userPopupData.users}
          disabled={userPopupData.disabled}
          onClose={async (didUpdate: boolean) => {
            if (didUpdate) {
              await reloadUsers()

              if (userPopupData.user?.id) {
                setNotification('User has been updated.')
              } else {
                setNotification('User added successfully.')
              }
            }

            setUserPopupData(undefined)
          }}
          orgId={orgId}
          user={userPopupData.user}
          setUserPopupData={setUserPopupData}
        />
      )}
      {notification && (
        <HUD
          onExpire={() => setNotification(undefined)}
          className="animate-fade-in"
        >
          {notification}
        </HUD>
      )}
    </Content>
  )
}
