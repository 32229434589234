import { OptionTypeBase } from '@shared/components/StyledSelect'

export enum TaskSortOrder {
  TASK_SORT_BY_NAME_ASC = 'Sort by task name',
  TASK_SORT_BY_NAME_DESC = 'Sort by task name (reverse)',
  TASK_SORT_BY_LAST_UPDATE_ASC = 'Sort by last updated',
  TASK_SORT_BY_LAST_UPDATE_DESC = 'Sort by last updated (reverse)',
}

export enum SnapshotSortOrder {
  SNAPSHOT_SORT_BY_NAME_ASC = 'Sort by document name',
  SNAPSHOT_SORT_BY_NAME_DESC = 'Sort by document name (reverse)',
  SNAPSHOT_SORT_BY_LAST_UPDATE_ASC = 'Sort by last updated',
  SNAPSHOT_SORT_BY_LAST_UPDATE_DESC = 'Sort by last updated (reverse)',
}

export type Option = OptionTypeBase<TaskSortOrder | SnapshotSortOrder>

export const TASK_SORT_OPTIONS: Option[] = Object.values(TaskSortOrder).map(
  (value) => ({
    label: value,
    value: value,
  })
)

export const SNAPSHOT_SORT_OPTIONS: Option[] = Object.values(
  SnapshotSortOrder
).map((value) => ({
  label: value,
  value: value,
}))
