import { faker } from '@faker-js/faker'
import { Factory } from 'fishery'
import { HumanName, HumanName_NameUse } from '@shared/types/human_name'

class HumanNameFactory extends Factory<HumanName> {
  alpha() {
    return this.params({
      family: 'Alpha',
      given: ['Albert'],
    })
  }

  beta() {
    return this.params({
      family: 'Beta',
      given: ['Betty'],
    })
  }
}
export const humanNameFactory = HumanNameFactory.define(
  (): HumanName => ({
    family: faker.person.lastName(),
    given: [faker.person.firstName()],
    prefix: faker.person.prefix(),
    suffix: faker.person.suffix(),
    use: faker.helpers.enumValue(HumanName_NameUse),
  })
)
