import { ForwardedRef, forwardRef } from 'react'
import { BadgeInput } from '@shared/components/BadgeInputs/BadgeInput'
import { BasicInputProps } from '@shared/components/BasicInput/BasicInput'
import TextInputWithIcon from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import { tw, twx } from '@shared/utils/tailwind'

export type Props = BasicInputProps & {
  ref?: ForwardedRef<HTMLInputElement>
  type?: string
  name?: string
  className?: string
  containerClassName?: string
  width?: string
  showErrorBorder?: boolean
}
export const CalendarInput = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    const {
      containerClassName,
      className,
      showErrorBorder = false,
      width = '184px',
      name,
      id,
      ...rest
    } = props

    const inputClasses = twx(
      'pl-inputIconLeftPadding',
      {
        ['border-form-input-error-border focus-within:border-form-input-error-border focus-within:outline-double focus-within:outline-form-input-error-border']:
          showErrorBorder,
      },
      className
    )

    return (
      <TextInputWithIcon
        holderClassName={containerClassName}
        containerProps={{
          style: {
            position: 'relative',
            width: width,
          },
        }}
        inputProps={{
          className: inputClasses,
          ref: ref,
          ...rest,
          name: name,
          id: id ?? name,
          placeholder: 'MM/DD/YYYY',
        }}
        iconClassName="fas fa-calendar-day"
      />
    )
  }
)
CalendarInput.displayName = 'CalendarInput'

export const BadgedCalendarInput = forwardRef<
  HTMLInputElement,
  { badgeLabel: string }
>((props, ref) => {
  return (
    <BadgeInput
      ref={ref}
      {...props}
      placeholder={'MM/DD/YYYY'}
      className={tw`relative min-w-[240px]`}
    />
  )
})
BadgedCalendarInput.displayName = 'BadgedCalendarInput'
