import { ResidentOrders } from '@augusthealth/models/com/august/protos/api/pharmacy_integration'
import { useEffect, useState } from 'react'
import { FacilityIds } from '@shared/types/facility'
import { Loading } from '@shared/utils/loading'
import { getMedicationOrderInbox } from '../api/medicationOrders'

export const usePendingMedicationsInbox = (facility: FacilityIds) => {
  const [pendingOrders, setPendingOrders] = useState<Loading<ResidentOrders[]>>(
    { tag: 'Loading' }
  )

  const { orgId, id: facilityId } = facility
  const refreshOrders = async () => {
    if (orgId && facilityId) {
      try {
        setPendingOrders({ tag: 'Loading' })
        const orders = await getMedicationOrderInbox({
          orgId,
          id: facilityId,
        })
        setPendingOrders({ tag: 'Complete', value: orders ?? [] })
      } catch (e) {
        setPendingOrders({ tag: 'Complete', value: [] })
      }
    }
  }

  useEffect(() => {
    void refreshOrders()
  }, [orgId, facilityId])

  return {
    pendingOrders,
    refreshOrders,
  }
}
