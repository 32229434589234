import { faker } from '@faker-js/faker'
import { Factory } from 'fishery'
import { Contact, Contact_ContactRelationship } from '@shared/types/contact'
import {
  contactPointFactory,
  emailContactPointFactory,
} from './contactPointFactory'
import { humanNameFactory } from './humanNameFactory'

export const contactFactory = Factory.define<Contact>(
  (): Contact => ({
    id: faker.string.uuid(),
    name: humanNameFactory.build(),
    telecom: [contactPointFactory.build()],
  })
)

export const rpContactFactory = contactFactory.params({
  relationship: [
    Contact_ContactRelationship.CONTACT_RELATIONSHIP_RESPONSIBLE_PERSON,
  ],
  telecom: [emailContactPointFactory.build()],
})
