import { AllergyIntolerance_AllergyIntoleranceCategory } from '@augusthealth/models/com/august/protos/allergy_intolerance'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import EditDiet from 'app/components/Diet/EditDiet'
import { EditableDetailsCard } from 'app/components/Prospects/Details/DetailsCard'
import { useState } from 'react'
import { Person } from '@shared/types/person'
import { allergyList } from '@shared/utils/person'
import styles from 'app/components/Diet/styles.module.css'

export default function Diet({
  person,
  updatePerson,
}: {
  person: Person
  updatePerson: () => Promise<void>
}) {
  const [showDietModal, setShowDietModal] = useState(false)
  const specialDiets = person.socialHistory?.diet?.dietTypeValue || []
  const foodAllergies = allergyList(person).filter(
    (allergy) =>
      allergy.category ===
      AllergyIntolerance_AllergyIntoleranceCategory.ALLERGY_INTOLERANCE_CATEGORY_FOOD
  )
  const additionalNotes = person.socialHistory?.diet?.summary

  return (
    <EditableDetailsCard
      id="diet-card"
      icon="fas fa-fw fa-wheat"
      title="Diet"
      editPermissions={{
        person,
        permissions: [GroupPermission.GROUP_PERMISSION_PERSON_UPDATE],
      }}
      onEditClick={() => setShowDietModal(true)}
      editButtonId={'edit-diet'}
    >
      <div className={styles.row}>
        <div className={styles.title}>Diet</div>
        <div>
          {specialDiets.length > 0 && specialDiets.join(', ')}
          {specialDiets.length === 0 && (
            <span className="text-gray-08">None to show</span>
          )}
        </div>
      </div>
      <div className={`${styles.row} mt-[4px]`}>
        <div className={styles.title}>Food Allergies</div>
        <div>
          {foodAllergies.length > 0 &&
            foodAllergies.map((allergy, index) => (
              <span key={index}>
                {allergy.summary}
                {index < foodAllergies.length - 1 && foodAllergies.length > 1
                  ? ', '
                  : ''}
              </span>
            ))}
          {foodAllergies.length === 0 && (
            <span className="text-gray-08">None to show</span>
          )}
        </div>
      </div>
      <div className={`${styles.row} mt-[4px]`}>
        <div className={styles.title}>Additional Notes</div>
        <div>
          {additionalNotes ? (
            additionalNotes
          ) : (
            <span className="text-gray-08">None to show</span>
          )}
        </div>
      </div>
      {showDietModal && (
        <EditDiet
          person={person}
          onClose={async (updated) => {
            if (updated) {
              await updatePerson()
            }
            setShowDietModal(false)
          }}
        />
      )}
    </EditableDetailsCard>
  )
}
