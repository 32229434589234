import { AddEditDetailModal } from 'app/pages/Tools/AssessmentConfiguration/DetailGroups/AddEditDetailModal/AddEditDetailModal'
import { useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import {
  AssessmentCategory,
  DetailModalMode,
} from '@shared/types/assessment_configuration'
import ConfirmModal from '@app/components/ConfirmModal'
import { FrontendDetail } from '@app/pages/Tasks/ResidentAssessment/AssessmentPage/CheckboxSections/utils'
import styles from '../styles.module.css'
import { REMOVED } from '../helpers'
import EditAugustField from './EditAugustField'
import { EditCheckbox, Props as EditCheckboxProps } from './EditCheckbox'
import { EditDropdown, Props as EditDropdownProps } from './EditDropdown'
import { EditTextarea, Props as EditTextareaProps } from './EditTextarea'
import { EditTextbox, Props as EditTextboxProps } from './EditTextbox'

type GroupCardProps = {
  selectedCategory: AssessmentCategory
  detailGroupKey: string
  value: FrontendDetail[]
  updatedDetails: Map<string, FrontendDetail[]>
  updateDetail: (eventDetails: {
    value: string | number
    index: number
    key: string
    optIndex?: number
    toUpdate: 'description' | 'score' | 'meta-description'
  }) => void

  editDetailFromModal: ({
    updatedDetail,
    index,
    optIndex,
  }: {
    updatedDetail: Partial<FrontendDetail>
    index: number
    optIndex?: number
  }) => void
  addDetail: ({
    newDetail,
    key,
  }: {
    newDetail: Partial<FrontendDetail>
    key: string
  }) => void
  removeDetail: ({
    index,
    optIndex,
    key,
  }: {
    index: number
    optIndex?: number
    key: string
  }) => void
  removeDetailGroup: ({ key }: { key: string }) => void
  categoryIsMarkedForDelete: boolean
}
export const DetailGroupCard = ({
  selectedCategory,
  detailGroupKey,
  value,
  updatedDetails,
  updateDetail,
  editDetailFromModal,
  addDetail,
  removeDetail,
  removeDetailGroup,
  categoryIsMarkedForDelete,
}: GroupCardProps) => {
  const [showAddEditModal, setShowAddEditModal] = useState<
    DetailModalMode | undefined
  >()
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)

  const confirmDeleteGroup = () => {
    removeDetailGroup({ key: detailGroupKey })
    setShowDeleteConfirm(false)
  }

  return (
    <>
      {showDeleteConfirm && (
        <ConfirmModal
          title={`Delete ${detailGroupKey} Detail Group?`}
          confirmButtonConfig={{
            onClick: confirmDeleteGroup,
          }}
          denyButtonConfig={{
            onClick: () => setShowDeleteConfirm(false),
          }}
        />
      )}
      <CollapsibleCard
        data-testid={`detail-group-card-${detailGroupKey}`}
        className={styles.card}
        key={`${selectedCategory?.categoryKey}-${detailGroupKey}-card`}
        cardTitle={detailGroupKey ?? ''}
        defaultExpanded={!categoryIsMarkedForDelete}
      >
        {showAddEditModal && (
          <AddEditDetailModal
            setShowModal={setShowAddEditModal}
            detailName={detailGroupKey}
            selectedCategory={selectedCategory}
            mode={showAddEditModal}
            currentDetails={updatedDetails}
          />
        )}
        {value.map((question, index) => {
          if (question.tempId === REMOVED) {
            return null
          }

          const componentProps = {
            question: question,
            updateDetail: updateDetail,
            index: index,
            detailGroupKey: detailGroupKey,
            setShowAddEditModal: setShowAddEditModal,
            editDetailFromModal: editDetailFromModal,
            removeDetail: removeDetail,
            disabled: categoryIsMarkedForDelete,
          }

          return (
            <div
              key={`${selectedCategory?.categoryKey}-${detailGroupKey}-question-${index}`}
              className={styles.detailInputs}
            >
              <div />
              <div className={styles.label}>Description</div>
              <ScoreHeader detail={question} />
              {question.tag === 'DetailWithAugustField' && (
                <EditAugustField
                  detail={question}
                  index={index}
                  removeDetail={removeDetail}
                  detailGroupKey={detailGroupKey}
                />
              )}
              {question.tag === 'DetailWithDropdown' && (
                <EditDropdown {...(componentProps as EditDropdownProps)} />
              )}
              {question.tag === 'DetailWithTextbox' && (
                <EditTextbox {...(componentProps as EditTextboxProps)} />
              )}
              {question.tag === 'DetailWithTextarea' && (
                <EditTextarea {...(componentProps as EditTextareaProps)} />
              )}
              {question.tag === 'DetailWithCheckbox' && (
                <EditCheckbox {...(componentProps as EditCheckboxProps)} />
              )}
            </div>
          )
        })}

        <div className={'mt-[16px] flex flex-row justify-between'}>
          <AsyncIconButton
            buttonStyle={'tertiary-fill'}
            buttonSize={'small'}
            initialIcon={'fa-solid fa-plus'}
            className="uppercase"
            onClick={() =>
              setShowAddEditModal({
                tag: 'add',
                addDetail,
              })
            }
            disabled={categoryIsMarkedForDelete}
          >
            Add {detailGroupKey}
          </AsyncIconButton>
          <AsyncIconButton
            buttonStyle={'danger-fill'}
            buttonSize={'small'}
            className="ml-[24px] uppercase"
            initialIcon={'fa-solid fa-ban'}
            onClick={() => {
              setShowDeleteConfirm(true)
            }}
            disabled={categoryIsMarkedForDelete}
          >
            Delete Group
          </AsyncIconButton>
        </div>
      </CollapsibleCard>
    </>
  )
}

function ScoreHeader({ detail }: { detail: FrontendDetail }) {
  // August Field details cannot have an associated score
  if (detail.tag === 'DetailWithAugustField') {
    return <div></div>
  }

  return <div className={styles.label}>Score</div>
}
