import { AppraisalSettings_CustomDetails } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import {
  getAddEditDetailModalDefaultValues,
  updateDetailFromAddEditFormValues,
} from 'app/pages/Tools/AssessmentConfiguration/DetailGroups/helpers'
import {
  ADDED,
  CUSTOM_DETAIL,
  CUSTOM_DROPDOWN_DETAIL,
  CUSTOM_TEXTAREA_DETAIL,
  CUSTOM_TEXTBOX_DETAIL,
} from 'app/pages/Tools/AssessmentConfiguration/helpers'
import { useEffect } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, { OptionTypeBase } from '@shared/components/StyledSelect'
import {
  AssessmentCategory,
  CustomDetailEnum,
  DetailModalMode,
} from '@shared/types/assessment_configuration'
import {
  categoryKeyToEnumMap,
  categoryKeyToPropertyMap,
} from '@shared/utils/assessmentConfiguration'
import {
  DetailWithDropdownOption,
  FrontendDetail,
} from '@app/pages/Tasks/ResidentAssessment/AssessmentPage/CheckboxSections/utils'
import { CheckboxDetail } from '@app/pages/Tools/AssessmentConfiguration/DetailGroups/AddEditDetailModal/CheckboxDetail'
import { DropdownDetail } from '@app/pages/Tools/AssessmentConfiguration/DetailGroups/AddEditDetailModal/DropdownDetail'
import {
  allRelevantOptions,
  excludedTypeNamePatterns,
  getRelevantCategoryOptions,
} from '@app/pages/Tools/AssessmentConfiguration/DetailGroups/AddEditDetailModal/helpers'
import { TextareaDetail } from '@app/pages/Tools/AssessmentConfiguration/DetailGroups/AddEditDetailModal/TextareaDetail'
import { TextInputDetail } from '@app/pages/Tools/AssessmentConfiguration/DetailGroups/AddEditDetailModal/TextInputDetail'
import { isSupportedAugustField } from '@app/pages/Tools/AssessmentConfiguration/DetailGroups/augustFieldHelpers'
import styles from 'app/pages/Tools/AssessmentConfiguration/styles.module.css'

export type DetailModalForm = {
  description: string
  score?: number
  enum?: CustomDetailEnum
  helpText?: string
  isRequired?: boolean
  answerGroup?: string
  multiSelect?: boolean
  options?: DetailWithDropdownOption[]
}

type Props = {
  setShowModal: (mode: DetailModalMode | undefined) => void
  currentDetails: Map<string, FrontendDetail[]>
  detailName: string
  selectedCategory: AssessmentCategory
  mode: DetailModalMode
}

export const AddEditDetailModal = ({
  setShowModal,
  detailName,
  selectedCategory,
  mode,
  currentDetails,
}: Props) => {
  const detailType = categoryKeyToEnumMap[selectedCategory.categoryKey] ?? {}
  const detailGroupProperty =
    categoryKeyToPropertyMap[selectedCategory.categoryKey]

  const defaultVals = getAddEditDetailModalDefaultValues({
    detailGroupProperty,
    mode,
  })

  const methods = useForm<DetailModalForm>({
    mode: 'onChange',
    defaultValues: defaultVals,
  })

  const {
    reset,
    handleSubmit,
    control,
    formState,
    getValues,
    setValue,
    watch,
  } = methods

  const categorySpecificOptions = getRelevantCategoryOptions(
    detailType,
    currentDetails,
    detailGroupProperty
  )

  const allOptions = [
    ...allRelevantOptions(defaultVals, mode),
    ...categorySpecificOptions,
  ]

  useEffect(() => {
    reset()
    setValue('description', defaultVals.description)
    setValue('enum', defaultVals.enum)
    setValue('score', defaultVals.score!)
    setValue('helpText', defaultVals.helpText)
    setValue('options', defaultVals.options ?? [])
  }, [])

  const onSubmit = () => {
    updateDetailFromAddEditFormValues({
      mode,
      getValues,
      detailGroupProperty,
      detailName,
    })

    setShowModal(undefined)
  }

  const isTextInput =
    mode.tag === 'add'
      ? watch('enum') === CUSTOM_TEXTBOX_DETAIL
      : mode.currentDetail.tag === 'DetailWithTextbox'
  const isTextarea =
    mode.tag === 'add'
      ? watch('enum') === CUSTOM_TEXTAREA_DETAIL
      : mode.currentDetail.tag === 'DetailWithTextarea'
  const isDropdown =
    mode.tag === 'add'
      ? watch('enum') === CUSTOM_DROPDOWN_DETAIL
      : mode.currentDetail.tag === 'DetailWithDropdown'
  const isCheckbox =
    mode.tag === 'add'
      ? watch('enum') === CUSTOM_DETAIL
      : mode.currentDetail.tag === 'DetailWithCheckbox'
  const isAugustFieldDetail =
    mode.tag === 'add'
      ? isSupportedAugustField(watch('enum'))
      : mode.currentDetail.tag === 'DetailWithAugustField'

  const options = watch('options') ?? []

  const isInvalidDropdown =
    isDropdown &&
    (!options.length ||
      !options.some((opt) => opt?.description?.trim()?.length ?? 0 === 0))

  return (
    <AnimatedPopup
      title={`${mode.tag} Detail for ${detailName}`}
      containerClassName={styles.detailModal}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <LabelAboveInput id={'label-enum'} htmlFor={'enum'}>
            Type/Enum
          </LabelAboveInput>
          <Controller
            control={control}
            name="enum"
            render={({ field: { onChange, value } }) => (
              <StyledSelect
                placeholder={'Select an enum...'}
                name={'enum'}
                id={'enum'}
                aria-labelledby={`label-enum`}
                options={allOptions}
                onChange={(opt: OptionTypeBase | null) => {
                  onChange(opt?.value || null)
                  if (
                    opt?.value === CUSTOM_DROPDOWN_DETAIL &&
                    options.length === 0
                  ) {
                    setValue('options', [
                      {
                        description: '',
                        score: 0,
                        answerGroup: getValues('answerGroup'),
                        enum: AppraisalSettings_CustomDetails.CUSTOM_DETAILS_DEFAULT,
                        group: detailName,
                        tempId: ADDED,
                      },
                    ])
                  } else {
                    setValue('options', undefined)
                  }
                }}
                value={allOptions.find((opt) => opt.value === value)}
                isDisabled={
                  mode.tag === 'edit' &&
                  defaultVals &&
                  defaultVals.enum !== undefined &&
                  excludedTypeNamePatterns.includes(defaultVals.enum)
                }
              />
            )}
          />
          {isCheckbox && <CheckboxDetail />}

          {isTextInput && <TextInputDetail />}

          {isTextarea && <TextareaDetail />}

          {isDropdown && (
            <DropdownDetail
              detailGroupProperty={detailGroupProperty}
              detailName={detailName}
              detailType={detailType}
              currentDetails={currentDetails}
            />
          )}

          {/* this will only be used if you are using an enum that is not assigned */}
          {!isDropdown &&
            !isTextInput &&
            !isTextarea &&
            !isCheckbox &&
            !isAugustFieldDetail && <CheckboxDetail />}
          <div className={styles.footer}>
            <AnimatedPopupFormFooter
              yesBtn={{
                label: 'Save',
                props: { disabled: isInvalidDropdown },
              }}
              noBtn={{
                action: () => setShowModal(undefined),
              }}
              formState={formState}
            />
          </div>
        </form>
      </FormProvider>
    </AnimatedPopup>
  )
}
