export enum FeatureFlagNames {
  CARE_APP = 'care_app',
  RESET_EMAR = 'reset_emar',
  MEDICATION_INBOX = 'medication_inbox',
  FAX_INBOX = 'fax_inbox',
  QUICK_BOOKS = 'quickbooks',
  DEMO = 'demo',
  MED_REFILLS = 'refills',
  MED_REQUIRED_DOCUMENTATION = 'medication_required_documentation',
  ASSESSMENT_ROUTINES_DEBUG = 'assessment_routines_debug',
}

export type FeatureFlag =
  | FeatureFlagNames.CARE_APP
  | FeatureFlagNames.RESET_EMAR
  | FeatureFlagNames.MEDICATION_INBOX
  | FeatureFlagNames.FAX_INBOX
  | FeatureFlagNames.QUICK_BOOKS
  | FeatureFlagNames.DEMO
  | FeatureFlagNames.MED_REFILLS
  | FeatureFlagNames.MED_REQUIRED_DOCUMENTATION
  | FeatureFlagNames.ASSESSMENT_ROUTINES_DEBUG
