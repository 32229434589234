import { Controller, useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import RoomCapacitySelect, { CAPACITY_OPTIONS } from '../RoomCapacitySelect'
import { PopupProps, SettingsData } from './types'

const DEFAULT_FORM_DATA = {
  numberOfRooms: 10,
  startRoomNumber: 1,
  defaultCapacity: 1,
}

export default function BulkRoomsPopup(props: PopupProps) {
  const { onClose, onSave } = props
  const {
    control,
    handleSubmit,
    register,
    formState,
    formState: { errors },
  } = useForm<SettingsData>({
    defaultValues: DEFAULT_FORM_DATA,
  })
  const onSubmit = async (formData: SettingsData) => {
    onSave(formData)
  }
  const hasNumberOfRoomsError = Boolean(errors.numberOfRooms)
  const numberOfRoomsErrorType = errors.numberOfRooms?.type
  let errorMessage = 'Required'
  if (numberOfRoomsErrorType === 'positive') {
    errorMessage = 'Need to be an positive integer'
  } else if (numberOfRoomsErrorType === 'lessThanHundred') {
    errorMessage = 'Need to be less or equal to 100'
  }

  return (
    <AnimatedPopup title="Add Rooms">
      <form>
        <div className="mt-[32px]">
          <LabelAboveInput
            htmlFor="numberOfRooms"
            subLabel={requiredLabel(hasNumberOfRoomsError)}
            errorMessage={errorMessage}
          >
            Number of rooms
          </LabelAboveInput>
          <BasicInput
            id="numberOfRooms"
            {...register('numberOfRooms', {
              required: true,
              valueAsNumber: true,
              validate: {
                positive: (value) => value > 0,
                lessThanHundred: (value) => value <= 100,
              },
            })}
            type="number"
            min="1"
            max="100"
          />
        </div>
        <div className="mt-[32px]">
          <LabelAboveInput
            htmlFor="startRoomNumber"
            subLabel={requiredLabel(Boolean(errors.startRoomNumber))}
          >
            Starting Room Number
          </LabelAboveInput>
          <BasicInput
            id="startRoomNumber"
            {...register('startRoomNumber', {
              required: true,
              valueAsNumber: true,
            })}
            type="number"
          />
        </div>
        <Controller
          control={control}
          name="defaultCapacity"
          render={({ field: { onChange, value } }) => (
            <div className="mt-[32px]">
              <LabelAboveInput htmlFor="defaultCapacity">
                Room Type
              </LabelAboveInput>
              <RoomCapacitySelect
                id="defaultCapacity"
                instanceId="defaultCapacity"
                name="defaultCapacity"
                onChange={(opt: OptionTypeBase) => onChange(opt?.value)}
                value={CAPACITY_OPTIONS.find((opt) => opt.value === value)}
              />
            </div>
          )}
        />
        <div className="mt-[32px] space-x-[12px] whitespace-nowrap">
          <AnimatedPopupFormFooter
            formState={formState}
            noBtn={{
              label: 'Cancel',
              action: onClose,
              props: {
                ['data-cy']: 'cancelAddRoomsButton',
              },
            }}
            yesBtn={{
              label: 'Continue',
              props: {
                onClick: handleSubmit(onSubmit),
                ['data-cy']: 'previewAddRoomsButton',
              },
            }}
          />
        </div>
      </form>
    </AnimatedPopup>
  )
}
