import React from 'react'
import { useHistory } from 'react-router-dom'
import { useUserContext } from '@shared/contexts/UserContext'
import { PersonStats } from '@shared/types/person_stats'
import { formatDate } from '@shared/utils/date'
import { getFirstAndLastName } from '@shared/utils/humanName'
import {
  getCareGroup,
  getResidentMoveInDate,
  getRoomNumber,
  isOutOfFacility,
} from '@shared/utils/person'
import { tw } from '@shared/utils/tailwind'
import {
  NameTd,
  TdWithExtraPadding,
  TrWithHoverEffect,
} from '@app/components/Table'
import ProfileImage from '@app/components/Table/ProfileImage'
import { getResidentPath } from '@app/routing/helpers'
import { formattedLevelOfCare, lastUpdatedBy, lastUpdateTime } from '../helpers'
import AlertTableRow from './AlertTableRow'

export default function ResidentTableBody({
  residents,
}: {
  residents: PersonStats[]
}) {
  const history = useHistory()
  const { user } = useUserContext()

  return (
    <tbody>
      {residents.map((resident, index) => {
        const person = resident.person
        const moveInDate = getResidentMoveInDate(person)
        const incidentsWithAlerts = resident.alerts ?? []
        const trKey = `tr-resident-${resident.person!.id}`

        const handleClickEvent = (event: { metaKey: any; ctrlKey: any }) => {
          const path = getResidentPath({
            user,
            person,
          })
          if (event.metaKey || event.ctrlKey) {
            window.open(path, '_blank')
          } else {
            history.push(path)
          }
        }

        return (
          <React.Fragment key={trKey}>
            <TrWithHoverEffect
              id={`resident-${index}`}
              onClick={handleClickEvent}
              noBottomLine={incidentsWithAlerts.length > 0}
            >
              <NameTd className={tw`text-gray-01`}>
                <div className={tw`flex items-center`}>
                  <ProfileImage
                    person={person}
                    profilePhotoUrl={resident.profilePhotoUrl}
                  />
                  {getFirstAndLastName(person.name)}
                  {isOutOfFacility(person) && (
                    <span
                      data-testid="away-tag"
                      className={tw`text-tags-alert`}
                    >
                      &nbsp;(away)
                    </span>
                  )}
                </div>
              </NameTd>
              <TdWithExtraPadding className={tw`text-gray-01`}>
                {moveInDate ? formatDate(moveInDate, { month: 'short' }) : ''}
              </TdWithExtraPadding>
              <TdWithExtraPadding className={tw`text-gray-07`}>
                {getRoomNumber(person)}
              </TdWithExtraPadding>
              <TdWithExtraPadding className={tw`text-gray-07`}>
                {formattedLevelOfCare(person)}
              </TdWithExtraPadding>
              <TdWithExtraPadding className={tw`text-gray-07`}>
                {getCareGroup(person)}
              </TdWithExtraPadding>
              <LastUpdated resident={resident} />
            </TrWithHoverEffect>
            {incidentsWithAlerts.length > 0 && (
              <AlertTableRow
                key={`${trKey}-incidents-alert`}
                incidentsWithAlerts={incidentsWithAlerts}
                person={person}
              />
            )}
          </React.Fragment>
        )
      })}
    </tbody>
  )
}

export function LastUpdated(props: { resident: PersonStats }) {
  return (
    <TdWithExtraPadding
      className={tw`overflow-hidden text-ellipsis whitespace-nowrap text-gray-01`}
    >
      {props.resident.lastUpdate?.updatedAt && (
        <>
          {lastUpdateTime(props.resident.lastUpdate)}
          <span className="text-gray-07">&nbsp;by&nbsp;</span>
          {lastUpdatedBy(props.resident.lastUpdate)}
        </>
      )}
    </TdWithExtraPadding>
  )
}
