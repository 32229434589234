import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { ContactPoint } from '@augusthealth/models/com/august/protos/contact_point'
import { isEmpty } from 'lodash'
import React from 'react'
import Badge from '@shared/components/Badge'
import {
  formatStreetAddress,
  getCityStatePostalCode,
} from '@shared/utils/address'
import { getFullName } from '@shared/utils/humanName'
import {
  getMainRelationship,
  getMainRole,
  getRoleRelationshipList,
} from '@shared/utils/relationship'
import styles from './styles.module.css'

interface Props {
  children?: React.ReactNode
  contact: Contact
  gridIndex: number
  linkable?: boolean
  containerClassName?: string
  onClick?: () => void
}

export default function ContactCard({
  contact,
  children,
  gridIndex,
  containerClassName,
  linkable = true,
  onClick,
}: Props) {
  let extraRoleLabels, mainRole
  if (contact.relationship) {
    mainRole = getMainRole(contact.relationship)
    extraRoleLabels = getRoleRelationshipList(contact.relationship)
    if (contact.clinicalSpecialty) {
      extraRoleLabels.push(contact.clinicalSpecialty)
    }
  }

  // this sets a variable delay for cascading animation
  const style = {
    '--animation-order': gridIndex,
    cursor: linkable ? 'pointer' : 'default',
  } as React.CSSProperties
  const cardClasses = linkable ? styles.contactCard : styles.disabledCard

  const ParentElement = linkable ? 'a' : 'div'

  return (
    <ParentElement
      key={`${getFullName(contact.name!)}${contact.id}`}
      data-testid={'contact-card-content'}
      style={style}
      className={`${styles.link} ${styles.slideLeft}`}
      onClick={onClick}
    >
      <div className={containerClassName ?? 'h-full w-full'}>
        <label className="visually-hidden">Edit Contact</label>
        <div className={cardClasses} tabIndex={0}>
          <ContactDetails contact={contact} />
          <section className={`${styles.hideOnMobile}`}>
            {extraRoleLabels &&
              extraRoleLabels.reduce((labels, role) => {
                labels.push(
                  <Badge
                    key={role + contact.id}
                    color={role !== mainRole ? 'gray' : 'purple'}
                    className={`${styles.primaryLabel} mr-[8px] rounded-[9px] px-[8px] py-[4px] text-[10px] font-semibold uppercase leading-[10px] tracking-[0.02em]`}
                  >
                    {role}
                  </Badge>
                )
                return labels
              }, [])}
            {children && (
              <div
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                {children}
              </div>
            )}
          </section>
        </div>
      </div>
    </ParentElement>
  )
}

interface Phone {
  value: string
  type: string
}

interface DisplayContactPoint {
  phones: Phone[]
  email: string
  fax: string
}

export const getDisplayContactPoints = (
  telecom: ContactPoint[]
): DisplayContactPoint => {
  return telecom.reduce(
    (contactMethods: DisplayContactPoint, item) => {
      if (!item.value) {
        return contactMethods
      }

      if (item.system === 'CONTACT_POINT_SYSTEM_PHONE') {
        const phone = {
          value: item.value,
          type: 'phone',
        }
        if (item.use === 'CONTACT_POINT_USE_MOBILE') {
          phone.type = 'mobile-alt'
        }

        contactMethods.phones.push(phone)
      } else if (item.system === 'CONTACT_POINT_SYSTEM_EMAIL') {
        contactMethods.email = item.value
      } else if (item.system === 'CONTACT_POINT_SYSTEM_FAX') {
        contactMethods.fax = item.value
      }
      return contactMethods
    },
    { phones: [], email: '', fax: '' }
  )
}

export const ContactDetails = ({ contact }: { contact: Contact }) => {
  const displayContactPoints = contact.telecom
    ? getDisplayContactPoints(contact.telecom)
    : undefined

  const mainRelationship = getMainRelationship(contact.relationship!)
  const displayName = getFullName(contact.name!)

  return (
    <section>
      <h4 className={styles.role}>{mainRelationship}</h4>
      <h3 className={styles.name}>{displayName}</h3>
      <div>
        {displayContactPoints?.phones?.map((phone, index) =>
          telecomRow({
            icon: `fa fa-fw fa-${phone.type}`,
            title: 'Phone Number',
            value: phone.value,
            index,
            length: displayContactPoints.phones.length,
          })
        )}
        {displayContactPoints?.email &&
          telecomRow({
            icon: 'fa fa-fw fa-envelope',
            title: 'Email',
            value: displayContactPoints.email,
          })}
        {displayContactPoints?.fax &&
          telecomRow({
            icon: 'fas fa-fw fa-fax',
            title: 'Fax Number',
            value: displayContactPoints.fax,
          })}
        {!isEmpty(contact.address) &&
          telecomRow({
            icon: 'fa fa-fw fa-map-marker-alt',
            title: 'Address',
            value: (
              <div>
                {formatStreetAddress(contact.address)}
                <br />
                {getCityStatePostalCode(contact.address)}
              </div>
            ),
          })}
        {contact.organization?.name &&
          telecomRow({
            icon: 'fas fa-fw fa-building',
            title: 'Organization',
            value: contact.organization.name,
          })}
      </div>
    </section>
  )
}

interface TelecomRow {
  icon: string
  title: string
  value: string | JSX.Element
  index?: number
  length?: number
}

const telecomRow = ({
  icon,
  title,
  value,
  index = 1,
  length = 0,
}: TelecomRow): React.ReactNode => (
  <div key={title + index} className={styles.contactInfo}>
    <span className="visually-hidden">{title}</span>
    <i aria-hidden={true} className={`${icon} ${styles.icon}`} />
    <span className={styles.infoText}>
      {value}
      {index === 0 && length > 1 && (
        <Badge color="darkGray" className={`${styles.primaryLabel} ml-[8px]`}>
          Primary
        </Badge>
      )}
    </span>
  </div>
)
