import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { patchUser } from '@shared/api/user'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import { BasicCheckbox } from '@shared/components/BasicInput/BasicInput'
import { FeatureFlagNames } from '@shared/constants/feature_flags'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { UserAccount } from '@shared/types/user'
import { tw } from '@shared/utils/tailwind'
import Content from '@app/components/generic/Content'
import HUD from '@app/components/HUD'
import PersonPageTitle from '@app/components/PersonPageTitle'
import SubPageTabs, { MY_FEATURE_FLAGS_LABEL } from './SubPageTabs'

const FLAG_ENTRIES = Object.entries(FeatureFlagNames)

export default function MyFeatureFlags() {
  const { setError } = useContext(GlobalContext)
  const { user, setUser } = useUserContext()
  const [showHUD, setShowHUD] = useState(false)
  const { formState, handleSubmit, register } = useForm<UserAccount>({
    defaultValues: user,
  })
  const { isDirty, isSubmitting } = formState
  const onSubmit = async (user: UserAccount) => {
    const { id, featureFlags } = user
    try {
      const updatedUser = await patchUser({ user: { id, featureFlags } })
      setUser(updatedUser)
      setShowHUD(true)
    } catch (err) {
      setError(err)
    }
  }

  return (
    <Content>
      <SubPageTabs activeTab={MY_FEATURE_FLAGS_LABEL} />
      <PersonPageTitle
        className={tw`mt-[32px]`}
        title={MY_FEATURE_FLAGS_LABEL}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        {FLAG_ENTRIES.map(([key, val]) => {
          return (
            <BasicCheckbox
              key={`flag-checkbox-${key}`}
              {...register('featureFlags')}
              value={val}
            >
              {key}
            </BasicCheckbox>
          )
        })}
        <Button
          isLoading={isSubmitting}
          disabled={isSubmitting || !isDirty}
          buttonStyle="primary-fill"
          className={tw`mt-[8px]`}
          type="submit"
        >
          Save
        </Button>
      </form>
      {showHUD && <HUD onExpire={() => setShowHUD(false)}>User updated!</HUD>}
    </Content>
  )
}
