import { Popup } from '@augusthealth/august-frontend-form-elements'
import {
  SignatureInfo,
  SignatureStatus,
  Task,
} from '@augusthealth/models/com/august/protos/task'
import { isEmpty } from 'lodash'
import { useContext } from 'react'
import { fetchTasks } from '@shared/api/task'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import StyledSelect from '@shared/components/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { getTaskUrl } from '@shared/legacy_routes'
import { SignerRole } from '@shared/types/snapshot'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { tw } from '@shared/utils/tailwind'
import { nextSigner } from '@shared/utils/task'
import PersonContext from '@app/contexts/PersonContext'

type Props = {
  facilityId: string
  taskToSign: SignInPersonData
  setTaskToSign: (t: SignInPersonData | undefined) => void
}

export interface SignInPersonData {
  task: Task
  onComplete: () => void
  email?: string
}

export default function SignInPersonPopup({
  facilityId,
  taskToSign,
  setTaskToSign,
}: Props) {
  const { setError } = useContext(GlobalContext)
  const { setTasks } = useContext(PersonContext)
  const { orgId, personId: pId, id: taskId } = taskToSign.task || {}

  if (!taskId) {
    return null
  }

  const onContinue = () => {
    const signInPersonUrl = getTaskUrl({
      facilityId,
      id: taskId,
      orgId: orgId!,
      personId: pId!,
      page: 'signInPerson',
      email: taskToSign.email,
    })
    const signInPersonWindow = window.open(
      signInPersonUrl,
      'signInPersonWindow'
    )
    signInPersonWindow?.addEventListener('beforeunload', () => {
      fetchTasks({ pId: pId || '', orgId: orgId || '' })
        .then((res) => setTasks(res && res.data))
        .catch(setError)
      taskToSign.onComplete()
    })
    setTaskToSign(undefined)
  }

  const getSignerRoleName = () => {
    switch (nextSigner(taskToSign.task)?.role) {
      case SignerRole.SIGNER_ROLE_RESPONSIBLE_PARTY:
        return 'Responsible Person'
      case SignerRole.SIGNER_ROLE_RESIDENT:
        return 'Resident'
      default:
        return 'Next Person'
    }
  }

  const isNextSignerExternal =
    nextSigner(taskToSign.task)?.role === SignerRole.SIGNER_ROLE_EXTERNAL

  const externalSigners = taskToSign.task.signatures?.filter(
    (s) =>
      !!s?.signer?.role && s.signer.role === SignerRole.SIGNER_ROLE_EXTERNAL
  )

  function Wrapper({ children }: { children: React.ReactNode }) {
    return (
      <Popup
        holderClassName="center-modal confirm-modal"
        modalClassName="grass small-modal-dialog"
        contentClassName="no-separator"
        defaultOpen
      >
        {[
          <div key="sign-in-person-popup-header">
            <img
              className={tw`inline-block`}
              alt=""
              src="/svg/sign-in-person.svg"
            />
          </div>,
          <div key="sign-in-person-popup-body">
            <div
              className={tw`mb-[8px] text-[18px] font-semibold leading-[24-px] text-secondary-04`}
            >
              Sign in person now?
            </div>
            {children}
          </div>,
          <div key="sign-in-person-popup-footer">
            <Button
              buttonStyle="primary-fill"
              className={tw`mb-[8px] w-full`}
              onClick={onContinue}
            >
              Sign now
            </Button>
            <Button
              buttonStyle="secondary-outline"
              className={tw`w-full`}
              onClick={() => setTaskToSign(undefined)}
            >
              Not now
            </Button>
          </div>,
        ]}
      </Popup>
    )
  }

  if (
    isEmpty(externalSigners) ||
    externalSigners?.length === 1 ||
    !isNextSignerExternal
  ) {
    return (
      <Wrapper>
        <div data-testid="non-external-signer-copy">
          This will open the document in a new window for the{' '}
          {getSignerRoleName()} to sign now.
        </div>
      </Wrapper>
    )
  }

  function SignerSelector({
    taskToSign,
    setTaskToSign,
    externalSigners,
  }: {
    taskToSign: SignInPersonData
    setTaskToSign: (t: SignInPersonData | undefined) => void
    externalSigners: SignatureInfo[]
  }) {
    const options = externalSigners.map((s) => {
      const isDisabled = s.status === SignatureStatus.SIGNATURE_STATUS_SIGNED
      const label = isDisabled
        ? `${getFirstAndLastName(s.signer!.name)} (Signed)`
        : getFirstAndLastName(s.signer!.name)

      return { label, value: s.signer!.email, isDisabled }
    })

    return (
      <StyledSelect
        value={
          externalSigners.find((s) => s.signer!.email === taskToSign.email)
            ? {
                label: getFirstAndLastName(
                  externalSigners.find(
                    (s) => s.signer!.email === taskToSign.email
                  )!.signer!.name
                ),
                value: taskToSign.email,
              }
            : undefined
        }
        name="email"
        aria-label="Select a signer"
        id="signer-selector"
        options={options}
        placeholder="Select a person..."
        onChange={({ value: email }) => {
          return setTaskToSign({ ...taskToSign, email })
        }}
      />
    )
  }

  return (
    <Wrapper>
      <div>
        Please select the person who will be signing the document below.
        <div className={tw`mt-6`}>
          <SignerSelector
            taskToSign={taskToSign}
            setTaskToSign={setTaskToSign}
            externalSigners={externalSigners!}
          />
        </div>
      </div>
    </Wrapper>
  )
}
