import {
  BillingCharge,
  BillingInvoice,
  InvoiceStatus,
  ResidentListEntry,
} from '@shared/types/billing'
import { convertEnumValueToLabel } from '@shared/utils/common'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { getOrElse, Loading } from '@shared/utils/loading'
import { Order, sortNumber, sortStr } from '@shared/utils/sorting'

export enum TransactionsColumnName {
  SERVICE_DATE = 'Service date',
  DESCRIPTION = 'Description',
  TRANSACTION_TYPE = 'Type',
  AMOUNT = 'Amount',
  BALANCE = 'Balance',
}

export enum ResidentsColumnName {
  RESIDENT = 'Resident',
  UPDATES = 'Updates',
  CURRENT_BALANCE = 'Current Balance',
  STATEMENT_BALANCE = 'Statement Balance',
  STATEMENT_STATUS = 'Statement Status',
}

export enum InvoicesColumnName {
  NUMBER = 'Number',
  BALANCE_DUE = 'Balance Due',
  INVOICE = 'Invoice',
  DATE_DUE = 'Date Due',
  DESCRIPTION = 'Description',
  SERVICE_DATES = 'Service Dates',
  NAME = 'Name',
}

export enum ChargesColumnName {
  DESCRIPTION = 'Description',
  AMOUNT = 'Amount',
  START_DATE = 'Start Date',
  END_DATE = 'End Date',
}

export function getSearchedInvoices({
  rows,
  searchTerm,
}: {
  rows: Loading<BillingInvoice[]>
  searchTerm: string
}) {
  return getOrElse(rows, []).filter((invoice) => {
    const {
      id,
      invoiceData: { notes },
    } = invoice

    return (
      id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      notes?.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })
}

export function getSortedInvoices({
  rows,
  selectedColumn,
  sortingOrder,
}: {
  rows: BillingInvoice[]
  selectedColumn: InvoicesColumnName
  sortingOrder: Order
}) {
  if (selectedColumn === InvoicesColumnName.NUMBER) {
    return rows.sort((a, b) => {
      const aNumber = a.id
      const bNumber = b.id
      if (sortingOrder === Order.ASC) {
        return aNumber.localeCompare(bNumber)
      }
      return bNumber.localeCompare(aNumber)
    })
  }

  if (selectedColumn === InvoicesColumnName.INVOICE) {
    return rows.sort((a, b) => {
      const aBalance = a.invoiceAmountCents
      const bBalance = b.invoiceAmountCents
      if (sortingOrder === Order.ASC) {
        return aBalance - bBalance
      }
      return bBalance - aBalance
    })
  }

  if (selectedColumn === InvoicesColumnName.DATE_DUE) {
    return rows.sort((a, b) => {
      const aDate = a.invoiceData.dueDate
      const bDate = b.invoiceData.dueDate
      if (!aDate && !bDate) return 0
      if (!aDate) return -sortingOrder
      if (!bDate) return sortingOrder

      if (sortingOrder === Order.ASC) {
        return aDate.localeCompare(bDate)
      }
      return bDate.localeCompare(aDate)
    })
  }

  if (selectedColumn === InvoicesColumnName.SERVICE_DATES) {
    return rows.sort((a, b) => {
      const startDateA = new Date(a.invoiceData.startDate)
      const startDateB = new Date(b.invoiceData.startDate)
      const endDateA = new Date(a.invoiceData.endDate)
      const endDateB = new Date(b.invoiceData.endDate)

      if (startDateA < startDateB) return -1
      if (startDateA > startDateB) return 1
      if (endDateA < endDateB) return -1
      if (endDateA > endDateB) return 1

      return 0
    })
  }

  if (selectedColumn === InvoicesColumnName.NAME) {
    return rows.sort((a, b) =>
      sortStr({
        strA: getFirstAndLastName(a.person.name),
        strB: getFirstAndLastName(b.person.name),
        sortingOrder,
      })
    )
  }

  return rows
}

export function getSearchedResidents({
  rows,
  searchTerm,
}: {
  rows: Loading<ResidentListEntry[]>
  searchTerm: string
}) {
  return getOrElse(rows, []).filter((resident) => {
    const { name } = resident
    const lowerName = getFirstAndLastName(name).toLowerCase()
    const searchTermLower = searchTerm.toLowerCase()
    return lowerName.includes(searchTermLower)
  })
}

export function getSortedResidents({
  rows,
  selectedColumn,
  sortingOrder,
}: {
  rows: ResidentListEntry[]
  selectedColumn: ResidentsColumnName
  sortingOrder: Order
}) {
  if (selectedColumn === ResidentsColumnName.RESIDENT) {
    return rows.sort((a, b) => {
      const aName = getFirstAndLastName(a.name)
      const bName = getFirstAndLastName(b.name)
      if (sortingOrder === Order.ASC) {
        return aName.localeCompare(bName)
      }
      return bName.localeCompare(aName)
    })
  }

  if (selectedColumn === ResidentsColumnName.CURRENT_BALANCE) {
    return rows.sort((a, b) => {
      const aBalance = a.totalBalanceCents
      const bBalance = b.totalBalanceCents
      if (sortingOrder === Order.ASC) {
        return aBalance - bBalance
      }
      return bBalance - aBalance
    })
  }

  if (selectedColumn === ResidentsColumnName.STATEMENT_BALANCE) {
    return rows.sort((a, b) => {
      const aBalance = a.statementBalanceCents
      const bBalance = b.statementBalanceCents
      if (sortingOrder === Order.ASC) {
        return aBalance - bBalance
      }
      return bBalance - aBalance
    })
  }

  if (selectedColumn === ResidentsColumnName.STATEMENT_STATUS) {
    return rows.sort((a, b) => {
      const aStatusOrder = a.lastInvoice?.data.status
        ? statusSort[a.lastInvoice?.data.status]
        : 0
      const bStatusOrder = b.lastInvoice?.data.status
        ? statusSort[b.lastInvoice?.data.status]
        : 0
      if (sortingOrder === Order.ASC) {
        return aStatusOrder - bStatusOrder
      }
      return bStatusOrder - aStatusOrder
    })
  }

  if (selectedColumn === ResidentsColumnName.UPDATES) {
    return rows.sort((a, b) => {
      return sortNumber({
        numA: a.pendingBillingEvents,
        numB: b.pendingBillingEvents,
        sortingOrder,
      })
    })
  }

  return rows
}

const statusSort: Record<InvoiceStatus, number> = {
  [InvoiceStatus.ERROR]: 1,
  [InvoiceStatus.NEEDS_WORK]: 2,
  [InvoiceStatus.PENDING]: 3,
  [InvoiceStatus.APPROVED]: 4,
  [InvoiceStatus.PAID]: 5,
  [InvoiceStatus.DUE]: 6,
}

export function getFrequencyLabelFromCharge(charge: BillingCharge) {
  const { frequency } = charge.item.data

  return convertEnumValueToLabel(frequency)
}
