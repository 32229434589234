import {
  get,
  Relation,
  TextInput,
} from '@augusthealth/august-frontend-form-elements'
import PropTypes from 'prop-types'
import './style.css'
import RelationshipCategoryDropdown from './RelationshipCategoryDropdown'
import RelationshipDropdown from './RelationshipDropdown'

function RelationshipDropdownGroup(props) {
  const {
    data,
    name,
    options = [],
    title,
    onUpdate,
    value,
    visibility,
    alertMessage,
    isAddNewPrimaryPhysicianPopup = false,
  } = props
  if (!visibility) {
    return false
  }

  const validationElement = alertMessage ? (
    <div className="validation-holder">{alertMessage}</div>
  ) : undefined

  const className =
    'form-control border-button-inner-border-color text-button-default-font-color hover:brightness-90 font-semibold text-[13px] min-w-[100px] min-h-[40px] rounded-[3px]'
  const triggerName = name
  const triggerProps = {
    name: triggerName,
    options,
    placeholder: 'Category',
    className,
    isRequired: true,
    isAddNewPrimaryPhysicianPopup,
  }
  const dropdownName = name
  const dropdownProps = {
    name: dropdownName,
    placeholder: 'Relationship',
    className,
    isRequired: true,
    isAddNewPrimaryPhysicianPopup,
  }
  const showClinicalSpecialty =
    (Array.isArray(value) &&
      value.includes('CONTACT_RELATIONSHIP_CLINICIAN')) ||
    false

  return (
    <div className="relationship-dropdown-group">
      <Relation
        data={data}
        className="horizontal"
        holderClassName="mb-0"
        title={title}
        trigger={RelationshipCategoryDropdown}
        triggerProps={triggerProps}
        onUpdate={onUpdate}
        relationType="visibility"
        projection={RelationshipDropdown}
        projectionProps={dropdownProps}
      />
      <TextInput
        name={name.replace('.relationship', '.clinicalSpecialty')}
        placeholder="e.g. clinical specialty or practice area, if applicable"
        onUpdate={onUpdate}
        value={get(data.contact, 'clinicalSpecialty')}
        visibility={showClinicalSpecialty}
      />
      {validationElement}
    </div>
  )
}

RelationshipDropdownGroup.propTypes = {
  data: {
    contact: PropTypes.any,
  },
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  onUpdate: PropTypes.func,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  visibility: PropTypes.bool,
  alertMessage: PropTypes.string,
  isAddNewPrimaryPhysicianPopup: PropTypes.bool,
}

export default RelationshipDropdownGroup
