import { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { EMAIL_REGX } from '@shared/utils/regex'
import { tw, twx } from '@shared/utils/tailwind'
import Button from './Button'
import { BtnState } from './LoginWithUsernameOrEmail'

export default function EmailLoginForm({
  state,
  handleLogin,
}: {
  state: BtnState
  handleLogin: (values: unknown) => Promise<void>
}) {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: { email: '' },
  })

  useEffect(() => {
    if (state === 'disengaged') {
      reset()
    }
  }, [state])

  const ctaText =
    state === 'disengaged' ? 'log in with email link' : 'send email link'
  const icon = state === 'disengaged' ? 'envelope' : undefined

  const inputRegistration =
    state === 'engaged'
      ? {
          required: true,

          pattern: {
            value: EMAIL_REGX,
            message: 'Please enter a valid email address',
          },
        }
      : { required: false }
  const emailRef: React.MutableRefObject<HTMLInputElement | null> =
    useRef<HTMLInputElement>(null)
  const { ref, ...rest } = register('email', {
    ...inputRegistration,
  })

  useEffect(() => {
    if (state === 'engaged') {
      emailRef.current?.focus()
    }
  })

  return (
    <form className={tw`mt-[40px]`} onSubmit={handleSubmit(handleLogin)}>
      <div
        className={twx('relative text-[14px]', {
          'h-0 w-0 overflow-hidden opacity-0':
            state === 'default' || state === 'disengaged',
          'mb-[16px] h-[48px] w-full opacity-100': state === 'engaged',
        })}
      >
        <input
          type="email"
          className={tw`h-[48px] w-[360px] rounded-[6px] border border-gray-10 pl-[40px] font-medium focus:outline focus:outline-form-input-border-hover`}
          placeholder="Enter your email address..."
          autoFocus
          ref={(e) => {
            ref(e)
            emailRef.current = e
          }}
          {...rest}
        />
        <i
          className={tw`fa fa-fw fa-envelope absolute left-[16px] top-[17px] text-primary-light`}
        />
      </div>

      <Button
        disabled={false}
        text={ctaText}
        icon={icon}
        state={state}
        className={tw`h-[48px] w-[360px] rounded-[8px] border-2 border-secondary-06 bg-background-login font-inter text-[14px] font-semibold uppercase leading-[16px] transition-all hover:brightness-90 disabled:opacity-50`}
      />
    </form>
  )
}
