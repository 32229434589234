import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { BillingCategory, BillingCategoryData } from '@shared/types/billing'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { isEmptyObject } from '@shared/utils/common'
import { hasFullOrgAccess } from '@shared/utils/user'
import useBillingCategories from '@app/hooks/useBillingCategories'
import BillingModal from '@app/pages/Facilities/Billing/Modals/components/BillingModal'
import CurrentOrgFacilitySelect from '../shared/CurrentOrgFacilitySelect'

type Props = {
  billingCategory: BillingCategory | null
  facility: Facility
  doneFn: (msg: string) => Promise<void>
  closeFn: () => void
  organization: Organization
}

export default function BillingCategoryModal(props: Props) {
  const { user } = useUserContext()
  const { setError } = useContext(GlobalContext)
  const { billingCategory, facility, organization, doneFn, closeFn } = props
  const { orgId, id: facilityId } = facility
  const isAddMode = isEmptyObject(billingCategory)
  const isOrgLevelCategory = Boolean(
    billingCategory?.data && !billingCategory.data.facilityId
  )
  const hasFullAccess = hasFullOrgAccess({ orgId, user })
  const readOnly = isOrgLevelCategory && !hasFullAccess
  const includeOrgOption = !isAddMode || hasFullAccess
  const { upsertBillingCategoryData } = useBillingCategories({
    orgId,
    facilityId,
  })
  const { handleSubmit, register, control, formState } =
    useForm<BillingCategoryData>({
      defaultValues: billingCategory?.data
        ? billingCategory.data
        : { facilityId },
    })
  const { errors } = formState

  async function onSubmit(billingCategoryData: BillingCategoryData) {
    try {
      await upsertBillingCategoryData({
        billingCategoryData,
        categoryId: billingCategory?.meta?.id ?? '',
      })
      await doneFn(isAddMode ? 'Category added!' : 'Caegory updated!')
      closeFn()
    } catch (err) {
      setError(err)
    }
  }

  const title = readOnly
    ? 'View Billing Category'
    : billingCategory?.meta?.id
      ? 'Edit Billing Category'
      : 'Add Billing Category'

  return (
    <BillingModal id="billing-category-modal" title={title} onClose={closeFn}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-5">
          <LabelAboveInput
            uppercase={false}
            htmlFor="name"
            subLabel={requiredLabel(Boolean(errors.name))}
          >
            Name
          </LabelAboveInput>
          <BasicInput
            {...register('name', { required: true })}
            disabled={readOnly}
          />
        </div>
        <div className="mt-4">
          <LabelAboveInput uppercase={false} htmlFor="facilityId">
            Community
          </LabelAboveInput>
          <Controller
            control={control}
            rules={{ required: false }}
            name="facilityId"
            render={({ field: { onChange, value } }) => {
              return (
                <CurrentOrgFacilitySelect
                  onChange={onChange}
                  facility={facility}
                  selectedFacilityId={value as string | undefined}
                  organization={organization}
                  isDisabled={!isAddMode || !hasFullAccess}
                  includeOrgOption={includeOrgOption}
                />
              )
            }}
          />
        </div>
        <div className="mt-8">
          <AnimatedPopupFormFooter
            yesBtn={{
              hideButton: readOnly,
              label: isAddMode ? 'Add' : 'Edit',
              props: { disabled: readOnly },
            }}
            noBtn={{
              action: closeFn,
              label: readOnly ? 'Close' : 'Cancel',
            }}
            formState={formState}
          />
        </div>
      </form>
    </BillingModal>
  )
}
