import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { LabelAboveInput } from '@shared/components/Labels'
import {
  OptionTypeBase,
  StyledMultiSelect,
} from '@shared/components/StyledSelect'
import useLocalStorage from '@shared/hooks/useLocalStorage'
import { UserAccount } from '@shared/types/user'
import { getOrElse } from '@shared/utils/loading'
import { setNewUserWithUpdatedPermissions } from './helpers'

export function SuppressPermissionsModal({
  user,
  onClose,
}: {
  user: UserAccount
  onClose: (newUser: UserAccount | undefined) => void
}) {
  const [storedSuppressedPermissions, setSuppressedPermissions] =
    useLocalStorage<GroupPermission[]>('suppressedPermissions', [])
  const suppressedPermissions = getOrElse(storedSuppressedPermissions, [])

  function toMenuEntries(permissions: GroupPermission[]): OptionTypeBase[] {
    return permissions.map((permission) => {
      return {
        label: `${permission.replace('GROUP_PERMISSION_', '')}`,
        value: permission,
      }
    })
  }

  const currentUserPermissions = user!.groups!.flatMap(
    (g) => g!.groupPermissions!
  )

  return (
    <AnimatedPopup
      title="Set Permissions"
      onClickBackground={() => onClose(undefined)}
    >
      <div className="mb-[32px]">
        <LabelAboveInput htmlFor="current">
          Suppressed Permissions
        </LabelAboveInput>

        <StyledMultiSelect
          defaultValue={toMenuEntries(suppressedPermissions)}
          isMulti
          name="suppressed"
          options={toMenuEntries(currentUserPermissions)}
          onChange={(opts: { value: GroupPermission }[]) => {
            void setSuppressedPermissions(opts.map((opt) => opt.value))
          }}
        />
      </div>
      <div>
        <AsyncIconButton
          buttonStyle="secondary-outline"
          onClick={() => onClose(undefined)}
          type="button"
        >
          Cancel
        </AsyncIconButton>
        <AsyncIconButton
          onClick={() => {
            const newUser = setNewUserWithUpdatedPermissions({
              suppressedPermissions,
              user,
            })
            return onClose(newUser)
          }}
          type="button"
          className="ml-[16px]"
          buttonStyle="primary-fill"
          buttonSize="medium"
        >
          Save
        </AsyncIconButton>
      </div>
    </AnimatedPopup>
  )
}
