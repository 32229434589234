import { isEmpty } from 'lodash'
import qs from 'qs'
import { v4 as uuidv4 } from 'uuid'
import { cacheETag, getETag } from '@shared/api/etags'
import { getMockAuthToken } from '@shared/api/mockAuth'
import { getSnapshotsUrl } from '@shared/api/urls'
import { logout, setLastUserActivity } from '@shared/components/Auth/Auth'
import environment from '@shared/environment'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import { UploadInfo } from '@shared/types/snapshot'
import { generateCloudwatchUrl } from '@shared/utils/error'
import fetchAuthSessionWithRetries from './auth'
import { ApiResponse } from './response'

export interface ApiParams extends Record<string, string | undefined> {}

interface RequestProps {
  url: string
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  body?: BodyInit
  contentType?: string
  additionalHeaders?: Record<string, string>
}

export async function fetchJson<TResponseData extends ApiResponse['data']>(
  url: string,
  options: Record<string, unknown> = {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const newOption = { ...options }
  if (!newOption.headers || !newOption.headers['Content-Type']) {
    const newHeaders = newOption.headers ? { ...newOption.headers } : {}
    newHeaders['Content-Type'] = 'application/json'
    newHeaders['X-August-Fetch'] = 'true'
    newOption.headers = newHeaders
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const res = await fetchAnything(url, newOption)
  const jsonResponse: ApiResponse<TResponseData> = Object.freeze(
    await res.json()
  ) as ApiResponse<TResponseData>

  // Internally we type `jsonResponse` as `ApiResponse<TResponseData>` but we cast
  // to a return value of `any` for backwards compatibility so we can update usages
  // slowly to start passing the `TResponseData` generic.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
  return new Promise((resolve) => resolve(jsonResponse as any))
}

if (typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
  ;(window as any).fetchJson = fetchJson
}

export async function fetchAnything(
  url: string,
  defaultOptions?: Record<string, unknown>
): Promise<Response> {
  const requestId = generateRequestIdWithBreadcrumb(url)
  const options = defaultOptions || {}
  const headers: Record<string, string> = {
    ...(await authHeader()),
    'X-August-Fetch': 'true',
    'X-Page-URL': location.href,
    'X-Request-ID': requestId,
    'X-Client-Version': environment.clientVersion,
    ...(options.headers || {}),
  } as Record<string, string>
  const fetchOptions = {
    ...options,
    headers,
  }

  const encodedUrl = encodeQueryParams(url)

  return fetch(encodedUrl, fetchOptions).then(handleResponse)
}

export async function requestAnything({
  url,
  method = 'GET',
  body,
  contentType,
  additionalHeaders,
}: RequestProps) {
  const requestId = generateRequestIdWithBreadcrumb(url)
  const headers = {
    'X-August-Fetch': 'true',
    'X-Request-ID': requestId,
    'X-Page-URL': location.href,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    'X-Client-Version': environment.clientVersion,
    ...(additionalHeaders || {}),
    ...(await authHeader()),
  }

  if (contentType) {
    headers['Content-Type'] = contentType
  }
  const response = await fetch(encodeQueryParams(url), {
    method,
    headers,
    body,
  })

  return handleResponse(response)
}

export async function requestString({
  url,
  method = 'GET',
  body,
  contentType,
}: RequestProps) {
  const requestId = generateRequestIdWithBreadcrumb(url)
  const headers = {
    'X-August-Fetch': 'true',
    'X-Request-ID': requestId,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    'X-Client-Version': environment.clientVersion,
    ...(await authHeader()),
  }

  if (contentType) {
    headers['Content-Type'] = contentType
  }

  const response = await fetch(encodeQueryParams(url), {
    method,
    headers,
    body,
  })

  return response.text()
}

export function encodeQueryParams(url: string): string {
  const parsedUrl = new URL(url)
  const query = qs.parse(parsedUrl.search, { ignoreQueryPrefix: true })

  if (!isEmpty(query)) {
    return (
      parsedUrl.origin +
      parsedUrl.pathname +
      '?' +
      qs.stringify(query, { arrayFormat: 'repeat' })
    )
  }

  return parsedUrl.href
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function request<TResponseData extends ApiResponse['data']>(
  props: RequestProps
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  const resp = await requestAnything(props)
  const jsonResponse: ApiResponse<TResponseData> = Object.freeze(
    await resp.json()
  ) as ApiResponse<TResponseData>

  // Internally we type `jsonResponse` as `ApiResponse<TResponseData>` but we cast
  // to a return value of `any` for backwards compatibility so we can update usages
  // slowly to start passing the `TResponseData` generic.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
  return jsonResponse
}

export function requestJson(props: RequestProps) {
  const contentType = props.contentType || 'application/json'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
  return request({ ...props, contentType }) as any
}

export function putRequest({ url, body }: { url: string; body: BodyInit }) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
  return requestJson({
    url,
    body,
    method: 'PUT',
    additionalHeaders: {
      'If-Match': getETag(url) || '*',
    },
  })
}

export async function handleResponse(response: Response) {
  if (response.ok) {
    if (response.headers.has('ETag')) {
      cacheETag(response.url, response.headers.get('ETag') || '')
    }

    return response
  } else {
    try {
      if (response.status === 401) {
        // if the request is unauthenticated, log the user out
        const requestId = response.headers.get('X-Request-ID')
        ErrorMonitoring.capture({
          error: 'Request was unauthenticated, logging out user',
          tags: requestId ? { request_id: requestId } : undefined,
          extras: requestId
            ? { cloudwatch_url: generateCloudwatchUrl(requestId) }
            : undefined,
          level: 'warning',
        })

        // FIXME: In careapp this will redirect to main app base
        void logout(environment.clientBaseUrl)
      }

      const json: unknown = await response.json()
      return Promise.reject({
        status: response.status,
        json,
        requestId: response.headers.get('X-Request-ID'),
      })
    } catch {
      return Promise.reject({
        status: response.status,
        json: null,
        requestId: response.headers.get('X-Request-ID'),
      })
    }
  }
}

export type UrlAndContentType = {
  url: string
  contentType: string
  filename?: string
}

type BlobRequestProps = {
  url: string
  method?: 'GET' | 'POST'
  body?: BodyInit
  requestContentType?: string
}

export async function fetchBlobUrlAndContentType({
  url,
  method,
  body,
  requestContentType,
}: BlobRequestProps): Promise<UrlAndContentType> {
  const res = await requestAnything({
    url,
    method,
    body,
    contentType: requestContentType,
  })
  const contentType =
    res.headers.get('Content-Type') || 'application/octet-stream'
  const filename = parseContentDisposition(
    res.headers.get('Content-Disposition')
  )
  const blob = await res.blob()
  return {
    contentType,
    filename,
    url: URL.createObjectURL(blob),
  }
}

function generateRequestIdWithBreadcrumb(url: string): string {
  const requestId = uuidv4()
  ErrorMonitoring.addRequestBreadcrumb({ url, requestId })

  return requestId
}

function parseContentDisposition(header: string | null): string | undefined {
  const match = (header || '').match(
    /filename=['"]?(?<filename>[^;"']*)['"]?;?"/
  )
  if (match) {
    return match.groups?.filename
  }
  return undefined
}

export async function authHeader(): Promise<{ Authorization: string }> {
  await setLastUserActivity()

  if (environment.isCypress) {
    return {
      Authorization:
        'Bearer ' + getMockAuthToken(environment.itestAdmin?.username || ''),
    }
  }

  if (environment.authEnabled) {
    try {
      const currentUser = await fetchAuthSessionWithRetries()
      return {
        Authorization: 'Bearer ' + currentUser.getIdToken().getJwtToken(),
      }
    } catch (e) {
      ErrorMonitoring.capture({
        error:
          'Unable to retrieve auth token for request. Likely auth expiration, logging out user',
        level: 'warning',
      })
      // FIXME: In careapp this will redirect to main app base
      await logout(environment.clientBaseUrl)
      return Promise.reject(
        'A bunch of inoffensive words so Sentry does not obscure this message'
      )
    }
  } else if (environment.name !== 'test') {
    let mockUsername = sessionStorage.getItem('mock_auth_username')
    if (!mockUsername) {
      mockUsername = prompt('Mock Auth Username')
      if (mockUsername) {
        sessionStorage.setItem('mock_auth_username', mockUsername)
      }
    }
    if (mockUsername) {
      return {
        Authorization: 'Bearer ' + getMockAuthToken(mockUsername),
      }
    }
  }

  return { Authorization: '' }
}

export async function updateSnapshot({
  pId,
  orgId,
  dataType,
  file,
  fileMetadata = {},
  customType,
}: {
  pId: string
  orgId: string
  dataType: string
  file: string | Blob
  fileMetadata?: UploadInfo
  customType?: string
}) {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('data', JSON.stringify({ uploadInfo: fileMetadata }))

  const params = customType ? { customType } : undefined

  return fetchAnything(getSnapshotsUrl(orgId, pId, dataType, params), {
    method: 'POST',
    body: formData,
  }).then((rsp) => rsp.json())
}
