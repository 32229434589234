import { SnapshotPartialPdf } from '@augusthealth/models/com/august/protos/api/snapshot_partial_pdf'
import { Immunization } from '@augusthealth/models/com/august/protos/immunization'
import {
  Task,
  TaskTemplateInfo,
} from '@augusthealth/models/com/august/protos/task'
import { FieldError } from 'react-hook-form'
import { request, updateSnapshot } from '@shared/api/request'
import {
  apiPartialUploadUrl,
  completeViaPartialUploadUrl,
} from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { DataType, Snapshot_Data, UploadInfo } from '@shared/types/snapshot'
import { fromDateToDateMessage } from '@shared/utils/date'
import { AugustError, buildAugustError } from '@shared/utils/error'
import { isIncomplete } from '@shared/utils/task'
import { attachImmunizationWithPartialUpload } from '@app/api/immunizations'
import { markTaskAsComplete } from '@app/api/tasks'
import {
  FormValues,
  namedDocuments,
  signedDocuments,
} from '@app/pages/Documents/Uploader/Forms'
import { saveImmunizations } from '@app/pages/Documents/Viewer/VaccineViewer/helpers'

export async function submitPartialDocument({
  taskTemplateInfo,
  data,
  person,
  file,
  tasks,
  selectedPages,
}: {
  taskTemplateInfo: TaskTemplateInfo
  data: FormValues
  person: Person
  file: File
  tasks: Task[]
  selectedPages: number[]
}) {
  const dataType = taskTemplateInfo.dataType!

  if (data.tag === 'CustomSignableForm') {
    const incompleteCustomTask = tasks
      .filter(isIncomplete)
      .find(
        (t) =>
          t.taskTemplateInfo!.dataType === dataType &&
          t.taskTemplateInfo!.customType === taskTemplateInfo.customType
      )
    const uploadInfo = {
      name: data.customType,
      signatureData: data.dateSigned
        ? { dateSigned: fromDateToDateMessage(data.dateSigned) }
        : undefined,
    }

    if (incompleteCustomTask) {
      return await uploadAndCompleteTask({
        file,
        person,
        task: incompleteCustomTask,
        selectedPages,
        data: { uploadInfo },
        dataType,
      })
    } else {
      return await uploadDocument({
        file,
        person,
        fileMetadata: uploadInfo,
        dataType,
        customType: taskTemplateInfo.customType,
        selectedPages,
      })
    }
  }

  const incompleteTask = tasks
    .filter(isIncomplete)
    .find((t) => t.taskTemplateInfo!.dataType === dataType)

  if (data.tag === 'NamedUpload') {
    const uploadInfo = { name: data.name }

    if (incompleteTask) {
      return await uploadAndCompleteTask({
        file,
        person,
        task: incompleteTask,
        selectedPages,
        data: { uploadInfo },
        dataType,
      })
    } else {
      return await uploadDocument({
        file,
        person,
        fileMetadata: uploadInfo,
        dataType,
        selectedPages,
      })
    }
  }

  if (data.tag === 'SignedDocument') {
    const uploadInfo = {
      signatureData: { dateSigned: fromDateToDateMessage(data.dateSigned) },
    }

    if (incompleteTask) {
      return await uploadAndCompleteTask({
        file,
        person,
        task: incompleteTask,
        selectedPages,
        data: { uploadInfo },
        dataType,
      })
    } else if (
      dataType === DataType.DATA_TYPE_CA_FORM_602 ||
      dataType === DataType.DATA_TYPE_PRN_AUTHORIZATION ||
      dataType === DataType.DATA_TYPE_NURSE_DELEGATION
    ) {
      return await uploadDocument({
        dataType,
        fileMetadata: uploadInfo,
        file,
        person,
        selectedPages,
      })
    } else {
      throw new Error(
        'You can only upload a 601 or 603 when there is a corresponding open task.'
      )
    }
  }

  if (data.tag === 'POLST') {
    const uploadInfo = {
      polst: { cprCode: data.codeStatus },
    }

    if (incompleteTask) {
      return await uploadAndCompleteTask({
        file,
        person,
        task: incompleteTask,
        selectedPages,
        data: { uploadInfo },
        dataType,
      })
    } else {
      return await uploadDocument({
        file,
        person,
        fileMetadata: uploadInfo,
        dataType,
        selectedPages,
      })
    }
  }

  if (data.tag === 'Immunization') {
    // this path should never be hit
    // immunizations should submit using the AddImmunization form which handles uploading on its own
    throw new Error('Immunization records must be uploaded to the correct form')
  }

  if (dataType === DataType.DATA_TYPE_PROFILE_PHOTO) {
    return await uploadDocument({
      file,
      person,
      fileMetadata: { name: file.name },
      dataType,
      selectedPages,
    })
  }
}

async function uploadDocument({
  file,
  person,
  fileMetadata,
  dataType,
  selectedPages,
  customType,
}: {
  file: File
  person: Person
  dataType: DataType
  selectedPages: number[]
  customType?: string
  fileMetadata?: UploadInfo
}) {
  if (file?.type !== 'application/pdf') {
    return await updateSnapshot({
      pId: person.id,
      orgId: person.orgId,
      dataType,
      file: file,
      customType,
      fileMetadata,
    })
  }

  const url = apiPartialUploadUrl({
    orgId: person.orgId!,
    personId: person.id!,
    dataType,
    customType,
  })

  const formData = new FormData()
  const data: SnapshotPartialPdf = {
    pages: selectedPages,
    data: { uploadInfo: fileMetadata },
  }

  formData.append('data', JSON.stringify(data))
  formData.append('file[]', file)

  return await request({
    url,
    method: 'POST',
    body: formData,
  })
}

const uploadAndCompleteTask = async ({
  file,
  person,
  task,
  selectedPages,
  data,
  dataType,
}: {
  file: File
  person: Person
  task: Task
  selectedPages: number[]
  data: Snapshot_Data
  dataType: DataType
}) => {
  if (file.type !== 'application/pdf') {
    const res = await updateSnapshot({
      pId: person.id,
      orgId: person.orgId,
      dataType,
      file,
      fileMetadata: data.uploadInfo,
    })
    await markTaskAsComplete(person, task)

    return res
  }

  const url = completeViaPartialUploadUrl({
    personId: person.id!,
    orgId: person.orgId!,
    id: task.id!,
  })

  const formData = new FormData()

  formData.append(
    'data',
    JSON.stringify({
      pages: selectedPages,
      data: {
        ...data,
      },
    })
  )
  formData.append('file[]', file)

  return await request({ url, method: 'POST', body: formData })
}

export const submitImmunizationsWithPartialUpload = async ({
  file,
  selectedPages,
  originalImmunizations,
  updatedImmunizations,
  person,
}: {
  file: File
  selectedPages: number[]
  originalImmunizations: Immunization[]
  updatedImmunizations: Immunization[]
  person: Person
}) => {
  const resolvedImmunizations = await saveImmunizations({
    originalImmunizations,
    updatedImmunizations,
    person,
  })

  const existingImmunizationIds = originalImmunizations.map((imm) => imm.id)

  const justUploadedImmunization: Immunization | undefined =
    resolvedImmunizations.find(
      (imm) => !existingImmunizationIds.includes(imm.id)
    )

  if (justUploadedImmunization) {
    return await attachImmunizationWithPartialUpload({
      file: file!,
      selectedPages: selectedPages,
      immunizationId: justUploadedImmunization.id!.toString(),
      person,
    })
  } else {
    // we should never land in this else block
    throw new Error('Unable to attach document to immunization')
  }
}

/**
 * Returns true if the form is has errors, false if the form is valid
 * @param formValues - the values of the form being uploaded (taken from methods.getValues())
 * @param dataType - the data type of the form being uploaded
 * @param setFormError - the React Hook Form setFormError method to show validations next to Input Label
 * @param setAugustError - show error to Global Error popup
 */
export const validateForm = ({
  file,
  formValues,
  dataType,
  setFormError,
  setAugustError,
  isSignable,
}: {
  file?: File
  formValues: FormValues
  dataType?: DataType
  setFormError: (name: string, error: FieldError) => void
  setAugustError: (err: AugustError) => void
  isSignable?: boolean
}) => {
  if (!dataType) {
    return true
  }

  const fileFoundButNotPdf = file && file.type !== 'application/pdf'

  if (formValues.tag === 'NamedUpload' && namedDocuments.includes(dataType)) {
    if (formValues.name.trim().length === 0) {
      setFormError('name', {
        type: 'required',
      })

      return true
    }
  }

  if (
    formValues.tag === 'SignedDocument' &&
    signedDocuments.includes(dataType)
  ) {
    if (!formValues.dateSigned) {
      setFormError('dateSigned', {
        type: 'required',
      })

      return true
    }
    if (fileFoundButNotPdf) {
      setAugustError(
        buildAugustError({
          name: 'PdfFileRequired',
          message: 'Please upload a PDF file',
        })
      )
      return true
    }
  }

  if (formValues.tag === 'POLST' && dataType === DataType.DATA_TYPE_POLST) {
    if (!formValues.codeStatus) {
      setFormError('codeStatus', {
        type: 'required',
      })

      return true
    }
  }

  if (formValues.tag === 'CustomSignableForm') {
    if (isSignable && !formValues.dateSigned) {
      setFormError('dateSigned', {
        type: 'required',
      })

      return true
    }

    if (fileFoundButNotPdf) {
      setAugustError(
        buildAugustError({
          name: 'PdfFileRequired',
          message: 'Please upload a PDF file',
        })
      )

      return true
    }
  }

  if (
    dataType === DataType.DATA_TYPE_PROFILE_PHOTO &&
    file?.type === 'application/pdf'
  ) {
    setAugustError(
      buildAugustError({
        name: 'ImageFileRequired',
        message: 'PDF file is not allowed',
      })
    )
    return true
  }

  return false
}
