import { typedMergePatchPerson } from '@shared/api/person'
import { AllergyIntolerance } from '@shared/types/allergy_intolerance'
import { SystemUrl } from '@shared/types/codeable_concept'
import { Condition } from '@shared/types/condition'
import { Person } from '@shared/types/person'
import { PickPartial } from '@shared/types/utilities'
import { hasDementia } from '@shared/utils/person'

export async function updateDiagnosesAndAllergies(
  person: PickPartial<Person, 'id' | 'facilityId' | 'orgId' | 'conditions'>,
  diagnoses: Condition[],
  allergies: AllergyIntolerance[]
) {
  const trimmedDiagnoses = diagnoses.map((d) => ({
    ...d,
    code: { ...d.code, text: d.code?.text?.trim() },
  }))
  const trimmedAllergies = allergies.map((a) => ({
    ...a,
    summary: a.summary?.trim(),
  }))

  addCodingToDementia(person, trimmedDiagnoses)

  await typedMergePatchPerson({
    fId: person.facilityId || '',
    pId: person.id || '',
    orgId: person.orgId || '',
    fields: ['conditions', 'allergiesAndIntolerances/allergies'],
    patch: {
      conditions: trimmedDiagnoses,
      allergiesAndIntolerances: { allergies: trimmedAllergies },
    },
  })
}

export const addCodingToDementia = (
  person: PickPartial<Person, 'conditions'>,
  diagnosesList: Condition[]
) => {
  const dementiaIndex = diagnosesList.findIndex(
    (d) => d.code?.text?.toLowerCase() === 'dementia' && !d.code.coding
  )
  if (dementiaIndex >= 0) {
    if (!hasDementia(person)) {
      // if dementia text is in the list, replace with CodeableConcept
      diagnosesList.splice(dementiaIndex, 1, DEMENTIA_CODEABLE_CONCEPT)
    } else {
      // if person has existing dementia diagnosis, remove duplicate
      diagnosesList.splice(dementiaIndex, 1)
    }
  }
}

export const DEMENTIA_CODE = '52448006'
export const DEMENTIA_CODEABLE_CONCEPT = {
  code: {
    coding: [
      {
        systemEnum: SystemUrl.SYSTEM_URL_SNOMED_CT,
        code: DEMENTIA_CODE,
        display: 'Dementia',
      },
    ],
    text: 'Dementia',
  },
}
