import StyledSelect, {
  OptionTypeBase,
  StyledSelectProps,
} from '@shared/components/StyledSelect'
import ElementHolder from '../formElements/ElementHolder'

type OptionValue = string | number | undefined

type Props = Omit<StyledSelectProps, 'onChange' | 'value'> & {
  onUpdate: (value: OptionValue, name?: string) => void
  value: OptionValue
}

export default function SelectInGenerator(props: Props) {
  const { onUpdate, value, ...selectProps } = props
  const selectedOption = props?.options?.find(
    (opt?: OptionTypeBase) => opt?.value === value
  )
  const inputId = `${selectProps.name}-styled-select-input`

  return (
    <ElementHolder htmlFor={inputId} {...props}>
      <StyledSelect
        inputId={inputId}
        {...(selectProps as StyledSelectProps)}
        onChange={(opt?: OptionTypeBase) => onUpdate(opt?.value, props.name)}
        value={selectedOption || null}
      />
    </ElementHolder>
  )
}
