import {
  Contact,
  Contact_ContactRelationship as Relationship,
} from '@augusthealth/models/com/august/protos/contact'
import classNames from 'classnames'
import { BigDotDivider } from '@shared/components/DotDivider'
import { ROLE_RELATIONSHIP_LIST } from '@shared/constants/contactRelationship'
import { getFullName } from '@shared/utils/humanName'
import { getRelationships } from '@shared/utils/relationship'

const RELATIONSHIP_LABELS_TO_HIDE: Relationship[] = [
  Relationship.CONTACT_RELATIONSHIP_C,
  Relationship.CONTACT_RELATIONSHIP_N,
  Relationship.CONTACT_RELATIONSHIP_FAMMEMB,
  Relationship.CONTACT_RELATIONSHIP_PERSONAL,
  Relationship.CONTACT_RELATIONSHIP_CLINICIAN,
  Relationship.CONTACT_RELATIONSHIP_PROFESSIONAL,
  ...ROLE_RELATIONSHIP_LIST,
]

export interface ItemComponentProps {
  name?: string
  data?: Contact
  showDefaultCursor?: boolean
  isLabel?: boolean
  inList?: boolean
  isSelected?: boolean
  label?: JSX.Element
  defaultLabel?: string
  onClick?
  onRemove?: (Contact) => void
}

export default function ItemComponent(props: ItemComponentProps) {
  const {
    name: dropdownName,
    data,
    showDefaultCursor,
    isLabel, // For default trigger UI component with Dropdown list hidden
    inList, // Component inside List (of dropdown such as Emergency Contact) vs individual Dropdown (unique selection such as Primary Contact)
    isSelected: isDefaultSelected,
    label: defaultLabel,
    onClick,
    onRemove,
  } = props

  let orgNameHolder
  let relationshipHolder
  let label = defaultLabel
  let removeBtn
  let className
  const { name, organization: org, relationship } = data || {}
  const isSelected = Boolean(isDefaultSelected && name) // Don't add selected icon to Add Contact link

  if (relationship) {
    relationshipHolder = (
      <span className="label-subtitle">
        {getRelationships(relationship, 'all', {
          excludes: RELATIONSHIP_LABELS_TO_HIDE,
        })}
      </span>
    )
  }

  if (org && org.name) {
    orgNameHolder = (
      <span className="label-subtitle ml-0">
        <BigDotDivider />
        {org.name}
      </span>
    )
  }

  if (name && getFullName(name)) {
    label = (
      <span data-cy={`${dropdownName}-fullName-label`}>
        {getFullName(name)}
      </span>
    )
    className = 'label-title'
  }

  if (onRemove) {
    removeBtn = (
      <div className="remove-button-holder">
        <button
          name={`${dropdownName}-close-icon`}
          type="button"
          className="close"
          onClick={() => onRemove(data)}
        >
          <i className="fa fa-close" />
        </button>
      </div>
    )
  }

  const isLabelInList = isLabel && inList
  const holderClassName = classNames('contact-dropdown-item', 'horizontal', {
    'font-bold text-[11px] leading-[14px] rounded-[6px] px-[12px] py-[11px] hover:brightness-90':
      isLabelInList,
    'border-button-inner-border-color bg-duotone-level6 border': isLabelInList,
    'font-semibold text-[13px] min-w-[100px] min-h-[40px] rounded-[3px]':
      isLabelInList,
    'dropdown-item': !isLabel,
    'contact-dropdown-readonly': showDefaultCursor,
  })

  return (
    <div className={holderClassName} onClick={onClick}>
      {isSelected && <i className="fa fa-check selected-icon" />}
      <div className="text-holder horizontal">
        <div className={className}>
          {label}
          {relationshipHolder}
          {orgNameHolder}
        </div>
      </div>
      {removeBtn}
    </div>
  )
}
