// http://stevehardie.com/2009/10/list-of-common-languages/
// https://www.convertcsv.com/csv-to-json.htm
import StyledSelect, {
  OptionTypeBase,
  StyledSelectProps,
} from '@shared/components/StyledSelect'
import { Person } from '@shared/types/person'
import { getLanguageOptions } from '@shared/utils/communication'

export type Props = Omit<StyledSelectProps, 'options' | 'value'> & {
  countryCode?: string
  options?: OptionTypeBase[]
  person: Person
  value?: string
}

export default function LanguageDropdown(props: Props) {
  const {
    countryCode = 'USA',
    options: defaultOptions,
    person,
    value,
    ...selectProps
  } = props
  const { communication = [] } = person || {}
  // Options may come from List Component, so don't need to re-compute every time
  const options =
    defaultOptions || getLanguageOptions({ communication, countryCode })

  // Since existing value will be filtered out, build a new option when value exists
  const defaultValue = value
    ? {
        label: value,
        value,
      }
    : undefined

  return (
    <div className="w-full">
      <StyledSelect
        placeholder="Select a preferred language"
        {...selectProps}
        options={options}
        value={defaultValue}
      />
    </div>
  )
}
