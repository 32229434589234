import { ReactNode } from 'react'
import { ClassNameValue } from 'tailwind-merge'
import { twx } from '@shared/utils/tailwind'

export default function Disclosure({
  summary,
  children,
  classes,
  summaryClasses,
}: {
  classes?: ClassNameValue
  summaryClasses?: ClassNameValue
  summary: ReactNode
  children: ReactNode
}) {
  return (
    <details className={twx(classes)}>
      <summary className={twx('mb-4', summaryClasses)}>{summary}</summary>
      {children}
    </details>
  )
}

export function SectionDisclosure({
  summary,
  children,
}: {
  summary: ReactNode
  children: ReactNode
}) {
  return (
    <Disclosure
      summary={summary}
      summaryClasses="text-sm font-medium text-gray-07 hover:text-gray-06 focus:text-primary-light"
    >
      {children}
    </Disclosure>
  )
}
