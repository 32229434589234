import { intersection } from 'lodash'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import {
  Group,
  GroupPermission,
  GroupType,
  PersonMatcher,
} from '@shared/types/permission'
import { UserAccount } from '@shared/types/user'
import { isSuperUser } from '@shared/utils/user'

export function removeGroupsByOrgId({
  groups,
  orgId,
}: {
  groups: Group[]
  orgId: string
}) {
  // Keep Group without OrgID or with different OrgID
  return groups.filter((g) => {
    return (
      !g.personMatcher?.organizationId ||
      g.personMatcher.organizationId !== orgId
    )
  })
}

export function getPersonMatchersWithPersonId(user: UserAccount) {
  return (user.groups ?? []).reduce((list: PersonMatcher[], g) => {
    const { personMatcher } = g
    if (personMatcher?.personId) {
      list.push(personMatcher)
    }

    return list
  }, [])
}

export function getOrgIdsFromPersonMatchers(
  personMatchers: PersonMatcher[]
): string[] {
  const set = personMatchers.reduce((s, pm) => {
    if (pm.organizationId !== undefined) {
      s.add(pm.organizationId)
    }

    return s
  }, new Set<string>())

  return Array.from(set)
}

export function getFacilityIdsFromGroups(
  groups: Group[],
  orgId: string
): string[] {
  const set = groups.reduce((s, g) => {
    const { organizationId, facilityId } = g.personMatcher || {}
    if (facilityId && organizationId === orgId) {
      s.add(facilityId)
    }

    return s
  }, new Set<string>())

  return Array.from(set)
}

const GROUP_TYPE_LABEL_HASH: Record<GroupType, string> = {
  [GroupType.GROUP_TYPE_ANALYST]: 'Basic Tools User',
  [GroupType.GROUP_TYPE_ORGANIZATION_ADMIN]: 'Owner',
  [GroupType.GROUP_TYPE_SOCIAL_WORKER]: 'Social Worker',
  [GroupType.GROUP_TYPE_BILLING]: 'Billing',
  [GroupType.GROUP_TYPE_NURSE]: 'Nurse',
  [GroupType.GROUP_TYPE_MED_TECH]: 'Med Tech',
  [GroupType.GROUP_TYPE_MED_TECH_SUPERVISOR]: 'Med Tech Supervisor',
  [GroupType.GROUP_TYPE_PHARMACIST]: 'Pharmacist',
  [GroupType.GROUP_TYPE_FRONT_DESK]: 'Front Desk',
  [GroupType.GROUP_TYPE_FACILITY_DIRECTOR]: 'Director',
  [GroupType.GROUP_TYPE_RESPONSIBLE_PARTY]: 'Responsible Person',
  [GroupType.GROUP_TYPE_SUPER_USER]: 'Super User',
  [GroupType.GROUP_TYPE_TOOL_USER]: 'Advanced Tools User',
  [GroupType.GROUP_TYPE_MEDICAL]: 'Medical',
  [GroupType.GROUP_TYPE_UNSPECIFIED]: 'Other',
  [GroupType.GROUP_TYPE_API_WRITE]: 'API Write Access',
  [GroupType.GROUP_TYPE_API_READ]: 'API Read Access',
  [GroupType.GROUP_TYPE_DEMO_USER]: 'Demo User',
  [GroupType.GROUP_TYPE_ORG_INTEGRATION_SERVICE_ACCOUNT]:
    'Internal Service Account',
  [GroupType.GROUP_TYPE_EXTERNAL_CONTACT]: 'External Contact',
  [GroupType.UNRECOGNIZED]: 'Unrecognized',
  [GroupType.GROUP_TYPE_FACILITY_ADMIN]: 'Sales and Marketing',
  [GroupType.GROUP_TYPE_FACILITY_SALES_AND_MARKETING]: 'Sales and Marketing',
  [GroupType.GROUP_TYPE_FACILITY_STAFF]: 'Staff',
  [GroupType.GROUP_TYPE_FACILITY_STAFF_PLUS_EMAR]: 'Staff',
}

export function getGroupLabelAndType(groupType: GroupType): OptionTypeBase {
  return {
    label: GROUP_TYPE_LABEL_HASH[groupType],
    value: groupType,
  }
}

export function hasPermissions({
  user,
  permissions,
}: {
  user: UserAccount
  permissions: GroupPermission[]
}) {
  if (isSuperUser(user)) return true

  return Boolean(
    permissions.length &&
      user.groups?.some(
        (g) =>
          g.groupPermissions &&
          intersection(g.groupPermissions, permissions).length ===
            permissions.length
      )
  )
}
