import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useEffect } from 'react'
import { Redirect, RouteComponentProps, Switch } from 'react-router-dom'
import { listFacilities } from '@shared/api/facility'
import GatedRoute from '@shared/components/Auth/GatedRoute'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { hasActivePharmacyIntegration } from '@shared/utils/facilities'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import AccountPreferences, {
  ACCOUNT_PREFERENCES_PATHS,
} from '@app/pages/AccountPreferences'
import Dashboard from '@app/pages/Compliance'
import ResidentCompletionList from '@app/pages/Compliance/ResidentCompletion/List'
import Billing from '@app/pages/Facilities/Billing'
import { FacilityInbox } from '@app/pages/Facilities/Inbox/FacilityInbox'
import Incidents from '@app/pages/Facilities/Incidents/FacilityIncidents'
import Insights from '@app/pages/Facilities/Insights'
import Occupancy from '@app/pages/Facilities/Occupancy'
import List from './List'
import ReportReview from './Reports/ReportReview'

const FACILITIES_LIST_PATHS = ['/orgs/:orgId/facilities', '/orgs/:orgId']

const FACILITIES_OCCUPANCY_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/occupancy',
]

const FACILITIES_REPORTS_PATHS = ['/orgs/:orgId/facilities/:facilityId/reports']

const FACILITIES_REPORT_PREVIEW = [
  '/orgs/:orgId/facilities/:facilityId/reports/:reportName',
]

export const FACILITIES_COMPLIANCE_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/compliance',
  '/orgs/:orgId/facilities/:facilityId/compliance/residentCompletion',
]

export const FACILITIES_INCIDENTS_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/incidents',
]

export const FACILITIES_INSIGHTS_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/insights',
]

export const FACILITIES_INBOX_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/inbox',
]

export const FACILITIES_BILLING_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/billing',
]

export const FACILITIES_PATHS = [
  ...FACILITIES_LIST_PATHS,
  ...FACILITIES_OCCUPANCY_PATHS,
  ...FACILITIES_REPORTS_PATHS,
  ...FACILITIES_REPORT_PREVIEW,
  ...FACILITIES_COMPLIANCE_PATHS,
  ...FACILITIES_INCIDENTS_PATHS,
  ...FACILITIES_INSIGHTS_PATHS,
  ...ACCOUNT_PREFERENCES_PATHS,
  ...FACILITIES_INBOX_PATHS,
  ...FACILITIES_BILLING_PATHS,
]

function Facilities({
  match,
}: RouteComponentProps<{ orgId: string; facilityId: string }>) {
  const { facilities, setFacilities } = useContext(FacilitiesContext)
  const { setError } = useContext(GlobalContext)
  const { orgId, facilityId } = match.params

  useEffect(() => {
    if (facilities?.some((f) => f.orgId !== orgId)) {
      setFacilities([])
    }

    listFacilities(orgId)
      .then((res) => setFacilities(res))
      .catch(setError)
  }, [orgId, facilityId])

  return (
    <Switch>
      <Redirect
        exact
        from={FACILITIES_LIST_PATHS[1]}
        to={FACILITIES_LIST_PATHS[0]}
      />
      <GatedRoute
        exact
        path={FACILITIES_LIST_PATHS}
        minimalPermissions={[GroupPermission.GROUP_PERMISSION_FACILITY_READ]}
        component={List}
        title={'Facilities'}
      />
      <GatedRoute
        exact
        path={FACILITIES_REPORT_PREVIEW}
        minimalPermissions={[
          GroupPermission.GROUP_PERMISSION_REPORT_LIMITED_READ,
        ]}
        component={ReportReview}
        title={'Reports'}
      />
      <GatedRoute
        exact
        path={FACILITIES_OCCUPANCY_PATHS}
        minimalPermissions={[GroupPermission.GROUP_PERMISSION_OCCUPANCY_READ]}
        component={Occupancy}
        title={'Occupancy'}
      />
      <GatedRoute
        exact
        path={FACILITIES_COMPLIANCE_PATHS[0]}
        minimalPermissions={[GroupPermission.GROUP_PERMISSION_TASK_READ]}
        component={Dashboard}
        title={'Compliance Dashboard'}
      />
      <GatedRoute
        exact
        path={FACILITIES_COMPLIANCE_PATHS[1]}
        minimalPermissions={[GroupPermission.GROUP_PERMISSION_TASK_READ]}
        component={ResidentCompletionList}
        title={'Resident Completion'}
      />
      <GatedRoute
        exact
        path={FACILITIES_INCIDENTS_PATHS[0]}
        minimalPermissions={[GroupPermission.GROUP_PERMISSION_INCIDENT_READ]}
        component={Incidents}
        title={'Notes & Incidents'}
      />
      <GatedRoute
        exact
        path={FACILITIES_INSIGHTS_PATHS[0]}
        minimalPermissions={[GroupPermission.GROUP_PERMISSION_INSIGHTS_READ]}
        component={Insights}
        title={'Insights'}
      />
      <GatedRoute
        exact
        path={FACILITIES_INBOX_PATHS}
        minimalPermissions={[
          GroupPermission.GROUP_PERMISSION_MEDICATION_UPDATE,
        ]}
        additionalFacilityChecks={[
          (facility: Facility) => hasActivePharmacyIntegration(facility),
        ]}
        component={FacilityInbox}
        title={'Inbox'}
      />

      <GatedRoute
        exact
        path={FACILITIES_BILLING_PATHS}
        minimalPermissions={[GroupPermission.GROUP_PERMISSION_BILLING_UPDATE]}
        component={Billing}
        title={'Billing'}
      />
      <GatedRoute
        exact
        minimalPermissions={[]}
        path={ACCOUNT_PREFERENCES_PATHS}
        component={AccountPreferences}
        title={'Account Preferences'}
      />
    </Switch>
  )
}

export default Facilities
