import { MedicationOrder as LegacyMedicationOrder } from '@augusthealth/models/com/august/protos/medication_order'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import { LabelAboveInput } from '@shared/components/Labels'
import {
  CreatableIconStyledSelect,
  OptionTypeBase,
} from '@shared/components/StyledSelect'
import TextInputWithIcon from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import { MedicationOrder } from '@shared/types/medication_order'
import { Person } from '@shared/types/person'
import { getFullName } from '@shared/utils/humanName'
import { isExternal } from '@shared/utils/medicationStatement'
import { prescribersForPerson } from '@shared/utils/person'
import { MedOrderCardTitle } from '@app/components/Residents/Medications/Orders/MedicationOrderDetails/MedOrderCardTitle'
import styles from '../styles.module.css'
import { MedOrderFormData } from './helpers'
import { ReviewOrderBadge } from './ReviewOrderBadges'

type Props = {
  order: LegacyMedicationOrder
  readOnly: boolean
  person: Person
}
export default function ReviewOrderPrescriptionDetailsCard(props: Props) {
  const { order, readOnly, person } = props
  const [expanded, setExpanded] = useState(false)
  const { control, watch, setValue, getValues } =
    useFormContext<MedOrderFormData>()
  const formMed = watch()
  const medStatement = order.medicationStatement

  const isExternalOrder = isExternal(order as MedicationOrder)

  const [selectedPrescriber, setSelectedPrescriber] = useState(
    getValues('prescriber') ?? medStatement?.prescriber
  )

  const ogPrescriber = order.medicationStatement?.prescriber
  const ogPharmacy = order.medicationStatement?.pharmacy
  const ogRxNumber = order.medicationStatement?.rxNumber

  const hasNoPrescriptionInfo =
    !formMed.prescriber && !formMed.pharmacy && !formMed.rxNumber

  const physicians = prescribersForPerson(person)
  const physicianOptions: OptionTypeBase[] = physicians.map((phy) => {
    const name = getFullName(phy.name!)
    return {
      value: name,
      label: name,
    }
  })

  if (
    selectedPrescriber &&
    !physicianOptions.find((phy) => phy.value === selectedPrescriber)
  ) {
    physicianOptions.push({
      value: selectedPrescriber,
      label: selectedPrescriber,
    })
  }
  return (
    <CollapsibleCard
      data-testid={'prescription-details-card'}
      defaultExpanded={false}
      enableAutoScrollOnExpand={true}
      collapsedIcon={readOnly ? 'fa-lock text-secondary-07' : undefined}
      cardTitle={
        <div className={styles.prescriptionDetailsContainer}>
          {expanded || hasNoPrescriptionInfo ? (
            <MedOrderCardTitle
              value={'Prescribing Physician, Pharmacy or RX Number...'}
            />
          ) : (
            <>
              <MedOrderCardTitle
                value={
                  <>
                    Prescribing Physician{' '}
                    <ReviewOrderBadge className={'ml-[8px]'}>
                      {formMed.prescriber ?? 'N/A'}
                    </ReviewOrderBadge>
                  </>
                }
              />
              <MedOrderCardTitle
                value={
                  <>
                    Pharmacy{' '}
                    <ReviewOrderBadge className={'ml-[8px]'}>
                      {formMed.pharmacy ?? 'N/A'}
                    </ReviewOrderBadge>
                  </>
                }
              />
              <MedOrderCardTitle
                value={
                  <>
                    RX Number{' '}
                    <ReviewOrderBadge className={'ml-[8px]'}>
                      {formMed.rxNumber ?? 'N/A'}
                    </ReviewOrderBadge>
                  </>
                }
              />
            </>
          )}
        </div>
      }
      onExpandClick={setExpanded}
    >
      <div>
        <Controller
          control={control}
          name="prescriber"
          render={({ field: { onChange, value } }) => (
            <div className="mt-[8px]">
              <LabelAboveInput uppercase={false} htmlFor="prescriber">
                Prescribing Physician
              </LabelAboveInput>
              <CreatableIconStyledSelect
                iconName={'fas fa-user-doctor-hair-long'}
                name="prescriber"
                inputId="prescriber"
                value={
                  physicianOptions.find((phy) => phy.value === value) ?? null
                }
                onChange={(e: OptionTypeBase) => {
                  onChange(e.value)
                }}
                options={physicianOptions}
                isDisabled={readOnly || (isExternalOrder && !!ogPrescriber)}
                onCreateOption={(val: string) => {
                  if (val.trim().length > 0) {
                    setSelectedPrescriber(val)
                    setValue('prescriber', val)
                  }
                }}
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="pharmacy"
          render={({ field: { onChange, value } }) => (
            <div className="mt-[24px]">
              <LabelAboveInput uppercase={false} htmlFor="pharmacy">
                Pharmacy
              </LabelAboveInput>
              <TextInputWithIcon
                inputProps={{
                  placeholder: 'e.g. Pharm to Table Pharmacy',
                  disabled: readOnly || (isExternalOrder && !!ogPharmacy),
                  type: 'text',
                  onChange,
                  value: value ?? '',
                  id: 'pharmacy',
                }}
                iconClassName="fas fa-fw fa-house-medical"
              />
            </div>
          )}
        />
        <Controller
          control={control}
          name="rxNumber"
          render={({ field: { onChange, value } }) => (
            <div className="mt-[24px]">
              <LabelAboveInput uppercase={false} htmlFor="rxNumber">
                RX Number
              </LabelAboveInput>
              <TextInputWithIcon
                inputProps={{
                  placeholder: '000000000',
                  disabled: readOnly || (isExternalOrder && !!ogRxNumber),
                  type: 'text',
                  onChange,
                  value: value ?? '',
                  id: 'rxNumber',
                }}
                iconClassName="fas fa-fw fa-prescription"
              />
            </div>
          )}
        />
      </div>
    </CollapsibleCard>
  )
}
