import { DateMessage } from '@augusthealth/models/com/august/protos/date'
import { Immunization } from '@augusthealth/models/com/august/protos/immunization'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { differenceInMonths } from 'date-fns'
import { ReactNode } from 'react'
import Badge from '@shared/components/Badge'
import { DotDivider } from '@shared/components/DotDivider'
import { ClickableEmbossedCard } from '@shared/components/EmbossedCard'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { Person } from '@shared/types/person'
import { formatDateMessage, fromDateMessageToDate } from '@shared/utils/date'
import {
  getDiseaseLabel,
  getTargetDiseaseName,
} from '@shared/utils/immunizations'
import { tw } from '@shared/utils/tailwind'

function CardContent({
  label,
  subLabel,
  badgeLabel,
}: {
  label: ReactNode
  subLabel?: string
  badgeLabel?: string
}) {
  return (
    <div className={tw`flex items-center`}>
      <div className={tw`flex-1`}>
        <div className={tw`font-semibold`}>{label}</div>
        {subLabel && <div className={tw`text-gray-07`}>{subLabel}</div>}
      </div>
      <div className={tw`flex-0`}>
        {badgeLabel && (
          <Badge color={'darkOrange'} shape={'square'}>
            {badgeLabel}
          </Badge>
        )}
      </div>
    </div>
  )
}

/**
 * Immunization Card for both with or without immunization
 * @param immunization {Immunization | string} Immunization data-model or string that represents the disease name
 */
export default function ImmunizationCard({
  immunization,
  person,
  onClick,
}: {
  immunization: Immunization | string
  person: Person
  onClick: () => void
}) {
  let badgeLabel: string | undefined
  let diseaseLabel: string | undefined
  let occurrenceDate: DateMessage | undefined
  if (typeof immunization === 'string') {
    diseaseLabel = immunization // immunization is a string the represents the disease name
  } else {
    // for existing immunization in Immunization data-model
    diseaseLabel = getDiseaseLabel(getTargetDiseaseName(immunization) || '')
    occurrenceDate = immunization.occurrenceDate
    if (occurrenceDate) {
      const occDate = fromDateMessageToDate(occurrenceDate)
      if (occDate && differenceInMonths(new Date(), occDate) > 12) {
        badgeLabel = 'Overdue'
      }
    }
  }

  if (!occurrenceDate) {
    badgeLabel = 'Missing'
  }

  return (
    <ClickableEmbossedCard
      buttonProps={{ onClick }}
      cardProps={{ className: tw`mb-[12px] bg-gray-12 p-[16px]` }}
    >
      <CardContent
        label={
          <>
            <span>{diseaseLabel}</span>
            <PersonPermissionGate
              permissions={[GroupPermission.GROUP_PERMISSION_SNAPSHOT_UPDATE]}
              person={person}
            >
              <DotDivider />
              <span
                className={tw`hover:text-august-primary-light font-medium text-august-primary`}
              >
                Add
              </span>
            </PersonPermissionGate>
          </>
        }
        subLabel={
          occurrenceDate ? formatDateMessage(occurrenceDate) : undefined
        }
        badgeLabel={badgeLabel}
      />
    </ClickableEmbossedCard>
  )
}
