import { Shift as _Shift } from '@augusthealth/models/com/august/protos/shift'
import { SetRequired } from 'type-fest'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import { formatTime } from '@shared/utils/date'
import { TimePeriod } from './date'

export type Shift = SetRequired<Omit<_Shift, 'period'>, 'id' | 'name'> & {
  period: Required<TimePeriod>
}

/**
 * Displays condensed shift period for the user
 */
export function displayPeriod(
  shift: Shift,
  { use24HourClock }: { use24HourClock: boolean }
) {
  const startTime = formatTime(shift.period.startTime, {
    use24HourClock,
  })
  const endTime = formatTime(shift.period.endTime, {
    use24HourClock,
  })

  return `${startTime} - ${endTime}`
}

/**
 * Displays condensed shift information for the user
 */
export function display(shift: Shift) {
  return shift.name
}

export type ShiftId = string

export function toShiftOption(
  shift: Shift,
  options: { use24HourClock: boolean; isDisabled?: boolean }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
): OptionTypeBase<ShiftId> {
  return {
    label: shift.name,
    subLabel: displayPeriod(shift, options),
    value: shift.id,
    isDisabled: options.isDisabled ?? false,
  }
}
