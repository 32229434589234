import { requestJson } from '@shared/api/request'
import environment from '@shared/environment'
import { apiFacilityUrl } from '@shared/legacy_routes'
import { ManualPaymentRequest } from '@shared/types/billing'
import { FacilityIds } from '@shared/types/facility'

export type PaymentMethodConfigParameters = {
  sessionKey: string
  paymentMethodConfigId: string
}

export type DashboardSession = {
  sessionKey: string
  merchantId: string
  expiresAt: string
}

/**
    TODO:
    1. Why is this a POST request?
    2. I would love if this accepted a `Person` and a `Contact` object instead of their IDs
*/
export const getSavePaymentMethodParameters = async ({
  orgId,
  facilityId,
  personId,
  contactId,
}: {
  orgId: string
  facilityId: string
  personId: string
  contactId: string
}): Promise<PaymentMethodConfigParameters> => {
  const url = `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/people/${personId}/contacts/${contactId}/payments/rainforestPaymentMethodConfig`
  const response = await requestJson({
    url,
    method: 'POST',
  })
  return response.data as PaymentMethodConfigParameters
}

export const getRainforestDashboardSession = async ({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId: string
}): Promise<DashboardSession> => {
  const url = `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/payments/rainforestDashboardSession`
  const response = await requestJson({
    url,
    method: 'GET',
  })
  return response.data as DashboardSession
}

export async function recordManualPayments({
  data,
  facility,
}: {
  data: ManualPaymentRequest
  facility: FacilityIds
}) {
  const url = `${apiFacilityUrl(facility.orgId, facility.id)}/payments/record`
  const response = await requestJson({
    method: 'POST',
    url,
    body: JSON.stringify(data),
  })

  return response.data
}
