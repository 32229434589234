import { ReactNode, useContext, useState } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import { EmptyCard } from '@shared/components/EmptyCard'
import { useUserContext } from '@shared/contexts/UserContext'
import { UserAccount } from '@shared/types/user'
import { getFullName } from '@shared/utils/humanName'
import { tw } from '@shared/utils/tailwind'
import { isDirector, isOrgAdmin, isSuperUser } from '@shared/utils/user'
import HUD from '@app/components/HUD'
import {
  NameTd,
  Table,
  TableContainer,
  TdWithExtraPadding,
  TrWithHoverEffect,
} from '@app/components/Table'
import TableHeader from '@app/components/Table/TableHeader'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import { UserPopupData } from '../UserFormPopup'
import DeleteUserModal from './DeleteUserModal'
import FacilityList from './FacilitiyList'
import {
  getFilteredUsers,
  getSortedUsers,
  getUserLabelAndType,
} from './helpers'

type Props = {
  orgId: string
  reloadUsers: () => void
  setUserPopupData: (userPopupData: UserPopupData) => void
  users?: UserAccount[]
  filterString?: string
  setFilterString: (str: string) => void
}

export default function UserTable({
  orgId,
  reloadUsers,
  setUserPopupData,
  users,
  filterString,
  setFilterString,
}: Props) {
  const { facilities = [] } = useContext(FacilitiesContext)
  const { user } = useUserContext()
  const loginAsDirector = isDirector({ user, orgId })
  const loginAsOrgAdmin = isOrgAdmin({ user, orgId })
  const loginAsSuperUser = isSuperUser(user)
  const [userToBeDeleted, setUserToBeDeleted] = useState<UserAccount>()
  const [showNotice, setShowNotice] = useState<boolean>(false)

  if (users && users.length === 0) {
    return (
      <EmptyCard
        version={{
          tag: 'GetStarted',
          cta: 'add a user',
          ctaFn: () => setUserPopupData({ user: null, users, disabled: false }),
        }}
        className={tw`ml-[12px] mr-[12px]`}
        title="No users yet."
      />
    )
  }

  const filteredUsers =
    (users && getFilteredUsers({ users, filterString, facilities })) || []
  const sortedUsers = getSortedUsers(filteredUsers)
  if (sortedUsers.length === 0) {
    return (
      <EmptyCard
        version={{
          tag: 'Children',
          children: (
            <div className={tw`mt-[4px]`}>
              Modify or{' '}
              <ButtonLink
                onClick={() => setFilterString('')}
                className={tw`font-medium`}
              >
                reset search text
              </ButtonLink>
            </div>
          ),
        }}
        className={tw`ml-[12px] mr-[12px]`}
        title="All users are filtered out."
      />
    )
  }

  const trs = sortedUsers?.map((u) => {
    const { id, email, name, preferredUsername } = u
    const fullName = name ? getFullName(name) : ''
    const { label: displayedRole } = getUserLabelAndType(u)
    const userIsDirector = isDirector({ user: u, orgId })
    const userIsOrgAdmin = isOrgAdmin({ user: u, orgId })
    const editable =
      loginAsSuperUser ||
      (loginAsOrgAdmin && !userIsOrgAdmin) ||
      (loginAsDirector && !userIsDirector && !userIsOrgAdmin)
    let deleteButton: ReactNode | undefined
    if (editable) {
      deleteButton = (
        <ButtonLink
          data-testid={`user-delete-btn-${id}`}
          onClick={(ev) => {
            ev.stopPropagation()
            setUserToBeDeleted(u)
          }}
        >
          <i className="fas fa-fw fa-trash-can" />
        </ButtonLink>
      )
    }

    const showUserEditForm = () => {
      setUserPopupData({
        user: u,
        disabled: !editable,
        users: users ?? [],
      })
    }

    return (
      <TrWithHoverEffect
        key={`${fullName}-user-row-${id}`}
        onClick={showUserEditForm}
      >
        <NameTd>{fullName}</NameTd>
        <TdWithExtraPadding>{displayedRole}</TdWithExtraPadding>
        <TdWithExtraPadding>{email ?? preferredUsername}</TdWithExtraPadding>
        <TdWithExtraPadding data-testid="facility-access">
          <div className={tw`flex flex-wrap`}>
            <div className={tw`grow`}>
              <FacilityList facilities={facilities} user={u} orgId={orgId} />
            </div>
            <div>{deleteButton}</div>
          </div>
        </TdWithExtraPadding>
      </TrWithHoverEffect>
    )
  })

  return (
    <div className="ml-[12px] mr-[12px]">
      <TableContainer>
        <Table data-id="userTable">
          <TableHeader
            headers={[
              {
                label: 'Name',
                className: 'w-[25%]',
              },
              {
                label: 'Role',
                className: 'w-[20%]',
              },
              {
                label: 'Email/Username',
                className: 'w-[30%]',
              },
              {
                label: 'Access',
                className: 'w-[25%]',
              },
            ]}
          />
          <tbody>{trs}</tbody>
        </Table>
        {userToBeDeleted && (
          <DeleteUserModal
            orgId={orgId}
            user={userToBeDeleted}
            onClose={() => setUserToBeDeleted(undefined)}
            setShowNotice={setShowNotice}
          />
        )}
        {showNotice && (
          <HUD onExpire={reloadUsers}>
            User{' '}
            {userToBeDeleted?.name ? getFullName(userToBeDeleted.name) : ''}{' '}
            deleted
          </HUD>
        )}
      </TableContainer>
    </div>
  )
}
