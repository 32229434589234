import {
  GenerateInvoices,
  InvoiceParameters,
} from '@augusthealth/models/com/august/protos/jobs'
import { requestJson } from '@shared/api/request'
import environment from '@shared/environment'

export const getQuickBooksOAuthUrl = async ({
  orgId,
}: {
  orgId: string
}): Promise<string> => {
  const url = `${environment.baseUrl}/organizations/${orgId}/quickbooks/oauthUrl`
  const response = await requestJson({
    url,
    method: 'GET',
  })
  return response.data
}

export const isConnectedToQuickBooks = async ({
  orgId,
}: {
  orgId: string
}): Promise<boolean> => {
  const url = `${environment.baseUrl}/organizations/${orgId}/quickbooks/isConnected`
  const response = await requestJson({
    url,
    method: 'GET',
  })
  return response.data
}

export const finishQuickBooksConnection = async ({
  orgId,
  accessCode,
  realmId,
}): Promise<object> => {
  const url = `${environment.baseUrl}/organizations/${orgId}/quickbooks/connect`
  const response = await requestJson({
    url,
    method: 'POST',
    body: JSON.stringify({
      realmId,
      accessCode,
    }),
  })
  return response.data
}

export const disconnectFromQuickBooks = async ({
  orgId,
}: {
  orgId: string
}): Promise<object> => {
  const url = `${environment.baseUrl}/organizations/${orgId}/quickbooks/disconnect`
  const response = await requestJson({
    url,
    method: 'POST',
  })
  return response.data
}

export const generateInvoices = async ({
  orgId,
  parameters,
}: {
  orgId: string
  parameters: InvoiceParameters
}): Promise<object> => {
  const url = `${environment.baseUrl}/organizations/${orgId}/quickbooks/generateInvoices`
  const body: GenerateInvoices = { person: { orgId }, parameters }
  const response = await requestJson({
    url,
    method: 'POST',
    body: JSON.stringify(body),
  })
  return response.data
}
