import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { BadgeInput } from '@shared/components/BadgeInputs/BadgeInput'
import { Label } from '@shared/components/Labels'
import {
  CreatableStyledSelect,
  OptionTypeBase,
} from '@shared/components/StyledSelect'
import { ErrorDropdownStyleConfig } from '@shared/components/StyledSelect/utils'
import { getQuantityUnitOptions } from '@shared/utils/medicationStatement'
import { MedOrderFormData } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'
import { DynamicFrequencyInputs } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/DynamicFrequencyInputs/DynamicFrequencyInputs'
import styles from '../styles.module.css'
import { updateDosesToMatchDoseUnit, validatePositiveNumber } from '../helpers'

type Props = {
  doseIndex: number
  readOnly: boolean
}
export const RoutineForm = ({ doseIndex, readOnly }: Props) => {
  const methods = useFormContext<MedOrderFormData>()
  const { register, control, formState, watch, trigger } = methods

  const data = useWatch({
    control,
    name: `doses.${doseIndex}`,
  })

  const quantityUnitOptions: OptionTypeBase[] = getQuantityUnitOptions(
    watch(`doses.${doseIndex}.quantity.unit`)
  )

  return (
    <>
      <div className={styles.scheduleInputRow}>
        <BadgeInput
          badgeLabel={'Quantity'}
          type={'number'}
          min={0}
          {...register(`doses.${doseIndex}.quantity.value`, {
            valueAsNumber: true,
            validate: validatePositiveNumber,
          })}
          step={'any'}
          placeholder={'#'}
          showErrorBorder={
            !!formState.errors.doses?.[doseIndex]?.quantity?.value
          }
          disabled={readOnly}
        />
        <Controller
          control={control}
          name={`doses.${doseIndex}.quantity.unit`}
          rules={{ required: true }}
          render={({ fieldState: { error } }) => {
            return (
              <>
                <Label
                  visuallyHidden
                  htmlFor={`doses.${doseIndex}.quantity.unit`}
                >
                  Quantity Unit
                </Label>
                <CreatableStyledSelect
                  inputId={`doses.${doseIndex}.quantity.unit`}
                  instanceId={`doses.${doseIndex}.quantity.unit`}
                  name={`doses.${doseIndex}.quantity.unit`}
                  data-testid={`doses.${doseIndex}.quantity.unit`}
                  options={quantityUnitOptions}
                  placeholder="Select..."
                  onChange={(e: OptionTypeBase) => {
                    updateDosesToMatchDoseUnit({
                      newUnit: e.value,
                      methods,
                    })
                    if (formState.isSubmitted) {
                      void trigger()
                    }
                  }}
                  value={quantityUnitOptions.find(
                    (opt) => opt.value === data?.quantity.unit
                  )}
                  isDisabled={readOnly}
                  styles={ErrorDropdownStyleConfig({ error: !!error })}
                  onCreateOption={(val: string) => {
                    if (val.trim().length > 0) {
                      if (
                        !quantityUnitOptions.find((opt) => opt.value === val)
                      ) {
                        quantityUnitOptions.push({
                          label: val,
                          value: val,
                        })
                      }

                      updateDosesToMatchDoseUnit({
                        newUnit: val,
                        methods,
                      })
                      if (formState.isSubmitted) {
                        void trigger()
                      }
                    }
                  }}
                />
              </>
            )
          }}
        />
      </div>
      <DynamicFrequencyInputs index={doseIndex} readOnly={readOnly} />
    </>
  )
}
