import { identity } from 'lodash'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import Icon from '@shared/components/Icon'
import { Label } from '@shared/components/Labels'
import RhfDatePicker from '@app/components/reactHookForm/RhfDateTimePicker'
import { BulkPaymentsForm, needsValidation } from './helpers'

export function PaymentsForm({ nestIndex }: { nestIndex: number }) {
  const methods = useFormContext<BulkPaymentsForm>()
  const { fields, append, remove } = useFieldArray({
    ...methods,
    name: `residents.${nestIndex}.payments`,
  })

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="ml-[48px] mt-[16px] rounded bg-gray-13 p-[24px]"
    >
      {fields.map((field, index) => {
        const required = needsValidation(
          methods.watch(`residents.${nestIndex}.payments.${index}`)
        )

        return (
          <div
            key={field.id}
            className="flex items-center justify-between [&:not(:first-child)]:mt-[16px]"
          >
            <div className="flex flex-grow flex-col">
              <Label
                htmlFor={`residents.${nestIndex}.payments.${index}.checkNumber`}
                uppercase={false}
                className="mb-[12px]"
                visuallyHidden={index > 0}
              >
                Check Number
              </Label>
              <BasicInput
                {...methods.register(
                  `residents.${nestIndex}.payments.${index}.checkNumber`,
                  { required }
                )}
                iconName="hashtag"
                iconClassNames="text-primary-light text-[14px]"
                containerClassName="flex-grow"
                placeholder="1234"
              />
            </div>
            <div className="flex-grow">
              <Label
                htmlFor={`residents.${nestIndex}.payments.${index}.checkDate`}
                uppercase={false}
                className="mb-[12px] ml-[16px]"
                visuallyHidden={index > 0}
              >
                Received On Date
              </Label>
              <RhfDatePicker
                holderDivProps={{ className: 'flex flex-grow ml-[16px]' }}
                datePickerProps={{
                  convertToDate: identity,
                  convertToSaveValue: identity,
                  wrapperClassName: 'w-full',
                }}
                labelProps={{ a11yOnly: true }}
                control={methods.control}
                name={`residents.${nestIndex}.payments.${index}.checkDate`}
                required={required}
                calendarWidth="auto"
              />
            </div>
            <div className="flex-grow">
              <Label
                htmlFor={`residents.${nestIndex}.payments.${index}.amountInCents`}
                uppercase={false}
                className="mx-[16px] mb-[12px]"
                visuallyHidden={index > 0}
              >
                Amount
              </Label>
              <BasicInput
                type="number"
                step={0.01}
                {...methods.register(
                  `residents.${nestIndex}.payments.${index}.amountInCents`,
                  { required, valueAsNumber: true }
                )}
                iconName="square-dollar"
                iconClassNames="text-primary-light text-[14px]"
                containerClassName="flex-grow ml-[16px] mr-[16px]"
                placeholder="0.00"
              />
            </div>
            <div>
              <div className={index > 0 ? 'visually-hidden' : ''}>&nbsp;</div>
              <div className="flex h-[40px] items-center">
                <button
                  type="button"
                  disabled={fields.length - 1 !== index}
                  onClick={() =>
                    append({
                      checkDate: null,
                      checkNumber: '',
                      amountInCents: null,
                    })
                  }
                  className="mr-[8px] text-gray-07 transition-colors disabled:text-gray-10"
                >
                  <Icon name="circle-plus" />
                </button>
                <button
                  type="button"
                  disabled={fields.length === 1}
                  onClick={() => remove(index)}
                  className="text-gray-07 transition-colors disabled:text-gray-10"
                >
                  <Icon name="circle-minus" />
                </button>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
