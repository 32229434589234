import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import { MedOrderCardTitle } from '@app/components/Residents/Medications/Orders/MedicationOrderDetails/MedOrderCardTitle'
import { MedOrderFormData } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'
import styles from '../styles.module.css'
import { ReviewOrderBadge } from './ReviewOrderBadges'

type Props = {
  readOnly?: boolean
}
export default function ReviewOrderTreatmentCard(props: Props) {
  const { readOnly = false } = props
  const [expanded, setExpanded] = useState(false)
  const { watch, register } = useFormContext<MedOrderFormData>()

  const condition = watch('condition')

  return (
    <CollapsibleCard
      data-testid={'treatment-card'}
      defaultExpanded={false}
      enableAutoScrollOnExpand={true}
      collapsedIcon={readOnly ? 'fa-lock text-secondary-07' : undefined}
      cardTitle={
        <div className={styles.treatmentContainer}>
          {!expanded && !!condition ? (
            <>
              <MedOrderCardTitle value={'This medication treats'} />
              <ReviewOrderBadge>{condition}</ReviewOrderBadge>
            </>
          ) : (
            <>
              <MedOrderCardTitle
                value={'What condition does this medication treat?'}
              />
            </>
          )}
        </div>
      }
      onExpandClick={setExpanded}
    >
      <BasicTextarea
        {...register('condition')}
        className={'min-h-[48px]'}
        disabled={readOnly}
        placeholder={'E.g. Pain'}
        ignoreMaxWidth={true}
      />
    </CollapsibleCard>
  )
}
