import { HumanName } from '@augusthealth/models/com/august/protos/human_name'

interface ExpandedModifiedByMetaData {
  modifiedByUserId: string
  modifiedByUserName: HumanName
  modificationTime: string
}

export interface RefillMetaData {
  id: string
  isActive: boolean
  createdBy: ExpandedModifiedByMetaData
  modifiedBy: ExpandedModifiedByMetaData
}

export interface RefillEligibility {
  medOrderGroupId: string
  refillAllowed: boolean
  refills: PharmacyRefill[]
}

export interface PharmacyRefill {
  data: {
    status: RefillStatus
    events: RefillEvent[]
  }
  meta: RefillMetaData
}

export enum RefillStatus {
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  DISPENSED = 'DISPENSED',
  ERROR = 'ERROR',
}

export interface RefillEvent {
  status: RefillStatus
  createdBy: ExpandedModifiedByMetaData
}
