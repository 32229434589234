import { SignableFormAssignment } from '@augusthealth/models/com/august/protos/signable_form'
import { TaskDefinition } from '@augusthealth/models/com/august/protos/task'
import { useEffect, useState } from 'react'
import { assignmentMatches, SelectedData } from '../OrgFacilityTasks/helpers'
import { CardLevel } from './helpers'

export default function useCurrentAssignment({
  allAssignments,
  cardLevel,
  options,
  taskDefinition,
}: {
  allAssignments: SignableFormAssignment[]
  cardLevel: CardLevel
  options: { label: string; value: string }[]
  taskDefinition: TaskDefinition
}) {
  const [selectedData, setSelectedData] = useState<SelectedData>()
  const { dataType, customType } = taskDefinition.template!
  const { orgId, facilityId, state } = cardLevel

  useEffect(() => {
    let selectedOption
    const currentAssignment = allAssignments.find((a) =>
      assignmentMatches(a, {
        orgId,
        facilityId,
        dataType,
        customType,
        state,
      })
    )

    if (currentAssignment) {
      const signableFormId = currentAssignment?.signableFormId
      if (signableFormId && options) {
        selectedOption = options.find((o) => o.value === signableFormId)
      }
    }

    if (currentAssignment && selectedOption) {
      setSelectedData({
        assignment: currentAssignment,
        dropdownOption: selectedOption,
      })
    } else {
      setSelectedData(undefined)
    }
  }, [allAssignments, orgId, facilityId, dataType])

  return selectedData
}
