import { cloneDeep } from 'lodash'
import { typedMergePatchPerson } from '@shared/api/person'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import { Coverage, Coverage_CoverageType } from '@shared/types/coverage'
import { Person } from '@shared/types/person'
import { Prefix } from '.'

export interface FormData {
  type: Coverage_CoverageType
  provider: string
  memberId: string
  groupNumber: string
  index: number
  rxbin?: string
  rxpcn?: string
}

export type IndexedCoverage = Coverage & { index: number }

export function createTitle({
  type,
  prefix,
}: {
  type: Coverage_CoverageType | undefined
  prefix: Prefix
}): string {
  const label = COVERAGE_TYPE_OPTIONS.find((opt) => opt.value === type)?.label

  if (!prefix) {
    return label || ''
  }

  return `${prefix} ${label}`
}

export const COVERAGE_TYPE_OPTIONS: OptionTypeBase<Coverage_CoverageType>[] = [
  { value: Coverage_CoverageType.COVERAGE_TYPE_MEDICAL, label: 'Medical' },
  { value: Coverage_CoverageType.COVERAGE_TYPE_DENTAL, label: 'Dental' },
  { value: Coverage_CoverageType.COVERAGE_TYPE_PHARMACY, label: 'Pharmacy' },
]

function assignIndexToCoverages(coverages: Coverage[] | undefined) {
  if (!coverages) return []

  return coverages.map((coverage, index) => ({
    ...coverage,
    index,
  }))
}

export function getAllCoveragesByType(person: Person) {
  const indexedCoverages = assignIndexToCoverages(person.coverage)
  return [
    Coverage_CoverageType.COVERAGE_TYPE_MEDICAL,
    Coverage_CoverageType.COVERAGE_TYPE_DENTAL,
    Coverage_CoverageType.COVERAGE_TYPE_PHARMACY,
  ].map((type) => {
    return indexedCoverages.filter((coverage) => coverage.type === type)
  })
}

export async function upsertCoverage({
  data,
  person,
}: {
  data: FormData
  person: Person
}) {
  const { index } = data
  const candidate: Coverage = {
    type: data.type,
    name: data.provider,
    subscriberId: data.memberId,
    plan: {
      value: data.groupNumber,
    },
  }

  if (
    data.type === Coverage_CoverageType.COVERAGE_TYPE_PHARMACY &&
    data.rxbin &&
    data.rxpcn
  ) {
    candidate.rxbin = {
      value: data.rxbin,
    }
    candidate.rxpcn = {
      value: data.rxpcn,
    }
  }

  const newCoverageArray = cloneDeep(person.coverage || [])

  if (index === -1) {
    newCoverageArray.push(candidate)
  } else {
    newCoverageArray[index] = candidate
  }

  return await typedMergePatchPerson({
    fId: person.facilityId!,
    orgId: person.orgId!,
    pId: person.id!,
    fields: ['coverage'],
    patch: {
      coverage: newCoverageArray,
    },
  })
}

export function deleteCoverage({
  person,
  index,
}: {
  person: Person
  index: number
}) {
  const newCoverageArray = cloneDeep(person.coverage || [])
  newCoverageArray.splice(index, 1)

  return typedMergePatchPerson({
    fId: person.facilityId!,
    orgId: person.orgId!,
    pId: person.id!,
    fields: ['coverage'],
    patch: {
      coverage: newCoverageArray,
    },
  })
}
