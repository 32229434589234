import {
  Group,
  GroupType,
} from '@augusthealth/models/com/august/protos/permission'
import { detailsPathForPerson } from '@shared/legacy_routes'
import { ResidentStatus } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { isSuperUser } from '@shared/utils/user'

/**
 * Determines where a user should be routed to given an orgId
 * Used for users with multiple groups, who have access to /orgs
 * @param user
 * @param orgId
 */
export default function rootRouteForUser(user: UserAccount, orgId: string) {
  if (isSuperUser(user)) {
    return facilitiesRoute(user, orgId)
  }

  const groups = user.groups ?? []
  const relevantGroup = groups.find(
    (group) =>
      group.personMatcher?.organizationId === orgId &&
      group.groupType !== GroupType.GROUP_TYPE_DEMO_USER
  )

  if (relevantGroup === undefined) {
    throw new Error(`User ${user.id} is not a member of org ${orgId}`)
  }

  switch (relevantGroup.groupType!) {
    case GroupType.GROUP_TYPE_NURSE:
    case GroupType.GROUP_TYPE_FACILITY_ADMIN:
    case GroupType.GROUP_TYPE_FACILITY_DIRECTOR:
    case GroupType.GROUP_TYPE_MED_TECH:
    case GroupType.GROUP_TYPE_MED_TECH_SUPERVISOR:
    case GroupType.GROUP_TYPE_BILLING:
    case GroupType.GROUP_TYPE_PHARMACIST:
    case GroupType.GROUP_TYPE_FACILITY_STAFF:
    case GroupType.GROUP_TYPE_FACILITY_STAFF_PLUS_EMAR:
    case GroupType.GROUP_TYPE_FACILITY_SALES_AND_MARKETING:
    case GroupType.GROUP_TYPE_FRONT_DESK:
      return facilityRoute(user, orgId)
    case GroupType.GROUP_TYPE_SOCIAL_WORKER:
      return personDetailsRoute(user, orgId)
    case GroupType.GROUP_TYPE_RESPONSIBLE_PARTY:
      return prospectRoute(user, orgId)
    case GroupType.GROUP_TYPE_ORGANIZATION_ADMIN:
      return facilitiesRoute(user, orgId)
    default:
      throw new Error(`No matching groupType for ${relevantGroup.groupType}`)
  }
}

export function personDetailsRoute(user: UserAccount, orgId?: string) {
  const group = user.groups?.find(
    (g) =>
      matchesOrgId(g, orgId) &&
      g.groupType === GroupType.GROUP_TYPE_SOCIAL_WORKER
  )

  if (group) {
    return detailsPathForPerson({
      id: group.personMatcher?.personId || '',
      facilityId: group.personMatcher?.facilityId || '',
      orgId: group.personMatcher?.organizationId || '',
      residentStatus: ResidentStatus.RESIDENT_STATUS_PROSPECT,
    })
  }

  throw new Error('No route matches for user!')
}

export function facilityRoute(user: UserAccount, orgId?: string) {
  const group = user.groups?.find(
    (g) =>
      matchesOrgId(g, orgId) &&
      (g.groupType === GroupType.GROUP_TYPE_FACILITY_ADMIN ||
        g.groupType === GroupType.GROUP_TYPE_FACILITY_STAFF_PLUS_EMAR ||
        g.groupType === GroupType.GROUP_TYPE_FACILITY_STAFF ||
        g.groupType === GroupType.GROUP_TYPE_FACILITY_SALES_AND_MARKETING ||
        g.groupType === GroupType.GROUP_TYPE_BILLING ||
        g.groupType === GroupType.GROUP_TYPE_NURSE ||
        g.groupType === GroupType.GROUP_TYPE_MED_TECH ||
        g.groupType === GroupType.GROUP_TYPE_MED_TECH_SUPERVISOR ||
        g.groupType === GroupType.GROUP_TYPE_FRONT_DESK ||
        g.groupType === GroupType.GROUP_TYPE_FACILITY_DIRECTOR ||
        g.groupType === GroupType.GROUP_TYPE_PHARMACIST)
  )

  if (group) {
    return `/orgs/${group.personMatcher!.organizationId}/facilities/${
      group.personMatcher!.facilityId
    }`
  }

  throw new Error('No route matches for user!')
}

export function prospectRoute(user: UserAccount, orgId?: string) {
  const group = user.groups?.find(
    (g) =>
      matchesOrgId(g, orgId) &&
      g.groupType === GroupType.GROUP_TYPE_RESPONSIBLE_PARTY
  )

  if (group) {
    return `/orgs/${group.personMatcher!.organizationId}/facilities/${
      group.personMatcher!.facilityId
    }/prospects/${group.personMatcher!.personId}`
  }

  throw new Error('No route matches for user!')
}

/**
 * Redirect user for first login or from login email after creation
 * @param user
 * @param orgId
 * @returns string
 */
export function externalSignerRoute(user: UserAccount, orgId?: string) {
  const group = user.groups?.find(
    (g) =>
      matchesOrgId(g, orgId) &&
      g.groupType === GroupType.GROUP_TYPE_EXTERNAL_CONTACT
  )

  if (group) {
    return `/orgs/${group.personMatcher!.organizationId}/facilities/${
      group.personMatcher!.facilityId
    }/person/${group.personMatcher!.personId}/signDocuments`
  }

  throw new Error('No route matches for user!')
}

export function facilitiesRoute(user: UserAccount, orgId?: string) {
  let orgIdToUse: string | undefined
  const isSuper = isSuperUser(user)

  if (isSuper) {
    orgIdToUse = orgId
  } else {
    const group = user.groups?.find(
      (g) =>
        matchesOrgId(g, orgId) &&
        g.groupType === GroupType.GROUP_TYPE_ORGANIZATION_ADMIN
    )
    orgIdToUse = group?.personMatcher?.organizationId
  }

  if (orgIdToUse) {
    return `/orgs/${orgIdToUse}/facilities`
  }

  throw new Error('No route matches for user!')
}

function matchesOrgId(g: Group, orgId?: string) {
  return orgId ? g.personMatcher!.organizationId === orgId : true
}
