import { EventTiming, Timing } from '@shared/types/timing'
import { fromTimeStringToTime } from '@shared/utils/time'

export type ScheduleValue = 'NoValue' | ScheduleSpecificTime | ScheduleTimeGroup

type ScheduleSpecificTime = {
  time: string
  name: 'Morning' | 'Afternoon' | 'Evening' | 'Bedtime' | 'Night'
  quantity: string
}

export type ScheduleTimeGroup = {
  timeGroup: TimeGroup
  quantity: string
}

export type TimeGroup = 'AM' | 'NN' | 'PM' | 'HS' | 'NOC'

export function isTimeGroup(input: string): input is TimeGroup {
  return ['AM', 'NN', 'PM', 'HS', 'NOC'].includes(input)
}

export function isSpecificTime(
  medSched: ScheduleSpecificTime | ScheduleTimeGroup
): medSched is ScheduleSpecificTime {
  // eslint-disable-next-line no-prototype-builtins
  return medSched.hasOwnProperty('time')
}

/**
 * Turns a ScheduleValue type into a subset of the Timing type.
 * See unit tests for examples.
 *
 * @param scheduleValue
 */
export function scheduleValueToTiming(
  scheduleValue: ScheduleValue
): Required<Pick<Timing, 'timeOfDay'>> {
  if (scheduleValue !== 'NoValue') {
    if (isSpecificTime(scheduleValue)) {
      return {
        timeOfDay: [
          {
            time: fromTimeStringToTime(scheduleValue.time),
            when: toEventTiming(scheduleValue.name),
          },
        ],
      }
    } else {
      return {
        timeOfDay: [
          {
            when: toEventTiming(scheduleValue.timeGroup),
          },
        ],
      }
    }
  }

  return {
    timeOfDay: [],
  }
}

function toEventTiming(
  value: TimeGroup | ScheduleSpecificTime['name']
): EventTiming {
  switch (value) {
    case 'AM':
    case 'Morning':
      return EventTiming.EVENT_TIMING_MORN
    case 'NN':
    case 'Afternoon':
      return EventTiming.EVENT_TIMING_AFT
    case 'PM':
    case 'Evening':
      return EventTiming.EVENT_TIMING_EVE
    case 'HS':
    case 'NOC':
    case 'Bedtime':
    case 'Night':
      return EventTiming.EVENT_TIMING_NIGHT
  }
}
