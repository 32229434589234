import { useContext, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, useForm } from 'react-hook-form'
import { mergePatchPerson } from '@shared/api/person'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { LabelAboveInput } from '@shared/components/Labels'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import {
  fromDateMessageToDate,
  fromDateToDateMessage,
} from '@shared/utils/date'
import { tw } from '@shared/utils/tailwind'
import usePerson, { usePersonInContext } from '@app/hooks/usePerson'

const FIELD_NAME = 'dischargeInformation.financialMoveOutDate'
const FIELDS = [
  'orgId',
  'facilityId',
  'id',
  'admissionsInformation/financialStartDate',
  'dischargeInformation/financialMoveOutDate',
]

export default function FinancialMoveOutDateModal({
  onClose,
  person,
}: {
  onClose: () => void
  person: Person
}) {
  const { setError } = useContext(GlobalContext)
  const { refreshPerson } = usePersonInContext({
    initialData: person,
    skipFirstFetch: true,
  })
  const { person: eTagPerson } = usePerson({
    initialData: person,
    fields: FIELDS,
  })

  useEffect(() => {
    if (eTagPerson.tag === 'Complete') {
      reset(eTagPerson.value)
    }
  }, [eTagPerson])

  const methods = useForm<Person>({
    defaultValues: person,
  })
  const { handleSubmit, control, formState, getValues, reset } = methods
  const financialStartDate = getValues(
    'admissionsInformation.financialStartDate'
  )
  const onSubmit = async (patch: Person) => {
    try {
      const { orgId, facilityId: fId, id: pId } = patch
      await mergePatchPerson({
        fId,
        pId,
        orgId,
        patch,
        fields: FIELDS,
      })
      await refreshPerson()
      onClose()
    } catch (e) {
      setError(e)
    }
  }

  return (
    <Modal
      open={true}
      id={'financial-move-out-date'}
      onClose={onClose}
      contentClassName={tw`w-[664px] min-w-[664px] max-w-[664px]`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name={FIELD_NAME}
          render={({ field: { onChange, value } }) => (
            <div className={tw`mb-[32px] [&_.react-datepicker-wrapper]:w-full`}>
              <LabelAboveInput htmlFor={FIELD_NAME} uppercase={false}>
                Financial effective end date
              </LabelAboveInput>
              <DatePicker
                name={FIELD_NAME}
                customInput={<CalendarInput width="100%" />}
                selected={fromDateMessageToDate(value)}
                onChange={(val: Date | null) => {
                  onChange(val ? fromDateToDateMessage(val) : null)
                }}
                minDate={
                  financialStartDate
                    ? fromDateMessageToDate(financialStartDate)
                    : undefined
                }
              />
            </div>
          )}
        />
        <AnimatedPopupFormFooter
          yesBtn={{
            props: {
              disabled: eTagPerson.tag !== 'Complete',
            },
          }}
          noBtn={{ action: onClose }}
          formState={formState}
        />
      </form>
    </Modal>
  )
}
