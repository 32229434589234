import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import {
  admissionTasksPathForPerson,
  billingPathForPerson,
  contactsPathForPerson,
  detailsPathForPerson,
  medicationsPathForPerson,
} from '@shared/legacy_routes'
import { GroupPermission } from '@shared/types/permission'
import { Person } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { isProspect, isProspectClosed } from '@shared/utils/person'

/**
 * Path for Prospect will check if we have permissions for Tasks tab first, then Details tab, then other accessible tab
 * Path for Resident will check if we have permissions for Details tab, then other accessible tab
 * @param person[Person]
 * @param user[UserAccount]
 * @returns
 */

export function getResidentPath({
  person,
  user,
}: {
  person: Person
  user: UserAccount
}) {
  const canAccessTasks = hasPermissionForPerson({
    user,
    person,
    permissions: [
      GroupPermission.GROUP_PERMISSION_PERSON_READ,
      GroupPermission.GROUP_PERMISSION_TASK_READ,
    ],
  })
  const canAccessDetails = hasPermissionForPerson({
    user,
    person,
    permissions: [
      GroupPermission.GROUP_PERMISSION_PERSON_READ,
      GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
      GroupPermission.GROUP_PERMISSION_SNAPSHOT_READ,
    ],
  })
  const canAccessMedications = hasPermissionForPerson({
    user,
    person,
    permissions: [
      GroupPermission.GROUP_PERMISSION_PERSON_READ,
      GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
      GroupPermission.GROUP_PERMISSION_MEDICATION_READ,
    ],
  })
  const canAccessBilling = hasPermissionForPerson({
    user,
    person,
    permissions: [
      GroupPermission.GROUP_PERMISSION_PERSON_READ,
      GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
      GroupPermission.GROUP_PERMISSION_BILLING_READ,
    ],
  })
  const canAccessContacts = hasPermissionForPerson({
    user,
    person,
    permissions: [
      GroupPermission.GROUP_PERMISSION_PERSON_READ,
      GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
      GroupPermission.GROUP_PERMISSION_PERSON_CONTACT_READ,
    ],
  })

  if ((isProspect(person) || isProspectClosed(person)) && canAccessTasks) {
    return admissionTasksPathForPerson(person)
  } else if (canAccessDetails) {
    return detailsPathForPerson(person)
  } else if (canAccessMedications) {
    return medicationsPathForPerson(person)
  } else if (canAccessBilling) {
    return billingPathForPerson(person)
  } else if (canAccessContacts) {
    return contactsPathForPerson(person)
  }

  return ''
}
