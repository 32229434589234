import Badge from '@shared/components/Badge'

export function RoutineBadge({
  icon,
  children,
  className,
}: {
  icon?: string
  children?: React.ReactNode
  className?: string
}) {
  if (children === null || children === undefined) {
    return null
  }

  return (
    <Badge
      className={`inline-block px-[8px] text-[14px] font-medium ${className}`}
      color={'darkGray'}
      badgeSize={'medium'}
      shape={'square'}
    >
      {icon && <i className={`fa ${icon ? icon : ''} mr-[8px]`} />}
      {children}
    </Badge>
  )
}
