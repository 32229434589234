import { Contact } from '@augusthealth/models/com/august/protos/contact'
import { useState } from 'react'
import { ROLE_RELATIONSHIP_MAP } from '@shared/constants/contactRelationship'
import { Person } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { getMainRole } from '@shared/utils/relationship'
import { usePersonInContext } from '@app/hooks/usePerson'
import { EditContactModal } from '@app/pages/Contacts/ContactModal/ContactModal'
import ContactActions from './ContactActions'
import ContactCard from './ContactCard'

export type Props = {
  contacts: Contact[]
  user: UserAccount
  person: Person
  refreshContacts: () => Promise<void>
}

export default function ContactsGrid({
  contacts,
  user,
  person,
  refreshContacts,
}: Props) {
  const [openEditContactModal, setOpenEditContactModal] =
    useState<Contact | null>(null)
  const { refreshPerson } = usePersonInContext({
    initialData: person,
    skipFirstFetch: true,
  })
  const alphabetizedContacts = contacts.sort(compareContacts)

  const { mainContacts, otherContacts } = alphabetizedContacts.reduce(
    (
      sortedContacts: {
        mainContacts: Contact[]
        otherContacts: Contact[]
      },
      contact
    ): {
      mainContacts: Contact[]
      otherContacts: Contact[]
    } => {
      const mainRole = getMainRole(contact.relationship!)

      if (
        mainRole ===
        ROLE_RELATIONSHIP_MAP.CONTACT_RELATIONSHIP_RESPONSIBLE_PERSON
      ) {
        // if contact is RP list them first
        sortedContacts.mainContacts.unshift(contact)
      } else if (
        mainRole ===
        ROLE_RELATIONSHIP_MAP.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER
      ) {
        // if Primary Care list them second
        sortedContacts.mainContacts.push(contact)
      } else {
        // everything else stays in order in a separate array for now
        sortedContacts.otherContacts.push(contact)
      }

      return sortedContacts
    },
    { mainContacts: [], otherContacts: [] }
  )

  // return list of <ContactCards> for combined array
  const cards = mainContacts.concat(otherContacts).map((contact, index) => (
    <ContactCard
      key={`${contact.id}-${index}`}
      contact={contact}
      gridIndex={index}
      onClick={() => {
        setOpenEditContactModal(contact)
      }}
    >
      <ContactActions
        user={user}
        contact={contact}
        person={person}
        refreshContacts={refreshContacts}
      />
    </ContactCard>
  ))

  return (
    <>
      {openEditContactModal && (
        <EditContactModal
          contact={openEditContactModal}
          person={person!}
          onClose={async (updated) => {
            if (updated) {
              await refreshContacts()
              await refreshPerson()
            }

            setOpenEditContactModal(null)
          }}
        />
      )}
      {cards}
    </>
  )
}

const compareContacts = (a, b) => {
  if (a.name.family < b.name.family) {
    return -1
  }
  if (a.name.family > b.name.family) {
    return 1
  }
  return 0
}
