import { useState } from 'react'
import Lightbox from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import { getCategoriesFromSnapshot } from '@shared/utils/residentAssessment'
import Print, { printSelectedElements } from '@app/components/Printable'
import { RoutableDocument } from '@app/pages/Documents/helpers'
import AssessmentPage from '@app/pages/Tasks/ResidentAssessment/AssessmentPage'
import { getOrderedCategories } from '@app/pages/Tasks/ResidentAssessment/helpers'
import { AssessmentViewerMode, getAssessmentType } from './helpers'
import { PrintableAssessment } from './PrintableAssessment'
import ServicePlanOverlay from './ServicePlanOverlay'
import { Sidebar } from './Sidebar'
import { UploadedAssessmentViewer } from './UploadedAssessmentViewer'

interface Props {
  person: Person
  assessment: Snapshot | RoutableDocument
  mode: AssessmentViewerMode
}

export default function AssessmentViewer({ person, assessment, mode }: Props) {
  const [printLoC, setPrintLoC] = useState(false)
  const [showServicePlanOverlay, setShowServicePlanOverlay] = useState(false)
  const categories = getCategoriesFromSnapshot(assessment) || []
  const assessmentType = getAssessmentType(assessment, person)

  const onCloseButtonClick =
    mode.tag === 'view' ? mode.onClose : mode.onMakeChanges
  if (assessmentType.tag === 'External' && assessmentType.fileUrl) {
    return (
      <UploadedAssessmentViewer
        assessment={assessment}
        person={person}
        mode={mode}
        pdfPath={assessmentType.fileUrl}
        onClose={onCloseButtonClick}
      />
    )
  }

  return (
    <>
      <Lightbox
        mainContent={
          <div className="flex flex-col text-secondary-01">
            {assessmentType.tag === 'Internal' && (
              <AssessmentPage
                showAdmissionTypeAndAssessmentReason={true}
                mode={mode}
                person={person}
                snapshot={assessment}
                categories={getOrderedCategories({ categories })}
              />
            )}
            {assessmentType.tag === 'External' &&
              'This assessment was conducted outside of August Health.'}
          </div>
        }
        sidebarContent={
          <>
            <Sidebar
              setShowServicePlanOverlay={setShowServicePlanOverlay}
              assessment={assessment}
              mode={mode}
              person={person}
              onPrint={() => printSelectedElements(setPrintLoC)}
            />
            {mode.tag === 'view' && (
              <AsyncIconButton
                buttonStyle={'secondary-outline'}
                onClick={mode.onClose}
              >
                Close
              </AsyncIconButton>
            )}
          </>
        }
        onClose={onCloseButtonClick}
        mainContentBackgroundColor="white"
      >
        {showServicePlanOverlay && mode.tag === 'review' && (
          <ServicePlanOverlay
            person={person}
            doneFn={mode.onSubmit}
            setShowServicePlanOverlay={setShowServicePlanOverlay}
          />
        )}
      </Lightbox>
      {printLoC && (
        <Print>
          <PrintableAssessment
            assessment={assessment}
            categories={categories}
            person={person}
          />
        </Print>
      )}
    </>
  )
}
