import { sortBy } from 'lodash'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import SearchBox from '@shared/components/SearchBox'
import { isNotEmpty } from '@shared/utils/common'
import { tw } from '@shared/utils/tailwind'
import {
  DownloadMenu,
  DownloadMenuButtonProps,
  MenuItem,
} from '@app/components/DownloadMenu'

type BtnProps = {
  label: string
  onClick: () => void
  order?: number
}

type DownloadMenuProps = {
  menuItems: MenuItem[]
  buttonProps: DownloadMenuButtonProps
  order?: number
}

type SearchBoxProps = {
  searchTerm: string
  setSearchTerm: (searchText: string) => void
  searchPlaceholder?: string
  order?: number
}

export default function TitleActions({
  name = 'title-action',
  addBtnProps,
  downloadMenuProps,
  searchBoxProps,
}: {
  name?: string
  addBtnProps?: BtnProps
  downloadMenuProps?: DownloadMenuProps
  searchBoxProps?: SearchBoxProps
}) {
  const items = sortBy(
    [
      searchBoxProps && {
        ui: (
          <SearchBox
            key={`${name}-search-box`}
            onChange={searchBoxProps.setSearchTerm}
            placeholder={searchBoxProps.searchPlaceholder}
            value={searchBoxProps.searchTerm}
          />
        ),
        order: searchBoxProps.order !== undefined ? searchBoxProps.order : 1,
      },
      downloadMenuProps && {
        ui: (
          <DownloadMenu
            key={`${name}-download-menu`}
            menuItems={downloadMenuProps.menuItems}
            menuProps={{
              buttonProps: downloadMenuProps.buttonProps,
            }}
          />
        ),
        order:
          downloadMenuProps.order !== undefined ? downloadMenuProps.order : 2,
      },
      addBtnProps && {
        ui: (
          <AsyncIconButton
            key={`${name}-add-btn`}
            buttonStyle="primary-fill"
            isLoading={false}
            initialIcon="plus"
            onClick={addBtnProps.onClick}
          >
            {addBtnProps.label}
          </AsyncIconButton>
        ),
        order: addBtnProps.order !== undefined ? addBtnProps.order : 3,
      },
    ].filter(isNotEmpty),
    ['order']
  ).map((item) => item?.ui)

  return <div className={tw`flex gap-2`}>{items}</div>
}
