import { useContext, useEffect, useState } from 'react'
import { fetchResidentBillingTransactions } from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import { BillingTransaction } from '@shared/types/billing'
import { RequiredPersonIds } from '@shared/types/person'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

export default function useBillingTransactions(props: RequiredPersonIds) {
  const { orgId, facilityId, id: personId } = props
  const { setError } = useContext(GlobalContext)
  const [billingTransactions, setBillingTransactions] =
    useState<Loading<BillingTransaction[]>>(LOADING)

  async function reloadBillingTransactions() {
    setBillingTransactions(LOADING)
    try {
      const transactions = await fetchResidentBillingTransactions(props)
      setBillingTransactions(loaded(transactions))
    } catch (e) {
      setError(e)
      setBillingTransactions(loaded([]))
    }
  }

  useEffect(() => {
    void reloadBillingTransactions()
  }, [orgId, facilityId, personId])

  return { billingTransactions, reloadBillingTransactions }
}
