import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useEffect, useState } from 'react'
import { fetchFacility } from '@shared/api/facility'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { Facility } from '@shared/types/facility'
import { Person as NewPerson } from '@shared/types/person'
import { DataType, SnapshotStatus } from '@shared/types/snapshot'
import { mapLoading } from '@shared/utils/loading'
import { getPrimaryPhysician } from '@shared/utils/person'
import { incompleteTask } from '@shared/utils/task'
import { downloadFaceSheetPdf } from '@app/api/facesheet'
import Diet from '@app/components/Diet'
import PersonPageTitle from '@app/components/PersonPageTitle'
import useCurrentPOLST from '@app/hooks/useCurrentPOLST'
import { usePersonInContext } from '@app/hooks/usePerson'
import useSnapshots from '@app/hooks/useSnapshots'
import { useTasks } from '@app/hooks/useTasks'
import GeneralUploader from '@app/pages/Documents/Uploader'
import styles from './styles.module.css'
import AdmissionDetails from './AdmissionDetails/AdmissionDetails'
import CareHighlights from './CareHighlights'
import DiagnosesAndAllergies from './Diagnoses'
import EmergencyPacketModal from './EmergencyPacket'
import Insurance from './Insurance'
import PreferredHospital from './PreferredHospital'
import PrimaryCarePhysician from './PrimaryPhysician'
import Vaccinations from './Vaccinations'

export default function Details(props: { match: any }) {
  const { match } = props
  const { facilityId, id, orgId } = match.params
  const { user } = useUserContext()
  const { setError } = useContext(GlobalContext)
  const [facility, setFacility] = useState<Facility>()
  const [openEmergencyPacketModal, setOpenEmergencyPacketModal] =
    useState(false)
  const [uploaderDataType, setUploaderDataType] = useState<DataType>()
  const [loadingFaceSheet, setLoadingFaceSheet] = useState(false)
  const { person, refreshPerson } = usePersonInContext({
    initialData: match.params,
  })
  const { refreshTasks, tasks } = useTasks(true)

  const currentPOLST = useCurrentPOLST()
  const incompletePolstTask = incompleteTask(tasks, DataType.DATA_TYPE_POLST)
  const levelOfCareTask = tasks?.find(
    (task) =>
      task.taskTemplateInfo?.dataType === 'DATA_TYPE_LEVEL_OF_CARE_CIMINO'
  )
  const { snapshots: serviceAppraisals } = useSnapshots({
    dataType: DataType.DATA_TYPE_AUGUST_INITIAL_APPRAISAL,
    status: SnapshotStatus.SNAPSHOT_STATUS_COMPLETE,
  })

  const { snapshots: insuranceCard, refreshSnapshots } = useSnapshots(
    {
      dataType: DataType.DATA_TYPE_INSURANCE_CARD,
      status: SnapshotStatus.SNAPSHOT_STATUS_COMPLETE,
    },
    [person?.id]
  )

  useEffect(() => {
    fetchFacility({ orgId, fId: facilityId })
      .then((fs) => setFacility(fs.data))
      .catch(setError)
  }, [id, orgId, facilityId])

  if (person === undefined || person.prospectInfo === undefined) {
    return null
  }

  const physician = getPrimaryPhysician(person)

  return (
    <>
      <PersonPageTitle title={'Details'}>
        <PersonPermissionGate
          person={person}
          permissions={[GroupPermission.GROUP_PERMISSION_MAR_READ]}
        >
          <AsyncIconButton
            className="mr-[12px]"
            buttonStyle="secondary-fill"
            buttonSize="medium"
            initialIcon="fa-siren-on"
            onClick={() => {
              setOpenEmergencyPacketModal(true)
            }}
          >
            Download Emergency Packet
          </AsyncIconButton>
        </PersonPermissionGate>
        <PersonPermissionGate
          person={person}
          permissions={[GroupPermission.GROUP_PERMISSION_FACESHEET_READ]}
        >
          <AsyncIconButton
            isLoading={loadingFaceSheet}
            disabled={!person || !facility || loadingFaceSheet}
            buttonStyle="secondary-outline"
            buttonSize="medium"
            initialIcon="fa-print"
            onClick={async () => {
              setLoadingFaceSheet(true)

              try {
                await downloadFaceSheetPdf(person)
                setLoadingFaceSheet(false)
              } catch (e) {
                setError(e)
              }
            }}
          >
            Print Face Sheet
          </AsyncIconButton>
        </PersonPermissionGate>
      </PersonPageTitle>
      {openEmergencyPacketModal && (
        <EmergencyPacketModal
          setUploaderDataType={setUploaderDataType}
          hasCurrentPOLST={currentPOLST}
          person={person}
          hasInsuranceCard={mapLoading(insuranceCard, (c) => c.length > 0)}
          onClose={() => {
            setOpenEmergencyPacketModal(false)
          }}
        />
      )}
      {uploaderDataType && (
        <GeneralUploader
          person={person}
          initialType={uploaderDataType}
          tasks={incompletePolstTask ? [incompletePolstTask] : []}
          onUpdate={async () => {
            if (uploaderDataType === DataType.DATA_TYPE_POLST) {
              await refreshPerson()
            } else if (uploaderDataType === DataType.DATA_TYPE_INSURANCE_CARD) {
              await refreshSnapshots()
            }
          }}
          onClose={() => setUploaderDataType(undefined)}
        />
      )}
      <div className={styles.detailsContainer}>
        <div>
          <AdmissionDetails person={person} facility={facility!} />
          <DiagnosesAndAllergies person={person} updatePerson={refreshPerson} />
          <Diet person={person} updatePerson={refreshPerson} />
          {physician && (
            <PrimaryCarePhysician
              physician={physician}
              person={person}
              updatePerson={refreshPerson}
            />
          )}
          <PreferredHospital person={person} updatePerson={refreshPerson} />
        </div>
        <div>
          <CareHighlights
            currentPOLST={currentPOLST}
            levelOfCareTask={levelOfCareTask}
            serviceAppraisals={
              serviceAppraisals.tag === 'Complete'
                ? serviceAppraisals.value
                : []
            }
            person={person}
            incompletePolstTask={incompletePolstTask}
            onUpdate={async (toUpdate) => {
              if (['Person', 'Both'].includes(toUpdate)) {
                await refreshPerson()
              }
              if (['Task', 'Both'].includes(toUpdate)) {
                await refreshTasks()
              }
            }}
          />
          <Vaccinations person={person} />
          <Insurance
            person={person as NewPerson}
            onInsuranceChange={refreshPerson}
            user={user}
          />
        </div>
      </div>
    </>
  )
}
