import { OrgFacilitySettings } from '@augusthealth/models/com/august/protos/org_facility_settings'
import { SignableFormAssignment } from '@augusthealth/models/com/august/protos/signable_form'
import { Facility } from '@shared/types/facility'
import { GroupPermission } from '@shared/types/permission'
import { DataType } from '@shared/types/snapshot'
import { UserAccount } from '@shared/types/user'
import { SettingsLevel } from '@shared/utils/orgFacilitySettings'
import { hasPermissions } from '@shared/utils/permisson'
import {
  expandStateAbbreviation,
  StateAbbreviation,
  StateName,
} from '@shared/utils/state'
import { TaskSuppressionArgs } from '@shared/utils/task'
import { isSuperUser } from '@shared/utils/user'
import { createFormAssignment, updateFormAssignment } from '@app/api/form'
import { upsertSettings } from '@app/api/orgFacilitySettings'

export async function updateOrCreateAssignment({
  signableFormId,
  assignment,
  facilityId,
  dataType,
  customType,
  orgId,
  state,
}: {
  signableFormId: string
  assignment: SignableFormAssignment | undefined
  facilityId?: string
  dataType: DataType
  customType?: string
  orgId?: string
  state?: string
}) {
  if (assignment?.id) {
    return updateFormAssignment({
      id: assignment.id,
      facilityId,
      dataType,
      customType,
      orgId,
      signableFormId,
      state,
    })
  } else {
    return createFormAssignment({
      facilityId,
      dataType,
      customType,
      orgId,
      signableFormId,
      state,
    })
  }
}

const buildArgsForSuppression = ({
  desiredSuppression,
  cardLevel,
}: {
  desiredSuppression: SettingsLevel
  cardLevel: CardLevel
}): TaskSuppressionArgs => {
  if (desiredSuppression === SettingsLevel.FACILITY_LEVEL) {
    return {
      facilityId: cardLevel.facilityId!,
      orgId: cardLevel.orgId!,
      state: null,
    }
  } else if (desiredSuppression === SettingsLevel.ORG_LEVEL) {
    return {
      facilityId: null,
      orgId: cardLevel.orgId!,
      state: null,
    }
  } else {
    throw new Error(
      `Attempt to suppress a task at ${desiredSuppression} is not allowed`
    )
  }
}

export const suppressTaskForMultipleStates = ({
  statesToSuppress,
  taskSettings,
}: {
  statesToSuppress: Array<StateName | StateAbbreviation>
  taskSettings: OrgFacilitySettings
}): Promise<void[]> => {
  const allStatePromises = statesToSuppress.map((state) => {
    const newSettings = {
      key: taskSettings.key,
      settingsType: taskSettings.settingsType,
      state: state,
      settings: {
        taskDefinition: {},
      },
    }
    return upsertSettings(newSettings)
  })
  return Promise.all(allStatePromises)
}

export const suppressSingleTask = ({
  desiredSuppression,
  taskSettings,
  cardLevel,
}: {
  desiredSuppression: SettingsLevel
  cardLevel: CardLevel
  taskSettings: OrgFacilitySettings
}): Promise<void | void[]> => {
  const settings: TaskSuppressionArgs = buildArgsForSuppression({
    desiredSuppression,
    cardLevel,
  })

  // @ts-expect-error
  const updatedTaskSetting: OrgFacilitySettings = {
    ...settings,
    key: taskSettings.key,
    settingsType: taskSettings.settingsType,
    settings: {
      taskDefinition: {},
    },
  }

  return upsertSettings(updatedTaskSetting)
}

export const getRelevantState = ({
  facility,
  taskSettings,
}: {
  facility: Facility
  taskSettings: OrgFacilitySettings
}): StateName | null => {
  let state: StateAbbreviation | undefined

  if (facility.address?.state) {
    state = facility.address.state as StateAbbreviation
  } else if (taskSettings.state) {
    state = taskSettings.state as StateAbbreviation
  }

  if (!state) {
    console.error('cannot set a default state based on current data')
    return null
  } else if (state.length === 2) {
    return state as StateName
  }
  return expandStateAbbreviation(state) as StateName
}

/**
 * Task cards are rendered at a specific 'level' (global/state/etc.)
 * This type gives a name to the level and tells you which IDs you should
 * expect to be present at that level. For example, at the Facility level,
 * we expect orgId and facilityId, but no state.
 */
export type CardLevel =
  | {
      tag: 'Global'
      state?: undefined
      orgId?: undefined
      facilityId?: undefined
    }
  | { tag: 'State'; state: string; orgId?: undefined; facilityId?: undefined }
  | { tag: 'State/Org'; state: string; orgId: string; facilityId?: undefined }
  | {
      tag: 'Organization'
      state?: undefined
      orgId: string
      facilityId?: undefined
    }
  | { tag: 'Facility'; state?: undefined; orgId: string; facilityId: string }

export type SuppressMode =
  | {
      tag: 'enabled'
      onSuppress: () => Promise<void>
    }
  | { tag: 'disabled' }

/**
 * Only Super User can assign Global and State assignment
 */
export function canAssign({
  user,
  cardLevel,
}: {
  user: UserAccount
  cardLevel: CardLevel
}) {
  if (isSuperUser(user)) {
    return true
  }

  if (
    cardLevel.tag === 'Facility' ||
    cardLevel.tag === 'Organization' ||
    cardLevel.tag === 'State/Org'
  ) {
    return hasPermissions({
      user,
      permissions: [
        GroupPermission.GROUP_PERMISSION_TOOL_FORM_ASSIGNMENT_READ,
        GroupPermission.GROUP_PERMISSION_TOOL_FORM_ASSIGNMENT_CREATE,
        GroupPermission.GROUP_PERMISSION_TOOL_FORM_ASSIGNMENT_DELETE,
        GroupPermission.GROUP_PERMISSION_TOOL_FORM_ASSIGNMENT_UPDATE,
      ],
    })
  }

  return false
}
