import { FunctionComponent } from 'react'
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabelProps,
  VictoryLegend,
  VictoryScatter,
  VictoryThemeDefinition,
  VictoryTooltip,
} from 'victory'
import {
  ChartProps,
  ChartSize,
  getAreaChartStyles,
  getColorByIndex,
} from '../helpers'
import { AREA_COLORS, getDefaultTheme, STROKE_COLORS } from '../theme'
import MonthXTickLabel, { TickLabelProps } from './MonthXTickLabel'
import YTickLabel from './YTickLabel'

export type Props = ChartProps & {
  XTickLabel?: FunctionComponent<VictoryLabelProps | TickLabelProps>
}

export default function AreaChart({
  id,
  categories,
  data = [],
  size = ChartSize.FULL,
  domain,
  xAxisTitle = '',
  yAxisTitle = '',
  chartStyles = {},
  XTickLabel = MonthXTickLabel,
  victoryLegendProps,
}: Props) {
  const defaultStyles = getAreaChartStyles({
    chartSize: size,
    hasLegend: Boolean(victoryLegendProps),
  })
  const {
    fontSize,
    height,
    padding,
    scatterSize,
    flyoutPadding,
    flyoutStyle,
    flyoutWidth,
    pointerLength,
    yAxisLabelPadding,
    strokeWidth,
    tickLabelsFontSize,
    tickLabelsPadding,
  } = { ...defaultStyles, ...chartStyles }
  const theme = getDefaultTheme({
    fontSize,
    showGrid: true,
    tickLabelsFontSize,
    tickLabelsPadding,
  }) as VictoryThemeDefinition

  return (
    <VictoryChart
      theme={theme} // for Grid's style
      height={height}
      padding={padding}
      domain={domain}
    >
      {categories && (
        <VictoryAxis
          label={xAxisTitle}
          tickLabelComponent={<XTickLabel categories={categories} />}
          orientation="bottom"
        />
      )}
      <VictoryAxis
        dependentAxis
        label={yAxisTitle}
        style={{
          axisLabel: { padding: yAxisLabelPadding, textTransform: 'uppercase' },
        }}
        tickLabelComponent={<YTickLabel />}
      />
      {data.map((d, i) => {
        return (
          <VictoryArea
            key={`area-${id}-${i}`}
            data={d}
            interpolation="monotoneX"
            style={{
              data: {
                strokeWidth,
                fill: getColorByIndex(AREA_COLORS, i),
                stroke: getColorByIndex(STROKE_COLORS, i),
              },
            }}
            categories={categories ? { x: categories } : undefined}
          />
        )
      })}
      {data.map((d, i) => {
        return (
          <VictoryScatter
            key={`scatter-${id}-${i}`}
            data={d}
            size={scatterSize}
            style={{
              data: {
                strokeWidth,
                fill: 'white',
                stroke: getColorByIndex(STROKE_COLORS, i),
              },
            }}
            labelComponent={
              <VictoryTooltip
                flyoutPadding={flyoutPadding}
                flyoutWidth={flyoutWidth}
                pointerLength={pointerLength}
                style={flyoutStyle}
              />
            }
            labels={({ datum }) => Math.round(datum.y)}
          />
        )
      })}
      {victoryLegendProps && <VictoryLegend {...victoryLegendProps} />}
    </VictoryChart>
  )
}
