import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import { BillingCharge } from '@shared/types/billing'
import {
  billingChargeIsEnded,
  BillingModalType,
  formatCurrencyForBilling,
} from '@shared/utils/billing'
import { convertISODateStringToLabel } from '@shared/utils/date'
import { tw, twx } from '@shared/utils/tailwind'
import { TdWithExtraPadding, TrWithHoverEffect } from '@app/components/Table'

export default function ChargeRow({
  billingCharge,
  openChargeModal,
}: {
  billingCharge: BillingCharge
  openChargeModal: (charge: BillingCharge, modalType: BillingModalType) => void
}) {
  const { amountCents, name, startDate, endDate } = billingCharge.data
  const hasEndDate = Boolean(endDate)
  const isEnded = billingChargeIsEnded(billingCharge)

  return (
    <TrWithHoverEffect
      className={twx({ 'opacity-50': isEnded })}
      disableHoverEffect
    >
      <TdWithExtraPadding>{name}</TdWithExtraPadding>
      <TdWithExtraPadding>
        {formatCurrencyForBilling(amountCents)}
      </TdWithExtraPadding>
      <TdWithExtraPadding>
        {convertISODateStringToLabel(startDate)}
      </TdWithExtraPadding>
      <TdWithExtraPadding>
        {endDate ? convertISODateStringToLabel(endDate) : ''}
      </TdWithExtraPadding>
      <TdWithExtraPadding className={tw`whitespace-nowrap text-right`}>
        {!isEnded && (
          <>
            <Button
              buttonStyle="secondary-outline"
              buttonSize="xsmall"
              className={tw`mr-[16px]`}
              initialIcon="pen"
              onClick={() => openChargeModal(billingCharge, 'EDIT_CHARGE')}
            >
              Update
            </Button>
            <Button
              buttonStyle="secondary-outline"
              buttonSize="xsmall"
              className={tw`mr-[16px]`}
              initialIcon="ban"
              onClick={() => openChargeModal(billingCharge, 'END_CHARGE')}
              disabled={hasEndDate}
            >
              End
            </Button>
            <Button
              buttonStyle="secondary-outline"
              buttonSize="xsmall"
              initialIcon="trash"
              onClick={() =>
                openChargeModal(billingCharge, 'DELETE_CHARGE_OR_CREDIT')
              }
            >
              Cancel
            </Button>
          </>
        )}
      </TdWithExtraPadding>
    </TrWithHoverEffect>
  )
}
