import { tw, twx } from '@shared/utils/tailwind'

export default function Button({
  text,
  className,
  icon,
  state,
  disabled,
}: {
  text: string
  className?: string
  icon?: string
  state: 'engaged' | 'disengaged' | 'default'
  disabled: boolean
}) {
  return (
    <button
      disabled={disabled}
      className={twx(
        className,
        'disabled:cursor-initial h-[48px] w-[360px] rounded-[8px] font-inter text-[14px] font-semibold uppercase leading-[16px] transition-all hover:brightness-90 disabled:opacity-50',
        {
          'border-0 bg-august-primary text-white':
            state === 'engaged' || state === 'default',
          'border-2 border-secondary-06 bg-background-login':
            state === 'disengaged',
        }
      )}
      value="Login to August Health"
      type="submit"
    >
      {icon && <i className={tw`fa fa-fw fa-${icon} mr-[8px]`} />}
      {text}
    </button>
  )
}
