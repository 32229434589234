import { Dropdown } from '@augusthealth/august-frontend-form-elements'
import PropTypes from 'prop-types'
import {
  NO_ROLE_RELATIONSHIP_MAP,
  PRIMARY_CARE_ROLES,
  RELATIONSHIPS_BY_CATEGORY_MAP,
  RELATIONSHIPS_HASH_BY_CATEGORY_MAP,
} from '@shared/constants/contactRelationship'
import { relationshipName } from '@shared/utils/contact'

const CATEGORY_LIST = Object.keys(RELATIONSHIPS_BY_CATEGORY_MAP)
const RELATIONSHIP_LIST = Object.keys(NO_ROLE_RELATIONSHIP_MAP)

function RelationshipDropdown(props) {
  const { onUpdate, value, isAddNewPrimaryPhysicianPopup } = props
  let predefinedOptions = []
  let relationshipsByCategory = []
  if (Array.isArray(value)) {
    const category = value.find((v) => CATEGORY_LIST.includes(v))
    if (isAddNewPrimaryPhysicianPopup) {
      predefinedOptions = PRIMARY_CARE_ROLES.map((r) => ({
        label: relationshipName(r),
        value: r,
      }))
    } else if (category) {
      relationshipsByCategory = RELATIONSHIPS_BY_CATEGORY_MAP[category]
      if (Array.isArray(relationshipsByCategory)) {
        predefinedOptions = relationshipsByCategory.map((r) => {
          return {
            label: RELATIONSHIPS_HASH_BY_CATEGORY_MAP[category][r],
            value: r,
          }
        })
      }
    } else {
      // When category not found but no-category relationship found, display it
      const relationship = value.find((v) => RELATIONSHIP_LIST.includes(v))
      if (relationship) {
        predefinedOptions.push({
          label: NO_ROLE_RELATIONSHIP_MAP[relationship],
          value: relationship,
        })
      }
    }
  }

  const update = (val, name) => {
    if (typeof onUpdate === 'function') {
      let newRelationship = [...value]
      newRelationship = newRelationship.filter(
        (r) => !relationshipsByCategory.includes(r)
      )
      if (val) {
        newRelationship.push(val)
      }
      onUpdate(newRelationship, name)
    }
  }

  return <Dropdown {...props} options={predefinedOptions} onUpdate={update} />
}

RelationshipDropdown.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  onUpdate: PropTypes.func,
  options: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isAddNewPrimaryPhysicianPopup: PropTypes.bool,
}

RelationshipDropdown.defaultProps = {
  value: [],
}

export default RelationshipDropdown
