import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Controller, useFormContext } from 'react-hook-form'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import CollapsibleCard from '@shared/components/CollapsibleCard'
import {
  Label,
  LabelAboveInput,
  requiredWhenError,
} from '@shared/components/Labels'
import {
  buildExactTimeOptionsGroup,
  TimeSelect,
} from '@shared/components/TimeSelect'
import { MedicationOrder } from '@shared/types/medication_order'
import {
  formatTime,
  fromDateMessageToDate,
  fromDateToDateMessage,
  fromDateToTime,
} from '@shared/utils/date'
import { isDiscontinued, isRejected } from '@shared/utils/medicationStatement'
import { twx } from '@shared/utils/tailwind'
import { MedOrderFormData } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'
import { isCurrentlyOnHold } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderHoldForCard/helpers'
import HoldBadges from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderHoldForCard/HoldBadges'
import styles from '@app/components/Residents/Medications/Orders/styles.module.css'
import { RHFSwitch, SwitchSize } from '@app/components/Switch'

type Props = {
  use24HourTime: boolean
  readOnly?: boolean
  order?: MedicationOrder
}
export default function ReviewOrderHoldForCard(props: Props) {
  const { use24HourTime, order, readOnly = false } = props
  const { register, watch, control, setValue, formState } =
    useFormContext<MedOrderFormData>()

  const formData = watch()
  const today = new Date()

  const setValueOptions = { shouldDirty: true }

  const medStartDate = watch('effectivePeriod.startDate')
  const holdStartDate = watch('onHold.start.date') ?? undefined
  const holdStartTime = watch('onHold.start.time')
  const holdEndDate = watch('onHold.end.date') ?? undefined
  const holdEndTime = watch(`onHold.end.time`)
  const isDiscontinuedMed = order
    ? isDiscontinued(order.medicationStatement)
    : false
  const isRejectedMed = order ? isRejected(order) : false

  useEffect(() => {
    if (formData.onHold) {
      const isOnHold = isCurrentlyOnHold(formData, today)
      setOnHold(isOnHold)
    }
  }, [formData])

  const [onHold, setOnHold] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const disableInputs = readOnly || isDiscontinuedMed || isRejectedMed

  return (
    <CollapsibleCard
      data-testid={'hold-for-card'}
      enableAutoScrollOnExpand={true}
      defaultExpanded={onHold}
      expanded={expanded}
      onExpandClick={setExpanded}
      collapsedIcon={readOnly ? 'fa-lock text-secondary-07' : undefined}
      ignoreClickIds={['hold-container']}
      cardTitle={
        <div className={'flex flex-col'} data-testid={'hold-card'}>
          <div id={'hold-container'} className={styles.holdContainer}>
            <RHFSwitch
              switchSize={SwitchSize.small}
              disabled={disableInputs}
              checked={onHold}
              id={`med-on-hold`}
              name={'med-on-hold'}
              label={'On Hold'}
              color={'alert'}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const toBeHeld = event.target.checked
                if (toBeHeld) {
                  setValue(
                    'onHold.start.date',
                    fromDateToDateMessage(today),
                    setValueOptions
                  )

                  const todayAsTime = fromDateToTime(today)
                  setValue(
                    'onHold.start.time',
                    {
                      label: formatTime(todayAsTime, {
                        use24HourClock: use24HourTime,
                      })!,
                      value: todayAsTime,
                    },
                    setValueOptions
                  )
                  setOnHold(toBeHeld)

                  setExpanded(true)
                } else {
                  setValue('onHold.start.date', null, setValueOptions)
                  setValue('onHold.start.time', undefined, setValueOptions)
                  setValue('onHold.end.date', null, setValueOptions)
                  setValue('onHold.end.time', undefined, setValueOptions)
                  setValue('onHold.reason', undefined, setValueOptions)
                  setOnHold(toBeHeld)
                }
              }}
            />
          </div>
          {!expanded && <HoldBadges use24HourTime={use24HourTime} />}
        </div>
      }
    >
      <div className={twx('mb-[16px] flex items-center')}>
        <Label
          htmlFor={'onHold.start'}
          uppercase={false}
          className={twx('w-[64px]')}
        >
          Start
        </Label>
        <Controller
          control={control}
          name={`onHold.start.date`}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <Label visuallyHidden htmlFor={`onHold.start.date`}>
                {'On Hold Start Date'}
              </Label>
              <DatePicker
                showDisabledMonthNavigation
                minDate={fromDateMessageToDate(medStartDate)}
                maxDate={fromDateMessageToDate(holdEndDate)}
                disabled={disableInputs}
                onChange={(e: Date) => {
                  if (e) {
                    const date = fromDateToDateMessage(e)
                    onChange(date)

                    if (!holdStartTime) {
                      const todayAsTime = fromDateToTime(today)
                      setValue(
                        'onHold.start.time',
                        {
                          label: formatTime(todayAsTime, {
                            use24HourClock: use24HourTime,
                          })!,
                          value: todayAsTime,
                        },
                        setValueOptions
                      )
                    }
                  } else {
                    onChange(undefined)
                    setValue('onHold.start.time', undefined, setValueOptions)
                  }
                }}
                customInput={
                  <CalendarInput
                    showErrorBorder={!!error}
                    data-testid={`onHold.start.date`}
                    containerClassName={twx('mr-[8px]')}
                  />
                }
                id={`onHold.start.date`}
                selected={value ? fromDateMessageToDate(value) : null}
              />
            </>
          )}
        />
        {!!holdStartDate && (
          <Controller
            control={control}
            name={`onHold.start.time`}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              const name = `onHold.start.time`

              const disableAfterTime = isEqual(
                fromDateMessageToDate(holdStartDate),
                fromDateMessageToDate(holdEndDate)
              )
                ? holdEndTime?.value
                : undefined
              const options = [
                buildExactTimeOptionsGroup({
                  label: '',
                  interval: 60,
                  use24HourClock: use24HourTime,
                  disableAfterTime,
                }),
              ]
              return (
                <div className={'w-160'}>
                  <TimeSelect
                    label={'On Hold Start Time'}
                    disabled={disableInputs}
                    onChange={onChange}
                    options={options}
                    value={value}
                    data-testid={name}
                    name={name}
                    id={name}
                    showErrorBorder={!!error}
                  />
                </div>
              )
            }}
          />
        )}
      </div>

      <div className={'flex items-center'}>
        <Label
          htmlFor={'onHold.end'}
          uppercase={false}
          className={twx('w-[64px]')}
        >
          End
        </Label>
        <Controller
          control={control}
          name={`onHold.end.date`}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <Label visuallyHidden htmlFor={`onHold.end.date`}>
                {'On Hold End Date'}
              </Label>
              <DatePicker
                showDisabledMonthNavigation
                disabled={disableInputs}
                minDate={fromDateMessageToDate(holdStartDate)}
                onChange={(e: Date) => {
                  if (e) {
                    const date = fromDateToDateMessage(e)
                    onChange(date)

                    if (!holdEndTime) {
                      const todayAsTime = fromDateToTime(today)
                      setValue(
                        'onHold.end.time',
                        {
                          label: formatTime(todayAsTime, {
                            use24HourClock: use24HourTime,
                          })!,
                          value: todayAsTime,
                        },
                        setValueOptions
                      )
                    }
                  } else {
                    onChange(undefined)
                    setValue('onHold.end.time', undefined, setValueOptions)
                  }
                }}
                customInput={
                  <CalendarInput
                    showErrorBorder={!!error}
                    data-testid={`onHold.end.date`}
                    containerClassName={twx('mr-[8px]')}
                  />
                }
                id={'onHold.end.date'}
                selected={value ? fromDateMessageToDate(value) : null}
              />
            </>
          )}
        />
        {!!holdEndDate && (
          <Controller
            control={control}
            name={`onHold.end.time`}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              const name = `onHold.end.time`

              const disableBeforeTime = isEqual(
                fromDateMessageToDate(holdStartDate),
                fromDateMessageToDate(holdEndDate)
              )
                ? holdStartTime?.value
                : undefined

              const options = [
                buildExactTimeOptionsGroup({
                  hideLabel: true,
                  interval: 60,
                  use24HourClock: use24HourTime,
                  disableBeforeTime,
                }),
              ]

              return (
                <div className={'w-160'}>
                  <TimeSelect
                    label={'On Hold End Time'}
                    disabled={disableInputs}
                    onChange={onChange}
                    options={options}
                    value={value}
                    data-testid={name}
                    name={name}
                    id={name}
                    showErrorBorder={!!error}
                  />
                </div>
              )
            }}
          />
        )}
      </div>
      <LabelAboveInput
        className={twx('mb-[4px] ml-auto mt-[24px] w-fit')}
        subLabel={requiredWhenError(!!formState.errors.onHold?.reason)}
      />
      <BasicTextarea
        {...register('onHold.reason', { required: !!holdStartDate })}
        aria-required
        id="onHoldReason"
        placeholder="Explain the reason for the hold, e.g. doctors order..."
        showErrorBorder={!!formState.errors.onHold?.reason}
        className={twx(`w-full max-w-full`)}
        disabled={disableInputs}
      />
    </CollapsibleCard>
  )
}
