import { isAfter, isBefore } from 'date-fns'
import { ForwardedRef, forwardRef } from 'react'
import { StylesConfig } from 'react-select'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'
import { Label } from '@shared/components/Labels'
import {
  baseSelectControlStyles,
  errorBorderControlStyles,
  GroupedOptionTypeBase,
  IconStyledSelect,
  OptionTypeBase,
  selectStyles,
  StyledSelectProps,
} from '@shared/components/StyledSelect'
import { Time } from '@shared/types/date'
import { Shift, ShiftId, toShiftOption } from '@shared/types/shift'
import { EventTiming } from '@shared/types/timing'
import { formatTime } from '@shared/utils/date'
import { buildTimesByInterval, medPassEventTimings } from '@shared/utils/time'

export const TimeStyleConfig = (error: boolean): StylesConfig => {
  return {
    ...selectStyles(),
    control: (provided, state) => ({
      ...provided,
      ...baseSelectControlStyles('medium', state),
      ...errorBorderControlStyles(error, state),
      minWidth: '124px',
      width: 'fit-content',
      paddingLeft: 'var(--form-input-icon-left-padding)',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isDisabled
        ? 'var(--form-input-disabled-text-color)'
        : 'var(--gray-04)',
      whitespace: 'no-wrap',
      overflow: 'visible',
    }),
    valueContainer: (provided) => ({
      ...provided,
      overflow: 'visible',
      width: 'fit-content',
    }),
    menu: (base) => ({
      ...base,
      width: 'max-content',
      minWidth: '100%',
      zIndex: 9999,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menuList: (base) => ({ ...base, maxHeight: '240px' }),
  }
}

type TimeSelectProps<T> = Omit<
  StyledSelectProps,
  'onChange' | 'options' | 'id' | 'instanceId' | 'inputId' | 'data-testid'
> & {
  id: string
  label: string
  ref?: ForwardedRef<HTMLInputElement>
  disabled?: boolean
  showErrorBorder?: boolean
  options: OptionTypeBase<T>[] | GroupedOptionTypeBase<T>[]
  onChange: (e: OptionTypeBase<T>) => void
  value?: OptionTypeBase<T> | null
}

/**
 *
 * @param props.id - the ID of the dropdown
 * @param props.label = the label of the time select (hidden in the UI but used for testing)
 * @param props.disabled = set to true to disable the dropdown
 * @param props.showErrorBorder = set to true to show the error border on the dropdown
 * @param props.options = an array of generic options (grouped or standard) used to populate the dropdown
 * @param props.onChange = argument is the selected OptionTypeBase<T> that was selected
 * @param props.value = the selected OptionTypeBase<T> value
 */
export function TimeSelect<T>(props: TimeSelectProps<T>) {
  return <TimeSelectRef ref={props.ref} {...props} />
}

const TimeSelectRef = forwardRef(TimeSelectFn)

function TimeSelectFn<T>(
  props: TimeSelectProps<T>,
  ref: ForwardedRef<HTMLInputElement>
) {
  const {
    label,
    id,
    showErrorBorder = false,
    disabled,
    value,
    options,
    onChange,
    ...rest
  } = props

  return (
    <>
      <Label visuallyHidden htmlFor={id} id={`${id}-label`}>
        {label}
      </Label>
      <IconStyledSelect
        ref={ref}
        aria-labelledby={`${id}-label`}
        id={id}
        inputId={`input-${id}`}
        instanceId={id}
        data-testid={id}
        iconName={'fa-fw fa-solid fa-clock'}
        options={options}
        isDisabled={disabled}
        onChange={(e: OptionTypeBase<T>) => {
          onChange(e)
        }}
        value={value}
        placeholder={`--:--`}
        filterOption={(
          opt: FilterOptionOption<OptionTypeBase>,
          inputValue: string
        ) => {
          const partsToSearch = inputValue.toLowerCase().split(' ')
          if (partsToSearch[0].match(/^\d/)) {
            return partsToSearch.some((part) => {
              return !!(
                opt.label.toLowerCase().includes(part) ||
                opt.label.split(':').join('').includes(part) ||
                opt.label.split(' ').join('').includes(part)
              )
            })
          } else {
            return partsToSearch.some((part) => {
              return opt.label.toLowerCase().includes(part)
            })
          }
        }}
        styles={TimeStyleConfig(showErrorBorder)}
        menuPortalTarget={null}
        {...rest}
      />
    </>
  )
}

export function buildMedPassOptionsGroup(): GroupedOptionTypeBase<EventTiming> {
  return {
    label: 'Med Pass',
    options: medPassEventTimings,
  }
}

export function buildShiftOptionsGroup(
  shifts: Shift[],
  { use24HourClock }: { use24HourClock: boolean }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
): GroupedOptionTypeBase<ShiftId> {
  return {
    label: 'Shifts',
    options: shifts.map((shift) => toShiftOption(shift, { use24HourClock })),
  }
}

function isDisabled(
  time: Time,
  disabledBeforeTime?: Time,
  disabledAfterTime?: Time
) {
  const timeDate =
    time.hour !== undefined
      ? new Date().setHours(time.hour, time.minute)
      : undefined

  if (!timeDate) {
    return false
  }

  const isBeforeDisabledTime =
    disabledBeforeTime && disabledBeforeTime.hour !== undefined
      ? isBefore(
          timeDate,
          new Date().setHours(
            disabledBeforeTime.hour,
            disabledBeforeTime.minute
          )
        )
      : false

  const isAfterDisabledTime =
    disabledAfterTime && disabledAfterTime.hour !== undefined
      ? isAfter(
          timeDate,
          new Date().setHours(disabledAfterTime.hour, disabledAfterTime.minute)
        )
      : false

  return isBeforeDisabledTime || isAfterDisabledTime
}

/**
 * @param label
 * @param interval - the minute interval to determine what options to show. 30 for half hours, 60 for full hours, 1 for each minute
 * @param show24HourClock - bool to show 24 hour vs AM/PM times
 * @param disableBeforeTime - specify a time to disable all times that come before
 * @param disableAfterTime - specify a time to disable all times that come after
 */
export function buildExactTimeOptionsGroup({
  label = 'Exact time',
  hideLabel = false,
  interval = 30,
  use24HourClock,
  disableBeforeTime,
  disableAfterTime,
}: {
  hideLabel?: boolean
  label?: string
  interval?: number
  use24HourClock: boolean
  disableBeforeTime?: Time
  disableAfterTime?: Time
}): GroupedOptionTypeBase<Time> {
  const times = buildTimesByInterval(interval)
  const timeOptions: OptionTypeBase<Time>[] = times.map((time) => {
    return {
      label: formatTime(time, { use24HourClock }) || '',
      value: time,
      isDisabled: isDisabled(time, disableBeforeTime, disableAfterTime),
    }
  })

  return { label: hideLabel ? '' : label, options: timeOptions }
}
