import { Dropdown } from '@augusthealth/august-frontend-form-elements'
import PropTypes from 'prop-types'
import { RELATIONSHIPS_BY_CATEGORY_MAP } from '@shared/constants/contactRelationship'

const CATEGORY_LIST = Object.keys(RELATIONSHIPS_BY_CATEGORY_MAP)
const {
  CONTACT_RELATIONSHIP_PERSONAL,
  CONTACT_RELATIONSHIP_CLINICIAN,
  CONTACT_RELATIONSHIP_PROFESSIONAL,
} = RELATIONSHIPS_BY_CATEGORY_MAP
const MEDICAL_OPTION = {
  label: 'Medical',
  value: CATEGORY_LIST[1],
}
const CATEGORY_OPTIONS = [
  {
    label: 'Personal',
    value: CATEGORY_LIST[0],
  },
  MEDICAL_OPTION,
  {
    label: 'Professional',
    value: CATEGORY_LIST[2],
  },
]

function RelationshipCategoryDropdown(props) {
  const { onUpdate, value, isAddNewPrimaryPhysicianPopup } = props
  const update = (val, name) => {
    if (typeof onUpdate === 'function') {
      if (Array.isArray(value)) {
        let newRelationship = [...value]
        // reset relationship if not in the same category
        newRelationship = newRelationship.filter(
          (r) => !CATEGORY_LIST.includes(r)
        )
        if (val !== 'CONTACT_RELATIONSHIP_PERSONAL') {
          newRelationship = newRelationship.filter(
            (r) => !CONTACT_RELATIONSHIP_PERSONAL.includes(r)
          )
        }
        if (val !== 'CONTACT_RELATIONSHIP_CLINICIAN') {
          newRelationship = newRelationship.filter(
            (r) => !CONTACT_RELATIONSHIP_CLINICIAN.includes(r)
          )
        }
        if (val !== 'CONTACT_RELATIONSHIP_PROFESSIONAL') {
          newRelationship = newRelationship.filter(
            (r) => !CONTACT_RELATIONSHIP_PROFESSIONAL.includes(r)
          )
        }

        if (val) {
          newRelationship.push(val)
        }
        onUpdate(newRelationship, name)
      } else if (val) {
        onUpdate([val], name)
      } else {
        onUpdate(undefined, name)
      }
    }
  }
  const options = isAddNewPrimaryPhysicianPopup
    ? [MEDICAL_OPTION]
    : CATEGORY_OPTIONS
  return <Dropdown {...props} options={options} onUpdate={update} />
}

RelationshipCategoryDropdown.propTypes = {
  onUpdate: PropTypes.func,
  value: PropTypes.array,
  isAddNewPrimaryPhysicianPopup: PropTypes.bool,
}

export default RelationshipCategoryDropdown
