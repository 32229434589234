import { addDays, parseISO } from 'date-fns'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { updateBillingCharge } from '@shared/api/billing'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import GlobalContext from '@shared/contexts/GlobalContext'
import { BillingCharge } from '@shared/types/billing'
import { RequiredPersonIds } from '@shared/types/person'
import { tw } from '@shared/utils/tailwind'
import BaseMoneyInput from '@app/components/MoneyInput'
import MoneyInReactHookForm from '@app/components/MoneyInput/MoneyInReactHookForm'
import { convertCentsToDollars } from '@app/components/MoneyInput/utils'
import useBillingItems from '@app/hooks/useBillingItems'
import BillingModal from '@app/pages/Facilities/Billing/Modals/components/BillingModal'
import FeeDropdown from '../components/FeeDropdown'
import StartEndDates from '../components/StartEndDates'
import {
  BillingChargeFormData,
  convertChargeDataToFormData,
  convertFormDataToChargeData,
  ModalProps,
} from '../helpers'

type Props = Pick<ModalProps, 'doneFn' | 'closeFn'> & {
  personIds: RequiredPersonIds
  charge: BillingCharge
}

export default function EditRecurringChargeModal(props: Props) {
  const { setError } = useContext(GlobalContext)
  const { personIds, charge, doneFn, closeFn } = props
  const { orgId, facilityId, id: personId } = personIds
  const chargeData = charge.data
  const { startDate, endDate } = chargeData
  const dayAfterStartDateObj = addDays(parseISO(startDate), 1) // New startDate must be after current startDate
  const endDateObj = endDate ? parseISO(endDate) : undefined
  const useFormReturn = useForm<BillingChargeFormData>({
    defaultValues: {
      ...convertChargeDataToFormData(charge.data),
      startDate: undefined, // Empty it so user should enter a new date
    },
  })
  const {
    formState,
    formState: { errors },
    register,
    handleSubmit,
    control,
  } = useFormReturn

  const { billingItemsWithParentCategories } = useBillingItems({
    facilityId,
    orgId,
  })
  const disabled = billingItemsWithParentCategories.tag === 'Loading'

  async function onSubmit(formData: BillingChargeFormData) {
    try {
      await updateBillingCharge({
        personIds: { orgId, facilityId, id: personId },
        chargeData: convertFormDataToChargeData(formData),
        chargeId: charge.meta.id,
      })

      await doneFn()
      closeFn()
    } catch (e) {
      setError(e)
    }
  }

  return (
    <BillingModal
      id="update-recurring-charge-modal"
      title="Update Recurring Charge"
      onClose={closeFn}
    >
      <form className={tw`mt-[32px]`} onSubmit={handleSubmit(onSubmit)}>
        <FeeDropdown
          billingItemsWithParentCategories={billingItemsWithParentCategories}
          useFormReturn={useFormReturn}
          disableOptionsBasedOnCharge={charge}
        />
        <div className={tw`mt-[24px]`}>
          <LabelAboveInput uppercase={false} htmlFor="note">
            Additional Description
          </LabelAboveInput>
          <BasicInput
            {...register('note')}
            disabled={disabled}
            placeholder="Add an addendum to the charge name"
          />
        </div>
        <div className={tw`mt-[24px] flex`}>
          <div className={tw`mr-[16px]`}>
            <LabelAboveInput uppercase={false}>Current Rate</LabelAboveInput>
            <BaseMoneyInput
              inputProps={{
                defaultValue: convertCentsToDollars({
                  value: charge.data.amountCents.toString(),
                }),
                disabled: true,
              }}
            />
          </div>
          <MoneyInReactHookForm
            uppercaseTitle={false}
            subLabel={requiredWhenError(Boolean(errors.amountCents))}
            control={control}
            inputProps={{ min: 0, disabled }}
            name="amountCents"
            id="amountCents"
            required
            title="New Rate"
          />
          <div className={tw`ml-[16px]`}>
            <StartEndDates
              startDateLabel="Effective Date"
              startDatePickerProps={{
                minDate: dayAfterStartDateObj,
                maxDate: endDateObj,
              }}
              useFormReturn={useFormReturn}
            />
          </div>
        </div>
        <div className={tw`mt-[32px]`}>
          <AnimatedPopupFormFooter
            yesBtn={{ props: { disabled } }}
            noBtn={{ action: closeFn }}
            formState={formState}
          />
        </div>
      </form>
    </BillingModal>
  )
}
