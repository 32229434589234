import { Person } from '@shared/types/person'
import { DeepNull } from '@shared/types/utilities'
import { isEmptyObject } from '@shared/utils/common'

export function filterOutEmptyMedications(patch: DeepNull<Person>) {
  const updatedPatch = { ...patch }
  if (updatedPatch.healthQuestionnaire?.medications) {
    let { medications } = updatedPatch.healthQuestionnaire
    medications = medications.filter((m) => !isEmptyObject(m))
    updatedPatch.healthQuestionnaire.medications = medications
  }

  return updatedPatch
}
