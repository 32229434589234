import { useContext, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useHistory } from 'react-router-dom'
import { issueStatementForResident } from '@shared/api/billing'
import Lightbox from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import GlobalContext from '@shared/contexts/GlobalContext'
import { billingRouteForPerson } from '@shared/routes'
import { isSuccessfullyIssued } from '@shared/utils/billing'
import { getOrElse } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import useBillingInvoicePreview from '@app/hooks/useBillingInvoicePreview'
import useResidentBillingEvents from '@app/hooks/useResidentBillingEvents'
import DocumentPreview from '@app/pages/Documents/Viewer/DocumentPreview/DocumentPreview'
import DocumentPreviewFloatingActions from '@app/pages/Documents/Viewer/DocumentPreview/DocumentPreviewFloatingActions'
import IssueIndividualStatementModal from '../Modals/InvoiceModals/IssueIndividualStatementModal'
import { filterOutInvalidBillingEvents } from '../Residents/Resident/BillingEvents/helpers'
import { ActionButtons } from './ActionButtons'
import EventHistory from './EventHistory'
import Header from './Header'
import Hero from './Hero'

type Props = {
  match: {
    params: {
      orgId: string
      facilityId: string
      invoiceId: string
      personId: string
    }
  }
}

export default function InvoiceViewer(props: Props) {
  const { match } = props
  const { invoiceId, orgId, facilityId, personId } = match.params
  const { setError } = useContext(GlobalContext)
  const { invoiceBlob, detailedInvoice, person, reloadInvoicePreviewData } =
    useBillingInvoicePreview({
      orgId,
      facilityId,
      invoiceId,
      personId,
    })

  const { billingEvents } = useResidentBillingEvents({
    orgId,
    facilityId,
    id: personId,
  })
  const history = useHistory()
  const goBack = () => {
    if (history.length <= 1) {
      history.push(billingRouteForPerson(orgId, facilityId, personId))
    } else {
      history.goBack()
    }
  }
  const [showModal, setShowModal] = useState<boolean>(false)

  const sendStatement = async () => {
    try {
      await issueStatementForResident({
        personIds: { orgId, facilityId, id: personId },
        statementId: invoiceId,
      })
      await reloadInvoicePreviewData()
    } catch (err) {
      setError(err)
    }
  }

  if (detailedInvoice.tag !== 'Complete' || person.tag !== 'Complete') {
    return (
      <Lightbox
        mainContent={<SimpleSpinner />}
        sidebarContent={
          <div
            data-testid="loader"
            className={tw`flex h-full flex-col justify-between`}
          >
            <div className={tw`flex flex-col gap-[24px]`}>
              <Skeleton height={36} />
              <Skeleton height={66} />
              <Skeleton height={21} />
            </div>
            <Skeleton height={31} />
          </div>
        }
        showSidebarOnPrint
        onClose={goBack}
      />
    )
  }

  const loadedDetailedInvoice = getOrElse(detailedInvoice, null)
  const loadedPerson = getOrElse(person, null)

  if (loadedDetailedInvoice && loadedPerson) {
    const filteredEvents = filterOutInvalidBillingEvents({
      billingEvents: getOrElse(billingEvents, []),
      person: loadedPerson,
    })

    const handleSendStatement = async () => {
      if (filteredEvents.length > 0) {
        setShowModal(true)
      } else {
        await sendStatement()
      }
    }

    return (
      <>
        <Lightbox
          mainContent={
            <div>
              <DocumentPreview blobData={invoiceBlob} />
              <DocumentPreviewFloatingActions
                blobData={invoiceBlob}
                softDeletable={false}
                showSmallPrintButton
                showSmallDownloadButton
              />
            </div>
          }
          sidebarContent={
            <div
              className={tw`flex h-full flex-col justify-between gap-3 overflow-y-auto`}
            >
              <div className={tw`flex flex-col gap-[24px]`}>
                <Header detailedInvoice={loadedDetailedInvoice} />
                <Hero person={person} />
                <EventHistory detailedInvoice={loadedDetailedInvoice} />
              </div>
              <ActionButtons
                sendStatementFn={handleSendStatement}
                disabled={isSuccessfullyIssued(loadedDetailedInvoice)}
              />
            </div>
          }
          showSidebarOnPrint
          onClose={goBack}
        />
        {showModal && (
          <IssueIndividualStatementModal
            closeFn={() => setShowModal(false)}
            doneFn={sendStatement}
            facilityId={facilityId}
            orgId={orgId}
            statementId={invoiceId}
          />
        )}
      </>
    )
  }

  return null
}
