import urlcat, { join, ParamMap } from 'urlcat'

export const MAIN_APP_BASE_PATH = '/'
export const FORGET_PASSWORD_PATH = '/forgot-password'
export const USER_LOGIN_PATH = '/userLogin'
export const SHIFT_ID_PARAM = 'shiftId'
export const CARE_GROUP_ID_PARAM = 'careGroupId'

const path = (...segments: Array<string>) =>
  '/' + segments.reduce((acc, curr) => join(acc, '/', curr), '')
const relativePath = (template: string, params: ParamMap = {}) =>
  urlcat('', template.replace(/^\//g, ''), params)
const absolutePath = (template: string, params: ParamMap = {}) =>
  `/${relativePath(template, params)}`

export const rootRoute = () => MAIN_APP_BASE_PATH

export const rootPath = (params: ParamMap = {}) => {
  return absolutePath(rootRoute(), params)
}

export function magicEmailPath(basePath: string) {
  return path(basePath, '/login/c/:c/e/:e')
}

export function forgotPasswordPath(basePath: string) {
  return path(basePath, FORGET_PASSWORD_PATH)
}

export function loginPath(basePath: string) {
  return path(basePath, USER_LOGIN_PATH)
}

export function logoutPath(basePath: string) {
  return path(basePath, '/logout')
}

export const medicationEditUrl = (params: {
  orgId: string
  facilityId: string
  residentId: string
  medicationOrderId: string
}) =>
  absolutePath(
    '/orgs/:orgId/facilities/:facilityId/residents/:residentId/medications/:medicationOrderId/edit',
    params
  )

export const emarRootRoute = () => '/emar'

export const emarRootPath = (params: ParamMap = {}) => {
  return absolutePath(emarRootRoute(), params)
}

export const emarOrgsRoute = () => path(emarRootRoute(), '/orgs')

export const emarOrgsPath = (params: ParamMap = {}) => {
  return absolutePath(emarOrgsRoute(), params)
}

export const emarOrgRoute = () => path(emarOrgsRoute(), '/:orgId')

export const emarOrgPath = (orgId: string, params: ParamMap = {}) => {
  return absolutePath(emarOrgRoute(), { ...params, orgId })
}

export const emarOrgFacilitiesRoute = () => path(emarOrgRoute(), '/facilities')

export const emarOrgFacilitiesPath = (orgId: string, params: ParamMap = {}) => {
  return absolutePath(emarOrgFacilitiesRoute(), { ...params, orgId })
}

export const emarOrgFacilityRoute = () =>
  path(emarOrgFacilitiesRoute(), '/:facilityId')

export const emarOrgFacilityPath = (
  orgId: string,
  facilityId: string,
  params: ParamMap = {}
) => {
  return absolutePath(emarOrgFacilityRoute(), {
    ...params,
    orgId,
    facilityId,
  })
}

export const emarOrgFacilityProgressRoute = () =>
  path(emarOrgFacilityRoute(), '/progress')

export const emarOrgFacilityProgressPath = (
  orgId: string,
  facilityId: string,
  params: ParamMap = {}
) => {
  return absolutePath(emarOrgFacilityProgressRoute(), {
    ...params,
    orgId,
    facilityId,
  })
}

export const emarOrgFacilityPassmedsRoute = () =>
  path(emarOrgFacilityRoute(), '/passmeds')

export const emarOrgFacilityPassmedsPath = (
  orgId: string,
  facilityId: string,
  params: ParamMap = {}
) => {
  return absolutePath(emarOrgFacilityPassmedsRoute(), {
    ...params,
    orgId,
    facilityId,
  })
}

export const emarOrgFacilityMedpassPersonRoute = () =>
  path(
    emarOrgFacilityRoute(),
    '/medpasses/:medPassOccurrenceId/people/:personId'
  )

export const emarOrgFacilityMedpassPersonPath = (
  orgId: string,
  facilityId: string,
  medPassOccurrenceId: string,
  personId: string,
  params: ParamMap = {}
) => {
  return absolutePath(emarOrgFacilityMedpassPersonRoute(), {
    ...params,
    orgId,
    facilityId,
    medPassOccurrenceId,
    personId,
  })
}

export const emarOrgFacilityMedpassPersonSignOffRoute = () =>
  path(
    emarOrgFacilityRoute(),
    '/medpasses/:medPassOccurrenceId/people/:personId/signOff'
  )

export const emarOrgFacilityMedpassPersonSignOffPath = (
  orgId: string,
  facilityId: string,
  medPassOccurrenceId: string,
  personId: string,
  params: ParamMap = {}
) => {
  return absolutePath(emarOrgFacilityMedpassPersonSignOffRoute(), {
    ...params,
    orgId,
    facilityId,
    medPassOccurrenceId,
    personId,
  })
}

export const billingRoute = (orgId: string, facilityId: string) =>
  absolutePath(path(`/orgs/${orgId}/facilities/${facilityId}/billing`))

export const billingRouteForPerson = (
  orgId: string,
  facilityId: string,
  personId: string
) =>
  absolutePath(
    path(`/orgs/${orgId}/facilities/${facilityId}/billing/people/${personId}`)
  )

export const billingInvoicePreviewRoute = (
  orgId: string,
  facilityId: string,
  personId: string,
  invoiceId: string
) =>
  absolutePath(
    path(
      `/orgs/${orgId}/facilities/${facilityId}/billing/people/${personId}/invoice/${invoiceId}/preview`
    )
  )

export const billingPersonRoute = (
  orgId: string,
  facilityId: string,
  personId: string
) =>
  absolutePath(
    path(`/orgs/${orgId}/facilities/${facilityId}/billing/people/${personId}`)
  )
