import { components, MenuListProps } from 'react-select'
import ButtonLink from '@shared/components/ButtonLink'
import { StyledOptionProps } from '@shared/components/StyledSelect'
import { Person } from '@shared/types/person'
import { PersonStats } from '@shared/types/person_stats'
import { getProfileSvgPath, getRoomNumber } from '@shared/utils/person'
import styles from './residentSearch.module.css'

export function ResidentOption(props: StyledOptionProps) {
  const { innerRef, innerProps, isSelected } = props
  const { label, value: personStats } = props.data as {
    label: string
    value: PersonStats
  }
  const person: Person = personStats.person
  const profileUrl = personStats.profilePhotoUrl
    ? personStats.profilePhotoUrl
    : getProfileSvgPath(person)
  const roomNumber = getRoomNumber(person)
  const selectedClass = isSelected ? styles.selected : ''

  return (
    <div
      data-testid={`resident-option-${person.id}`}
      ref={innerRef}
      {...innerProps}
      className={styles.optionContainer}
    >
      <div className={`${selectedClass} ${styles.option}`}>
        <img
          className={styles.profilePic}
          src={profileUrl}
          alt={'profile picture'}
        />
        <div>
          <div>{label}</div>
          {roomNumber && (
            <div className="mt-[4px] text-[12px] leading-[12px] text-gray-07">
              Room {roomNumber}
            </div>
          )}
        </div>
      </div>
      <div className={styles.divider} />
    </div>
  )
}

export function QuickVitalsMenu(onClick: () => void) {
  // eslint-disable-next-line react/display-name
  return (props: MenuListProps) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        <ButtonLink
          onClick={onClick}
          className="w-full p-[12px] text-left text-[14px] font-semibold leading-[16px]"
          data-testid="add-quick-vitals"
        >
          <i className="fa-solid fa-rabbit-running fa-fw mr-[8px]" />
          Quick Vitals...
        </ButtonLink>
      </components.MenuList>
    )
  }
}
