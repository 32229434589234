import {
  OrgFacilitySettings,
  SettingsType,
} from '@augusthealth/models/com/august/protos/org_facility_settings'
import { PersonFilter } from '@augusthealth/models/com/august/protos/scheduled_task_assignment'
import { TaskType } from '@augusthealth/models/com/august/protos/task'
import { useState } from 'react'
import Badge from '@shared/components/Badge'
import Card from '@shared/components/Card'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { DataType } from '@shared/types/snapshot'
import { getTitleFromDataType } from '@shared/utils/task'
import useSettingsByKey from '@app/hooks/useSettingsByKey'
import { OrgFacilitySelects } from '../SharedSelects/OrgFacilitySelects'
import { getSettingsLevel } from '../TaskDefinitions/helpers'

export default function ScheduledTasks() {
  const [selectedOrganization, setSelectedOrganization] =
    useState<OptionTypeBase<Organization> | null>(null)
  const [selectedFacility, setSelectedFacility] =
    useState<OptionTypeBase<Facility> | null>(null)

  const { settings } = useSettingsByKey({
    facility: selectedFacility?.value,
    settingsType: SettingsType.SETTINGS_TYPE_SCHEDULED_TASK,
  })
  // Fetch task settings, so we can use the configured display names
  const { settings: taskSettings } = useSettingsByKey({
    facility: selectedFacility?.value,
    settingsType: SettingsType.SETTINGS_TYPE_TASK_DEFINITION,
  })

  const isLoading = settings.tag === 'Loading' || taskSettings.tag === 'Loading'

  return (
    <div className="content-holder">
      <h4 className="page-title mt-[24px]">Scheduled Tasks</h4>
      <div className={'flex justify-between'}>
        <div>
          <Card className="mb-[16px] flex flex-col">
            <OrgFacilitySelects
              orgLabel={'I want to view scheduled task settings for...'}
              onOrgSelect={setSelectedOrganization}
              onFacilitySelect={setSelectedFacility}
              selectedOrganization={selectedOrganization}
              selectedFacility={selectedFacility}
            />
          </Card>
        </div>
        <div className={'ml-[32px] grow'}>
          {!isLoading &&
            settings.value.map((setting) => (
              <ScheduledTaskCard
                taskSettings={taskSettings.value}
                settings={setting}
                key={setting.key}
              />
            ))}
          {settings.tag === 'Complete' && settings.value.length === 0 && (
            <Card>No scheduled tasks found</Card>
          )}
          {isLoading && selectedFacility && <Card>Loading...</Card>}
        </div>
      </div>
    </div>
  )
}

function ScheduledTaskCard({
  settings,
  taskSettings,
}: {
  settings: OrgFacilitySettings
  taskSettings: OrgFacilitySettings[]
}) {
  const settingsLevel = getSettingsLevel(settings)
  const dataType = settings.settings?.scheduledTask?.dataType
  const filters = settings.settings?.scheduledTask?.parameters?.filters ?? []
  const expiresEvery =
    settings.settings?.scheduledTask?.parameters?.daysToExpire
  const daysInAdvance =
    settings.settings?.scheduledTask?.parameters?.daysInAdvance

  const tasksCreated = (
    settings.settings?.scheduledTask?.parameters?.tasksToCreate ?? []
  )
    .map((tt) => ({
      taskType: tt,
      customType: settings.settings?.scheduledTask?.customType,
    }))
    .map(nameForTaskType(taskSettings))

  if (dataType === undefined) {
    return null
  }

  const title = nameForDataType(taskSettings, dataType)

  return (
    <Card className={'mb-[16px] flex-col'} data-testid={dataType}>
      <div className={'flex items-center'}>
        <span className={'font-medium'}>{title}</span>
        <Badge shape={'square'} color={'purple'} className={'ml-[8px]'}>
          {settingsLevel}
        </Badge>
      </div>
      <div className={'mt-[16px] text-[14px]'}>
        <ul className={'mb-0 pl-[16px]'}>
          <li>Expires every: {expiresEvery} days</li>
          <li>Task is created: {daysInAdvance} days in advance of expiry</li>
          <FiltersItem filters={filters} />
          {tasksCreated.length > 0 && (
            <li>
              Tasks created:{' '}
              {tasksCreated.map((name, i) => (
                <span key={`task-created-${i}`}>{name}</span>
              ))}
            </li>
          )}
        </ul>
      </div>
    </Card>
  )
}

function FiltersItem({ filters }: { filters: PersonFilter[] }) {
  if (filters.length === 0) {
    return (
      <li>
        Applies to:{' '}
        <Badge color={'gray'} shape={'square'}>
          Everyone
        </Badge>
      </li>
    )
  }

  return (
    <li>
      Applies to people with any of:{' '}
      {filters
        .map((pf) => pf.replace('PERSON_FILTER_', '').replaceAll('_', ' '))
        .map((pf) => (
          <Badge
            color={'gray'}
            shape={'square'}
            className={'mr-[8px]'}
            key={pf}
          >
            {pf}
          </Badge>
        ))}
    </li>
  )
}

function nameForTaskType(taskSettings: OrgFacilitySettings[]) {
  // eslint-disable-next-line react/display-name
  return ({
    taskType,
    customType,
  }: {
    taskType: TaskType
    customType: string | undefined
  }) => {
    const taskDefinition = taskSettings.find(
      (ts) =>
        ts.settings?.taskDefinition?.template?.taskType === taskType &&
        ts.settings?.taskDefinition?.template?.customType === customType
    )

    return (
      taskDefinition?.settings?.taskDefinition?.template?.displayName ?? (
        <>
          <span title="Task not found in Task Definitions">⚠️</span>
          {taskType}
        </>
      )
    )
  }
}

function nameForDataType(
  taskSettings: OrgFacilitySettings[],
  dataType: DataType
) {
  return (
    taskSettings.find(
      (ts) => ts.settings?.taskDefinition?.template?.dataType === dataType
    )?.settings?.taskDefinition?.template?.displayName ??
    getTitleFromDataType(dataType)
  )
}
