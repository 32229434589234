import { SetOptional } from 'type-fest'
import { ApiParams, fetchJson } from '@shared/api/request'
import {
  emarBulkUpdateUrl,
  medicationAdministrationsByOrderGroupUrl,
} from '@shared/api/urls'
import { EmarMedicationAdministration } from '@shared/types/emar'
import { Person } from '@shared/types/person'
import { Vital } from '@shared/types/vital'
import {
  MedicationAdministrationRow,
  MedPassOccurrenceSignOffRow,
} from '@emar/db/emar'

export interface BulkUpdateMedicationAdministrationsParams extends ApiParams {}

export async function bulkUpdateMedicationAdministrations(
  orgId: string,
  facilityId: string,
  administrations: Array<SetOptional<MedicationAdministrationRow, 'id'>>,
  vitals: Array<
    SetOptional<Vital, 'id'> & {
      clientId?: string
    }
  >,
  signOffs: Array<MedPassOccurrenceSignOffRow>,
  params: BulkUpdateMedicationAdministrationsParams = {}
): Promise<Array<unknown>> {
  return fetchJson<Record<string, never>>(
    emarBulkUpdateUrl(orgId, facilityId, params),
    {
      method: 'POST',
      headers: { 'emar-bulk-update-version': '2' },
      body: JSON.stringify({
        medicationAdministrations: administrations,
        vitals,
        signOffs,
      }),
    }
    // Remove casts once fetchJson is fully typed
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  ).then((response) => response.data) as Promise<Array<unknown>>
}

export async function listForOrderGroup(person: Person, orderGroupId: string) {
  return fetchJson<Record<string, never>>(
    medicationAdministrationsByOrderGroupUrl(
      person.orgId,
      person.facilityId,
      person.id,
      orderGroupId
    )
    // Remove casts once fetchJson is fully typed
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  ).then((response) => response.data) as Promise<
    Array<EmarMedicationAdministration>
  >
}
