import { faker } from '@faker-js/faker'
import { Factory } from 'fishery'
import { DateMessage } from '@shared/types/date'

class DateMessageFactory extends Factory<DateMessage> {
  recent() {
    const date = faker.date.recent()

    return this.params({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    })
  }
  past() {
    const date = faker.date.past()

    return this.params({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    })
  }

  future() {
    const date = faker.date.future()

    return this.params({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    })
  }

  today() {
    const date = new Date()

    return this.params({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    })
  }
}

export const dateMessageFactory = DateMessageFactory.define(
  (): DateMessage => ({
    year: faker.date.recent().getFullYear(),
    month: faker.date.recent().getMonth() + 1,
    day: faker.date.recent().getDate(),
  })
)
