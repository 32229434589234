import styles from './styles.module.css'

export default function Banner({
  children,
  description,
}: {
  children: React.ReactNode
  description: {
    icon?: string
    copy: React.ReactNode
  }
}) {
  return (
    <div className={styles.banner}>
      <div>
        {description.icon && (
          <i className={`${styles.icon} fa-fw fas ${description.icon}`} />
        )}
        {description.copy}
      </div>
      {children}
    </div>
  )
}
