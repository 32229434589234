import classNames from 'classnames/bind'
import styles from './styles.module.css'

const cx = classNames.bind(styles)

export const ROUTINE_NAME_ICONS = {
  Morning: 'fa-sunrise',
  Bedtime: 'fa-moon-stars',
  Bathing: 'fa-bath',
  Meals: 'fa-fork-knife',
  Laundry: 'fa-washing-machine',
  Trash: 'fa-recycle',
  Mobility: 'fa-wheelchair',
  Sleep: 'fa-snooze',
  'Pet Care': 'fa-dog',
  Housekeeping: 'fa-broom',
  Transfers: 'fa-arrow-up-from-line',
  Toileting: 'fa-toilet',
  'Special Care Needs': 'fa-hand-heart',
}

export const getRoutineIcon = (routineName: string) =>
  cx({
    [`fa-light ${styles.icon}`]: true,
    [ROUTINE_NAME_ICONS[routineName]]: true,
  })
