import {
  Contact,
  Contact_ContactRelationship as CR,
} from '@augusthealth/models/com/august/protos/contact'
import { request, requestJson } from '@shared/api/request'
import environment from '@shared/environment'
import { filterOutEmptyTelecom } from '@shared/utils/contactPoint'

type Props = {
  contact: Contact
  orgId: string
  facilityId: string
  personId: string
}

type UpdateProps = {
  contactId: number
  orgId: string
  facilityId: string
  personId: string
}

export const getContacts = async ({
  personId,
  orgId,
  facilityId,
}: Pick<Props, 'personId' | 'orgId' | 'facilityId'>): Promise<Contact[]> => {
  const url = `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/people/${personId}/contacts`
  const response = await requestJson({ url })
  return response.data
}

export const createContact = async ({
  contact,
  orgId,
  facilityId,
  personId,
}: Props): Promise<{ id: number }> => {
  const url = `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/people/${personId}/contacts`
  const response = await requestJson({
    url,
    method: 'POST',
    body: JSON.stringify(contact),
  })
  return response.data
}

export const createRPContact = async ({
  contact,
  facilityId,
  orgId,
  personId,
}: Props) => {
  const { id: contactId } = await createContact({
    contact,
    facilityId,
    orgId,
    personId,
  })
  await updateContactResponsibleParty({
    contactId,
    facilityId,
    orgId,
    personId,
  })
}

export const createPrimaryPhysicianContact = async ({
  contact,
  facilityId,
  orgId,
  personId,
}: Props) => {
  const contactWithoutPCPRole = {
    ...contact,
    relationship: contact.relationship!.filter(
      (r) => r !== CR.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER
    ),
  }

  const { id: contactId } = await createContact({
    contact: contactWithoutPCPRole,
    facilityId,
    orgId,
    personId,
  })
  await updateContactGeneralPractitioner({
    contactId,
    facilityId,
    orgId,
    personId,
  })
  return Promise.resolve({ id: contactId })
}

export const updateContact = async ({
  contact,
  orgId,
  facilityId,
  personId,
}: Props) => {
  const url = `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/people/${personId}/contacts/${contact.id}`
  const newContact = { ...contact }
  if (newContact.telecom) {
    newContact.telecom = filterOutEmptyTelecom(newContact.telecom)
  }
  const response = await request({
    url,
    body: JSON.stringify(newContact),
    contentType: 'application/merge-patch+json',
    method: 'PATCH',
  })
  return response.data
}

export const deleteContact = async ({
  contactId,
  orgId,
  facilityId,
  personId,
}: UpdateProps) => {
  const url = `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/people/${personId}/contacts/${contactId}`
  const response = await requestJson({ url, method: 'DELETE' })
  return response.data
}

export const updateContactResponsibleParty = async ({
  contactId,
  orgId,
  facilityId,
  personId,
}: UpdateProps) => {
  const url = `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/people/${personId}/contacts/responsibleParty/${contactId}`
  const response = await requestJson({ url, method: 'PUT' })
  return response.data
}

export const updateContactGeneralPractitioner = async ({
  contactId,
  orgId,
  facilityId,
  personId,
}: UpdateProps) => {
  const url = `${environment.baseUrl}/organizations/${orgId}/facilities/${facilityId}/people/${personId}/contacts/generalPractitioner/${contactId}`
  const response = await requestJson({ url, method: 'PUT' })
  return response.data
}
