import { useEffect } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import {
  buildExactTimeOptionsGroup,
  buildMedPassOptionsGroup,
  TimeSelect,
} from '@shared/components/TimeSelect'
import { VerticalRoundedBar } from '@shared/components/VerticalRoundedBar'
import { MedOrderFormData } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'
import { getNumberOfTimeInputsToShow } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/helpers'
import styles from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/styles.module.css'
import { useCurrentFacility } from '@app/hooks/useFacilities'

type Props = {
  index: number
  readOnly: boolean
}
export const TimesOfDay = ({ index, readOnly }: Props) => {
  const {
    settings: { use24HourClock },
  } = useCurrentFacility()
  const { control, watch, getValues } = useFormContext<MedOrderFormData>()

  const { fields, replace } = useFieldArray({
    name: `doses.${index}.timeOfDay`,
    control,
  })

  const frequencyType = watch(`doses.${index}.periodUnit`)
  const frequencyCount = watch(`doses.${index}.frequency`)
  const variablePeriodUnit = watch(`doses.${index}.variablePeriodUnit`)
  const period = watch(`doses.${index}.period`)

  const numberOfTimesToShow = getNumberOfTimeInputsToShow({
    frequencyCount,
    frequencyType,
    variablePeriodUnit,
    period,
  })

  useEffect(() => {
    const EMPTY_TIMING_OPTION = null
    const current = getValues(`doses.${index}.timeOfDay`) ?? []

    if (current.length === 0) {
      replace(Array(numberOfTimesToShow).fill(EMPTY_TIMING_OPTION))
    } else {
      if (numberOfTimesToShow > current.length) {
        const toAdd = numberOfTimesToShow - current.length
        replace([...current, ...Array(toAdd).fill(EMPTY_TIMING_OPTION)])
      } else if (numberOfTimesToShow < current.length) {
        const updated = current.slice(0, numberOfTimesToShow)
        replace(updated)
      } else {
        replace(current)
      }
    }
  }, [numberOfTimesToShow])

  if (!frequencyCount) {
    return null
  }

  return (
    <div className={styles.time} data-testid={'time-selects'}>
      <VerticalRoundedBar />
      <div className={styles.timeInputContainer}>
        {fields.map((field, ix) => {
          return (
            <div key={field.id} className={'flex h-fit flex-row items-center'}>
              <Controller
                control={control}
                name={`doses.${index}.timeOfDay.${ix}`}
                rules={{ required: true }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  const doseId = `dose-${index + 1}-time-${ix + 1}`
                  return (
                    <TimeSelect
                      label={`Time Select ${ix + 1}`}
                      onChange={onChange}
                      options={[
                        buildMedPassOptionsGroup(),
                        buildExactTimeOptionsGroup({ use24HourClock }),
                      ]}
                      value={value}
                      disabled={readOnly}
                      name={doseId}
                      id={doseId}
                      showErrorBorder={!!error}
                    />
                  )
                }}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
