import {
  GroupPermission,
  GroupType,
} from '@augusthealth/models/com/august/protos/permission'
import { Task } from '@augusthealth/models/com/august/protos/task'
import CloseTasks from 'app/components/RemoveTasks'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import ButtonLink from '@shared/components/ButtonLink'
import { EmptyCard } from '@shared/components/EmptyCard'
import { LinkCard } from '@shared/components/LinkCard'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { documentsPathForPerson } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import PersonContext from '@app/contexts/PersonContext'
import styles from './styles.module.css'
import TaskListItem from './Item'

type Props = {
  facilityId: string
  orgId: string
  list: Task[]
  openUploadPopup: (task: Task) => void
  hasCompletedTasks: boolean
  refreshTasks: () => Promise<void>
}

export default function List(props: Props) {
  const history = useHistory()
  const { person } = useContext(PersonContext)
  const { list, openUploadPopup, hasCompletedTasks, refreshTasks } = props

  if (person === undefined) {
    return null
  }

  const documents = list.map((item, i: number) => {
    const key = `task-${item.id}`

    return (
      <TaskListItem
        animationStyle={{ '--animation-order': i } as React.CSSProperties}
        key={key}
        task={item}
        openUploadPopup={openUploadPopup}
        refreshTasks={refreshTasks}
      />
    )
  })

  if (list && list.length) {
    return (
      <div className={styles.cardList}>
        {documents}
        {hasCompletedTasks && (
          <LinkCard
            className={styles.linkCard}
            href={documentsPathForPerson(person as Required<Person>)}
          >
            <div>
              Completed documents are saved in&nbsp;
              <span className="font-semibold text-gray-01">
                Resident Documents
              </span>
            </div>
            <i className={'far fa-angle-right ml-[4px] text-[14px]'} />
          </LinkCard>
        )}
        <PersonPermissionGate
          person={person}
          permissions={[GroupPermission.GROUP_PERMISSION_TASK_UPDATE]}
          excludedRoles={[GroupType.GROUP_TYPE_RESPONSIBLE_PARTY]}
        >
          <CloseTasks />
        </PersonPermissionGate>
      </div>
    )
  }

  return (
    <div>
      <EmptyCard
        className={styles.emptyCard}
        title="You have no open tasks."
        version={{
          tag: 'Children',
          children: (
            <div className={'mt-[4px]'}>
              Completed documents are saved in{' '}
              <ButtonLink
                onClick={() =>
                  history.push(
                    documentsPathForPerson(person as Required<Person>)
                  )
                }
              >
                the Documents tab.
              </ButtonLink>
            </div>
          ),
        }}
      />
    </div>
  )
}
