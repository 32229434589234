import { useContext, useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import HUD from '@app/components/HUD'
import PersonPageTitle from '@app/components/PersonPageTitle'
import PersonContext from '@app/contexts/PersonContext'
import AddCharge from './AddCharge'
import MakeAdjustment from './AddCharge/MakeAdjustment'
import List from './List'

export default function Billing() {
  const { person } = useContext(PersonContext)
  const [showAddCharge, setShowAddCharge] = useState(false)
  const [showMakeAdjustment, setShowMakeAdjustment] = useState(false)
  const [showHud, setShowHud] = useState<string>()
  const [newChargeId, setNewChargeId] = useState<string | undefined>(undefined)

  if (!person) {
    return null
  }

  return (
    <>
      <PersonPageTitle title={'Charges'}>
        <AsyncIconButton
          buttonStyle={'secondary-fill'}
          id={'addCharge'}
          initialIcon={'fa-bed'}
          className={'mr-[12px]'}
          onClick={() => setShowAddCharge(true)}
        >
          Add Charge
        </AsyncIconButton>
        <AsyncIconButton
          buttonStyle={'secondary-outline'}
          initialIcon={'fa-percentage'}
          onClick={() => setShowMakeAdjustment(true)}
        >
          Make Adjustment
        </AsyncIconButton>
      </PersonPageTitle>
      {showHud && (
        <HUD
          onExpire={() => {
            setShowHud(undefined)
          }}
        >
          {showHud}
        </HUD>
      )}
      {showAddCharge && (
        <AddCharge
          person={person}
          doneFn={(newChargeId) => {
            if (newChargeId) {
              setShowHud('Charge Added')
              setNewChargeId(newChargeId)
            }
            setShowAddCharge(false)
          }}
        />
      )}
      {showMakeAdjustment && (
        <MakeAdjustment
          person={person}
          done={(newChargeId) => {
            if (newChargeId) {
              setShowHud('Adjustment Added')
              setNewChargeId(newChargeId)
            }
            setShowMakeAdjustment(false)
          }}
        />
      )}
      <List
        newChargeAdded={newChargeId}
        person={person}
        addNewCharge={() => {
          setShowAddCharge(true)
        }}
      />
    </>
  )
}
