import { Occupancy_Room as OccupancyRoom } from '@augusthealth/models/com/august/protos/api/occupancy'
import { useState } from 'react'
import { createFilter, Props as ReactSelectProps } from 'react-select'
import StyledSelect from '@shared/components/StyledSelect'
import Warning from '../Warning'
import { getOption, OptionProps } from './helpers'

export interface Props extends Omit<ReactSelectProps, 'options' | 'value'> {
  isRequired?: boolean
  occupancyRooms: OccupancyRoom[]
  value?: string
  orgId: string
  facilityId: string
}

export default function RoomBedSelect(props: Props) {
  const [bedIsOccupied, setBedIsOccupied] = useState(false)
  const {
    isRequired = false,
    occupancyRooms,
    onChange,
    orgId,
    facilityId,
    value,
    ...restProps
  } = props
  const options = occupancyRooms
    .filter(({ room, beds }) => room && beds && beds.length)
    .reduce((list: OptionProps[], { room, beds }) => {
      beds!.forEach(({ bed, occupants }) => {
        if (bed) {
          list.push(getOption({ bed, room: room!, occupants }))
        }
      })

      return list
    }, [])

  const defaultOption = options.find((opt) => opt.value === value)

  return (
    <>
      <StyledSelect
        inputId="room-bed-input"
        isClearable={!isRequired}
        isSearchable
        placeholder="Select..."
        {...restProps}
        onChange={(opt: OptionProps, meta) => {
          setBedIsOccupied(Boolean(opt && opt.isOccupied))
          return onChange && onChange(opt, meta)
        }}
        options={options}
        value={defaultOption}
        filterOption={createFilter<{ searchText: string }>({
          stringify: (opt) => opt.data.searchText,
        })}
      />
      {bedIsOccupied && (
        <Warning className="mt-[12px]">
          This room is already occupied. <strong>Are you sure?</strong>
        </Warning>
      )}
    </>
  )
}
