import { Dropdown, isEmpty } from '@augusthealth/august-frontend-form-elements'
import {
  Contact,
  Contact_ContactRelationship as Relationship,
} from '@augusthealth/models/com/august/protos/contact'
import { useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { fetchPerson } from '@shared/api/person'
import {
  PRIMARY_RELATIONSHIP_LIST,
  RELATIONSHIPS_BY_CATEGORY_MAP,
  ROLE_RELATIONSHIP_LIST,
  SUB_RELATIONSHIP_LIST,
} from '@shared/constants/contactRelationship'
import GlobalContext from '@shared/contexts/GlobalContext'
import { contactsPathForPerson } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { relationshipName } from '@shared/utils/contact'
import { RelationshipCategory } from '@app/components/AddPersonPopup/RPVerifyInformation/helpers'
import PersonContext from '@app/contexts/PersonContext'
import { AddContactModal } from '@app/pages/Contacts/ContactModal/ContactModal'
import { ContactFormData } from '@app/pages/Contacts/ContactModal/types'
import './style.css'
import { getFullName } from '@shared/utils/humanName'
import { isResident } from '@shared/utils/person'
import { getDefaultRelationships, getRelationshipTypeFromName } from './helpers'
import ItemComponent, { ItemComponentProps } from './Item'

export interface ContactDropdownProps {
  title?: string
  name: string
  subTitle?: string
  person: Person
  value?: Contact
  onUpdate: (data: any, name: string) => void
  readOnly?: boolean
  unique?: boolean
  relationshipsToExclude?: Relationship | Relationship[]
  relationshipsToInclude?: Relationship | Relationship[]
  onRemove?: (Contact) => void
  inList?: boolean
  contactsToExclude?: Contact[]
  placeholder?: string
  hideRemoveBtn?: boolean
  useStandardUpdate?: boolean
  description?: string
  alertMessage?: string
  onlyAllowToAdd?: boolean
  includeContactsPageLink?: boolean
  onAddContact?: (a: number | string) => void
  reloadData: () => void
}

export default function ContactDropdown(props: ContactDropdownProps) {
  const {
    title,
    name: dropdownName,
    subTitle,
    value,
    onUpdate,
    readOnly = false,
    unique = false,
    relationshipsToExclude,
    relationshipsToInclude,
    onRemove,
    inList,
    contactsToExclude, // Don't show already selected options
    placeholder = 'Select contact',
    hideRemoveBtn,
    useStandardUpdate,
    description,
    alertMessage,
    onlyAllowToAdd,
    includeContactsPageLink = false,
    onAddContact,
    reloadData,
    person,
  } = props

  const history = useHistory()
  const includes: Relationship[] | undefined =
    typeof relationshipsToInclude === 'string'
      ? RELATIONSHIPS_BY_CATEGORY_MAP[relationshipsToInclude]
      : relationshipsToInclude
  const excludes: Relationship[] | undefined =
    typeof relationshipsToExclude === 'string'
      ? RELATIONSHIPS_BY_CATEGORY_MAP[relationshipsToExclude]
      : relationshipsToExclude
  const { setError } = useContext(GlobalContext)
  const { setPerson } = useContext(PersonContext)
  const { id: personId, orgId, facilityId, contact = [] } = person

  // Only option available is Add New Contact, exculde existing contacts
  // when onlyAllowToAdd is specified
  let contactFiltered = onlyAllowToAdd ? [] : contact
  if (Array.isArray(includes)) {
    contactFiltered = contactFiltered.filter(
      (c) => c.relationship && c.relationship.some((r) => includes.includes(r))
    )
  }

  if (Array.isArray(excludes)) {
    contactFiltered = contactFiltered.filter(
      (c) => c.relationship && !c.relationship.some((r) => excludes.includes(r))
    )
  }

  if (!readOnly && Array.isArray(contactsToExclude)) {
    contactFiltered = contactFiltered.filter(
      (c1) =>
        c1.name && !contactsToExclude.some((c2) => c2.id && c1.id === c2.id)
    )
  }

  // Add contact popup related code
  const [openAddContactPopup, setOpenAddContactPopup] = useState(false)
  const [defaultRelationships, setDefaultRelationships] =
    useState<Relationship[]>()

  const showAddContactPopup = (relationType?: Relationship) => {
    if (relationType) {
      setDefaultRelationships(getDefaultRelationships(relationType))
    }
    setOpenAddContactPopup(true)
  }

  const addContactPopupOnSave = (res: {
    id: number | string
  }): Promise<void> | void => {
    // After adding a contact we need to refresh the person before updating the data
    // or the new person won't be an option in the dropdown
    if (orgId && personId && facilityId) {
      return fetchPerson({ orgId, facilityId, personId })
        .then((p) => {
          setPerson(p)
          if (typeof onAddContact === 'function') {
            onAddContact(res.id)
          } else {
            // By default after adding a contact with a specific role, we simply reload
            // the form to update the data
            reloadData()
          }
        })
        .catch(setError)
    }
  }

  // Item component in list with X icon only
  // No Dropdown options will be shown
  if (
    (hideRemoveBtn || typeof onRemove === 'function') &&
    !readOnly &&
    !isEmpty(value)
  ) {
    return (
      <ItemComponent
        name={dropdownName}
        data={value}
        onRemove={onRemove}
        showDefaultCursor
        isLabel
        inList={inList}
      />
    )
  }

  const options: (ItemComponentProps | string)[] = contactFiltered.map(
    (contact) => {
      const { id, name = {} } = contact
      const fullName = getFullName(name)

      return {
        label: <>{fullName}</>,
        value: id,
        data: contact,
        readOnly,
      }
    }
  )

  if (options.length) {
    options.push('separator')
  }

  options.push({
    label: (
      <span className="contact-dropdown-edit">
        <label className="contact-dropdown-link">
          <i className="fa fa-plus-circle mr-[8px]" />
          Add new contact...
        </label>
      </span>
    ),
    onClick: () => {
      showAddContactPopup(getRelationshipTypeFromName(dropdownName))
      return true
    },
  })

  options.push({
    label: (
      <span className="contact-dropdown-edit">
        <label className="contact-dropdown-link">
          <i className="fas fa-address-book mr-[8px]" />
          Edit {isResident(person) ? 'resident' : 'move-in'} contacts...
        </label>
      </span>
    ),
    onClick: () => {
      history.push(contactsPathForPerson(person as Required<Person>))
      return true
    },
  })

  const update = (contactId: string, name: string, data: any) => {
    if (typeof onUpdate === 'function') {
      if (useStandardUpdate) {
        onUpdate(data, name)
        return
      }
      const relationshipType = getRelationshipTypeFromName(name)
      if (relationshipType) {
        if (unique) {
          onUpdate(data, `${name}[0]`) // Responsible Person and Primary Physician
        } else {
          // In ContactDropdownList, only allow to add new relationship
          // Remove is handle inside ContactDropdownList
          const contactIndex = contact.findIndex((c) => c.id === contactId)!
          if (contactIndex > -1 && contact[contactIndex]) {
            const currentContact = contact[contactIndex]
            if (currentContact.relationship) {
              currentContact.relationship.push(relationshipType)
            } else {
              currentContact.relationship = [relationshipType]
            }

            onUpdate(currentContact, `contact[${contactIndex}]`)
          }
        }
      }
    }
  }

  const classNameList = [
    'form-control',
    'font-semibold text-[13px] min-w-[100px] min-h-[40px] rounded-[3px]',
    'contact-dropdown',
    'horizontal',
  ]

  if (isEmpty(value)) {
    classNameList.push(
      'border-button-inner-border-color text-button-default-font-color hover:brightness-90'
    )
  } else {
    classNameList.push(
      'border-button-inner-border-color bg-duotone-level6 border'
    )
  }

  let addContactPopup
  if (openAddContactPopup) {
    const subCategoryRelationship = defaultRelationships?.find((r) =>
      SUB_RELATIONSHIP_LIST.includes(r)
    )
    const defaultFormValues: Partial<ContactFormData> = {
      primaryRelationship: (defaultRelationships?.find((r) =>
        PRIMARY_RELATIONSHIP_LIST.includes(r)
      ) || Relationship.CONTACT_RELATIONSHIP_PERSONAL) as RelationshipCategory,
      secondaryRelationship: subCategoryRelationship
        ? {
            label: relationshipName(subCategoryRelationship as Relationship),
            value: subCategoryRelationship as Relationship,
          }
        : undefined,
      roles: defaultRelationships?.filter((r) =>
        ROLE_RELATIONSHIP_LIST.includes(r)
      ),
    }
    addContactPopup = (
      <AddContactModal
        defaultFormValues={defaultFormValues}
        person={person}
        onClose={async (res) => {
          if (res) {
            await addContactPopupOnSave(res)
          }
          setOpenAddContactPopup(false)
        }}
      />
    )
  }
  const dropdown = (
    <>
      <Dropdown
        title={title}
        subTitle={subTitle}
        description={description}
        className={classNameList.join(' ')}
        name={dropdownName}
        itemComponent={ItemComponent}
        labelComponent={ItemComponent}
        value={value && value.id}
        options={options}
        onUpdate={update}
        readOnly={readOnly}
        placeholder={options.length > 1 ? placeholder : 'Add new contact'}
        alertMessage={alertMessage}
        isRequired
      />
      {openAddContactPopup && addContactPopup}
    </>
  )

  if (includeContactsPageLink) {
    return (
      <div style={{ position: 'relative' }}>
        {dropdown}
        <Link
          id={`${dropdownName}-edit-link`}
          style={{
            position: 'absolute',
            bottom: 12,
            right: '36px',
          }}
          to={contactsPathForPerson(person as Required<Person>)}
        >
          Edit
        </Link>
      </div>
    )
  }

  return dropdown
}
