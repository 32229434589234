import { ApplicationEvent } from '@augusthealth/models/com/august/protos/application_event'
import { useEffect, useState } from 'react'
import {
  AnalyticsAPI,
  ApplicationInterval,
  fetchFacilityAnalytics,
} from '@shared/api/facility'
import { FacilityIds } from '@shared/types/facility'
import { getDateString } from '@shared/utils/date'
import { AsyncResult } from '@shared/utils/loading'

export default function useFacilityAnalytics({
  facility,
  interval,
  events,
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
  interval: ApplicationInterval
  events: ApplicationEvent[]
  facility: FacilityIds
}) {
  const { orgId, id: facilityId } = facility
  const [analyticsHash, setAnalyticsHash] = useState<
    AsyncResult<AnalyticsAPI, any>
  >({
    tag: 'Loading',
  })

  const startDateString = getDateString(startDate)
  const endDateString = getDateString(endDate)
  const eventsString = events.join(',') // Use in useEffect depList

  useEffect(() => {
    fetchFacilityAnalytics({
      orgId,
      facilityId,
      startDate: startDateString,
      endDate: endDateString,
      interval,
      events,
    })
      .then((value) => setAnalyticsHash({ tag: 'Complete', value }))
      .catch((value) => {
        setAnalyticsHash({ tag: 'Error', value })
      })
  }, [orgId, facilityId, startDateString, endDateString, eventsString])

  return analyticsHash
}
