import { AugustFieldFormConfigurationMap } from '@shared/constants/custom_field/type'
import { AugustFieldType } from '@shared/types/custom_field'
import { Gender } from '@shared/types/gender'
import { Person_MaritalStatus as MartialStatus } from '@shared/types/person'

export const RESIDENT_INFO: AugustFieldFormConfigurationMap = {
  [AugustFieldType.AUGUST_FIELD_TYPE_NAME]: {
    fieldLists: ['name'],
    requiredFields: ['name[0].given[0]', 'name[0].family'],
    fields: [
      {
        title: 'FIRST NAME',
        type: 'dropdown_with_details',
        className: 'horizontal mb-0',
        name: 'name[0].given[0]',
        textInputProps: {
          name: 'name[0].given[0]',
          placeholder: 'First Name',
          isRequired: true,
        },
        dropdownProps: {
          name: 'name[0].prefix',
          className:
            'form-control font-semibold text-[13px] min-w-[100px] min-h-[40px] rounded-[3px] border-button-inner-border-color text-button-default-font-color hover:brightness-90',
          options: [
            { value: 'Dr.' },
            { value: 'Mr.' },
            { value: 'Mrs.' },
            { value: 'Ms.' },
            { value: 'Mx.' },
            { value: 'Rev.' },
          ],
          placeholder: 'Title',
        },
        reverseUiComponent: true,
        condition: true,
      },
      {
        title: 'MIDDLE NAME',
        subTitle: 'OPTIONAL',
        name: 'name[0].given[1]',
        placeholder: 'Middle Name',
        className: 'horizontal',
      },
      {
        title: 'LAST NAME',
        name: 'name[0].family',
        placeholder: 'Last Name',
        className: 'horizontal',
        isRequired: true,
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_DATE_OF_BIRTH]: {
    fieldLists: ['birthDate'],
    requiredFields: ['birthDate'],
    fields: [
      {
        title: 'DATE OF BIRTH',
        name: 'birthDate',
        type: 'birthday',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_PRIMARY_LANGUAGE]: {
    fieldLists: ['communication'],
    requiredFields: ['communication[preferred=true].languageStr'],
    fields: [
      {
        title: 'PRIMARY LANGUAGE',
        name: 'communication[preferred=true].languageStr',
        type: 'LanguageDropdown',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_OTHER_LANGUAGES]: {
    fieldLists: ['communication'],
    requiredFields: ['communication[preferred=false][*]'],
    fields: [
      {
        title: 'OTHER LANGUAGES',
        type: 'LanguageDropdownList',
        name: 'communication[preferred=false][*]',
        itemNamePostfix: 'languageStr',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_GENDER]: {
    fieldLists: ['gender'],
    requiredFields: ['gender'],
    fields: [
      {
        title: 'GENDER',
        name: 'gender',
        type: 'dropdown',
        options: [
          { label: 'Male', value: Gender.GENDER_MALE },
          { label: 'Female', value: Gender.GENDER_FEMALE },
          { label: 'Non-Binary', value: Gender.GENDER_NON_BINARY },
        ],
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_FIRST_NAME]: {
    fieldLists: ['name'],
    requiredFields: ['name[0].given[0]'],
    fields: [
      {
        title: 'FIRST NAME',
        name: 'name[0].given[0]',
        placeholder: 'FIRST Name',
        isRequired: true,
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_LAST_NAME]: {
    fieldLists: ['name'],
    requiredFields: ['name[0].family'],
    fields: [
      {
        title: 'LAST NAME',
        name: 'name[0].family',
        placeholder: 'LAST Name',
        isRequired: true,
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_RESIDENT_PRIOR_ADDRESS]: {
    fieldLists: ['address'],
    requiredFields: ['address'],
    fields: [
      {
        title: 'ADDRESS PRIOR TO ADMISSION',
        name: 'address[0]',
        type: 'address_autocomplete',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_RELIGIOUS_PREFERENCE]: {
    fieldLists: ['religiousPreference'],
    requiredFields: ['religiousPreference'],
    fields: [
      {
        title: 'RELIGIOUS PREFERENCE',
        subTitle: 'IF ANY',
        name: 'religiousPreference',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_OCCUPATION]: {
    fieldLists: ['socialHistory/occupationSummary'],
    requiredFields: ['socialHistory.occupationSummary'],
    fields: [
      {
        title: 'OCCUPATION',
        type: 'hash_textarea',
        name: 'socialHistory.occupationSummary',
        placeholder: 'Career background',
      },
    ],
  },
  [AugustFieldType.AUGUST_FIELD_TYPE_MARITAL_STATUS]: {
    fieldLists: ['maritalStatus'],
    requiredFields: ['maritalStatus'],
    fields: [
      {
        title: 'MARITAL STATUS',
        type: 'dropdown',
        className:
          'border-button-inner-border-color text-button-default-font-color hover:brightness-90 horizontal form-control font-semibold text-[13px] min-w-[100px] min-h-[40px] rounded-[3px]',
        name: 'maritalStatus',
        options: [
          { label: 'Single', value: MartialStatus.MARITAL_STATUS_SINGLE },
          { label: 'Married', value: MartialStatus.MARITAL_STATUS_MARRIED },
          { label: 'Widowed', value: MartialStatus.MARITAL_STATUS_WIDOWED },
          { label: 'Separate', value: MartialStatus.MARITAL_STATUS_SEPARATED },
          { label: 'Divorced', value: MartialStatus.MARITAL_STATUS_DIVORCED },
          {
            label: 'Domestic Partner',
            value: MartialStatus.MARITAL_STATUS_DOMESTIC_PARTNER,
          },
        ],
      },
    ],
  },
}
