import { ResultsOrErrors } from '@augusthealth/models/com/august/protos/api/import_results'
import { SuccessOrErrors } from '@augusthealth/models/com/august/protos/data_import'
import Card from '@shared/components/Card'
import { getFirstAndLastName } from '@shared/utils/humanName'
import styles from './styles.module.css'

export default function PreviewTable({
  results,
}: {
  results: SuccessOrErrors[] | ResultsOrErrors[]
}) {
  return (
    <div className="flex flex-col gap-2">
      {results.map((result, index) => {
        let content
        if (result.error) {
          content = (
            <div className="flex">
              <span className="font-semibold">
                Row {index + 1}
                <i className="fa-solid fa-times-circle text-urgent ml-[8px] mr-[16px]" />
              </span>
              <span
                data-testid={`import-error-${index}`}
                className={styles.errors}
              >
                {result.error!.errors?.map((e) => e.message).join('\n')}
              </span>
            </div>
          )
        } else {
          content = (
            <div>
              <span className="font-semibold">
                {typeof result.success === 'boolean' && (
                  <span data-testid={`import-success-${index}`}>
                    Row {index + 1}
                  </span>
                )}
                {typeof result.success !== 'boolean' && (
                  <span data-testid={`import-preview-${index}`}>
                    {result.success?.person &&
                      getFirstAndLastName(result.success.person.name)}
                    {result.userAccount?.name &&
                      getFirstAndLastName(result.userAccount.name)}
                    {result?.facilityFields?.id &&
                      `Facility ID: ${result.facilityFields.id}`}
                  </span>
                )}
              </span>
              <i className="fa-solid fa-check-circle ml-[8px] text-tags-happy" />
            </div>
          )
        }

        return (
          <Card key={`import-row-${index}`} className="m-0">
            {content}
          </Card>
        )
      })}
    </div>
  )
}
