import {
  ServicePlan_EvaluationOption,
  ServicePlan_LocationOption,
} from '@augusthealth/models/com/august/protos/service_plan'
import React, { useContext, useEffect, useState } from 'react'
import {
  BasicCheckbox,
  BasicInput,
} from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, {
  StyledMultiSelect,
} from '@shared/components/StyledSelect'
import { CategoryKeyIdentifier } from '@shared/types/assessment_configuration'
import { isVirginiaFacility } from '@shared/utils/facilities'
import {
  getCategoriesFromAppraisal,
  getCategoryTitle,
  getMatchingAssessmentCategory,
} from '@shared/utils/residentAssessment'
import { twx } from '@shared/utils/tailwind'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import AssessmentNeeds from './AssessmentNeeds'
import {
  evaluationMethods,
  frequencies,
  locationOptions,
  responsiblePeople,
} from './constants'
import ServicePlanContext from './ServicePlanContext'
import { Action, reducer, State } from './stateManagement'
import { PresetObjectiveText } from './TextboxWithPresetObjectiveText'
import {
  EvaluationOption,
  Frequency,
  LocationOption,
  ResponsiblePerson,
} from './types'

export default function Category({
  planCategory,
  incomplete,
  saveServicePlan,
  setShowEditAssessmentCategory,
}: {
  planCategory: CategoryKeyIdentifier
  incomplete: boolean
  saveServicePlan: (state: State) => Promise<void>
  setShowEditAssessmentCategory: (categoryKey: CategoryKeyIdentifier) => void
}) {
  const [showObjectiveOptions, setShowObjectiveOptions] = useState(false)
  const [showEvaluationTextInput, setShowEvaluationTextInput] = useState(false)
  const [showLocationTextInput, setShowLocationTextInput] = useState(false)
  const [evaluationOtherText, setEvaluationOtherText] = useState<string>()
  const [locationOtherText, setLocationOtherText] = useState<string>()
  const { dispatch, state, servicePlan, objectiveOptions, assessment } =
    useContext(ServicePlanContext)
  const { currentFacility } = useCurrentFacility()
  const facilityIsInVirginia =
    currentFacility?.tag === 'Complete'
      ? isVirginiaFacility(currentFacility.value)
      : false

  const handleOnChange = (action: Action) => {
    dispatchAndMarkChanged(action)

    return saveWithCurrentState(action)
  }

  const saveWithCurrentState = (action: Action) => {
    const newState = reducer(state, action)

    return saveServicePlan(newState)
  }

  const dispatchAndMarkChanged = (action: Action) => {
    dispatch(action)
  }

  const currentFrequency = frequencies.find(
    (f) => f.value === state[planCategory]?.frequency
  )
  const currentPersonsResponsible = responsiblePeople.filter((rp) =>
    (state[planCategory]?.personResponsible || []).includes(rp.value)
  )

  const hasPresetObjectives =
    (state[planCategory]?.objective?.selections || []).length > 0 ||
    showObjectiveOptions

  const evaluationMethod = evaluationMethods.find(
    (f) => f.value === state[planCategory]?.evaluation?.selection
  )

  const locationOption = locationOptions.find(
    (f) => f.value === state[planCategory]?.location?.selection
  )

  const hasEvaluationText =
    state[planCategory]?.evaluation?.selection ===
    ServicePlan_EvaluationOption.EVALUATION_OPTION_OTHER

  const hasLocationText =
    state[planCategory]?.location?.selection ===
    ServicePlan_LocationOption.LOCATION_OPTION_OTHER

  useEffect(() => {
    setShowObjectiveOptions(hasPresetObjectives)
    setShowEvaluationTextInput(hasEvaluationText)
    setShowLocationTextInput(hasLocationText)
    setEvaluationOtherText(state[planCategory]?.evaluation?.text || '')
    setLocationOtherText(state[planCategory]?.location?.text || '')
  }, [hasPresetObjectives, hasEvaluationText])

  if (servicePlan.tag === 'Loading') {
    return null
  }

  const relevantOptions = objectiveOptions.filter(
    (option) => option.categoryKey === planCategory
  )

  const categories =
    (assessment.tag === 'Complete' &&
      getCategoriesFromAppraisal(assessment.value)) ||
    []
  const category = getMatchingAssessmentCategory({
    categoryKey: planCategory,
    categories: categories,
  })
  const categoryName = getCategoryTitle(category!)

  return (
    <div>
      <section className="mb-[40px]">
        <h2 className="mb-[24px] text-[16px] font-semibold uppercase leading-[24px] text-secondary-07">
          {incomplete ? (
            <>
              <i className="fas fa-info-circle mr-[8px] text-tags-alert" />
              {categoryName}
              <span className="ml-[8px] text-[14px] uppercase text-tags-alert">
                Incomplete
              </span>
            </>
          ) : (
            <>
              <i className="fas fa-square mr-[8px] text-august-primary" />
              {categoryName}
            </>
          )}
        </h2>
        <div>
          <AssessmentNeeds
            planCategory={planCategory}
            setShowEditAssessmentCategory={setShowEditAssessmentCategory}
          />
          <LabelAboveInput htmlFor="frequency">Frequency</LabelAboveInput>
          <StyledSelect
            isClearable
            value={currentFrequency}
            options={frequencies}
            onChange={async (selection?: Frequency) => {
              await handleOnChange({
                actionType: 'changeFrequency',
                payload: {
                  category: planCategory,
                  newFrequency: selection?.value,
                },
              })
            }}
          />
        </div>
        <div className="mt-[32px]">
          <LabelAboveInput htmlFor="personResponsible">
            Person Responsible
          </LabelAboveInput>
          <StyledMultiSelect
            value={currentPersonsResponsible}
            options={responsiblePeople}
            onChange={async (selection: ResponsiblePerson[]) => {
              await handleOnChange({
                actionType: 'setResponsiblePeople',
                payload: {
                  category: planCategory,
                  newPeople: selection.map((s) => s.value),
                },
              })
            }}
          />
        </div>
        <div className="mt-[32px]">
          <LabelAboveInput htmlFor="objective">
            Objective/Plan{' '}
            {relevantOptions.length > 0 && (
              <button
                className={twx(
                  'm-0 ml-[8px] border-0 bg-none p-0 text-[12px] font-[600] uppercase leading-[17px]',
                  {
                    'text-alert': showObjectiveOptions,
                    'text-august-primary': !showObjectiveOptions,
                  }
                )}
                type="button"
                onClick={() => {
                  if (showObjectiveOptions) {
                    dispatchAndMarkChanged({
                      actionType: 'clearPresetObjectives',
                      payload: { category: planCategory },
                    })
                  }
                  setShowObjectiveOptions(!showObjectiveOptions)
                }}
              >
                {showObjectiveOptions ? 'Clear Selected' : 'Add Preset Plans'}
              </button>
            )}
          </LabelAboveInput>
          <PresetObjectiveText
            planCategory={planCategory}
            assessmentCategory={category}
            saveServicePlan={saveServicePlan}
          />
          {showObjectiveOptions && (
            <div className="mt-[16px]">
              {relevantOptions.map((presetObjective, ix) => {
                const checked = (
                  state[planCategory].objective?.selections || []
                ).includes(presetObjective.value!)
                return (
                  <BasicCheckbox
                    key={`preset-${ix}`}
                    value={presetObjective.value}
                    checked={checked}
                    onChange={async (e) => {
                      await handleOnChange({
                        actionType: 'changePresetObjective',
                        payload: {
                          category: planCategory,
                          checked: e.currentTarget.checked,
                          objective: presetObjective.value!,
                        },
                      })
                    }}
                  >
                    {presetObjective.label}
                  </BasicCheckbox>
                )
              })}
            </div>
          )}
        </div>
        <div className="mt-[32px]">
          <LabelAboveInput htmlFor="evaluation">
            Evaluation Method
          </LabelAboveInput>
          <StyledSelect
            isClearable
            value={evaluationMethod}
            options={evaluationMethods}
            menuPlacement={'top'}
            onChange={async (selection?: EvaluationOption) => {
              if (
                selection?.value ===
                ServicePlan_EvaluationOption.EVALUATION_OPTION_OTHER
              ) {
                setShowEvaluationTextInput(true)
                await handleOnChange({
                  actionType: 'changeEvaluationMethod',
                  payload: {
                    category: planCategory,
                    kind: 'other',
                    method: selection.value,
                    text: evaluationOtherText || '',
                  },
                })
              } else {
                setShowEvaluationTextInput(false)

                await handleOnChange({
                  actionType: 'changeEvaluationMethod',
                  payload: {
                    category: planCategory,
                    kind: 'preselected',
                    method: selection?.value,
                  },
                })
              }
            }}
          />
          {showEvaluationTextInput && (
            <BasicInput
              className="mt-[8px]"
              placeholder="Enter Evaluation Method"
              value={evaluationOtherText}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setEvaluationOtherText(e.currentTarget.value)
                dispatchAndMarkChanged({
                  actionType: 'changeEvaluationMethod',
                  payload: {
                    category: planCategory,
                    kind: 'other',
                    method:
                      ServicePlan_EvaluationOption.EVALUATION_OPTION_OTHER,
                    text: e.currentTarget.value,
                  },
                })
              }}
              onBlur={async (e: React.FocusEvent<HTMLInputElement>) => {
                await saveWithCurrentState({
                  actionType: 'changeEvaluationMethod',
                  payload: {
                    category: planCategory,
                    kind: 'other',
                    method:
                      ServicePlan_EvaluationOption.EVALUATION_OPTION_OTHER,
                    text: e.currentTarget.value,
                  },
                })
              }}
            />
          )}
          {facilityIsInVirginia && (
            <div className="mt-[32px]">
              <LabelAboveInput htmlFor="location">Location</LabelAboveInput>
              <StyledSelect
                isClearable
                value={locationOption}
                options={locationOptions}
                menuPlacement={'top'}
                onChange={async (selection?: LocationOption) => {
                  if (
                    selection?.value ===
                    ServicePlan_LocationOption.LOCATION_OPTION_OTHER
                  ) {
                    setShowLocationTextInput(true)
                    await handleOnChange({
                      actionType: 'changeLocation',
                      payload: {
                        category: planCategory,
                        kind: 'other',
                        location:
                          ServicePlan_LocationOption.LOCATION_OPTION_OTHER,
                        text: locationOtherText || '',
                      },
                    })
                  } else {
                    setShowLocationTextInput(false)

                    await handleOnChange({
                      actionType: 'changeLocation',
                      payload: {
                        category: planCategory,
                        kind: 'preselected',
                        location: selection?.value,
                        text: null,
                      },
                    })
                  }
                }}
              />
              {showLocationTextInput && (
                <BasicInput
                  className="mt-[8px]"
                  placeholder="Enter Location"
                  value={locationOtherText}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setLocationOtherText(e.currentTarget.value)

                    dispatchAndMarkChanged({
                      actionType: 'changeLocation',
                      payload: {
                        category: planCategory,
                        kind: 'other',
                        location:
                          ServicePlan_LocationOption.LOCATION_OPTION_OTHER,
                        text: e.currentTarget.value,
                      },
                    })
                  }}
                  onBlur={async (e: React.FocusEvent<HTMLInputElement>) => {
                    await saveWithCurrentState({
                      actionType: 'changeLocation',
                      payload: {
                        category: planCategory,
                        kind: 'other',
                        location:
                          ServicePlan_LocationOption.LOCATION_OPTION_OTHER,
                        text: e.currentTarget.value,
                      },
                    })
                  }}
                />
              )}
              <LabelAboveInput className="mt-[32px]" htmlFor="timeframe">
                Expected Outcomes
              </LabelAboveInput>
              <BasicInput
                placeholder="Expected Outcomes"
                value={state[planCategory]?.expectedOutcomes}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  dispatchAndMarkChanged({
                    actionType: 'changeExpectedOutcomes',
                    payload: {
                      category: planCategory,
                      text: e.currentTarget.value,
                    },
                  })
                }}
                onBlur={async (e: React.FocusEvent<HTMLInputElement>) => {
                  await saveWithCurrentState({
                    actionType: 'changeExpectedOutcomes',
                    payload: {
                      category: planCategory,
                      text: e.currentTarget.value,
                    },
                  })
                }}
              />
              <LabelAboveInput className="mt-[32px]" htmlFor="timeframe">
                Timeframe
              </LabelAboveInput>
              <BasicInput
                placeholder="Timeframe"
                value={state[planCategory]?.timeFrame}
                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                  dispatchAndMarkChanged({
                    actionType: 'changeTimeframe',
                    payload: {
                      category: planCategory,
                      text: e.currentTarget.value,
                    },
                  })
                }}
                onBlur={async (e: React.FocusEvent<HTMLInputElement>) => {
                  await saveWithCurrentState({
                    actionType: 'changeTimeframe',
                    payload: {
                      category: planCategory,
                      text: e.currentTarget.value,
                    },
                  })
                }}
              />
            </div>
          )}
        </div>
      </section>
    </div>
  )
}
