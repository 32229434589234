import { RoutineStatus } from '@augusthealth/models/com/august/protos/routine'
import { ServicePlan_PlanCategoryKey } from '@augusthealth/models/com/august/protos/service_plan'
import classNames from 'classnames'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Redirect, useHistory } from 'react-router-dom'
import ButtonLink from '@shared/components/ButtonLink'
import { EmbossedCard } from '@shared/components/EmbossedCard'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import StyledSelect from '@shared/components/StyledSelect'
import useCurrentPage, { extractIds } from '@shared/hooks/useCurrentPage'
import { carePathForPerson } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { DataType } from '@shared/types/snapshot'
import { getCategoryTasks } from '@shared/utils/routine'
import { updateRoutine } from '@app/api/routines'
import ConfirmModal, {
  Props as ConfirmModalProps,
} from '@app/components/ConfirmModal'
import FullpageFormFooter from '@app/components/FullpageFormFooter'
import PersonPageTitle from '@app/components/PersonPageTitle'
import PersonContext from '@app/contexts/PersonContext'
import useAssessment from '@app/hooks/useAssessment'
import { useRoutine, useRoutineOptions } from '@app/hooks/useRoutines'
import styles from './styles.module.css'
import { recreateTaskAndReturnUrl } from '../Documents/helpers'
import { toFullDescription } from './components/RoutineDescription'
import { EditAssessmentCategory } from './EditAssessmentCategory'
import Schedule from './Schedule'
import {
  isEmptySchedule,
  isEmptyTiming,
  RoutineSchedule,
  scheduleToTiming,
} from './Schedule/helpers'

export type Description = {
  categoryKey: ServicePlan_PlanCategoryKey
  categoryTitle: string
  categoryLevel: string | undefined
  details: string[]
  options: (string | undefined)[]
  notes: string | undefined
}

export default function ScheduleRoutine() {
  const [confirmationModalProps, setConfirmationModalProps] =
    useState<ConfirmModalProps>()
  const history = useHistory()
  const page = useCurrentPage()
  const { routineId, facilityId, orgId, personId } = extractIds(page)
  const { person = { id: personId, facilityId, orgId } as Person } =
    useContext(PersonContext)
  const [schedule, setSchedule] = useState<RoutineSchedule>()

  const { routine: loadingRoutine, refreshRoutine } = useRoutine({
    person: person,
    routineId: routineId!,
  })
  const { assessment, refreshAssessment } = useAssessment({
    person: person,
    withOriginalSettings: true,
  })
  const allowInlineEdits =
    assessment.tag === 'Complete' &&
    assessment.value.newerSettingsAvailable === false

  const [showEditAssessmentCategory, setShowEditAssessmentCategory] =
    useState<ServicePlan_PlanCategoryKey>()

  const { routineOptions } = useRoutineOptions({ person: person })

  const { handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: {},
  })

  if (
    loadingRoutine.tag === 'Loading' ||
    person === undefined ||
    routineOptions.tag === 'Loading' ||
    assessment.tag === 'Loading'
  ) {
    return <></>
  }

  const routine = loadingRoutine.value

  if (
    routine.status &&
    ![
      RoutineStatus.ROUTINE_STATUS_DISCARDED,
      RoutineStatus.ROUTINE_STATUS_DRAFT,
      RoutineStatus.ROUTINE_STATUS_ACTIVE,
    ].includes(routine.status)
  ) {
    return <Redirect to={carePathForPerson(person as Required<Person>)} />
  }

  async function onSubmit() {
    const timing = scheduleToTiming(schedule!)

    await updateRoutine({
      person: person,
      routine: {
        ...routine,
        timing: isEmptyTiming(timing) ? null : timing,
        status: RoutineStatus.ROUTINE_STATUS_ACTIVE,
      },
    })

    history.push(carePathForPerson(person as Required<Person>))
  }

  const fullDescription: Description[] = toFullDescription(
    getCategoryTasks(routine)
  )

  async function handleEdit(d: Description) {
    if (allowInlineEdits) {
      setShowEditAssessmentCategory(d.categoryKey)
    } else {
      setConfirmationModalProps((existing) => ({
        ...existing,
        title: 'Editing not available',
        content:
          'The assessment questions changed since your last edits. To make edits, start a new assessment.',
        confirmButtonConfig: {
          children: 'Start new assessment',
          buttonStyle: 'danger-fill',
          onClick: async () => {
            const url = await recreateTaskAndReturnUrl(
              person!,
              undefined,
              DataType.DATA_TYPE_AUGUST_INITIAL_APPRAISAL
            )
            history.push(url)
          },
        },
        denyButtonConfig: {
          onClick: () => setConfirmationModalProps(undefined),
        },
      }))
    }
  }

  return (
    <div className={`mt-[32px] ${styles.formContainer}`}>
      <PersonPageTitle title={'Schedule Routine'} />
      <form
        className="mt-[32px] flex flex-col"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col">
          <LabelAboveInput
            className="horizontal baseline"
            htmlFor="name"
            subLabel={requiredLabel(false)}
          >
            Name
          </LabelAboveInput>
          <StyledSelect
            isDisabled={true}
            value={{
              label: routine.name,
              value: routine.name,
            }}
            options={[]}
          />
        </div>

        <div className="mt-[32px] flex flex-col">
          <LabelAboveInput
            className="horizontal baseline"
            htmlFor="instructions"
          >
            Instructions
          </LabelAboveInput>
          <EmbossedCard>
            {fullDescription.map((d, i) => {
              const lastOne = fullDescription.length - 1 === i
              const sectionClasses = classNames(
                'text-[14px] leading-[20px] font-bold',
                {
                  'mb-[24px]': !lastOne,
                }
              )

              return (
                <section
                  className={sectionClasses}
                  key={`${d.categoryTitle}-${i}`}
                >
                  <div className={'uppercase'}>
                    {d.categoryTitle}
                    <ButtonLink
                      className={'ml-[8px]'}
                      onClick={async () => handleEdit(d)}
                    >
                      edit
                    </ButtonLink>
                  </div>
                  <div className={'mt-[8px] font-medium'}>
                    <span className={'font-semibold'}>{d.categoryLevel}</span>
                    {d.notes && <span> &ndash; {d.notes}</span>}
                    <ul className={styles.fullInstructions}>
                      {d.details.map((d, j) => (
                        <li key={`${routine.id}-${j}`}>
                          <span>{d}</span>
                        </li>
                      ))}
                      {d.options.map((o, j) => (
                        <li key={`${routine.id}-${j}`}>
                          <span>{o}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </section>
              )
            })}
          </EmbossedCard>
        </div>
        <div className="mb-[128px] mt-[32px] flex flex-col">
          <Schedule onChange={setSchedule} routine={routine} />
        </div>
        <FullpageFormFooter
          yesBtn={{
            text: 'Save',
            props: {
              id: 'saveRoutine',
              disabled: schedule && isEmptySchedule(schedule),
            },
          }}
          noBtn={{
            text: 'Cancel',
            action: async () => {
              history.push(carePathForPerson(person as Required<Person>))
            },
          }}
          formState={formState}
        />
      </form>
      {showEditAssessmentCategory && (
        <EditAssessmentCategory
          person={person}
          categoryKey={showEditAssessmentCategory}
          assessment={assessment.value}
          onComplete={async (updated) => {
            if (updated) {
              await refreshRoutine()
              await refreshAssessment()
            }

            setShowEditAssessmentCategory(undefined)
          }}
        />
      )}
      {confirmationModalProps && <ConfirmModal {...confirmationModalProps} />}
    </div>
  )
}
