import { logoutAndRedirect } from '@shared/components/Auth/Auth'
import { useUserContext } from '@shared/contexts/UserContext'
import { loginPath, MAIN_APP_BASE_PATH } from '@shared/routes'
import { useInactivityTimer } from '@app/hooks/useInactivityTimer'
import { BasicOverlay } from './BasicOverlay'

type Props = {
  timeout: number
  forceShow?: boolean
}
export const IdleOverlay = ({ timeout }: Props) => {
  const { user } = useUserContext()

  const { state, handleStillHere } = useInactivityTimer({ timeout, user })

  const logInAsSomeoneElse = async () => {
    await logoutAndRedirect(loginPath(MAIN_APP_BASE_PATH))
  }

  if (state === 'Active') {
    return null
  }

  return (
    <BasicOverlay
      user={user}
      handleStillHere={handleStillHere}
      logInAsSomeoneElse={logInAsSomeoneElse}
    />
  )
}
