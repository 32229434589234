import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { format } from 'date-fns'
import localForage from 'localforage'
import { capitalize, upperCase } from 'lodash'
import { download } from '@shared/api/legacy'
import { hasPermissionForFacility } from '@shared/components/PermissionGates/PermissionGates'
import { FeatureFlagNames } from '@shared/constants/feature_flags'
import { Facility } from '@shared/types/facility'
import { Person } from '@shared/types/person'
import { PersonStats } from '@shared/types/person_stats'
import { UserAccount } from '@shared/types/user'
import { Order } from '@shared/utils/common'
import { getFirstName, getLastName } from '@shared/utils/humanName'
import {
  sortPeople,
  sortPeopleByCareGroup,
  sortPeopleByCompletedTaskCounter,
  sortPeopleByLastModification,
  sortPeopleByLevelOfCare,
  sortPeopleByMoveInDate,
  sortPeopleByMoveOutDate,
  sortPeopleByMoveOutReason,
  sortPeopleByRoomNumber,
} from '@shared/utils/personStats'
import { isFeatureAllowed } from '@shared/utils/user'
import { careNeedsUrl, routineScheduleUrl } from '@app/api/routines'

export enum ResidentTableColumn {
  NAME = 'Name',
  MOVE_IN_DATE = 'Move-In Date',
  ROOM = 'Room',
  CARE = 'Care',
  GROUP = 'Group',
  LAST_UPDATE = 'Last Update',
}

export enum MovedOutResidentTableColumn {
  NAME = 'Name',
  MOVE_OUT_DATE = 'Move-Out Date',
  REASON = 'Reason',
}

export enum ProspectTableColumn {
  NAME = 'Name',
  MOVE_IN_DATE = 'Desired Move-In Date',
  COMPLETED_TASKS = 'Completed Tasks',
  LAST_UPDATE = 'Last Update',
}

export type NameToSort = 'LastName' | 'FirstName'

export type ColumnName =
  | ResidentTableColumn
  | ProspectTableColumn
  | MovedOutResidentTableColumn

type SortFunc = (list: PersonStats[], order: Order) => PersonStats[]

export interface TableHeaderData {
  className?: string
  label: ColumnName
  sortFunc: SortFunc
}

export const STORAGE_NAME_TO_SORT = 'nameToSort'
export const STORAGE_PREVIOUS_COLUMN = 'previousColumn'

/**
 * sortPeopleByName takes extra parameter 'nameToSort' to decide if sorting by 'LastName' or 'FirstName'
 * @param {PersonStats[]} residents
 * @param {Order} order
 * @param {NameToSort} nameToSort
 * @returns {Promise<PersonStats[]>}
 */

export function sortPeopleByName(
  residents: PersonStats[],
  order: Order,
  nameToSort?: NameToSort
): PersonStats[] {
  const sortFunc =
    nameToSort === 'FirstName'
      ? (person: Person) => getFirstName(person.name)
      : (person: Person) => getLastName(person.name)

  return sortPeople(residents, sortFunc, order)
}

function getTableHeaderData(
  columnName: ColumnName,
  func: SortFunc,
  className?: string
) {
  return {
    className,
    label: columnName,
    sortFunc: (residents: PersonStats[], order: Order) => {
      if (columnName !== 'Name') {
        void localForage.setItem(STORAGE_NAME_TO_SORT, 'LastName')
        void localForage.setItem(STORAGE_PREVIOUS_COLUMN, columnName)
      }

      return func(residents, order)
    },
  }
}

export const RESIDENT_THEAD_DATA: TableHeaderData[] = [
  getTableHeaderData(ResidentTableColumn.NAME, sortPeopleByName, 'w-[30%]'),
  getTableHeaderData(
    ResidentTableColumn.MOVE_IN_DATE,
    sortPeopleByMoveInDate,
    'w-[20%]'
  ),
  getTableHeaderData(ResidentTableColumn.ROOM, sortPeopleByRoomNumber),
  getTableHeaderData(ResidentTableColumn.CARE, sortPeopleByLevelOfCare),
  getTableHeaderData(ResidentTableColumn.GROUP, sortPeopleByCareGroup),
  getTableHeaderData(
    ResidentTableColumn.LAST_UPDATE,
    sortPeopleByLastModification,
    'w-[33%]'
  ),
]

export const MOVED_OUT_RESIDENT_THEAD_DATA: TableHeaderData[] = [
  getTableHeaderData(MovedOutResidentTableColumn.NAME, sortPeopleByName),
  getTableHeaderData(
    MovedOutResidentTableColumn.MOVE_OUT_DATE,
    sortPeopleByMoveOutDate
  ),
  getTableHeaderData(
    MovedOutResidentTableColumn.REASON,
    sortPeopleByMoveOutReason
  ),
]

export const PROSPECT_THEAD_DATA: TableHeaderData[] = [
  getTableHeaderData(ProspectTableColumn.NAME, sortPeopleByName),
  getTableHeaderData(ProspectTableColumn.MOVE_IN_DATE, sortPeopleByMoveInDate),
  getTableHeaderData(
    ProspectTableColumn.COMPLETED_TASKS,
    sortPeopleByCompletedTaskCounter
  ),
  getTableHeaderData(
    ProspectTableColumn.LAST_UPDATE,
    sortPeopleByLastModification
  ),
]

function mapLabel(data: TableHeaderData) {
  const { className, label } = data
  return { className, label }
}
export const prospectHeaders = PROSPECT_THEAD_DATA.map(mapLabel)
export const residentHeaders = RESIDENT_THEAD_DATA.map(mapLabel)
export const movedOutResidentHeaders =
  MOVED_OUT_RESIDENT_THEAD_DATA.map(mapLabel)

export function getResidentSortFunc(column: ResidentTableColumn) {
  const data = RESIDENT_THEAD_DATA.find((r) => r.label === column)
  return data?.sortFunc || sortPeopleByName
}

export function getMovedOutResidentSortFunc(
  column: MovedOutResidentTableColumn
) {
  const data = MOVED_OUT_RESIDENT_THEAD_DATA.find((r) => r.label === column)
  return data?.sortFunc || sortPeopleByName
}

export function getProspectSortFunc(column: ProspectTableColumn) {
  const data = PROSPECT_THEAD_DATA.find((r) => r.label === column)
  return data?.sortFunc || sortPeopleByName
}

export function routineMenuItems({
  facility,
  user,
  setError,
}: {
  facility: Required<Pick<Facility, 'orgId' | 'id'>>
  user: UserAccount
  setError: (e: any) => void
}) {
  const { orgId, id: facilityId } = facility
  const includesAssessmentRoutineOrder = isFeatureAllowed(
    user,
    FeatureFlagNames.ASSESSMENT_ROUTINES_DEBUG
  )

  return (
    ['laundry', 'housekeeping', 'shower']
      .map((type) => {
        const capitalized = capitalize(type)

        return {
          isDisabled: false,
          isVisible: hasPermissionForFacility({
            user: user!,
            facility: { orgId, id: facilityId },
            permissions: [GroupPermission.GROUP_PERMISSION_ROUTINE_READ],
          }),
          dataTestId: `download-${type}-schedule-btn`,
          id: `download${capitalized}Schedule`,
          onClick: async () => {
            try {
              const fileUrl = routineScheduleUrl({
                facility: { orgId, id: facilityId },
                type: type as 'housekeeping' | 'laundry' | 'shower',
                options: { includesAssessmentRoutineOrder },
              })

              await download({
                fileUrl,
                target: '_blank',
              })
            } catch (e) {
              setError(e)
            }
          },
          title: `${capitalized} Schedule`,
        }
      })
      // TODO: Unify this with the above housekeeping, laundry, shower types
      .concat([
        {
          isDisabled: false,
          isVisible: hasPermissionForFacility({
            user: user!,
            facility: { orgId, id: facilityId },
            permissions: [GroupPermission.GROUP_PERMISSION_ROUTINE_READ],
          }),
          dataTestId: `download-care-needs-btn`,
          id: `downloadCareNeeds`,
          onClick: async () => {
            try {
              const fileUrl = careNeedsUrl(
                { orgId, id: facilityId },
                { includesAssessmentRoutineOrder }
              )

              await download({
                fileUrl,
                target: '_blank',
              })
            } catch (e) {
              setError(e)
            }
          },
          title: 'Care Needs',
        },
      ])
  )
}

export function setCareSummaryReportFileName({
  facilityName,
}: {
  facilityName: string | undefined
}) {
  const dateStamp = format(new Date(), 'MM-dd-yyyy_HH:MMaa')
  const prefix = facilityName ? `${upperCase(facilityName)}_` : ''

  return `${prefix}CARE_SUMMARIES_${dateStamp}.zip`
}

/**
 * Set nameToSort state either FirstName or LastName in State and localForage
 */

export function onNameSortingToggle({
  previousColumn,
  nameToSort,
  sortingOrder,
  setNameToSort,
  setPreviousColumn,
}: {
  previousColumn: ColumnName | undefined
  nameToSort: NameToSort
  sortingOrder: Order
  setNameToSort: (name: NameToSort) => void
  setPreviousColumn: (column: ColumnName) => void
}) {
  if (previousColumn === 'Name') {
    if (nameToSort === 'LastName') {
      if (sortingOrder === Order.DESC) {
        // Second click / Last Name / DESC / Next click will sort by First Name
        void localForage.setItem(STORAGE_NAME_TO_SORT, 'FirstName')
        setNameToSort('FirstName')
      }
    } else if (nameToSort === 'FirstName') {
      if (sortingOrder === Order.DESC) {
        // Forth click / First Name / DESC / Next click will sort by Last Name
        void localForage.setItem(STORAGE_NAME_TO_SORT, 'LastName')
        setNameToSort('LastName')
      } // else Third click / First Name / ASC
    }
  } else {
    // First click / Last Name / ASC
    void localForage.setItem(STORAGE_PREVIOUS_COLUMN, 'Name')
    setPreviousColumn('Name' as ColumnName)
  }
}
