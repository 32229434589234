import {
  AssessmentCategory,
  AssessmentConfig,
} from '@shared/types/assessment_configuration'
import { categoryKeyToPropertyMap } from '@shared/utils/assessmentConfiguration'
import { FrontendDetail } from '@app/pages/Tasks/ResidentAssessment/AssessmentPage/CheckboxSections/utils'
import { DetailGroupCard } from './DetailGroupCard'
import {
  addNewDetail,
  generateUpdatedDetails,
  removeExistingDetail,
  removeExistingDetailGroup,
  replaceExistingDetail,
} from './helpers'

type Props = {
  updatedDetails: Map<string, FrontendDetail[]>
  selectedCategory: AssessmentCategory
  setUpdatedDetails: (updatedDetails: Map<string, FrontendDetail[]>) => void
  setAssessmentConfig: (assessmentConfig: AssessmentConfig) => void
  currentAssessmentConfig: AssessmentConfig
  categoryIsMarkedForDelete: boolean
}
export const DetailGroups = ({
  updatedDetails,
  selectedCategory,
  setUpdatedDetails,
  setAssessmentConfig,
  currentAssessmentConfig,
  categoryIsMarkedForDelete,
}: Props) => {
  const updateParent = (
    updatedDetails: Map<string, FrontendDetail[]>
  ): void => {
    setUpdatedDetails(updatedDetails)
    setAssessmentConfig({
      ...currentAssessmentConfig,
      [selectedCategory.categoryKey]: {
        ...currentAssessmentConfig[selectedCategory.categoryKey],
        details: updatedDetails,
      },
    })
  }

  const updateDetail = (eventDetails: {
    value: string | number
    index: number
    key: string
    optIndex?: number
    toUpdate:
      | 'description'
      | 'score'
      | 'meta-description'
      | 'helpText'
      | 'isRequired'
  }) => {
    const newDetails = generateUpdatedDetails({
      ...eventDetails,
      currentDetails: updatedDetails,
    })
    updateParent(newDetails)
  }

  const addDetail = ({
    newDetail,
    key,
  }: {
    newDetail: Partial<FrontendDetail>
    key: string
  }) => {
    const newDetails = addNewDetail({
      newDetail,
      key,
      currentDetails: updatedDetails,
      detailGroupProperty:
        categoryKeyToPropertyMap[selectedCategory.categoryKey],
    })
    updateParent(newDetails)
  }

  const editDetailFromModal = ({
    updatedDetail,
    index,
    optIndex,
  }: {
    updatedDetail: Partial<FrontendDetail>
    index: number
    optIndex?: number
  }) => {
    const newDetails = replaceExistingDetail({
      updatedDetail,
      index,
      optIndex,
      currentDetails: updatedDetails,
    })
    updateParent(newDetails)
  }

  const removeDetail = ({
    index,
    optIndex,
    key,
  }: {
    index: number
    optIndex?: number
    key: string
  }) => {
    const newDetails = removeExistingDetail({
      index,
      optIndex,
      currentDetails: updatedDetails,
      key,
    })
    updateParent(newDetails)
  }

  const removeDetailGroup = ({ key }: { key: string }) => {
    const newDetails = removeExistingDetailGroup({
      key,
      currentDetails: updatedDetails,
      selectedCategory,
    })
    updateParent(newDetails)
  }

  return (
    <>
      {[...updatedDetails.entries()].map((entry) => {
        const [key, value] = entry

        const groupWasDeleted =
          [
            ...(selectedCategory.details ?? []),
            ...(selectedCategory.customDetails ?? []),
          ].some((group) => group.group === key) && value.length === 0

        if (groupWasDeleted) {
          return null
        }

        return (
          <DetailGroupCard
            key={`${selectedCategory?.categoryKey}-${key}-card`}
            detailGroupKey={key}
            selectedCategory={selectedCategory}
            value={value}
            updatedDetails={updatedDetails}
            updateDetail={updateDetail}
            editDetailFromModal={editDetailFromModal}
            addDetail={addDetail}
            removeDetail={removeDetail}
            removeDetailGroup={removeDetailGroup}
            categoryIsMarkedForDelete={categoryIsMarkedForDelete}
          />
        )
      })}
    </>
  )
}
