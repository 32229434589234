import { identity, isMatchWith } from 'lodash'
import { useContext, useEffect } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { FeatureFlag } from '@shared/constants/feature_flags'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import { useUserContext } from '@shared/contexts/UserContext'
import useCurrentPage, { extractIds } from '@shared/hooks/useCurrentPage'
import { Facility } from '@shared/types/facility'
import { GroupPermission } from '@shared/types/permission'
import { hasPermissions as HasGroupPermissionForToolUsers } from '@shared/utils/permisson'
import {
  isAnalyst,
  isFeatureAllowed,
  isSuperUser,
  isToolsUser,
} from '@shared/utils/user'

export const TAB_SEPARATOR = ' › '

type Props = RouteProps & {
  minimalPermissions: GroupPermission[]
  featureFlags?: FeatureFlag[]
  additionalFacilityChecks?: Array<(facility: Facility) => boolean>
  title?: string
}

function GatedRouteBase({
  minimalPermissions,
  featureFlags = [],
  title,
  allowFacilityLevelPermissions,
  additionalFacilityChecks = [],
  superUserOnly = false,
  ...routeProps
}: Props & {
  allowFacilityLevelPermissions: boolean
  superUserOnly?: boolean
}) {
  const currentPage = useCurrentPage()
  const { currentFacility } = useContext(CurrentFacilityContext)

  const facility =
    currentFacility?.tag === 'Complete' ? currentFacility.value : undefined
  const { user } = useUserContext()

  useEffect(() => {
    const getDocumentTitle = (postfix: string) => {
      const list = [facility?.name, postfix]
      return list.filter((item) => item).join(TAB_SEPARATOR)
    }

    if (title) {
      document.title = getDocumentTitle(title)
    }
  }, [title, facility?.id, facility?.name])

  // Super Users can access everything
  if (isSuperUser(user)) {
    return <Route {...routeProps} />
  } else if (superUserOnly) {
    return <Redirect to={'/'} />
  }

  const hasFeaturePermission = featureFlags
    .map((flag) => isFeatureAllowed(user, flag))
    .every(identity)

  if (isToolsUser(user) || isAnalyst(user)) {
    if (
      hasFeaturePermission &&
      HasGroupPermissionForToolUsers({ user, permissions: minimalPermissions })
    ) {
      return <Route {...routeProps} />
    }
  }

  const {
    orgId: routeOrgId,
    facilityId: routeFacilityId,
    personId: routePersonId,
  } = extractIds(currentPage)

  const hasGroupPermission = (user.groups || []).find((g) => {
    const { organizationId, facilityId, personId } = g.personMatcher || {}
    return (
      minimalPermissions.every((p) => g.groupPermissions?.includes(p)) &&
      isMatchWith(
        {
          organizationId: routeOrgId,
          facilityId: routeFacilityId,
          personId: routePersonId,
        },
        {
          organizationId,
          facilityId: allowFacilityLevelPermissions ? undefined : facilityId,
          personId,
        },
        (pathValue, matcherValue) =>
          pathValue === matcherValue || matcherValue === undefined
      )
    )
  })

  const meetsAdditionalFacilityChecks = additionalFacilityChecks.every(
    (check) => facility && check(facility)
  )

  if (
    hasGroupPermission &&
    hasFeaturePermission &&
    meetsAdditionalFacilityChecks
  ) {
    return <Route {...routeProps} />
  }

  return <Redirect to={'/'} />
}

/*
 * Allows users with facility level permissions access to a org level path.
 * Ex. /orgs/:orgId/dashboard allows users with facility level access
 */
export function FacilityGatedRoute(props: Props) {
  return <GatedRouteBase allowFacilityLevelPermissions={true} {...props} />
}

export default function GatedRoute(props: Props) {
  return <GatedRouteBase allowFacilityLevelPermissions={false} {...props} />
}

export function SuperUserOnlyRoute(props: Omit<Props, 'minimalPermissions'>) {
  return (
    <GatedRouteBase
      allowFacilityLevelPermissions={false}
      minimalPermissions={[]}
      superUserOnly
      {...props}
    />
  )
}
