import { FieldOption } from '@augusthealth/models/com/august/protos/api/json_report'
import { useContext, useEffect, useState } from 'react'
import Card from '@shared/components/Card'
import StyledSelect from '@shared/components/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Loading } from '@shared/utils/loading'
import { listAvailableFields } from '@app/api/reports'
import styles from './styles.module.css'
import FieldPicker from './FieldPicker'
import FilterBy from './Filter'
import {
  CategoryGroup,
  Field,
  FieldOption as LocalFieldOption,
  ReportFields,
} from './models'
import ReportPreviewer from './ReportPreview'
import { FieldOptionComponent, SingleValue } from './SelectComponents'

export default function ReportGenerator({
  afterCreate,
}: {
  afterCreate: () => void
}) {
  const { setError } = useContext(GlobalContext)

  const [fieldOptions, setFieldOptions] = useState<Loading<CategoryGroup[]>>()

  useEffect(() => {
    setFieldOptions({ tag: 'Loading' })
    listAvailableFields()
      .then((fieldOptions) =>
        setFieldOptions({
          tag: 'Complete',
          value: convertOptions(fieldOptions),
        })
      )
      .catch(setError)
  }, [])

  function convertOptions(fieldOptions: FieldOption[]): CategoryGroup[] {
    const optionsMap = fieldOptions.reduce<Map<string, CategoryGroup>>(
      (res, next) => {
        const display = next.displayInfo!
        const groupList = res.get(display.category) || {
          label: display.category,
          options: [],
        }
        groupList.options.push({
          label: display,
          value: next.accessor?.augustField || next.accessor!.reportField!,
        })
        res.set(display.category, groupList)
        return res
      },
      new Map<string, CategoryGroup>()
    )
    return Array.from(optionsMap.values())
  }
  if (fieldOptions?.tag === 'Complete') {
    return (
      <ReportGeneratorImpl
        afterCreate={afterCreate}
        fieldOptions={fieldOptions.value}
      />
    )
  }
  return <div>Loading...</div>
}

function ReportGeneratorImpl({
  afterCreate,
  fieldOptions,
}: {
  afterCreate: () => void
  fieldOptions: CategoryGroup[]
}) {
  const [report, setReport] = useState<ReportFields>({ fields: [] })

  const addField = () => {
    setReport({
      ...report,
      fields: [...report.fields, { columnTitle: '', fieldOption: [] }],
    })
  }
  const updateField = (index: number, newValue: Field) => {
    report.fields[index] = newValue
    setReport({ ...report })
  }

  return (
    <div>
      <div className="page-title mt-[24px]">Create Reports</div>
      <div className={styles.reportGrid}>
        <section className="mb-[40px]">
          <label className="form-control-label">
            <div className="form-element-title uppercase">
              Select Report Fields
            </div>
          </label>
          <Card className={'flex-col'}>
            {report.fields.map((f, i) => (
              <FieldPicker
                key={i}
                fieldOptions={fieldOptions}
                field={f}
                onChange={(v) => updateField(i, v)}
              />
            ))}
            <button
              className="rounded-[6px] border border-button-tertiary-color bg-button-default-bg-color px-[12px] py-[11px] text-[11px] font-bold uppercase leading-[14px] text-button-tertiary-color hover:bg-button-tertiary-color hover:text-button-fill-font-color"
              onClick={addField}
            >
              <i className="fa-solid fa-plus-circle mr-[8px]" />
              Add Column
            </button>
          </Card>
          <section className="mt-[24px]">
            <label className="form-control-label">
              <div className="form-element-title uppercase">Filter & Sort</div>
            </label>
            <Card className={'flex-col'}>
              <div className="mb-[24px] flex items-center">
                <div className="mr-[8px] w-[64px] font-semibold">Sort By</div>
                <div className="grow">
                  <StyledSelect
                    isClearable
                    placeholder="Select a field..."
                    components={{
                      Option: FieldOptionComponent,
                      SingleValue,
                    }}
                    options={fieldOptions}
                    onChange={(e: LocalFieldOption) => {
                      setReport((prev) => ({
                        ...prev,
                        orderByField: e?.value,
                      }))
                    }}
                  />
                </div>
              </div>
              <FilterBy fieldOptions={fieldOptions} setReport={setReport} />
            </Card>
          </section>
        </section>
        <section>
          <ReportPreviewer afterCreate={afterCreate} report={report} />
        </section>
      </div>
    </div>
  )
}
