import { useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Card from '@shared/components/Card'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { Person } from '@shared/types/person'
import { getFirstAndLastName } from '@shared/utils/humanName'
import ConfirmModal from '@app/components/ConfirmModal'
import HUD from '@app/components/HUD'
import { OrgFacilityPersonSelects } from '../SharedSelects/OrgFacilityPersonSelects'
import { OrgFacilitySelects } from '../SharedSelects/OrgFacilitySelects'
import { transferResident } from './helpers'

interface RecentlyTransferred {
  name: string
  fromFacility: string
  toFacility: string
}
export default function ResidentTransfer() {
  const [fromOrg, setFromOrg] = useState<OptionTypeBase<Organization> | null>(
    null
  )
  const [fromFacility, setFromFacility] =
    useState<OptionTypeBase<Facility> | null>(null)
  const [person, setPerson] = useState<OptionTypeBase<Person> | null>(null)
  const [toOrg, setToOrg] = useState<OptionTypeBase<Organization> | null>(null)
  const [toFacility, setToFacility] = useState<OptionTypeBase<Facility> | null>(
    null
  )

  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [transferStarted, setTransferStarted] = useState(false)
  const [recentlyTransferred, setRecentlyTransferred] = useState<
    RecentlyTransferred[]
  >([])

  const formIsIncomplete =
    !fromOrg || !fromFacility || !person || !toOrg || !toFacility

  const personName = person?.value
    ? getFirstAndLastName(person.value.name)
    : undefined
  const fromFacilityName = fromFacility?.value
    ? fromFacility.value.name
    : undefined
  const toFacilityName = toFacility?.value ? toFacility.value.name : undefined

  const confirmTransfer = async () => {
    await transferResident({
      oldOrgId: fromOrg!.value.id,
      oldFacilityId: fromFacility!.value.id,
      personId: person!.value!.id!,
      newFacilityId: toFacility!.value.id,
      newOrgId: toOrg!.value.id,
    })

    setRecentlyTransferred((prevState) => [
      ...prevState,
      {
        name: personName!,
        fromFacility: fromFacilityName!,
        toFacility: toFacilityName!,
      },
    ])
    setShowConfirm(false)
    setTransferStarted(true)
  }

  return (
    <div className="content-holder">
      <h1 className="page-title mt-[24px]">Resident Copy/Transfer</h1>
      {transferStarted && (
        <HUD onExpire={() => setTransferStarted(false)}>
          Resident copy/transfer started
        </HUD>
      )}
      <div
        style={{
          display: 'grid',
          gap: '24px',
          gridTemplateRows: '1fr 1fr',
        }}
      >
        <form
          style={{
            display: 'grid',
            gap: '24px',
            gridTemplateColumns: '1fr 1fr',
          }}
        >
          <section>
            <h2 className="text-[20px] font-semibold leading-[24px]">
              Copy From
            </h2>
            <Card className="h-full flex-col justify-between">
              <OrgFacilityPersonSelects
                onOrgSelect={(e) => {
                  setFromOrg(e!)
                  setToOrg(e!)
                  setFromFacility(null)
                }}
                onFacilitySelect={(e) => {
                  setFromFacility(e)
                  setPerson(null)
                }}
                onPersonSelect={(e) => {
                  setPerson(e)
                }}
                selectedOrganization={fromOrg}
                selectedFacility={fromFacility}
                selectedPerson={person}
              />
            </Card>
          </section>
          <section>
            <h2 className="text-[20px] font-semibold leading-[24px]">
              Transfer To
            </h2>
            <Card className="h-full flex-col justify-between">
              <OrgFacilitySelects
                onOrgSelect={(e) => {
                  setToOrg(e)
                  setToFacility(null)
                }}
                selectedOrganization={toOrg}
                onFacilitySelect={(e) => {
                  setToFacility(e)
                }}
                selectedFacility={toFacility}
              />
              <AsyncIconButton
                isLoading={false}
                disabled={formIsIncomplete}
                buttonStyle="primary-fill"
                className="mt-[24px]"
                type="button"
                onClick={() => setShowConfirm(true)}
              >
                Transfer
              </AsyncIconButton>
            </Card>
          </section>
        </form>
        {recentlyTransferred.length > 0 && (
          <section>
            <h2 className="mt-[32px] text-[20px] font-semibold leading-[24px]">
              Copy/Transfer started for...
            </h2>
            <ul>
              {recentlyTransferred.map((rt, i) => (
                <li key={`${rt.name}-${i}`}>
                  {rt.name} from {rt.fromFacility} to {rt.toFacility}
                </li>
              ))}
            </ul>
          </section>
        )}
      </div>
      {showConfirm && (
        <ConfirmModal
          title={`Copy/Transfer ${personName}?`}
          content={
            <>
              <h3 className="text-center text-[18px] leading-[24px] text-gray-07">
                {fromFacilityName}
                <i className="fa-solid fa-arrow-right ml-[8px] mr-[8px]" />
                {toFacilityName}
              </h3>
              <p>
                Once the copy/transfer is complete, {personName} will be in both
                communities. You may want to ask{' '}
                <span className="font-semibold">{fromFacilityName}</span> to
                close the original {personName}.
              </p>
            </>
          }
          confirmButtonConfig={{
            buttonStyle: 'primary-fill',
            children: 'transfer',
            onClick: confirmTransfer,
          }}
          denyButtonConfig={{
            onClick: () => setShowConfirm(false),
          }}
        />
      )}
    </div>
  )
}
