import { intersection } from 'lodash'
import { DeepPartial } from 'react-hook-form'
import { AdmissionsInformation_AdmissionType } from '@shared/types/admissions'
import { AllergyIntolerance } from '@shared/types/allergy_intolerance'
import { Condition } from '@shared/types/condition'
import { Contact, Contact_ContactRelationship } from '@shared/types/contact'
import {
  ContactPoint,
  ContactPoint_ContactPointSystem,
} from '@shared/types/contact_point'
import { Coverage_CoverageType } from '@shared/types/coverage'
import { DateMessage } from '@shared/types/date'
import { Gender } from '@shared/types/gender'
import { Person, ResidentStatus } from '@shared/types/person'
import { Polst_CPR } from '@shared/types/snapshot'
import { PickPartial } from '@shared/types/utilities'
import { getPhone as getContactPhone } from '@shared/utils/contactPoint'
import { fromDateMessageToDate } from '@shared/utils/date'
import { DEMENTIA_CODE } from '@shared/utils/diagnosis'
import {
  getHospitalAddress,
  getHospitalFax,
  getHospitalPhone,
} from '@shared/utils/healthcareService'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { escapeStringRegexp } from '@shared/utils/regex'

export function getProfileSvgPath(
  person?: PickPartial<Person, 'gender'>
): string {
  switch (person?.gender) {
    case Gender.GENDER_FEMALE:
      return '/svg/illustration-gender--female.svg'
    case Gender.GENDER_MALE:
      return '/svg/illustration-gender--male.svg'
    default:
      return '/svg/illustration-gender--neutral.svg'
  }
}

export function getHospitalInfo(person: PickPartial<Person, 'hospital'>) {
  const hospital = person.hospital

  if (hospital === undefined) {
    return {
      hospitalPhone: undefined,
      hospitalFax: undefined,
      hospitalAddress: undefined,
      hospitalName: undefined,
    }
  }

  return {
    hospitalName: hospital.name,
    hospitalPhone: getHospitalPhone(hospital),
    hospitalFax: getHospitalFax(hospital),
    hospitalAddress: getHospitalAddress(hospital),
  }
}

export const personNameMatchesSearch = (
  person: PickPartial<Person, 'name'>,
  nameSearch: string
) => {
  const searchTerms = nameSearch.split(' ').map(escapeStringRegexp)
  const searchRegex = new RegExp(searchTerms.join('[a-z]*\\s*'), 'i')

  return getFirstAndLastName(person.name).match(searchRegex)
}
export const personRoomMatchesSearch = (
  person: PickPartial<Person, 'admissionsInformation'>,
  nameSearch: string
) => {
  const searchTerms = nameSearch.split(' ').map(escapeStringRegexp)
  const searchRegex = new RegExp(searchTerms.join('[a-z]*\\s*'), 'i')

  return getRoomNumber(person)?.match(searchRegex)
}

export const getBirthDate = (person: PickPartial<Person, 'birthDate'>) =>
  person.birthDate
    ? fromDateMessageToDate(person.birthDate as DateMessage)
    : null

export const getPhone = (
  person: DeepPartial<Person>
): ContactPoint | undefined => getContactPhone(person.telecom as ContactPoint[])

export function getEmail(person: DeepPartial<Person>): ContactPoint | null {
  return (
    person.telecom?.find((contactPoint) => {
      return (
        contactPoint?.system ===
        ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_EMAIL
      )
    }) || null
  )
}

export function getResidentMoveInDate(
  person: Partial<Person>
): Date | undefined {
  return fromDateMessageToDate(person.admissionsInformation?.startDate)
}

export function getResidentFinancialStartDate(
  person: Partial<Person>
): Date | undefined {
  return fromDateMessageToDate(person.admissionsInformation?.financialStartDate)
}

export function getResidentMoveOutDate(
  person: Partial<Person>
): Date | undefined {
  return fromDateMessageToDate(person.dischargeInformation?.moveOutDate)
}

export function getResidentFinancialEndDate(
  person: Partial<Person>
): Date | undefined {
  return fromDateMessageToDate(
    person.dischargeInformation?.financialMoveOutDate
  )
}

export function getResidentMoveOutReason(
  person: Partial<Person>
): string | undefined {
  return person.dischargeInformation?.reason
}

export function getAdmissionType(
  person: Partial<Person>
): AdmissionsInformation_AdmissionType | undefined {
  return person.admissionsInformation?.admissionType
}

export function getOnHospice(person: Partial<Person>): boolean {
  return person.hospiceStatus?.onHospice || false
}

type FormatRoomNumberOptions = {
  bedNameInParentheses?: boolean
}

export function formatRoomNumber(
  roomName?: string,
  bedName?: string,
  options?: FormatRoomNumberOptions
) {
  const { bedNameInParentheses } = options || {}
  if (roomName) {
    if (bedName) {
      if (bedNameInParentheses) {
        return `${roomName} (${bedName})`
      } else {
        return `${roomName} ${bedName}`
      }
    } else {
      return roomName
    }
  }

  return ''
}

export function getRoomNumber(
  person: PickPartial<Person, 'admissionsInformation'>,
  options?: FormatRoomNumberOptions
): string | undefined {
  const { bedDetails, roomDetails } = person.admissionsInformation || {}

  return formatRoomNumber(roomDetails?.name, bedDetails?.name, options)
}

export const getCareGroup = (
  person: PickPartial<Person, 'careGroupDetails'>
): string => person.careGroupDetails?.name || ''

export const isRespite = (
  person: PickPartial<Person, 'respiteStatus'>
): boolean => person.respiteStatus?.isRespite || false

export const isResident = (
  person: PickPartial<Person, 'residentStatus'>
): boolean =>
  person.residentStatus === ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT

export const isDischargedResident = (
  person: PickPartial<Person, 'residentStatus'>
): boolean =>
  person.residentStatus === ResidentStatus.RESIDENT_STATUS_DISCHARGED

export const isOutOfFacility = (
  person: PickPartial<Person, 'isOutOfFacility'>
): boolean => !!person.isOutOfFacility

export function isProspectClosed(
  person: PickPartial<Person, 'residentStatus'>
): boolean {
  return person.residentStatus === ResidentStatus.RESIDENT_STATUS_CLOSED
}

export const isProspect = (
  person: PickPartial<Person, 'residentStatus'>
): boolean => person.residentStatus === ResidentStatus.RESIDENT_STATUS_PROSPECT

export const getLevelOfCare = (person: PickPartial<Person, 'levelOfCare'>) =>
  person.levelOfCare?.value === undefined ? null : `${person.levelOfCare.value}`

export function getPrimaryPhysician(
  person: PickPartial<Person, 'contact'>
): Contact | undefined {
  return person.contact?.find((contact) =>
    contact.relationship?.includes(
      Contact_ContactRelationship.CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER
    )
  )
}

export function getResponsiblePerson(
  person: PickPartial<Person, 'contact'>
): Contact | undefined {
  return person.contact?.find((contact) =>
    contact.relationship?.includes(
      Contact_ContactRelationship.CONTACT_RELATIONSHIP_RESPONSIBLE_PERSON
    )
  )
}

export function getPayer(
  person: PickPartial<Person, 'contact'>
): Contact | undefined {
  return person.contact?.find((contact) =>
    contact.relationship?.includes(
      Contact_ContactRelationship.CONTACT_RELATIONSHIP_FINANCIAL_PAYMENT_OF_CARE
    )
  )
}

export function displayGender(person: PickPartial<Person, 'gender'>): string {
  switch (person.gender) {
    case Gender.GENDER_FEMALE:
      return 'Female'
    case Gender.GENDER_MALE:
      return 'Male'
    case Gender.GENDER_NON_BINARY:
      return 'Non-Binary'
    default:
      return 'No gender on record'
  }
}

export function getGenderLetter(person: PickPartial<Person, 'gender'>): string {
  switch (person.gender) {
    case Gender.GENDER_FEMALE:
      return 'F'
    case Gender.GENDER_MALE:
      return 'M'
    case Gender.GENDER_NON_BINARY:
      return 'X'
    default:
      return ''
  }
}

export function displayResidentStatus(
  person: PickPartial<Person, 'residentStatus'>
): string {
  switch (person.residentStatus) {
    case ResidentStatus.RESIDENT_STATUS_CLOSED:
      return 'Closed'
    case ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT:
      return 'Resident'
    case ResidentStatus.RESIDENT_STATUS_DISCHARGED:
      return 'Moved-out'
    case ResidentStatus.RESIDENT_STATUS_PROSPECT:
      return 'Move-in'
    default:
      return 'Unknown'
  }
}

type PersonWithContacts = PickPartial<Person, 'contact'>

export function prescribersForPerson(person: PersonWithContacts): Contact[] {
  return (person.contact || []).filter((contact) => {
    const prescribers = intersection(contact.relationship || [], [
      Contact_ContactRelationship.CONTACT_RELATIONSHIP_PHYSICIAN,
      Contact_ContactRelationship.CONTACT_RELATIONSHIP_NURSE_PRACTITIONER,
      Contact_ContactRelationship.CONTACT_RELATIONSHIP_PHYSICIAN_ASSISTANT,
    ])
    return prescribers.length > 0
  })
}

export function displayCPRCode(
  person: PickPartial<Person, 'cprCode'>,
  defaultValue?: string
): string | undefined {
  switch (person.cprCode) {
    case Polst_CPR.CPR_DO_NOT_ATTEMPT_RESUSCITATION:
      return 'DNR'
    case Polst_CPR.CPR_ATTEMPT_RESUSCITATION:
      return 'Full Code'
    case Polst_CPR.UNRECOGNIZED:
    case Polst_CPR.CPR_UNSPECIFIED:
    default:
      return defaultValue
  }
}

export const diagnosesList = (
  person: PickPartial<Person, 'conditions'>
): Condition[] => {
  if (!person.conditions || person.conditions.length < 1) {
    return []
  }

  return person.conditions
}
export const allergyList = (
  person: PickPartial<Person, 'allergiesAndIntolerances'>
): AllergyIntolerance[] => {
  if (!person.allergiesAndIntolerances?.allergies) {
    return []
  }

  return person.allergiesAndIntolerances.allergies.sort(
    (a: AllergyIntolerance, b: AllergyIntolerance) => {
      if (a.summary === undefined || b.summary === undefined) return 0
      return a.summary.localeCompare(b.summary)
    }
  )
}

export const primaryInsurance = (person: PickPartial<Person, 'coverage'>) =>
  person.coverage?.find(
    (coverage) => coverage.type === Coverage_CoverageType.COVERAGE_TYPE_MEDICAL
  )

export const secondaryInsurance = (person: PickPartial<Person, 'coverage'>) =>
  person.coverage?.filter(
    (coverage) => coverage.type === Coverage_CoverageType.COVERAGE_TYPE_MEDICAL
  )[1]

export const dentalInsurance = (person: PickPartial<Person, 'coverage'>) =>
  person.coverage?.find(
    (coverage) => coverage.type === Coverage_CoverageType.COVERAGE_TYPE_DENTAL
  )

export function ageString(person: PickPartial<Person, 'birthDate'>) {
  const birthDate = getBirthDate(person)

  if (!birthDate) {
    return 'No birthdate on record'
  }

  const today = new Date()
  const monthDiff = today.getMonth() - birthDate.getMonth()
  let age = today.getFullYear() - birthDate.getFullYear()
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--
  }
  return age
}

export const hasDementia = (person: PickPartial<Person, 'conditions'>) =>
  person.conditions?.some((condition) =>
    condition.code?.coding?.some((coding) => coding.code === DEMENTIA_CODE)
  )

export function sortPeopleByStatus(p1: Person, p2: Person) {
  const rs1 = p1.residentStatus
  const rs2 = p2.residentStatus
  if (!rs1 && !rs2) {
    return 0
  } else if (!rs1) {
    return 1
  } else if (!rs2) {
    return -1
  } else if (rs1 === rs2) {
    return 0
  } else if (
    rs1 === ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT &&
    rs2 === ResidentStatus.RESIDENT_STATUS_PROSPECT
  ) {
    return 1
  } else {
    return -1
  }
}

export const TITLE_OPTIONS = [
  { label: 'Dr.', value: 'Dr.' },
  { label: 'Mr.', value: 'Mr.' },
  { label: 'Mrs.', value: 'Mrs.' },
  { label: 'Ms.', value: 'Ms.' },
  { label: 'Mx.', value: 'Mx.' },
  { label: 'Rev.', value: 'Rev.' },
]
