import { Vital } from '@augusthealth/models/com/august/protos/vital'
import { FunctionComponent } from 'react'
import { VictoryLabel, VictoryLabelProps, VictoryLegendProps } from 'victory'
import { VitalsType } from '@shared/utils/vitals'
import AreaChart from '@app/libraries/Victory/AreaChart'
import { CategoriesDataDomain, getAreaChartProps } from './helpers'

export default function Chart({
  activeTab,
  vitalsForTab,
}: {
  activeTab?: VitalsType
  vitalsForTab: Vital[]
}) {
  let categoriesDataDomain: CategoriesDataDomain = { data: [[]] }
  if (vitalsForTab.length && activeTab) {
    categoriesDataDomain = getAreaChartProps({
      vitals: vitalsForTab,
      vitalsType: activeTab,
    })
  }
  const victoryLegendProps: VictoryLegendProps | undefined =
    activeTab === VitalsType.BLOOD_PRESSURE
      ? {
          data: [{ name: 'systolic' }, { name: 'diastolic' }],
        }
      : undefined

  return (
    <AreaChart
      id={`vital-${activeTab}-chart`}
      {...categoriesDataDomain}
      chartStyles={{
        height: 89,
        padding: { top: 8, bottom: 12, left: 16, right: 8 },
      }}
      XTickLabel={
        VictoryLabel as unknown as FunctionComponent<VictoryLabelProps>
      }
      victoryLegendProps={victoryLegendProps}
    />
  )
}
