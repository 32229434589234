import classNames from 'classnames'
import { RoutineOrder, RoutineStatus } from '@shared/types/routine_order'
import { Description } from '@app/pages/Routines/ScheduleRoutine'
import styles from './styles.module.css'
import {
  levelsForRoutine,
  toFullDescription,
} from './components/RoutineDescription'

export function Instructions({
  routineOrder,
  abbreviated,
}: {
  routineOrder: RoutineOrder
  abbreviated: boolean
}) {
  if (routineOrder.routineType.assessment) {
    const categoryTasks = routineOrder.routineType.assessment.tasks ?? []
    const borderClasses = classNames(styles.detailsBorder, {
      [styles.draft]:
        routineOrder.status === RoutineStatus.ROUTINE_STATUS_DRAFT,
      [styles.active]:
        routineOrder.status === RoutineStatus.ROUTINE_STATUS_ACTIVE,
    })
    if (abbreviated) {
      const levelsTextForRoutine = levelsForRoutine(categoryTasks)

      return (
        <div className={'flex'}>
          <div className={borderClasses} />
          <div className={styles.detailsText}>
            <>
              {levelsTextForRoutine.map((s, i) => (
                <div key={`l-i${i}`}>{s}</div>
              ))}
              {levelsTextForRoutine.length === 0 && (
                <div>No level information available.</div>
              )}
            </>
          </div>
        </div>
      )
    } else {
      const fullDescription: Description[] = toFullDescription(categoryTasks)
      return (
        <>
          {fullDescription.map((fid, i) => (
            <div className={'mb-[12px] flex'} key={`${routineOrder.id}-${i}`}>
              <div className={borderClasses} />
              <div className={styles.detailsText}>
                <ul className={styles.fullInstructions}>
                  {fid.categoryLevel && (
                    <span>
                      {fid.categoryLevel} {fid.categoryTitle}{' '}
                      {fid.notes && <>- {fid.notes}</>}
                    </span>
                  )}

                  {fid.details.map((d, j) => (
                    <li key={`${routineOrder.id}-${i}-${j}`}>
                      <span>{d}</span>
                    </li>
                  ))}
                  {fid.options.map((o, j) => (
                    <li key={`${routineOrder.id}-${i}-${j}`}>
                      <span>{o}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </>
      )
    }
  } else {
    return (
      <div className="border-l-2 border-l-gray-10">
        <pre className="whitespace-pre-wrap px-4 font-inter font-medium">
          {routineOrder.instructions}
        </pre>
      </div>
    )
  }
}
