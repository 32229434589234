import {
  PharmacyMessage,
  PharmacyResidentIdentifier,
} from '@augusthealth/models/com/august/protos/pharmacy_message'

export interface PharmacyMessagePayload {
  id: number
  receivedAt: string
  messageId: {
    personId: PharmacyResidentIdentifier
    messageId: string
  }
  personId?: number
  messageStatus: PharmacyMessageStatus
  pharmacyMessage: PharmacyMessage
}

export enum PharmacyMessageStatus {
  Received = 'Received',
  Processed = 'Processed',
  Matched = 'Matched',
  Rejected = 'Rejected',
  Replaced = 'Replaced',
  Accepted = 'Accepted',
  NoOp = 'NoOp',
  NotSupported = 'NotSupported',
}

export type ProcessPharmacyMessagePayload = {
  processPharmacyMessage: {
    residentIdentifier: PharmacyResidentIdentifier
    external_message_id: string
    message_id: number
  }
}
