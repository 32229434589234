import { BillingCharge, BillingChargeData } from '@shared/types/billing'
import { getOrElse, Loading } from '@shared/utils/loading'
import { Order, sortNumber, sortStr } from '@shared/utils/sorting'
import { ChargesColumnName } from '../../helpers'

type BillingChargeDataKey = keyof BillingChargeData

const keysToSearch: BillingChargeDataKey[] = ['name', 'amountCents']

// TO DO: Need to add tests
export function getSearchedBillingCharges({
  rows,
  searchTerm,
}: {
  rows: Loading<BillingCharge[]>
  searchTerm: string
}) {
  const searchText = searchTerm.toLowerCase()

  return getOrElse(rows, []).filter((charge) => {
    return Object.entries(charge.data).some(([key, val]) => {
      if (keysToSearch.includes(key as BillingChargeDataKey) && val) {
        return val.toString().toLowerCase().includes(searchText)
      }

      return false
    })
  })
}

export function getSortedCharges({
  rows,
  selectedColumn,
  sortingOrder,
}: {
  rows: BillingCharge[]
  selectedColumn: ChargesColumnName
  sortingOrder: Order
}) {
  if (selectedColumn === ChargesColumnName.DESCRIPTION) {
    return rows.sort((a, b) =>
      sortStr({
        strA: a.data.name,
        strB: b.data.name,
        sortingOrder,
      })
    )
  }

  if (selectedColumn === ChargesColumnName.AMOUNT) {
    return rows.sort((a, b) =>
      sortNumber({
        numA: a.data.amountCents,
        numB: b.data.amountCents,
        sortingOrder,
      })
    )
  }

  if (selectedColumn === ChargesColumnName.START_DATE) {
    return rows.sort((a, b) =>
      sortStr({
        strA: a.data.startDate,
        strB: b.data.startDate,
        sortingOrder,
      })
    )
  }

  if (selectedColumn === ChargesColumnName.END_DATE) {
    return rows.sort((a, b) =>
      sortStr({
        strA: a.data.endDate,
        strB: b.data.endDate,
        sortingOrder,
      })
    )
  }

  return rows
}
