import { DosageType } from '@augusthealth/models/com/august/protos/medication_statement'
import { UnitOfTime } from '@augusthealth/models/com/august/protos/timing'
import { UseFormReturn } from 'react-hook-form'
import { GroupBase, StylesConfig } from 'react-select'
import {
  baseSelectControlStyles,
  selectStyles,
} from '@shared/components/StyledSelect'
import { CustomPeriodUnits } from '@shared/utils/medicationStatement'
import pluralize from '@shared/utils/pluralize'
import {
  MedOrderFormData,
  MedOrderFormDose,
} from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'

export type SlidingScaleDoseEntry =
  `doses.${number}.slidingScale.entries.${number}`
export const PrimaryDropdownStyleConfig: StylesConfig<
  unknown,
  boolean,
  GroupBase<unknown>
> = {
  ...selectStyles(),
  control: (provided, state) => {
    const disabledStyles = {
      borderColor: 'var(--form-input-border)',
      backgroundColor: 'var(--form-input-disabled-background-color)',
      color: 'var(--form-input-disabled-text-color)',
    }
    const enabledStyles = {
      borderColor: 'var(--primary-border)',
      backgroundColor: 'var(--primary-light)',
      color: 'white',
    }

    const styles = state.isDisabled ? disabledStyles : enabledStyles

    return {
      ...provided,
      ...baseSelectControlStyles('medium', state),
      width: 'fit-content',
      ...styles,
    }
  },
  menu: (provided) => ({
    ...provided,
    width: 'fit-content',
  }),
  input: (provided) => ({
    ...provided,
    color: 'white',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? 'var(--form-input-disabled-text-color)' : 'white',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--form-input-placeholder-color)',
  }),
}

export const getNumberOfTimeInputsToShow = ({
  frequencyCount,
  frequencyType,
  variablePeriodUnit,
  period,
}: {
  frequencyCount: number
  frequencyType?: UnitOfTime | CustomPeriodUnits
  variablePeriodUnit?: UnitOfTime
  period?: number
}) => {
  if (
    variablePeriodUnit === UnitOfTime.UNIT_OF_TIME_HOUR &&
    frequencyType === CustomPeriodUnits.EVERY &&
    period &&
    !isNaN(period) &&
    period > 0 // Prevent division by zero
  ) {
    // calculate inputs to show for 24 hour period, rounding up
    return Math.ceil(24 / period)
  }

  // frequencyCount coming from input can be NaN
  if (isNaN(frequencyCount) || frequencyCount < 0) {
    return 0
  }

  if (frequencyType === undefined && variablePeriodUnit === undefined) {
    return frequencyCount
  }

  return frequencyType === UnitOfTime.UNIT_OF_TIME_DAY ||
    frequencyType === CustomPeriodUnits.EVERY ||
    frequencyType === CustomPeriodUnits.SPECIFIC_DAYS ||
    variablePeriodUnit === UnitOfTime.UNIT_OF_TIME_DAY
    ? frequencyCount
    : 1
}

export const validateOptionalPositiveNumber = (
  value?: number
): string | boolean => {
  if (value !== undefined && value <= 0) {
    return 'Must be greater than 0'
  }

  return true
}

export const validatePositiveNumber = (value?: number): string | boolean => {
  if (value !== undefined) {
    if (Number.isNaN(value)) {
      return 'Required'
    } else if (value <= 0) {
      return 'Must be greater than 0'
    }

    return true
  }

  return 'Required'
}

function atLeastOnePositiveQuantity(doses: MedOrderFormDose[]) {
  return doses.some((d) =>
    d?.slidingScale?.entries?.some(
      (entry) => entry?.doseQuantity?.value && entry.doseQuantity.value > 0
    )
  )
}

export const validateAtLeastOneSlidingQuantity = ({
  formData,
  unit,
}: {
  formData: MedOrderFormData
  unit: string
}): string | boolean => {
  const { doses } = formData
  if (!atLeastOnePositiveQuantity(doses)) {
    return `At least one "${pluralize(unit, 1, false).toUpperCase()}" input is required`
  }

  return true
}

export const validateRequired = (value: any) => {
  if (value === undefined) {
    return 'Required'
  }

  return true
}

export const validateRequiredNumberSelection = (
  value: any[],
  frequencyCount: number
) => {
  if (value.length === 0 || isNaN(frequencyCount) || frequencyCount === 0) {
    return 'Required'
  } else if (value.length !== frequencyCount) {
    return `Must select ${pluralize('day', frequencyCount, true)}`
  }
  return true
}

export const updateDosesToMatchDoseUnit = ({
  newUnit,
  methods,
}: {
  newUnit: string
  methods: UseFormReturn<MedOrderFormData>
}): void => {
  const hasCustomPrnMaxDosage = methods.getValues('hasCustomPrnMaxDosage')

  const updatedDoses = [...methods.getValues('doses')]
  updatedDoses.forEach((dose: MedOrderFormDose) => {
    if (dose) {
      if (
        isRoutineDosageType(dose.userSetDosageType) ||
        isTaperDosageType(dose.userSetDosageType)
      ) {
        dose.quantity.unit = newUnit
      } else if (isPrnDosageType(dose.userSetDosageType)) {
        dose.quantity.unit = newUnit
        if (!hasCustomPrnMaxDosage) {
          dose.maxDosage!.unit = newUnit
        }
      } else if (isSlidingScaleDosageType(dose.userSetDosageType)) {
        dose.quantity.unit = newUnit
        dose.slidingScale!.entries = (dose.slidingScale?.entries ?? []).map(
          (entry) => ({
            ...entry,
            doseQuantity: {
              ...entry.doseQuantity,
              unit: newUnit,
            },
          })
        )
      }
    }
  })

  methods.setValue('doses', updatedDoses, { shouldDirty: true })
  return
}

const isDosageType = (
  valueToCompare: DosageType,
  expectedDosageType: DosageType
): boolean => {
  return valueToCompare === expectedDosageType
}

export const isRoutineDosageType = (value?: DosageType): boolean => {
  return !!value && isDosageType(value, DosageType.DOSAGE_TYPE_ROUTINE)
}
export const isPrnDosageType = (value?: DosageType): boolean => {
  return !!value && isDosageType(value, DosageType.DOSAGE_TYPE_PRN)
}
export const isSlidingScaleDosageType = (value?: DosageType): boolean => {
  return !!value && isDosageType(value, DosageType.DOSAGE_TYPE_SLIDING_SCALE)
}
export const isTaperDosageType = (value?: DosageType): boolean => {
  return !!value && isDosageType(value, DosageType.DOSAGE_TYPE_TAPER)
}
export const isSplitDosageType = (value?: DosageType): boolean => {
  return !!value && isDosageType(value, DosageType.DOSAGE_TYPE_SPLIT)
}
