import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { Route, Switch, useParams } from 'react-router-dom'
import GatedRoute, {
  FacilityGatedRoute,
} from '@shared/components/Auth/GatedRoute'
import { useUserContext } from '@shared/contexts/UserContext'
import { isDirector as getIsDirector } from '@shared/utils/user'
import Dashboard from '@app/pages/Facilities/Insights'
import {
  ORGANIZATION_DASHBOARD_PAGE_PATH,
  ORGANIZATION_DASHBOARD_PATH,
} from '@app/pages/Facilities/Insights/type'
import List from './List'
import Settings from './Settings'
import { ConnectToQuickBooks } from './Settings/GeneralPage/QuickBooks/quick_books'
import {
  ORGANIZATIONS_SETTINGS_PATH,
  ORGANIZATIONS_SETTINGS_TAB_PATH,
} from './Settings/type'

const ORGANIZATIONS_LIST_PATH = '/orgs'
const ORGANIZATION_CONNECT_TO_QUICKBOOKS = '/quickbooks'

export const ORGANIZATIONS_PATHS = [
  ORGANIZATIONS_LIST_PATH,
  ORGANIZATION_CONNECT_TO_QUICKBOOKS,
  ORGANIZATIONS_SETTINGS_PATH,
  ORGANIZATIONS_SETTINGS_TAB_PATH,
  ORGANIZATION_DASHBOARD_PATH,
  ORGANIZATION_DASHBOARD_PAGE_PATH,
]

function Organizations() {
  const { user } = useUserContext()
  const { orgId } = useParams<{ orgId: string }>()
  const isDirector = getIsDirector({ user, orgId })
  const settingsPath = [
    ORGANIZATIONS_SETTINGS_PATH,
    ORGANIZATIONS_SETTINGS_TAB_PATH,
  ]
  return (
    <Switch>
      {isDirector && <Route exact path={settingsPath} component={Settings} />}
      <GatedRoute
        exact
        minimalPermissions={[
          GroupPermission.GROUP_PERMISSION_ORGANIZATION_READ,
        ]}
        path={settingsPath}
        component={Settings}
      />
      <GatedRoute
        exact
        minimalPermissions={[
          GroupPermission.GROUP_PERMISSION_ORGANIZATION_UPDATE,
        ]}
        path={ORGANIZATION_CONNECT_TO_QUICKBOOKS}
        component={ConnectToQuickBooks}
        title={'QuickBooks'}
      />
      <Route
        exact
        path={ORGANIZATIONS_LIST_PATH}
        component={() => {
          return <List containerClassNames="my-[24px] mx-[248px]" />
        }}
      />
      <FacilityGatedRoute
        exact
        minimalPermissions={[
          GroupPermission.GROUP_PERMISSION_SUPERSET_DASHBOARD_READ,
        ]}
        path={[ORGANIZATION_DASHBOARD_PATH, ORGANIZATION_DASHBOARD_PAGE_PATH]}
        component={Dashboard}
        title={'Dashboard'}
      />
    </Switch>
  )
}

export default Organizations
