import { OrgFacilitySettings } from '@augusthealth/models/com/august/protos/org_facility_settings'
import { SignableFormAssignment } from '@augusthealth/models/com/august/protos/signable_form'
import { useContext, useState } from 'react'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect from '@shared/components/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { DataType } from '@shared/types/snapshot'
import {
  SETTINGS_LEVEL_OPTIONS,
  SettingsLevel,
} from '@shared/utils/orgFacilitySettings'
import { taskTitle } from '@shared/utils/task'
import { CardLevel, updateOrCreateAssignment } from './helpers'

export default function AssignmentModal({
  assignment,
  customType,
  dataType,
  cardLevel,
  name,
  signableFormId,
  taskSettings,
  onClose,
}: {
  assignment: SignableFormAssignment
  customType?: string
  dataType: DataType
  cardLevel: CardLevel
  name: string
  signableFormId: string
  taskSettings: OrgFacilitySettings
  onClose: (updated: boolean) => Promise<void>
}) {
  const { setError } = useContext(GlobalContext)
  const [submitting, setSubmitting] = useState(false)
  const taskDefinition = taskSettings.settings!.taskDefinition!
  const { facilityId, orgId, state } = cardLevel

  let assignmentLevel: SettingsLevel
  if (state && orgId) {
    assignmentLevel = SettingsLevel.ORG_STATE_LEVEL
  } else if (facilityId) {
    assignmentLevel = SettingsLevel.FACILITY_LEVEL
  } else if (orgId) {
    assignmentLevel = SettingsLevel.ORG_LEVEL
  } else if (state) {
    assignmentLevel = SettingsLevel.STATE_LEVEL
  } else {
    assignmentLevel = SettingsLevel.GLOBAL_LEVEL
  }

  const onSubmit = async () => {
    const { tag, ...settingsIds } = cardLevel

    try {
      setSubmitting(true)
      await updateOrCreateAssignment({
        assignment,
        dataType,
        customType,
        signableFormId,
        ...settingsIds,
      })

      await onClose(true)
    } catch (e) {
      setError(e)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <AnimatedPopup title={`${taskTitle(taskDefinition.template!)}`}>
      <div className="mb-[32px]">Assigning {name}</div>
      <div className="mb-[32px]">
        <LabelAboveInput
          htmlFor="settingsLevel"
          subLabel={{ tag: 'Required', hasError: false }}
        >
          Assignment Level
        </LabelAboveInput>
        <StyledSelect
          name="settingsLevel"
          maxMenuHeight={200}
          isDisabled={true}
          value={{ label: assignmentLevel, value: assignmentLevel }}
          options={SETTINGS_LEVEL_OPTIONS}
        />
      </div>
      <AnimatedPopupFormFooter
        yesBtn={{
          props: {
            onClick: () => void onSubmit(),
          },
        }}
        noBtn={{
          action: () => void onClose(false),
        }}
        formState={{ isSubmitting: submitting }}
      />
    </AnimatedPopup>
  )
}
