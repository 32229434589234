import { AugustFieldType } from '@augusthealth/models/com/august/protos/custom_field'
import { DateFilter_DateFilterOperation } from '@augusthealth/models/com/august/protos/report'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import StyledSelect from '@shared/components/StyledSelect'
import { fromDateToDateMessage } from '@shared/utils/date'
import { dateFilterOptions } from '../helpers'
import { ReportFields } from '../models'

export function DateFilter({
  filterField,
  setReport,
}: {
  filterField: AugustFieldType | undefined
  setReport: React.Dispatch<React.SetStateAction<ReportFields>>
}) {
  const [filterDate, setFilterDate] = useState<Date | null>()
  const [filterOperator, setFilterOperator] =
    useState<DateFilter_DateFilterOperation>()

  const isNonSpecificDateFilter =
    filterOperator ===
      DateFilter_DateFilterOperation.DATE_FILTER_OPERATION_NON_EMPTY ||
    filterOperator ===
      DateFilter_DateFilterOperation.DATE_FILTER_OPERATION_IS_EMPTY

  const addDateFilter = (newFilter) => {
    setReport((report) => ({
      fields: report.fields,
      orderByField: report.orderByField,
      dateFilter: {
        ...report.dateFilter,
        ...newFilter,
      },
    }))
  }

  return (
    <div className="flex">
      <div className="grow">
        <StyledSelect
          isClearable
          isDisabled={!filterField}
          placeholder="Filter by..."
          options={dateFilterOptions}
          onChange={(e: { value: DateFilter_DateFilterOperation }) => {
            setFilterOperator(e?.value)
            addDateFilter({ operator: e?.value })
          }}
        />
      </div>
      <div className="ml-[12px]">
        <DatePicker
          disabled={!filterField || !filterOperator || isNonSpecificDateFilter}
          selected={filterDate}
          id="filterDate"
          onChange={(date: Date | null) => {
            setFilterDate(date)
            addDateFilter({
              date: date ? fromDateToDateMessage(date) : undefined,
            })
          }}
          customInput={<CalendarInput />}
        />
      </div>
    </div>
  )
}
