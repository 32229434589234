import { Auth } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { login } from '@shared/api/login'
import { userHasAccessToOrg } from '@shared/components/Auth/helpers'
import LoginWithUsernameOrEmail from '@shared/components/Auth/LoginWithUsernameOrEmail/LoginWithUsernameOrEmail'
import { LoaderSvg } from '@shared/components/LoadingPopup'
import useQuery from '@shared/hooks/useQuery'
import { tw } from '@shared/utils/tailwind'
import { isLoggedInAs, logout } from './Auth'

function Login({ applicationBasePath }: { applicationBasePath: string }) {
  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const { e: loginEmail, c: loginCode } = useParams<{
    e: string | undefined
    c: string | undefined
  }>()
  const [expired, setExpired] = useState(false)
  const history = useHistory()
  const queryString = useQuery()
  const redirectPath = queryString.get('path') || applicationBasePath

  useEffect(() => {
    if (loginEmail && loginCode) {
      setIsLoggingIn(true)

      handleMagicLogin({
        redirectPath,
        email: loginEmail,
        code: loginCode,
        history,
        applicationBasePath,
      }).catch(() => {
        setExpired(true)
        setEmail(loginEmail)
      })
    }
  }, [history, loginCode, loginEmail, redirectPath])

  const onSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    path: string
  ) => {
    e.preventDefault()
    // clear state of login component for submit
    setExpired(false)
    setIsLoggingIn(false)

    setSubmitted(true)
    await login(email, path)
  }

  return (
    <div
      className={tw`flex h-screen flex-col items-center justify-center text-secondary-03`}
      slot="sign-in"
    >
      <img src="/svg/wordmark.svg" alt={'August Health wordmark'} />
      {expired && <Expired onSubmit={(e) => onSubmit(e, redirectPath)} />}

      {isLoggingIn && !expired && <LoggingYouIn />}

      {!isLoggingIn && submitted && <CheckEmail email={email} />}

      {!isLoggingIn && !submitted && (
        <LoginWithUsernameOrEmail applicationBasePath={applicationBasePath} />
      )}

      <Footer />
    </div>
  )
}

const LoggingYouIn = () => (
  <>
    <div
      className={tw`mt-[40px] font-inter text-[20px] font-semibold not-italic leading-[24px]`}
    >
      Logging you in
    </div>
    <div className={tw`mt-[40px]`}>
      <LoaderSvg />
    </div>
  </>
)

function Expired({
  onSubmit,
}: {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}) {
  return (
    <div className={tw`flex w-[360px] flex-col items-center`}>
      <div
        className={tw`mt-[40px] font-inter text-[20px] font-semibold not-italic leading-[24px]`}
      >
        Your login link has expired
      </div>
      <form onSubmit={onSubmit} className={tw`w-full`}>
        <input
          className={tw`no-italic disabled:cursor-initial mt-[16px] h-[48px] w-full cursor-pointer rounded-[4px] border-0 bg-august-primary text-center font-inter text-[14px] font-semibold uppercase leading-[16px] text-white hover:bg-button-primary-color hover:brightness-90 disabled:opacity-50`}
          type="submit"
          value="Send me a new link"
        />
      </form>
    </div>
  )
}

function CheckEmail({ email }: { email: string }) {
  return (
    <>
      <div
        className={tw`mt-[40px] font-inter text-[20px] font-semibold not-italic leading-[24px]`}
      >
        Check your email
      </div>
      <div className={tw`mt-[16px]`}>
        <div className={tw`text-center`}>
          We've sent a temporary login link to{' '}
          <span className={tw`font-bold`}>{email}</span>
        </div>
      </div>
    </>
  )
}

export function Footer() {
  return (
    <div
      className={tw`mt-[40px] flex flex-col items-center text-[12px] font-medium leading-[16px] text-secondary-07`}
    >
      <div>
        Or,{' '}
        <a
          href="mailto:support@augusthealth.com"
          className={tw`font-semibold text-august-primary`}
        >
          contact support
        </a>{' '}
        if you need help to log in.
      </div>
      <div className={tw`mt-[4px]`}>
        By logging in, you accept our{' '}
        <a href="/terms.html" className={tw`font-semibold text-august-primary`}>
          Terms
        </a>{' '}
        and{' '}
        <a
          href="/privacy.html"
          className={tw`font-semibold text-august-primary`}
        >
          Privacy Policy
        </a>
      </div>
    </div>
  )
}

async function handleMagicLogin({
  redirectPath,
  email,
  code,
  history,
  applicationBasePath,
}: {
  redirectPath: string
  email: string
  code: string
  history: unknown
  applicationBasePath: string
}) {
  const currentSessionAuthStatus = await isLoggedInAs()
  const isUserLoggedIn = currentSessionAuthStatus.loggedIn
  const isLoggedInAsTargetUser =
    currentSessionAuthStatus.currentUserEmail &&
    currentSessionAuthStatus.currentUserEmail === email

  // If the current session is logged in as someone other than the target user (the one in the link), log them out before proceeding
  if (isUserLoggedIn && !isLoggedInAsTargetUser) {
    await logout()
    return
  }

  // If we're still here and the user isn't logged in as the target user, log them in
  if (!isLoggedInAsTargetUser) {
    const session = (await Auth.signIn(email)) as unknown
    await Auth.sendCustomChallengeAnswer(session, code)
  }

  try {
    const isValidRedirect = await userHasAccessToOrg(redirectPath)

    if (isValidRedirect) {
      window.location.href = redirectPath
    } else {
      window.location.href = applicationBasePath
    }
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    ;(history as any).push(applicationBasePath) // (TODO: update `history`)
  }
}

export default Login
