import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'
import { tw } from '@shared/utils/tailwind'

export function EmbossedCard({
  children,
  className,
  ...rest
}: React.HTMLProps<HTMLDivElement> & {
  className?: string
}) {
  return (
    <div
      className={tw`rounded-[8px] border border-secondary-10 bg-secondary-12 p-[24px] shadow-card ${className}`}
      {...rest}
    >
      {children}
    </div>
  )
}

export function ClickableEmbossedCard({
  buttonProps,
  cardProps,
  children,
}: {
  buttonProps: DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
  cardProps: React.HTMLProps<HTMLDivElement>
  children: React.ReactNode
}) {
  const { className: buttonClassName, ...buttonRest } = buttonProps

  return (
    <button
      {...buttonRest}
      className={tw`block w-full text-left ${buttonClassName ?? ''}`}
    >
      <EmbossedCard
        {...cardProps}
        className={tw`${cardProps.className} hover:card-outline`}
      >
        {children}
      </EmbossedCard>
    </button>
  )
}
