import { OrgFacilitySettings } from '@augusthealth/models/com/august/protos/org_facility_settings'
import { useState } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Badge from '@shared/components/Badge'
import { SettingsLevel } from '@shared/utils/orgFacilitySettings'
import { deleteOrgFacilitySetting } from '@app/api/orgFacilitySettings'
import ConfirmModal from '@app/components/ConfirmModal'
import { getSettingsLevel } from '../TaskDefinitions/helpers'

export default function ViewDeleteCurrentSettings({
  config,
  reloadConfig,
  clearConfig,
}: {
  config?: OrgFacilitySettings
  reloadConfig: () => Promise<void>
  clearConfig: () => void
}) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  if (config === undefined) {
    return null
  }

  const settingsLevel = getSettingsLevel(config)

  return (
    <>
      <div
        className={'mt-[16px] flex items-center text-[14px] font-medium'}
        data-testid={'level-for-community'}
      >
        <span className={'mr-[8px]'}>Level for selected community</span>
        <Badge color={'purple'} shape={'square'}>
          {settingsLevel}
        </Badge>
      </div>
      <AsyncIconButton
        className={'mt-[16px]'}
        buttonStyle={'danger-fill'}
        disabled={settingsLevel === SettingsLevel.GLOBAL_LEVEL}
        onClick={async () => {
          setShowDeleteConfirmation(true)
        }}
      >
        Delete settings
      </AsyncIconButton>
      {showDeleteConfirmation && (
        <ConfirmModal
          title={`Delete ${settingsLevel} settings?`}
          confirmButtonConfig={{
            onClick: async () => {
              await deleteOrgFacilitySetting(config)
              setShowDeleteConfirmation(false)
              clearConfig()

              await reloadConfig()
            },
          }}
          denyButtonConfig={{
            onClick: () => setShowDeleteConfirmation(false),
          }}
        />
      )}
    </>
  )
}
