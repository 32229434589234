import { AppraisalSettings_AppraisalCategoryOptions } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import {
  BasicCheckbox,
  BasicInput,
} from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import { GroupedOptionTypeBase } from '@shared/components/StyledSelect'
import { AssessmentCategory } from '@shared/types/assessment_configuration'
import styles from './styles.module.css'

export type CustomCategoryForm = {
  title: string
  page: number
  displayOrder: number
  includeOnServicePlan: boolean
}
type Props = {
  proposedName: string
  closeModal: () => void
  createNewCategory: (data: AppraisalSettings_AppraisalCategoryOptions) => void
  categoryDropdownOptions: GroupedOptionTypeBase<AssessmentCategory>[]
  currentCategoryNames: string[]
}
export const CreateCustomCategoryModal = ({
  proposedName,
  closeModal,
  createNewCategory,
  categoryDropdownOptions,
  currentCategoryNames,
}: Props) => {
  const { handleSubmit, register, formState } = useForm<CustomCategoryForm>({
    defaultValues: {
      title: proposedName,
    },
  })

  return (
    <AnimatedPopup
      title={'Create Custom Category'}
      onClickBackground={closeModal}
    >
      <form
        onSubmit={handleSubmit(createNewCategory)}
        data-testid={'custom-category-modal'}
      >
        <div>
          <LabelAboveInput
            htmlFor={'title'}
            subLabel={requiredLabel(!!formState.errors.title)}
            errorMessage={formState.errors.title?.message}
          >
            Title
          </LabelAboveInput>
          <BasicInput
            id={'title'}
            className={'mb-[12px]'}
            {...register('title', {
              required: true,
              validate: (value: string) => {
                if (
                  currentCategoryNames
                    .map((opt) => opt.toLowerCase())
                    .includes(value.toLowerCase())
                ) {
                  return 'Must Be Unique'
                }

                return true
              },
            })}
          />
        </div>
        <div>
          <LabelAboveInput
            htmlFor={'page'}
            className={'w-[64px]'}
            subLabel={requiredLabel(!!formState.errors.page)}
          >
            Page
          </LabelAboveInput>
          <BasicInput
            id={'page'}
            className={'mb-[12px]'}
            type={'number'}
            min={1}
            max={6}
            {...register('page', { required: true, valueAsNumber: true })}
          />
        </div>
        <div>
          <LabelAboveInput
            htmlFor={'displayOrder'}
            className={'w-[64px] whitespace-nowrap'}
            subLabel={requiredLabel(!!formState.errors.displayOrder)}
          >
            Order
          </LabelAboveInput>
          <BasicInput
            id={'displayOrder'}
            className={'mb-[12px]'}
            type={'number'}
            min={1}
            {...register('displayOrder', {
              required: true,
              valueAsNumber: true,
            })}
          />
        </div>
        <div>
          <BasicCheckbox
            id={'includeOnServicePlan'}
            min={1}
            labelClassName={'ml-[8px] mt-[4px] mb-[24px]'}
            {...register('includeOnServicePlan')}
          >
            Include on Service Plan
          </BasicCheckbox>
        </div>

        {categoryDropdownOptions && (
          <details className={styles.customCategoriesDetails}>
            <summary className={'text-[14px] font-semibold'}>
              Current Categories
            </summary>
            {categoryDropdownOptions.map((pageGroup) => {
              return (
                <div
                  key={pageGroup.label}
                  className={'mb-[8px] ml-[24px] mt-[8px]'}
                >
                  <div
                    className={'text-[14px] font-semibold text-secondary-06'}
                  >
                    {pageGroup.label}
                  </div>
                  <div className={'ml-[16px] text-[14px]'}>
                    {pageGroup.options.map((opt) => (
                      <code
                        className={'mr-[8px] block'}
                        key={`${pageGroup.label}-${opt.label}`}
                      >
                        {opt.value.customKey
                          ? `${opt.value.customKey} (title: ${opt.label})`
                          : opt.label}
                        <span className={'ml-[4px]'}>
                          ({opt.value.categoryOptions?.displayOrder})
                        </span>
                      </code>
                    ))}
                  </div>
                </div>
              )
            })}
          </details>
        )}

        <AnimatedPopupFormFooter
          yesBtn={{
            label: 'Save',
          }}
          noBtn={{
            action: closeModal,
          }}
          formState={formState}
        />
      </form>
    </AnimatedPopup>
  )
}
