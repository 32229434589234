import { ReactElement, useContext, useEffect, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import Card from '@shared/components/Card'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import {
  configureSettingsId,
  isCompleteSettingsConfig,
  SettingsConfig,
} from '@shared/utils/orgFacilitySettings'
import { tw } from '@shared/utils/tailwind'
import Content from '@app/components/generic/Content'
import HUD from '@app/components/HUD'
import { ConfigurationLevelSelects } from '../ConfigurationLevelSelects/ConfigurationLevelSelects'
import { OrgFacilitySelects } from '../SharedSelects/OrgFacilitySelects'

export type OnSubmit = {
  ids: SettingsConfig
  data: any
}

/**
 * @param {string} pageTitle - Main Page Title
 * @param {string} loaderTitle - Title of loader UI with Org. and Facility dropdowns
 * @param {string} saverTitle - Title of saver UI with Level, Org. State and Facility dropdowns
 * @param {string} childrenTitle - Title of customized content
 * @param {string} saveBtnLabel - Save button's label
 * @param {string} hudText - Head Up Display's text after saving
 * @param {FunctionalComponent} children - Customized content
 * @param {Function} loaderFunc - Function to call once loader UI is set
 * @param {Function} [loaderFuncMap = (res) => res] - Function to map loading data, for example (res) => res.settings.dietSettings
 * @param {Function} onSubmit - Function to call after click on Save button
 * @param {UseFormReturn} useFormReturn - Methods return by useForm from react-hook-form
 * @param {string} path - path of list use in children component with map the value in useFormReturn
 */

type Props = {
  pageTitle: string
  loaderTitle: string
  saverTitle: string
  childrenTitle: string
  saveBtnLabel: string
  hudText: string
  children: ReactElement
  loaderFunc: (orgId: string, facilityId: string) => Promise<any>
  loaderFuncMap?: (res: any) => any
  onSubmit: ({ ids, data }: OnSubmit) => Promise<void>
  useFormReturn: UseFormReturn
  path: string
}

export default function OrgFacilityLoaderSaver({
  pageTitle,
  loaderTitle,
  saverTitle,
  childrenTitle,
  saveBtnLabel,
  hudText,
  children,
  loaderFunc,
  loaderFuncMap = (res: any) => res,
  onSubmit,
  useFormReturn,
  path,
}: Props) {
  const { setError } = useContext(GlobalContext)
  const [selectedOrganization, setSelectedOrganization] =
    useState<OptionTypeBase<Organization> | null>(null)
  const [selectedFacility, setSelectedFacility] =
    useState<OptionTypeBase<Facility> | null>(null)
  const [savingSettingsConfig, setSavingSettingsConfig] =
    useState<SettingsConfig>({})
  const [showHUD, setShowHUD] = useState(false)
  const { clearErrors, formState, handleSubmit, setValue, watch } =
    useFormReturn
  const { isSubmitting } = formState
  const data = watch(path)

  const selectedOrganizationId = selectedOrganization?.value.id
  const selectedFacilityId = selectedFacility?.value.id
  useEffect(() => {
    setValue(path, undefined)
    clearErrors()
    if (selectedOrganizationId && selectedFacilityId) {
      loaderFunc(selectedOrganizationId, selectedFacilityId)
        .then((res: any) => {
          setValue(path, loaderFuncMap(res))
        })
        .catch(setError)
    }
  }, [selectedOrganizationId, selectedFacilityId])

  const onSubmitFunc = async (data: any) => {
    try {
      const ids = configureSettingsId(savingSettingsConfig)
      await onSubmit({ ids: ids as never, data })
      setShowHUD(true)
    } catch (e) {
      setError(e)
    }
  }

  return (
    <Content>
      <h1 className={tw`page-title mt-[24px]`}>{pageTitle}</h1>
      <form
        onSubmit={handleSubmit(onSubmitFunc)}
        style={{
          display: 'grid',
          gap: '32px',
          gridTemplateColumns: 'repeat(auto-fill, minmax(496px, 1fr))',
        }}
      >
        <section>
          <Card className={tw`mb-[16px] flex flex-col`}>
            <OrgFacilitySelects
              onFacilitySelect={setSelectedFacility}
              onOrgSelect={setSelectedOrganization}
              orgLabel={loaderTitle}
              selectedOrganization={selectedOrganization}
              selectedFacility={selectedFacility}
            />
          </Card>
          {data && (
            <Card className={tw`flex flex-col`}>
              <ConfigurationLevelSelects
                onSelectionChange={setSavingSettingsConfig}
                defaultOrg={selectedOrganization}
                defaultFacility={selectedFacility}
                labelText={saverTitle}
              />
              <Button
                data-testid="orgFacilityLoaderSaver-submit-btn"
                isLoading={isSubmitting}
                disabled={
                  isSubmitting ||
                  !isCompleteSettingsConfig(savingSettingsConfig)
                }
                buttonStyle="primary-fill"
                className={tw`mt-[8px]`}
                type="submit"
              >
                {saveBtnLabel}
              </Button>
            </Card>
          )}
        </section>
        {data && (
          <section>
            <h2 className={tw`mb-[16px] text-[16px] leading-[18px]`}>
              {childrenTitle}
            </h2>
            {children}
          </section>
        )}
      </form>
      {showHUD && (
        <HUD onExpire={() => setShowHUD(false)}>
          <div className={tw`text-[16px] font-semibold leading-[24px]`}>
            {hudText}
          </div>
        </HUD>
      )}
    </Content>
  )
}
