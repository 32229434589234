import {
  AggregateInterval,
  FacilityMetricAggregateHistory,
} from '@augusthealth/models/com/august/protos/api/facility_metrics'
import { useEffect, useState } from 'react'
import { fetchFacilityMetrics, MetricRequest } from '@shared/api/facility'
import { FacilityIds } from '@shared/types/facility'
import { getDateString } from '@shared/utils/date'
import { AsyncResult } from '@shared/utils/loading'

export default function useFacilityMetrics({
  facility,
  metrics,
  startDate,
  endDate,
  interval,
}: {
  startDate: Date
  endDate: Date
  metrics: MetricRequest[]
  facility: FacilityIds
  interval: AggregateInterval
}) {
  const { orgId, id: facilityId } = facility

  const [metricList, setMetricList] = useState<
    AsyncResult<FacilityMetricAggregateHistory[], any>
  >({
    tag: 'Loading',
  })

  useEffect(() => {
    fetchFacilityMetrics({
      orgId,
      facilityId,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      metrics,
      interval,
    })
      .then((value) => setMetricList({ tag: 'Complete', value }))
      .catch((value) => setMetricList({ tag: 'Error', value }))
  }, [orgId, facilityId, getDateString(startDate), getDateString(endDate)])

  return metricList
}
