import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext } from 'react'
import Badge from '@shared/components/Badge'
import {
  FacilityPermissionGate,
  hasPermissionForFacility,
} from '@shared/components/PermissionGates/PermissionGates'
import NavigationItem from '@shared/components/Sidebar/NavigationItem'
import { useUserContext } from '@shared/contexts/UserContext'
import * as urls from '@shared/legacy_routes'
import * as routes from '@shared/routes'
import {
  billingFeatureForFacility,
  hasActivePharmacyIntegration,
} from '@shared/utils/facilities'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import { useOccupancyStatsInFacilitiesContext } from '@app/hooks/useOccupancyStats'
import styles from './community-section.module.css'
import { getOccupancyPercentageLabel } from '../helpers'
import SidebarContentsContext from '../SidebarContentsContext'

export default function CommunitySection() {
  const { currentPage, orgId, currentFacility } = useContext(
    SidebarContentsContext
  )
  const { user } = useUserContext()
  const facilityId = currentFacility!.id!

  const canSeeInbox =
    hasPermissionForFacility({
      user,
      facility: { orgId: orgId!, id: facilityId },
      permissions: [GroupPermission.GROUP_PERMISSION_MEDICATION_UPDATE],
    }) && hasActivePharmacyIntegration(currentFacility!)

  const hasBillingPermission = hasPermissionForFacility({
    user,
    facility: { orgId: orgId!, id: facilityId },
    permissions: [GroupPermission.GROUP_PERMISSION_BILLING_READ],
  })
  const canSeeBilling =
    currentFacility &&
    hasBillingPermission &&
    billingFeatureForFacility(currentFacility) === 'Community-wide Billing'

  return (
    <section>
      <ul className={styles.linksList}>
        {canSeeInbox && (
          <NavigationItem
            isActive={
              currentPage.tag === 'Facility' && currentPage.page === 'Inbox'
            }
            url={urls.getFacilityInboxUrl(orgId!, facilityId)}
            title="Inbox"
            iconClass="fas fa-inbox"
            count={currentFacility?.statistics?.inboxCount}
          />
        )}
        {canSeeBilling && (
          <NavigationItem
            isActive={
              currentPage.tag === 'Facility' && currentPage.page === 'Billing'
            }
            url={routes.billingRoute(orgId!, facilityId)}
            title="Billing"
            iconClass="fas fa-piggy-bank"
          />
        )}

        <NavigationItem
          isActive={
            currentPage.tag === 'Facility' &&
            currentPage.page === 'ProspectList'
          }
          url={urls.getProspectsUrl(orgId!, facilityId)}
          title="Move-ins"
          iconClass="fas fa-users"
          count={currentFacility?.statistics?.prospectCount}
        />
        <NavigationItem
          isActive={
            currentPage.tag === 'Facility' &&
            currentPage.page === 'ResidentList'
          }
          url={urls.getResidentsUrl(orgId!, facilityId)}
          title="Residents"
          iconClass="fas fa-user-friends"
          count={currentFacility?.statistics?.residentCount}
        />
        <FacilityPermissionGate
          facility={currentFacility}
          permissions={[GroupPermission.GROUP_PERMISSION_TASK_READ]}
        >
          <NavigationItem
            isActive={
              currentPage.tag === 'Facility' &&
              currentPage.page === 'Compliance'
            }
            url={urls.getComplianceUrl(orgId!, facilityId)}
            title="Compliance"
            iconClass="fas fa-clipboard-list"
          />
        </FacilityPermissionGate>
        <FacilityPermissionGate
          facility={currentFacility}
          permissions={[GroupPermission.GROUP_PERMISSION_OCCUPANCY_READ]}
        >
          <OccupancyNavigationItem />
        </FacilityPermissionGate>
        <FacilityPermissionGate
          facility={currentFacility}
          permissions={[GroupPermission.GROUP_PERMISSION_INCIDENT_READ]}
        >
          <NavigationItem
            isActive={
              currentPage.tag === 'Facility' && currentPage.page === 'Incidents'
            }
            url={urls.getIncidentsUrl(orgId!, facilityId)}
            title="Notes & Incidents"
            iconClass="fa-solid fa-face-head-bandage"
          />
        </FacilityPermissionGate>
        <FacilityPermissionGate
          facility={currentFacility}
          permissions={[
            GroupPermission.GROUP_PERMISSION_SUPERSET_DASHBOARD_READ,
          ]}
        >
          <NavigationItem
            isActive={
              currentPage.tag === 'Facility' && currentPage.page === 'Insights'
            }
            url={urls.getInsightsUrl(orgId!, facilityId)}
            title="Insights"
            titleComponent={
              <>
                Insights
                <Badge className="ml-[8px]" shape={'square'} color={'green'}>
                  Beta
                </Badge>
              </>
            }
            iconClass="fas fa-chart-mixed"
          />
        </FacilityPermissionGate>
      </ul>
    </section>
  )
}

function OccupancyNavigationItem() {
  const { occupancyStats } = useContext(FacilitiesContext)
  const occupancyPercentage = getOccupancyPercentageLabel(occupancyStats)
  const { currentPage, currentFacility } = useContext(SidebarContentsContext)
  const { id: facilityId, orgId } = currentFacility || {}
  useOccupancyStatsInFacilitiesContext({
    orgId: orgId!,
    facilityId: facilityId!,
  })

  return (
    <NavigationItem
      isActive={
        currentPage.tag === 'Facility' && currentPage.page === 'Occupancy'
      }
      url={urls.getOccupancyUrl(orgId!, facilityId!)}
      title="Occupancy"
      iconClass="fas fa-bed-bunk"
      count={occupancyPercentage}
    />
  )
}
