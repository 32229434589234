import { ApplicationEvent } from '@augusthealth/models/com/august/protos/application_event'
import { AnalyticsAPI } from '@shared/api/facility'
import { NameTd, TdWithExtraPadding } from '@app/components/Table'
import { getSum } from './helpers'

export default function EventCountsTr({
  title,
  analyticsHash = {} as AnalyticsAPI,
  event,
}: {
  title: string
  analyticsHash?: AnalyticsAPI
  event: ApplicationEvent
}) {
  const eventCounts = analyticsHash[event]?.counts || []

  return (
    <tr>
      <NameTd className="capitalize" title={title}>
        {title}
      </NameTd>
      <TdWithExtraPadding data-note="today">
        {eventCounts[eventCounts.length - 1]}
      </TdWithExtraPadding>
      <TdWithExtraPadding data-note="yesterday">
        {eventCounts[eventCounts.length - 2]}
      </TdWithExtraPadding>
      <TdWithExtraPadding data-note="week">
        {getSum(eventCounts.slice(-7))}
      </TdWithExtraPadding>
      <TdWithExtraPadding data-note="month">
        {getSum(eventCounts)}
      </TdWithExtraPadding>
      <TdWithExtraPadding data-note="min">
        {Math.min(...eventCounts)}
      </TdWithExtraPadding>
      <TdWithExtraPadding data-note="max">
        {Math.max(...eventCounts)}
      </TdWithExtraPadding>
    </tr>
  )
}
