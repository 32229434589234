import { useState } from 'react'
import { BillingTransaction } from '@shared/types/billing'
import { RequiredPersonIds } from '@shared/types/person'
import { BillingModalType } from '@shared/utils/billing'
import { getOrElse, Loading } from '@shared/utils/loading'
import { tw } from '@shared/utils/tailwind'
import { Header } from '@app/components/Table/TableHeader'
import WithTable from '@app/pages/Facilities/Billing/Components/WithTable'
import { TransactionsColumnName } from '../../helpers'
import {
  BadgeFilterType,
  filterTransactions,
  getBadgeFilterOptions,
  getPeriodItems,
  getSearchedBillingTransactions,
} from './helpers'
import TransactionsBadgeFilter from './TransactionBadgesFilter'
import TransactionRow from './TransactionRow'

type Props = {
  personIds: RequiredPersonIds
  billingTransactions: Loading<BillingTransaction[]>
  openTransactionModal: (
    transaction: BillingTransaction,
    modalType: BillingModalType
  ) => void
}

const headers: Header[] = [
  {
    label: TransactionsColumnName.SERVICE_DATE,
    className: 'w-auto',
  },
  {
    label: TransactionsColumnName.DESCRIPTION,
    className: 'w-[18%]',
  },
  {
    label: TransactionsColumnName.TRANSACTION_TYPE,
    className: 'w-[18%]',
  },
  {
    label: TransactionsColumnName.AMOUNT,
    className: 'w-[18%]',
  },
  {
    label: TransactionsColumnName.BALANCE,
    className: 'w-[18%]',
  },
  {
    label: '',
    className: 'w-[120px]',
  }, // Column for Action Menu, no label
]

export default function BillingTransactionsTable(props: Props) {
  const { billingTransactions, openTransactionModal, personIds } = props
  const [badgeFilter, setBadgeFilter] = useState<BadgeFilterType>()
  const [monthIndex, setMonthIndex] = useState<number>()
  const periodOptions = getPeriodItems({
    selectedMonthIndex: monthIndex,
    onClick: setMonthIndex,
  })

  const selectedPeriodLabel = (
    periodOptions.find((opt) => opt.mIndex === monthIndex) || periodOptions[0]
  ).title

  return (
    <WithTable
      title={
        <div className={tw`flex items-center`}>
          <div className={tw`mr-[24px]`}>Transactions</div>
          <TransactionsBadgeFilter
            name="transactionBadgeFilter"
            options={getBadgeFilterOptions(getOrElse(billingTransactions, []))}
            onClick={(badgeValue) =>
              setBadgeFilter((prev) =>
                prev === badgeValue
                  ? undefined
                  : (badgeValue as BadgeFilterType)
              )
            }
            selectedValue={badgeFilter}
          />
        </div>
      }
      rows={billingTransactions}
      headers={headers}
      defaultSelectedColumn={TransactionsColumnName.SERVICE_DATE}
      searchBoxProps={{
        searchPlaceholder: 'Search transactions...',
        searchFn: getSearchedBillingTransactions,
        order: 2,
      }}
      downloadMenuProps={{
        menuItems: periodOptions,
        buttonProps: {
          initialIcon: 'calendar',
          cta: selectedPeriodLabel,
        },
        order: 1,
      }}
    >
      {(sortedRows: BillingTransaction[]) => (
        <>
          {filterTransactions({
            transactions: sortedRows,
            badgeFilter,
            monthIndex,
          }).map((transaction, index) => {
            return (
              <TransactionRow
                key={`transction-row-${index}`}
                personIds={personIds}
                transaction={transaction}
                openTransactionModal={openTransactionModal}
              />
            )
          })}
        </>
      )}
    </WithTable>
  )
}
