import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { DetailModalMode } from '@shared/types/assessment_configuration'
import {
  DetailWithCheckbox,
  FrontendDetail,
} from '@app/pages/Tasks/ResidentAssessment/AssessmentPage/CheckboxSections/utils'
import styles from '../styles.module.css'
import { EditRemoveButtons } from '../EditRemoveButtons'

export type Props = {
  question: DetailWithCheckbox
  updateDetail: (eventDetails: {
    value: string | number
    index: number
    key: string
    optIndex?: number
    toUpdate: 'description' | 'score'
  }) => void
  index: number
  detailGroupKey: string
  setShowAddEditModal: (mode: DetailModalMode | undefined) => void
  editDetailFromModal: ({
    updatedDetail,
    index,
    optIndex,
  }: {
    updatedDetail: Partial<FrontendDetail>
    index: number
    optIndex?: number
  }) => void
  removeDetail: ({
    index,
    optIndex,
    key,
  }: {
    index: number
    optIndex?: number
    key: string
  }) => void
  disabled: boolean
}
export const EditCheckbox = ({
  question,
  updateDetail,
  index,
  detailGroupKey,
  setShowAddEditModal,
  editDetailFromModal,
  removeDetail,
  disabled,
}: Props) => {
  const onEdit = () => {
    setShowAddEditModal({
      tag: 'edit',
      currentDetail: question,
      editDetail: editDetailFromModal,
      index,
    })
  }

  const onRemove = () => {
    removeDetail({ index, key: detailGroupKey })
  }
  return (
    <>
      <EditRemoveButtons
        onEdit={onEdit}
        onRemove={onRemove}
        disabled={disabled}
      />
      <BasicInput
        type={'text'}
        value={question.value.description}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateDetail({
            value: e.target.value,
            index,
            key: detailGroupKey,
            toUpdate: 'description',
          })
        }
        data-testid={`${question.value.group}-${index}-description`}
        disabled={disabled}
      />
      <BasicInput
        type={'number'}
        value={question.value.score}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateDetail({
            value: e.target.value,
            index,
            key: detailGroupKey,
            toUpdate: 'score',
          })
        }
        className={styles.scoreInput}
        data-testid={`${question.value.group}-${index}-score`}
        disabled={disabled}
      />
    </>
  )
}
