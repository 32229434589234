import {
  BillingCategory,
  BillingFrequency,
  BillingItem,
} from '@shared/types/billing'
import {
  convertEnumValueToLabel,
  Order,
  sortNumberString,
} from '@shared/utils/common'
import { getOrElse, Loading } from '@shared/utils/loading'

export function getSearchedFees({
  rows,
  searchTerm,
}: {
  rows: Loading<BillingItem[]>
  searchTerm: string
}) {
  return getOrElse(rows, []).filter((fee) => {
    const {
      data: { name },
      category: {
        data: { name: categoryName },
      },
    } = fee
    const billingCategory = categoryName.toLowerCase()
    const typeAbbrv = billingCategory.slice(0, 3)
    const description = name.toLowerCase()
    const searchTermLower = searchTerm.toLowerCase()
    return (
      billingCategory.includes(searchTermLower) ||
      typeAbbrv.includes(searchTermLower) ||
      description.includes(searchTermLower)
    )
  })
}

export enum FeesColumnName {
  BILLING_CATEGORY = 'Category',
  DESCRIPTION = 'Description',
  APPLIES_TO = 'Applies to',
  AMOUNT = 'Amount',
  FREQUENCY = 'Frequency',
  GL_CODE = 'GL Code',
}

export enum BillingCategoriesColumnName {
  NAME = 'Name',
  APPLIES_TO = 'Applies to',
}

export function getSortedFees({
  rows,
  selectedColumn,
  sortingOrder,
}: {
  rows: BillingItem[]
  selectedColumn: FeesColumnName
  sortingOrder: Order
}) {
  if (selectedColumn === FeesColumnName.BILLING_CATEGORY) {
    return rows.sort((a, b) => {
      const aType = a?.category?.data.name
      const bType = b.category.data.name
      if (sortingOrder === Order.ASC) {
        return aType.localeCompare(bType)
      }
      return bType.localeCompare(aType)
    })
  }

  if (selectedColumn === FeesColumnName.DESCRIPTION) {
    return rows.sort((a, b) => {
      const aDesc = a.data.name
      const bDesc = b.data.name
      if (sortingOrder === Order.ASC) {
        return aDesc.localeCompare(bDesc)
      }
      return bDesc.localeCompare(aDesc)
    })
  }

  if (selectedColumn === FeesColumnName.APPLIES_TO) {
    return rows.sort((a, b) => {
      const x = a.data.facilityId || ''
      const y = b.data.facilityId || ''
      if (sortingOrder === Order.ASC) {
        return sortNumberString(x, y)
      }

      return sortNumberString(y, x)
    })
  }

  if (selectedColumn === FeesColumnName.AMOUNT) {
    return rows.sort((a, b) => {
      const aAmount = a.data.amountCents
      const bAmount = b.data.amountCents
      // Always sort the amout at the bottom of the table when it is undefined
      if (!bAmount) return -1
      if (!aAmount) return 1

      if (sortingOrder === Order.ASC) {
        return aAmount - bAmount
      }
      return bAmount - aAmount
    })
  }

  if (selectedColumn === FeesColumnName.FREQUENCY) {
    return rows.sort((a, b) => {
      const aFrequency = a.data.frequency
      const bFrequency = b.data.frequency
      if (sortingOrder === Order.ASC) {
        return aFrequency.localeCompare(bFrequency)
      }
      return bFrequency.localeCompare(aFrequency)
    })
  }

  if (selectedColumn === FeesColumnName.GL_CODE) {
    return rows.sort((a, b) => {
      const aGlCode = a.data.glCode
      const bGlCode = b.data.glCode
      if (sortingOrder === Order.ASC) {
        return aGlCode.localeCompare(bGlCode)
      }
      return bGlCode.localeCompare(aGlCode)
    })
  }

  return rows
}

export function getSearchedBillingCategories({
  rows,
  searchTerm,
}: {
  rows: Loading<BillingCategory[]>
  searchTerm: string
}) {
  return getOrElse(rows, []).filter((type) => {
    const {
      data: { name, orgId, facilityId },
    } = type
    const typeName = name.toLowerCase()
    const orgName = orgId.toLowerCase()
    const facilityName = facilityId?.toLowerCase()
    const searchTermLower = searchTerm.toLowerCase()
    return (
      typeName.includes(searchTermLower) ||
      orgName.includes(searchTermLower) ||
      facilityName?.includes(searchTermLower)
    )
  })
}

export function getSortedBillingCategories({
  rows: targetTypes,
  selectedColumn,
  sortingOrder,
}: {
  rows: BillingCategory[]
  selectedColumn: BillingCategoriesColumnName
  sortingOrder: Order
}) {
  if (selectedColumn === BillingCategoriesColumnName.NAME) {
    return targetTypes.sort((a, b) => {
      const aName = a.data.name
      const bName = b.data.name
      if (sortingOrder === Order.ASC) {
        return aName.localeCompare(bName)
      }
      return bName.localeCompare(aName)
    })
  }

  if (selectedColumn === BillingCategoriesColumnName.APPLIES_TO) {
    return targetTypes.sort((a, b) => {
      const x = a?.data?.facilityId || ''
      const y = b?.data?.facilityId || ''
      if (sortingOrder === Order.ASC) {
        return sortNumberString(x, y)
      }
      return sortNumberString(y, x)
    })
  }

  return targetTypes
}

export const FREQUENCY_OPTIONS = Object.values(BillingFrequency).map(
  (value) => ({
    label: convertEnumValueToLabel(value),
    value,
  })
)
