import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import {
  SignatureInfo,
  Task,
} from '@augusthealth/models/com/august/protos/task'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { updateSignaturePacket } from '@shared/api/task'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { taskPathForPerson } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { getFirstName } from '@shared/utils/humanName'
import { isAdminSigner, isExternalSigner } from '@shared/utils/signer'
import {
  nextSigner as getNextSigner,
  isAwaitingReview,
  isPendingSignatures,
  signatureInfoForUser,
} from '@shared/utils/task'
import { userMatchesSigner } from '@shared/utils/user'
import { getNextRequiredSigner } from '@app/components/SignatureFlow/helpers'
import { getProposedSigners, signersHaveChanged } from './helpers'

export default function ContinueButton({
  task,
  person,
  user,
  currentSigners,
  refreshTasks,
  onClose,
  disabled,
  setButtonIsProcessing,
}: {
  task: Task
  person: Person
  user: UserAccount
  currentSigners: SignatureInfo[]
  refreshTasks: () => Promise<void>
  onClose: () => void
  disabled: boolean
  setButtonIsProcessing: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const history = useHistory()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isChangingSigner, setIsChangingSigner] = useState(false)
  const { setError } = useContext(GlobalContext)

  const isCurrentUserAnAdminSigner = signatureInfoForUser({
    user,
    currentAdminSigners: currentSigners,
  })
  const nextSigner = getNextSigner(task)
  const imNext = nextSigner ? userMatchesSigner(user, nextSigner) : false

  async function updateSigners() {
    setIsChangingSigner(true)

    try {
      const signers = currentSigners
        .filter(
          (sig) => isAdminSigner(sig.signer!) || isExternalSigner(sig.signer!)
        )
        .map((sig) => sig.signer!)

      await updateSignaturePacket({
        person,
        task,
        signers,
      })
      await refreshTasks()
    } catch (e) {
      setError(e)
    } finally {
      setIsChangingSigner(false)
    }
  }

  // When pending signatures, look at the next signer
  // to determine if a continue button gets rendered
  // if some other admin-signer is next, we don't show a CTA here
  const newSignersProposed = signersHaveChanged(
    task.signatures ?? [],
    currentSigners
  )

  if (isPendingSignatures(task) && imNext && !newSignersProposed) {
    return (
      <AsyncIconButton
        isLoading={isChangingSigner}
        className={'mt-[16px]'}
        disabled={isChangingSigner || disabled}
        buttonStyle={'primary-fill'}
        data-testid={'requestSignatureBtn'}
        initialIcon={'fa-pen-nib'}
        onClick={async () => {
          setButtonIsProcessing(true)
          if (imNext) {
            history.push(
              taskPathForPerson(person as Required<Person>, task.id!)
            )
          } else {
            await updateSigners()
            setButtonIsProcessing(false)
            onClose()
          }
        }}
      >
        {imNext
          ? 'Continue to Sign'
          : `Request ${getFirstName(
              isCurrentUserAnAdminSigner?.signer?.name
            )}'s Signature`}
      </AsyncIconButton>
    )
  }

  // In this case we want to change the admin signer,
  // so we need to show a 'Request/Continue' cta button
  if (isPendingSignatures(task) && newSignersProposed) {
    const imNextProposedAdminSigner = isUserNextProposedSigner({
      task,
      currentAdminSigners: currentSigners,
      user,
    })
    const ctaText = imNextProposedAdminSigner
      ? 'Continue to Sign'
      : `Update signers`

    return (
      <AsyncIconButton
        isLoading={isChangingSigner}
        className={'mt-[16px]'}
        disabled={isChangingSigner || disabled}
        buttonStyle={'primary-fill'}
        data-testid={'requestSignatureBtn'}
        initialIcon={'fa-pen-nib'}
        onClick={async () => {
          setButtonIsProcessing(true)
          // Change the signer
          await updateSigners()

          // If I'm next
          if (imNextProposedAdminSigner) {
            history.push(
              taskPathForPerson(person as Required<Person>, task.id!)
            )
          } else {
            setButtonIsProcessing(false)
            onClose()
          }
        }}
      >
        {ctaText}
      </AsyncIconButton>
    )
  }

  if (isAwaitingReview(task)) {
    const imNextProposedAdminSigner = isUserNextProposedSigner({
      task,
      currentAdminSigners: currentSigners,
      user,
    })
    const ctaText = imNextProposedAdminSigner
      ? 'Continue to Sign'
      : 'Request Signature'

    return (
      <PersonPermissionGate
        person={person}
        permissions={[GroupPermission.GROUP_PERMISSION_TASK_CREATE]}
      >
        <AsyncIconButton
          buttonStyle={'primary-fill'}
          className={'mt-[16px]'}
          initialIcon={'fa-pen-nib'}
          data-testid={'requestSignatureBtn'}
          isLoading={isSubmitting}
          disabled={isSubmitting || disabled}
          onClick={async () => {
            try {
              setButtonIsProcessing(true)
              setIsSubmitting(true)

              await updateSigners()
              await refreshTasks()

              if (imNextProposedAdminSigner) {
                history.push(
                  taskPathForPerson(person as Required<Person>, task.id || '')
                )
              } else {
                onClose()
              }
            } catch (e) {
              setError(e)
              setButtonIsProcessing(false)
              setIsSubmitting(false)
            }
          }}
        >
          {ctaText}
        </AsyncIconButton>
      </PersonPermissionGate>
    )
  }

  return null
}

function isUserNextProposedSigner({
  task,
  currentAdminSigners,
  user,
}: {
  task: Task
  currentAdminSigners: SignatureInfo[]
  user: UserAccount
}) {
  const proposedSigners = getProposedSigners({
    originalSigners: task.signatures ?? [],
    proposedSigners: currentAdminSigners,
  })

  const nextRequiredSigner = getNextRequiredSigner({ signers: proposedSigners })
  return nextRequiredSigner
    ? userMatchesSigner(user, nextRequiredSigner.signer)
    : false
}
