import { matchPath } from 'react-router-dom'
import { fetchUser } from '@shared/api/user'
import { logout } from '@shared/components/Auth/Auth'
import { scheme } from '@shared/hooks/useCurrentPage'
import { isSuperUser } from '@shared/utils/user'

export async function userHasAccessToOrg(redirectPath: string) {
  const user = await fetchUser()

  const matchResult = matchPath<{
    orgId?: string
    personId?: string
    facilityId?: string
  }>(redirectPath, {
    path: scheme,
  })

  return (
    (user.groups ?? []).some(
      (g) => g.personMatcher?.organizationId === matchResult?.params.orgId
    ) || isSuperUser(user)
  )
}

export const logoutIfNoSessionIsStored = (event: StorageEvent) => {
  const { key, newValue } = event
  if (isCognitoIdToken(key) && newValue === null) {
    void logout()
  }

  // The key is null when window.localStorage.clear() is called
  if (key === null) {
    void logout()
  }
}

export const isCognitoIdToken = (key: string | null) => {
  return (
    typeof key === 'string' &&
    key.startsWith('CognitoIdentityServiceProvider') &&
    key.endsWith('idToken')
  )
}
