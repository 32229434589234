import { DateMessage } from '@augusthealth/models/com/august/protos/date'
import { MedicationOrder as LegacyMedicationOrder } from '@augusthealth/models/com/august/protos/medication_order'
import { MedicationStatement } from '@augusthealth/models/com/august/protos/medication_statement'
import { flatten, orderBy } from 'lodash'
import { MedicationOrder } from '@shared/types/medication_order'
import {
  isActive,
  isApproved,
  isDiscontinued,
  isExternal,
  isOnlyPRN,
  isPending,
  isPendingDiscontinue,
  isPendingNew,
  isPendingUpdate,
  isRejected,
  isTreatment,
  isWeakMatch,
} from '@shared/utils/medicationStatement'
import notEmpty from '@shared/utils/notEmpty'
import { isTaperDosageType } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/helpers'

const getStartOrderDateFromBoundsPeriod = (
  order: MedicationStatement
): DateMessage | null => {
  if (order.dosageInstruction?.length) {
    const firstDoseStart =
      order.dosageInstruction[0].timing?.boundsPeriod?.startDate

    return firstDoseStart ?? null
  }

  return null
}

const getEndOrderDateFromBoundsPeriod = (
  order: MedicationStatement
): DateMessage | null => {
  if (order.dosageInstruction?.length) {
    const lastDoseIndex = order.dosageInstruction.length - 1
    const lastDoseEnd =
      order.dosageInstruction[lastDoseIndex].timing?.boundsPeriod?.endDate

    return lastDoseEnd ?? null
  }

  return null
}
export const getStartAndEndOrderDates = (order: MedicationStatement) => {
  if (isTaperDosageType(order.dosageType)) {
    return {
      startingDate: getStartOrderDateFromBoundsPeriod(order),
      endingDate: getEndOrderDateFromBoundsPeriod(order),
    }
  }

  return {
    startingDate: order.effectivePeriod?.startDate ?? null,
    endingDate: order.effectivePeriod?.endDate ?? null,
  }
}

export const getMedicationOrderKey = (order: LegacyMedicationOrder): string => {
  if (isPending(order as MedicationOrder)) {
    return `pending-${order.sourceData!.messageId!}`
  }

  const med = order.medicationStatement!
  const pharmacy = med.pharmacy
  const rxNumber = med.rxNumber
  const medId = order.id

  return [pharmacy, rxNumber, medId].filter(notEmpty).join('-')
}

export const getUnassignedMedicationOrderKey = (
  order: LegacyMedicationOrder
): string => {
  const pharmacy = order.medicationStatement?.pharmacy
  const externalMessageId = order.sourceData?.externalMessageId
  const externalOrderId = order.sourceData?.externalOrderId

  return [pharmacy, externalMessageId, externalOrderId]
    .filter(notEmpty)
    .join('-')
}

const alphabetizeByDrugName = (medOrders: LegacyMedicationOrder[]) => {
  return orderBy(medOrders, 'medicationStatement.medication.drugName')
}

export const getSortedMedicationOrderList = ({
  pharmacyMessages,
  medicationOrders,
}: {
  pharmacyMessages: LegacyMedicationOrder[]
  medicationOrders: LegacyMedicationOrder[]
}) => {
  const weakMatches = pharmacyMessages.filter((med) =>
    isWeakMatch(med as MedicationOrder)
  )
  const pendingNew = pharmacyMessages.filter((med) =>
    isPendingNew(med as MedicationOrder)
  )
  const pendingUpdate = pharmacyMessages.filter((med) =>
    isPendingUpdate(med as MedicationOrder)
  )
  const pendingDiscontinue = pharmacyMessages.filter((med) =>
    isPendingDiscontinue(med as MedicationOrder)
  )
  const approvedExternal = pharmacyMessages.filter(
    (med) =>
      isApproved(med as MedicationOrder) &&
      !isDiscontinued(med!.medicationStatement!)
  )

  const activeMeds = [
    ...medicationOrders.filter((med) => isActive(med.medicationStatement!)),
    ...approvedExternal,
  ]

  const scheduledMeds = activeMeds.filter(
    (med) =>
      !isOnlyPRN(med.medicationStatement!) &&
      !isTreatment(med.medicationStatement!)
  )
  const treatmentMeds = activeMeds.filter((med) =>
    isTreatment(med.medicationStatement!)
  )
  const prnOnlyMeds = activeMeds.filter(
    (med) =>
      isOnlyPRN(med.medicationStatement!) &&
      !isTreatment(med.medicationStatement!)
  )

  const discontinuedMeds = [
    ...medicationOrders.filter((med) =>
      isDiscontinued(med.medicationStatement!)
    ),
    ...pharmacyMessages.filter(
      (med) =>
        isDiscontinued(med!.medicationStatement!) &&
        !isPending(med as MedicationOrder)
    ),
  ]

  const activeMedsList = [
    alphabetizeByDrugName([
      ...pendingNew,
      ...pendingUpdate,
      ...pendingDiscontinue,
    ]),
    alphabetizeByDrugName(scheduledMeds),
    alphabetizeByDrugName(treatmentMeds),
    alphabetizeByDrugName(prnOnlyMeds),
  ]

  return {
    weakMatchMeds: alphabetizeByDrugName(weakMatches),
    activeMeds: flatten(activeMedsList),
    discontinuedMeds: alphabetizeByDrugName(discontinuedMeds),
  }
}

export const getPharmacyMessageTitle = (order: MedicationOrder) => {
  let text = 'Review Order'

  if (isPendingUpdate(order)) {
    text = 'Update Order'
  } else if (isPendingDiscontinue(order)) {
    text = 'Discontinue Order'
  } else if (isRejected(order)) {
    text = 'Rejected Order'
  } else if (isDiscontinued(order.medicationStatement) && !isPending(order)) {
    text = 'Discontinued Order'
  }

  return text
}
const getMedicationOrderTitle = (
  order: MedicationOrder,
  useOrderTerminology = true
) => {
  const noun = useOrderTerminology ? 'Order' : 'Medication'

  let text = `Edit ${noun}`

  if (isDiscontinued(order.medicationStatement)) {
    text = `Discontinued ${noun}`
  }

  return text
}

export const getMedicationBreadCrumbText = (
  medicationOrder: MedicationOrder,
  useOrderTerminology: boolean
) => {
  // only external pharmacy messages need to worry about the various states
  if (isExternal(medicationOrder)) {
    return getPharmacyMessageTitle(medicationOrder)
  }

  return getMedicationOrderTitle(medicationOrder, useOrderTerminology)
}
