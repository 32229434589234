import { AugustFieldFormConfigurationMap } from '@shared/constants/custom_field/type'
import { AugustFieldType } from '@shared/types/custom_field'
import { ADMISSIONS_INFORMATION } from './categories/admissionsInformation'
import { CONTACTS_INFO } from './categories/contacts'
import { HEALTH_DIET } from './categories/healthDiet'
import { INSURANCE_INFO } from './categories/insuranceInfo'
import { RESIDENT_INFO } from './categories/residentInfo'

export const AUGUST_FIELD_DISPLAY_CONFIGURATION: AugustFieldFormConfigurationMap =
  {
    ...CONTACTS_INFO,
    ...INSURANCE_INFO,
    ...RESIDENT_INFO,
    ...HEALTH_DIET,
    ...ADMISSIONS_INFORMATION,
    [AugustFieldType.AUGUST_FIELD_TYPE_SSN]: {
      fieldLists: ['ssn'],
      requiredFields: ['ssn'],
      fields: [
        {
          title: 'SOCIAL SECURITY NUMBER',
          name: 'ssn',
          type: 'SSN',
        },
      ],
    },
  }

export const AUGUST_FIELD_WHITE_LIST: AugustFieldType[] = Object.keys(
  AUGUST_FIELD_DISPLAY_CONFIGURATION
) as AugustFieldType[]
