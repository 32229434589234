import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import StyledSelect from '@shared/components/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import MoneyInput from '@app/components/MoneyInput'
import { useContacts } from '@app/hooks/useContacts'
import styles from './styles.module.css'
import {
  addAdjustment,
  AdjustmentFormData,
  adjustmentReasons,
  contactsToSelect,
} from './helpers'

interface Props {
  person: Person
  done: (newChargeId?: string) => void
}

export default function MakeAdjustment({ person, done }: Props) {
  const { setError } = useContext(GlobalContext)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AdjustmentFormData>()
  const { contacts } = useContacts()

  const onSubmit = async (data: AdjustmentFormData) => {
    try {
      const response = await addAdjustment(person, data)
      done(response)
    } catch (e) {
      setError(e)
    }
  }

  return (
    <AnimatedPopup title="Make Adjustment">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          defaultValue={undefined}
          name="reason"
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { invalid } }) => {
            return (
              <div>
                <LabelAboveInput
                  subLabel={requiredLabel(invalid)}
                  htmlFor="reason"
                >
                  Reason
                </LabelAboveInput>
                <StyledSelect
                  name="reason"
                  options={adjustmentReasons()}
                  value={value}
                  onChange={onChange}
                />
              </div>
            )
          }}
        />
        <Controller
          control={control}
          defaultValue={undefined}
          name="payer"
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { invalid } }) => {
            return (
              <div className="mt-[32px]">
                <LabelAboveInput
                  subLabel={requiredLabel(invalid)}
                  htmlFor="payer"
                >
                  Payer
                </LabelAboveInput>
                <StyledSelect
                  name="payer"
                  value={value}
                  onChange={onChange}
                  isDisabled={contacts.tag === 'Loading'}
                  options={contactsToSelect(
                    contacts.tag === 'Complete' ? contacts.value : []
                  )}
                />
              </div>
            )
          }}
        />
        <div className="mt-[32px]">
          <LabelAboveInput
            subLabel={requiredLabel(!!errors.amount)}
            htmlFor="amount"
          >
            Amount
          </LabelAboveInput>
          <div className={styles.amountContainer}>
            <MoneyInput
              inputProps={{
                className: 'w-[184px]',
                type: 'number',
                step: '0.01',
                ...register('amount', {
                  required: true,
                }),
              }}
            />
          </div>
        </div>
        <div className="mt-[32px]">
          <LabelAboveInput htmlFor="notes">Notes</LabelAboveInput>
          <BasicTextarea
            {...register('notes')}
            placeholder="e.g. Accidental charge for haircut"
          />
        </div>
        <div className="mt-[32px]">
          <button
            className={`rounded-[6px] border border-button-tertiary-color bg-button-default-bg-color px-[12px] py-[11px] text-[11px] font-bold leading-[14px] text-button-tertiary-color hover:bg-button-tertiary-color hover:text-button-fill-font-color ${styles.btn}`}
            onClick={(e) => {
              e.preventDefault()
              done()
            }}
          >
            CANCEL
          </button>
          <button
            className={`rounded-[6px] bg-button-primary-color px-[12px] py-[11px] text-[11px] font-bold leading-[14px] text-button-fill-font-color hover:brightness-125  ${styles.btn}`}
          >
            SAVE
          </button>
        </div>
      </form>
    </AnimatedPopup>
  )
}
