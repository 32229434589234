import { Contact } from '@augusthealth/models/com/august/protos/contact'
import {
  ContactPoint,
  ContactPoint_ContactPointSystem,
} from '@augusthealth/models/com/august/protos/contact_point'
import { TaskTemplateInfo } from '@augusthealth/models/com/august/protos/task'
import { Person } from '@shared/types/person'
import { AugustError } from '@shared/utils/error'
import { updateContact } from '@app/api/contacts'
import { getTemplate } from '@app/api/form'
import { getRequiredFields as getRequiredFieldsFromDisplay } from '../Prospects/Forms/CustomForm/helpers'
import { getRequiredFieldsByTaskType } from '../Prospects/tasksConfig'

export async function setRPEmail({
  responsiblePerson,
  person,
  newRpEmail,
}: {
  responsiblePerson: Contact
  person: Person
  newRpEmail: string
}) {
  const rpTelecom = responsiblePerson.telecom || []
  const newTelecom: ContactPoint[] = [
    ...rpTelecom,
    {
      system: ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_EMAIL,
      value: newRpEmail,
    },
  ]

  await updateContact({
    contact: { ...responsiblePerson, telecom: newTelecom },
    orgId: person.orgId!,
    facilityId: person.facilityId!,
    personId: person.id!,
  })
}

export async function getRequiredFields(
  orgId: string,
  facilityId: string,
  taskTemplateInfo: TaskTemplateInfo
) {
  const { taskType } = taskTemplateInfo
  const defaultRequiredFields: string[] = getRequiredFieldsByTaskType(taskType!)

  try {
    const form = await getTemplate({
      orgId,
      facilityId,
      dataType: taskTemplateInfo.dataType!,
      customType: taskTemplateInfo.customType,
    })

    if (form.data.display) {
      return getRequiredFieldsFromDisplay(form.data.display)
    } else {
      return defaultRequiredFields
    }
  } catch (e) {
    const error = e as AugustError
    if (error.status && error.status === 404) {
      return defaultRequiredFields
    } else {
      throw error
    }
  }
}
