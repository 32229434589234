import { CognitoUser } from 'amazon-cognito-identity-js'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { login } from '@shared/api/login'
import GlobalContext from '@shared/contexts/GlobalContext'
import { ErrorCode } from '@shared/types/api/error_response'
import { tw, twx } from '@shared/utils/tailwind'
import EmailLoginForm from './EmailLoginForm'
import Footer from './Footer'
import ResetPassword from './ForgotPassword/ResetPassword'
import Header from './Header'
import { handleLogin } from './helpers'
import Loader from './Loader'
import UsernameLoginForm from './UsernameLoginForm'

type LoginStep = 'username' | 'email' | 'forgotPassword' | undefined
export type BtnState = 'engaged' | 'disengaged' | 'default'

export const DEFAULT_LOGIN_TITLE = 'Log in to August Health'
export default function LoginWithUsernameOrEmail({
  title = DEFAULT_LOGIN_TITLE,
  applicationBasePath,
}: {
  title?: string
  applicationBasePath: string
}) {
  const history = useHistory()
  const [loginStep, setLoginStep] = useState<LoginStep>('username')
  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [cognitoUserToReset, setCognitoUserToReset] = useState<
    CognitoUser | undefined
  >(undefined)
  const [passwordToReset, setPasswordToReset] = useState<string | undefined>(
    undefined
  )
  const { setError } = useContext(GlobalContext)

  useEffect(() => {
    document.body.classList.add('bg-background-login')
  }, [])

  if (isLoading) {
    return <Loader title="Logging you in" />
  }

  function getBtnState(type: LoginStep) {
    if (loginStep === undefined) {
      return 'default'
    }

    if (loginStep === type) {
      return 'engaged'
    }

    return 'disengaged'
  }

  async function onEmailSubmit({ email }: { email: string }) {
    if (loginStep !== 'email') {
      return setLoginStep('email')
    }

    setSubmitted(true)
    setEmail(email)
    await login(email, history.location.pathname)
  }

  async function onUsernameSubmit({
    preferredUsername,
    password,
  }: {
    preferredUsername: string
    password: string
  }) {
    // Also allow to login right after reset password
    const isReseting = cognitoUserToReset && passwordToReset
    if (loginStep !== 'username' && !isReseting) {
      return setLoginStep('username')
    }

    setIsLoading(true)
    try {
      const user = await handleLogin(preferredUsername, password)
      const { challengeName } = user
      if (challengeName === 'NEW_PASSWORD_REQUIRED') {
        setCognitoUserToReset(user)
        setPasswordToReset(password)
        setIsLoading(false)
      } else {
        setCognitoUserToReset(undefined)
        setPasswordToReset(undefined)
        window.location.pathname = history.location.pathname
      }
    } catch (e) {
      setError({
        json: {
          errors: [
            {
              code: ErrorCode.ERROR_CODE_DISPLAY_TO_CLIENT,
              message: 'Your username or password is incorrect',
            },
          ],
        },
      })
      setIsLoading(false)
    }
  }

  if (submitted) {
    return (
      <div className={tw`flex flex-col items-center`} slot="sign-in">
        <Header title={`We've sent a temporary login link to ${email}`} />
        <Footer />
      </div>
    )
  }

  if (cognitoUserToReset && passwordToReset) {
    return (
      <ResetPassword
        cognitoUser={cognitoUserToReset}
        currentPassword={passwordToReset}
        afterReset={({ preferredUsername, password }) => {
          void onUsernameSubmit({
            preferredUsername,
            password,
          })
        }}
      />
    )
  }

  return (
    <div className={tw`flex flex-col items-center`}>
      <Header title={title} />

      <EmailLoginForm
        handleLogin={onEmailSubmit}
        state={getBtnState('email')}
      />

      <Divider isCollapsed={loginStep === undefined} />

      <UsernameLoginForm
        handleLogin={onUsernameSubmit}
        state={getBtnState('username')}
        applicationBasePath={applicationBasePath}
      />

      <Footer />
    </div>
  )
}

function Divider({ isCollapsed }: { isCollapsed?: boolean }) {
  return (
    <div
      className={twx(
        'flex h-[64px] w-full flex-col justify-center bg-background-login transition-all',
        {
          'h-[16px]': isCollapsed,
        }
      )}
    >
      <hr
        className={twx('my-[16px] w-full border border-secondary-11', {
          'border-0': isCollapsed,
        })}
      />
    </div>
  )
}
