import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useEffect, useState } from 'react'
import { hasPermissionForFacility } from '@shared/components/PermissionGates/PermissionGates'
import SidebarContext from '@shared/components/Sidebar/SidebarContext'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import CommunitySection from './Components/CommunitySection'
import FacilityChooser from './Components/FacilityDropdown'
import ResidentSearch from './Components/ResidentSearch/ResidentSearch'
import UserSettings from './Components/UserSettings'
import { facilityDropdownClasses } from './helpers'
import SidebarContentsContext from './SidebarContentsContext'
import useSidebarData from './useSidebarData'

export default function SidebarContents({
  setShowPermissionsModal,
}: {
  setShowPermissionsModal: (show: boolean) => void
}) {
  /**
   * Called to ensure currentFacility data is set app-wide,
   * since the sidebar shows up on every page (for relevant users)
   */
  useCurrentFacility()

  const sidebarData = useSidebarData()
  const { isAnyMenuOpen, setIsAnyMenuOpen, setShowMobileSidebar } =
    useContext(SidebarContext)

  const [showUserSettings, setShowUserSettings] = useState(false)
  const [showFacilityList, setShowFacilityList] = useState(false)
  const [showSearchDropdown, setShowSearchDropdown] = useState(false)
  const closeAllMenus = () => {
    setShowUserSettings(false)
    setShowFacilityList(false)
    setShowSearchDropdown(false)
  }

  useEffect(() => {
    const anyOfOurMenusAreOpen =
      showUserSettings || showFacilityList || showSearchDropdown

    if (anyOfOurMenusAreOpen) {
      setIsAnyMenuOpen(true)
    }
  }, [showUserSettings, showFacilityList, showSearchDropdown])

  useEffect(() => {
    if (!isAnyMenuOpen) {
      closeAllMenus()
    }
  }, [isAnyMenuOpen])

  if (sidebarData === undefined) {
    /* We've matched a route that doesn't display the sidebar */
    return null
  }

  const { currentFacility, currentPage, facilities, user, orgId } = sidebarData

  const showCommunity = !!currentFacility
  const canCreateVitals = currentFacility
    ? hasPermissionForFacility({
        facility: currentFacility,
        user: user,
        permissions: [GroupPermission.GROUP_PERMISSION_VITAL_CREATE],
      })
    : false

  return (
    <SidebarContentsContext.Provider
      value={{
        currentFacility,
        currentPage,
        facilities,
        orgId,
      }}
    >
      <div
        className={facilityDropdownClasses({
          oneOrZeroFacilities: facilities.length <= 1,
          facilityDropdownShowing: showFacilityList,
        })}
      >
        <FacilityChooser
          onClick={() => {
            closeAllMenus()
            setShowFacilityList(!showFacilityList)
          }}
          onFacilityClick={() => setShowMobileSidebar(false)}
          showFacilityList={showFacilityList}
        />
      </div>
      <div className="mt-[20px] xs:mt-0">
        {showCommunity && (
          <ResidentSearch
            closeAllMenus={closeAllMenus}
            setShowDropdown={setShowSearchDropdown}
            showDropdown={showSearchDropdown}
            canCreateVitals={canCreateVitals}
          />
        )}
      </div>
      {showCommunity && <CommunitySection />}
      <UserSettings
        closeAllMenus={closeAllMenus}
        showUserSettings={showUserSettings}
        setShowUserSettings={setShowUserSettings}
        setShowPermissionsModal={setShowPermissionsModal}
        user={user}
      />
    </SidebarContentsContext.Provider>
  )
}
