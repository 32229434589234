import { Contact_ParentObjectType } from '@augusthealth/models/com/august/protos/contact'
import { faker } from '@faker-js/faker'
import { Factory } from 'fishery'
import { Gender } from '@shared/types/gender'
import { HumanName_NameUse } from '@shared/types/human_name'
import { Person, ResidentStatus } from '@shared/types/person'
import { rpContactFactory } from './contactFactory'
import { dateMessageFactory } from './dateMessageFactory'
import { humanNameFactory } from './humanNameFactory'

class PersonFactory extends Factory<Person> {
  resident() {
    return this.params({
      residentStatus: ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT,
    })
  }

  prospect() {
    return this.params({
      residentStatus: ResidentStatus.RESIDENT_STATUS_PROSPECT,
    })
  }

  discharged() {
    return this.params({
      residentStatus: ResidentStatus.RESIDENT_STATUS_DISCHARGED,
    })
  }

  closed() {
    return this.params({
      residentStatus: ResidentStatus.RESIDENT_STATUS_CLOSED,
    })
  }

  withRp() {
    const id = faker.string.uuid()
    return this.params({
      id,
      contact: [
        rpContactFactory.build({
          parent: {
            id,
            objectType: Contact_ParentObjectType.PARENT_OBJECT_TYPE_PERSON,
          },
        }),
      ],
    })
  }
}

export const personFactory = PersonFactory.define(
  ({ sequence, transientParams }): Person => {
    const { firstName, lastName } = transientParams
    return {
      id: sequence.toString(),
      orgId: faker.string.numeric(),
      facilityId: faker.string.numeric(),
      name: [
        humanNameFactory.build({
          ...(firstName && { given: [firstName] }),
          ...(lastName && { family: lastName }),
          ...(lastName &&
            firstName && { use: HumanName_NameUse.NAME_USE_USUAL }),
        }),
      ],
      gender: faker.helpers.enumValue(Gender),
      birthDate: dateMessageFactory.build(),
    }
  }
)
