import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import { MedicationOrder } from '@shared/types/medication_order'
import {
  FormattedRxTerm,
  StrengthAndForm,
} from '@app/components/Residents/Medications/MedicationSearch/helpers'
import { MedOrderFormData } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'

export default function useMedicationSearch({
  order,
}: {
  order?: MedicationOrder
}) {
  const { reset, formState, register, control, setValue, watch } =
    useFormContext<MedOrderFormData>()
  const [rxTermResponse, setRxTermResponse] = useState<
    OptionTypeBase<FormattedRxTerm>[]
  >([])
  const [strengthAndFormOptions, setStrengthAndFormOptions] = useState<
    OptionTypeBase<StrengthAndForm>[]
  >([])
  const selectedMedication = watch('displayName')

  const medicationOptions: OptionTypeBase<FormattedRxTerm>[] = [
    ...rxTermResponse,
  ]

  if (
    selectedMedication &&
    !rxTermResponse.find((re) => re.value.displayName === selectedMedication)
  ) {
    medicationOptions.push({
      label: selectedMedication,
      value: {
        displayName: selectedMedication,
        codeName: selectedMedication,
        strengthsAndForms: [],
      },
    })
  }

  useEffect(() => {
    if (order?.medicationStatement.medication?.strengthAndForm) {
      setStrengthAndFormOptions([
        {
          label:
            order.medicationStatement.medication.strengthAndForm ?? 'Unknown',
          value: {
            strengthAndForm:
              order.medicationStatement.medication.strengthAndForm,
            rxCui: order.medicationStatement.medication.code?.coding?.[0].code,
          },
        },
      ])
    }
  }, [order?.id ?? ''])

  return {
    control,
    medicationOptions,
    register,
    reset,
    setRxTermResponse,
    setStrengthAndFormOptions,
    setValue,
    rxTermResponse,
    strengthAndFormOptions,
    selectedMedication,
    formState,
  }
}
