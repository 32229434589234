import { OrgFacilitySettings } from '@augusthealth/models/com/august/protos/org_facility_settings'
import {
  SignableFormAssignment,
  SignableFormMetaData,
} from '@augusthealth/models/com/august/protos/signable_form'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { ToolPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { Organization } from '@shared/types/organization'
import { GroupPermission } from '@shared/types/permission'
import {
  findScheduledTaskForTaskDefinition,
  SettingsLevel,
  sortTaskDefinitions,
} from '@shared/utils/orgFacilitySettings'
import PersonPageTitle from '@app/components/PersonPageTitle'
import TaskDefinitionModal from '@app/pages/Tools/TaskDefinitions/TaskDefinitionModal'
import TaskCard from '../TaskCard'
import { CardLevel } from '../TaskCard/helpers'
import { SuppressedTaskCard } from '../TaskCard/SuppressedTaskCard'

type Props = {
  allAssignments: SignableFormAssignment[]
  formList: SignableFormMetaData[]
  loadAssignments: () => Promise<any>
  onTaskDefinitionChange: () => Promise<void>
  org: Organization
  orgTaskDefinitions: OrgFacilitySettings[]
  scheduledTaskSettings: OrgFacilitySettings[]
  reloadScheduledTaskSettings: () => Promise<void>
}

type TaskDefinition = null | {
  cardLevel: CardLevel
  settingsLevel: SettingsLevel
}

export default function OrgTask({
  allAssignments,
  formList,
  loadAssignments,
  onTaskDefinitionChange,
  org,
  orgTaskDefinitions,
  scheduledTaskSettings,
  reloadScheduledTaskSettings,
}: Props) {
  const { id: orgId, name } = org
  const history = useHistory()
  const [taskDefinition, setTaskDefinition] = useState<TaskDefinition>(null)

  return (
    <div>
      <PersonPageTitle title={name} subtitle>
        <AsyncIconButton
          buttonStyle="secondary-outline"
          initialIcon="fa-arrow-left"
          buttonSize="small"
          onClick={() => history.goBack()}
        >
          Back
        </AsyncIconButton>
        <ToolPermissionGate
          permissions={[GroupPermission.GROUP_PERMISSION_TOOL_SETTINGS_CREATE]}
        >
          <AsyncIconButton
            initialIcon="fa-plus"
            buttonStyle="secondary-fill"
            buttonSize="small"
            className="ml-[8px]"
            onClick={() =>
              setTaskDefinition({
                cardLevel: {
                  tag: 'Organization',
                  orgId: orgId!,
                },
                settingsLevel: SettingsLevel.ORG_LEVEL,
              })
            }
          >
            Add Org Task
          </AsyncIconButton>
          <AsyncIconButton
            data-testid="add-state-org-task"
            initialIcon="fa-plus"
            buttonStyle="secondary-fill"
            buttonSize="small"
            className="ml-[8px]"
            onClick={() =>
              setTaskDefinition({
                cardLevel: {
                  tag: 'State/Org',
                  orgId: orgId!,
                  state: '',
                },
                settingsLevel: SettingsLevel.ORG_STATE_LEVEL,
              })
            }
          >
            Add State Org Task
          </AsyncIconButton>
        </ToolPermissionGate>
      </PersonPageTitle>
      {sortTaskDefinitions(orgTaskDefinitions).map(
        (ts: OrgFacilitySettings) => {
          const task = ts.settings!.taskDefinition!

          if (!task.template) {
            return (
              <SuppressedTaskCard
                key={`org-${ts.key}`}
                taskSetting={ts}
                reloadTaskSettings={onTaskDefinitionChange}
              />
            )
          }
          const { taskType, customType } = task.template!

          return (
            <TaskCard
              cardLevel={{
                tag: 'Organization',
                orgId: orgId!,
              }}
              allAssignments={allAssignments}
              key={`${taskType}${customType || ''}-${orgId}-task`}
              formList={formList}
              name={name!}
              taskSettings={ts}
              scheduledTask={findScheduledTaskForTaskDefinition({
                scheduledTasks: scheduledTaskSettings,
                taskDefinition: ts,
                cardLevel: {
                  tag: 'Organization',
                  orgId: orgId!,
                },
              })}
              loadAssignments={loadAssignments}
              onTaskDefinitionChange={async () => {
                await onTaskDefinitionChange()
              }}
              onScheduledTaskChange={async () => {
                await reloadScheduledTaskSettings()
              }}
            />
          )
        }
      )}
      {!!taskDefinition && (
        <TaskDefinitionModal
          defaultSettingsLevel={taskDefinition.settingsLevel}
          name={name!}
          cardLevel={taskDefinition.cardLevel}
          onClose={async (updated) => {
            if (updated) {
              await onTaskDefinitionChange()
            }
            setTaskDefinition(null)
          }}
        />
      )}
    </div>
  )
}
