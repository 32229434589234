import { Immunization } from '@augusthealth/models/com/august/protos/immunization'
import {
  Task,
  TaskTemplateInfo,
} from '@augusthealth/models/com/august/protos/task'
import { Dispatch, SetStateAction } from 'react'
import { createCtx } from '@shared/contexts/createCtx'
import { Facility } from '@shared/types/facility'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import { UserAccount } from '@shared/types/user'
import { Loading } from '@shared/utils/loading'
import { SnapshotSortOrder } from '@app/components/SortByDropdown/helpers'

export interface DocumentsContextProps {
  user: UserAccount
  configuredTemplates: Loading<TaskTemplateInfo[]>
  tasks: Task[]
  refreshTasks: () => Promise<void>
  person: Person
  refreshPerson: () => Promise<void>
  snapshots: Loading<Snapshot[]>
  refreshSnapshots: () => Promise<void>
  immunizations: Loading<Immunization[]>
  refreshImmunizations: () => Promise<void>
  currentFacility: Facility
  documentFilter: string
  setDocumentFilter: Dispatch<SetStateAction<string>>
  snapshotSortOrder?: SnapshotSortOrder
  setSnapshotSortOrder: Dispatch<SetStateAction<SnapshotSortOrder>>
}

export const [useDocumentsContext, DocumentsContextProvider] =
  createCtx<DocumentsContextProps>()
