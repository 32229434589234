import {
  VITALS_INPUT_PROPS_MAP,
  VitalsType,
  VitalTypeEnumToVitalsType,
} from '@shared/utils/vitals'
import { MedOrderFormDose } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'

export const VitalCheck = ({
  dose,
  index,
}: {
  dose: MedOrderFormDose
  index: number
}) => {
  if (dose?.requiredVitals) {
    return (
      <>
        {dose.requiredVitals.map((vi) => {
          const matching = vi
            ? VITALS_INPUT_PROPS_MAP[
                VitalTypeEnumToVitalsType(vi) as VitalsType
              ]
            : null
          if (matching) {
            return (
              <div
                key={`${vi}-dose-${index}`}
                className={
                  'mb-[16px] text-[14px] font-semibold capitalize text-gray-01'
                }
              >
                <i
                  className={`${matching.iconClassName} mr-[8px] text-primary-light`}
                />
                Check {matching.label}
              </div>
            )
          }

          return null
        })}
      </>
    )
  }

  return null
}
