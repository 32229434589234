import { sortBy } from 'lodash'
import { AdmissionsInformation_AdmissionType as AT } from '@shared/types/admissions'
import { OrgFacilitySettings } from '@shared/types/org_facility_settings'
import { StateAbbreviation, StateName } from '@shared/utils/state'
// eslint-disable-next-line no-restricted-imports
import { CardLevel } from '@app/pages/Tools/PdfTemplate/AssignTemplates/TaskCard/helpers'

export enum SettingsLevel {
  GLOBAL_LEVEL = 'Global Level',
  STATE_LEVEL = 'State Level',
  ORG_LEVEL = 'Org Level',
  ORG_STATE_LEVEL = 'Org Level for State',
  FACILITY_LEVEL = 'Facility Level',
}

export const SETTINGS_LEVEL_HIERARCHY = [
  SettingsLevel.FACILITY_LEVEL,
  SettingsLevel.ORG_LEVEL,
  SettingsLevel.ORG_STATE_LEVEL,
  SettingsLevel.STATE_LEVEL,
  SettingsLevel.GLOBAL_LEVEL,
]

export const SETTINGS_LEVEL_OPTIONS = SETTINGS_LEVEL_HIERARCHY.map((value) => ({
  label: value,
  value: value,
}))

export type SettingsConfig = {
  settingsLevel?: SettingsLevel
  state?: StateAbbreviation | StateName
  orgId?: string
  facilityId?: string
}
export const admissionTypeOptions = [
  { label: 'Assisted Living', value: AT.ADMISSION_TYPE_ASSISTED_LIVING },
  { label: 'Memory Care', value: AT.ADMISSION_TYPE_MEMORY_CARE },
  {
    label: 'Independent Living',
    value: AT.ADMISSION_TYPE_INDEPENDENT_LIVING,
  },
]
export const configureSettingsId = (settingsConfig: SettingsConfig) => {
  const configIds: { orgId?: string; facilityId?: string; state?: string } = {}
  if (settingsConfig.settingsLevel === SettingsLevel.FACILITY_LEVEL) {
    configIds.orgId = settingsConfig.orgId
    configIds.facilityId = settingsConfig.facilityId
  }
  if (
    settingsConfig.settingsLevel === SettingsLevel.ORG_LEVEL ||
    settingsConfig.settingsLevel === SettingsLevel.ORG_STATE_LEVEL
  ) {
    configIds.orgId = settingsConfig.orgId
  }
  if (
    settingsConfig.settingsLevel === SettingsLevel.ORG_STATE_LEVEL ||
    settingsConfig.settingsLevel === SettingsLevel.STATE_LEVEL
  ) {
    configIds.state = settingsConfig.state
  }

  return configIds
}

export function isCompleteSettingsConfig(settingsConfig: SettingsConfig) {
  const ids = configureSettingsId(settingsConfig)
  return Object.values(ids).every((v: string | undefined) => v !== undefined)
}

/**
 * Find corresponding scheduled task in list based on taskDefinition and cardLevel
 * The IDs from the scheduled task need to match the card level
 * The taskType and customType from the scheduled task need to match the taskDefinition
 * @param cardLevel
 * @param scheduledTasks
 * @param taskDefinition
 */
export function findScheduledTaskForTaskDefinition({
  cardLevel,
  scheduledTasks,
  taskDefinition,
}: {
  cardLevel: CardLevel
  scheduledTasks: OrgFacilitySettings[]
  taskDefinition: OrgFacilitySettings
}) {
  const { settings: definitionSettings } = taskDefinition
  const { taskType: definitionTaskType, customType: definitionCustomType } =
    definitionSettings?.taskDefinition?.template || {}

  return scheduledTasks.find((schedule) => {
    const {
      orgId: scheduleOrgId,
      facilityId: scheduleFacilityId,
      state: scheduleState,
      settings: scheduleSettings,
    } = schedule
    const { scheduledTask } = scheduleSettings || {}
    if (scheduledTask) {
      const { customType: scheduleCustomType, parameters } = scheduledTask
      const { tasksToCreate } = parameters || {}
      const scheduleTaskType = tasksToCreate?.length
        ? tasksToCreate[0]
        : undefined
      const {
        facilityId: cardFacilityId,
        orgId: cardOrgId,
        state: cardState,
      } = cardLevel
      const sameLevel =
        cardFacilityId === scheduleFacilityId &&
        cardOrgId === scheduleOrgId &&
        cardState === scheduleState
      const sameType =
        definitionTaskType === scheduleTaskType &&
        definitionCustomType === scheduleCustomType

      return sameLevel && sameType
    }

    return false
  })
}

function getTaskDefinitionDisplayName(s: OrgFacilitySettings) {
  return s.settings?.taskDefinition?.template?.displayName
}

export function sortTaskDefinitions(settings: OrgFacilitySettings[]) {
  return sortBy(settings, getTaskDefinitionDisplayName)
}
