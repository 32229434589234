import { useState } from 'react'
import Lightbox, {
  LightboxSidebarContent,
  LightboxSidebarFooter,
} from '@shared/components/AnimatedPopup/Lightbox/Lightbox'
import { ToolPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { OptionTypeBase } from '@shared/components/StyledSelect'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { GroupPermission } from '@shared/types/permission'
import { Person } from '@shared/types/person'
import {
  SignableForm,
  SignableFormWithAssignments,
} from '@shared/types/signable_form'
import { OrgFacilityPersonSelects } from '@app/pages/Tools/SharedSelects/OrgFacilityPersonSelects'
import LightboxTitleHeader from './LightboxTitleHeader'
import Preview from './Preview'
import PublishButton from './PublishButton'

type PreviewLightboxProps = SignableForm & {
  onClose: () => void
  reload: (updatedForm: SignableFormWithAssignments) => void
}

export default function PreviewLightbox(props: PreviewLightboxProps) {
  const [selectedOrganization, setSelectedOrganization] =
    useState<OptionTypeBase<Organization> | null>(null)
  const [selectedFacility, setSelectedFacility] =
    useState<OptionTypeBase<Facility> | null>(null)
  const [selectedPerson, setSelectedPerson] =
    useState<OptionTypeBase<Person> | null>(null)
  const { id, onClose } = props

  return (
    <Lightbox
      mainContent={
        <Preview
          id={id}
          orgId={selectedOrganization?.value?.id}
          facilityId={selectedFacility?.value?.id}
          personId={selectedPerson?.value?.id}
        />
      }
      sidebarContent={
        <>
          <LightboxSidebarContent>
            <LightboxTitleHeader {...props} />
            <OrgFacilityPersonSelects
              onOrgSelect={(e) => {
                setSelectedOrganization(e)
                setSelectedFacility(null)
                setSelectedPerson(null)
              }}
              onFacilitySelect={(e) => {
                setSelectedFacility(e)
                setSelectedPerson(null)
              }}
              onPersonSelect={(e) => {
                setSelectedPerson(e)
              }}
              prepopulateWithDefaults={true}
              selectedOrganization={selectedOrganization}
              selectedFacility={selectedFacility}
              selectedPerson={selectedPerson}
              personSelectProps={{
                isClearable: false,
              }}
            />
          </LightboxSidebarContent>
          <LightboxSidebarFooter>
            <ToolPermissionGate
              permissions={[GroupPermission.GROUP_PERMISSION_TOOL_FORM_PUBLISH]}
            >
              <PublishButton {...props} />
            </ToolPermissionGate>
          </LightboxSidebarFooter>
        </>
      }
      onClose={onClose}
    />
  )
}
