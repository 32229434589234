import { DosageType } from '@augusthealth/models/com/august/protos/medication_statement'
import { UnitOfTime } from '@augusthealth/models/com/august/protos/timing'
import { Controller, useFormContext } from 'react-hook-form'
import { BadgeInput } from '@shared/components/BadgeInputs/BadgeInput'
import { Label } from '@shared/components/Labels'
import StyledSelect, { OptionTypeBase } from '@shared/components/StyledSelect'
import { ErrorDropdownStyleConfig } from '@shared/components/StyledSelect/utils'
import { EVERY_BLANK_PERIOD_UNIT_OPTIONS } from '@shared/utils/medicationStatement'
import { MedOrderFormData } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'
import styles from '../styles.module.css'
import { validatePositiveNumber } from '../helpers'
import { RoutineForm } from '../RoutineSchedule/RoutineForm'
import { ScheduleFooter } from '../ScheduleFooter/ScheduleFooter'
import {
  isIndefiniteEndTaperDuration,
  updateTaperDoseDurations,
} from './helpers'

const TAPER_EVERY_BLANK_PERIOD_OPTIONS = EVERY_BLANK_PERIOD_UNIT_OPTIONS.filter(
  (opt) => opt.value !== UnitOfTime.UNIT_OF_TIME_HOUR
)
type Props = {
  readOnly: boolean
  doseIndex: number
  numberOfDoses: number
  diySchedule?: boolean
}
export const TaperForm = ({
  readOnly,
  doseIndex,
  numberOfDoses,
  diySchedule = false,
}: Props) => {
  const methods = useFormContext<MedOrderFormData>()
  const { setValue, register, formState, control } = methods

  const startDateName: `doses.${number}.boundsPeriod.startDate` = `doses.${doseIndex}.boundsPeriod.startDate`
  const endDateName: `doses.${number}.boundsPeriod.endDate` = `doses.${doseIndex}.boundsPeriod.endDate`
  const noteName: `doses.${number}.note` = `doses.${doseIndex}.note`
  const requiredDocumentationName: `doses.${number}.requiredInjectionOrApplicationSitePrompt` = `doses.${doseIndex}.requiredInjectionOrApplicationSitePrompt`
  const requiredDocumentationError =
    formState.errors.doses?.[doseIndex]
      ?.requiredInjectionOrApplicationSitePrompt ?? null

  const hasOnlyOneDose = numberOfDoses === 1
  const isFinalDose = numberOfDoses - 1 === doseIndex

  const disableDurationInputs = !diySchedule || readOnly
  return (
    <>
      <RoutineForm doseIndex={doseIndex} readOnly={readOnly} />
      <div className={styles.scheduleInputRow}>
        <BadgeInput
          badgeLabel={'Duration'}
          type={'number'}
          min={1}
          {...register(`doses.${doseIndex}.duration`, {
            required: hasOnlyOneDose || !isFinalDose,
            valueAsNumber: true,
            validate: (value?: number) => {
              if (
                !hasOnlyOneDose &&
                isFinalDose &&
                isIndefiniteEndTaperDuration(value)
              ) {
                return true
              }

              return validatePositiveNumber(value)
            },
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
              const newVal = parseInt(e.currentTarget.value)
              setValue(`doses.${doseIndex}.duration`, newVal)
            },
            onBlur: () => {
              updateTaperDoseDurations(methods)
            },
          })}
          placeholder={'#'}
          showErrorBorder={!!formState.errors.doses?.[doseIndex]?.duration}
          disabled={disableDurationInputs}
        />
        <Controller
          control={control}
          name={`doses.${doseIndex}.durationUnit`}
          rules={{
            required: hasOnlyOneDose || !isFinalDose,
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <Label
                  visuallyHidden
                  htmlFor={`doses.${doseIndex}.durationUnit`}
                  id={`label-doses.${doseIndex}.durationUnit`}
                >
                  Duration Unit
                </Label>
                <StyledSelect
                  aria-labelledby={`label-doses.${doseIndex}.durationUnit`}
                  inputId={`doses.${doseIndex}.durationUnit`}
                  options={TAPER_EVERY_BLANK_PERIOD_OPTIONS}
                  placeholder="Select..."
                  onChange={(e: OptionTypeBase) => {
                    onChange(e.value)
                    updateTaperDoseDurations(methods)
                  }}
                  value={
                    TAPER_EVERY_BLANK_PERIOD_OPTIONS.find(
                      (opt) => opt.value === value
                    ) ?? null
                  }
                  isDisabled={disableDurationInputs}
                  styles={ErrorDropdownStyleConfig({ error: !!error })}
                />
              </>
            )
          }}
        />
      </div>
      <ScheduleFooter
        readOnly={readOnly}
        mode={{
          tag: `partial-${DosageType.DOSAGE_TYPE_TAPER}`,
          startDateName,
          endDateName,
          noteName,
          requiredDocumentationName,
          requiredDocumentationError,
        }}
      />
    </>
  )
}
