import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { createInvoices } from '@shared/api/billing'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import GlobalContext from '@shared/contexts/GlobalContext'
import {
  DraftStatementsRequest,
  ResidentListEntry,
} from '@shared/types/billing'
import { canDraftStatement } from '@shared/utils/billing'
import ConfirmModal from '@app/components/ConfirmModal'

type Props = {
  closeFn: () => void
  doneFn: () => Promise<void>
  facilityId: string
  orgId: string
  residents: ResidentListEntry[]
}

export default function DraftBulkStatementsModal(props: Props) {
  const { orgId, facilityId, doneFn, closeFn } = props
  const { setError } = useContext(GlobalContext)
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, isLoading },
  } = useForm<DraftStatementsRequest>()
  const people = props.residents
    .filter(canDraftStatement)
    .map((r) => r.personId)

  async function onSubmit(formData: DraftStatementsRequest) {
    try {
      await createInvoices({
        orgId,
        facilityId,
        data: {
          people,
          notes: formData.notes,
        },
      })
      await doneFn()
      closeFn()
    } catch (e) {
      setError(e)
    }
  }

  return (
    <ConfirmModal
      title="Draft statements for all residents?"
      confirmButtonConfig={{
        onClick: handleSubmit(onSubmit),
        isLoading,
        buttonStyle: 'primary-fill',
        type: 'submit',
        children: 'Create Statements',
      }}
      denyButtonConfig={{
        onClick: closeFn,
        isLoading,
        disabled: isSubmitting,
      }}
      content={
        <form onSubmit={handleSubmit(onSubmit)}>
          <LabelAboveInput uppercase={false} htmlFor="notes">
            Notes
          </LabelAboveInput>
          <BasicTextarea {...register('notes')} />
        </form>
      }
    />
  )
}
