import { compact } from 'lodash'
import Card from '@shared/components/Card'
import { useUserContext } from '@shared/contexts/UserContext'
import { tw } from '@shared/utils/tailwind'
import { isDirector as getIsDirector } from '@shared/utils/user'
import RouterTabs from '@app/components/RouterTabs'
import PageTitle from '@app/pages/Organizations/sharedUIs/PageTitle'
import {
  ORGANIZATIONS_SETTINGS_COMMUNITIES_PATH,
  ORGANIZATIONS_SETTINGS_GENERAL_PATH,
  ORGANIZATIONS_SETTINGS_USERS_PATH,
} from './type'

export default function OrgTitleTabs({
  activeTab,
  orgId,
}: {
  activeTab: string
  orgId: string
}) {
  const { user } = useUserContext()
  const isDirector = getIsDirector({ user, orgId })
  const tabList = compact([
    !isDirector && {
      label: 'General',
      url: ORGANIZATIONS_SETTINGS_GENERAL_PATH.replace(':orgId', orgId),
    },
    {
      label: 'Communities',
      url: ORGANIZATIONS_SETTINGS_COMMUNITIES_PATH.replace(':orgId', orgId),
    },
    {
      label: 'Users',
      url: ORGANIZATIONS_SETTINGS_USERS_PATH.replace(':orgId', orgId),
    },
  ])

  return (
    <section className={tw`mb-[24px] ml-[12px] mr-[12px] mt-[32px]`}>
      <PageTitle>Org Settings</PageTitle>
      <Card className={tw`m-0 p-0`}>
        <RouterTabs
          className={tw`rounded-t-[8px]`}
          activeTab={activeTab}
          tabList={tabList}
        />
      </Card>
    </section>
  )
}
