import { useContext, useEffect, useState } from 'react'
import {
  createBillingItem,
  getFacilityBillingItemsWithParentCategories,
  getOrgBillingItems,
  updateBillingItem,
} from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import {
  BillingItem,
  BillingItemData,
  BillingItemsWithParentCategory,
} from '@shared/types/billing'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

export default function useBillingItems({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId?: string
}) {
  const { setError } = useContext(GlobalContext)
  const [billingItems, setBillingItems] =
    useState<Loading<BillingItem[]>>(LOADING)
  const [
    billingItemsWithParentCategories,
    setBillingItemsWithParentCategories,
  ] = useState<Loading<BillingItemsWithParentCategory[]>>(LOADING)

  async function reloadBillingItems() {
    setBillingItems(LOADING)
    try {
      if (facilityId) {
        const res = await getFacilityBillingItemsWithParentCategories({
          orgId: orgId!,
          facilityId: facilityId!,
        })
        const newBillingItems = res
          .map((obj) => {
            const category = obj.category
            const items = obj.items
            return items.map((item) => {
              return {
                ...item,
                category,
              }
            })
          })
          .flat()
        const newBillingItemsWithParentCategories = res

        setBillingItems(loaded(newBillingItems))
        setBillingItemsWithParentCategories(
          loaded(newBillingItemsWithParentCategories)
        )
      } else {
        const newBillingItems = await getOrgBillingItems({ orgId })

        setBillingItems(loaded(newBillingItems))
      }
    } catch (e) {
      setError(e)
      setBillingItems(loaded([]))
    }
  }

  async function upsertBillingItemData({
    billingItemData,
    itemId,
  }: {
    billingItemData: BillingItemData
    itemId: string
  }) {
    const coreProps = {
      orgId,
      item: billingItemData,
    }
    const isEditing = !!itemId

    const fn = isEditing
      ? () =>
          updateBillingItem({
            itemId,
            ...coreProps,
          })
      : () =>
          createBillingItem({
            ...coreProps,
          })

    try {
      await fn()
    } catch (error) {
      setError(error)
    }
  }

  useEffect(() => {
    void reloadBillingItems()
  }, [])

  return {
    billingItems,
    billingItemsWithParentCategories,
    reloadBillingItems,
    upsertBillingItemData,
  }
}
