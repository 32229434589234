import { BillingCharge } from '@shared/types/billing'
import { BillingModalType } from '@shared/utils/billing'
import { Loading } from '@shared/utils/loading'
import { Header } from '@app/components/Table/TableHeader'
import WithTable from '@app/pages/Facilities/Billing/Components/WithTable'
import { ChargesColumnName } from '../../helpers'
import ChargeRow from './ChargeRow'
import { getSearchedBillingCharges, getSortedCharges } from './helpers'

type Props = {
  billingCharges: Loading<BillingCharge[]>
  openChargeModal: (charge: BillingCharge, modalType: BillingModalType) => void
}

const headers: Header[] = [
  {
    label: ChargesColumnName.DESCRIPTION,
    className: 'w-auto',
  },
  {
    label: ChargesColumnName.AMOUNT,
    className: 'w-auto',
  },
  {
    label: ChargesColumnName.START_DATE,
    className: 'w-auto',
  },
  {
    label: ChargesColumnName.END_DATE,
    className: 'w-auto',
  },
  {
    label: '',
    className: 'w-[272px]',
  }, // Column for Buttons, no label
]

export default function RecurringChargesTable(props: Props) {
  const { billingCharges, openChargeModal } = props

  return (
    <WithTable
      title="Manage Recurring Charges"
      rows={billingCharges}
      headers={headers}
      defaultSelectedColumn={ChargesColumnName.DESCRIPTION}
      sortingFn={getSortedCharges}
      searchBoxProps={{
        searchPlaceholder: 'Search charges...',
        searchFn: getSearchedBillingCharges,
      }}
    >
      {(sortedRows: BillingCharge[]) => (
        <>
          {sortedRows.map((charge) => {
            return (
              <ChargeRow
                key={`charge-row-${charge.meta.id}`}
                billingCharge={charge}
                openChargeModal={openChargeModal}
              />
            )
          })}
        </>
      )}
    </WithTable>
  )
}
