import { useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { fetchPerson } from '@shared/api/person'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'
import {
  calculateLevel,
  calculateTotalScore,
} from '@app/components/Prospects/Forms/LevelOfCare'
import ReadOnlyLevelOfCare from '@app/components/Prospects/Forms/LevelOfCare/ReadOnly'
import DocumentPageHolder from './DocumentPageHolder'

interface Props
  extends RouteComponentProps<{
    orgId: string
    facilityId: string
    personId: string
    documentId: string
  }> {
  snapshot: Snapshot
}

export function LevelOfCarePage({ match, snapshot }: Props) {
  const { setError } = useContext(GlobalContext)
  const [person, setPerson] = useState<Person>()

  const { orgId, facilityId, personId } = match.params

  useEffect(() => {
    fetchPerson({
      orgId,
      facilityId,
      personId,
    })
      .then(setPerson)
      .catch(setError)
  }, [match])

  if (person === undefined) {
    return null
  }

  const levelOfCareAssessment = snapshot.data?.levelOfCareAssessment || {}

  const score = calculateTotalScore({ data: levelOfCareAssessment })
  const level = calculateLevel({
    totalScore: score,
  })

  return (
    <DocumentPageHolder
      title="Level of Care"
      person={person}
      snapshot={snapshot}
      score={score}
      level={level}
    >
      <ReadOnlyLevelOfCare levelOfCare={levelOfCareAssessment} />
    </DocumentPageHolder>
  )
}
