import {
  getSavePaymentMethodParameters,
  PaymentMethodConfigParameters,
} from 'app/api/payments'
import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

/**
    TODO:
    1. Can this accept `Person` and `Contact` objects instead of their IDs?
    2. Can we name this more generically, like `usePaymentMethodConfig`?
*/
export default function useRainforestPaymentMethodConfig({
  orgId,
  facilityId,
  personId,
  contactId,
}: {
  orgId: string
  facilityId: string
  personId: string
  contactId: string
}) {
  const { setError } = useContext(GlobalContext)
  const [paymentMethodConfig, setPaymentMethodConfig] =
    useState<Loading<PaymentMethodConfigParameters>>(LOADING)

  async function reloadPaymentMethodConfig() {
    setPaymentMethodConfig(LOADING)
    try {
      const paymentMethodConfig = await getSavePaymentMethodParameters({
        orgId,
        facilityId,
        personId,
        contactId,
      })
      setPaymentMethodConfig(loaded(paymentMethodConfig))
    } catch (e) {
      setError(e)
    }
  }

  useEffect(() => {
    void reloadPaymentMethodConfig()
  }, [orgId, facilityId, personId])

  return {
    paymentMethodConfig,
    reloadPaymentMethodConfig,
  }
}
