// eslint-disable-next-line no-restricted-imports
import { PharmacyResidentIdentifier } from '@augusthealth/models/com/august/protos/pharmacy_message'
import { getMedicationOrdersUrl } from '@shared/api/medication_orders'
import { fetchJson } from '@shared/api/request'
import { apiUrl, baseUrl, queueJobUrl } from '@shared/api/urls'
import { apiPersonUrl } from '@shared/legacy_routes'
import {
  PharmacyMessagePayload,
  PharmacyMessageStatus,
  ProcessPharmacyMessagePayload,
} from '@shared/types/api/pharmacy_message'
import { Person } from '@shared/types/person'
import { PickPartial } from '@shared/types/utilities'

export const getSinglePharmacyMessageUrl = (
  person: Required<PickPartial<Person, 'id' | 'orgId' | 'facilityId'>>,
  medicationId: string
) =>
  `${apiPersonUrl(
    person.orgId,
    person.facilityId,
    person.id
  )}/pharmacyMessages/${medicationId}`

export const approvePharmacyMessageUrl = (
  person: Required<PickPartial<Person, 'id' | 'orgId' | 'facilityId'>>,
  medicationId: string
) => `${getSinglePharmacyMessageUrl(person, medicationId)}/approve`

export const approvePharmacyDiscontinueUrl = (
  person: Required<PickPartial<Person, 'id' | 'orgId' | 'facilityId'>>,
  medicationId: string
) => `${getSinglePharmacyMessageUrl(person, medicationId)}/approveDiscontinue`

export const rejectPharmacyMessageUrl = (
  person: Required<PickPartial<Person, 'id' | 'orgId' | 'facilityId'>>,
  medicationId: string
) => `${getSinglePharmacyMessageUrl(person, medicationId)}/reject`

export const getMedicationOrderDiffUrl = ({
  orgId,
  facilityId,
  personId,
  orderGroupId,
  pharmacyMessageId,
}: {
  orgId: string
  facilityId: string
  personId: string
  orderGroupId: string
  pharmacyMessageId: string
}) =>
  `${getMedicationOrdersUrl({
    id: personId,
    orgId,
    facilityId,
  })}/orderGroups/${orderGroupId}/pharmacyMessages/${pharmacyMessageId}`

export type GetPharmacyParameters = {
  orgId: string
  facilityId: string
  personId?: string
}

export type DeletePharmacyAssignmentParameters = {
  augustIdentifier: GetPharmacyParameters
  pharmacyIdentifier: PharmacyResidentIdentifier
}

export const pharmacyMessagesForOrgFacilityUrl = (
  props: GetPharmacyParameters
) => {
  const { orgId, facilityId, personId } = props
  return apiUrl(
    baseUrl,
    `/organizations/:orgId/facilities/:facilityId/pharmacyMessages`,
    {
      orgId,
      facilityId,
      personId,
      messageStatuses: [
        PharmacyMessageStatus.Received,
        PharmacyMessageStatus.Rejected,
      ],
    }
  )
}

export const deletePharmacyAssignmentForOrgFacilityPersonUrl = (
  props: DeletePharmacyAssignmentParameters
) => {
  const { pharmacyIdentifier, augustIdentifier } = props
  const { orgId, facilityId, personId } = augustIdentifier
  const { partner, externalFacilityIdentifier, externalResidentIdentifier } =
    pharmacyIdentifier
  return apiUrl(
    baseUrl,
    `/organizations/:orgId/facilities/:facilityId/people/:personId/pharmacyIntegration/partners/:partner/facilities/:externalFacilityIdentifier/people/:externalResidentIdentifier`,
    {
      orgId,
      facilityId,
      personId,
      partner,
      externalFacilityIdentifier,
      externalResidentIdentifier,
    }
  )
}

export const pharmacyAssignmentsForOrgFacilityPersonUrl = (
  props: GetPharmacyParameters
) => {
  const { orgId, facilityId, personId } = props
  return apiUrl(
    baseUrl,
    `/organizations/:orgId/facilities/:facilityId/people/:personId/pharmacyIntegration/assignments`,
    {
      orgId,
      facilityId,
      personId,
    }
  )
}

export async function getPharmacyAssignments(
  props: GetPharmacyParameters
): Promise<PharmacyResidentIdentifier[]> {
  return fetchJson<PharmacyResidentIdentifier[]>(
    pharmacyAssignmentsForOrgFacilityPersonUrl(props)
    // Remove casts once fetchJson is fully typed
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  ).then((response) => response.data) as Promise<PharmacyResidentIdentifier[]>
}

export async function deletePharmacyAssignment(
  props: DeletePharmacyAssignmentParameters
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  return fetchJson(deletePharmacyAssignmentForOrgFacilityPersonUrl(props), {
    method: 'DELETE',
  })
}

export async function getPharmacyMessages(
  props: GetPharmacyParameters
): Promise<PharmacyMessagePayload[]> {
  return fetchJson<PharmacyMessagePayload[]>(
    pharmacyMessagesForOrgFacilityUrl(props)
    // Remove casts once fetchJson is fully typed
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  ).then((response) => response.data) as Promise<PharmacyMessagePayload[]>
}

export function processPharmacyMessage(
  pharmacyMessage: ProcessPharmacyMessagePayload
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  return fetchJson(queueJobUrl(), {
    method: 'POST',
    body: JSON.stringify(pharmacyMessage),
  })
}
