import {
  AppraisalSettings_AnswerGroup,
  AppraisalSettings_CustomDetails,
} from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { DetailModalForm } from 'app/pages/Tools/AssessmentConfiguration/DetailGroups/AddEditDetailModal/AddEditDetailModal'
import { cloneDeep } from 'lodash'
import { UseFormGetValues } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import {
  AssessmentCategory,
  ConfigurationUpdate,
  DetailModalMode,
  UpdatedDetailMap,
} from '@shared/types/assessment_configuration'
import { AugustFieldType } from '@shared/types/custom_field'
import { categoryKeyToPropertyMap } from '@shared/utils/assessmentConfiguration'
import {
  DetailWithAugustField,
  DetailWithCheckbox,
  DetailWithDropdown,
  DetailWithDropdownOption,
  DetailWithTextarea,
  DetailWithTextbox,
  FrontendDetail,
  makeCheckboxSections,
} from '@app/pages/Tasks/ResidentAssessment/AssessmentPage/CheckboxSections/utils'
import {
  constructAugustFieldDetail,
  isSupportedAugustField,
} from '@app/pages/Tools/AssessmentConfiguration/DetailGroups/augustFieldHelpers'
import {
  ADDED,
  CUSTOM_DETAIL,
  CUSTOM_DROPDOWN_DETAIL,
  CUSTOM_TEXTAREA_DETAIL,
  CUSTOM_TEXTBOX_DETAIL,
  getHighestDisplayOrderForCategory,
  REMOVED,
} from '../helpers'

/**
 * Creates a map of the updated details for each category
 * @param updated the updates detail map, matches what you are currently seeing in the UI
 * @param original the original details pulled when starting the assessment
 * @param selectedCategory the selected detail category (bathing, grooming, etc)
 */
export const getListOfUpdatedDetails = (
  updated: Map<string, FrontendDetail[]>,
  original: Map<string, FrontendDetail[]>,
  selectedCategory: AssessmentCategory
): UpdatedDetailMap => {
  const detailProperty = categoryKeyToPropertyMap[selectedCategory.categoryKey!]
  const updates: UpdatedDetailMap = {}

  updated.forEach((updatedGroup, key) => {
    const groupB = original.get(key) ?? []

    if (!updates[key]) {
      updates[key] = []
    }

    if (updatedGroup.length === 0 && groupB.length > 0) {
      // detail group was deleted
      updates[key].push({
        type: 'detailGroup',
        valueChanged: 'removed detail group',
        group: groupB[0].groupName,
      })
      return
    }

    if (groupB.length === 0) {
      // detail group was added
      const addedGroupUpdates = _handleNewDetailGroupUpdates({
        group: key,
        updatedGroup,
      })

      updates[key].push(...addedGroupUpdates)
    } else {
      updatedGroup.forEach((updatedDetail) => {
        const originalDetail = groupB.find(
          (d) => d.tempId === updatedDetail.tempId
        )

        if (updatedDetail.tag === 'DetailWithDropdown') {
          const dropdownUpdates = _handleExistingDetailDropdownUpdates({
            updatedDetail,
            originalDetail: originalDetail as DetailWithDropdown,
            detailProperty,
          })

          updates[key].push(...dropdownUpdates)
        } else if (updatedDetail.tag === 'DetailWithAugustField') {
          const augustFieldUpdates = handleAddOrRemoveAugustField({
            updatedDetail,
          })
          updates[key].push(...augustFieldUpdates)
        } else {
          const checkboxOrTextboxUpdates =
            _handleExistingCheckboxOrTextboxUpdates({
              updatedDetail,
              originalDetail: originalDetail as
                | DetailWithCheckbox
                | DetailWithTextbox
                | DetailWithTextarea,
              detailProperty,
            })

          updates[key].push(...checkboxOrTextboxUpdates)
        }
      })
    }
  })

  return updates
}

const _handleNewDetailGroupUpdates = ({
  group,
  updatedGroup,
}: {
  group: string
  updatedGroup: FrontendDetail[]
}): ConfigurationUpdate[] => {
  const updates: ConfigurationUpdate[] = []

  updates.push({
    type: 'detailGroup',
    valueChanged: 'new detail group',
    group,
  })

  updatedGroup.forEach((updatedDetail) => {
    if (
      updatedDetail.tag === 'DetailWithCheckbox' ||
      updatedDetail.tag === 'DetailWithTextbox' ||
      updatedDetail.tag === 'DetailWithTextarea'
    ) {
      updates.push({
        type: 'newDetail',
        valueChanged: 'added detail',
        description: updatedDetail.value.description!,
        helpText: updatedDetail.value.helpText,
        score: updatedDetail.value.score!,
        group,
        isRequired: updatedDetail.value.isRequired,
      })
    } else if (updatedDetail.tag === 'DetailWithDropdown') {
      updates.push({
        type: 'newDetail',
        valueChanged: 'added detail',
        description: updatedDetail.value.detailMetadata.description!,
        group: updatedDetail.groupName!,
      })

      updatedDetail.value.options.forEach((updatedOption) => {
        if (updatedOption.tempId !== REMOVED) {
          const updated: ConfigurationUpdate = {
            type: 'newDetail',
            valueChanged: 'added detail',
            description: updatedOption.description!,
            answerGroup: updatedDetail.value.detailMetadata.description!,
            score: updatedOption.score,
            group: updatedDetail.groupName!,
          }

          updates.push(updated)
        }
      })
    }
  })

  return updates
}

const _handleExistingDetailDropdownUpdates = ({
  updatedDetail,
  originalDetail,
  detailProperty,
}: {
  updatedDetail: DetailWithDropdown
  originalDetail: DetailWithDropdown
  detailProperty: string
}): ConfigurationUpdate[] => {
  const updates: ConfigurationUpdate[] = []
  if (updatedDetail.tempId === REMOVED) {
    updates.push({
      type: 'removeDetail',
      valueChanged: 'removed detail',
      description: updatedDetail.value.detailMetadata.description!,
      group: updatedDetail.groupName!,
    })
  } else if (updatedDetail.tempId === ADDED) {
    updates.push({
      type: 'newDetail',
      valueChanged: 'added detail',
      description: updatedDetail.value.detailMetadata.description!,
      group: updatedDetail.groupName!,
    })

    updatedDetail.value.options.forEach((updatedOption) => {
      if (updatedOption.tempId !== REMOVED) {
        const updated: ConfigurationUpdate = {
          type: 'newDetail',
          valueChanged: 'added detail',
          description: updatedOption.description!,
          answerGroup: updatedDetail.value.detailMetadata.description!,
          score: updatedOption.score,
          group: updatedDetail.groupName!,
        }

        updates.push(updated)
      }
    })
  } else {
    const originalDetailMetadata: AppraisalSettings_AnswerGroup =
      originalDetail!.value['detailMetadata']

    const group = updatedDetail!.groupName

    if (
      updatedDetail.value.detailMetadata.description !==
      originalDetailMetadata.description
    ) {
      updates.push({
        type: 'detail',
        valueChanged: 'description',
        updated: updatedDetail.value.detailMetadata.description!,
        original: originalDetailMetadata.description!,
        group,
      })
    }

    updatedDetail.value.options.forEach((updatedOption) => {
      const answerGroup = updatedDetail.value.detailMetadata.description!
      const descriptor = updatedOption.description!

      if (updatedOption.tempId === REMOVED) {
        updates.push({
          type: 'removeDetail',
          valueChanged: 'removed option',
          description: descriptor,
          answerGroup,
          group,
        })
      } else if (updatedOption.tempId === ADDED) {
        const updated: ConfigurationUpdate = {
          type: 'newDetail',
          valueChanged: 'added detail',
          description: descriptor,
          answerGroup,
          score: updatedOption.score,
          group,
        }

        updates.push(updated)
      } else {
        const originalOption = originalDetail!.value['options'].find(
          (op) => op.tempId === updatedOption.tempId
        )

        if (originalOption) {
          if (updatedOption.description !== originalOption.description) {
            updates.push({
              type: 'detail',
              valueChanged: 'description',
              updated: updatedOption.description!,
              original: originalOption.description!,
              answerGroup,
              group,
            })
          }

          if (updatedOption.score !== originalOption.score) {
            updates.push({
              type: 'detail',
              valueChanged: 'score',
              updated: updatedOption.score!,
              original: originalOption.score!,
              descriptor,
              answerGroup,
              group,
            })
          }

          if (
            updatedOption[detailProperty] !== originalOption[detailProperty]
          ) {
            updates.push({
              type: 'detail',
              valueChanged: 'enum',
              updated: updatedOption[detailProperty] ?? CUSTOM_DETAIL,
              original: originalOption[detailProperty] ?? CUSTOM_DETAIL,
              descriptor,
              answerGroup,
              group,
            })
          }
        }
      }
    })
  }

  return updates
}

const handleAddOrRemoveAugustField = ({
  updatedDetail,
}: {
  updatedDetail: DetailWithAugustField
}): ConfigurationUpdate[] => {
  if (updatedDetail.tempId === REMOVED) {
    return [
      {
        type: 'removeDetail',
        valueChanged: 'removed detail',
        description: updatedDetail.value.augustFieldType ?? 'August Field',
        group: updatedDetail.groupName,
      },
    ]
  } else if (updatedDetail.tempId === ADDED) {
    return [
      {
        type: 'newDetail',
        valueChanged: 'added detail',
        description: updatedDetail.value.augustFieldType ?? 'August Field',
        group: updatedDetail.groupName,
      },
    ]
  }

  return []
}

const _handleExistingCheckboxOrTextboxUpdates = ({
  updatedDetail,
  originalDetail,
  detailProperty,
}: {
  updatedDetail: DetailWithCheckbox | DetailWithTextbox | DetailWithTextarea
  originalDetail: DetailWithCheckbox | DetailWithTextbox | DetailWithTextarea
  detailProperty: string
}): ConfigurationUpdate[] => {
  const updates: ConfigurationUpdate[] = []

  const group = updatedDetail.value.group!
  const descriptor = updatedDetail.value.description!

  if (updatedDetail.tempId === REMOVED) {
    updates.push({
      type: 'removeDetail',
      valueChanged: 'removed detail',
      description: updatedDetail.value.description!,
      group: updatedDetail.groupName!,
    })
  } else if (updatedDetail.tempId === ADDED) {
    updates.push({
      type: 'newDetail',
      valueChanged: 'added detail',
      description: updatedDetail.value.description!,
      helpText: updatedDetail.value.helpText,
      score: updatedDetail.value.score!,
      group,
      isRequired: updatedDetail.value.isRequired,
    })
  } else {
    const originalValue = originalDetail!.value

    const originalDescription = originalValue['description']
    if (updatedDetail.value.description !== originalDescription) {
      updates.push({
        type: 'detail',
        valueChanged: 'description',
        updated: updatedDetail.value.description!,
        original: originalDescription ?? '',
        group,
      })
    }

    const originalScore = originalValue['score']
    if (updatedDetail.value.score !== originalScore) {
      updates.push({
        type: 'detail',
        valueChanged: 'score',
        updated: updatedDetail.value.score!,
        original: originalScore ?? 'N/A',
        descriptor,
        group,
      })
    }

    const originalHelpText = originalValue['helpText']
    if (updatedDetail.value.helpText !== originalHelpText) {
      updates.push({
        type: 'detail',
        valueChanged: 'helpText',
        updated: updatedDetail.value.helpText!,
        original: originalHelpText ?? '',
        group,
        descriptor,
      })
    }

    const originalRequired = originalValue['isRequired']
    if (updatedDetail.value.isRequired !== originalRequired) {
      updates.push({
        type: 'detail',
        valueChanged: 'isRequired',
        updated: updatedDetail.value.isRequired ?? 'N/A',
        original: originalRequired ?? 'N/A',
        group,
        descriptor,
      })
    }

    if (updatedDetail.value[detailProperty] !== originalValue[detailProperty]) {
      updates.push({
        type: 'detail',
        valueChanged: 'enum',
        updated: updatedDetail.value[detailProperty] ?? CUSTOM_DETAIL,
        original: originalValue[detailProperty] ?? CUSTOM_DETAIL,
        descriptor,
        group,
      })
    }
  }

  return updates
}

/**
 * Attaches a tempId to each detail and option to track changes
 * @param category The category to attach tempIds to
 */
export const buildAssessmentDetails = (category: AssessmentCategory) => {
  const checkboxSections = makeCheckboxSections(category)
  Array.from(checkboxSections.keys()).forEach((key, sectionIndex) => {
    const value = checkboxSections.get(key)!
    const updatedWithIds = value.map((detail, detailIndex) => {
      if (detail.tag === 'DetailWithDropdown') {
        return {
          ...detail,
          value: {
            ...detail.value,
            options: detail.value.options.map((opt, optIndex) => ({
              ...opt,
              tempId:
                opt.tempId ?? `${sectionIndex}-${detailIndex}-${optIndex}`,
              originalTempId:
                opt.originalTempId ??
                `${sectionIndex}-${detailIndex}-${optIndex}`,
            })),
          },
          tempId: detail.tempId ?? `${sectionIndex}-${detailIndex}`,
        }
      } else if (detail.tag === 'DetailWithCheckbox') {
        return {
          ...detail,
          tempId: detail.tempId ?? `${sectionIndex}-${detailIndex}`,
        }
      } else if (
        detail.tag === 'DetailWithTextbox' ||
        detail.tag === 'DetailWithTextarea'
      ) {
        return {
          ...detail,
          tempId: detail.tempId ?? `${sectionIndex}-${detailIndex}`,
        }
      }

      return detail
    })

    checkboxSections.set(key, updatedWithIds)
  })

  return checkboxSections
}

/**
 * Updates the detail map when adding or editing a detail from the modal
 * @param mode
 * @param getValues
 * @param detailGroupProperty
 * @param detailName
 */
export const updateDetailFromAddEditFormValues = ({
  mode,
  getValues,
  detailGroupProperty,
  detailName,
}: {
  mode: DetailModalMode
  getValues: UseFormGetValues<DetailModalForm>
  detailGroupProperty: string
  detailName: string
}) => {
  if (mode.tag === 'add') {
    const detailEnum = getValues('enum')
    const description = getValues('description')

    if (detailEnum === CUSTOM_TEXTBOX_DETAIL) {
      const newDetail: Partial<FrontendDetail> = {
        tag: 'DetailWithTextbox',
        value: {
          description,
          helpText: getValues('helpText'),
          score: undefined,
          group: detailName,
          customDetails: AppraisalSettings_CustomDetails.CUSTOM_DETAILS_TEXTBOX,
          isRequired: getValues('isRequired'),
        },
        groupName: detailName,
        tempId: ADDED,
      }
      mode.addDetail({
        newDetail,
        key: detailName,
      })
    } else if (detailEnum === CUSTOM_TEXTAREA_DETAIL) {
      const newDetail: Partial<FrontendDetail> = {
        tag: 'DetailWithTextarea',
        value: {
          description,
          helpText: getValues('helpText'),
          score: undefined,
          group: detailName,
          customDetails:
            AppraisalSettings_CustomDetails.CUSTOM_DETAILS_TEXTAREA,
          isRequired: getValues('isRequired'),
        },
        groupName: detailName,
        tempId: ADDED,
      }
      mode.addDetail({
        newDetail,
        key: detailName,
      })
    } else if (detailEnum === CUSTOM_DROPDOWN_DETAIL) {
      const newDetail: Partial<FrontendDetail> = {
        tag: 'DetailWithDropdown',
        value: {
          detailMetadata: {
            description,
            name: getValues('answerGroup'),
            multiSelect: getValues('multiSelect'),
          },
          options: getValues('options') as DetailWithDropdownOption[],
        },
        groupName: detailName,
        tempId: ADDED,
      }
      mode.addDetail({ newDetail, key: detailName })
    } else if (isSupportedAugustField(detailEnum)) {
      const newDetail = constructAugustFieldDetail({
        augustField: detailEnum as AugustFieldType,
        description: detailEnum ?? 'August Field',
        detailGroup: detailName,
      })
      mode.addDetail({
        newDetail,
        key: detailName,
      })
    } else {
      const newDetail: Partial<FrontendDetail> = {
        tag: 'DetailWithCheckbox',
        value: {
          description,
          score: getValues('score'),
          group: detailName,
        },
        groupName: detailName,
      }

      if (getValues('enum') === CUSTOM_DETAIL) {
        newDetail.value!['customDetails'] =
          AppraisalSettings_CustomDetails.CUSTOM_DETAILS_DEFAULT
      } else {
        newDetail.value![detailGroupProperty] = getValues('enum')
      }
      newDetail.tempId = ADDED
      mode.addDetail({ newDetail, key: detailName })
    }
  } else if (mode.tag === 'edit') {
    const updatedDetail = cloneDeep(mode.currentDetail)

    if (updatedDetail.tag === 'DetailWithCheckbox') {
      updatedDetail.value.description = getValues('description')
      updatedDetail.value.score = getValues('score')
      if (getValues('enum') === CUSTOM_DETAIL) {
        updatedDetail.value!['customDetails'] =
          AppraisalSettings_CustomDetails.CUSTOM_DETAILS_DEFAULT
        updatedDetail.value![detailGroupProperty] = undefined
      } else {
        updatedDetail.value![detailGroupProperty] = getValues('enum')
        updatedDetail.value!['customDetails'] = undefined
      }
    } else if (updatedDetail.tag === 'DetailWithDropdown') {
      updatedDetail.value.detailMetadata.description = getValues('description')

      const options = getValues('options') ?? []

      const formattedOptions: DetailWithDropdownOption[] =
        options.length > 0
          ? options.map((opt) => {
              const enumValue = opt.enum
              const formattedOption: DetailWithDropdownOption = {
                ...opt,
                customDetails: undefined,
                enum: undefined,
                group: detailName,
              }

              if (enumValue === CUSTOM_DETAIL) {
                formattedOption.customDetails =
                  AppraisalSettings_CustomDetails.CUSTOM_DETAILS_DEFAULT
                formattedOption[detailGroupProperty] = undefined
              } else {
                formattedOption.customDetails = undefined
                formattedOption[detailGroupProperty] = enumValue
              }

              return formattedOption
            })
          : []

      updatedDetail.value.options = formattedOptions
    } else if (
      updatedDetail.tag === 'DetailWithTextbox' ||
      updatedDetail.tag === 'DetailWithTextarea'
    ) {
      updatedDetail.value.description = getValues('description')
      updatedDetail.value.helpText = getValues('helpText')
      updatedDetail.value.score = undefined
      updatedDetail.value.isRequired = getValues('isRequired')
    }

    mode.editDetail({
      updatedDetail,
      optIndex: mode.optIndex,
      index: mode.index,
    })
  }
}

export const getAddEditDetailModalDefaultValues = ({
  mode,
  detailGroupProperty,
}: {
  detailGroupProperty: string
  mode?: DetailModalMode
}): DetailModalForm => {
  if (mode?.tag === 'edit') {
    if (mode.currentDetail.tag === 'DetailWithCheckbox') {
      return {
        description: mode.currentDetail.value!.description!,
        score: mode.currentDetail.value!.score!,
        enum: mode.currentDetail.value[detailGroupProperty] ?? CUSTOM_DETAIL,
      }
    } else if (mode.currentDetail.tag === 'DetailWithTextbox') {
      return {
        description: mode.currentDetail.value!.description!,
        enum: AppraisalSettings_CustomDetails.CUSTOM_DETAILS_TEXTBOX,
        helpText: mode.currentDetail.value.helpText,
        score: undefined,
        isRequired: mode.currentDetail.value.isRequired,
      }
    } else if (mode.currentDetail.tag === 'DetailWithTextarea') {
      return {
        description: mode.currentDetail.value!.description!,
        enum: AppraisalSettings_CustomDetails.CUSTOM_DETAILS_TEXTAREA,
        helpText: mode.currentDetail.value.helpText,
        score: undefined,
        isRequired: mode.currentDetail.value.isRequired,
      }
    } else if (mode.currentDetail.tag === 'DetailWithDropdown') {
      const answerGroup = mode.currentDetail.value.detailMetadata.name!
      return {
        description: mode.currentDetail.value.detailMetadata.description!,
        enum: CUSTOM_DROPDOWN_DETAIL,
        score: undefined,
        isRequired: false,
        answerGroup,
        multiSelect:
          mode.currentDetail.value.detailMetadata.multiSelect ?? false,
        options: mode.currentDetail.value.options.map((opt) => {
          return {
            answerGroup,
            description: opt.description,
            score: opt.score,
            enum: opt[detailGroupProperty] ?? CUSTOM_DETAIL,
            tempId: opt.tempId ?? undefined,
            originalTempId: opt.originalTempId ?? undefined,
          }
        }),
      }
    } else {
      throw new Error('Unsupported edit, contact Jazz')
    }
  }

  return {
    enum: CUSTOM_DETAIL,
    score: undefined,
    description: '',
    isRequired: false,
    answerGroup: uuid(),
    multiSelect: false,
    options: [],
  }
}

/**
 * Removes an existing detail group from the current details map
 * @param key the name property of the detail group
 * @param currentDetails
 * @param selectedCategory
 */
export const removeExistingDetailGroup = ({
  key,
  currentDetails,
  selectedCategory,
}: {
  key: string
  currentDetails: Map<string, FrontendDetail[]>
  selectedCategory: AssessmentCategory
}) => {
  const newDetails: Map<string, FrontendDetail[]> = new Map()

  currentDetails.forEach((val, key) => {
    newDetails.set(key, cloneDeep(val))
  })

  const groupWasAddedDuringThisSession = ![
    ...(selectedCategory.details ?? []),
    ...(selectedCategory.customDetails ?? []),
  ].some((group) => group.group === key)

  if (groupWasAddedDuringThisSession) {
    newDetails.delete(key)
  } else {
    newDetails.set(key, [])
  }
  return newDetails
}

/**
 * Removes an existing detail from a detail group
 * @param index
 * @param optIndex
 * @param key
 * @param currentDetails
 */
export const removeExistingDetail = ({
  index,
  optIndex,
  key,
  currentDetails,
}: {
  index: number
  optIndex?: number
  key: string
  currentDetails: Map<string, FrontendDetail[]>
}) => {
  const newDetails: Map<string, FrontendDetail[]> = new Map()

  currentDetails.forEach((val, key) => {
    newDetails.set(key, cloneDeep(val))
  })

  let updatedDetail: FrontendDetail[] = newDetails.get(key)!

  if (optIndex !== undefined) {
    const toRemove = updatedDetail[index].value['options'][optIndex]

    if (toRemove.tempId === ADDED) {
      delete updatedDetail[index].value['options'][optIndex]
      updatedDetail[index].value['options'] = updatedDetail[index].value[
        'options'
      ].filter((v: any) => v)
    } else {
      toRemove.tempId = REMOVED

      updatedDetail[index].value['options'][optIndex] = toRemove
    }
  } else {
    const toRemove = updatedDetail[index]
    if (toRemove.tempId === ADDED) {
      delete updatedDetail[index]

      updatedDetail = updatedDetail.filter((v) => v)
    } else {
      toRemove.tempId = REMOVED

      updatedDetail[index] = toRemove
    }
  }

  newDetails.set(key, updatedDetail)

  return newDetails
}

/**
 * Adds a new detail group to the current details map
 * @param groupName
 * @param currentDetails
 */
export const addNewDetailGroup = ({
  groupName,
  currentDetails,
}: {
  groupName: string
  currentDetails: Map<string, FrontendDetail[]>
}) => {
  const newDetails: Map<string, FrontendDetail[]> = new Map()

  currentDetails.forEach((val, key) => {
    newDetails.set(key, cloneDeep(val))
  })

  newDetails.set(groupName, [])

  return newDetails
}

/**
 * Adds a new detail to a detail group
 * @param newDetail
 * @param key
 * @param currentDetails
 * @param detailGroupProperty - the name of the property saving the enum (e.g. bathingDetails when saving an enum from the bathing section)
 */
export const addNewDetail = ({
  newDetail,
  key,
  currentDetails,
  detailGroupProperty,
}: {
  newDetail: Partial<FrontendDetail>
  key: string
  detailGroupProperty: string
  currentDetails: Map<string, FrontendDetail[]>
}) => {
  const newDetails: Map<string, FrontendDetail[]> = new Map()

  currentDetails.forEach((val, key) => {
    newDetails.set(key, cloneDeep(val))
  })

  const updatedDetail: FrontendDetail[] = newDetails.get(key)!

  const highestDisplayOrder = getHighestDisplayOrderForCategory(currentDetails)

  newDetail.displayOrder = highestDisplayOrder + 1
  if (
    newDetail.tag === 'DetailWithCheckbox' ||
    newDetail.tag === 'DetailWithTextbox' ||
    newDetail.tag === 'DetailWithTextarea' ||
    newDetail.tag === 'DetailWithAugustField'
  ) {
    newDetail.value!.displayOrder = highestDisplayOrder + 1
    newDetail.tempId = ADDED
  } else {
    if (newDetail.tag === 'DetailWithDropdown') {
      newDetail.value?.options.forEach((opt, index) => {
        // change options enum to reflect appraisal data model
        if (opt.enum === CUSTOM_DETAIL) {
          opt.customDetails = CUSTOM_DETAIL
          opt[detailGroupProperty] = undefined
        } else {
          opt.customDetails = undefined
          opt[detailGroupProperty] = opt.enum
        }

        opt.displayOrder = highestDisplayOrder + 1 + index
        opt.tempId = ADDED

        delete opt.enum
      })
    }
  }

  updatedDetail.push(newDetail as FrontendDetail)
  newDetails.set(key, updatedDetail)

  return newDetails
}

/**
 * Replaces an existing detail in the current details map - used when editing a detail via the modal
 * @param updatedDetail
 * @param index
 * @param optIndex
 * @param currentDetails
 */
export const replaceExistingDetail = ({
  updatedDetail,
  index,
  optIndex,
  currentDetails,
}: {
  updatedDetail: Partial<FrontendDetail>
  index: number
  optIndex?: number
  currentDetails: Map<string, FrontendDetail[]>
}) => {
  const newDetails: Map<string, FrontendDetail[]> = new Map()

  currentDetails.forEach((val, key) => {
    newDetails.set(key, cloneDeep(val))
  })

  const updatedDetailList: FrontendDetail[] = newDetails.get(
    updatedDetail.groupName!
  )!

  if (
    updatedDetail.tag === 'DetailWithCheckbox' ||
    updatedDetail.tag === 'DetailWithTextbox' ||
    updatedDetail.tag === 'DetailWithTextarea'
  ) {
    updatedDetailList[index].value = updatedDetail.value!
  } else if (updatedDetail.tag === 'DetailWithDropdown') {
    if (optIndex !== undefined) {
      updatedDetailList[index].value['options'][optIndex!] =
        updatedDetail.value!.options[optIndex!]
    } else {
      updatedDetailList[index].value = updatedDetail.value!
    }
  }

  newDetails.set(updatedDetail.groupName!, updatedDetailList)

  return newDetails
}

/**
 * Creates a new map of details with updated values - used when editing a detail inline in the Assessment Config tool
 * @param value
 * @param index
 * @param key
 * @param optIndex
 * @param toUpdate
 * @param currentDetails
 */
export const generateUpdatedDetails = ({
  value,
  index,
  key,
  optIndex,
  toUpdate,
  currentDetails,
}: {
  value: string | number
  index: number
  key: string
  optIndex?: number
  toUpdate:
    | 'description'
    | 'score'
    | 'meta-description'
    | 'helpText'
    | 'isRequired'
  currentDetails: Map<string, FrontendDetail[]>
}): Map<string, FrontendDetail[]> => {
  let newValue: string | number | undefined = value

  if (toUpdate === 'score') {
    if (newValue.toString().length > 0) {
      const parsedValue = parseInt(newValue as string)
      newValue = isNaN(parsedValue) ? 0 : parsedValue
    } else {
      newValue = undefined
    }
  }
  const newDetails: Map<string, FrontendDetail[]> = new Map()

  currentDetails.forEach((val, key) => {
    newDetails.set(key, cloneDeep(val))
  })

  const updatedDetail: FrontendDetail[] = newDetails.get(key)!

  if (updatedDetail[index].tag === 'DetailWithDropdown') {
    if (toUpdate === 'meta-description') {
      updatedDetail[index].value['detailMetadata'].description =
        newValue?.toString()
    } else {
      // @ts-expect-error Update a specific key for a given dropdown option
      updatedDetail[index].value['options'][optIndex][toUpdate] = newValue
    }
  } else {
    updatedDetail[index].value[toUpdate] = newValue
  }

  newDetails.set(key, updatedDetail)

  return newDetails
}
