import { useState } from 'react'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { BillingCategory } from '@shared/types/billing'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import { TdWithExtraPadding, TrWithHoverEffect } from '@app/components/Table'
import useBillingCategories from '@app/hooks/useBillingCategories'
import WithTable from '@app/pages/Facilities/Billing/Components/WithTable'
import {
  BillingCategoriesColumnName,
  getSearchedBillingCategories,
  getSortedBillingCategories,
} from './helpers'
import BillingCategoryModal from './Modals/BillingCategoryModal'

type Props = {
  facility: Facility
  organization: Organization
  onUpdate: (msg: string) => void
}

export default function BillingCategoriesTable(props: Props) {
  const { facility, organization, onUpdate } = props
  const { orgId, id: facilityId } = facility
  const { billingCategories, reloadBillingCategories } = useBillingCategories({
    orgId,
    facilityId,
  })
  const [billingCategoryInModal, setBillingCategoryInModal] =
    useState<BillingCategory | null>(null)
  const headers = [
    { label: BillingCategoriesColumnName.NAME },
    { label: BillingCategoriesColumnName.APPLIES_TO },
  ]

  if (!facility) {
    return <SimpleSpinner />
  }

  return (
    <>
      <WithTable
        title="Categories"
        rows={billingCategories}
        headers={headers}
        defaultSelectedColumn={BillingCategoriesColumnName.NAME}
        sortingFn={getSortedBillingCategories}
        addBtnProps={{
          label: 'Add Category',
          onClick: () => setBillingCategoryInModal({} as BillingCategory),
        }}
        searchBoxProps={{
          searchFn: getSearchedBillingCategories,
          searchPlaceholder: 'Search categories...',
        }}
      >
        {(sortedRows: BillingCategory[]) => (
          <>
            {sortedRows?.map((billingCategory, ix) => {
              const {
                data: { name, facilityId: fId },
                meta: { id },
              } = billingCategory

              return (
                <TrWithHoverEffect
                  data-testid={`billing-category-${ix}`}
                  id={id}
                  key={id}
                  onClick={() => {
                    setBillingCategoryInModal(billingCategory)
                  }}
                >
                  <TdWithExtraPadding>{name}</TdWithExtraPadding>
                  {!fId && (
                    <TdWithExtraPadding>
                      <i className="fas fa-house-building mr-[8px] text-gray-07" />
                      All Communities
                    </TdWithExtraPadding>
                  )}
                  {fId && (
                    <TdWithExtraPadding>{facility.name}</TdWithExtraPadding>
                  )}
                </TrWithHoverEffect>
              )
            })}
          </>
        )}
      </WithTable>
      {billingCategoryInModal && (
        <BillingCategoryModal
          billingCategory={billingCategoryInModal}
          closeFn={() => setBillingCategoryInModal(null)}
          doneFn={(msg: string) => {
            onUpdate(msg)
            return reloadBillingCategories()
          }}
          organization={organization}
          facility={facility}
        />
      )}
    </>
  )
}
