import { AppraisalSettings_CustomDetails } from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { ADDED, REMOVED } from 'app/pages/Tools/AssessmentConfiguration/helpers'
import { useEffect } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import ButtonLink from '@shared/components/ButtonLink'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, { OptionTypeBase } from '@shared/components/StyledSelect'
import notEmpty from '@shared/utils/notEmpty'
import { tw, twx } from '@shared/utils/tailwind'
import {
  DetailWithDropdownOption,
  FrontendDetail,
} from '@app/pages/Tasks/ResidentAssessment/AssessmentPage/CheckboxSections/utils'
import { DetailModalForm } from '@app/pages/Tools/AssessmentConfiguration/DetailGroups/AddEditDetailModal/AddEditDetailModal'
import { getValidDropdownEnumOptions } from '@app/pages/Tools/AssessmentConfiguration/DetailGroups/AddEditDetailModal/helpers'

type Props = {
  detailGroupProperty: string
  detailName: string
  detailType: string
  currentDetails: Map<string, FrontendDetail[]>
}
export const DropdownDetail = ({
  currentDetails,
  detailGroupProperty,
  detailName,
  detailType,
}: Props) => {
  const { formState, register, unregister, control, watch, getValues } =
    useFormContext<DetailModalForm>()

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'options',
  })

  useEffect(() => {
    return () => {
      unregister(['description', 'options'])
    }
  }, [])
  const addNewOption = () => {
    append({
      description: '',
      score: 0,
      answerGroup: getValues('answerGroup'),
      enum: AppraisalSettings_CustomDetails.CUSTOM_DETAILS_DEFAULT,
      group: detailName,
      tempId: ADDED,
    })
  }

  const options = watch('options') ?? []

  const currentEnums: string[] = options
    .map((field) => field.enum ?? '')
    .filter(notEmpty)

  const handleRemovingOrRestoringOption = ({
    toBeRemoved,
    index,
    opt,
  }: {
    toBeRemoved: boolean
    index: number
    opt: DetailWithDropdownOption
  }) => {
    if (toBeRemoved) {
      // restore the tempId to its original value or the index of the option to "undo" the removal of the option
      const originalTempId =
        formState.defaultValues?.options?.[index]?.originalTempId ?? index

      update(index, {
        ...opt,
        tempId: originalTempId.toString(),
      })
    } else if (opt.tempId === ADDED) {
      // the option was added during the current session => outright remove the option
      remove(index)
    } else {
      // the option was part of a saved config =>  earmark this for removal when the assessment is saved
      update(index, {
        ...opt,
        tempId: REMOVED,
      })
    }
  }

  return (
    <>
      <LabelAboveInput htmlFor={'description'}>Description</LabelAboveInput>
      <BasicInput
        type={'text'}
        placeholder={'Description'}
        {...register('description')}
      />
      {fields.map((opt, index) => {
        const relevantOptions: OptionTypeBase[] = getValidDropdownEnumOptions(
          currentDetails,
          detailType,
          detailGroupProperty,
          currentEnums,
          opt.enum
        )

        const toBeRemoved = opt.tempId === REMOVED
        const labelClasses = twx({
          'opacity-75': toBeRemoved,
        })

        return (
          <div
            key={opt.id}
            className={tw`my-2 flex flex-row items-center gap-4`}
          >
            <div
              className={tw`whitespace-nowrap text-[12px] font-bold leading-[16px] tracking-wide text-gray-07`}
            >
              {`Opt ${index + 1}`}
              <ButtonLink
                title={toBeRemoved ? `Restore Item` : 'Delete Item'}
                onClick={() => {
                  handleRemovingOrRestoringOption({ toBeRemoved, index, opt })
                }}
                className={tw`ml-1`}
                disabled={options.length < 2}
              >
                <i
                  className={tw` fa fa-fw text-[16px] fa-${
                    toBeRemoved ? 'rotate-left' : 'trash'
                  }`}
                />
              </ButtonLink>
            </div>
            <div
              className={tw`my-2 flex w-full flex-col gap-4 rounded-md border border-form-input-border bg-white px-4 py-3 shadow-card`}
            >
              <div>
                <LabelAboveInput
                  htmlFor={`options.${index}.description`}
                  className={labelClasses}
                >
                  {toBeRemoved ? 'Marked for Deletion' : 'Description'}
                </LabelAboveInput>
                <BasicInput
                  type={'text'}
                  placeholder={'Description'}
                  {...register(`options.${index}.description`)}
                  disabled={toBeRemoved}
                />
              </div>
              <div
                className={tw`flex flex-row items-center justify-between gap-4`}
              >
                <div className={tw`w-[80px]`}>
                  <LabelAboveInput
                    htmlFor={`options.${index}.score`}
                    className={labelClasses}
                  >
                    Score
                  </LabelAboveInput>
                  <BasicInput
                    type={'number'}
                    placeholder={'Score'}
                    min={0}
                    defaultValue={0}
                    {...register(`options.${index}.score`)}
                    disabled={toBeRemoved}
                  />
                </div>
                <div className={tw`grow`}>
                  <LabelAboveInput
                    htmlFor={`options.${index}.enum`}
                    id={`label-options.${index}.enum`}
                    className={labelClasses}
                  >
                    Enum
                  </LabelAboveInput>
                  <Controller
                    control={control}
                    name={`options.${index}.enum`}
                    render={({ field: { onChange, value } }) => (
                      <StyledSelect
                        placeholder={'Select an enum...'}
                        name={`options.${index}.enum`}
                        id={`options.${index}.enum`}
                        aria-labelledby={`label-options.${index}.enum`}
                        options={relevantOptions}
                        onChange={(opt: OptionTypeBase | null) =>
                          onChange(opt?.value || null)
                        }
                        value={relevantOptions.find(
                          (opt) => opt.value === value
                        )}
                        isDisabled={toBeRemoved}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      })}

      <AsyncIconButton
        buttonStyle={'primary-outline'}
        buttonSize={'small'}
        initialIcon={'fa-solid fa-plus'}
        className={tw`bg-white uppercase`}
        onClick={addNewOption}
      >
        Add Dropdown Option
      </AsyncIconButton>
    </>
  )
}
