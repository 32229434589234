import { MedicationOrder as LegacyMedicationOrder } from '@augusthealth/models/com/august/protos/medication_order'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import OrderCard from 'app/components/Residents/Medications/Orders/OrderCard'
import { useEffect, useRef, useState } from 'react'
import { SectionDisclosure } from '@shared/components/Disclosure'
import { EmptyCard } from '@shared/components/EmptyCard'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import { FeatureFlagNames } from '@shared/constants/feature_flags'
import { useUserContext } from '@shared/contexts/UserContext'
import { MedicationOrder } from '@shared/types/medication_order'
import { Person } from '@shared/types/person'
import { Loading } from '@shared/utils/loading'
import { nameOrInstructionMatchesString } from '@shared/utils/medicationOrder'
import { tw, twx } from '@shared/utils/tailwind'
import { isFeatureAllowed } from '@shared/utils/user'
import HUD from '@app/components/HUD'
import { useRefillsByPerson } from '@app/components/Residents/Medications/Orders/Refills/useRefillsByPerson'
import { WeakMatchOrdersList } from '@app/components/Residents/Medications/Orders/WeakMatch/WeakMatchOrdersList'
import { NoteCardSkeleton } from '@app/pages/Notes/NoteCardSkeleton'
import { getMedicationOrderKey, getSortedMedicationOrderList } from './helpers'

type Props = {
  person: Person
  medications: Loading<LegacyMedicationOrder[]>
  externalMedications: Loading<LegacyMedicationOrder[]>
  history: any
  medFilter: string
  highlightOrderId?: string
  reloadMedications: () => Promise<void>
}
const Orders = ({
  person,
  medications,
  externalMedications,
  history,
  medFilter,
  highlightOrderId,
  reloadMedications,
}: Props) => {
  const { user } = useUserContext()
  const [showHUD, setShowHUD] = useState<string | null>(null)
  const highlightRef = useRef<HTMLElement>(null)
  const { refills } = useRefillsByPerson({ person })

  useEffect(() => {
    if (highlightOrderId && externalMedications.tag === 'Complete') {
      highlightRef.current?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [externalMedications.tag])

  if (
    medications.tag !== 'Complete' ||
    externalMedications.tag !== 'Complete' ||
    refills.tag !== 'Complete'
  ) {
    return <NoteCardSkeleton />
  }

  const { weakMatchMeds, activeMeds, discontinuedMeds } =
    getSortedMedicationOrderList({
      pharmacyMessages: externalMedications.value,
      medicationOrders: medications.value,
    })

  const hasDiscontinued = discontinuedMeds.length > 0

  const canEditMedication = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_MEDICATION_UPDATE],
  })
  const canViewMedication = hasPermissionForPerson({
    user,
    person,
    permissions: [
      GroupPermission.GROUP_PERMISSION_MEDICATION_READ,
      GroupPermission.GROUP_PERMISSION_MED_PASSES_READ,
    ],
  })
  const refillEligibility = isFeatureAllowed(user, FeatureFlagNames.MED_REFILLS)
    ? refills.value
    : null

  const hasNoActiveOrPendingMeds =
    activeMeds.length === 0 && weakMatchMeds.length === 0

  const filteredWeakMatchMeds = weakMatchMeds.filter(
    nameOrInstructionMatchesString(medFilter)
  )
  const filteredActiveMeds = activeMeds.filter(
    nameOrInstructionMatchesString(medFilter)
  )
  const filteredDiscontinuedMeds = discontinuedMeds.filter(
    nameOrInstructionMatchesString(medFilter)
  )

  return (
    <div className={twx('flex flex-col gap-2')}>
      {hasNoActiveOrPendingMeds && (
        <div className={'mb-4'}>
          {!canEditMedication ? (
            <EmptyCard
              version={{
                tag: 'Children',
                children: 'No medications to display.',
              }}
            />
          ) : (
            <EmptyCard
              version={{
                tag: 'GetStarted',
                cta: 'add a medication',
                ctaFn: () => history.push('medications/new'),
              }}
              title="No medications to display."
            />
          )}
        </div>
      )}
      <WeakMatchOrdersList
        person={person}
        filteredWeakMatchMeds={filteredWeakMatchMeds as MedicationOrder[]}
        allOrders={
          [
            ...externalMedications.value,
            ...medications.value,
          ] as MedicationOrder[]
        }
        onOrderReject={async () => {
          await reloadMedications()
          setShowHUD('Order Rejected')
        }}
      />
      {filteredActiveMeds.map((m) => {
        const highlightOrder =
          highlightOrderId !== undefined && highlightOrderId === m.id

        return (
          <div key={getMedicationOrderKey(m)}>
            <span ref={highlightOrder ? highlightRef : undefined}></span>
            <OrderCard
              order={m as MedicationOrder}
              canInteractWithMedication={canEditMedication || canViewMedication}
              highlight={highlightOrder}
              refillEligibility={refillEligibility}
            />
          </div>
        )
      })}
      {hasDiscontinued && (
        <SectionDisclosure summary="Discontinued Orders">
          <div className={tw`flex flex-col gap-2`}>
            {filteredDiscontinuedMeds.map((m) => {
              return (
                <OrderCard
                  order={m as MedicationOrder}
                  discontinued={true}
                  canInteractWithMedication={
                    canEditMedication || canViewMedication
                  }
                  key={getMedicationOrderKey(m)}
                  refillEligibility={refillEligibility}
                />
              )
            })}
          </div>
        </SectionDisclosure>
      )}
      {!!showHUD && <HUD onExpire={() => setShowHUD(null)}>{showHUD}</HUD>}
    </div>
  )
}

export default Orders
