import TextInputWithIcon, {
  Props,
} from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import styles from './styles.module.css'

export default function MoneyInput({ inputProps, holderClassName }: Props) {
  const { className, placeholder = '0.00' } = inputProps
  const classNameList = [styles.money]
  if (className) {
    classNameList.push(className)
  }

  const props = {
    type: 'number',
    step: 0.01,
    ...inputProps,
    placeholder,
    className: classNameList.join(' '),
  }

  return (
    <TextInputWithIcon
      holderClassName={holderClassName}
      inputProps={props}
      iconClassName="fas fa-fw fa-usd-square"
    />
  )
}
