import {
  Routine,
  RoutineStatus,
} from '@augusthealth/models/com/august/protos/routine'
import { partition, sortBy } from 'lodash'
import {
  isActiveRoutine,
  isDiscardedRoutine,
  isDraftRoutine,
} from '@shared/utils/routine'

export function routineIsVisible(r: Routine) {
  return isDraftRoutine(r) || isActiveRoutine(r) || isDiscardedRoutine(r)
}

export function sortRelevantRoutines(routines: Routine[]) {
  return sortBy(
    routines.map((r) => {
      // Active routines are prioritized over  others, so give them a lower
      // initial 'starting order'
      const initialOrder = isActiveRoutine(r) ? 100 : 1000

      if (r.name === 'Morning') {
        // Morning routines have an order of either 90 (active) or 990 (other)
        // 90 is the lowest possible order number, so an active morning routine
        // will always be top priority.
        return {
          ...r,
          order: initialOrder - 10,
        }
      } else if (r.name === 'Bedtime') {
        // Bedtime routines have an order of either 95 (active) or 995 (other)
        return {
          ...r,
          order: initialOrder - 5,
        }
      }

      // Everything else gets an order of either 100 (active) or 1000 (other)
      return { ...r, order: initialOrder }
    }),
    'order'
  )
}

export function partitionByVisibility(routines: Routine[]) {
  const [visibleRoutines, hiddenRoutines] = partition(routines, (r) =>
    isDiscardedRoutine(r)
  )

  return { visibleRoutines, hiddenRoutines }
}

export function omitDiscardedDuplicates(routines: Routine[]) {
  return routines.filter((r, _, self) => {
    if (
      r.status === RoutineStatus.ROUTINE_STATUS_DISCARDED &&
      Boolean(
        self.find(
          (er) =>
            er.name === r.name &&
            er.status === RoutineStatus.ROUTINE_STATUS_ACTIVE
        )
      )
    ) {
      return false
    }

    return true
  })
}
