import {
  AppraisalSettings_AnswerGroup,
  AppraisalSettings_AppraisalCategory,
  AppraisalSettings_CustomDetails,
  AppraisalSettings_AppraisalCategoryDetail as Detail,
} from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { minBy, sortBy } from 'lodash'
import { AssessmentCategory } from '@shared/types/assessment_configuration'
import { isCustomTextDetail } from '@shared/utils/assessmentConfiguration'

type SectionName = string
export type FrontendDetail =
  | DetailWithCheckbox
  | DetailWithDropdown
  | DetailWithTextbox
  | DetailWithAugustField
  | DetailWithTextarea

export interface DetailWithTextbox {
  tag: 'DetailWithTextbox'
  value: Detail
  displayOrder: number
  groupName: string
  tempId?: string
}

export interface DetailWithTextarea {
  tag: 'DetailWithTextarea'
  value: Detail
  displayOrder: number
  groupName: string
  tempId?: string
}

export interface DetailWithDropdownOption extends Detail {
  originalTempId?: string
  tempId?: string
  enum?: string
}

export interface DetailWithCheckbox {
  tag: 'DetailWithCheckbox'
  value: Detail
  displayOrder: number
  groupName: string
  /*
    tempId is used by the Assessment Configuration Tool to keep track of items that have been edited during the current session.
   */
  tempId?: string
}

export interface DetailWithDropdown {
  tag: 'DetailWithDropdown'
  value: {
    detailMetadata: AppraisalSettings_AnswerGroup
    options: DetailWithDropdownOption[]
  }
  displayOrder: number
  groupName: string
  tempId?: string
}

export interface DetailWithAugustField {
  tag: 'DetailWithAugustField'
  value: Detail
  tempId?: string
  groupName: string
  displayOrder: number
}

export function makeCheckboxSections(
  category: AppraisalSettings_AppraisalCategory | AssessmentCategory
) {
  const { details = [], customDetails = [], answerGroups = [] } = category
  const orderedGroups = new Map<SectionName, FrontendDetail[]>()

  const dropdownDetails: DetailWithDropdown[] = answerGroups.map(
    (answerGroup) => {
      const optionsForDropdown = [...details, ...customDetails].filter(
        (d) => d.answerGroup === answerGroup.name
      )

      return {
        tag: 'DetailWithDropdown',
        value: {
          detailMetadata: answerGroup,
          options: sortBy(optionsForDropdown, (d) => d.displayOrder),
        },
        displayOrder:
          minBy(optionsForDropdown, (gd) => gd.displayOrder)?.displayOrder ?? 0,
        groupName: optionsForDropdown[0].group ?? 'Unmatched',
      }
    }
  )

  const augustFieldDetails: DetailWithAugustField[] = [
    ...details,
    ...customDetails,
  ].reduce((results: DetailWithAugustField[], d) => {
    if (d.augustFieldType !== undefined) {
      results.push({
        tag: 'DetailWithAugustField',
        value: d,
        groupName: d.group ?? '',
        displayOrder: d.displayOrder ?? 0,
      })
    }

    return results
  }, [])
  const checkboxDetails: DetailWithCheckbox[] = [
    ...details,
    ...customDetails,
  ].reduce((results: DetailWithCheckbox[], d) => {
    if (
      d.answerGroup === undefined &&
      !isCustomTextDetail(d.customDetails) &&
      d.augustFieldType === undefined
    ) {
      results.push({
        tag: 'DetailWithCheckbox',
        value: d,
        displayOrder: d.displayOrder ?? 0,
        groupName: d.group ?? '',
      })
    }

    return results
  }, [])

  const textboxDetails: DetailWithTextbox[] = [
    ...details,
    ...customDetails,
  ].reduce((results: DetailWithTextbox[], d) => {
    if (
      d.answerGroup === undefined &&
      d.customDetails === AppraisalSettings_CustomDetails.CUSTOM_DETAILS_TEXTBOX
    ) {
      results.push({
        tag: 'DetailWithTextbox',
        value: d,
        displayOrder: d.displayOrder ?? 0,
        groupName: d.group ?? '',
      })
    }

    return results
  }, [])

  const textareaDetails: DetailWithTextarea[] = [
    ...details,
    ...customDetails,
  ].reduce((results: DetailWithTextarea[], d) => {
    if (
      d.answerGroup === undefined &&
      d.customDetails ===
        AppraisalSettings_CustomDetails.CUSTOM_DETAILS_TEXTAREA
    ) {
      results.push({
        tag: 'DetailWithTextarea',
        value: d,
        displayOrder: d.displayOrder ?? 0,
        groupName: d.group ?? '',
      })
    }

    return results
  }, [])

  const sorted = sortBy(
    [
      ...augustFieldDetails,
      ...dropdownDetails,
      ...checkboxDetails,
      ...textboxDetails,
      ...textareaDetails,
    ],
    (d) => d.displayOrder
  )

  sorted.forEach((detailData) => {
    const existingDetailsForGroup =
      orderedGroups.get(detailData.groupName!) ?? []
    orderedGroups.set(
      detailData.groupName!,
      sortBy([...existingDetailsForGroup, detailData], (d) => d.displayOrder)
    )
  })

  return orderedGroups
}

export function formatScore(backendDetail: Detail) {
  const score = backendDetail.score

  if (score && score > 0) {
    return `(+${score})`
  }
  if (score && score < 0) {
    return `(${score})`
  }

  return ''
}
