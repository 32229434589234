import {
  Task,
  TaskDefinition,
  TaskType,
} from '@augusthealth/models/com/august/protos/task'
import Badge from '@shared/components/Badge'
import { SettingsLevel } from '@shared/utils/orgFacilitySettings'
import { taskSubtitle, taskTitle } from '@shared/utils/task'

export default function TaskTitle({
  task,
  taskLevel,
  setShowDetails,
  assignmentLevel,
}: {
  task: TaskDefinition
  taskLevel: string
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>
  assignmentLevel?: SettingsLevel
}) {
  const taskWithTemplateInfo: Task = { taskTemplateInfo: task.template! }
  const { isFile, isUpload } = task.template!
  const title = taskTitle(taskWithTemplateInfo)
  const subTitle = taskSubtitle(taskWithTemplateInfo)

  return (
    <div
      className="flex grow cursor-pointer items-center"
      onClick={() => setShowDetails((prev) => !prev)}
    >
      <h3 className="mb-0 text-august-primary">{title}</h3>
      {subTitle && <h4 className="mb-0 ml-[8px]">{subTitle}</h4>}
      <Badge color="green" className="ml-[8px]">
        {taskLevel}
      </Badge>
      {task.template?.taskType === TaskType.TASK_TYPE_CUSTOM_SIGNABLE_FORM && (
        <Badge color="green" className="ml-[8px]">
          Custom Form
        </Badge>
      )}
      {isUpload && (
        <Badge color="purple" className="ml-[8px]">
          Uploaded by Users
        </Badge>
      )}
      {!isFile && (
        <Badge color="purple" className="ml-[8px]">
          Doesn't produce PDF
        </Badge>
      )}
      {assignmentLevel && (
        <Badge color="orange" className="ml-[8px]">
          Matches {assignmentLevel} Assignment
        </Badge>
      )}
    </div>
  )
}
