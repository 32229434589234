import { Immunization } from '@augusthealth/models/com/august/protos/immunization'
import {
  Task,
  TaskTemplateInfo,
  TaskType,
} from '@augusthealth/models/com/august/protos/task'
import { compact, isEmpty } from 'lodash'
import type { RequiredDeep } from 'type-fest'
import { archivedDocumentRoute } from '@shared/hooks/useCurrentPage'
import { getTaskUrl, snapshotFileUrl } from '@shared/legacy_routes'
import { Facility } from '@shared/types/facility'
import { Person } from '@shared/types/person'
import { DataType, Snapshot, SnapshotStatus } from '@shared/types/snapshot'
import { Order } from '@shared/utils/common'
import {
  formatDateMessage,
  fromIsoStringToDateAndTime,
} from '@shared/utils/date'
import { isCaliforniaFacility } from '@shared/utils/facilities'
import { getFullName } from '@shared/utils/humanName'
import {
  AVAILABLE_VACCINATIONS,
  getImmunizationsByDiseaseName,
  mostRecentAttachmentId,
} from '@shared/utils/immunizations'
import { isResident } from '@shared/utils/person'
import { alphaNumericSort } from '@shared/utils/personStats'
import { getRelevantDate, getSnapshotName } from '@shared/utils/snapshot'
import { incompleteTask, taskSubtitle, taskTitle } from '@shared/utils/task'
import { recreateTask } from '@app/api/tasks'
import { SnapshotSortOrder } from '@app/components/SortByDropdown/helpers'

export interface RoutableDocument extends Snapshot {
  routeDirectlyToDocument: boolean
}

export interface ArchivedDocument extends Snapshot {
  status: SnapshotStatus.SNAPSHOT_STATUS_ARCHIVED
  fileUrl: string
  mode: 'View' | 'Download' | 'No Files'
}

export type UploadableTask = {
  name: string
  dataType: DataType
  taskToComplete: Task | undefined
}

export const filterMatchesContent = function ({
  filter,
  content,
}: {
  filter: string
  content: string
}): boolean {
  const searchContent = content.replace(/\W+/g, '').toLowerCase()
  const searchFilterWithPunctuation = filter.toLowerCase()
  const searchFilterWithPunctuationRemoved = filter
    .toLowerCase()
    .replace(/\W+/g, '')

  return (
    searchContent.includes(searchFilterWithPunctuation) ||
    searchContent.includes(searchFilterWithPunctuationRemoved)
  )
}

function hasDedicatedRoute(snapshot: Snapshot) {
  const { dataType } = snapshot

  return (
    (dataType === DataType.DATA_TYPE_AUGUST_INITIAL_APPRAISAL ||
      dataType === DataType.DATA_TYPE_LEVEL_OF_CARE_CIMINO) &&
    !snapshot.fileMetaData
  )
}

export function getArchivedDocuments({
  snapshots,
  dataType,
  person,
}: {
  snapshots: Snapshot[]
  dataType: DataType
  person: Person
}): ArchivedDocument[] {
  return snapshots
    .sort(getSortBySnapshotDateFunc(Order.DESC))
    .map((snapshot) => {
      if (hasDedicatedRoute(snapshot)) {
        return {
          ...snapshot,
          status: SnapshotStatus.SNAPSHOT_STATUS_ARCHIVED,
          fileUrl: archivedDocumentRoute({
            person: person as RequiredDeep<Person>,
            snapshot,
          }),
          mode: 'View',
        }
      }

      // For POLST without attachment
      if (!snapshot.fileMetaData) {
        return {
          ...snapshot,
          mode: 'No Files',
          status: SnapshotStatus.SNAPSHOT_STATUS_ARCHIVED,
          fileUrl: '',
        }
      }

      const params = snapshot.customType
        ? { customType: snapshot.customType }
        : {}
      return {
        ...snapshot,
        status: SnapshotStatus.SNAPSHOT_STATUS_ARCHIVED,
        fileUrl: snapshotFileUrl({
          personId: person.id!,
          orgId: person.orgId!,
          dataType,
          snapshotId: snapshot.id!,
          params,
        }),
        mode: 'Download',
      }
    })
}

export async function recreateTaskAndReturnUrl(
  person: Person,
  incompleteTask: Task | undefined,
  dataType: DataType,
  customType?: string
): Promise<string> {
  if (incompleteTask) {
    // Person already has an incomplete task
    // redirect them there
    return Promise.resolve(
      getTaskUrl({
        orgId: person.orgId!,
        facilityId: person.facilityId!,
        personId: person.id!,
        id: incompleteTask.id!,
      })
    )
  } else {
    // Person needs a new task of the requested type
    // Create then return new url
    return recreateTask({ person, dataType, customType }).then((json) =>
      getTaskUrl({
        orgId: person.orgId!,
        facilityId: person.facilityId!,
        personId: person.id!,
        id: json.data.id,
      })
    )
  }
}

export function incompleteSignableTasks(
  tasks: Task[],
  person: Person,
  facility: Facility
): UploadableTask[] {
  const signableTaskTypes = [
    DataType.DATA_TYPE_CA_FORM_601,
    DataType.DATA_TYPE_CA_FORM_603,
    DataType.DATA_TYPE_CA_FORM_603A,
  ]
  const incompleteSignableTasks: UploadableTask[] = []

  signableTaskTypes.map((taskType) => {
    const task = incompleteTask(tasks, taskType)

    if (task) {
      incompleteSignableTasks.push({
        name: taskName(task.taskTemplateInfo!, person, facility),
        dataType: task.taskTemplateInfo!.dataType!,
        taskToComplete: task,
      })
    }
  })

  return incompleteSignableTasks
}

export const taskName = (
  taskTemplate: TaskTemplateInfo,
  person: Person,
  facility: Facility
) => {
  const { title, subtitle } = taskNameData(taskTemplate, person, facility)
  return subtitle ? `${title} (${subtitle})` : title
}

export function taskNameData(
  taskTemplate: TaskTemplateInfo,
  person: Person,
  facility: Facility
): { title: string; subtitle?: string } {
  /* California residents get a 603A instead of a 603 */
  /* See the following PR for details */
  /* https://github.com/augusthealth/august-frontend/pull/1018 */
  if (
    taskTemplate.dataType === DataType.DATA_TYPE_CA_FORM_603 &&
    isResident(person) &&
    isCaliforniaFacility(facility)
  ) {
    return taskNameData(
      {
        displayName: 'Resident Appraisal',
        shortName: 'LIC 603A',
        taskType: TaskType.TASK_TYPE_CA_FORM_603A,
      },
      person,
      facility
    )
  }

  const title = taskTitle(taskTemplate)
  const subtitle = taskSubtitle(taskTemplate)

  return { title, subtitle }
}

/**
 * Build Snapshot list to be displayed
 * Also find document ID based on disease name such as COVID-19 or influenza
 * If Immunization record found but Snapshot NOT found, create a fake Snapshot to display a Immunization record in Ducuments tab
 */
export function buildDocumentEntries({
  snapshots,
  immunizations,
}: {
  immunizations: Immunization[]
  snapshots: Snapshot[]
}): Snapshot[] {
  const immunizationSnapshots = AVAILABLE_VACCINATIONS.map((diseaseName) => {
    return findSnapshotWithMostRecentImmunization({
      diseaseName,
      snapshots,
      immunizations,
    })
  })
  const otherEntries = snapshots.filter(belongsInDocumentsTab)

  return compact([...otherEntries, ...immunizationSnapshots])
}

export function taskTemplateInfoMatchesFilter(
  taskTemplateInfo: TaskTemplateInfo,
  filter: string
): boolean {
  if (filter.trim().length === 0) {
    return true
  }

  return [
    taskTemplateInfo.taskType,
    taskTemplateInfo.customType,
    taskTemplateInfo.dataType,
    taskTemplateInfo.displayName,
    taskTemplateInfo.shortName,
  ]
    .filter((value) => !isEmpty(value))
    .map((value: any) => value.toString())
    .some((value: string) => {
      return filterMatchesContent({ content: value, filter })
    })
}

/**
 * Filter document based on almost all stringify-able attributes inside the document
 * @param document {Snapshot}
 * @param filter {string}
 * @returns boolean
 */
export function documentEntryMatchesFilter(
  document: Snapshot,
  filter: string
): boolean {
  if (filter.trim().length === 0) {
    return true
  }

  return [
    document.dataType,
    document.customType,
    document.fileMetaData?.fileName,
    document.data?.uploadInfo?.name,
    document.data?.uploadInfo?.notes,
    document.snapshotDetail?.note,
    document.snapshotDetail?.displayName,
    document.snapshotDetail?.shortName,
    getFullName(document.lastModification?.modifiedByUserName),
    formatDateMessage(
      fromIsoStringToDateAndTime(document.lastModification?.modificationTime)
        ?.date
    ),
    formatDateMessage(fromIsoStringToDateAndTime(document.completedAt)?.date),
    formatDateMessage(document.data?.uploadInfo?.signatureData?.dateSigned),
    formatDateMessage(document.data?.uploadInfo?.polst?.dateSigned),
    ...(document.signatureDetails?.signers || []).flatMap((signer) => {
      return [signer.name, signer.email]
    }),
  ]
    .filter((value) => !isEmpty(value))
    .map((value: any) => value.toString())
    .some((value: string) => {
      return filterMatchesContent({ content: value, filter })
    })
}

function getSortBySnapshotNameFunc(sortOrder: Order) {
  return (snapshotA: Snapshot, snapshotB: Snapshot) => {
    const snapshotNameA = getSnapshotName(snapshotA)
    const snapshotNameB = getSnapshotName(snapshotB)
    return alphaNumericSort(snapshotNameA, snapshotNameB, sortOrder)
  }
}

function getSortBySnapshotDateFunc(sortOrder: Order) {
  return (snapshotA: Snapshot, snapshotB: Snapshot) => {
    const snapshotDateA = getRelevantDate(snapshotA)
    const snapshotDateB = getRelevantDate(snapshotB)
    return alphaNumericSort(snapshotDateA, snapshotDateB, sortOrder)
  }
}

export function getSortedSnapshots(
  snapshots: Snapshot[],
  sortOrder?: SnapshotSortOrder
) {
  const sortedSnapshots = [...snapshots]
  if (sortOrder === SnapshotSortOrder.SNAPSHOT_SORT_BY_NAME_ASC) {
    return sortedSnapshots.sort(getSortBySnapshotNameFunc(Order.ASC))
  } else if (sortOrder === SnapshotSortOrder.SNAPSHOT_SORT_BY_NAME_DESC) {
    return sortedSnapshots.sort(getSortBySnapshotNameFunc(Order.DESC))
  } else if (sortOrder === SnapshotSortOrder.SNAPSHOT_SORT_BY_LAST_UPDATE_ASC) {
    return sortedSnapshots.sort(getSortBySnapshotDateFunc(Order.ASC))
  } else if (
    sortOrder === SnapshotSortOrder.SNAPSHOT_SORT_BY_LAST_UPDATE_DESC
  ) {
    return sortedSnapshots.sort(getSortBySnapshotDateFunc(Order.DESC))
  }

  return sortedSnapshots
}

/**
 * Remove Snapshot with DataType
 * - DATA_TYPE_PROFILE_PHOTO
 * - DATA_TYPE_RESIZED_PROFILE_PHOTO
 * - DATA_TYPE_INCIDENT_ATTACHMENT
 * - DATA_TYPE_IMMUNIZATION_RECORD
 * @param {Snapshot} s
 */
function belongsInDocumentsTab(s: Snapshot) {
  return ![
    DataType.DATA_TYPE_PROFILE_PHOTO,
    DataType.DATA_TYPE_RESIZED_PROFILE_PHOTO,
    DataType.DATA_TYPE_INCIDENT_ATTACHMENT,
    DataType.DATA_TYPE_IMMUNIZATION_RECORD,
  ].includes(s.dataType!)
}

export function findSnapshotWithMostRecentImmunization({
  diseaseName,
  immunizations,
  snapshots,
}: {
  diseaseName?: string
  snapshots: Snapshot[]
  immunizations: Immunization[]
}): Snapshot | undefined {
  const immunizationSnapshots = snapshots.filter(
    (s) => s.dataType === DataType.DATA_TYPE_IMMUNIZATION_RECORD
  )
  const immuList = diseaseName
    ? getImmunizationsByDiseaseName({
        diseaseName,
        immunizations,
      })
    : immunizations

  if (immunizationSnapshots.length) {
    const mostRecentSnapshotId = mostRecentAttachmentId(immuList)
    return immunizationSnapshots.find((s) => s.id === mostRecentSnapshotId)
  }

  return undefined
}
