import { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { formDataToVitalsPayload } from '@shared/components/Vitals/helpers'
import { VitalsModalContents } from '@shared/components/Vitals/VitalsModalContents'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { tw, twx } from '@shared/utils/tailwind'
import { VitalsFormData } from '@shared/utils/vitals'
import { createVitalNote } from '@app/api/incidents'
import { createVitals } from '@app/api/vitals'
import VitalsContext from '@app/contexts/VitalsContext'

const AddVitalsModal = ({
  person,
  refreshIncidents,
  btnClassName,
}: {
  person: Person
  refreshIncidents: () => Promise<void>
  btnClassName?: string
}) => {
  const { setError } = useContext(GlobalContext)
  const { latestVitals, refreshLatestVitals } = useContext(VitalsContext)
  const [showVitalsModal, setShowVitalsModal] = useState(false)
  const methods = useForm<VitalsFormData>({
    mode: 'all',
  })

  useEffect(() => {
    const today = new Date()

    methods.reset({
      date: today,
      time: today.toLocaleTimeString('en-US', { hour12: false }).slice(0, 5),
    })
  }, [showVitalsModal])

  const onSubmit = async (data: VitalsFormData) => {
    try {
      const vitals = { ...formDataToVitalsPayload(data), personId: person.id }
      const { id: vitalId } = await createVitals(person, vitals)
      if (data.note?.trim() && vitalId !== undefined) {
        await createVitalNote({
          noteText: data.note,
          person,
          vital: {
            ...vitals,
            id: vitalId.toString(),
          },
        })
        void refreshIncidents()
      }
      void refreshLatestVitals()
      setShowVitalsModal(false)
    } catch (err) {
      setError(err)
    }
  }

  return (
    <FormProvider {...methods}>
      <AsyncIconButton
        data-testid="add-vitals"
        id="addVitalsButton"
        buttonStyle="secondary-fill"
        onClick={() => setShowVitalsModal(true)}
        initialIcon="fa-plus"
        className={twx(btnClassName)}
      >
        Add Vitals
      </AsyncIconButton>
      <Modal
        open={showVitalsModal}
        onClose={() => setShowVitalsModal(false)}
        id={'add-vitals'}
        contentClassName={tw`w-[600px] min-w-[600px] max-w-[600px]`}
      >
        <VitalsModalContents
          onSubmit={onSubmit}
          onCancel={() => setShowVitalsModal(false)}
          latestVitals={latestVitals}
        />
      </Modal>
    </FormProvider>
  )
}

export default AddVitalsModal
