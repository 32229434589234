import { LatestVital } from '@augusthealth/models/com/august/protos/api/latest_vital'
import { Vital } from '@augusthealth/models/com/august/protos/vital'
import { requestJson } from '@shared/api/request'
import { apiVitalsByIdUrl, apiVitalsUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'

export const getAllVitals = async (person: Person): Promise<Vital[]> => {
  const res = await requestJson({
    url: apiVitalsUrl(person as Required<Person>),
  })

  return res.data
}

export const getLatestVitals = async (person: Person): Promise<LatestVital> => {
  const res = await requestJson({
    url: `${apiVitalsUrl(person as Required<Person>)}/latest`,
  })

  return res.data
}

export const createVitals = async (
  person: Person,
  vitals: Vital
): Promise<{ id: number }> => {
  const res = await requestJson({
    method: 'POST',
    contentType: 'application/json',
    body: JSON.stringify(vitals),
    url: apiVitalsUrl(person as Required<Person>),
  })

  return res.data
}

export const mergePatchVitals = async (
  person: Person,
  updatedVitals: Vital
): Promise<Vital> => {
  const response = await requestJson({
    url: apiVitalsByIdUrl(person, updatedVitals.id || ''),
    method: 'PATCH',
    contentType: 'application/merge-patch+json',
    body: JSON.stringify(updatedVitals),
  })

  return response.data
}
