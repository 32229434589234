import { AdmissionsInformation_AdmissionType } from '@shared/types/admissions'
import { Contact } from '@shared/types/contact'
import { Gender } from '@shared/types/gender'
import { HumanName } from '@shared/types/human_name'
import { LevelOfCare } from '@shared/types/level_of_care'
import { MetaData, MetaDataWithGroup } from '@shared/types/meta_data'
import { ResidentStatus } from '@shared/types/person'
import { UserAccount } from './user'

export type BillingCategoryData = {
  orgId: string
  facilityId?: string
  name: string
}

export type BillingCategory = {
  data: BillingCategoryData
  meta: MetaData
}

export enum BillingFrequency {
  ONE_TIME = 'ONE_TIME',
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
}

export type BillingItemData = {
  orgId: string
  facilityId?: string
  categoryId: string
  name: string
  amountCents: number
  frequency: BillingFrequency
  glCode: string
}

export type BillingItem = {
  data: BillingItemData
  meta: MetaData
  category: BillingCategory
}

export type BillingItemsWithParentCategory = {
  category: BillingCategory
  items: BillingItem[]
}

export type BillingChargeData = {
  personId: string
  itemId: string
  name: string
  quantity: number
  amountCents: number
  note?: string
  startDate: string
  endDate?: string
}

export type BillingCharge = {
  data: BillingChargeData
  meta: MetaDataWithGroup
  item: BillingItem
}

export type RoomDetails = {
  roomNumber: string
  bedNumber?: string
}

export type ResidentBillingSummary = {
  name: HumanName
  admissionType: AdmissionsInformation_AdmissionType
  levelOfCare?: LevelOfCare
  roomDetails?: RoomDetails
  moveInDate?: string
  financialStartDate?: string
  moveOutDate?: string
  financialEndDate?: string
  payers: Contact[]
  responsibleParty?: Contact
  statementBalanceCents: number
  totalBalanceCents: number
}

export interface ResidentListEntry {
  personId: string
  roomDetails?: RoomDetails
  profilePictureUrl?: string
  name: HumanName[]
  gender: Gender
  residentStatus: ResidentStatus
  statementBalanceCents: number
  totalBalanceCents: number
  lastInvoice?: WriteableInvoice
  pendingBillingEvents: number
}

export type PersonSummary = {
  id: string
  name: HumanName
  bed?: string
  profilePhotoUrl?: string
}

export enum InvoiceStatus {
  NEEDS_WORK = 'NEEDS_WORK',
  PENDING = 'PENDING',
  PAID = 'PAID',
  DUE = 'DUE',
  APPROVED = 'APPROVED',
  ERROR = 'ERROR',
}

export type InvoiceData = {
  personId: string
  startDate: string
  endDate: string
  issueDate?: string
  dueDate?: string
  status: InvoiceStatus
  notes?: string
  rollOverBalanceCents?: number
}

export type WriteableInvoice = {
  data: InvoiceData
  meta: MetaData
}

export type BillingInvoice = {
  id: string
  invoiceData: InvoiceData
  invoiceAmountCents: number
  person: PersonSummary
}

export type DraftStatementsRequest = {
  people: string[]
  startDate?: string
  endDate?: string
  notes?: string
}

export type BillingInvoiceItemData = {
  personId: string
  billingItemId: string
  billingChargeId?: string
  invoiceId?: string
  description: string
  quantity: number
  amountCents: number
  startDate: string
  endDate?: string
  postDate: string
}

export interface WriteableBillingCharge {
  data: BillingChargeData
  meta: MetaDataWithGroup
}

export type InvoiceItem = {
  data: BillingInvoiceItemData
  meta: MetaData
}

export enum InvoiceEventType {
  MarkedApproved = 'MarkedApproved',
  MarkedNeedsWork = 'MarkedNeedsWork',
  InvoiceGenerated = 'InvoiceGenerated',
  InvoiceSent = 'InvoiceSent',
  InvoiceError = 'InvoiceError',
  PaymentReceived = 'PaymentReceived',
}

type BaseInvoiceEventData = {
  occurredAt: string
  userId: string
}

export type ApprovalData = {
  note?: string
}

export type NeedsWorkData = {
  note: string
}

export type InvoiceErrorData = {
  message: string
}

/* Dimitry: there is more to this type,
 * but we don't need any of the other fields yet */
export type PaymentReceivedData = {
  paymentData: {
    amountCents: number
  }
  remainingBalanceCents: number
}

export type InvoiceEventData =
  | (BaseInvoiceEventData & {
      eventType: InvoiceEventType.InvoiceGenerated
    })
  | (BaseInvoiceEventData & {
      eventType: InvoiceEventType.MarkedApproved
      data: ApprovalData
    })
  | (BaseInvoiceEventData & {
      eventType: InvoiceEventType.MarkedNeedsWork
      data: NeedsWorkData
    })
  | (BaseInvoiceEventData & {
      eventType: InvoiceEventType.PaymentReceived
      data: PaymentReceivedData
    })
  | (BaseInvoiceEventData & {
      eventType: InvoiceEventType.InvoiceError
      data: InvoiceErrorData
    })
  | (BaseInvoiceEventData & {
      eventType: InvoiceEventType.InvoiceSent
    })

export type InvoiceEvent = {
  userName: HumanName
  eventData: InvoiceEventData
}

export type DetailedInvoice = {
  data: InvoiceData
  meta: MetaData
  events: InvoiceEvent[]
  invoiceAmountCents: number
  invoiceItems: InvoiceItem[]
}

export enum BillingEventType {
  RESIDENT_STATUS_CHANGED = 'RESIDENT_STATUS_CHANGED',
  ROOM_CHANGED = 'ROOM_CHANGED',
  ADMISSION_TYPE_CHANGED = 'ADMISSION_TYPE_CHANGED',
  LEVEL_OF_CARE_CHANGED = 'LEVEL_OF_CARE_CHANGED',
}

export enum BillingEventStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export type AbstractBillingEventData = {
  eventType: BillingEventType
  personId: string
  status: BillingEventStatus
}

export type ResidentStatusChanged = {
  previousStatus: ResidentStatus
  newStatus:
    | ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT
    | ResidentStatus.RESIDENT_STATUS_DISCHARGED
}

export type RoomDescription = {
  bedId: string
  roomNumber: string
  bedNumber?: string
  capacity: number
}

export type RoomChanged = {
  previousRoom?: RoomDescription
  newRoom: RoomDescription
}

export type AdmissionTypeChanged = {
  currentAdmissionType: AdmissionsInformation_AdmissionType
  previousAdmissionType?: AdmissionsInformation_AdmissionType
}

/**
 * The top level `LevelOfCareChanged` type is a case-class on the backend
 * But the inner types for `currentLevelOfCare` and `previousLevelOfCare` are not.
 * So I faithfully modeled the value/score as optional here.
 */
export type LevelOfCareChanged = {
  currentLevelOfCare: { value?: number; score?: number }
  previousLevelOfCare?: { value?: number; score?: number }
}

export type BillingEventData =
  | (AbstractBillingEventData & {
      eventType: BillingEventType.RESIDENT_STATUS_CHANGED
      data: ResidentStatusChanged
    })
  | (AbstractBillingEventData & {
      eventType: BillingEventType.ROOM_CHANGED
      data: RoomChanged
    })
  | (AbstractBillingEventData & {
      eventType: BillingEventType.ADMISSION_TYPE_CHANGED
      data: AdmissionTypeChanged
    })
  | (AbstractBillingEventData & {
      eventType: BillingEventType.LEVEL_OF_CARE_CHANGED
      data: LevelOfCareChanged
    })

export type BillingEvent = {
  data: BillingEventData
  meta: MetaData
}

export enum BillingTransactionType {
  CREDIT = 'CREDIT',
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING',
  STATEMENT = 'STATEMENT',
  PAYMENT = 'PAYMENT',
}

export enum TransactionIdType {
  BILLING_CHARGE = 'BILLING_CHARGE',
  INVOICE = 'INVOICE',
  INVOICE_ITEM = 'INVOICE_ITEM',
}

export type BillingTransaction = {
  amountCents?: number
  balanceCents: number
  createdAt: string
  description: string
  id: string
  idType: TransactionIdType
  pending: boolean
  serviceStartDate: string
  serviceEndDate: string
  transactionType: BillingTransactionType
}

export type AccountingPeriodClose = {
  data: {
    facilityId: string
    closedBefore: string
  }
  meta: MetaData
}

// Manual Payment Entry
export enum PaymentMethod {
  CASH = 'CASH',
  CHECK = 'CHECK',
  CREDIT_CARD = 'CREDIT_CARD',
  WIRE = 'WIRE',
  DEBIT_CARD = 'DEBIT_CARD',
}

export interface ManualPaymentEntry {
  personId: string
  paymentDate: string
  amountCents: number
  checkNumber?: string
}

export interface ManualPaymentRequest {
  depositDate: string
  externalId: string
  paymentMethod: PaymentMethod
  payments: ManualPaymentEntry[]
}

type LedgerExportData = {
  id: string
  facilityId: string
  endDate: string
  createdBy: string
  createdAt: string
}

export type LedgerExport = {
  data: LedgerExportData
  user: UserAccount
}
