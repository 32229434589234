import { get } from '@augusthealth/august-frontend-form-elements'
import {
  SignatureInfo,
  SignatureStatus,
  StateMachineType,
  Task,
} from '@augusthealth/models/com/august/protos/task'
import { useContext, useEffect, useState } from 'react'
import { getUrl } from '@shared/api/legacy'
import { PERSON_PDF_URL } from '@shared/api/person'
import { SignatureInfoWithSkip } from '@shared/api/task'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { SignatureConfiguration } from '@shared/types/signable_form'
import { DataType, SignerRole } from '@shared/types/snapshot'
import { UserAccount } from '@shared/types/user'
import { getFirstName } from '@shared/utils/humanName'
import { Loading } from '@shared/utils/loading'
import { rpCanCreateSignaturePacket } from '@shared/utils/task'
import { isResponsiblePerson, userMatchesSigner } from '@shared/utils/user'
import { PageGeneratorItem } from '@app/components/Prospects/Forms/FormLayout/type'
import { fetchPageIndexWithEmptyRequiredField } from '@app/components/Prospects/Forms/FormLayout/utils'
import { getRequiredFields } from './api'

/**
 * Certain tasks can be signed by the RP without first being reviewed by the community.
 * However, those tasks need all their signers chosen by the community before the RP can sign.
 * This function figures out if we're in the scenario.
 */
export function noReviewTaskWithUnassignedSigners({
  currentSigners,
  task,
}: {
  currentSigners: SignatureInfoWithSkip[]
  task: Task
}) {
  const canCreateSignaturePacket = rpCanCreateSignaturePacket({ task })

  return (
    canCreateSignaturePacket && hasUnassignedExternalSigners(currentSigners)
  )
}

export function shouldDisableCTA({
  isRP,
  isTaskIncomplete,
  isRequestingSignature,
  currentSigners,
  task,
}: {
  isRequestingSignature: boolean
  isTaskIncomplete: Loading<boolean>
  isRP: boolean
  currentSigners: SignatureInfoWithSkip[]
  task: Task
}) {
  const isIncomplete =
    isTaskIncomplete.tag === 'Complete' && isTaskIncomplete.value

  if (!isRP) {
    // Non-RPs need to have all signers assigned
    return (
      isRequestingSignature ||
      isIncomplete ||
      hasUnassignedAdminSigner(currentSigners) ||
      hasUnassignedExternalSigners(currentSigners)
    )
  }

  // RPs should be able to 'request review' even before signers have been assigned
  return (
    isRequestingSignature ||
    isIncomplete ||
    noReviewTaskWithUnassignedSigners({ currentSigners, task })
  )
}

export function pdfUrl({ person, task }: { person: Person; task: Task }) {
  return getUrl({
    baseUrl: PERSON_PDF_URL,
    pId: person.id,
    orgId: person.orgId,
    formCode: task.taskTemplateInfo?.dataType,
    params: task.taskTemplateInfo?.customType
      ? {
          customType: task.taskTemplateInfo?.customType,
        }
      : undefined,
  })
}

export function useIsTaskIncomplete({
  configuration,
  dataType,
  person,
  task,
}: {
  configuration?: PageGeneratorItem[]
  dataType?: DataType
  person: Loading<Person>
  task: Task
}) {
  const { setError } = useContext(GlobalContext)
  const [isIncomplete, setIsIncomplete] = useState<Loading<boolean>>({
    tag: 'Loading',
  })

  useEffect(() => {
    if (person.tag === 'Loading') {
      return
    }

    const { facilityId, orgId, id: pId } = person.value
    if (configuration && dataType && facilityId && orgId && pId) {
      fetchPageIndexWithEmptyRequiredField({
        configuration,
        dataType,
        person: person.value as Person,
      })
        .then((pIndex) =>
          setIsIncomplete({ tag: 'Complete', value: pIndex > -1 })
        )
        .catch(setError)
    } else {
      // Check if the required fields are filled out
      getRequiredFields(
        person.value.orgId!,
        person.value.facilityId!,
        task.taskTemplateInfo!
      )
        .then((requiredFields) => {
          const needToFillRequiredFields = requiredFields.some((field) => {
            return !get(person.value, field)
          })

          setIsIncomplete({ tag: 'Complete', value: needToFillRequiredFields })
        })
        .catch(setError)
    }
  }, [person, dataType, configuration])

  return isIncomplete
}

/**
 * Ensure that the first admin signer on a task, if unset, is set to the current user
 */
export function buildCurrentSignersFromTemplate({
  templateSigners,
  user,
  person,
}: {
  templateSigners: SignatureConfiguration[] | undefined
  user: UserAccount | undefined
  person: Person
}) {
  if (!templateSigners || !user) {
    return []
  }

  return templateSigners.reduce(buildSigner(user, person), [])
}

/**
 * This only builds a signer object if the user is an admin
 * I think we can modify to build the full signer list (admins, RPs, residents)
 * but I'm not sure if that's necessary/desirable
 */
function buildSigner(user: UserAccount, person: Person) {
  return (accum: SignatureInfo[], el: SignatureConfiguration) => {
    const { orgId, facilityId, id: personId } = person
    const userIsRP = isResponsiblePerson({ user, orgId, facilityId, personId })

    return accum.concat({
      status: SignatureStatus.SIGNATURE_STATUS_NOT_SIGNED,
      signer: {
        role: el.signerRole,
        roleIndex: el.roleIndex,
        ...(el.signerRole === SignerRole.SIGNER_ROLE_ADMIN &&
          !userIsRP &&
          !accum.find((info) => info?.signer?.role === el.signerRole) && {
            userAccountId: user.id,
            name: user.name,
            email: user.email,
          }),
      },
    })
  }
}

export function hasUnassignedAdminSigner(signers: SignatureInfoWithSkip[]) {
  return signers.some((signerData) => {
    return (
      signerData.signer?.userAccountId === undefined &&
      signerData.signer?.role === SignerRole.SIGNER_ROLE_ADMIN &&
      !signerData.signer.skip
    )
  })
}

function hasUnassignedExternalSigners(signers: SignatureInfoWithSkip[]) {
  return signers.some((signerData) => {
    return (
      signerData.signer?.name === undefined &&
      signerData.signer?.role === SignerRole.SIGNER_ROLE_EXTERNAL &&
      !signerData.signer.skip
    )
  })
}

export function getRpCtaCtext({
  currentSigners,
  task,
}: {
  currentSigners: SignatureInfo[]
  task: Task
}) {
  if (
    task.taskTemplateInfo?.stateMachineType !==
    StateMachineType.STATE_MACHINE_TYPE_SHAREABLE_AND_SIGNABLE_NO_REVIEW
  ) {
    return 'Request Review'
  }

  if (
    currentSigners[0]?.signer?.role === SignerRole.SIGNER_ROLE_RESPONSIBLE_PARTY
  ) {
    return 'Continue to Sign'
  } else {
    return 'Request Signature'
  }
}

export const getNextRequiredSigner = ({
  signers,
}: {
  signers: SignatureInfoWithSkip[]
}): SignatureInfoWithSkip | undefined => {
  return signers.find(
    (signatureInfo) => signatureInfo.signer && !signatureInfo.signer?.skip
  )
}

export function getAdminCtaText({
  currentSigners,
  user,
}: {
  currentSigners: SignatureInfoWithSkip[]
  user: UserAccount
}) {
  const nextRequiredSigner = getNextRequiredSigner({ signers: currentSigners })

  const nextSigner = nextRequiredSigner?.signer

  if (nextSigner?.role === SignerRole.SIGNER_ROLE_ADMIN) {
    if (userMatchesSigner(user, nextSigner)) {
      return 'Continue to Sign'
    }
    const nextSignerName = getFirstName(nextSigner?.name)

    return `Request ${nextSignerName ? `${nextSignerName}'s` : 'Next'} Signature`
  }

  if (nextSigner?.role === SignerRole.SIGNER_ROLE_RESPONSIBLE_PARTY) {
    return 'Request RP Signature'
  }

  if (nextSigner?.role === SignerRole.SIGNER_ROLE_EXTERNAL) {
    const name = nextSigner?.name
      ? getFirstName(nextSigner.name)
      : 'External Party'

    if (
      currentSigners.filter(
        (s) => s.signer?.role === SignerRole.SIGNER_ROLE_EXTERNAL
      ).length > 1
    ) {
      return 'Request Signatures'
    }

    return `Request ${name}'s Signature`
  }

  return 'Continue'
}

export function replaceSignerPreservingRoleIndex({
  previous,
  newSigner,
  ix,
  toggleSkip = false,
}: {
  newSigner: SignatureInfoWithSkip
  previous: SignatureInfoWithSkip[]
  ix: number
  toggleSkip?: boolean
}) {
  return previous.map((prev, j) => {
    if (j === ix) {
      return {
        ...newSigner,
        signer: {
          ...newSigner.signer,
          roleIndex: prev.signer?.roleIndex,
          ...(toggleSkip && { skip: !prev.signer?.skip }),
        },
      }
    }

    return prev
  })
}
