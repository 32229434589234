import { AUGUST_FIELD_DISPLAY_CONFIGURATION } from '@shared/constants/custom_field/custom_field'
import { AugustFieldType } from '@shared/types/custom_field'

export function getResidentDetailsJson() {
  return [
    ...(AUGUST_FIELD_DISPLAY_CONFIGURATION[
      AugustFieldType.AUGUST_FIELD_TYPE_NAME
    ]?.fields || []),
    ...(AUGUST_FIELD_DISPLAY_CONFIGURATION[
      AugustFieldType.AUGUST_FIELD_TYPE_DATE_OF_BIRTH
    ]?.fields || []),
    ...(AUGUST_FIELD_DISPLAY_CONFIGURATION[
      AugustFieldType.AUGUST_FIELD_TYPE_GENDER
    ]?.fields || []),
    ...(AUGUST_FIELD_DISPLAY_CONFIGURATION[
      AugustFieldType.AUGUST_FIELD_TYPE_SSN
    ]?.fields || []),
    ...(AUGUST_FIELD_DISPLAY_CONFIGURATION[
      AugustFieldType.AUGUST_FIELD_TYPE_RESIDENT_PRIOR_ADDRESS
    ]?.fields || []),
    ...(AUGUST_FIELD_DISPLAY_CONFIGURATION[
      AugustFieldType.AUGUST_FIELD_TYPE_RELIGIOUS_PREFERENCE
    ]?.fields || []),
  ]
}

export function getContactsJson() {
  return [
    ...(AUGUST_FIELD_DISPLAY_CONFIGURATION[
      AugustFieldType.AUGUST_FIELD_TYPE_RP_NAME
    ]?.fields || []),
    {
      title: 'RESPONSIBLE PERSON ADDRESS',
      name: 'contact[relationship=CONTACT_RELATIONSHIP_RESPONSIBLE_PERSON].address',
      type: 'address_autocomplete',
      relation: {
        trigger:
          'contact[relationship=CONTACT_RELATIONSHIP_RESPONSIBLE_PERSON]',
        condition: 'exists',
        relationType: 'visibility',
      },
    },
    ...(AUGUST_FIELD_DISPLAY_CONFIGURATION[
      AugustFieldType.AUGUST_FIELD_TYPE_GP_NAME
    ]?.fields || []),
    {
      title: 'OTHER CLINICIANS',
      description: 'Mental health, dental, social work or other providers',
      name: 'contact[relationship=CONTACT_RELATIONSHIP_CLINICIAN][*]',
      type: 'contact_dropdown_list',
      relationshipsToExclude: ['CONTACT_RELATIONSHIP_GENERAL_PRACTITIONER'],
      onlyAllowToAdd: true,
      hideRemoveBtn: true,
    },
    ...(AUGUST_FIELD_DISPLAY_CONFIGURATION[
      AugustFieldType.AUGUST_FIELD_TYPE_EMERGENCY_1_NAME
    ]?.fields || []),
    {
      title: 'CLERGY OR RELIGIOUS ADVISOR',
      subTitle: 'IF ANY',
      name: 'contact[relationship=CONTACT_RELATIONSHIP_RELIGIOUS_ADVISOR][*]',
      type: 'contact_dropdown_list',
      placeholder: 'Add clergy or religious advisor',
    },
    {
      ...AUGUST_FIELD_DISPLAY_CONFIGURATION[
        AugustFieldType.AUGUST_FIELD_TYPE_FUNERAL_HOME_1_NAME
      ]?.fields[0],
      subTitle: 'IF ANY',
    },
  ]
}

export function getEmergencyInfoJson() {
  return [
    ...(AUGUST_FIELD_DISPLAY_CONFIGURATION[
      AugustFieldType.AUGUST_FIELD_TYPE_HOSPITAL_NAME
    ]?.fields || []),
    ...(AUGUST_FIELD_DISPLAY_CONFIGURATION[
      AugustFieldType.AUGUST_FIELD_TYPE_MED_INS_1_COMPANY
    ]?.fields || []),
    ...(AUGUST_FIELD_DISPLAY_CONFIGURATION[
      AugustFieldType.AUGUST_FIELD_TYPE_MED_INS_2_COMPANY
    ]?.fields || []),
  ]
}
