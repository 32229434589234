import {
  FormGenerator,
  get,
  isEmpty,
  set,
} from '@augusthealth/august-frontend-form-elements'
import { MouseEvent, ReactNode, useEffect, useState } from 'react'
import ButtonGroup, {
  ButtonGroupOption,
} from '@app/components/generic/ButtonGroup'
import { mapExtraCompName, uiComponentsMap } from '../utils'
import getInvalidFields, { RequiredField } from './getInvalidFields'

type Configuration = {
  name: string
  type?: string
  options?: ButtonGroupOption[]
}

type FormGeneratorProps = {
  mapComponentProps?: (props: object) => void
  mapExtraCompName?: () => void
  uiComponentsMap?: object
  readOnly?: boolean
}

type Props = FormGeneratorProps & {
  data?: object
  buttonGroupClassName?: string
  configuration: Configuration[]
  footerButtonGroupOptions?: ButtonGroupOption[]
  requiredFields?: RequiredField[]
  cancelButtonClassName?: string
  cancelButtonLabel?: ReactNode
  cancelButtonClick?: (ev: MouseEvent<HTMLButtonElement>) => any
  saveButtonClassName?: string
  saveButtonLabel?: ReactNode
  saveButtonClick?: (data: any) => any
  defaultShowRequiredFieldAlert?: boolean
  onUpdate?: (value: any, name: string, data: object) => void
  allowAutoComplete?: boolean
}

export default function AdvancedFormGenerator(props: Props) {
  const {
    data,
    buttonGroupClassName,
    configuration,
    footerButtonGroupOptions,
    requiredFields = [],
    cancelButtonClassName,
    cancelButtonLabel,
    cancelButtonClick,
    saveButtonClassName = 'hover:brightness-125 bg-button-primary-color text-button-fill-font-color ',
    saveButtonLabel,
    saveButtonClick,
    defaultShowRequiredFieldAlert = false,
    onUpdate,
    allowAutoComplete = false,
  } = props
  const [showRequiredFieldAlert, setShowRequiredFieldAlert] = useState<boolean>(
    defaultShowRequiredFieldAlert
  )
  useEffect(() => {
    setShowRequiredFieldAlert(defaultShowRequiredFieldAlert)
  }, [defaultShowRequiredFieldAlert])

  const onSave = () => {
    const newData = { ...data }
    if (allowAutoComplete) {
      // fill data based on HTMLFormElement's value
      const names = configuration.map((c) => c.name)
      names.forEach((n) => {
        if (isEmpty(get(newData, n))) {
          const fieldList = document.getElementsByName(n)
          if (fieldList.length) {
            const rf = fieldList[0] as HTMLFormElement
            if (!isEmpty(rf.value)) {
              set(newData, n, rf.value)
              if (onUpdate) {
                onUpdate(rf.value, n, newData)
              }
            }
          }
        }
      })
    }
    const invalidFields = getInvalidFields({ data: newData, requiredFields })
    if (!isEmpty(invalidFields)) {
      setShowRequiredFieldAlert(true)
      const elementNames = Object.keys(invalidFields)
      const elName = elementNames[0]
      const fieldList = document.getElementsByName(elName)
      if (fieldList.length) {
        const rf = fieldList[0] as HTMLFormElement
        // Still need to check if .focus or .select exists
        // Some composite no-native element such as Birthdate or React-Select
        // may not have .focus or .select and need to add those events manually
        if (typeof rf.focus === 'function') {
          rf.focus()
        }
        if (typeof rf.select === 'function') {
          rf.select()
        }
      } else {
        console.warn(`Required field ${elName} cannot be found`)
      }
    } else if (typeof saveButtonClick === 'function') {
      return saveButtonClick(newData)
    }
  }
  const formGenerator = (
    <FormGenerator
      mapExtraCompName={mapExtraCompName}
      uiComponentsMap={uiComponentsMap}
      {...props}
      requiredFields={showRequiredFieldAlert ? requiredFields : []}
    />
  )

  const buttonGroupOptions: ButtonGroupOption[] = []
  if (footerButtonGroupOptions && footerButtonGroupOptions.length) {
    buttonGroupOptions.push(...footerButtonGroupOptions)
  }
  if (cancelButtonLabel) {
    buttonGroupOptions.push({
      label: cancelButtonLabel,
      className: cancelButtonClassName,
      onClick: cancelButtonClick,
    })
  }
  if (saveButtonLabel) {
    buttonGroupOptions.push({
      label: saveButtonLabel,
      className: saveButtonClassName,
      onClick: onSave,
    })
  }
  if (buttonGroupOptions.length) {
    return (
      <>
        {formGenerator}
        <ButtonGroup
          className={buttonGroupClassName}
          options={buttonGroupOptions}
        />
      </>
    )
  }

  return formGenerator
}
