import { Contact_ContactRelationship } from '@augusthealth/models/com/august/protos/contact'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import {
  Label,
  LabelAboveInput,
  requiredLabel,
} from '@shared/components/Labels'
import StyledSelect from '@shared/components/StyledSelect'
import { EmailInput } from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import { EMAIL_REGX } from '@shared/utils/regex'
import styles from './styles.module.css'
import PhoneInput from '../PhoneInputWithType'
import { RelationshipOption } from './helpers'
import {
  categoryOptions,
  optionsForCategory,
  RelationshipCategory,
} from './RPVerifyInformation/helpers'

export default function RPStep({
  isRPDataRequired,
}: {
  isRPDataRequired: boolean
}) {
  const {
    control,
    formState: { errors },
    formState,
    register,
    getValues,
    setValue,
  } = useFormContext()

  const [secondaryOptions, setSecondaryOptions] = useState<
    RelationshipOption[]
  >([])

  return (
    <>
      <div className={styles.inputRow}>
        <div className={styles.formInput}>
          <LabelAboveInput
            htmlFor="rpFirstName"
            subLabel={
              isRPDataRequired
                ? requiredLabel(
                    formState.dirtyFields['rpFirstName'] && !!errors.rpFirstName
                  )
                : undefined
            }
          >
            First Name
          </LabelAboveInput>
          <BasicInput
            {...register('rpFirstName', { required: isRPDataRequired })}
            placeholder="First Name"
          />
        </div>
        <div className={styles.formInput}>
          <LabelAboveInput
            htmlFor="rpLastName"
            subLabel={
              isRPDataRequired
                ? requiredLabel(
                    formState.dirtyFields['rpLastName'] && !!errors.rpLastName
                  )
                : undefined
            }
          >
            Last Name
          </LabelAboveInput>
          <BasicInput
            {...register('rpLastName', { required: isRPDataRequired })}
            placeholder="Last Name"
          />
        </div>
      </div>
      <div className={styles.inputRow}>
        <div className={styles.formInput}>
          <LabelAboveInput
            htmlFor="rpPrimaryRelationship"
            subLabel={
              isRPDataRequired
                ? requiredLabel(
                    formState.dirtyFields['rpPrimaryRelationship'] &&
                      !!(
                        errors.rpPrimaryRelationship ||
                        errors.rpSecondaryRelationship
                      )
                  )
                : undefined
            }
          >
            Relationship to Move-in
          </LabelAboveInput>
          <Controller
            control={control}
            name="rpPrimaryRelationship"
            rules={{ required: isRPDataRequired }}
            render={({ field: { onChange, value } }) => (
              <StyledSelect
                options={categoryOptions.filter(
                  (category) =>
                    category.value !==
                    Contact_ContactRelationship.CONTACT_RELATIONSHIP_CLINICIAN
                )}
                onChange={(
                  value: {
                    label: string
                    value: RelationshipCategory
                  },
                  context
                ) => {
                  onChange(value, context)
                  setValue('rpSecondaryRelationship', null)
                  setSecondaryOptions(optionsForCategory(value.value))
                }}
                name="rpPrimaryRelationship"
                value={value}
                placeholder="Relationship Category..."
              />
            )}
          />
        </div>
        <div className={styles.formInput}>
          <LabelAboveInput htmlFor="rpSecondaryRelationship">
            <span style={{ visibility: 'hidden' }}>Specific Relationship</span>
          </LabelAboveInput>
          <Controller
            control={control}
            name="rpSecondaryRelationship"
            rules={{ required: isRPDataRequired }}
            render={({ field: { onChange, value } }) => (
              <StyledSelect
                options={secondaryOptions}
                onChange={onChange}
                name="rpSecondaryRelationship"
                value={value}
                isDisabled={!getValues('rpPrimaryRelationship')}
                placeholder="Specific Relationship..."
              />
            )}
          />
        </div>
      </div>
      <div className="mt-[32px]">
        <LabelAboveInput htmlFor="rpPhone">Phone</LabelAboveInput>
        <Controller
          control={control}
          name="rpPhoneUse"
          render={({ field: { value, onChange } }) => {
            return (
              <PhoneInput
                inputProps={{
                  ...register('rpPhone'),
                }}
                dropdownProps={{
                  name: 'rpPhoneUse',
                  onChange,
                  value,
                }}
              />
            )
          }}
        />
      </div>
      <div className="mt-[32px]">
        <div className={styles.emailLabel}>
          <Label htmlFor="rpEmail">Email</Label>
          {formState.touchedFields.rpEmail && errors.rpEmail && (
            <span className={styles.errorLabel}>Invalid format</span>
          )}
        </div>
        <EmailInput
          inputProps={{
            ...register('rpEmail', {
              validate: (value: string) =>
                !value.trim() || EMAIL_REGX.test(value),
            }),
          }}
        />
      </div>
    </>
  )
}
